import React, {ReactNode} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {CardSizes} from "../../../typesAndConstants";
import {useTranslation} from "react-i18next";
import {Grid, Skeleton, Typography} from '@barracuda-internal/bds-core';
import {Theme} from "@mui/material";

export interface TotalChartProps {
    /**
     * The total to display
     */
    total: number | string;
    /**
     * Icon shown above or next to the total
     */
    icon: ReactNode;
    /**
     * size of the parent card
     */
    size: CardSizes;
    /**
     * if true, loading skeleton bars are displayed instead of data
     */
    loading?: boolean,
    className?: string;
}

const styles = (theme: Theme) => createStyles<string, TotalChartProps>({
    root: {
        paddingTop: ({size}) => theme.spacing(size === CardSizes.small ? 8 : 1)
    },
    icon: {
        textAlign: "center",
        padding: ({size}) => size === CardSizes.large ? theme.spacing(1.5, 2, 0, 0) : 0,
        color: theme.palette.reporting.fontColor
    },
    total: {
        textAlign: "center",
        fontSize: 30,
        fontWeight: "lighter",
        color: theme.palette.reporting.fontColor
    },
    totalValue: {
        color: theme.palette.error.main,
        fontWeight: "bold"
    },
    skeleton: {
        width: 48,
        display: "inline-block"
    }
});
const useStyles = makeStyles(styles);
/**
 *  Shows a total number of something
 * @param props
 * @constructor
 */
export const TotalChart = (props: TotalChartProps) => {
    const {total, icon, loading, className, size} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);

    return (
        <Grid container className={classes.root} alignItems="center" justifyContent="center">
            <Grid item xs={size === CardSizes.large ? undefined : 12} className={classes.icon}>
                {icon}
            </Grid>
            <Grid item xs={size === CardSizes.large ? undefined : 12}>
                <Typography variant="h2" className={classes.total}>
                    <span>{translate("stratosReporting.totalChart.total")} </span>
                    {loading ? (
                        <Skeleton className={classes.skeleton}/>
                    ) : (
                        <span className={classes.totalValue}>{total} </span>
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
};
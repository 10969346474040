import React, {useRef, useState} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    CrudTypes,
    DeleteDialog,
    EmptyPageContent,
    PropGateway,
    TablePage,
    TextField,
    useCrudFetch,
    useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditIngressRule from "./CreateEditIngressRule";
import {useSourceDestinationField} from "../../../../../hooks/sourceAndDestinationHooks";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {get, merge} from "lodash";
import apiResources from "../../../../../apiResources";
import {
    formatGatewaySiteGroupInTableData,
    formatSourceDestinationFilters
} from "../../../../../components/sourceAndDestination/tableUtils";
import GroupingField from "../GroupingField";
import {GetStartedBackgroundGraphic, IngressNatGraphic} from "@cuda-react/icons";

const IngressRule = () => {
    const tableRefreshRef = useRef<any>();
    const [, , callIngressRuleControl] = useCrudFetch(CrudTypes.CREATE, apiResources.ingressNatRulesUpdateOrder, {});
    const handleOrderClick = (id: any, direction: string, data: any) => callIngressRuleControl({
        id,
        changeOrder: direction,
        data
    }).then(tableRefreshRef.current);
    const generateChipArrayProps = useSourceDestinationField();
    const registerAction = useMixpanel("Ingress NAT Rules");
    const [actionVisible, setActionVisible] = useState(false);
    const renderGatewayGroupName = (applianceId: any, rowData: {
        items: {
            type: string;
        }[];
    }) => {
        const groupByType = rowData.items[0].type === "gateway" ? "onPremGatewayName" : "siteName";
        return generateChipArrayProps("scope")({data: {scope: {type: groupByType}}}).render;
    };


    return (
        <TablePage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.network", "tesseract.securityAndAccess.ingressNatRules"]}
            resource={apiResources.ingressNatRules}
            exportResource={{resource: apiResources.ingressNatRulesExport, filename: "ingressNatRules"}}
            minCellWidth={128}
            refreshRef={tableRefreshRef}
            titleActions={actionVisible &&
                <CreateEditButtonDialog
                    key="create"
                    create
                    createEditComponent={CreateEditIngressRule}
                    component={CreateEditIngressRule}
                    onSuccess={tableRefreshRef.current}/>
            }
            groupBy="destination.applianceId"
            groupField={<GroupingField
                // @ts-ignore
                render={(applianceId: any, data: {
                    items: { type: string; }[];
                }) => renderGatewayGroupName(applianceId, data)(applianceId, data)}
                createEditComponent={CreateEditIngressRule} dataIconType={'destination.type'}/>}
            defaultItemsPerPage={5000}
            formatData={formatGatewaySiteGroupInTableData}
            onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
            params={(data) => merge({}, formatSourceDestinationFilters(data))}
            authenticated
            noDataPage={
                <EmptyPageContent
                    background={<GetStartedBackgroundGraphic/>}
                    icon={<IngressNatGraphic/>}
                    iconHeader="tesseract.security.ingressNatRules.noData.iconHeader"
                    textHeader="tesseract.security.ingressNatRules.noData.textHeader"
                    text="tesseract.security.ingressNatRules.noData.text"
                    actions={<CreateEditButtonDialog label="tesseract.security.ingressNatRules.noData.labelButton"
                                                     key="create" create createEditComponent={CreateEditIngressRule}
                                                     component={CreateEditIngressRule}
                                                     onSuccess={tableRefreshRef.current}/>}
                />
            }
        >
            <ActionButtonsField
                left
                label="tesseract.security.ingressNatRules.settings.order"
                width={96}
                source="id"
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: any) => handleOrderClick(data.id, "increase", data)}
                    disabled={(data: any) => get(data, "order", 0) < 2}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: any) => handleOrderClick(data.id, "decrease", data)}
                    disabled={(data: any) => (get(data, "groupTotal", 0) - get(data, "order", 0)) < 1}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.ingressNatRules.settings.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.ingressNatRules.settings.description"
                filter="text"
            />
            <PropGateway
                source="source"
                label="tesseract.security.ingressNatRules.settings.source"
                editProps={generateChipArrayProps("source")}
                filter="custom"
                filterProps={{
                    component: SourceFilter,
                    filterSources: ["internet", "networks"]
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <PropGateway
                source="destination"
                label="tesseract.security.ingressNatRules.settings.destination"
                editProps={generateChipArrayProps("destination")}
                filter="custom"
                filterProps={{
                    component: DestinationFilter,
                    filterSources: ["onPremGateway", "ingressSite"]
                }}
            >
                <ChipArrayField source="destination"/>
            </PropGateway>
            <PropGateway
                source="target"
                label="tesseract.security.ingressNatRules.settings.target"
                editProps={generateChipArrayProps("target")}
                filter="selectarray"
                filterProps={{
                    resource: apiResources.validTargets
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <ActionButtonsField
                width={96}
                source="id"
            >
                <CreateEditButtonDialog component={CreateEditIngressRule} onSuccess={tableRefreshRef.current}/>
                <DeleteDialog
                    resource={apiResources.ingressNatRules}
                    title="tesseract.security.ingressNatRules.settings.delete.title"
                    message="tesseract.security.ingressNatRules.settings.delete.body"
                    onSuccess={() => {
                        tableRefreshRef.current?.();
                        registerAction("Delete");
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

IngressRule.propTypes = {};

export default IngressRule;
import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const NetworkIcon = ({className}: {className?: string}) => (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-network"
            viewBox="0 0 60 60"
        >
            <path
                style={{fill: "currentColor", strokeWidth: 0}}
                d="M32.4,54.5h19.6v-17.1h-7.3v-9.8h-12.2v-4.9h7.3V5.5h-19.6v17.1h7.3v4.9h-12.2v9.8h-7.3v17.1h19.6v-17.1h-7.3v-4.9h19.6v4.9h-7.3v17.1ZM25.1,17.8v-7.3h9.8v7.3h-9.8ZM22.7,42.2v7.3h-9.8v-7.3h9.8ZM47.1,42.2v7.3h-9.8v-7.3h9.8Z"
            />
        </SvgIcon>
    );

export default NetworkIcon;
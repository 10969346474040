import Gateways from "./gateways/Gateways";
import {Route, Switch} from "react-router";
import Sites from "./sites/Sites";
import VmActivation from "./vmActivation/VmActivation";
import React from "react";
import NetworkSettings from "./settings/NetworkSettings";
import IoT from "./sites/IoT";
import SdwanConnector from "./sdwanConnector/SdwanConnector";

export const Network = () => (
    <Switch>
        <Route path="/infrastructure/gateways">
            <Gateways/>
        </Route>
        <Route path="/infrastructure/sites">
            <Sites/>
        </Route>
        <Route path="/infrastructure/iot">
            <IoT/>
        </Route>
        <Route path="/infrastructure/connectors">
            <SdwanConnector />
        </Route>
        <Route path="/infrastructure/vmactivation">
            <VmActivation/>
        </Route>
        <Route path="/infrastructure/settings">
            <NetworkSettings/>
        </Route>
    </Switch>
);

export default Network;
import {CrudTypes, useCrudProps, useCrudSubscription, useGlobalParam} from "@cuda-react/core";
import apiResources from "../apiResources";
import {useCallback, useEffect, useState} from "react";
import {StepState} from "@cuda-react/icons";

export enum Steps {
    intro = "intro",
    step1 = "step1",
    step2 = "step2",
    step3 = "step3",
    step4 = "step4",
    step5 = "step5",
    complete = "complete"
}

/**
 *
 * @param pointsOfEntry
 * @param gateways
 * @param sites
 *
 * @return step1state StepState
 */
const getStep1State = (
    pointsOfEntry: { configurationType: string, configurationUuid: string }[],
    gateways: { uuid: string, connectionStatus: string }[],
    sites: { uuid: string, connectionStatus: any }[]
) => {
    if (pointsOfEntry?.length > 0) {
        const poeIsOnline = pointsOfEntry.some((poe: any) => {
            const appliances = poe?.configurationType === "gateway" ? gateways : sites;
            const poeAppliance = appliances && appliances.find((appliance: any) => appliance.uuid === poe.configurationUuid);
            return poeAppliance?.connectionStatus?.aggregateConnectionState === "online";
        });
        return poeIsOnline ? StepState.Completed : StepState.Pending;
    }
    return StepState.Incomplete;
};

/**
 *
 * @param users
 * @param identityProvider
 * @param userDirectories
 *
 * @return step2state StepState
 */
const getStep2State = (
    users: any[],
    identityProvider: { status: string }[],
    userDirectories: { lastSuccessfulSync?: string }[]
) => {
    const identityProviderIsActive = identityProvider?.some((provider) => provider.status === "active");

    // No IDP, its always incomplete, so return early
    if (!identityProvider?.length) {
        return StepState.Incomplete;
    }
    // Users and active IDP
    if (users?.length > 0 && identityProviderIsActive) {
        return StepState.Completed;
    }
    // Users and Pending IDP
    if (users?.length > 0 && identityProvider?.length) {
        return StepState.Pending;
    }
    // Active UD and IDP
    const userDirectoryHasSynced = userDirectories?.some((directory) => !!directory.lastSuccessfulSync);
    if (userDirectoryHasSynced && identityProviderIsActive) {
        return StepState.Completed;
    }
    // Pending UD and IDP
    if (userDirectories?.length && identityProvider?.length) {
        return StepState.Pending;
    }

    return StepState.Incomplete;
};

/**
 * Hook for managing the ZTNA Setup Wizard
 * @return {step, value} an object containing the stepId string and as value an object containing a status string value and the loading boolean. The state can be completed, uncompleted and pending.
 */
export const useZtnaWizardState = () => {
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const [preload, setPreload] = useState(true);

    // Step1
    const [pointsOfEntry, pointsOfEntryLoading, pointsOfEntryRefresh] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources.pointsOfEntry : undefined, {}, {pollInterval: 300000, crudOptions: {quietErrors: true}}) ?? [undefined, false];
    const [gateways, gatewaysLoading, gatewaysRefresh] = useCrudProps(!isDemo ? apiResources.gateways : undefined, {}, {quietErrors: true}) ?? [undefined, false];
    const [sites, sitesLoading, sitesRefresh] = useCrudProps(!isDemo ? apiResources.sites : undefined, {}, {quietErrors: true}) ?? [undefined, false];
    const step1Loading = pointsOfEntryLoading || gatewaysLoading || sitesLoading;
    const step1Reload = useCallback(() => {
        pointsOfEntryRefresh();
        gatewaysRefresh();
        sitesRefresh();
    }, [pointsOfEntryRefresh, gatewaysRefresh, sitesRefresh]);

    // Step2
    const [users, usersLoading, usersRefresh] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources.identityUsers : undefined, {}, {pollInterval: 300000, crudOptions: {quietErrors: true}}) ?? [undefined, false];
    const [identityProvider, identityProviderLoading, identityProviderRefresh] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources.identityProvider : undefined, {}, {pollInterval: 300000, crudOptions: {quietErrors: true}}) ?? [undefined, false];
    const [userDirectories, userDirectoriesLoading, userDirectoryRefresh] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources.directories : undefined, {}, {pollInterval: 300000, crudOptions: {quietErrors: true}}) ?? [undefined, false];
    const step2Loading = usersLoading || identityProviderLoading || userDirectoriesLoading;
    const step2Reload = useCallback(() => {
        usersRefresh();
        identityProviderRefresh();
        userDirectoryRefresh();
    }, [usersRefresh, identityProviderRefresh, userDirectoryRefresh]);

    // Step3
    const [appCatalog, appCatalogLoading, appCatalogRefresh] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources.appCatalog : undefined, {}, {pollInterval: 300000, crudOptions: {quietErrors: true}}) ?? [undefined, false];
    const step3Loading = appCatalogLoading;
    const step3Reload = useCallback(() => {
        appCatalogRefresh();
    }, [appCatalogRefresh]);

    // Step4
    const [zeroTrustPolicies, zeroTrustPoliciesLoading, zeroTrustPoliciesRefresh] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources.zeroTrustPolicies : undefined, {}, {pollInterval: 300000, crudOptions: {quietErrors: true}}) ?? [undefined, false];
    const step4Loading = zeroTrustPoliciesLoading;
    const step4Reload = useCallback(() => {
        zeroTrustPoliciesRefresh();
    }, [zeroTrustPoliciesRefresh]);

    // Step5
    const [zeroTrustUsers, zeroTrustUsersLoading, zeroTrustUsersRefresh] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources.zeroTrustUsers : undefined, {}, {pollInterval: 300000, crudOptions: {quietErrors: true}}) ?? [undefined, false];
    const step5Loading = zeroTrustUsersLoading;
    const step5Reload = useCallback(() => {
        zeroTrustUsersRefresh();
    }, [zeroTrustUsersRefresh]);

    const anyLoading = step1Loading || step2Loading || step3Loading || step4Loading || step5Loading;

    useEffect(() => {
        if (anyLoading || isDemo) {
            setPreload(false);
        }
    }, [anyLoading]);

    return {
        [Steps.step1]: {
            state: !isDemo ? getStep1State(pointsOfEntry?.data?.content, gateways?.data?.content, sites?.data?.content) : StepState.Completed,
            loading: step1Loading,
            refresh: step1Reload
        },
        [Steps.step2]: {
            state: !isDemo ? getStep2State(users?.data?.content, identityProvider?.data?.content, userDirectories?.data?.content) : StepState.Completed,
            loading: step2Loading,
            refresh: step2Reload
        },
        [Steps.step3]: {
            state: !isDemo ? (appCatalog?.data?.content?.length > 0 ? StepState.Completed : StepState.Incomplete) : StepState.Completed,
            loading: step3Loading,
            refresh: step3Reload
        },
        [Steps.step4]: {
            state: !isDemo ? (zeroTrustPolicies?.data?.content?.length > 0 ? StepState.Completed : StepState.Incomplete) : StepState.Completed,
            loading: step4Loading,
            refresh: step4Reload
        },
        [Steps.step5]: {
            state: !isDemo ? (zeroTrustUsers?.data?.content?.length > 0 ? StepState.Completed : StepState.Incomplete) : StepState.Completed,
            loading: step5Loading,
            refresh: step5Reload
        },
        loading: preload || anyLoading
    };
};
import React from "react";
import Input, {InputProps} from "../Input/Input";
import Double from './Double';

export interface DoubleInputProps extends Omit<InputProps<typeof Double>, "component" | "displayError"> {}

/**
 * [Input](/?path=/docs/core-components-inputs-input) Wrapper component format, to work natively with redux form "Field" prop.
 * form ready version of the Double component.
 */
export const DoubleInput = (props: DoubleInputProps) => (
    <Input {...props} displayError={false} component={Double} />
);
export default DoubleInput;
import {get, omit} from "lodash";
import {FieldValues} from "react-hook-form";

export const formatRequestData = (data: FieldValues) => ({
    ...data,
    resourceCriteria: {
        ...omit(get(data, "resourceCriteria", {}), ["publicApps", "internalApps"]),
        type: get(data, "resourceCriteria.type"),
        applications: get(data, get(data, "resourceCriteria.type") === "public" ? "resourceCriteria.publicApps" : "resourceCriteria.internalApps", [])
    },
    deviceCriteria: {
        ...omit(get(data, "deviceCriteria", {}), "osMinimumVersions"),
        minimumClientVersion: get(data, "deviceCriteria.appUpdates") ? get(data, "deviceCriteria.appMinimumVersion", "") : null,
        minimumOperatingSystemVersions: (get(data, "deviceCriteria.osUpdates") && get(data, "deviceCriteria.osMinimumVersions") || [])
            .reduce((versions: any, {platform, version}: any) => ({...versions, [platform]: version}), {})
    }
});

export const formatResourceData = (data: any) => ({
    ...data,
    resourceCriteria: {
        ...get(data, "resourceCriteria", {}),
        publicApps: get(data, "resourceCriteria.type") === "public" ? get(data, "resourceCriteria.applications") : [],
        internalApps: get(data, "resourceCriteria.type") === "internal" ? get(data, "resourceCriteria.applications") : [],
    },
    deviceCriteria: {
        ...get(data, "deviceCriteria", {}),
        appUpdates: !!get(data, "deviceCriteria.minimumClientVersion") || false,
        appMinimumVersion: get(data, "deviceCriteria.minimumClientVersion") || "",
        osUpdates: Object.values(get(data, "deviceCriteria.minimumOperatingSystemVersions", {})).some((value) => !!value),
        osMinimumVersions: Object.keys(get(data, "deviceCriteria.minimumOperatingSystemVersions", {}))
            .map((operatingSystem) => ({platform: operatingSystem, version: get(data, "deviceCriteria.minimumOperatingSystemVersions." + operatingSystem, "")}))
            .filter(({version}) => !!version)
    }
});

export const formatError = (error: any, data: any) => ({
    ...error,
    resourceCriteria: {
        ...get(error, "resourceCriteria", {}),
        publicApps: get(data, "resourceCriteria.type") === "public" ? get(error, "resourceCriteria.applications") : [],
        internalApps: get(data, "resourceCriteria.type") === "internal" ? get(error, "resourceCriteria.applications") : [],
    },
    deviceCriteria: {
        ...get(error, "deviceCriteria", {}),
        minimumOperatingSystemVersions: undefined,
        osMinimumVersions: get(error, "deviceCriteria.minimumOperatingSystemVersions")
            && Object.keys(get(error, "deviceCriteria.minimumOperatingSystemVersions", {}))
                .map((platform) => ({
                    platform,
                    inputIndex: get(data, "deviceCriteria.osMinimumVersions", []).findIndex(({platform: searchPlatform}: any) => searchPlatform === platform)
                }))
                .sort(({inputIndex: inputIndexA}, {inputIndex: inputIndexB}) => inputIndexA - inputIndexB)
                .map(({platform}) => ({version: get(error, `deviceCriteria.minimumOperatingSystemVersions.${platform}`)}))
    }
});
import React from "react";
import Input, {InputProps} from "../Input/Input";
import Select from "./Select";

export interface SelectInputProps extends Omit<InputProps<typeof Select>, "component" | "displayError"> {}

/**
 * Based on the Select component, but modified to use the default [Input](/?path=/docs/core-components-inputs-input) format. Works natively with redux form "input" prop.
 */
export const SelectInput = (props: SelectInputProps) => (
        <Input {...props} displayError={false} component={Select}/>
    );

export default SelectInput;
import React from 'react';
import {BaseBarCard} from "../../baseCards";
import {bytesFormatter, colorFunctionSuccess} from "../../../utils";
import {AppsIcon} from "../../icons";
import {useReportingData} from "../../../contexts";
import {ApplicationDataPoint, DashboardCardProps} from "../../../typesAndConstants";
import {BarGridEntry} from "../../statistics/BarGrid";
import {sortBy} from "lodash";
import {Trans} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";

export interface TopAllowedApplicationsTrafficCardProps extends DashboardCardProps {
}

export const TopAllowedApplicationsTrafficCard = (props: TopAllowedApplicationsTrafficCardProps) => {
    const {preview} = props;
    const {data, loading, addFilter} = useReportingData("TopAllowedApplicationsTrafficCard", preview);
    const {applicationDataPoints = []} = data || {};
    const theme = useTheme();

    const maxValue = Math.max(...applicationDataPoints.map((category: ApplicationDataPoint) => category.numberOfBytes));
    const entries: BarGridEntry[] = sortBy(applicationDataPoints, "numberOfBytes").reverse().map((category: ApplicationDataPoint) => ({
        title: category.label,
        value: bytesFormatter(category.numberOfBytes),
        barProgress: (100.0 / maxValue) * category.numberOfBytes,
        color: colorFunctionSuccess((100.0 / maxValue) * category.numberOfBytes, theme),
        onClick: (category.label && addFilter) ? () => addFilter({key: category.id, name: category.label}, "applicationDataPoints") : undefined,
        tooltipMessage: (category.label && addFilter) ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            {...props}
            entries={entries}
            title={
                <Trans i18nKey="stratosReporting.allowedApplicationsTraffic.title">
                    <Typography variant="body1" component="span"/>
                </Trans>
            }
            rowIcon={<AppsIcon size={20}/>}
            loading={loading && !data.applicationDataPoints}
            noData={!loading && !data.applicationDataPoints}
            activeFilter
        />
    );
};
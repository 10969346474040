import React from "react";
import {
    Card,
    CustomField,
    getDataContent,
    ListCardContent,
    PercentageBarChartField,
    StatusIconField,
    TableCardContent,
    TextField,
    useCrudProps
} from "@cuda-react/core";
import {
    AuditLogIcon,
    ConfigUpdatesIcon,
    FirewallIcon,
    GatewayIcon,
    ProcessorIcon,
    ResourceIcon,
    SdWanIcon,
    SecurityIcon,
    UrlCategoriesIcon
} from "@cuda-react/icons";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import {get} from "lodash";


interface DetailsCardProps {
    details: {
        gatewayType?: "on-prem" | "cloud" | "managed";
        id?: string | number;
        activeSerial?: string;
        modelSeries?: "S" | "T";
    };
    type: "gateways" | "sites";
    column?: number;
}

const serviceIcons: { [key: string]: React.ElementType } = {
    "SD WAN": SdWanIcon,
    "Routing": GatewayIcon,
    "Firewall": FirewallIcon,
    "Configuration": ConfigUpdatesIcon,
    "IPS": SecurityIcon,
    "Log Analytics": AuditLogIcon,
    "URL Filtering": UrlCategoriesIcon
};

const colorScheme = (state: string) => (val: number, data: any, theme: any) => {
    let color;
    if (state === "warning") {
        color = theme.palette.warning.main;
    } else if (state === "error") {
        color = theme.palette.error.main;
    } else {
        if (val < 75) {
            color = theme.palette.success.main;
        } else if (val >= 75 && val < 90) {
            color = theme.palette.warning.main;
        } else {
            color = theme.palette.error.main;
        }
    }
    return color;
};

const useStyles = makeStyles((theme) => ({
    resourceErrorRow: {
        height: "auto"
    },
    resourceErrorCell: {
        height: "auto",
        padding: theme.spacing(0, 1)
    },
    resourceErrorField: {
        color: theme.palette.error.main
    },
    serviceIcon: {
        height: "1.5rem",
        width: "1.5rem",
        marginBottom: theme.spacing(-0.8),
        paddingRight: theme.spacing(1),
        position: "relative",
    }
}));

const DetailsCard: React.FC<DetailsCardProps> = ({type, details}) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const gatewayType = details?.gatewayType;
    const diskPartitions = getDataContent(useCrudProps(
        details.activeSerial ? (gatewayType === "on-prem" ? apiResources.configurationOnPremResources : apiResources.configurationResources) : undefined,
        {
            id: details.activeSerial,
            siteId: details.id
        }
    )[0]?.data);
    const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");

    return (type === "sites" && !isSc) || gatewayType === "on-prem" ? (
        <>
            {(!isSc || gatewayType === "on-prem") && (
                <Card
                    id="ApplianceServicesCard"
                    title="tesseract.appliances.dashboard.details.servicesCardTitle"
                >
                    <ListCardContent
                        getFrom={{
                            resource: details.activeSerial ? (gatewayType === "on-prem" ? apiResources.configurationOnPremServices : apiResources.configurationServices) : undefined,
                            params: {id: details.activeSerial, siteId: details.id}
                        }}
                    >
                        <StatusIconField
                            source="state"
                            arraySource="services"
                            textSource="state"
                            textPrefix="cuda.state."
                            labelIcon={({data, ...props}) => {
                                const LabelIcon = serviceIcons[data.name];
                                return LabelIcon ? <LabelIcon className={classes.serviceIcon} {...props} /> : null;
                            }}
                        />
                    </ListCardContent>
                </Card>
            )}
            <Card
                id="ApplianceInterfacesCard"
                title="tesseract.appliances.dashboard.details.interfaces.title"
            >
                <TableCardContent
                    resource={details.activeSerial ? (gatewayType === "on-prem" ? apiResources.configurationOnPremInterfaces : apiResources.configurationInterfaces) : undefined}
                    pollInterval={300000}
                    params={{id: details.activeSerial, siteId: details.id}}
                    formatData={(data) => data?.interfaces || []}
                >
                    <StatusIconField
                        label="tesseract.appliances.dashboard.details.interfaces.status"
                        source="state"
                        tooltipSource="state"
                        tooltipPrefix="tesseract.appliances.dashboard.details.interfaces.state."
                    />
                    <TextField
                        label="tesseract.appliances.dashboard.details.interfaces.name"
                        source="name"
                    />
                    <TextField
                        label="tesseract.appliances.dashboard.details.interfaces.speed"
                        source="speedDescription"
                    />
                    <TextField
                        label="tesseract.appliances.dashboard.details.interfaces.duplex"
                        source="duplex"
                    />
                    <TextField
                        label="tesseract.appliances.dashboard.details.interfaces.errors"
                        source="errors"
                    />
                </TableCardContent>
            </Card>
            <Card
                id="ApplianceResourcesCard"
                title="tesseract.appliances.dashboard.details.boxResources.title"
            >
                <ListCardContent
                    getFrom={{
                        resource: details.activeSerial ? (gatewayType === "on-prem" ? apiResources.configurationOnPremResources : apiResources.configurationResources) : undefined,
                        options: {pollInterval: 60000},
                        params: {id: details.activeSerial, siteId: details.id}
                    }}
                >
                    <PercentageBarChartField
                        label="tesseract.appliances.dashboard.details.boxResources.cpuLoad"
                        source="cpuLoad.minute1"
                        tooltipSource="cpuLoad.description"
                        // @ts-ignore At some point the icons were removed from PrecentageBarChartField, we should restore them
                        icon={<ProcessorIcon/>}
                    />
                    <PercentageBarChartField
                        label="tesseract.appliances.dashboard.details.boxResources.memory"
                        source="memory.usage"
                        tooltipSource="memory.description"
                        // @ts-ignore At some point the icons were removed from PrecentageBarChartField, we should restore them
                        icon={<ResourceIcon/>}
                    />
                    <PercentageBarChartField
                        label="tesseract.appliances.dashboard.details.boxResources.diskInformation"
                        source="totalDiskUsage.usage"
                        tooltipSource="totalDiskUsage.description"
                        // @ts-ignore At some point the icons were removed from PrecentageBarChartField, we should restore them
                        icon={<ResourceIcon/>}
                    />
                    {diskPartitions?.diskPartitionsUsage?.map((value: any, key: any) => (
                        <PercentageBarChartField
                            key={key}
                            label={value.mountpoint}
                            source={`diskPartitionsUsage[${key}].usage`}
                            tooltipSource={`diskPartitionsUsage[${key}].description`}
                            // @ts-ignore At some point the icons were removed from PrecentageBarChartField, we should restore them
                            icon={<ResourceIcon/>}
                            customColorScheme={colorScheme(value && value.state)}
                        />)
                    )}
                    <CustomField
                        noLabel
                        source="cpuUsageState"
                        classes={{root: classes.resourceErrorField}}
                        cellClassName={classes.resourceErrorCell}
                        rowClassName={classes.resourceErrorRow}
                        render={(value) => value === "error" ? translate("tesseract.appliances.dashboard.details.boxResources.cpuLoadError") : null}
                    />
                    <CustomField
                        noLabel
                        source="diskUsageState"
                        classes={{root: classes.resourceErrorField}}
                        cellClassName={classes.resourceErrorCell}
                        rowClassName={classes.resourceErrorRow}
                        render={(value) => value === "error" ? translate("tesseract.appliances.dashboard.details.boxResources.diskInformationError") : null}
                    />
                </ListCardContent>
            </Card>
        </>
    ) : null;
};


export default DetailsCard;
import {useTheme} from "@mui/material";
import {useMemo} from "react";
import {get} from "lodash";
import {getStatusColorFromTheme} from "@cuda-react/theme";

export interface Status {
    name: string,
    key: string,
}

export interface FormattedState {
    title: string,
    value: string,
    color: string,
    link?: string
}

export type UseStatisticsCardDetailsAndTotalHook = (
    statuses: Status[],
    linkCreator?: (status: Status) => string,
    getKeyColor?: (key: string) => string
) => [FormattedState[], (data: any) => number];

export const useStatisticsCardDetailsAndTotal: UseStatisticsCardDetailsAndTotalHook = (statuses, linkCreator, getKeyColor) => {
    const theme = useTheme();
    const [siteStatusDetails, siteStatusTotal] = useMemo(() => [
        statuses.map((status) => ({
            title: status.name,
            value: status.key,
            color: getKeyColor ? getKeyColor(status.key) : getStatusColorFromTheme(status.key, theme),
            link: linkCreator  ? linkCreator(status) : void 0
        })),
        (data:object) => statuses.reduce((total, status) => total + get(data, status.key, 0), 0)
    ], [statuses, theme]);
    return [siteStatusDetails, siteStatusTotal];
};
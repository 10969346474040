import React from "react";
import {BaseBarCard} from "../../baseCards";
import {colorFunctionError, numberFormatter} from "../../../utils";
import {CardSizes, DashboardCardProps, UrlCategoryDataPoint} from "../../../typesAndConstants";
import {useTranslation} from "react-i18next";
import {BarGridEntry} from "../../statistics/BarGrid";
import {useReportingData} from "../../../contexts";
import {sortBy} from "lodash";
import {UrlCategoriesIcon} from "@cuda-react/icons";
import {useTheme} from "@mui/material";

/**
 * Shows a list of blocked url categories and a "progress bar".
 * The progress bar does not actually show the ratio of the values but is dependent on the position of the value.
 */
export const TopBlockedUrlCategoriesCard = (props: DashboardCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("TopBlockedUrlCategoriesCard", preview);
    const {blockedUrlCategoryDataPoints = []} = data || {};
    const maxEntry = Math.max(...blockedUrlCategoryDataPoints.map((category: UrlCategoryDataPoint) => category.numberOfSessions));
    const theme = useTheme();

    const entries: BarGridEntry[] = sortBy(blockedUrlCategoryDataPoints, "numberOfSessions").reverse().map((category: UrlCategoryDataPoint) => ({
        title: category.label,
        value: size === CardSizes.small ?
            numberFormatter(category.numberOfSessions) :
            translate("stratosReporting.topBlockedUrlCategoriesCard.sessions", {
                count: category.numberOfSessions,
                formattedCount: numberFormatter(category.numberOfSessions)
            }),
        icon: <UrlCategoriesIcon size={20}/>,
        barProgress: (100.0 / maxEntry) * category.numberOfSessions,
        color: colorFunctionError((100.0 / maxEntry) * category.numberOfSessions, theme),
        onClick: (category.label && addFilter) ? () => addFilter({key: category.id, name: category.label}, "urlCategoryDataPoints") : undefined,
        tooltipMessage: (category.label && addFilter) ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            entries={entries}
            title="stratosReporting.topBlockedUrlCategoriesCard.title"
            size={size}
            preview={preview}
            loading={loading && !data.blockedUrlCategoryDataPoints}
            noData={!loading && !data.blockedUrlCategoryDataPoints}
            activeFilter
        />
    );
};
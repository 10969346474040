import React from "react";
import {ConnectedWizard, InputStep, ResultStep, SubmitStep, useGlobalParam} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import apiResources from "../../../../apiResources";
import PhasesInput from "./PhasesInput";
import NetworkInput from "./NetworkInput";
import SourceDestInput from "./SourceDestInput";
import GeneralIpSecTunnelInput from "./GeneralIpSecTunnelInput";
import {useGatewaysSites} from "./useGatewaysSites";
import {useTranslation} from "react-i18next";

export const useStyles = makeStyles((theme) => ({
    dialogContent: {
        width: 730,
        backgroundColor: "white"
    }
}));

export const CreateRule = (props: { onClose?: () => void, onSuccess?: () => void }) => {
    const classes = useStyles();
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const {gateways, sites, validate} = useGatewaysSites();
    const [translate] = useTranslation();

    return (
        <ConnectedWizard
            title="tesseract.integration.ipSecV2.create"
            resource={apiResources.ipsecV2}
            dialogBodyProps={{classes: {dialogContent: classes.dialogContent}}}
            formatRequestData={(data) => ({
                ...data,
                virtualWanId,
                local: {
                    ...(data?.local || {}),
                    wan: gateways.some(({uuid, cloud}) => uuid === data?.local?.uuid && cloud) ? "" : data?.local?.wan
                },
                remotes: data?.bgp ? [...data.remotes] : [data.remotes[0]],
                dpd: {
                    delay: data?.dpd?.action === "none" ? "30" : data?.dpd?.delay,
                    action: data?.dpd?.action
                },
                phase2: {
                    ...(data?.phase2 || {}),
                    traffic: data?.phase2?.trafficEnabled ? data?.phase2?.traffic : null
                },
                subnetPairs: data?.bgp ? false : data?.subnetPairs,
                universalTrafficSelectors: data?.bgp ? true : data?.universalTrafficSelectors
            })}
            initialValues={{
                enabled: true,
                bgp: false,
                initiate: true,
                phase1: {
                    encryptionMethod: "aes256",
                    hashMethod: "sha256",
                    dhGroup: "group2",
                    proposalHandling: "strict",
                    lifetime: 28800,
                },
                phase2: {
                    encryptionMethod: "aes256",
                    hashMethod: "sha256",
                    dhGroup: "group2",
                    proposalHandling: "strict",
                    lifetime: 3600,
                    trafficEnabled: false
                },
                reauthentication: true,
                dpd: {
                    action: "restart",
                    delay: "30"
                },
                subnetPairs: false,
                universalTrafficSelectors: false,
                udpEncapsulation: false
            }}
            onClose={props.onClose}
            onSubmitSuccess={props.onSuccess}
            validate={validate}
        >
            <InputStep
                label="tesseract.integration.ipSecV2.general"
            >
                <GeneralIpSecTunnelInput/>
            </InputStep>
            <InputStep
                label="tesseract.integration.ipSecV2.sourceDest"
            >
                <SourceDestInput sites={sites} gateways={gateways}/>
            </InputStep>
            <InputStep
                label="tesseract.integration.ipSecV2.phases"
            >
                <PhasesInput/>
            </InputStep>
            <SubmitStep
                label="tesseract.integration.ipSecV2.network"
            >
                <NetworkInput/>
            </SubmitStep>
            <ResultStep onClose={props.onClose} label="tesseract.integration.ipSecV2.success">
                <Typography>{translate("tesseract.integration.ipSecV2.successMessage")}</Typography>
            </ResultStep>
        </ConnectedWizard>
    );
};

CreateRule.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func
};

export default CreateRule;
import {maxBy, minBy} from "lodash";
import {ChartRef, LocationMapSeriesDefinition} from "./UseLocationsMap";
import {CustomMapPoint} from "./getLinkedPoints";

export type CustomMapPointOrCluster = CustomMapPoint & {
    clusteredData?: CustomMapPoint[],
    data?: CustomMapPoint[],
    source?: LocationMapSeriesDefinition
};

export type MapLatLonPoint = {
    lat: number,
    lon: number
}
/**
 * Zooms the provided chart to auto-fit the provided points
 *
 * @function
 * @param chart
 * @param pointsToFit
 */
export const zoomToFit = (chart: ChartRef, pointsToFit: CustomMapPointOrCluster[], redraw: boolean = false) => {
    if (!pointsToFit || !pointsToFit.length) {
        return;
    }

    // If points don't already have an x value, calculate them from the lat/lon. Also expand points from within clusters
    const getPointXY = (point: CustomMapPoint | MapLatLonPoint) => "x" in point ? point : chart.current?.fromLatLonToPoint?.(point as MapLatLonPoint);
    const parsedPointsToFit = pointsToFit.filter((point) => !!point)
        .flatMap((point) => point?.clusteredData ? point.clusteredData.filter((cluster) => !!cluster).map(getPointXY) : getPointXY(point));

    const paddingCoefficient = 0.2;
    const minWidth = 1000;
    const minHeight = minWidth * (9 / 16); // proportional to the width, at an aspect ratio of 16:9
    const xAxis = chart.current?.xAxis;
    const yAxis = chart.current?.yAxis;

    // allow the map to center on any point without snapping to the nearest tick
    // this ensures the points are in the centre of the view when zoomed in
    const minX = minBy(parsedPointsToFit, (point) => point?.x)?.x || xAxis?.[0]?.min || 0;
    const maxX = maxBy(parsedPointsToFit, (point) => point?.x)?.x || xAxis?.[0]?.max || minWidth;
    const minY = minBy(parsedPointsToFit, (point) => point?.y)?.y || yAxis?.[0]?.min || 0;
    const maxY = maxBy(parsedPointsToFit, (point) => point?.y)?.y || yAxis?.[0]?.max || minHeight;

    const xPadding = (maxX - minX) * paddingCoefficient;
    const yPadding = (maxY - minY) * paddingCoefficient;

    let leftBound = minX - xPadding;
    let rightBound = maxX + xPadding;
    let topBound = minY - yPadding;
    let bottomBound = maxY + yPadding;

    const xRange = rightBound - leftBound;
    const yRange = bottomBound - topBound;

    if (xRange <= minWidth) {
        // ensure the minimum width is adhered to
        const additionalPadding = (minWidth - xRange) / 2;
        leftBound = leftBound - additionalPadding;
        rightBound = rightBound + additionalPadding;
    }
    if (yRange <= minHeight) {
        // ensure the minimum height is adhered to
        const additionalPadding = (minHeight - yRange) / 2;
        topBound = topBound - additionalPadding;
        bottomBound = bottomBound + additionalPadding;
    }

    xAxis?.[0]?.setExtremes(leftBound, rightBound, false);
    yAxis?.[0]?.setExtremes(topBound, bottomBound, redraw);
};
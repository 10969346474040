import React from "react";
import {useDeepCompareEffect} from "../../../hooks";
import {isEqual} from "lodash";

export interface HiddenProps {
    /**
     * callback to called when input's value wants to be updated. Called with the new value as the only argument.
     */
    onChange?: (value: any) => void,
    /**
     * desired value of the hidden input
     */
    hiddenValue?: any,
    /**
     * current value of the input
     */
    value?: any,
}

/**
 * Registers a field on the form, and sets its value to the provided value, without rendering an input to the user.
 *
 * Useful when you want to hide values from the user but still need them always set as part of a form.
 */
export const Hidden = ({hiddenValue, onChange, value}: HiddenProps) => {
    useDeepCompareEffect(() => {
        if (hiddenValue && !isEqual(hiddenValue, value)) {
            onChange?.(hiddenValue);
        }
    }, [hiddenValue, onChange, value]);

    return <React.Fragment/>;
};

export default Hidden;
import React, {useCallback} from "react";
import {useDeepCompareMemo, useDeepCompareMemoize} from "../UtilHooks";
import {GridColumn} from "@progress/kendo-react-grid";
import {CellRender, HeaderCellRender} from "../../utils/commonTypes";

export interface useDataTableSelectProps {
    /**
     * callback used to identify which table rows are selected.
     * Called with each row's data. For each row it returns true is considered selected.
     */
    isSelected?: (row: object) => boolean,
    /**
     * callback called when a row is selected/unselected. Called with the row's data.
     * If not provided row highlighting will not be active.
     */
    onSelect?: (row: object) => void,
    /**
     * callback called when the "select all" is selected/unselected. Called with the table's current data.
     */
    onSelectAll?: (data: object[]) => void,
    /**
     * callback to define if all rows are selected. If provided, the "selection" column has a 'select all' checkbox.
     */
    selectAllState?: (row: object[]) => boolean,
}

type useDataTableSelectReturn = [
    {
        cellRender: CellRender,
        data: object[],
        headerCellRender: HeaderCellRender,
        onHeaderSelectionChange?: (event: object) => void,
        onSelectionChange?: (event: object) => void,
        selectedField?: "_isSelected",
    },
        JSX.Element | null
]

/**
 * Hook for providing row selection functionality to a BDS DataTable (or other tables based on it, such as Table and ConnectedTable).
 *
 * If you already make use of "cellRender" or "headerCellRender", you should instead provide your method to the returned render functions as the third argument. I.e:
 *
 * @example
 * cellRender={(cell, cellProps) => dataTableSelectProps.cellRender(cell, cellProps, yourRenderMethod)}
 * headerCellRender={(cell, cellProps) => dataTableSelectProps.headerCellRender(cell, cellProps, yourRenderMethod)}
 */
export const useDataTableSelect = (
    data: object[],
    props: useDataTableSelectProps
): useDataTableSelectReturn => {
    const {isSelected, onSelect, onSelectAll, selectAllState} = props;

    const cellRender = useCallback((cell, cellProps, additionalRender) => {
        const {field} = cellProps;
        if (field === "_isSelected") {
            return cell;
        }
        return additionalRender ? additionalRender(cell, cellProps) : cell;
    }, []);
    const headerCellRender = useCallback((cell, cellProps, additionalRender) => {
        const {field} = cellProps;
        if (field === "_isSelected" && !onSelectAll) {
            return null;
        }
        return additionalRender ? additionalRender(cell, cellProps) : cell;
    }, [!onSelectAll]);
    const onSelectionChange = useCallback((event) => {
        onSelect?.(event.dataItem);
    }, [onSelect]);
    const onHeaderSelectionChange = useCallback(() => onSelectAll?.(data), [data, onSelectAll]);
    const memoizedData = useDeepCompareMemoize(isSelected ? data.map((row) => ({
        ...row,
        _isSelected: isSelected(row)
    })) : data);
    const dataTableColumn = useDeepCompareMemo(() => isSelected ? (
        <GridColumn
            field="_isSelected"
            key="_isSelected"
            width={64}
            headerSelectionValue={selectAllState?.(data)}
        />
    ) : null, [data, isSelected]);

    return [
        {
            headerCellRender,
            onSelectionChange: onSelect ? onSelectionChange : undefined,
            onHeaderSelectionChange: onSelectAll ? onHeaderSelectionChange : undefined,
            selectedField: isSelected ? "_isSelected" : undefined,
            data: memoizedData,
            cellRender
        },
        dataTableColumn
    ];
};
import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {ConnectedForm, DialogBody, Tab} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import GeneralIpSecTunnelInput from "./GeneralIpSecTunnelInput";
import SourceDestInput from "./SourceDestInput";
import PhasesInput from "./PhasesInput";
import NetworkInput from "./NetworkInput";
import {useGatewaysSites} from "./useGatewaysSites";

export const useStyles = makeStyles((theme) => ({
    dialogContent: {
        width: 730,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

export const EditRule = (props: { id: number, onClose: () => void, onSuccess: () => void }) => {
    const classes = useStyles();
    const {id, onClose, onSuccess} = props;
    const {gateways, sites, validate} = useGatewaysSites();


    return (
        <DialogBody
            title="tesseract.integration.ipSecV2.edit"
            onClose={onClose}
            classes={classes}
            form
        >
            <ConnectedForm
                tabbed
                resource={apiResources.ipsecV2}
                onSubmitSuccess={() => {
                    onSuccess?.();
                    onClose?.();
                }}
                formatRequestData={(data) => ({
                    ...data,
                    local: {
                        ...(data?.local || {}),
                        wan: gateways.some(({uuid, cloud}) => uuid === data?.local?.uuid && cloud)
                            ? "" : data?.local?.wan
                    },
                    remotes: data?.bgp ? [...data.remotes] : [data.remotes[0]],
                    dpd: {
                        delay: data?.dpd?.action === "none" ? "30" : data?.dpd?.delay,
                        action: data?.dpd?.action
                    },
                    phase2: {
                        ...(data?.phase2 || {}),
                        traffic: data?.phase2?.trafficEnabled ? data?.phase2?.traffic : null
                    },
                    subnetPairs: data?.bgp ? false : data?.subnetPairs,
                    universalTrafficSelectors: data?.bgp ? true : data?.universalTrafficSelectors
                })}
                formatResourceData={(data) => ({
                    ...data,
                    phase2: {
                        ...(data?.phase2 || {}),
                        trafficEnabled: !!data?.phase2?.traffic,
                        traffic: data?.phase2?.traffic || ""
                    }
                })}
                params={{id}}
                onCancel={onClose}
                validate={validate}
                allowDirtyNavigation
            >
                <Tab label="tesseract.integration.ipSecV2.general">
                    <GeneralIpSecTunnelInput/>
                </Tab>
                <Tab label="tesseract.integration.ipSecV2.sourceDest">
                    <SourceDestInput gateways={gateways} sites={sites}/>
                </Tab>
                <Tab label="tesseract.integration.ipSecV2.phases">
                    <PhasesInput/>
                </Tab>
                <Tab label="tesseract.integration.ipSecV2.network">
                    <NetworkInput/>
                </Tab>
            </ConnectedForm>
        </DialogBody>
    );
};

EditRule.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    id: PropTypes.number
};

export default EditRule;
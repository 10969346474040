import React from "react";
import {BooleanInput, FormSection} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {get} from "lodash";
import DhcpNetworksTableInput from "../../inputs/DhcpNetworksTableInput";
import DhcpLeasesReservationInput from "../../inputs/DhcpLeasesReservationInput";
import DhcpReservationsTableInput, {getReservationsFormProps} from "../../inputs/DhcpReservationsTableInput";
import {
    getNetworkId,
    useReservationFormValidation,
    useSiteInputValidations
} from "../../../../../hooks/createEditSiteHooks";
import {useWatch} from "react-hook-form";

const useStyles = makeStyles({
    dhcpMacOnlyLabel: {
        width: 256
    }
});


interface DhcpTabProps {
    applianceType?: "site" | "gateway",
    id?: string | number
}

export const DhcpTab: React.FC<DhcpTabProps> = ({applianceType, id}) => {
    const classes = useStyles();
    const lans = useWatch({name: "lans"}) || [];
    const activeLans = lans
        .map((lan: any) => ({...lan, networkId: getNetworkId(lan)}));
    const reservedIps = activeLans.flatMap((lan: any) => get(lan, "dhcp.reservations", [])
        .map((reservation: any) => getNetworkId(lan) + ":" + get(reservation, "ipAddress"))
    );
    const reservableLans = activeLans.filter((lan: any) => get(lan, "dhcp.firstIp") && get(lan, "dhcp.lastIp"));
    const inputValidations = useSiteInputValidations();
    const reservationFormValidation = useReservationFormValidation();

    return (
        <React.Fragment>
            <FormSection title="tesseract.appliances.settings.dhcp.networksSectionTitle">
                <DhcpNetworksTableInput/>
            </FormSection>
            <FormSection title="tesseract.appliances.settings.dhcp.leasesSectionTitle">
                <DhcpLeasesReservationInput
                    applianceType={applianceType}
                    source="lans"
                    id={id}
                    lans={activeLans}
                    {...getReservationsFormProps({
                        reservableLans,
                        inputValidations,
                        reservationFormValidation
                    })}
                    disabled={reservableLans.length < 1}
                    disableReserveButton={(networkId?: string, ip?: string) => reservedIps.includes(networkId + ":" + ip)}
                />
            </FormSection>
            <FormSection title="tesseract.appliances.settings.dhcp.reservationsSectionTitle">
                <BooleanInput
                    source="enableDhcpForKnownMacsOnly"
                    label="tesseract.appliances.settings.dhcp.blockUnknownMac"
                    description="tesseract.appliances.settings.dhcp.descriptions.blockUnknownMac"
                    inputLabelProps={{
                        classes: {inputLabel: classes.dhcpMacOnlyLabel}
                    }}
                />
                <DhcpReservationsTableInput
                    activeLans={activeLans}
                    reservableLans={reservableLans}
                    reservationFormValidation={reservationFormValidation}
                />
            </FormSection>
        </React.Fragment>
    );
};


export default DhcpTab;
import {get, set} from "lodash";
import {getArrayDataContent} from "@cuda-react/core";


const setFilterFromValue = (filter: object, filterKey: string, sourceKey: string, params: any) => {
    const value = get(params, sourceKey);
    if (value) {
        set(filter, filterKey, value);
    }
};

export const formatSourceDestinationFilters = (params: any) => {
    const filter = {};

    setFilterFromValue(filter, "sourceType", "filter.source.type", params);
    setFilterFromValue(filter, "sourceNetwork", "filter.source.sourceNetwork", params);
    setFilterFromValue(filter, "sourceNetwork", "filter.source.sourceIngressNatNetwork", params);
    setFilterFromValue(filter, "sourceSite", "filter.source.sourceSite", params);
    setFilterFromValue(filter, "sourceSite", "filter.source.sourceOnPremGateway", params);
    setFilterFromValue(filter, "sourceApplication", "filter.source.sourceApplication", params);
    setFilterFromValue(filter, "user", "filter.source.user", params);
    setFilterFromValue(filter, "group", "filter.source.group", params);

    setFilterFromValue(filter, "application", "filter.destination.application", params);
    setFilterFromValue(filter, "destinationType", "filter.destination.type", params);
    setFilterFromValue(filter, "destinationSite", "filter.destination.destinationSite", params);
    setFilterFromValue(filter, "destinationSite", "filter.destination.destinationOnPremGateway", params);
    setFilterFromValue(filter, "destinationApplication", "filter.destination.destinationApplication", params);
    setFilterFromValue(filter, "destinationNetwork", "filter.destination.destinationNetwork", params);
    setFilterFromValue(filter, "domain", "filter.destination.domain", params);
    setFilterFromValue(filter, "category", "filter.destination.category", params);
    setFilterFromValue(filter, "destinationCustomCategory", "filter.destination.destinationCustomCategory", params);
    setFilterFromValue(filter, "destinationGatewayId", "filter.destination.destinationGatewayId", params);
    setFilterFromValue(filter, "destinationSiteId", "filter.destination.destinationSiteId", params);

    setFilterFromValue(filter, "customCategory", "filter.rules.customCategory", params);

    if (get(params, "filter.destination.type") === "ingressSite") {
        set(filter, "destinationType",  "site");
    }

    return {
        ...params,
        filter: {
            ...(params.filter || {}),
            ...filter,
            destination: undefined,
            source: undefined
        }
    };
};

export const formatUserGroupInTableData = (rows: any) => getArrayDataContent(rows).map((row) => {
    const users = get(row, "source.users", [])
        .map((user: any) => ({name: user, icon: "user"}));
    const groups = get(row, "source.groups", [])
        .map((group: any) => ({id: group, icon: "group"}));
    return {
        ...row,
        source: {
            ...get(row, "source", {}),
            userOrGroups: users.concat(groups)
        }
    };
});

export const formatGatewaySiteGroupInTableData = (rows: any) => getArrayDataContent(rows).map((row) => {
    const type = get(row, "destination.type", []);
    const applianceType = type === "onPremGateway" ? "gateway" : "site";

    return {
        ...row,
        type: applianceType
    };
});
import Tooltip from "@barracuda-internal/bds-core/dist/Tooltip";
import React from "react";
import {useTranslation} from "react-i18next";
import ConditionalWrapper from "../../functional/ConditionalWrapper/ConditionalWrapper";
import ButtonDialog from "../ButtonDialog/ButtonDialog";
import {Delete} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import DeleteDialogContent, {DeleteDialogContentProps} from "../DeleteDialogContent/DeleteDialogContent";

const styles = (theme: Theme) => createStyles({
    iconDisabled: {
        fill: theme.palette.text.disabled,
        color: theme.palette.text.disabled
    },
    tooltip: {
        ...theme.typography.body2
    }
});
const useStyles = makeOverrideableStyles("DeleteDialog", styles);

export interface DeleteDialogProps extends StyledComponentProps<typeof styles>, Omit<DeleteDialogContentProps, "classes"> {
    /**
     * Callback function for controlling the disabled state of the button
     *
     * @function
     * @param {object} data the "data" prop that as provided to this component.
     * @return {boolean} if truthy, the button is disabled.
     */
    disable?: (data: any) => boolean,
    /**
     * If true, the button is disabled.
     */
    disabled?: boolean,
    /**
     * Optional message to be displayed as an "on hover" tooltip when the button is disabled.
     */
    disabledMessage?: string,
}

/**
 * Creates an icon button which displays a confirmation dialog for deleting resources.
 *
 * On confirmation of the dialog, a delete [CRUD](/?path=/docs/cudareactapp-crud--page) request is submitted
 * against the provided [CRUD](/?path=/docs/cudareactapp-crud--page) resource/params.
 *
 * This behaviour can be overridden by providing your own onDelete function, which is called instead of performing the
 * [CRUD](/?path=/docs/cudareactapp-crud--page) request.
 */
export const DeleteDialog = (props: DeleteDialogProps) => {
    const {disable, disabled, disabledMessage, classes: _overrides, ...deleteDialogContentProps} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);
    const isDisabled = disabled || (!!disable && disable(props.data));

    return (
        <ConditionalWrapper
            condition={disabledMessage && isDisabled || false}
            wrapper={(children) => (
                <Tooltip
                    title={disabledMessage ? translate(disabledMessage) : ""}
                    placement="top"
                    classes={{tooltip: classes.tooltip}}
                >
                    <span>
                        {children}
                    </span>
                </Tooltip>
            )}
        >
            <ButtonDialog
                maxWidth={'sm'}
                buttonIcon={
                    <Delete
                        id="cuda-icon-delete"
                        className={isDisabled ? classes.iconDisabled : undefined}
                    />
                }
                disabled={isDisabled}
                stopPropagation={props.stopPropagation}
            >
                <DeleteDialogContent {...deleteDialogContentProps} />
            </ButtonDialog>
        </ConditionalWrapper>
    );
};

DeleteDialog.defaultProps = {
    optionValue: "id",
    optionText: "name",
    title: "cuda.delete.title",
    message: "cuda.delete.message"
};

export default DeleteDialog;
import React, {ReactElement, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {ColumnField} from "../../components/fields";
import classNames from "classnames";
import {useDeepCompareMemo} from "../UtilHooks";
import {Dialog} from "@barracuda-internal/bds-core";
import {DialogBody} from "../../components/dialog";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {RowRender} from "../../utils/commonTypes";

const rowDetailsStyles = {
    clickableRow: {
        cursor: "pointer"
    }
};
const useRowDetailsStyles = makeOverrideableStyles("DataTableRowDetails", rowDetailsStyles);

export interface useDataTableRowDetailsProps extends StyledComponentProps<typeof rowDetailsStyles> {
    /**
     * if set this will enable the onClick behaviour of each row
     */
    rowDetails?: {
        /**
         * the fields to present the data.
         *
         * The *Field components in cuda-react are all made to seamlessly work with ConnectedTable.
         *
         * @param {number} child.props.column the data is presented in two [Columns](/?path=/docs/core-components-layout-columns--columns), so column attributes should be provided
         */
        fields: ReactElement | (ReactElement | null)[] | null
        /**
         * the text to show as the title of the dialog
         */
        title: string,
    };
}

type useDataTableRowDetailsReturn = [
    {
        /**
         * a function to render the rows of a table within DataTable.
         */
        rowRender: RowRender
    },
    /**
     * The dialog containing details
     */
        ReactElement | null
]

/**
 * Hook for providing the functionality for showing details about a row in a BDS DataTable (or other tables based on it, such as Table and ConnectedTable).
 */
export const useDataTableRowDetails = (props: useDataTableRowDetailsProps): useDataTableRowDetailsReturn => {
    const {rowDetails} = props;
    const classes = useRowDetailsStyles(props);
    const [clickedData, setClickedData] = useState<any>();
    const [translate] = useTranslation();

    const rowRender = useCallback((row, rowProps) => rowDetails
        ? React.cloneElement(row, {
            ...row.props,
            className: classNames(
                row.props.className,
                classes.clickableRow
            ),
            onClick: () => {
                setClickedData(rowProps.dataItem);
            },
            title: translate("cuda.table.rowDetails")
        }) : row, [rowDetails]);

    const detailsDialog = useDeepCompareMemo(() => rowDetails
        ? (
            <Dialog
                disableEnforceFocus
                open={!!clickedData}
                maxWidth="md"
            >
                <DialogBody
                    // @ts-ignore not sure why this is not working
                    title={translate(rowDetails.title, clickedData)}
                    onClose={() => {
                        setClickedData(null);
                    }}
                >
                    <ColumnField autoWidth data={clickedData}>
                        {rowDetails.fields}
                    </ColumnField>
                </DialogBody>
            </Dialog>
        ) : null, [clickedData, rowDetails]);

    return [
        {rowRender},
        detailsDialog
    ];
};
import React, {useEffect} from "react";
import {BooleanField, CustomField, getArrayDataContent, SelectedChoiceField, TableInput, TextField, useCrudProps} from "@cuda-react/core";
import {useNetworkFormValidation} from "../../../../hooks/createEditSiteHooks";
import SCWanTableInputForm from "./SCWanTableInputForm";
import {useFormContext, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";

const SCWanTableInput = () => {
    const [translate] = useTranslation();
    const networkFormValidation = useNetworkFormValidation();
    const wanTypesData = getArrayDataContent(useCrudProps(apiResources.siteModes)[0]?.data);
    const wanTypesChoices = wanTypesData.filter((obj) => obj.key !== "pppoe");
    const {setValue} = useFormContext();
    const [wans = [], firstWanMode, serial] = useWatch({name: ["wans", "wans[0].mode", "serials[0]"]});
    const wansLength = wans.length;
    const fallbackDisabled = firstWanMode === "wwan";
    const noCreate = wansLength === 2 || fallbackDisabled;

    //Ensure that the second is always the fallback, and the first wan is always on group_1
    useEffect(() => {
        wansLength > 0 && setValue("wans[0].provider", "group_1");
        wansLength > 1 && setValue("wans[1].provider", "fallback");
    }, [wansLength]);

    return (
        <TableInput
            source="wans"
            optionValue="name"
            labels={{
                addTitle: "tesseract.appliances.settings.wan.addTitle",
                addButtonText: "tesseract.appliances.settings.wan.addSCButtonText",
                editTitle: "tesseract.appliances.settings.wan.editTitle",
                deleteTitle: "tesseract.appliances.settings.wan.deleteTitle",
                deleteMessage: "tesseract.appliances.settings.wan.deleteMessage"
            }}
            formValidate={(...args) => {
                const errors = networkFormValidation(...args);

                if (errors?.port) {
                    return {
                        ...errors,
                        mode: translate("tesseract.appliances.settings.wan.validateOnePort")
                    };
                }
                return errors;
            }}
            formatFormData={(newData) => ({
                ...newData,
                port: newData.mode === "wwan" ? undefined : newData.port
            })}
            minimumItems={1}
            formChildren={<SCWanTableInputForm wanTypes={wanTypesData} wansLength={wansLength} serial={serial}/>}
            noCreate={noCreate}
        >
            <TextField
                source="name"
                label="tesseract.appliances.settings.wan.name"
            />
            <SelectedChoiceField
                source="mode"
                choices={wanTypesChoices}
                label="tesseract.appliances.settings.wan.type"
            />
            <CustomField
                source="provider"
                label="tesseract.appliances.settings.wan.fallback"
                width={154}
                render={(value) => value === "fallback" ? (
                    <BooleanField
                        data={{value: true}}
                        source="value"
                    />
                ) : null}
            />
        </TableInput>
    );
};

export default SCWanTableInput;
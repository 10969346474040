import React from "react";
import {useTranslation} from "react-i18next";

export type EmailTemplateProps = {
    content?: string,
    username?: string
};

const EmailTemplate: React.FC<EmailTemplateProps> = ({content, username}) => {
  const [translate] = useTranslation();

    return (
        <div>
            <div
                style={{
                    width: 728,
                    background: "white",
                    border: "solid 1px rgba(0,0,0,0.12)",
                    borderRadius: 8,
                    padding: "36px 48px 0px",
                    textAlign: "left"
                }}
            >
                <div style={{marginBottom: 32}}>
                    <p style={{lineHeight: "22px"}}>
                <span
                    style={{
                        fontSize: 22,
                        fontFamily: "\"Helvetica\",sans-serif",
                        color: "#373B45",
                        letterSpacing: -0.05,
                        fontWeight: "bold"
                    }}
                >
                    {translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.hello", {username})}
                </span>
                    </p>
                </div>
                <p style={{marginRight: 0, paddingBottom: 8, marginLeft: 0, lineHeight: "15px"}}>
                <span
                    style={{
                        fontSize: 16,
                        fontFamily: "\"Helvetica\",sans-serif",
                        color: "#383C45",
                        letterSpacing: -0.05
                    }}
                >
                    {translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.message")}
                </span>
                </p>
                {content ? (
                    <p style={{marginRight: 0, paddingBottom: 8, marginLeft: 0, lineHeight: "15px"}}>
                    <span
                        style={{
                            fontSize: 16,
                            fontFamily: "\"Helvetica\",sans-serif",
                            color: "#383C45",
                            letterSpacing: -0.05
                        }}
                    >
                        {content}
                    </span>
                    </p>
                ) : null}
                <p style={{lineHeight: "13.5px"}}>
                <span
                    style={{
                        fontSize: 16,
                        fontFamily: "\"Helvetica\",sans-serif",
                        color: "rgb(6,124,193)",
                        letterSpacing: -0.05
                    }}
                >
                    {translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.clickStart")}
                </span>
                </p>
                <div
                    style={{
                        border: "none",
                        marginTop: 16,
                        borderBottom: "solid #D7D8DA 1.0px",
                        marginRight: 32,
                        height: 460,
                        width: 612,
                        backgroundImage: "url(/images/email_getting_started.svg)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right"
                    }}
                >
                    <div style={{width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.4)"}}>
                        <p style={{lineHeight: "13.5px", paddingTop: 48}}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Start"
                                data-safelink="true"
                                data-linkindex="0"
                                style={{textDecoration: "none", zIndex: 5}}
                            >
                            <span
                                style={{
                                    fontSize: 16,
                                    fontFamily: "\"Helvetica\",sans-serif",
                                    color: "#FFFFFF",
                                    letterSpacing: -0.05,
                                    backgroundColor: "rgb(6,124,193)",
                                    borderRadius: 4,
                                    padding: "8px 80px"
                                }}
                            >
                                {translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.start")}
                            </span>
                            </a>
                        </p>
                        <p style={{marginRight: 0, paddingTop: "16px", marginLeft: 0, lineHeight: "13px"}}>
                            <span style={{
                                fontSize: 12,
                                fontFamily: "\"Helvetica\",sans-serif",
                                color: "#383C45",
                                letterSpacing: -.05
                            }}>
                                {translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.copyLink")}
                            </span>
                        </p>
                        <p style={{lineHeight: "13.5px", marginTop: "-8px"}}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Start"
                                data-safelink="true"
                                data-linkindex="0"
                                style={{zIndex: 5}}
                            >
                                <span style={{
                                    fontSize: 12,
                                    fontFamily: "\"Helvetica\",sans-serif",
                                    color: "#383C45",
                                    letterSpacing: -.05
                                }}>
                                    {translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.exampleLink")}
                                </span>
                            </a>
                        </p>
                    </div>
                </div>
                <div style={{width: "100%", display: "flex", alignItems: "center"}}>
                    <div style={{width: "70%"}}>
                        <p>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                data-auth="NotApplicable"
                                title="Barracuda SecureEdge"
                                data-safelink="true"
                                data-linkindex="1"
                            >
                            <span
                                // @ts-ignore
                                style={{border: "none", windowtext: 1, padding: 0, textDecoration: "none"}}
                            >
                                <img
                                    data-imagetype="External"
                                    src="/images/SecureEdge_Logo.svg"
                                    // @ts-ignore
                                    border="0"
                                    width="105"
                                    height="28.8"
                                    id="x__x0000_i1025"
                                    style={{width: "1.875in", height: ".3541in"}}
                                />
                            </span>
                            </a>
                        </p>
                    </div>
                    <div>
                        <p>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                data-auth="NotApplicable"
                                data-safelink="true"
                                data-linkindex="2"
                                style={{textDecoration: "none"}}
                            >
                                <b>
                                    <span
                                        style={{
                                            fontSize: 12,
                                            color: "#888A8F",
                                            letterSpacing: -0.05,
                                            border: "none",
                                            // @ts-ignore
                                            windowtext: 1
                                        }}
                                    >
                                        barracuda.com
                                    </span>
                                </b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailTemplate;
import React from "react";
import {
    Card,
    ChipArrayField,
    CustomField,
    DateField,
    getArrayDataContent,
    LinkField,
    ListCardContent,
    SelectedChoiceField,
    StatusIconField,
    TableCardContent,
    TextField,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import {StatusIcon} from "@cuda-react/icons";
import {makeStyles} from "@mui/styles";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
import apiResources from "../../../../apiResources";
import {siteGatewayStatusIcons} from "../../../../components/iconMapping";

const useStyles = makeStyles((theme) => ({
    serialIconSpan: {
        lineHeight: 3,
        marginLeft: -7,
        "& svg": {
            position: "relative",
            top: 7,
            marginRight: theme.spacing(1)
        }
    }
}));

interface GatewaySummaryCardProps {
    details: {
        id?: string | number;
        gatewayType?: string;
        gateway?: number;
    };
    column?: number;
}

const GatewaySummaryCard: React.FC<GatewaySummaryCardProps> = ({details}) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]?.data);
    const isOnPremGateway = details.gatewayType === "on-prem";
    const isManagedGateway = details.gatewayType === "managed";
    const licenseStateChoices = getArrayDataContent(useCrudProps(isOnPremGateway ? apiResources.licensesStates : undefined)[0]?.data);
    const licenseTypeChoices = getArrayDataContent(useCrudProps(isOnPremGateway ? apiResources.licensesTypes : undefined)[0]?.data);

    return (
        <>
            <Card
                id="GatewaySummaryCard"
                title="tesseract.appliances.dashboard.summary.status.tabTitle"
            >
                <ListCardContent data={details}>
                    {isOnPremGateway ? (
                        <TextField label="tesseract.appliances.dashboard.summary.siteStatus.model" source="model"/>
                    ) : null}
                    <LinkField
                        source="hubName"
                        label="tesseract.appliances.dashboard.summary.status.hub"
                        link={getAzureHubLink("hubId", isDemo)}
                        filter="text"
                        sortable
                    />
                    <LinkField
                        source="cloudVirtualWan.name"
                        label="tesseract.appliances.dashboard.summary.status.vwan"
                        link={getAzureResourceLink("cloudVirtualWan.resourceId", isDemo)}
                        sortable
                    />
                    <SelectedChoiceField
                        source="region"
                        label="tesseract.appliances.dashboard.summary.status.region"
                        choices={getArrayDataContent(regionChoices)}
                        sortable
                    />
                    <ChipArrayField
                        label={isOnPremGateway ? "tesseract.appliances.dashboard.summary.gateway.serial" : isManagedGateway ? "tesseract.appliances.dashboard.summary.gateway.publicIP" : "tesseract.appliances.dashboard.summary.gateway.publicIPs"}
                        source="connectionStatus.connectionStates"
                        render={(val) => (
                            <span className={classes.serialIconSpan}>
                                <StatusIcon
                                    iconMap={siteGatewayStatusIcons}
                                    status={val.state}
                                />
                                {val.id || translate("tesseract.appliances.dashboard.summary.gateway.instanceNotFound")}
                            </span>
                        )}
                    />
                </ListCardContent>
            </Card>
            {isOnPremGateway && (
                <Card
                    id="GatewayLicencesCard"
                    title="tesseract.appliances.dashboard.summary.licenses.title"
                >
                    <TableCardContent
                        resource={details.id ? apiResources.licensesGateways : undefined}
                        params={{id: details.id}}
                        formatData={(data) => data?.licenseStates || []}
                        minCellWidth={64}
                    >
                        <StatusIconField
                            label="tesseract.appliances.dashboard.summary.licenses.status"
                            source="state"
                        />
                        <TextField
                            label="tesseract.appliances.dashboard.summary.licenses.serial"
                            source="instanceId"
                        />
                        <SelectedChoiceField
                            label="tesseract.appliances.dashboard.summary.licenses.state"
                            source="state"
                            key="textState"
                            choices={licenseStateChoices}
                        />
                        <SelectedChoiceField
                            source="type"
                            label="tesseract.appliances.dashboard.summary.licenses.type"
                            choices={licenseTypeChoices}
                        />
                        <CustomField
                            source="expiryDate"
                            label="tesseract.appliances.dashboard.summary.licenses.expirationDate"
                            render={(expiryDate, data) => !(data.type === "payg" && data.state === "ok")
                                ? <DateField data={data} source="expiryDate"/>
                                : <TextField source="data"
                                             data={{data: translate("tesseract.appliances.dashboard.summary.licenses.noData")}}/>
                            }
                        />
                    </TableCardContent>
                </Card>
            )}
        </>
    );
};

export default GatewaySummaryCard;
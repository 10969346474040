import {CrudParams, CrudSubscribeOptions, CrudTypes, getDataContent, useCrudSubscription} from "@cuda-react/core";

export interface CardFetchProps<ResponseData extends any = any> {
    /**
     * the url to fetch from
     */
    url?: string,
    /**
     * optional CRUD params required for the request.
     */
    params?: CrudParams,
    /**
     * optional CRUD subscription options, to change the behaviour of the refresh intervals etc.
     */
    options?: CrudSubscribeOptions,
    /**
     * optional method for formatting the data returned from the request.
     */
    formatData?: (data?: any) => ResponseData
}

export interface CardFetchResponse<ResponseData extends any = any> {
    data?: ResponseData,
    loading: boolean,
    refresh: () => void,
    error?: any
}

export default <ResponseData extends any = any>({
                                                    url,
                                                    params,
                                                    options,
                                                    formatData
                                                }: CardFetchProps<ResponseData>): CardFetchResponse<ResponseData> => {
    const [response, loading, refresh] = useCrudSubscription(CrudTypes.GET, url, params, options);
    const formattedData = formatData ? formatData(response.data) : getDataContent(response.data);

    return {
        data: formattedData,
        loading,
        refresh,
        error: response.error
    };
};
import React from "react";
import {useTranslation} from "react-i18next";
import {CircularProgress, Typography} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        // @ts-ignore Added by Cuda-react. Default (undefined), is fine
        backgroundColor: theme.palette.background.appbar,
        backgroundImage: "url('https://auth.barracudanetworks.com/img/bg_product-login.jpg')",
        backgroundSize: "cover",
        color: theme.palette.text.primary
    },
    authCheck: {
        fontSize: 32,
        color: theme.palette.primary.contrastText,
        marginBottom: theme.spacing(2)
    },
    text: {
        color: theme.palette.text.primary
    },
    title: {
        "& *": {
            color: theme.palette.primary.contrastText
        },
        backgroundColor: theme.palette.primary.main,
        fontSize: 32,
        fontWeight: 400
    },
    dialogMessage: {
        padding: "17px 7px 20px 7px",
        margin: "0 23px !important"
    },
    actions: {
        // @ts-ignore Added by Cuda-react. Default (undefined), is fine
        backgroundColor: theme.palette.background.navbar
    },
    dialog: {}
});

const useStyles = makeOverrideableStyles("AuthenticatingPage", styles);

export type AuthenticatingPageProps = {}

/**
 * Basic page to display while performing authentication checks.
 *
 * Renders an empty page with authentication message.
 */
export const AuthenticatingPage = (props: AuthenticatingPageProps): JSX.Element => {
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <div className={classes.root} id="login-page">
            <CircularProgress size={56} className={classes.authCheck}/>
            <Typography variant="h1" className={classes.authCheck}>
                {translate("cuda.auth.checking")}
            </Typography>
        </div>
    );
};

export default AuthenticatingPage;
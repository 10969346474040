import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const EndpointIcon = ({className}: {className?: string}) => (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-endpoint"
            viewBox="0 0 60 60"
        >
            <path
                style={{fill: "currentColor", strokeWidth: 0}}
                d="M.2,51.1v-5h9.9v-2.5c-1.4,0-2.5-.5-3.5-1.5s-1.5-2.1-1.5-3.5V13.8c0-1.4.5-2.5,1.5-3.5s2.1-1.5,3.5-1.5h39.8c1.4,0,2.5.5,3.5,1.5,1,1,1.5,2.1,1.5,3.5v24.9c0,1.4-.5,2.5-1.5,3.5-1,1-2.1,1.5-3.5,1.5v2.5h9.9v5H.2ZM10.1,38.7h39.8V13.8H10.1v24.9ZM10.1,38.7V13.8v24.9Z"
            />
            <path
                style={{fill: "currentColor", strokeWidth: 0}}
                d="M30,16.4l-7.5,3.6v5.4c0,5,3.2,9.7,7.5,10.8,4.3-1.1,7.5-5.8,7.5-10.8v-5.4l-7.5-3.6ZM28.3,30.8l-3.3-3.6,1.2-1.3,2.1,2.3,5.5-5.9,1.2,1.3-6.6,7.2Z"
            />
        </SvgIcon>
    );

export default EndpointIcon;
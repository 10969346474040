import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const SitesGraphic = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-sites"
            viewBox="0 0 96 96"
        >
            <g id="ArtBoard11">
                <g id="Ebene-1">
                    <path style={{fill: theme.palette.general?.white, strokeWidth: 0}} d="M68.7,57h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="42.5" y="25.7" width="12.2" height="9.2"/>
                    <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} d="M42.5,56.2h6.9c1.3-2.5,3.1-4.6,5.3-6.3v-12h-12.2v18.3Z"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="24.2" y="44" width="15.3" height="12.2"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="24.2" y="25.7" width="15.3" height="15.3"/>
                    <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                          d="M47.1,65.5c0-.8,0-1.6.2-2.4h-30V18.8h44.4v27.6c1.7-.5,3.5-.7,5.3-.7v-26.1c0-3.4-2.8-6.1-6.1-6.1H18.1c-3.4,0-6.1,2.8-6.1,6.1v42.8c0,3.4,2.8,6.1,6.1,6.1h29.3c-.1-1-.2-1.9-.2-2.9"/>
                    <path style={{fill: theme.palette.success?.main, strokeWidth: 0}}
                          d="M67,48.5c-9.4,0-17,7.6-17,17s7.6,17,17,17,17-7.6,17-17-7.6-17-17-17ZM75.5,67.2h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default SitesGraphic;
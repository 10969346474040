import React from "react";
import {ButtonDialog} from "@cuda-react/core";
import EnableSupportContent from "./EnableSupportContent";

interface EnableSupportDialogProps {
    applianceType?: "gateway" | "site",
    name?: string,
    uuid?: string,
    gatewayType?: "cloud" | "managed" | "on-prem",
    applicationId?: string
}

const EnableSupportDialog: React.FC<EnableSupportDialogProps> = ({applianceType, uuid, name, gatewayType, applicationId}) => (
    <ButtonDialog buttonText="tesseract.appliances.support.buttonText">
        <EnableSupportContent
            applianceType={applianceType}
            uuid={uuid}
            name={name}
            gatewayType={gatewayType}
            applicationId={applicationId}
        />
    </ButtonDialog>
);


export default EnableSupportDialog;
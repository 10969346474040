import {
    ConnectedForm,
    DialogBody,
    FormSection,
    getArrayDataContent,
    HiddenInput,
    SelectArrayInput,
    SelectInput,
    TextArrayInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {categoryIcons, fallbackIcons, priorityIcons} from "../../../../components/iconMapping";
import apiResources from "../../../../apiResources";

/**
 * For now we are using this workaround to get the karma tests working.
 */

interface ExplicitPolicyContentProps {
    title?: string;
    create?: boolean;
    id?: number | string;
    onClose?: () => void | any;
    onSuccess?: () => void | any;
    meta?: {}
}

export const ExplicitPolicyContent: React.FC<ExplicitPolicyContentProps> = ({title, create, id, onSuccess, ...props}) => {
    const registerAction = useMixpanel("SDWAN Explicit", false);
    // TODO: Temporarily disabled "traffic type" as this feature is not working correctly in 8.2.0
    // const trafficTypeData = useCrudProps(apiResources.trafficType, {}, {cache: true})[0]?.data;

    // @ts-ignore
    const priorityData = useCrudProps(apiResources.priority, {}, {cache: true})[0]?.data;
    // @ts-ignore
    const actionData = useCrudProps(apiResources.action, {}, {cache: true})[0]?.data;
    // @ts-ignore
    const fallbackData = useCrudProps(apiResources.fallback, {}, {cache: true})[0]?.data;
    // @ts-ignore
    const loadBalancingData = useCrudProps(apiResources.loadBalancing, {}, {cache: true})[0]?.data;
    const forwardErrorCorrectionData = [
        {"key": "true", "name": "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.options.on"},
        {"key": "false", "name": "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.options.off"}
    ];
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [translate] = useTranslation();

    return (
        <DialogBody
            {...props}
            form
            title={title}
        >
            <ConnectedForm
                create={create}
                flat
                resource={apiResources.sdwanExplicitPolicies}
                id={id}
                // @ts-ignore
                form="explicit-policies"
                onSubmitSuccess={(response) => {
                    registerAction(create ? "Create" : "Update", {
                        applications: response.applications?.length,
                        sources: response.sources?.length
                    });
                    onSuccess?.();
                    // @ts-ignore
                    props.onClose();
                }}
                onCancel={props.onClose}
                validate={(values) => {
                    if ((!values.sources || values.sources.length === 0) && (!values.applications || values.applications.length === 0)) {
                        return {
                            applications: translate("tesseract.policies.dialogs.explicit.validation.required"),
                            sources: translate("tesseract.policies.dialogs.explicit.validation.required")
                        };
                    }
                    return {};
                }}
                formatRequestData={create ? (data) => ({
                    ...data,
                    virtualWanId
                }) : undefined}
                formatResourceData={(data) => ({
                    ...data,
                    forwardErrorCorrection: data.forwardErrorCorrection ? "true" : "false"
                })}
                allowDirtyNavigation
            >
                <FormSection title="tesseract.policies.dialogs.explicit.general.sectionTitle">
                    <TextInput
                        source="name"
                        label="tesseract.policies.dialogs.explicit.general.name"
                        description="tesseract.policies.dialogs.explicit.general.descriptions.name"
                        validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                        isRequired
                    />
                    <TextInput
                        source="description"
                        label="tesseract.policies.dialogs.explicit.general.description"
                        description="tesseract.policies.dialogs.explicit.general.descriptions.description"
                        validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                    />
                </FormSection>
                <FormSection title="tesseract.policies.dialogs.explicit.criteria.sectionTitle">
                    <SelectArrayInput
                        source="applications"
                        label="tesseract.policies.dialogs.explicit.criteria.applications"
                        description="tesseract.policies.dialogs.explicit.criteria.descriptions.applications"
                        resource={apiResources.applications}
                        filterKey="applicationId"
                        iconMap={categoryIcons}
                        optionIcon="categoryId"
                        optionValue="id"
                        options={{dropdownAutoWidth: true}}
                    />
                    <TextArrayInput
                        source="sources"
                        label="tesseract.policies.dialogs.explicit.criteria.sources"
                        description="tesseract.policies.dialogs.explicit.criteria.descriptions.sources"
                    />
                    {/* TODO: Temporarily disabled "traffic type" as this feature is not working correctly in 8.2.0 */}
                    <HiddenInput
                        source="trafficType"
                        hiddenValue="auto"
                    />
                    {/*<SelectInput*/}
                    {/*    source="trafficType"*/}
                    {/*    label="tesseract.policies.dialogs.explicit.criteria.trafficType"*/}
                    {/*    description="tesseract.policies.dialogs.explicit.criteria.descriptions.trafficType"*/}
                    {/*    choices={getArrayDataContent(trafficTypeData)}*/}
                    {/*    iconMap={trafficTypeIcons}*/}
                    {/*    isRequired*/}
                    {/*/>*/}
                </FormSection>
                <FormSection title="tesseract.policies.dialogs.explicit.action.sectionTitle">
                    <SelectInput
                        source="priority"
                        label="tesseract.policies.dialogs.explicit.action.priority"
                        description="tesseract.policies.dialogs.explicit.action.descriptions.priority"
                        choices={getArrayDataContent(priorityData)}
                        iconMap={priorityIcons}
                        isRequired
                    />
                    <SelectInput
                        source="action"
                        label="tesseract.policies.dialogs.explicit.action.action"
                        description="tesseract.policies.dialogs.explicit.action.descriptions.action"
                        choices={getArrayDataContent(actionData)}
                        isRequired
                    />
                    <SelectInput
                        source="fallback"
                        label="tesseract.policies.dialogs.explicit.action.fallback"
                        description="tesseract.policies.dialogs.explicit.action.descriptions.fallback"
                        choices={getArrayDataContent(fallbackData)}
                        iconMap={fallbackIcons}
                        isRequired
                    />
                    <SelectInput
                        source="loadBalancing"
                        label="tesseract.policies.dialogs.explicit.action.loadBalancing"
                        description="tesseract.policies.dialogs.explicit.action.descriptions.loadBalancing"
                        choices={getArrayDataContent(loadBalancingData)}
                        isRequired
                    />
                    <SelectInput
                        source="forwardErrorCorrection"
                        defaultChoice="false"
                        label="tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.label"
                        description="tesseract.policies.dialogs.explicit.action.descriptions.forwardErrorCorrection"
                        choices={forwardErrorCorrectionData}
                        isRequired
                    />
                </FormSection>
            </ConnectedForm>
        </DialogBody>
    );
};

ExplicitPolicyContent.defaultProps = {
    onClose: () => {
    }
};
export default ExplicitPolicyContent;
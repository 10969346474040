import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {TextField, WizardSummary} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    summaryName: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 200
    }
}));

interface SdwanConnectorSubmitStepProps {
    data?: any;
}

const SdwanConnectorSubmitStep: React.FC<SdwanConnectorSubmitStepProps> = ({data}) => {
    const [translate] = useTranslation();
    const classes = useStyles();
    const connector = data?.name;

    return (
        <React.Fragment>
            <Typography>
                {translate("tesseract.network.sdwanConnector.dialog.summary.name", {connector})}
            </Typography>
            <WizardSummary columnCount={1} columns={[{xs: 10, md: 10}]} data={data} stratosSummaryStyle>
                <TextField
                    source="name"
                    label="tesseract.network.sdwanConnector.dialog.summary.connectorName"
                    column={0}
                    className={classes.summaryName}
                    //@ts-ignore used by column field employed within WizardSummary, so missing in the textfield props...
                    borderBottom
                />
                {data?.resources.map((destinationsData: any, index: number, resourcesArray: any[]) => [
                    <TextField
                        key="serviceName"
                        source="serviceName"
                        label="tesseract.network.sdwanConnector.dialog.summary.serviceName"
                        className={classes.summaryName}
                        defaultText={destinationsData.serviceName}
                    />,
                    <TextField
                        key="hostname"
                        source="hostname"
                        label="tesseract.network.sdwanConnector.dialog.summary.hostname"
                        column={0}
                        className={classes.summaryName}
                        defaultText={destinationsData.hostname}
                        //@ts-ignore used by column field employed within WizardSummary, so missing in the textfield props...
                        borderBottom={resourcesArray.length - 1 !== index}
                    />
                ])}
            </WizardSummary>
        </React.Fragment>
    );
};

export default SdwanConnectorSubmitStep;
import React from "react";
import BaseCard from "../BaseCard";
import StatisticsOverview from "../../statistics/StatisticsOverview";
import StatisticsDetail from "../../statistics/StatisticsDetail";
import {CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import {StatisticsTable, StatisticsTableProps} from "../../statistics";
import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
    cardContent: {
        height: "calc(100% - 50px)",
        overflowY: "hidden"
    }
});

/** Defines one entry in the overview section  */
interface OverviewData {
    /** Title of the value */
    title: string,
    /** numerical value */
    value: number,
    /** color of the number */
    color: string,
    /** Icon or any other JSX.Element that will be displayed over the title of the value */
    icon?: JSX.Element,
    /** Link to another page */
    link?: string
}

export interface ResourceStatisticsCardPropsBase extends DashboardCardProps {
    /**
     * A list of different stati e.g. how many devices are offline or online.
     * If undefined the loading the placeholder is shown, this works independently of the table.
     **/
    overViewData: OverviewData[] | undefined,
    /** total sum of the values in data */
    overViewValue: number | undefined,
    /** title of the overview card */
    overviewTitle: string
    /** title of the overview section */
    customOverviewTitle?: string
    /** Title of the table shown in large sizes. If not present, no table will be rendered for any size. */
    tableTitle?: string
}

export type ResourceStatisticsCardProps = (ResourceStatisticsCardPropsBase & {tableTitle?: undefined}) | (ResourceStatisticsCardPropsBase & StatisticsTableProps);

/**
 * Card content that contains statistics for a resource. Renders a large "overview" statistic value, then can be configured
 * to display multiple other overview values.
 *
 * Data can either be provided directly, or collected via crud.
 */
export const ResourceStatisticsCard = (props: ResourceStatisticsCardProps) => {
    const {
        overViewData,
        overViewValue,
        overviewTitle,
        customOverviewTitle,
        size,
        preview,
        ...tableProps
    } = props;
    const showTable = tableProps?.tableTitle && size === CardSizes.large;
    const classes = useStyles(props);

    return (
        <BaseCard title={overviewTitle} size={size} preview={preview} classes={{cardContent: classes.cardContent}}>
            <StatisticsOverview
                overviewTitle={customOverviewTitle ? customOverviewTitle : "stratosReporting.resourceStatisticsCard.overview"}
                overviewValue={overViewValue}
            >
                {Array.isArray(overViewData) && overViewData.map((data: OverviewData) => (
                    <StatisticsDetail
                        title={data.title}
                        value={data?.value}
                        color={data?.color}
                        icon={data?.icon}
                        key={data.title}
                        link={data?.link}
                    />
                ))}
            </StatisticsOverview>
            {showTable && (
                <StatisticsTable {...tableProps}>
                    {tableProps.children}
                </StatisticsTable>
            )}
        </BaseCard>
    );

};

ResourceStatisticsCard.defaultProps = {
    overviewText: "stratosReporting.resourceStatisticsCard.overview",
    overviewValue: "total"
};
import {getStatusColorFromTheme} from "../utils/themeUtils";
import {get} from "lodash";
import {useTheme} from "@mui/material";
import {useMemo} from "react";


export type ApplianceStatusRecord = {
    name: string,
    key: string
}

export type LinkCreator = (status: ApplianceStatusRecord) => string

export type UseStatisticsCardDetailsAndTotalMethod = (statuses: ApplianceStatusRecord[], linkCreator?: LinkCreator) => [
    {
        text: string,
        value: string,
        color: string,
        link: string | undefined
    }[],
    (data: any) => number
];

export const useStatisticsCardDetailsAndTotal: UseStatisticsCardDetailsAndTotalMethod = (statuses: ApplianceStatusRecord[], linkCreator?: LinkCreator) => {
    const theme = useTheme();

    const [siteStatusDetails, siteStatusTotal] = useMemo(() => [
        statuses.map((status) => ({
            text: status.name,
            value: status.key,
            color: getStatusColorFromTheme(status.key, theme),
            link: linkCreator ? linkCreator(status) : undefined
        })),
        (data: any) => statuses.reduce((total, status) => total + get(data, status.key, 0), 0)
    ], [statuses, theme]);

    return [siteStatusDetails, siteStatusTotal];
};
import {Dashboard} from "../../../hooks/useDashboards";
import {
    DialogBody,
    Form,
    SelectInput,
    TextInput,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import React from "react";

export interface AddDashboardDialogProps {
    /** callback called on save. */
    addUpdateDashboard: (newDashboardSettings: Dashboard, originalDashboardName?: string, reset?: boolean) => Promise<any>,
    /** callback called on cancel/dialog close/after saving */
    onClose?: () => void,
    /** not used for this component, but for setting the menu button entry label **/
    label?: string,
    /** templates to select for new dashboard. **/
    templates?: Dashboard[]
}

/**
 * Dialog content for the Create/Edit Dashboard dialog.
 */
export const AddDashboardDialog = ({
                                    addUpdateDashboard,
                                    onClose,
                                    templates
                                }: AddDashboardDialogProps) => (
    <DialogBody
        title="stratosReporting.dashboardPage.addDashboard"
        onClose={onClose}
        form
    >
        <Form
            initialValues={{name: "", cards: []}}
            save={
                (newDashboard: any) => addUpdateDashboard(newDashboard)
                    .then((result) => {
                        onClose?.();
                        return result;
                    })
            }
        >
            <TextInput
                source="name"
                label="stratosReporting.dashboardPage.name"
                isRequired
                validate={[validateMaxLengthMemo(32), validateRegexMemo(/^[a-zA-Z0-9 ]+$/, "stratosReporting.dashboardPage.validateName")]}
            />
            {templates?.length ? (
                <SelectInput
                    source="template"
                    label="stratosReporting.dashboardPage.template"
                    choices={templates}
                    optionValue="name"
                />
            ) : null}
        </Form>
    </DialogBody>
);
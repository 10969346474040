import {makeOverrideableStyles} from "@cuda-react/theme";
import React from "react";
import Columns, {ColumnsProps} from "../../layout/Columns/Columns";
import BasicPage, {BasicPageProps} from "../BasicPage/BasicPage";

const useStyles = makeOverrideableStyles("Dashboard", (theme) => ({
    dashboard: {
        width: `calc(100% + ${theme.spacing(2)})`,
        margin: theme.spacing(-0.5, -1, 0)
    }
}));

export interface DashboardProps extends BasicPageProps {
    /**
     * the content to render in the dashboard page. This is usually Cards, although any content can be rendered. See Columns
     * for more information on what props can be added to the children to control how they layout.
     */
    children: ColumnsProps["children"],
    /**
     * the columns definition, as required by the [Columns](/?path=/docs/core-components-layout-columns--columns) component.
     */
    columns?: ColumnsProps["columns"]
}

/**
 * Creates a [Columns](/?path=/docs/core-components-layout-columns--columns) layout within a [BasicPage](/?path=/docs/core-components-pages-basicpage--basic-page), perfect for use with child Cards for creating a dashboard page.
 *
 * All props provided that are not defined below are passed through to the [BasicPage](/?path=/docs/core-components-pages-basicpage--basic-page) component.
 */
export const Dashboard = ({children, columns, ...props}: DashboardProps): JSX.Element => {
    const classes = useStyles(props);

    return (
        <BasicPage {...props} >
            <Columns columns={columns} className={classes.dashboard}>
                {children}
            </Columns>
        </BasicPage>
    );
};

export default Dashboard;
import {
    CustomField,
    DateField,
    getArrayDataContent,
    SelectedChoiceField,
    TablePage,
    TextField,
    useCrudProps,
    EmptyPageContent
} from "@cuda-react/core";
import {GetStartedBackgroundGraphic, SubscriptionsGraphic} from "@cuda-react/icons";
import React from "react";
import apiResources from "../../../apiResources";
import {useTranslation} from "react-i18next";

const SubscriptionTable = () => {
    const pamProductsData = useCrudProps(apiResources.subscriptionsPamProducts)[0]?.data;
    const [translate] = useTranslation();

    return (
        <TablePage
            authenticated
            resource={apiResources.subscriptionsPam}
            title="tesseract.subscription.pam.pageTitle"
            subtitle="tesseract.subscription.pam.pageSubTitle"
            minCellWidth={144}
            tableName="PamSubscriptionsTable"
            resetOnGlobalParamChange
            resizable
            reorderable
            editColumns
            noDataPage={
                <EmptyPageContent
                    background={<GetStartedBackgroundGraphic/>}
                    icon={<SubscriptionsGraphic/>}
                    iconHeader="tesseract.subscription.pam.table.noData.iconHeader"
                    textHeader="tesseract.subscription.pam.table.noData.textHeader"
                    text="tesseract.subscription.pam.table.noData.text"
                />
            }
        >
            <SelectedChoiceField
                source="product"
                label="tesseract.subscription.pam.table.subscription"
                choices={getArrayDataContent(pamProductsData)}
                optionValue="key"
            />
            <TextField
                source="serial"
                label="tesseract.subscription.pam.table.serial"
                hideByDefault
            />
            <CustomField
                source="amount"
                label="tesseract.subscription.pam.table.amount"
                render={(value, data) => translate(`tesseract.subscription.pam.table.${data.usagePlan === "consumption" ? "msp" : "value"}`, {context: data?.product, value})}
            />
            <DateField
                source="start"
                label="tesseract.subscription.pam.table.startDate"
            />
            <DateField
                source="expiry"
                label="tesseract.subscription.pam.table.expirationDate"
            />
            <TextField
                source="information"
                label="tesseract.subscription.pam.table.information"
            />
        </TablePage>
    );
};

export default SubscriptionTable;
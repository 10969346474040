import React from "react";
import {FormSection} from "@cuda-react/core";
import DhcpNetworksTableInput from "../../inputs/DhcpNetworksTableInput";

interface DhcpTabProps {
    id?: string | number;
}

export const DhcpTab: React.FC<DhcpTabProps> = () => (
    <React.Fragment>
        <FormSection title="tesseract.sites.settings.dhcp.networksSectionTitle">
            <DhcpNetworksTableInput series="S"/>
        </FormSection>
    </React.Fragment>
);


export default DhcpTab;
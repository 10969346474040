import {get} from "lodash";

export const getAzureResourceLink = (key: string, demoMode?: boolean) => (data: any) => {
    if (demoMode) {
        return "https://portal.azure.com";
    }
    const resourceId = get(data, key);
    return resourceId ? "https://portal.azure.com/#@/resource" + resourceId : "";
};

export const getAzureHubLink = (key: string, demoMode?: boolean) => (data: any) => {
    if (demoMode) {
        return "https://portal.azure.com";
    }
    const resourceId = get(data, key);
    return resourceId ? "https://portal.azure.com/#blade/Microsoft_Azure_HybridNetworking/VirtualHubManagementViewModel/overview/virtualHubId/" + encodeURIComponent(resourceId) : "";
};

/**
 * Creates the Azure resource link directly from the resource ID.
 * Useful when the resource ID is directly available as a prop.
 * @param resourceId
 * @param demoMode
 */
export const getAzureResourceLinkFromResourceID = (resourceId?: string, demoMode?: boolean) => {
    if (demoMode) {
        return "https://portal.azure.com";
    }
    return "https://portal.azure.com/#@/resource" + resourceId;
};
import React from "react";
import ButtonDialog from "../../dialog/ButtonDialog/ButtonDialog";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import TableInputAddEditForm, {TableInputAddEditFormProps} from "./TableInputAddEditForm";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    iconDisabled: {
        color: theme.palette.text.disabled
    }
});
const useStyles = makeOverrideableStyles("TableInputAddEditDialog", styles);

export interface TableInputAddEditDialogProps extends StyledComponentProps<typeof styles>, Omit<TableInputAddEditFormProps, "initialValue"> {
    /**
     * data to use.
     * This value is provided by [TableInput](/?path=/docs/core-components-inputs-tableinput--table-input).
     */
    data?: TableInputAddEditFormProps["initialValue"],
    /**
     * if true, the edit ButtonDialog will be disabled for that specific row.
     */
    disabled?: boolean,
    /**
     * text for the edit button.
     */
    buttonText?: string,
    /**
     * if true, an edit ButtonDialog will be rendered for that specific row.
     */
    edit?: boolean
}

/**
 * This component is part of the [TableInput](/?path=/docs/core-components-inputs-tableinput--table-input) component.
 * It renders an edit icon on each entry to be able to edit the row data. Clicking on that button will render a TableInputAddEditForm for that specific row.
 */
export const TableInputAddEditDialog = (props: TableInputAddEditDialogProps) => {
    const {children, data, disabled, buttonText, edit, initialErrors, ...formProps} = props;
    const classes = useStyles(props);

    return (
        <ButtonDialog
            buttonIcon={edit ?
                <Edit id="edit-dialog-icon" className={disabled ? classes.iconDisabled : undefined}/> : undefined}
            buttonText={edit ? undefined : buttonText}
            disabled={disabled}
        >
            <TableInputAddEditForm
                {...formProps}
                initialValue={data}
                initialErrors={data && initialErrors}
            >
                {children}
            </TableInputAddEditForm>
        </ButtonDialog>
    );
};

export default TableInputAddEditDialog;
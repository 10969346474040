import React from "react";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    BooleanCheckListInput,
    BooleanInput,
    ConnectedForm,
    DialogBody,
    getArrayDataContent,
    SelectArrayInput,
    SelectInput,
    TextArrayInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import {settingsIcons} from "../../../../../../components/iconMapping";
import apiResources from "../../../../../../apiResources";
import {get} from "lodash";

export const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        minWidth: 840
    },
    inputRoot: {
        marginLeft: theme.spacing(8),
        width: "calc(100% - " + theme.spacing(16) + ")"
    },
    lastInput: {
        marginBottom: theme.spacing(2)
    }
}));

const hideInput = (key: string, type: string) => (value: any, data: any) => !get(data, key) || get(data, key) !== type;

/**
 * For now we are using this workaround to get the karma tests working.
 */

interface CreateEditUrlFilteringProps {
    create?: boolean;
    id?: string | number;
    onClose?: () => void;
    onSuccess?: () => void;
}

export const CreateEditUrlFiltering: React.FC<CreateEditUrlFilteringProps> = ({create, id, onSuccess, ...props}) => {
    const registerAction = useMixpanel("Web Filter Rule", false);
    // @ts-ignore
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterActions, {}, {cache: true})[0]?.data);
    // @ts-ignore
    const typeChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterTypes, {}, {cache: true})[0]?.data);
    // @ts-ignore
    const categoryChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterCategories, {}, {cache: true})[0]?.data);
    const groupChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterGroups)[0]?.data);
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <DialogBody
            {...props}
            form
            title={create ?
                "tesseract.security.urlFiltering.settings.create.dialogTitle" :
                "tesseract.security.urlFiltering.settings.edit.dialogTitle"
            }
            classes={{dialogContent: classes.dialogContent}}
        >
            <ConnectedForm
                resource={apiResources.urlFilterRules}
                // @ts-ignore
                form="urlFilterRules"
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={(response) => {
                    registerAction(create ? "Create" : "Update", {
                        action: response.action,
                        type: response.type,
                        length: response.categories?.length || response.rules?.length
                    });
                    onSuccess?.();
                    props.onClose?.();
                }}
                onCancel={props.onClose}
                formatRequestData={({categories, rules, customCategories, ...data}) => ({
                    ...data,
                    rules: data.type === "category" ? categories : data.type === "customCategory" ? customCategories : rules,
                    virtualWanId: create ? virtualWanId : data.virtualWanId,
                    appliesTo: create ? "Everyone" : data.appliesTo
                })}
                formatResourceData={({rules, ...data}) => ({
                    ...data,
                    categories: data.type === "category" ? rules : [],
                    rules: data.type === "domain" ? rules : [],
                    customCategories: data.type === "customCategory" ? rules.map((customCategoriesId: string) => parseInt(customCategoriesId)) : []
                })}
                allowDirtyNavigation
            >
                <TextInput
                    source="name"
                    label="tesseract.security.urlFiltering.settings.name"
                    description="tesseract.security.urlFiltering.settings.descriptions.name"
                    // @ts-ignore need to check what class this is tring to overide... because it should work
                    inputProps={{classes: {root: classes.inputRoot}}}
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.security.urlFiltering.settings.description"
                    description="tesseract.security.urlFiltering.settings.descriptions.description"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                    // @ts-ignore need to check what class this is tring to overide... because it should work
                    inputProps={{classes: {root: classes.inputRoot}}}
                />
                <SelectInput
                    source="action"
                    label="tesseract.security.urlFiltering.settings.action"
                    description="tesseract.security.urlFiltering.settings.descriptions.action"
                    iconMap={settingsIcons}
                    choices={actionChoices}
                    inputProps={{classes: {root: classes.inputRoot}}}
                    isRequired
                />
                <BooleanInput
                    source="silent"
                    label="tesseract.security.urlFiltering.settings.silent"
                    description="tesseract.security.urlFiltering.settings.descriptions.silent"
                    disable={hideInput("action", "block")}
                />
                <SelectInput
                    source="type"
                    label="tesseract.security.urlFiltering.settings.type"
                    description="tesseract.security.urlFiltering.settings.descriptions.type"
                    choices={typeChoices}
                    isRequired
                    inputProps={{classes: {root: classes.inputRoot}}}
                />
                <BooleanCheckListInput
                    source="categories"
                    label=" "
                    categoryChoices={categoryChoices}
                    groupChoices={groupChoices}
                    hide={(value, data: any) => data && data.type !== "category"}
                    validate={[(value) => (!value || value.length === 0) ? translate("tesseract.security.urlFiltering.settings.validation.emptyCategories") : undefined]}
                />
                <SelectArrayInput
                    source="customCategories"
                    label="tesseract.security.urlFiltering.settings.customCategories"
                    description="tesseract.security.urlFiltering.settings.descriptions.customCategories"
                    resource={apiResources.customCategories}
                    optionValue="id"
                    // @ts-ignore
                    hide={(value, data) => data && data.type !== "customCategory"}
                    isRequired
                />
                <TextArrayInput
                    source="rules"
                    label="tesseract.security.urlFiltering.settings.domains"
                    description="tesseract.security.urlFiltering.settings.descriptions.domains"
                    // @ts-ignore
                    hide={(value, data) => data && data.type !== "domain"}
                    isRequired
                    // @ts-ignore need to check what root this is trying to set?
                    inputProps={{classes: {root: classes.inputRoot + " " + classes.lastInput}}}
                />
            </ConnectedForm>
        </DialogBody>
    );
};

export default CreateEditUrlFiltering;
import React from "react";
import {ConnectedForm, DialogBody, TextInput,} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import useDemoApi from "../../../../hooks/useDemoApi";

interface CreateEditGroupProps {
    create?: boolean;
    id?: string | number;
    onClose?: () => void;
    onSuccess?: () => void;
}

export const CreateEditGroup: React.FC<CreateEditGroupProps> = ({create, id, onSuccess, ...props}) => (
    <DialogBody
        {...props}
        form
        title={create ?
            "tesseract.identity.groups.create" :
            "tesseract.identity.groups.edit"
        }
    >
        <ConnectedForm
            resource={useDemoApi(apiResources.identityGroups, apiResources.demoSingleGroup)}
            create={create}
            params={create ? undefined : {id}}
            onSubmitSuccess={() => {
                onSuccess && onSuccess();
                props.onClose?.();
            }}
            onCancel={props.onClose}
        >
            <TextInput
                source="name"
                label="tesseract.identity.groups.name"
                isRequired
            />
            <TextInput
                source="description"
                label="tesseract.identity.groups.description"
                isRequired
            />
        </ConnectedForm>
    </DialogBody>
);
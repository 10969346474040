import React, {Fragment} from "react";
import {
    Card,
    DateField,
    EventCardContent,
    ListCardContent,
    LocationsCardContent,
    TextField,
    usePreview,
} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import {useTheme} from "@mui/material";

interface DeploymentCardProps {
    details: {
        id?: number | string;
        serials?: string[];
    };
    type: "gateways" | "sites";
    column?: number;
}

const DeploymentCard: React.FC<DeploymentCardProps> = ({type, details}: DeploymentCardProps) => {
    const [translate] = useTranslation();
    const expertMode = usePreview("expert");
    const theme = useTheme();

    const renderDeploymentCard = (serial: string, multiple: boolean) => (
        <Fragment key={serial}>
            <Card
                id="DeploymentStatusCard"
                title={translate(
                    "tesseract.appliances.dashboard.deployment.status.title",
                    multiple ? {serial} : {serial: ""}
                )}
            >
                <ListCardContent
                    getFrom={{
                        resource: apiResources.applianceDeploymentStatus,
                        params: {id: serial},
                    }}
                >
                    <TextField label="tesseract.appliances.dashboard.deployment.status.status" source="statusName"/>
                    <TextField label="tesseract.appliances.dashboard.deployment.status.action" source="actionName"/>
                    <TextField label="tesseract.appliances.dashboard.deployment.status.message" source="message"/>
                    <DateField label="tesseract.appliances.dashboard.deployment.status.registered" source="registered"/>
                    <DateField label="tesseract.appliances.dashboard.deployment.status.lastSeen" source="lastSeen"/>
                    <DateField label="tesseract.appliances.dashboard.deployment.status.completed" source="completed"/>
                    <TextField label="tesseract.appliances.dashboard.deployment.status.localIp" source="localIp"/>
                    <TextField label="tesseract.appliances.dashboard.deployment.status.remoteIp" source="remoteIp"/>
                </ListCardContent>
            </Card>
            <Card id="DeploymentEventsCard" title="tesseract.appliances.dashboard.deployment.events.title">
                <EventCardContent
                    getFrom={{
                        resource: apiResources.applianceDeploymentEvents,
                        params: {id: serial},
                        options: {
                            pollInterval: 300000,
                        }
                    }}
                    titleKey="status"
                    statusKey="state"
                    dateKey="created"
                    textKey="description"
                    noDataMessage="tesseract.appliances.dashboard.deployment.events.siteDeploying"
                />
            </Card>
            <Card id="DeploymentLocationsCard" title="tesseract.appliances.dashboard.deployment.location.title">
                <LocationsCardContent
                    params={{id: serial}}
                    legendEnabled={false}
                    resource={apiResources.applianceDeploymentLocations}
                    series={[{label: "Location", color: theme.palette.neutral.main}]}
                />
            </Card>
        </Fragment>
    );
    return (
        <>
            {type === "sites" && expertMode && details?.serials?.map((serial) => renderDeploymentCard(serial, (details?.serials?.length || 0) > 1)) || null}
        </>
    );
};

export default DeploymentCard;
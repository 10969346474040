import React from "react";
import {BaseBarCard} from "../../../baseCards";
import {colorFunction, numberFormatter} from "../../../../utils";
import {DashboardCardProps, GeoSourceDataPoint} from "../../../../typesAndConstants";
import {BarGridEntry} from "../../../statistics/BarGrid";
import {useReportingData} from "../../../../contexts";
import {sortBy} from "lodash";
import {NetworkIcon, StratosSupportIcon} from "@cuda-react/icons";
import Flag from "react-world-flags";
import {usei18nCountries} from "../../../../utils/countries";
import {useTheme} from "@mui/material";

/**
 * Shows a list of top users and a "progress bar".
 * The progress bar does not actually show the ratio of the values but is dependent on the position of the value.
 */
export const GeoSourceTopCountries = (props: DashboardCardProps) => {
    const {preview, size} = props;
    const {data, loading, addFilter} = useReportingData("GeoSourceTopCountries", preview);
    const {geoSourceDataPoints = []} = data || {};
    const getCountryNameByCode = usei18nCountries();
    const theme = useTheme();

    const maxValue = Math.max(...geoSourceDataPoints.map((country: GeoSourceDataPoint) => country.numberOfSessions));
    const entries: BarGridEntry[] = sortBy(geoSourceDataPoints, "numberOfSessions").reverse().map((country: GeoSourceDataPoint) => ({
        title: getCountryNameByCode(country.id),
        value: numberFormatter(country.numberOfSessions),
        barProgress: (100.0 / maxValue) * country.numberOfSessions,
        icon: country.id ? (country.id === "ZZ" ? <NetworkIcon/> : (
            <Flag
                code={country.id}
                fallback={<StratosSupportIcon/>}
                style={{
                    width: 40,
                    height: 20,
                    borderRadius: "50%",
                    marginRight: 0,
                    marginLeft: -6,
                    overflow: "hidden",
                    objectFit: "cover",
                    transform: "scaleX(0.50)",
                    flexShrink: 0
                }}
            />
        )) : <StratosSupportIcon/>,
        color: colorFunction((100.0 / maxValue) * country.numberOfSessions, theme),
        onClick: addFilter && country.id !== "" ? () => addFilter(country.id, "geoSourceDataPoints") : undefined,
        tooltipMessage: (addFilter && country.id !== "") ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            entries={entries}
            title="stratosReporting.geoCard.sourceTopCountries.title"
            size={size}
            preview={preview}
            loading={loading && !data.geoSourceDataPoints}
            noData={!loading && !data.geoSourceDataPoints}
            activeFilter
        />
    );
};
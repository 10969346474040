import {CardSizeOptions, CardSizes} from "../../../typesAndConstants";
import {BaseCard, BaseCardProps} from "../BaseCard";
import {DualContainer} from "../../statistics/DualContainer";
import React, {ReactElement} from "react";
import {TotalChart, TotalChartProps} from "../../charts";
import {BarGrid} from "../../statistics/BarGrid";
import {Divider, Grid} from "@barracuda-internal/bds-core";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon";

const styles = (theme: Theme) => createStyles({
    container: {
        width: "100%",
        height: "100%"
    },
    barGridLarge: {
        minHeight: CardSizeOptions[CardSizes.large].height - 180,
        maxHeight: CardSizeOptions[CardSizes.large].height - 180
    },
    divider: {
        margin: theme.spacing(3, 2, 2),
        width: `calc(100% - ${theme.spacing(4)})`
    }
});
const useStyles = makeStyles(styles);

export interface IncidentCardProps extends Omit<BaseCardProps, "children"> {
    /**
     * Entries of the list shown in medium and large mode.
     */
    entries: { title: string, value: string }[],
    /**
     * Icon shown in the next/above the total sum and as list item icon
     */
    icon?: ReactElement<{ fontSize: SvgIconTypeMap["props"] }>,
    /**
     * Total value to display
     */
    total?: TotalChartProps["total"],
    /**
     * if true, loading skeleton bars are displayed instead of data
     */
    loading?: boolean,
    /**
     * Title of the card.
     */
    title: string,
}

export const IncidentBaseCard = (props: IncidentCardProps) => {
    const {size, icon, loading, total, entries} = props;
    const classes = useStyles(props);

    return (
        <BaseCard {...props}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    {size !== CardSizes.medium &&
                        <TotalChart
                            total={total || 0}
                            icon={icon}
                            size={size}
                            loading={loading}
                        />
                    }
                    {size === CardSizes.medium &&
                        <DualContainer>
                            <TotalChart
                                total={total || 0}
                                icon={icon}
                                size={CardSizes.small}
                                loading={loading}
                            />
                            <BarGrid
                                size={CardSizes.small}
                                entries={entries}
                                loading={loading}
                            />
                        </DualContainer>
                    }
                </Grid>
                {size === CardSizes.large && (
                    <>
                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        <Grid item xs={12}>
                            <BarGrid
                                className={classes.barGridLarge}
                                classes={{container: classes.barGridLarge}}
                                size={size}
                                entries={entries}
                                loading={loading}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </BaseCard>
    );
};
import React, {useRef, useState} from "react";
import {
    ConnectedTableRefresh,
    MenuButton,
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    useMixpanel,
    usePostNotification
} from "@cuda-react/core";
import CreateEnrollEmailDialog from "./enroll/CreateEnrollEmailDialog";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import {Dialog} from "@barracuda-internal/bds-core";

interface UsersMenuProps {
    data?: {
        id?: any;
        email?: string;
        uuid?: string;
    };
    enrollmentUrl?: string;
}

const UsersMenu: React.FC<UsersMenuProps> = ({data, enrollmentUrl}) => {
    const registerAction = useMixpanel("Endpoint Users", false);
    const postNotification = usePostNotification();
    const [resendOpen, setResendOpen] = useState(false);
    const copyOnClickRef = useRef<ConnectedTableRefresh>(null);

    return (
        <React.Fragment>
            <MenuButton
                anchorOrigin={{horizontal: "left", vertical: "top"}}
                transformOrigin={{horizontal: "left", vertical: "top"}}
                menuItems={[
                    {
                        label: "tesseract.users.table.resendInvite",
                        onClick: () => setResendOpen(true)
                    },
                    {
                        label: "tesseract.users.table.enrollmentLink",
                        onClick: () => {
                            registerAction("Copy Link");
                            copyOnClickRef?.current?.();
                            postNotification({
                                event: NotificationEventType.SHOW,
                                display: NotificationDisplayType.TOAST,
                                params: {
                                    content: "tesseract.users.table.enrollmentLinkCopied",
                                    level: NotificationLevel.INFO,
                                    duration: 5000
                                }
                            });
                        }
                    }
                ]}
                iconButton
            />
            <Dialog open={resendOpen} maxWidth={false} onClose={() => (setResendOpen(false))}>
                <CreateEnrollEmailDialog id={data?.id} email={data?.email} onClose={() => (setResendOpen(false))}/>
            </Dialog>
            <CopyToClipboardButton
                value={`${document.location.origin}/zerotrust/enroll/?uuid=${data?.uuid}&endpoint=https://${enrollmentUrl}`}
                onClickRef={copyOnClickRef}
                hideButton
            />
        </React.Fragment>
    );
};

export default UsersMenu;
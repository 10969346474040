import {RiskLevels} from "../typesAndConstants";
import {Theme} from "@mui/material";

export const getRiskLevel = (riskNumber: string | number): RiskLevels => {
    const level = typeof riskNumber === "string" ? parseInt(riskNumber) : riskNumber;

    if (Math.round(level) >= 4)
        return RiskLevels.high;
    if (Math.round(level) >= 3)
        return RiskLevels.mediumHigh;
    if (Math.round(level) >= 2)
        return RiskLevels.medium;
    if (Math.round(level) >= 1)
        return RiskLevels.lowMedium;
    return RiskLevels.low;
};

export const getRiskColor = (riskNumber: string | number, theme: Theme): string => theme.palette.risk[getRiskLevel(riskNumber)];
import {Card, ConnectedForm, TimeWindowInput, useMixpanel} from "@cuda-react/core";
import {get, set} from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../../apiResources";
import {parseJson} from "../../../../../utils/jsonUtils";

const SecurityUpdates = () => {
    const [translate] = useTranslation();
    const validateTime = (values: any, key: any, subKey: any, errors: any, errorMsg: any) => {
        if (!get(values, key + "." + subKey, "").match(/^([01][0-9]|2[0-3]):[0-5][0-9]$/)) {
            set(errors, key, translate(errorMsg));
        }
    };
    const registerAction = useMixpanel("Network Settings Security Updates", false);

    return (
        <Card title="tesseract.settings.tabs.updates.security.title">
            <ConnectedForm
                resource={apiResources.settings}
                // @ts-ignore as type definition for form already there
                form="securityUpdates"
                formatRequestData={(data) => (
                    {
                        settings: {
                            security_updates: JSON.stringify(data.settings.security_updates || {})
                        }
                    }
                )}
                formatResourceData={(data) => (
                    {
                        settings: {
                            security_updates: data.settings?.security_updates && parseJson(data.settings.security_updates) || {
                                intervals: [{
                                    from: {
                                        day: "*",
                                        time: "00:00"
                                    }, to: {day: "*", time: "02:00"}
                                }]
                            }
                        }
                    }
                )}
                validate={(values) => {
                    const errors = {};
                    if (values && values.settings) {
                        validateTime(values, "settings.security_updates.intervals[0]", "to.time", errors, "tesseract.settings.tabs.updates.validation.invalidEndTime");
                        validateTime(values, "settings.security_updates.intervals[0]", "from.time", errors, "tesseract.settings.tabs.updates.validation.invalidStartTime");
                    }

                    return errors;
                }}
                params={{filter: {type: "virtualWan"}}}
                flat
                onSubmitSuccess={() => {
                    registerAction("Update");
                }}
            >
                <TimeWindowInput
                    defaults={{from: {time: "00:00"}, to: {time: "02:00"}}}
                    isDaily
                    isRequired
                    label="tesseract.settings.tabs.updates.security.window"
                    description="tesseract.settings.tabs.updates.security.windowDescription"
                    source="settings.security_updates.intervals[0]"
                />
            </ConnectedForm>
        </Card>
    );
};

export default SecurityUpdates;
import {TFunction} from "i18next";
import {Theme} from "@mui/material";
import {get, merge} from "lodash";
import {zoomToFit} from "./zoomToFit";
import {ChartRef, SetLocationTooltip} from "./UseLocationsMap";
import {CustomMapPoint, getLinkedPoints} from "./getLinkedPoints";
import {drawLinkedLines} from "./drawLinkedLines";
import {highlightSourcePoint} from "./highlightSourcePoint";
import {drawLinkedPoints} from "./drawLinkedPoints";

/**
 * Handles point click behaviour:
 *  - Finds linked points, and adds the linked lines and points to the graph.
 *  - Sets the tooltip data
 */
export const handlePointClick = (chart: ChartRef, clickedPoint: CustomMapPoint, setTooltip: SetLocationTooltip, translate: TFunction, theme: Theme) => {
    const point = merge({}, clickedPoint); // This clones the point, as subsequent steps can delete the original object

    // Set tooltip
    setTooltip({element: null, point});

    // Highlight clicked point
    highlightSourcePoint(chart, get(point, "pointData.series", {}), point, setTooltip, translate, theme);

    // Get linked points and generate "linked" series
    const linkedPoints = getLinkedPoints(chart, clickedPoint);
    if (linkedPoints.length) {
        drawLinkedPoints(chart, get(point, "pointData.series", {}), linkedPoints, setTooltip, translate, theme);
        drawLinkedLines(chart, theme, false);
        zoomToFit(chart, [point, ...linkedPoints]);
    }

    // Redraw chart
    chart.current?.redraw();
    return false;
};
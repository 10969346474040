import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {DateTimePicker} from "@mui/x-date-pickers";
import classNames from "classnames";
import {lightFormat, set} from 'date-fns';
import React from "react";
import {formatErrorMessage} from "../../../utils";

const styles = createStyles({
    root: {
        width: 256,
        "& label": {
            fontSize: 14,
            transform: "translate(0px, 0px) scale(1) !important"
        },
        "& fieldset > legend": {
            width: 0
        },
        "& input": {
            cursor: "pointer",
        }
    }
});
const useStyles = makeOverrideableStyles("DateTime", styles);

export interface DateTimeProps extends StyledComponentProps<typeof styles> {
    /**
     * Additional styles for the DateTimePicker
     */
    className?: string,
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * format to render the date.
     */
    format?: string,
    /**
     * label to be displayed above the text box
     */
    label?: string,
    /**
     * id, string that defines the html id of the element ("datetime-input-" + id)
     */
    id?: string,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (value: string) => void,
    /**
     * props to pass into the DateTimePicker component
     */
    pickerProps?: object,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: string | Date,
}

/**
 * Renders a date time selector with the specified format. If value is not defined, current date will be displayed.
 * Note: Dates are formatted in local time (without timezone information)
 *
 * All props will be passed to the DataTimePicker component.
 * Based on the material-ui "DateTimePicker" input.
 */
export const DateTime = (props: DateTimeProps) => {
    const {
        className,
        disabled,
        error,
        format = "yyyy-MM-dd HH:mm", // the format which the user sees the date in
        id,
        label,
        onChange,
        pickerProps,
        value
    } = props;
    const classes = useStyles(props);
    const helperText = formatErrorMessage(error);

    const handleOnChange = (date: any) => {
        // ensure seconds are 0 since they aren't user controllable
        const roundedDate = set(date || Date.now(), {seconds: 0});
        const dateString = lightFormat(roundedDate, "yyyy-MM-dd'T'HH:mm:ss");

        onChange?.(dateString);
    };

    return (
        <DateTimePicker
            slotProps={{
                textField: {
                    helperText,
                    error: !!error,
                    id: id && "datetime-input-" + id
                }
            }}
            label={label}
            format={format}
            className={classNames(classes.root, className)}
            value={(typeof value === "string" ? new Date(value) : value) || null}
            disabled={disabled}
            onChange={handleOnChange}
            ampm={false}
            {...pickerProps}
        />
    );
};

export default DateTime;
import React from "react";
import {Grid, Link, Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";
import classNames from "classnames";
import {CustomField, getArrayDataContent, useCrudProps} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {useWatch} from "react-hook-form";
import apiResources from "../../../../../apiResources";

const useStyles = makeStyles((theme) => ({
    siteCreated: {
        textAlign: "center",
        marginBottom: theme.spacing(2)
    },
    message: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    serial: {
        textAlign: "center",
        paddingTop: 12
    },
    warning: {
        color: theme.palette.warning.main
    },
}));

interface SiteResultStepProps {
    applianceType?: "site" | "gateway",
    data?: {
        test?: string
    }
}

const defaultPorts = ["p2", "p3", "p4", "p5"];

const SiteResultStep: React.FC<SiteResultStepProps> = ({applianceType, data}) => {
    const [model, wans, lans] = useWatch({name: ["serials.0.model", "wans", "lans"]});
    const portsData = getArrayDataContent(useCrudProps(apiResources.modelPorts, {id: model})[0]?.data);
    const nonDefaultPorts = [...wans, ...lans]
        .map((link) => link && link.port)
        .filter((port) => !defaultPorts.includes(port));
    const nonDefaultPortNames = portsData
        .filter((appliancePort) => nonDefaultPorts.includes(appliancePort.port))
        .map((port) => port.name);
    const classes = useStyles();
    const [translate] = useTranslation();
    const documentationLink = applianceType === "site" ? "https://campus.barracuda.com/doc/98223578/" : "https://campus.barracuda.com/doc/98223586/";

    return (
        <CustomField
            source="serials"
            data={data}
            render={(value) => {
                const valueAsArray = value && (Array.isArray(value) ? value : [value]);
                return (
                    <Grid container direction="column">
                        <Grid item>
                            <Typography className={classes.siteCreated} variant="h5">
                                {translate("tesseract.appliances.dialog.result.success", {context: applianceType})}
                            </Typography>
                            <Typography className={classes.message} variant="body1">
                                <Trans i18nKey="tesseract.appliances.dialog.result.connectPort"
                                       count={valueAsArray.length}
                                       values={model === "T93A" ? {port: "P2"} : {port: "P4"}}>
                                    <strong/>
                                </Trans>
                            </Typography>
                            {valueAsArray.length > 1 && (
                                <Typography className={classes.message} variant="body1">
                                    <Trans i18nKey="tesseract.appliances.dialog.result.HAMessage">
                                        <strong/>
                                    </Trans>
                                </Typography>
                            )}
                            <Typography className={classes.message} variant="body1">
                                <Trans i18nKey="tesseract.appliances.dialog.result.campus">
                                    <Link href={documentationLink} target="_blank" rel="noopener"/>
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            {valueAsArray.map((appliance: any) => (
                                <Typography variant="h6" className={classes.serial} key={appliance.serial}>
                                    {translate("tesseract.appliances.dialog.result.serial", {serial: appliance.serial})}
                                </Typography>
                            ))}
                        </Grid>
                        {(nonDefaultPortNames && nonDefaultPortNames.length) ? (
                            <Grid item>
                                <Typography className={classNames(classes.message, classes.warning)} variant="body1">
                                    <Trans
                                        i18nKey="tesseract.appliances.dialog.result.nonstandardPort"
                                        count={nonDefaultPortNames.length}
                                        values={{ports: nonDefaultPortNames.join(", ")}}
                                    >
                                        <strong/>
                                    </Trans>
                                </Typography>
                            </Grid>
                        ) : null}
                    </Grid>
                );
            }}
        />
    );
};


export default SiteResultStep;
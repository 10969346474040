import React from "react";
import {
    BooleanInput,
    ConnectedFormPage,
    FormSection,
    getArrayDataContent,
    PropGateway,
    TextArrayInput,
    useCrudProps,
    useMixpanel,
    validateArray,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import apiResources from "../../../../apiResources";
import SimpleBooleanCheckListInput from "./SimpleBooleanChecklistInput";
import {parseJson} from "../../../../utils/jsonUtils";

const useStyles = makeStyles((theme: Theme) => ({
    helpText: {
        padding: theme.spacing(1, 1, 4, 3),
        color: theme.palette.text.secondary
    }
}));

const WebMonitorPolicies = () => {
    const registerAction = useMixpanel("Web filter configuration");
    const classes = useStyles();
    const categoryChoices = getArrayDataContent(useCrudProps(apiResources.webMonitorPolicies)[0]);

    return (
        <ConnectedFormPage
            resource={apiResources.settings}
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.webFilter", "tesseract.securityAndAccess.webMonitorPolicies"]}
            formatRequestData={(data) => (
                {
                    settings: {
                        web_monitor_policies: JSON.stringify(data)
                    }
                }
            )}
            formatResourceData={(data) => {
                const webMonitorPolicies = parseJson(data?.settings?.web_monitor_policies || "{}");
                return {
                    enabled: false,
                    categories: [],
                    custom_keywords: [],
                    ...webMonitorPolicies
                };
            }}
            params={{filter: {type: "virtualWan"}}}
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
            canReset
        >
            <FormSection title="tesseract.webMonitorPolicies.webMonitoringTitle" newStyle hideBorder>
                <PropGateway>
                    <Typography className={classes.helpText}>
                        <Trans i18nKey="tesseract.webMonitorPolicies.webConfigHelpText">
                            <br/>
                        </Trans>
                    </Typography>
                </PropGateway>
                <BooleanInput
                    source="enabled"
                    label="tesseract.webMonitorPolicies.enabled"
                    toggleEnabledText="tesseract.webMonitorPolicies.toggleEnabled"
                    newStyle
                />
            </FormSection>
            <FormSection title="tesseract.webMonitorPolicies.keywordCategoriesTitle" newStyle>
                <Typography className={classes.helpText}>
                    <Trans i18nKey="tesseract.webMonitorPolicies.keywordCategoriesHelpText">
                        <a href="https://campus.barracuda.com/product/secureedge/doc/169967891/how-to-configure-web-monitoring-in-barracuda-secureedge/" rel='noopener noreferrer' target='_blank'/>
                    </Trans>
                </Typography>
                <SimpleBooleanCheckListInput
                    source="categories"
                    label=""
                    categoryChoices={categoryChoices}
                    disable={(value, data) => !data?.enabled}
                />
            </FormSection>
            <FormSection title="tesseract.webMonitorPolicies.customKeywordTitle" newStyle>
                <PropGateway>
                    <Typography className={classes.helpText}>
                        <Trans i18nKey="tesseract.webMonitorPolicies.customKeywordHelpText"/>
                    </Typography>
                </PropGateway>
                <TextArrayInput
                    source="custom_keywords"
                    label="tesseract.webMonitorPolicies.customKeywords"
                    disable={(value, data) => !data?.enabled}
                    validate={[validateArray([validateMaxLengthMemo(128), validateRegexMemo(/^[a-zA-Z0-9](([a-zA-Z0-9.' -]{0,126})[a-zA-Z0-9])?$/, "tesseract.validation.customKeyword")])]}
                    newStyle
                />
            </FormSection>
        </ConnectedFormPage>
    );
};


export default WebMonitorPolicies;
import {get} from "lodash";
import PropTypes from "prop-types";
import {getDataContent} from "../utils";
import {CrudSubscribeOptions, useCrudSubscription} from "./index";
import {useEffect} from "react";
import {CrudTypes} from "../clients";


const resolveGetFromValue = <Data, Props extends object, Value>(path: string, getFrom?: getFrom<Data, Props>, defaults?: getFrom<Data, Props>, props?: Props): Value => {
    const value = get(getFrom, path) || get(props, path) || get(defaults, path);
    return typeof value === "function" ? value(props) : value;
};

// TODO: This can go away one all consumers are swapped to using the types instead
export const cardGetFromPropTypes = PropTypes.shape({
    crudType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    resource: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    params: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])), PropTypes.object]),
    formatData: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
        debounceWait: PropTypes.number,
        crudOptions: PropTypes.shape({
            idKey: PropTypes.string,
            quietErrors: PropTypes.bool,
        }),
        pollInterval: PropTypes.number
    })])
});

export type getFrom<Data, Props extends object> = {
    /**
     * useCrudSubscription crudType value (or callback to get value).
     */
    crudType?: CrudTypes | ((props: Props) => CrudTypes),
    /**
     * useCrudSubscription resource value (or callback to get value).
     */
    resource?: string | ((props: Props) => string),
    /**
     * useCrudSubscription params value (or callback to get value).
     */
    params?: object | ((props: Props) => object),
    /**
     * locally supplied data to use if no resource is set.
     */
    data?: Data,
    /**
     * function to reformat the data returned by the CRUD request, prior to use.
     */
    formatData?: (data: any) => Data,
    /**
     * optional options for configuring the useCrudSubscription hook (or callback to get value).
     */
    options?: CrudSubscribeOptions,
    /**
     * the lodash dot path to a target within the rawData.data that should be used as the status when present.
     */
    statusAvatarSource?: string,
    /**
     * the lodash dot path to a target within the each entry in arrayData that should be used to calculate the aggregate status when present.
     */
    statusAvatarAggregateSource?: string,
};
export type getFromSupportedProps<Data, Props extends object> = getFrom<Data, Props> & {
    /**
     * callback called whenever the global status changes. This is usually provided by TabbedCard.
     */
    updateState?: (state: any) => void
} & object;

/**
 * Simple hook that sets up a crud subscription and returns the data.
 *
 * A crud subscription is a request that is also repeated after any successful state-changing request is made to the same
 * resource, or any of the supplied listenResources.
 *
 * It can also be optionally set to have a poll interval to repeat after a set time if not already repeated.
 * In the provided object each option should provide either the option value or a function that can be called with props to return the value
 *
 * This hook also calls useStatusAvatar, passing in any statusAvatarSource, statusAvatarAggregateSource and updateFunction that it finds in either getFrom or props.
 *
 * @function
 * @param getFrom the getFrom props as provided to the component, defining the CRUD request.
 * @param props the current component's props, provided to callbacks in getFrom, and is also used to get the getFrom props if not defined in the main getFrom.
 * @param defaults default values to use if not set via getFrom or props.
 * @returns crudResult the [content, loading, error, status] details of the CRUD request
 */
export const useCardGetFrom = <Data, Props extends getFromSupportedProps<Data, Props> = {}>(getFrom?: getFrom<Data, Props>, props?: Props, defaults?: getFrom<Data, Props>): [any, boolean, any, any] => {
    // Call subscription and get data
    const formatData = get(getFrom, "formatData") || get(defaults, "formatData") || get(props, "formatData");
    const [data, loading] = useCrudSubscription(
        resolveGetFromValue<Data, Props, CrudTypes>("crudType", getFrom, defaults, props) || CrudTypes.GET,
        resolveGetFromValue<Data, Props, string>("resource", getFrom, defaults, props),
        resolveGetFromValue<Data, Props, object>("params", getFrom, defaults, props) || {},
        resolveGetFromValue<Data, Props, CrudSubscribeOptions>("options", getFrom, defaults, props)
    );
    const retrievedData = getDataContent(data.data);
    const providedData = retrievedData ? retrievedData : resolveGetFromValue("data", getFrom, defaults, props);
    const content = formatData ? formatData(providedData) : providedData;

    const status = useStatusAvatar(
        data,
        content,
        loading,
        resolveGetFromValue("statusAvatarSource", getFrom, defaults, props),
        resolveGetFromValue("statusAvatarAggregateSource", getFrom, defaults, props),
        props && props.updateState
    );

    return [content, !!loading, data && data.error, status];
};

/**
 * Hook for managing the status of a dataset.
 *
 * Finds the current status of a dataset, using the provided source/s, and calls the provided updateState function with the result if changed.
 *
 * @function
 * @param rawData the raw data, as returned from the CRUD hooks (i.e. it can contain both the data and the error object).
 * @param arrayData the data after formatting down to an array (if applicable).
 * @param loading the CRUD loading status.
 * @param statusAvatarSource the lodash dot path to a target within the rawData.data that should be used as the status when present.
 * @param statusAvatarAggregateSource the lodash dot path to a target within the each entry in arrayData that should be used to calculate the aggregate status when present.
 * @param updateState callback called with the calculate status on each change in status.
 * @returns the calculated status
 */
export const useStatusAvatar: (
    rawData?: {
        /**
         * the data content from successful CRUD request response.
         */
        data?: any,
        /**
         * the error content from failed CRUD request response
         */
        error?: any
    },
    arrayData?: any[],
    loading?: boolean,
    statusAvatarSource?: string,
    statusAvatarAggregateSource?: string,
    updateState?: (state: any) => void
) => any = (rawData, arrayData, loading, statusAvatarSource, statusAvatarAggregateSource, updateState) => {
    // Calculate status for avatar
    let statusAvatarValue: any;

    if (statusAvatarSource || statusAvatarAggregateSource) {
        if (rawData && rawData.error) {
            statusAvatarValue = "error";
        } else if (statusAvatarSource) {
            statusAvatarValue = get(rawData && rawData.data, statusAvatarSource) || (loading ? "loading" : "unknown");
        } else if (statusAvatarAggregateSource && arrayData && Array.isArray(arrayData)) {
            statusAvatarValue = arrayData.some((item) => {
                const state = get(item, statusAvatarAggregateSource);
                return state !== "successful" && state !== "ok";
            }) ? "error" : "ok";
        } else {
            statusAvatarValue = loading ? "loading" : undefined;
        }
    }

    // Update status for avatar
    useEffect(() => {
        updateState && updateState(statusAvatarValue);
    }, [statusAvatarValue]);

    return statusAvatarValue;
};
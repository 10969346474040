import {
    ConnectedForm,
    ConnectedWizard,
    DialogBody,
    getArrayDataContent,
    InputStep,
    ResultStep,
    SubmitStep,
    useCrudProps,
    useGlobalParam,
    useMixpanel
} from "@cuda-react/core";
import React, {useCallback} from "react";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../apiResources";
import SdwanConnectorGeneralStep
    from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorGeneralStep";
import SdwanConnectorServicesStep
    from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorServicesStep";
import SdwanConnectorSubmitStep
    from "../../../../components/appliances/create/steps/sdwanConnector/./SdwanConnectorSubmitStep";
import SdwanConnectorPoolNetworkStep
    from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorPoolNetworkStep";
import SdwanConnectorResultStep
    from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorResultStep";
import {Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        width: 850
    },
    completeMessage: {
        margin: theme.spacing(3)
    }
}));

interface CreateSdwanConnectorProps {
    onClose?: () => void,
    onSuccess?: any
}

export const CreateSdwanConnector: React.FC<CreateSdwanConnectorProps> = ({onClose, onSuccess}) => {
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const registerAction = useMixpanel("SD-WAN Connector Create SD-WAN Connector", "Start", {});
    const classes = useStyles();
    const [{data: connectedDataNetwork}, loadingNetwork, fetchDataNetwork] = getArrayDataContent(useCrudProps(apiResources.sdwanConnectorNetwork));
    const emptyPool = !connectedDataNetwork?.content?.clientNetworkConfiguration?.range;
    const [translate] = useTranslation();

    const onSuccessHandler = useCallback((response) => {
        registerAction("Success");
        onSuccess?.(response);
    }, []);

    return (
        <React.Fragment>
            {emptyPool && !loadingNetwork ?
                <DialogBody
                    onClose={onClose}
                    form
                    title="tesseract.network.sdwanConnector.dialog.poolNetwork.sectionTitle"
                    classes={{dialogContent: classes.dialogContent}}
                >
                    <Typography variant="body1" className={classes.completeMessage}>
                        {translate("tesseract.network.sdwanConnector.dialog.poolNetwork.descriptionMessage")}
                    </Typography>
                    <ConnectedForm resource={apiResources.sdwanConnectorNetwork} initialValues={{virtualWanId}}
                                   onSubmitSuccess={fetchDataNetwork}>
                        <SdwanConnectorPoolNetworkStep/>
                    </ConnectedForm>
                </DialogBody> :
                <ConnectedWizard
                    title={`tesseract.network.sdwanConnector.dialog.create.dialogTitle`}
                    dialogBodyProps={{classes: {dialogContent: classes.dialogContent}}}
                    resource={apiResources.sdwanConnector}
                    formatRequestData={(data) => ({
                        ...data,
                        virtualWanId,
                        configurationType: "gateway"
                    })}
                    onClose={onClose}
                    onSubmitSuccess={onSuccessHandler}
                >
                    <InputStep label="tesseract.network.sdwanConnector.dialog.general.sectionTitle">
                        <SdwanConnectorGeneralStep/>
                    </InputStep>
                    <InputStep label="tesseract.network.sdwanConnector.dialog.servers.sectionTitle">
                        <SdwanConnectorServicesStep create/>
                    </InputStep>
                    <SubmitStep key="submit" label="tesseract.network.sdwanConnector.dialog.summary.sectionTitle">
                        <SdwanConnectorSubmitStep/>
                    </SubmitStep>
                    <ResultStep label="tesseract.appliances.dialog.steps.result" onClose={onClose}>
                        <SdwanConnectorResultStep/>
                    </ResultStep>
                </ConnectedWizard>
            }
        </React.Fragment>
    );
};


export default CreateSdwanConnector;
import React, {useEffect} from "react";
import {ConnectedTable} from "../../table";
import {getArrayDataContent} from "../../../utils";
import {CrudTypes} from "../../../clients/crudClient";
import {useCrudFetch, useStatusAvatar} from "../../../hooks";
import {ConnectedTableProps} from "../../table/ConnectedTable/ConnectedTable";

export interface TableCardContentProps extends ConnectedTableProps {
    /**
     * A dot-path key to a properties in the returned data array that defines the overall status of the data. This status is then used to call the
     * updateState callback (if provided). The worst status found is always used.
     */
    statusAvatarAggregateSource?: string,
    /**
     * A dot-path key to a property in the returned data that defines the overall status of the data. This status is then used to call the
     * updateState callback (if provided).
     */
    statusAvatarSource?: string,
    /**
     * callback called whenever the global status changes. This is usually provided by TabbedCard.
     */
    updateState?: (state: any) => void,
    /**
     *  if false, content is not rendered (although CRUD data is still collected).
     *
     *  This prop is passed down by [TabbedCard](/?path=/docs/core-components-cards-tabbedcard--tabbed-card) when tab is not currently selected.
     */
    visible?: boolean
}

/**
 * Card content that contains a [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table).
 *
 * Implements [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table), passing through all additional props along with "noRouter", "flat" and "compact".
 */
export const TableCardContent = (props: TableCardContentProps) => {
    const {children, statusAvatarSource, statusAvatarAggregateSource, resource, params, updateState, visible = true, ...tableProps} = props;
    const [data, status, performFetch] = useCrudFetch(CrudTypes.GET, resource, params, {quietErrors: true});
    useStatusAvatar(
        data,
        props.formatData ? props.formatData(getArrayDataContent(data)) : getArrayDataContent(data),
        status,
        statusAvatarSource,
        statusAvatarAggregateSource,
        updateState
    );

    // On first render, if the table wont be rendering then fetch data for the status icon
    useEffect(() => {
        !visible && resource && performFetch();
    }, [resource]);

    return visible ? (
        <ConnectedTable
            resource={resource}
            params={params}
            flat
            compact
            noRouter
            {...tableProps}
        >
            {children}
        </ConnectedTable>
    ) : null;
};

export default TableCardContent;
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {ButtonDialog} from "@cuda-react/core";
import React from 'react';
import UpdateDialogContent from "./UpdateDialogContent";

interface Appliance {
    key: number;
    name: string;
    type: "site" | "gateway" | string;
}

interface EditUpdateDialogProps {
    data?: object;
    appliances: Appliance[];
    onSuccess?: () => void;
    availableUpdates: Record<string, any>;
}

const EditUpdateDialog: React.FC<EditUpdateDialogProps> = ({data, appliances, onSuccess, availableUpdates}) => (
    <ButtonDialog buttonIcon={<Edit id="update-window-edit-icon"/>}>
        <UpdateDialogContent
            data={data}
            appliances={appliances}
            title="tesseract.settings.tabs.updates.firmware.edit.dialogTitle"
            onSuccess={onSuccess}
            availableUpdates={availableUpdates}
        />
    </ButtonDialog>
);


export default EditUpdateDialog;
import {Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {CustomField, TableInput, TextField} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import {
    getNetworkNameFromPorts,
    useNetworkFormValidation,
    useSiteInputValidations
} from "../../../../hooks/createEditSiteHooks";
import SCLanTableInputForm from "./SCLanTableInputForm";


interface LanTableInputProps {
    portsData?: any
}

const LanTableInput: React.FC<LanTableInputProps> = ({portsData}) => {
    const [translate] = useTranslation();
    const networkFormValidation = useNetworkFormValidation();
    const getNetworkName = getNetworkNameFromPorts(portsData);
    const inputValidations = useSiteInputValidations();

    return (
        <TableInput
            source="lans"
            optionValue="name"
            labels={{
                addTitle: "tesseract.sites.settings.lan.addTitle",
                addButtonText: "tesseract.sites.settings.lan.addButtonText",
                editTitle: "tesseract.sites.settings.lan.editTitle",
                deleteTitle: "tesseract.sites.settings.lan.deleteTitle",
                deleteMessage:
                    <Typography>
                        <div>
                            {translate("tesseract.sites.settings.lan.deleteMessage1")}
                        </div>
                        <div>
                            {translate("tesseract.sites.settings.lan.deleteMessage2")}
                        </div>
                        <div>
                            {translate("tesseract.sites.settings.lan.deleteMessage3")}
                        </div>
                        <p>
                            {translate("tesseract.sites.settings.lan.deleteMessage4")}
                        </p>
                    </Typography>
            }}
            formValidate={networkFormValidation}
            minimumItems={1}
            defaultFormValues={{dhcp: {type: "disabled"}}}
            minCellWidth={128}
            formChildren={<SCLanTableInputForm portsData={portsData}/>}
        >
            <TextField
                source="name"
                label="tesseract.sites.settings.lan.name"
            />
            <CustomField
                source="port"
                label="tesseract.sites.settings.lan.port"
                render={(value, data) => getNetworkName(data)}
            />
            <TextField
                source="address"
                label="tesseract.sites.settings.lan.address"
            />
            <TextField
                source="netmask"
                label="tesseract.sites.settings.lan.netmask"
            />
        </TableInput>
    );
};


export default LanTableInput;
import {
    ChipArrayField,
    CrudTypes,
    DateField,
    getArrayDataContent,
    getDataContent,
    StatusIconField,
    TablePage,
    TextField,
    useCrudProps,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import React, {memo, useEffect, useState} from "react";
import {logsIconMap, logsTrafficStatesIconMap} from "../../../../components/iconMapping";
import apiResources from "../../../../apiResources";
import {isString} from "lodash";

interface LiveLogProps {
    id?: number | string,
    messagesType?: string,
    resource?: string,
    serial?: number | string,
    tableName?: string,
    details: {
        gatewayType?: "cloud" | "on-prem" | "managed",
        model?: string,
        modelSeries?: "S" | "T"
    }
}

export const createdRanges = [
    {key: "1", name: "tesseract.audit.filter.created.days1"},
    {key: "3", name: "tesseract.audit.filter.created.days3"},
    {key: "7", name: "tesseract.audit.filter.created.weeks1"},
    {key: "14", name: "tesseract.audit.filter.created.weeks2"},
    {key: "30", name: "tesseract.audit.filter.created.months1"},
    {key: "60", name: "tesseract.audit.filter.created.months2"},
    {key: "90", name: "tesseract.audit.filter.created.months3"},
    {key: "180", name: "tesseract.audit.filter.created.months6"},
    {key: "270", name: "tesseract.audit.filter.created.months9"},
    {key: "365", name: "tesseract.audit.filter.created.years1"}
];

const liveConnectionTrafficStates = [
    {key: "initiate", name: "tesseract.logs.liveLog.connectionState.initiate"},
    {key: "fail", name: "tesseract.logs.liveLog.connectionState.fail"},
    {key: "sync", name: "tesseract.logs.liveLog.connectionState.sync"},
    {key: "closing", name: "tesseract.logs.liveLog.connectionState.closing"},
    {key: "established", name: "tesseract.logs.liveLog.connectionState.established"},
    {key: "httpDeny", name: "tesseract.logs.liveLog.connectionState.httpDeny"}
];


export const LiveLog: React.FC<LiveLogProps> = (props) => {
    const {id, messagesType, resource, serial, tableName, details: {model, gatewayType, modelSeries}} = props;
    const [pollInterval, setPollInterval] = useState(5000);
    const liveState = getArrayDataContent(useCrudProps(apiResources.connectionHistoryStates, {}, {}, true)[0]?.data);
    const detailsLoaded = id && (resource === apiResources.gatewayCloudLive || serial);

    let liveUpdatedUrl = apiResources.siteLiveUpdated;
    if (gatewayType === "on-prem") liveUpdatedUrl = apiResources.gatewayOnPremLiveUpdated;
    else if (resource === apiResources.gatewayCloudLive) liveUpdatedUrl = apiResources.gatewayCloudLiveUpdated;

    const liveUpdated = getDataContent(useCrudSubscription(
        CrudTypes.GET,
        liveUpdatedUrl,
        {id},
        {
            pollInterval,
            crudOptions: {quietErrors: true},
            debounceWait: 500
        }
    )[0]);
    const emptyDataMessage = `tesseract.${messagesType}.liveLog.${liveUpdated?.content === null ? "waitingForConnections" : "emptyData"}`;

    useMixpanel(
        gatewayType ? "Gateways Live" : "Sites Live",
        !!model,
        {model: model || "", type: gatewayType || modelSeries || ""}
    );

    useEffect(() => {
        if (isString(liveUpdated)) setPollInterval(600000);
    }, [liveUpdated]);

    return (
        <TablePage
            authenticated
            tableName={tableName}
            resource={detailsLoaded ? resource : undefined}
            params={{id, serial}}
            noDataMessage={emptyDataMessage}
            pageMode="paginate"
            pollInterval={5000}
            resizable
            reorderable
            editColumns
            sortable
            compact
            noStaleData
            hasTitle
            rowDetails={{
                title: "tesseract.logs.liveLog.detailsTitle",
                fields: [
                    <StatusIconField
                        key="state"
                        source="state"
                        tooltipSource="connectionStateDescription"
                        label="tesseract.logs.liveLog.labels.state"
                        iconMap={logsIconMap}
                        column={0}
                    />,

                    <StatusIconField
                        key="connectionState"
                        source="connectionState"
                        tooltipSource="connectionStateDescription"
                        label="tesseract.logs.liveLog.labels.connectionTrafficState"
                        iconMap={logsTrafficStatesIconMap}
                        column={0}
                    />,
                    <DateField
                        key="startTime"
                        source="startTime"
                        label="tesseract.logs.liveLog.labels.startTime"
                        dateOnly={false}
                        column={0}
                    />,
                    <TextField
                        key="type"
                        source="type"
                        label="tesseract.logs.liveLog.labels.type"
                        column={0}
                    />,
                    <TextField
                        key="ipProtocol"
                        source="ipProtocol"
                        label="tesseract.logs.liveLog.labels.ipProtocol"
                        column={0}
                    />,
                    <TextField
                        key="speedDescription"
                        source="speedDescription"
                        label="tesseract.logs.liveLog.labels.speedDescription"
                        column={0}
                    />,
                    <TextField
                        key="sourceIp"
                        source="sourceIp"
                        label="tesseract.logs.liveLog.labels.sourceIp"
                        column={0}
                    />,
                    <TextField
                        key="sourcePort"
                        source="sourcePort"
                        label="tesseract.logs.liveLog.labels.sourcePort"
                        column={0}
                    />,
                    <TextField
                        key="sourceNat"
                        source="sourceNat"
                        label="tesseract.logs.liveLog.labels.sourceNat"
                        column={0}
                    />,
                    <TextField
                        key="sourceBytesDescription"
                        source="sourceBytesDescription"
                        label="tesseract.logs.liveLog.labels.sourceBytesDescription"
                        column={0}
                    />,
                    <TextField
                        key="destinationIp"
                        source="destinationIp"
                        label="tesseract.logs.liveLog.labels.destinationIp"
                        column={0}
                    />,
                    <TextField
                        key="destinationPort"
                        source="destinationPort"
                        label="tesseract.logs.liveLog.labels.destinationPort"
                    />,
                    <TextField
                        key="destinationInterface"
                        source="destinationInterface"
                        label="tesseract.logs.liveLog.labels.destinationInterface"
                    />,
                    <TextField
                        key="destinationBytesDescription"
                        source="destinationBytesDescription"
                        label="tesseract.logs.liveLog.labels.destinationBytesDescription"
                    />,
                    <TextField
                        key="totalBytesDescription"
                        source="totalBytesDescription"
                        label="tesseract.logs.liveLog.labels.totalBytesDescription"
                    />,
                    <DateField
                        key="idleTime"
                        source="idleTime"
                        label="tesseract.logs.liveLog.labels.idleTime"
                        dateOnly={false}
                    />,
                    <TextField
                        key="user"
                        source="user"
                        label="tesseract.logs.liveLog.labels.user"
                    />,
                    <ChipArrayField
                        key="urlCategories"
                        source="urlCategories"
                        label="tesseract.logs.liveLog.labels.urlCategories"
                    />,
                    <ChipArrayField
                        key="application"
                        source="application"
                        label="tesseract.logs.liveLog.labels.application"
                    />,
                    <TextField
                        key="appName"
                        source="appName"
                        label="tesseract.logs.liveLog.labels.appName"
                    />
                ]
            }}
        >
            <StatusIconField
                source="state"
                tooltipSource="connectionStateDescription"
                label="tesseract.logs.liveLog.labels.state"
                width={96}
                filter="selectarray"
                iconMap={logsIconMap}
                filterProps={{
                    choices: liveState,
                }}
                alwaysVisible
            />
            <StatusIconField
                source="connectionState"
                tooltipSource="connectionStateDescription"
                label="tesseract.logs.liveLog.labels.connectionTrafficState"
                width={96}
                filter="selectarray"
                iconMap={logsTrafficStatesIconMap}
                filterProps={{
                    choices: liveConnectionTrafficStates,
                }}
                alwaysVisible
            />
            <DateField
                source="startTime"
                label="tesseract.logs.liveLog.labels.startTime"
                dateOnly={false}
                width={164}
                filter="select"
                filterProps={{
                    choices: createdRanges
                }}
                hideByDefault
            />
            <TextField
                source="type"
                label="tesseract.logs.liveLog.labels.type"
                filter="text"
                width={150}
                hideByDefault
            />
            <TextField
                label="tesseract.logs.liveLog.labels.ipProtocol"
                source="ipProtocol"
                filter="text"
                width={130}
            />
            <TextField
                source="speedDescription"
                label="tesseract.logs.liveLog.labels.speedDescription"
                columnProps={{
                    field: "speed"
                }}
                width={136}
                hideByDefault
            />
            <TextField
                source="sourceIp"
                label="tesseract.logs.liveLog.labels.sourceIp"
                width={150}
                filter="text"
            />
            <TextField
                source="sourcePort"
                label="tesseract.logs.liveLog.labels.sourcePort"
                width={120}
                filter="text"
                filterProps={{
                    type: "number"
                }}
            />
            <TextField
                source="sourceNat"
                label="tesseract.logs.liveLog.labels.sourceNat"
                width={150}
                filter="text"
                hideByDefault
            />
            <TextField
                label="tesseract.logs.liveLog.labels.sourceBytesDescription"
                source="sourceBytesDescription"
                columnProps={{
                    field: "sourceBytes"
                }}
                width={140}
                hideByDefault
            />
            <TextField
                source="destinationIp"
                label="tesseract.logs.liveLog.labels.destinationIp"
                width={150}
                filter="text"
            />
            <TextField
                source="destinationPort"
                label="tesseract.logs.liveLog.labels.destinationPort"
                width={150}
                filter="text"
                filterProps={{
                    type: "integer"
                }}
            />
            <TextField
                source="destinationInterface"
                label="tesseract.logs.liveLog.labels.destinationInterface"
                filter="text"
            />
            <TextField
                label="tesseract.logs.liveLog.labels.destinationBytesDescription"
                source="destinationBytesDescription"
                columnProps={{
                    field: "destinationBytes"
                }}
                width={140}
                hideByDefault
            />
            <TextField
                label="tesseract.logs.liveLog.labels.totalBytesDescription"
                source="totalBytesDescription"
                columnProps={{
                    field: "totalBytes"
                }}
                width={140}
            />
            <DateField
                source="idleTime"
                label="tesseract.logs.liveLog.labels.idleTime"
                dateOnly={false}
                width={164}
                filter="text"
                hideByDefault
            />
            <TextField
                label="tesseract.logs.liveLog.labels.user"
                source="user"
                filter="text"
                width={130}
            />
            <ChipArrayField
                source="urlCategories"
                label="tesseract.logs.liveLog.labels.urlCategories"
                filter="text"
                width={150}
            />
            <ChipArrayField
                source="application"
                label="tesseract.logs.liveLog.labels.application"
                width={130}
                filter="selectarray"
                filterProps={{
                    resource: apiResources.applications,
                    source: "applicationId",
                    optionValue: "id"
                }}
            />
            <TextField
                label="tesseract.logs.liveLog.labels.appName"
                source="appName"
                width={130}
                filter="text"
                hideByDefault
            />
        </TablePage>
    );
};

export default memo(LiveLog);
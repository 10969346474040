import React from "react";
import {
    BooleanInput,
    ConnectedFormPage,
    CrudTypes,
    FormSection,
    getArrayDataContent,
    SelectArrayInput,
    TextInput,
    useCrudSubscription,
    useMixpanel,
    validateDomain,
    validateInt,
    validateMaxValueMemo,
    validateMinValueMemo
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import {get} from "lodash";
import {parseJson} from "../../../utils/jsonUtils";
import {makeStyles} from "@mui/styles";
import {Link, Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    helpText: {
        padding: theme.spacing(1, 1, 4, 3),
        color: theme.palette.text.secondary
    }
}));

const Xdr = () => {
    const registerAction = useMixpanel("Xdr");
    const siteNames = getArrayDataContent(useCrudSubscription(CrudTypes.GET, apiResources.siteNames)[0]?.data);
    const gatewayNames = getArrayDataContent(useCrudSubscription(CrudTypes.GET, apiResources.gatewayNames)[0]?.data);
    const classes = useStyles();

    return (
        <ConnectedFormPage
            resource={apiResources.settings}
            title="tesseract.integration.pageTitle"
            subtitle="tesseract.xdr.pageTitle"
            formatRequestData={(data: any) => (
                {settings: {
                    xdr: JSON.stringify({
                        ...data,
                        sites: {
                            all: data.sites.includes("__all__"),
                            configurations: data.sites.includes("__all__") ? [] : data.sites,
                        },
                        gateways: {
                            all: data.gateways.includes("__all__"),
                            configurations: data.gateways.includes("__all__") ? [] : data.gateways,
                        }
                    })
                }}
            )}
            formatResourceData={(data: any) => {
                const xdrSettings = parseJson(data?.settings?.xdr);
                return {
                    enabled: false,
                    hostname: "",
                    port: "",
                    ...xdrSettings,
                    sites: get(xdrSettings, "sites.all", false) ? ["__all__"] : get(xdrSettings, "sites.configurations", []),
                    gateways: get(xdrSettings, "gateways.all", false) ? ["__all__"] : get(xdrSettings, "gateways.configurations", []),
                };
            }}
            params={{filter: {type: "virtualWan"}}}
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
            canReset
        >
            <FormSection title="tesseract.xdr.pageTitle" newStyle hideBorder>
                <Typography className={classes.helpText}>
                    <Trans i18nKey="tesseract.xdr.xdrDescription">
                        <Link href="https://campus.barracuda.com/product/secureedge/doc/169972636/how-to-configure-barracuda-xdr-in-secureedge/"
                              target="_blank"
                              rel="noopener"/>
                    </Trans>
                </Typography>
                <BooleanInput
                    source="enabled"
                    label="tesseract.xdr.enable"
                    newStyle
                    toggleEnabledText="tesseract.xdr.streamingEnabled"
                />
                <SelectArrayInput
                    source="sites"
                    label="tesseract.xdr.sites"
                    choices={siteNames}
                    optionValue="uuid"
                    options={{params: {filter: {modelSeries: "T"}}}}
                    disable={(value, data) => !data?.enabled}
                    selectAllValue="__all__"
                    selectAll
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <SelectArrayInput
                    source="gateways"
                    label="tesseract.xdr.gateways"
                    choices={gatewayNames}
                    optionValue="uuid"
                    disable={(value, data) => !data?.enabled}
                    selectAllValue="__all__"
                    selectAll
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <TextInput
                    source="hostname"
                    label="tesseract.xdr.hostname"
                    validate={[validateDomain]}
                    disable={(value, data) => !data?.enabled}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <TextInput
                    source="port"
                    label="tesseract.xdr.port"
                    validate={[validateInt, validateMaxValueMemo(65535), validateMinValueMemo(1)]}
                    disable={(value, data) => !data?.enabled}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
            </FormSection>
        </ConnectedFormPage>
    );
};

export default Xdr;
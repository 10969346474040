import React from "react";
import LoadingMessage from "../../loading/LoadingMessage/LoadingMessage";
import {getFrom, getFromSupportedProps, useCardGetFrom} from "../../../hooks";
import {Button, Divider, Grid, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {get} from "lodash";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useHistory} from "react-router";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    overviewContainer: {
        margin: theme.spacing(4, 0, 2)
    },
    overviewText: {
        marginRight: theme.spacing(1),
        fontSize: "40px"
    },
    overviewValue: {
        fontWeight: "bold",
        fontSize: "40px"
    },
    dividerRow: {
        width: "75%"
    },
    divider: {
        width: "100%"
    },
    detailsRow: {
        width: "100%"
    },
    detailsContainer: {
        margin: theme.spacing(2, 0)
    },
    detailLinkButton: {
        textTransform: "none",
        padding: theme.spacing(1)
    },
    detailContainer: {
        padding: theme.spacing(1)
    },
    detailsText: {
        margin: theme.spacing(0, 0.5),
        fontSize: "20px",
        color: "#4E4D4D"
    },
    detailsValue: {
        fontWeight: "bold",
        color: theme.palette.primary.main,
        fontSize: "30px"
    }
});
const useStyles = makeOverrideableStyles("ResourceStatisticsCardContent", styles);

export interface ResourceStatisticsCardContentProps<Data extends object, Props extends object> extends getFromSupportedProps<Data, Props>, StyledComponentProps<typeof styles> {
    /** array of additional value-text pairs to display under the overview **/
    details?: {
        /**
         * string to display next to the value.
         */
        text: string,
        /**
         * a dot-notation path to the value to display from within the provided or retrieved data, or a callback function that provides the string to display from the data.
         */
        value: string | ((data: any) => any),
        /**
         * override the default text color for the value
         */
        color?: string,
        /** if true, if this detail returns a falsy value (e.g. "", 0, null, undefined, false) it will not be shown **/
        hideFalseyValues?: boolean,
        /** if true, then this detail is rendered as a flat button, which on click sends the user to the provided link **/
        link?: string
    }[],
    /** if true, any detail that returns a falsy value (e.g. "", 0, null, undefined, false) will not be shown **/
    hideFalseyValues?: boolean
    /**
     * CRUD configuration, defining the values to use for fetching data via the CRUD framework. You can provide the CRUD parameters either
     * inside a getFrom object, or as individual props themselves.
     *
     * See [cardGetFromPropTypes](/?path=/docs/core-hooks-card-hooks--page#cardgetfromproptypes--codeobjectcode) for full definition.
     */
    getFrom?: getFrom<Data, Props>,
    /**
     * string to display next to the overview value.
     */
    overviewText: string,
    /**
     * a dot-notation path to the value to display from within the provided or retrieved data, or a callback function that provides the string to display from the data.
     */
    overviewValue: string | ((data: any) => any),
    /**
     *  if false, content is not rendered (although CRUD data is still collected).
     *
     *  This prop is passed down by [TabbedCard](/?path=/docs/core-components-cards-tabbedcard--tabbed-card) when tab is not currently selected.
     */
    visible?: boolean
}

const getValue = (data: any, pathOrFunction?: string | ((data: any) => any)): any => {
    if (typeof pathOrFunction === "string") {
        return get(data, pathOrFunction);
    }
    return pathOrFunction && pathOrFunction(data);
};

/**
 * Card content that contains statistics for a resource. Renders a large "overview" statistic value, then can be configured
 * to display multiple other overview values.
 *
 * Data can either be provided directly, or collected via crud.
 */
export const ResourceStatisticsCardContent = <Data extends object, Props extends ResourceStatisticsCardContentProps<Data, Props>>(props: Props): JSX.Element | null => {
    const {visible, getFrom, overviewText, overviewValue, details, hideFalseyValues} = props;
    const {push} = useHistory();
    const [translate] = useTranslation();
    const classes = useStyles(props);
    const [getFromData, getFromStatus] = useCardGetFrom(getFrom, props);
    const isLoading = getFromStatus;

    if (!visible) {
        return null;
    }

    if (!getFromData && isLoading) {
        return (<LoadingMessage/>);
    }

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <Grid container alignItems="center" className={classes.overviewContainer}>
                    <Typography variant="h4" className={classes.overviewText}>
                        {translate(overviewText)}
                    </Typography>
                    <Typography variant="h4" className={classes.overviewValue}>
                        {getValue(getFromData, overviewValue)}
                    </Typography>
                </Grid>
            </Grid>
            {details && (
                <React.Fragment>
                    <Grid item xs={12} className={classes.dividerRow}>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} className={classes.detailsRow}>
                        <Grid container className={classes.detailsContainer} justifyContent="space-around">
                            {details && details.map((detail, index) => {
                                const value = getValue(getFromData, detail.value);
                                if ((hideFalseyValues || detail.hideFalseyValues) && !value) {
                                    return null;
                                }
                                const idSuffix = `${detail.text || index}`.replace(/[^a-zA-Z0-9]/g, "-");

                                return (
                                    <Grid
                                        item
                                        id={`resource-statistics-statistic-${idSuffix}`}
                                        key={detail.text || index}
                                        onClick={() => detail.link && push(detail.link)}
                                        component={detail.link ? Button : "div"}
                                        className={detail.link ? classes.detailLinkButton : classes.detailContainer}
                                    >
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item>
                                                <Typography className={classes.detailsText}>
                                                    {translate(detail.text)}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="h5"
                                                    className={classes.detailsValue}
                                                    style={detail.color ? {color: detail.color} : undefined}
                                                >
                                                    {value}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

ResourceStatisticsCardContent.defaultProps = {
    overviewText: "cuda.cards.overview",
    overviewValue: "total",
    visible: true
};

export default ResourceStatisticsCardContent;
import {Card, IconButton} from "@barracuda-internal/bds-core";
import ListCardContent from "../ListCardContent/ListCardContent";
import React from "react";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {Close} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {ClonableChildren} from "../../../utils/commonTypes";

export const styles = (theme: Theme) => createStyles({
    root: {
        border: theme.palette.divider + " solid 1px"
    },
    listCardTable: {
        marginTop: theme.spacing(2)
    },
    listCardTableCell: {
        paddingBottom: 4,
        paddingTop: 4,
        width: "auto",
        maxWidth: 160,
        lineBreak: "anywhere"
    },
    listCardTableRow: {
        height: "auto"
    },
    listCardTableLabelCell: {
        paddingLeft: theme.spacing(1)
    },
    closeIcon: {
        position: "absolute",
        top: 0,
        right: 0
    }
});

const useStyles = makeOverrideableStyles("LocationsCardContentTooltip", styles);

export interface LocationsCardContentTooltipProps extends StyledComponentProps<typeof styles> {
    /**
     * children to pass to [ListCardContent](/?path=/docs/core-components-cards-listcardcontent--list-card-content).
     * See [ListCardContent](/?path=/docs/core-components-cards-listcardcontent--list-card-content) for more details.
     */
    children?: ClonableChildren,
    /**
     * data to pass to [ListCardContent](/?path=/docs/core-components-cards-listcardcontent--list-card-content).
     */
    data?: any,
    /**
     * onClose callback to use for the close button's onClick action.
     */
    onClose?: () => void
}

/**
 * This is a simple card, used as the contents for the  "Popover" tooltip in the [LocationsCardContent](/?path=/docs/core-components-cards-locationscardcontent--locations-card-content) map.
 *
 * It creates a simple Card, with a close button and [ListCardContent](/?path=/docs/core-components-cards-listcardcontent--list-card-content).
 */
export const LocationsCardContentTooltip = (props: LocationsCardContentTooltipProps) => {
    const {children, data, onClose} = props;
    const classes = useStyles(props);
    return children ? (
        <Card className={classes.root} elevation={3}>
            <IconButton
                size="small"
                aria-label="close"
                disableRipple
                disableTouchRipple
                className={classes.closeIcon}
                onClick={() => onClose?.()}
            >
                <Close id="cuda-icon-close"/>
            </IconButton>
            <ListCardContent
                classes={{
                    table: classes.listCardTable,
                    tableCells: classes.listCardTableCell,
                    tableRow: classes.listCardTableRow,
                    tableLabelCell: classes.listCardTableLabelCell
                }}
                data={data}
            >
                {children}
            </ListCardContent>
        </Card>
    ) : null;
};

export default LocationsCardContentTooltip;
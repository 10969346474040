import {ButtonDialog, formatDateTime, InputLabel} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import React from 'react';
import {Trans} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import UpdateDialogContent from "./dialog/UpdateDialogContent";

const useStyles = makeStyles({
    dateTimeInput: {
        width: 140
    },
    inputLabel: {
        marginTop: 16,
        paddingRight: 8
    },
    inputRoot: {
        width: 580
    },
    value: {
        paddingTop: 6,
        marginTop: 16,
        "& > *": {
            display: "inline-block"
        },
        "& p": {
            paddingTop: 2
        }
    },
    midText: {
        display: "inline-block",
        color: "grey"
    }
});

interface DefaultUpdateWindowProps {
    data?: Record<string, any>;
    onSuccess?: () => void;
    availableUpdates: Record<string, any>;
}

const DefaultUpdateWindow: React.FC<DefaultUpdateWindowProps> = ({data, onSuccess, availableUpdates}) => {
    const classes = useStyles();

    return (
        <InputLabel
            label="tesseract.settings.tabs.updates.firmware.default.label"
            classes={{inputLabel: classes.inputLabel, root: classes.inputRoot}}
            minimised
        >
            <div className={classes.value}>
                <Typography variant="body1">
                    <Trans
                        i18nKey={data ? "tesseract.settings.tabs.updates.firmware.default.setting" : "tesseract.settings.tabs.updates.firmware.noUpdatesMessage"}
                        values={{from: formatDateTime(data?.from), to: formatDateTime(data?.to)}}
                    >
                        <div className={classes.midText}/>
                    </Trans>
                </Typography>
                {data && (
                    <ButtonDialog
                        buttonIcon={<Edit id="edit-dialog-icon"/>}
                    >
                        <UpdateDialogContent
                            data={data}
                            title="tesseract.settings.tabs.updates.firmware.editDefault.dialogTitle"
                            defaultUpdateWindow
                            availableUpdates={availableUpdates}
                            onSuccess={onSuccess}
                        />
                    </ButtonDialog>
                )}
            </div>
        </InputLabel>
    );
};


export default DefaultUpdateWindow;
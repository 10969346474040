import React, {useCallback} from "react";
import classNames from "classnames";
import {Theme} from "@mui/material";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {RowRender} from "../../utils/commonTypes";

const useDataTableRowHighlightingStyles = (theme: Theme) => ({
    highlightedRow: {
        boxShadow: theme.shadows[5]
    },
    dimmedRow: {
        opacity: 0.5,
        backgroundColor: theme.palette.action.disabledBackground + "!important"
    },
});
const useRowHighlightingStyles = makeOverrideableStyles("DataTableRowHighlighting", useDataTableRowHighlightingStyles);

export interface useDataTableRowHighlightingProps extends StyledComponentProps<typeof useDataTableRowHighlightingStyles> {
    /**
     * callback used to identify which table rows should be dimmed.
     *  Called with each row's data. Each row it returns true for will be dimmed. If not provided row highlighting will not be active.
     */
    rowDimmed?: (rowDataItem: any) => boolean,
    /**
     * callback used to identify which table rows should be highlighted.
     * Called with each row's data. For each row it returns true for will be highlighted, all others will be dimmed.
     * If not provided row highlighting will not be active.
     */
    rowHighlight?: (rowDataItem: any) => boolean,
}

/**
 * Hook for providing the functionality for highlighting/dimming rows to a BDS DataTable (or other tables based on it, such as Table and ConnectedTable).
 *
 * Only one of rowHighlight or rowDimmed should be supplied, as behaviour may be undefined if both are active.
 */
export const useDataTableRowHighlighting = (props: useDataTableRowHighlightingProps): { rowRender: RowRender } => {
    const {rowHighlight, rowDimmed} = props;
    const classes = useRowHighlightingStyles(props);

    return {
        rowRender: useCallback((row, rowProps) => React.cloneElement(row, {
            ...row.props,
            className: classNames(
                row.props && row.props.className,
                rowProps.className,
                rowHighlight && (rowHighlight(rowProps.dataItem) ? classes.highlightedRow : classes.dimmedRow),
                rowDimmed?.(rowProps.dataItem) && classes.dimmedRow
            )
        }), [rowHighlight, rowDimmed])
    };
};
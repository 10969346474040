import React from 'react';
import apiResources from "../../../../apiResources";
import {CardSizes, ResourceStatisticsCard, useFetchStatisticsCardDetailsAndTotal} from "@stratos/reporting";
import {Theme, useTheme} from "@mui/material";
import {getStatusColorFromTheme} from "../../../../utils/themeUtils";
import {generateQueryString, LinkField, StatusIconField, TextField} from "@cuda-react/core";
import {siteGatewayStatusIcons} from "../../../../components/iconMapping";
import {StatisticsDetails} from "@stratos/reporting/lib/hooks/useFetchStatisticsCardDetailsAndTotal";

const previewData = (theme: Theme) => [
    [
        {
            "title": "tesseract.dashboard.cards.vpn.status.online",
            "value": 1,
            "color": getStatusColorFromTheme("online", theme)
        },
        {
            "title": "tesseract.dashboard.cards.vpn.status.pending",
            "value": 2,
            "color": getStatusColorFromTheme("pending", theme)
        },
        {
            "title": "tesseract.dashboard.cards.vpn.status.connecting",
            "value": 3,
            "color": getStatusColorFromTheme("connecting", theme)
        },
        {
            "title": "tesseract.dashboard.cards.vpn.status.degraded",
            "value": 4,
            "color": getStatusColorFromTheme("degraded", theme)
        },
        {
            "title": "tesseract.dashboard.cards.vpn.status.offline",
            "value": 2,
            "color": getStatusColorFromTheme("offline", theme)
        }
    ],
    12
] as [StatisticsDetails[], number];

const filterDegraded = (details: any) => !(details.link || details.title).includes("degraded");

export interface TunnelStatusCardProps {
    preview?: boolean,
    size: CardSizes
}

export const TunnelStatusCard: React.FC<TunnelStatusCardProps> = (props) => {
    const {size, preview} = props;
    const theme = useTheme();
    const apiResult = useFetchStatisticsCardDetailsAndTotal(
        {
            url: preview ? undefined : apiResources.VPNTunnelStatus
        },
        {
            url: preview ? undefined : apiResources.siteStates
        },
        {
            getKeyColor: (key: any) => (getStatusColorFromTheme(key, theme)),
            linkCreator: (data: any) => `/#/infrastructure/sites${generateQueryString({connectionStatus: [data.key]})}#/infrastructure/sites`
        }
    );
    const [details, total] = preview ? previewData(theme) : apiResult;
    return (
        <ResourceStatisticsCard
            overViewData={size === CardSizes.small && details ? details.filter(filterDegraded) : details}
            size={size}
            preview={preview}
            overViewValue={total}
            overviewTitle="tesseract.dashboard.cards.vpn.status.tabTitle"
            tableTitle="tesseract.dashboard.cards.vpn.offline.tabTitle"
            noDataMessage="tesseract.dashboard.cards.vpn.offline.noData"
        >
            <StatusIconField
                source="gateway.status"
                label="tesseract.dashboard.cards.vpn.offline.gatewayStatus"
                iconMap={siteGatewayStatusIcons}
                width={96}
            />
            <LinkField
                label="tesseract.dashboard.cards.vpn.offline.gatewayName"
                source="gateway.name"
                link={(data) => `/infrastructure/gateways${generateQueryString({
                    name: [data.gateway.name],
                    connectionStatus: [data.gateway.status]
                })}`}
            />
            <StatusIconField
                source="status"
                label="tesseract.dashboard.cards.vpn.offline.siteStatus"
                iconMap={siteGatewayStatusIcons}
                width={96}
            />
            <LinkField
                label="tesseract.dashboard.cards.vpn.offline.siteName"
                source="name"
                link={(data) => `/infrastructure/${data.model?.startsWith("F") ? "iot" : "sites"}/${data.id}`}
            />
            <TextField label="tesseract.dashboard.cards.vpn.offline.siteLocation" source="summary"/>
        </ResourceStatisticsCard>
    );
};

export default TunnelStatusCard;
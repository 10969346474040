import React from 'react';
import {
    CustomField,
    getArrayDataContent,
    LinkField,
    SelectedChoiceField,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
import apiResources from "../../../../apiResources";

export const useGatewayDetails= () => {
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const regionChoices = useCrudProps(apiResources.gatewayRegions)[0]?.data;
    const scaleUnitChoices = useCrudProps(apiResources.gatewayScaleUnits)[0]?.data;

    return [
        <LinkField
            label="tesseract.dashboard.cards.vpn.locations.gateways.fields.name"
            source="name"
            key="name"
            link={getAzureResourceLink("applicationId", isDemo)}
        />,
        <CustomField
            label="tesseract.dashboard.cards.vpn.locations.gateways.fields.sites"
            source="tseries"
            key="tseries"
            render={(sites, data) => (<LinkField
                defaultText={(sites && sites.length) || sites}
                link={`/infrastructure/sites?filter={"upstream":[${data.id}]}`}
            />)}
        />,
        <CustomField
            label="tesseract.dashboard.cards.vpn.locations.gateways.fields.iot"
            source="iot"
            key="iot"
            render={(sites, data) => (<LinkField
                defaultText={(sites && sites.length) || sites}
                link={`/infrastructure/iot?filter={"upstream":[${data.id}]}`}
            />)}
        />,
        <LinkField
            label="tesseract.dashboard.cards.vpn.locations.gateways.fields.vwan"
            source="virtualWan.name"
            key="vwanName"
            link={getAzureResourceLink("virtualWan.resourceId", isDemo)}
        />,
        <LinkField
            label="tesseract.dashboard.cards.vpn.locations.gateways.fields.hub"
            source="hubName"
            key="hubName"
            link={getAzureHubLink("hubId", isDemo)}
        />,
        <SelectedChoiceField
            label="tesseract.dashboard.cards.vpn.locations.gateways.fields.region"
            source="region"
            key="region"
            choices={getArrayDataContent(regionChoices)}
        />,
        <SelectedChoiceField
            label="tesseract.dashboard.cards.vpn.locations.gateways.fields.size"
            source="scaleUnit"
            key="scaleUnit"
            choices={getArrayDataContent(scaleUnitChoices)}
        />
    ];
};

export default useGatewayDetails;
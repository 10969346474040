import {previewDataGenerator} from "../../typesAndConstants";

const dataGenerator: previewDataGenerator = () => [
    {
        "enrollmentDate": "2023-01-17T08:52:31Z",
        "brand": "VMware, Inc.",
        "hardwareModel": "VMware Virtual Platform",
        "operatingSystem": "Windows",
        "operatingSystemFlavor": null,
        "operatingSystemVersion": "10.0.19045.2486",
        "agentVersion": "1.0.56",
        "location": {latitude: 48.8628, longitude: 50.1167},
        "userEmail": "bob@cudatest.onmicrosoft.com",
        "userFullName": "Bob Doe",
    },
    {
        "enrollmentDate": "2023-01-18T13:47:06Z",
        "brand": "ASUS",
        "hardwareModel": "ASUS 14.0lta",
        "operatingSystem": "Windows",
        "operatingSystemFlavor": null,
        "operatingSystemVersion": "10.0.19045.2486",
        "agentVersion": "1.0.61",
        "location": {latitude: 48.2, longitude: 16.3667},
        "userEmail": "ewrwerw@cudatest.onmicrosoft.com",
        "userFullName": "John Charles"
    },
    {
        "id": 806,
        "uuid": "a84002bc-b3ec-414d-b4a4-be121908c82d",
        "enrollmentDate": "2023-01-18T14:42:55Z",
        "brand": "ASUS",
        "hardwareModel": "ASUS 14.0lta",
        "model": "",
        "operatingSystem": "Windows",
        "operatingSystemFlavor": null,
        "operatingSystemVersion": "10.0.19045.2604",
        "agentVersion": "1.0.56",
        "location": {latitude: 22.516429, longitude: 88.346782},
        "userId": 31,
        "userEmail": "ewrwerw@cudatest.onmicrosoft.com",
        "userFullName": "Sue Doe",
        "virtualWanId": 3366
    },
    {
        "id": 7636,
        "createdUsername": "",
        "created": "2024-02-13T11:52:40Z",
        "updatedUsername": "581fae29-0771-49e5-8097-e379c139f06a",
        "updated": "2024-02-13T11:52:42Z",
        "uuid": "d172133c-32e9-4800-a3cd-f4510c13a9b8",
        "enrollmentDate": "2024-02-13T11:52:40Z",
        "brand": "ASUS",
        "hardwareModel": "ASUS 14.0lta",
        "model": "",
        "operatingSystem": "Windows",
        "operatingSystemFlavor": null,
        "operatingSystemVersion": "10.0.19045.3930",
        "agentVersion": "1.0.56",
        "location": {latitude: 47.2361, longitude: 11.3115},
        "userId": 49,
        "userEmail": "qa2pink@cudatest.onmicrosoft.com",
        "userFullName": "Charles Jonson",
        "virtualWanId": 3366
    },
    {
        "id": 7642,
        "createdUsername": "",
        "created": "2024-02-13T13:39:34Z",
        "updatedUsername": "00c2fb18-c5dd-4884-bbdc-43df21ee2492",
        "updated": "2024-02-26T13:52:38Z",
        "uuid": "a03d74fb-f7b2-4c31-bf0b-442a49cb5b74",
        "enrollmentDate": "2024-02-13T13:39:34Z",
        "brand": "ASUS",
        "hardwareModel": "ASUS 14.0lta",
        "model": "",
        "operatingSystem": "Linux",
        "operatingSystemFlavor": "ubuntu",
        "operatingSystemVersion": "22.04",
        "agentVersion": "1.0.4-37",
        "location": {
            "latitude": 47.1774,
            "longitude": 11.3603,
            "countryCode": "AT"
        },
        "userId": 49,
        "userEmail": "qa2pink@cudatest.onmicrosoft.com",
        "userFullName": "Charles Jonson",
        "virtualWanId": 3366
    },
    {
        "id": 8055,
        "createdUsername": "",
        "created": "2024-02-27T13:03:43Z",
        "updatedUsername": "9ce0ef06-ff94-4e30-942c-1505b8861f2f",
        "updated": "2024-02-28T14:07:40Z",
        "uuid": "ccba063b-3fdf-41b7-a1b5-81b2c0413bae",
        "enrollmentDate": "2024-02-27T13:03:43Z",
        "brand": "Apple",
        "hardwareModel": "Macbook Air",
        "model": "",
        "operatingSystem": "macOS",
        "operatingSystemFlavor": null,
        "operatingSystemVersion": "10.0.19045.4046",
        "agentVersion": "1.0.3-135",
        "location": {
            "latitude": 48.2343,
            "longitude": 16.293,
            "countryCode": "AT"
        },
        "userId": 49,
        "userEmail": "qa2pink@cudatest.onmicrosoft.com",
        "userFullName": "Kirsty Jane",
        "virtualWanId": 3366
    },
    {
        "id": 8061,
        "createdUsername": "",
        "created": "2024-02-27T14:13:57Z",
        "updatedUsername": "b66d87cf-bd66-4ee7-bf61-18943fe6eff2",
        "updated": "2024-02-27T14:13:57Z",
        "uuid": "58ec2a86-a17a-487d-8b03-6a430df697fd",
        "enrollmentDate": "2024-02-27T14:13:57Z",
        "brand": "samsung",
        "hardwareModel": "SM-G781B",
        "model": "SM-G781B",
        "operatingSystem": "Android",
        "operatingSystemFlavor": null,
        "operatingSystemVersion": "13.0.0",
        "agentVersion": "1.0.1-180",
        "location": {
            "latitude": 48.1535,
            "longitude": 16.3855,
            "countryCode": "AT"
        },
        "userId": 49,
        "userEmail": "qa2pink@cudatest.onmicrosoft.com",
        "userFullName": "Hans Anderson",
        "virtualWanId": 3366
    },
    {
        "id": 543,
        "createdUsername": "",
        "created": "2022-12-22T10:36:46Z",
        "updatedUsername": "",
        "updated": "2022-12-22T10:36:46Z",
        "uuid": "542ca07c-2504-446d-84e7-51dbc6909880",
        "enrollmentDate": "2022-12-22T10:36:46Z",
        "brand": "samsung",
        "hardwareModel": "SM-G781B",
        "model": "SM-G781B",
        "operatingSystem": "Android",
        "operatingSystemFlavor": null,
        "operatingSystemVersion": null,
        "agentVersion": "1.0.56",
        "location": {latitude: 39.3888, longitude: -76.6133},
        "userId": 70,
        "userEmail": "crazyusername1_-1@cudatest.onmicrosoft.com",
        "userFullName": "Tom Smith",
        "virtualWanId": 3366
    },
    {
        "id": 775,
        "createdUsername": "",
        "created": "2023-01-17T10:34:40Z",
        "updatedUsername": "45682d10-cb49-4b46-bdfd-d5e51ba730a6",
        "updated": "2023-01-17T10:34:41Z",
        "uuid": "67c2a894-d3d0-4de5-a006-7cc9e2ba2b39",
        "enrollmentDate": "2023-01-17T10:34:40Z",
        "brand": "Apple",
        "hardwareModel": "Macbook Air",
        "model": "Other",
        "operatingSystem": "macOS",
        "operatingSystemFlavor": "ubuntu",
        "operatingSystemVersion": "22.04",
        "agentVersion": "1.0.56",
        "location": {latitude: 42.598508, longitude: -71.329444},
        "userId": 70,
        "userEmail": "crazyusername1_-1@cudatest.onmicrosoft.com",
        "userFullName": "Tom Smith",
        "virtualWanId": 3366
    },
    {
        "id": 1058,
        "createdUsername": "",
        "created": "2023-02-21T07:12:23Z",
        "updatedUsername": "588a943b-710f-442e-a17d-6a16bbcd1651",
        "updated": "2023-03-06T07:12:31Z",
        "uuid": "a0eef505-7e5e-42c4-9fa4-74b74d5160de",
        "enrollmentDate": "2023-02-21T07:12:23Z",
        "brand": "Apple",
        "hardwareModel": "Macbook Air",
        "model": "",
        "operatingSystem": "macOS",
        "operatingSystemFlavor": null,
        "operatingSystemVersion": "10.0.19045.2604",
        "agentVersion": "1.0.56",
        "location": {latitude: 33.3859, longitude: -84.6486},
        "userId": 70,
        "userEmail": "crazyusername1_-1@cudatest.onmicrosoft.com",
        "userFullName": "Charles Jonson",
        "virtualWanId": 3366
    }
];

export default dataGenerator;
import {StyledComponentProps} from "@cuda-react/theme";
import React, {ReactChild} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Divider, Grid, Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";

interface EmptyPageContentBaseProps {
    /** image to use for the background of the coming soon page */
    background?: React.ReactChild,
    /** override the icon to use in the coming soon page */
    icon?: React.ReactChild,
    /** override the main icon header to use in the coming soon page */
    iconHeader?: string,
    /** override the text header to use in the coming soon page */
    textHeader?: string,
    /** override the text to use in the coming soon page. You can use '<0/>' in your translated string to insert <br /> elements. */
    text?: string,
    /** add actions underneath the icon header */
    actions?: ReactChild | ReactChild[],
    /** array of steps. It needs to have an id to be shown inside the circle icon and a text shown under the icon */
    actionSteps?: { title: string, text: string }[],
    /** override the text footer to use in the coming soon page. You can use '<0/>' in your translated string to insert <br /> elements. */
    textFooter?: string,
    /** additional action/text/etc. to render below the text, but before the textFooter */
    customActions?: React.ReactChild
}

const styles = createStyles<string, EmptyPageContentBaseProps>({
    root: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 16,
        minHeight: 500,
        width: "60%",
        minWidth: 800,
        padding: "16px 16px"
    },
    background: {
        width: "60%",
        minWidth: 800,
        margin: "auto",
        marginTop: 32,
        marginBottom: -580,
        opacity: 0.2,
        textAlign: "right",
        "& > *": {
            height: 512,
            width: 512,
        }
    },
    icon: {
        textAlign: "center",
        "& > *": {
            height: 128,
            width: 128,
        }
    },
    actions: {
        textAlign: "center",
        zIndex: 5
    },
    divider: {
        width: 200,
        margin: "auto"
    },
    iconHeader: {
        textAlign: "center"
    },
    textHeader: {
        fontWeight: "bold",
        textAlign: "center"
    },
    text: {
        textAlign: "center"
    },
    mainContainer: {
        justifyContent: "center",
    },
    stepsContainer: {
        textAlign: "center",
        justifyContent: "center",
        color: "#2478BC",
        margin: "2em",
    },
    circleStep: {
        border: "0.3em solid #2478BC",
        borderRadius: "100%",
        height: "3em",
        width: "3em",
    },
    textStep: {
        marginTop: "0.35em",
        fontSize: "1.5em",
        fontWeight: "bold",
        textAlign: "center"
    },
    customActions: {
        marginTop: "1em",
        textAlign: "center",
        zIndex: 5
    }
});
const useStyles = makeStyles(styles);

export interface EmptyPageContentProps extends StyledComponentProps<typeof styles>, EmptyPageContentBaseProps {
}

const EmptyPageContent = (props: EmptyPageContentProps) => {
    const {
        background,
        classes: _ignore2,
        customActions,
        iconHeader,
        textHeader,
        icon,
        text,
        actions,
        actionSteps,
        textFooter
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <React.Fragment>
            {background && (
                <div className={classes.background}>
                    {background}
                </div>
            )}
            <Grid container className={classes.root} justifyContent="center" alignContent="center" spacing={3}>
                {icon && (
                    <Grid item xs={12} className={classes.icon}>
                        {icon}
                    </Grid>
                )}
                {iconHeader && (
                    <Grid item xs={12} className={classes.iconHeader}>
                        <Typography component="span" variant="h5" className={classes.iconHeader}>{translate(iconHeader)}</Typography>
                    </Grid>
                )}
                {actions && (
                    <Grid item xs={12} className={classes.actions}>
                        {actions}
                    </Grid>
                )}
                <Grid item xs={12}><Divider className={classes.divider}/></Grid>
                <Grid item xs={12} container spacing={0}>
                    {textHeader && (
                        <Grid item xs={12} className={classes.textHeader}>
                            <Typography component="span" className={classes.textHeader}>{translate(textHeader)}</Typography>
                        </Grid>
                    )}
                    {text && (
                        <Grid item xs={12} className={classes.text}>
                            <Typography component="span" className={classes.text}>
                                <Trans i18nKey={text}>
                                    <br/>
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                    <Grid container spacing={2} className={classes.mainContainer}>
                        {actionSteps && Array.isArray(actionSteps) && (
                            actionSteps.map((step, index) => (
                                <Grid key={index} item xs={2} container className={classes.stepsContainer}>
                                    <Typography component="span" className={classes.circleStep}>
                                        <p className={classes.textStep}>{translate(step?.title)}</p>
                                    </Typography>
                                    <Trans i18nKey={step?.text}>
                                        <br/>
                                    </Trans>
                                </Grid>
                            ))
                        )}
                    </Grid>
                    {customActions && (
                        <Grid item xs={12} className={classes.customActions}>
                            {customActions}
                        </Grid>
                    )}
                    {textFooter && (
                        <Grid item xs={12} className={classes.text}>
                            <Typography component="span" className={classes.text}>
                                <Trans i18nKey={textFooter}>
                                    <br/>
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default EmptyPageContent;
import {PointClickEventObject} from "highcharts";
import {TFunction} from "i18next";
import {Theme} from "@mui/material";
import {get, isEqual} from "lodash";
import {clearLinkedSeries} from "./clearLinkedSeries";
import {handlePointClick} from "./handlePointClick";
import {ChartRef, SetLocationTooltip} from "./UseLocationsMap";

/**
 * Handle click events on the linked mappoint series. Simply finds the original corresponding point for the point that was clicked
 * then passes it to the usual on point click method handler.
 */
export const handleLinkedPointClick = (chart: ChartRef, setTooltip: SetLocationTooltip, translate: TFunction, theme: Theme) => (event: PointClickEventObject) => {
    const clickedPointSource: any = get(event, "target.point.source");
    const originalPoint: any = clickedPointSource && chart.current?.series
        .filter((series) => get(series, "userOptions.originalData"))
        .flatMap((series) => get(series, "userOptions.originalData"))
        .find((point: any) => point && point.pointData && isEqual(
            {...point.pointData, linkedSeriesParent: undefined, series: undefined, linkedSeriesSources: undefined},
            {...clickedPointSource, linkedSeriesParent: undefined, series: undefined, linkedSeriesSources: undefined}
        ));

    if (originalPoint) {
        return handlePointClick(chart, originalPoint, setTooltip, translate, theme);
    } else {
        clearLinkedSeries(chart, setTooltip);
        return false;
    }
};
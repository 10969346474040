import {Theme} from '@mui/material';

export type Status = string | boolean | undefined

export const getStatusColorFromTheme = (status: Status, theme: Theme) => theme.palette[getStatusColor(status)].main;

const getStatusColor = (status: Status) => {
    switch (status) {
        case true:
        case "online":
        case "completed":
            return "success";
        case "connecting":
        case "degraded":
            return "warning";
        case false:
        case "offline":
        case "failed":
            return "error";
        case "pending":
        default:
            return "info";
    }
};
import React from "react";
import {ConnectedForm, DialogBody, Tab} from "@cuda-react/core";
import SdwanConnectorGeneralStep
    from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorGeneralStep";
import SdwanConnectorServicesStep
    from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorServicesStep";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../apiResources";

const useStyles = makeStyles({
    dialogContent: {
        width: 850
    }
});

interface EditSdwanConnectorProps {
    onClose?: any,
    onSuccess?: any,
    id?: number | string
}

export const EditSdwanConnector: React.FC<EditSdwanConnectorProps> = ({onClose, onSuccess, id}) => {
    const classes = useStyles();

    return (
        <DialogBody
            title="tesseract.network.sdwanConnector.dialog.edit.dialogTitle"
            onClose={onClose}
            classes={classes}
            form
        >
            <ConnectedForm
                tabbed
                resource={apiResources.sdwanConnector}
                onSubmitSuccess={() => {
                    onSuccess?.();
                    onClose?.();
                }}
                params={{id}}
                onCancel={onClose}
                allowDirtyNavigation
            >
                <Tab label="tesseract.network.sdwanConnector.dialog.general.sectionTitle">
                    <SdwanConnectorGeneralStep/>
                </Tab>
                <Tab label="tesseract.network.sdwanConnector.dialog.servers.sectionTitle">
                    <SdwanConnectorServicesStep/>
                </Tab>
            </ConnectedForm>
        </DialogBody>
    );
};

export default EditSdwanConnector;
import React from "react";
import {BaseBarCard} from "../../baseCards";
import {colorFunctionError, numberFormatter} from "../../../utils";
import {CardSizes, DashboardCardProps, DomainDataPoint} from "../../../typesAndConstants";
import {useTranslation} from "react-i18next";
import {BarGridEntry} from "../../statistics/BarGrid";
import {useReportingData} from "../../../contexts";
import {sortBy} from "lodash";
import {DomainsIcon} from "@cuda-react/icons";
import {useTheme} from "@mui/material";

export interface BlockedDomains {
    value: number,
    name: string,
    total: number
}

/**
 * Shows a list of blocked domains and a "progress bar".
 * The progress bar does not actually show the ratio of the values but is dependent on the position of the value.
 */
export const TopBlockedDomainsCard = (props: DashboardCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("TopBlockedDomainsCard", preview);
    const {blockedDomainDataPoints = []} = data || {};
    const maxEntry = Math.max(...blockedDomainDataPoints.map((domainDataPoint: DomainDataPoint) => domainDataPoint.numberOfSessions));
    const theme = useTheme();

    const entries: BarGridEntry[] = sortBy(blockedDomainDataPoints, "numberOfSessions").reverse().map((domainDataPoint: DomainDataPoint) => ({
        title: domainDataPoint.label,
        value: size === CardSizes.small ?
            numberFormatter(domainDataPoint.numberOfSessions) :
            translate('stratosReporting.topBlockedDomainsCard.sessions', {
                count: domainDataPoint.numberOfSessions,
                formattedCount: numberFormatter(domainDataPoint.numberOfSessions)
            }),
        icon: <DomainsIcon size={20}/>,
        barProgress: (100.0 / maxEntry) * domainDataPoint.numberOfSessions,
        color: colorFunctionError((100.0 / maxEntry) * domainDataPoint.numberOfSessions, theme),
        onClick: addFilter && domainDataPoint.id !== "Consolidated" ? () => addFilter(domainDataPoint.id, "domainDataPoints") : undefined,
        tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            entries={entries}
            title="stratosReporting.topBlockedDomainsCard.title"
            size={size}
            preview={preview}
            loading={loading && !data.blockedDomainDataPoints}
            noData={!loading && !data.blockedDomainDataPoints}
            activeFilter
        />
    );
};
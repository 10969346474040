import React from "react";
import {CardSizes, DashboardCardProps, RecentEventsCard, useCardFetch} from "@stratos/reporting";
import {generateQueryString} from "@cuda-react/core";
import {useHistory} from "react-router";

export interface RecentAdminEventsProps extends DashboardCardProps {}

export const previewData = {
    content: [
        {
            id: 131,
            createdUsername: "",
            created: "2023-02-27T05:56:41.97548Z",
            event: "configurePointToSiteVpn",
            eventName: "Configure VPN",
            type: "administrator",
            typeName: "Administrator Action",
            status: "successful",
            statusName: "Successful",
            description: "Configured VPN for Workspace 'Testing'. IDP metadata URL 'https://azure.com/T-12345678/idp/location'. SP Entity ID 'https://cloudgenwan.barracudanetworks.com/a701d78b-fbe3-476d-a808-3a8b79e29662'. Client Network Range '10.0.0.0/8',  Client Network Pool Size '24', Client Network Primary DNS '', Client Network Secondary DNS '', Client Network Routing Policy 'Internet Access'.",
            remoteAddress: "54.161.91.20",
        },
        {
            id: 129,
            createdUsername: "",
            created: "2023-02-27T05:56:21.873766Z",
            event: "configurePointToSiteVpn",
            eventName: "Configure VPN",
            type: "administrator",
            typeName: "Administrator Action",
            status: "successful",
            statusName: "Successful",
            description: "Configured VPN for Workspace 'Production'. IDP metadata URL 'https://azure.com/T-12345678/idp/location'. SP Entity ID 'https://cloudgenwan.barracudanetworks.com/26c417b8-320e-45fb-b3c3-396091a37287'. Client Network Range '10.0.0.0/8',  Client Network Pool Size '24', Client Network Primary DNS '', Client Network Secondary DNS '', Client Network Routing Policy 'Internet Access'.",
            remoteAddress: "54.161.91.20",
        },
        {
            id: 126,
            createdUsername: "guest@barracuda-internal.com",
            created: "2023-02-27T05:56:13.180161Z",
            event: "createNotification",
            eventName: "Create Notification",
            type: "administrator",
            typeName: "Administrator Action",
            status: "successful",
            statusName: "Successful",
            description: "Created notification 'Notify IT' for '4' events with a status of 'Failed'.",
            remoteAddress: "54.161.91.20"
        },
        {
            id: 125,
            createdUsername: "guest@barracuda-internal.com",
            created: "2023-02-27T05:56:13.178903Z",
            event: "createNotification",
            eventName: "Create Notification",
            type: "administrator",
            typeName: "Administrator Action",
            status: "successful",
            statusName: "Successful",
            description: "Created notification 'Notify Admin' for '1' events with a status of 'Failed'.",
            remoteAddress: "54.161.91.20"
        },
        {
            id: 127,
            createdUsername: "guest@barracuda-internal.com",
            created: "2023-02-27T05:56:13.168246Z",
            event: "createExplicitIntrusionPrevention",
            eventName: "Create Explicit IPS",
            type: "administrator",
            typeName: "Administrator Action",
            status: "successful",
            statusName: "Successful",
            description: "Created Explicit IPS 'IgnoreSecurity'. Applying action 'Log' to '1013767008' 'User/Groups' and '0.0.0.0/0' 'Networks'.",
            remoteAddress: "54.161.91.20"
        }
    ],
    page: {totalElements: 5}
};

export const RecentAdminEvents = (props: RecentAdminEventsProps) => {
    const {size, preview} = props;
    const {data = {}, loading} = useCardFetch({
        url: !preview ? "/api/v1/webui/{userData.currentAccount}/audit/entries" : undefined,
        params: {
            pagination: {page: 1, perPage: size === CardSizes.large ? 10 : 5},
            sort: {field: "created", order: "DESC"},
            filter: {type: "administrator"}
        },
        formatData: (data: any) => data
    });
    const adminEvents = preview ? previewData : data;
    const auditLogTypeLinks = (entryType: any) => `/logs/audit${generateQueryString({type: [entryType]}, 'created', 'desc')}`;
    const {push} = useHistory();

    return (
        <RecentEventsCard
            title="tesseract.dashboard.cards.recentEvents.adminTabTitle"
            entries={adminEvents.content || []}
            size={size}
            preview={preview}
            total={adminEvents.page?.totalElements}
            footerKey="tesseract.dashboard.cards.recentEvents.latest"
            loading={!adminEvents.page && loading}
            onEventClick={(event: any) => push(auditLogTypeLinks(event.type))}
        />
    );
};
import React, {useCallback, useEffect, useState} from "react";
import WizardIntroduction from "./steps/WizardIntroduction";
import WizardComplete from "./steps/WizardComplete";
import WizardStep1 from "./steps/WizardStep1";
import WizardStep2 from "./steps/WizardStep2";
import WizardStep3 from "./steps/WizardStep3";
import WizardStep4 from "./steps/WizardStep4";
import WizardStep5 from "./steps/WizardStep5";
import {Steps, useZtnaWizardState} from "../../hooks/StateWizardHooks";
import {Skeleton} from "@barracuda-internal/bds-core";
import {BasicPage, useGlobalParam, useMixpanel, usePersistedState} from "@cuda-react/core";
import {StepState} from "@cuda-react/icons";

const stepTransitions = [Steps.intro, Steps.step1, Steps.step2, Steps.step3, Steps.step4, Steps.step5, Steps.complete];
const WizardComponents = {
    [Steps.intro]: WizardIntroduction,
    [Steps.step1]: WizardStep1,
    [Steps.step2]: WizardStep2,
    [Steps.step3]: WizardStep3,
    [Steps.step4]: WizardStep4,
    [Steps.step5]: WizardStep5,
    [Steps.complete]: WizardComplete
};

export interface SavedFormData {
    applicationId?: string,
    users?: string[]
    policyUsers?: string[]
};

const SetupWizard = () => {
    const wizardState = useZtnaWizardState();
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [savedFormData, setSavedFormData] = usePersistedState<SavedFormData>("ztnaSetupFormData-" + virtualWanId, {});
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeStep, setActiveStep] = useState<null | Steps>(null);
    const incompleteSteps: (null | Steps)[] = stepTransitions.filter((step) => {
        if (step === Steps.intro) {
            return !wizardState[Steps.step1].loading && wizardState[Steps.step1].state === StepState.Incomplete;
        }
        return step === Steps.complete || (!wizardState[step].loading && wizardState[step].state === StepState.Incomplete);
    });
    const registerAction = useMixpanel("Wiz ZTNA" + (activeStep !== Steps.complete ? " " + activeStep : ""), false);

    const updateSavedFormData = useCallback((newData: SavedFormData) => {
        setSavedFormData({...savedFormData, ...newData});
    }, [savedFormData]);

    useEffect(() => {
        if (!wizardState.loading) {
            if (!incompleteSteps.includes(activeStep)) {
                setActiveStep(incompleteSteps[0]);
            }
        }
    }, [wizardState, incompleteSteps, activeStep]);

    useEffect(() => {
        if (!wizardState.loading && initialLoad) {
            setInitialLoad(false);
        }
    }, [wizardState.loading, initialLoad]);

    const onSuccess = useCallback(() => {
        registerAction(activeStep === Steps.intro ? "Setup" : "Next");
        const currentIndex = incompleteSteps.findIndex((step) => step === activeStep);
        setActiveStep(incompleteSteps[currentIndex >= 0 ? currentIndex + 1 : 1] || Steps.complete);
        // @ts-ignore we're safely accessing the method, so its fine that some indexes (null, intro, complete) are missing
        wizardState[activeStep]?.refresh?.();
    }, [wizardState, activeStep, incompleteSteps]);

    if (initialLoad) {
        return (
            <BasicPage
                title="tesseract.setupWizard.pageTitle"
            >
                <Skeleton sx={{width: 800, height: 500, transform: "none", margin: "auto", marginTop: 3}}/>
            </BasicPage>
        );
    }

    const WizardStep = activeStep ? WizardComponents[activeStep] : null;
    return WizardStep ? (
        <WizardStep
            wizardStates={wizardState}
            onSuccess={onSuccess}
            savedFormData={savedFormData}
            updateSavedFormData={updateSavedFormData}
            registerStepAction={registerAction}
        />
    ) : null;
};

export default SetupWizard;
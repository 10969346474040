import React from "react";
import {
    BooleanInput,
    ConnectedForm,
    DialogBody,
    getArrayDataContent,
    SelectInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import {get, isEqual} from "lodash";
import {useGeneratedSourceCriteriaSection} from "../../../../../../hooks/sourceAndDestinationHooks";
import generateDestinationCriteriaSection
    from "../../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import {
    formatRequestCriteria,
    formatResourceCriteria
} from "../../../../../../components/sourceAndDestination/formUtils";
import {settingsIcons} from "../../../../../../components/iconMapping";
import apiResources from "../../../../../../apiResources";

const hideInput = (key: any, type: any) => (value: any, data: any) => !get(data, key) || get(data, key) !== type;

/**
 * For now we are using this workaround to get the karma tests working.
 */

interface CreateEditUrlExceptionsProps {
    create?: boolean;
    id?: string | number;
    onClose: () => void;
    onSuccess?: () => void;
}

export const CreateEditUrlExceptions: React.FC<CreateEditUrlExceptionsProps> = ({create, id, onSuccess, ...props}) => {
    const registerAction = useMixpanel("Web Filter Explicit", false);
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterActions, {}, {}, true)[0]?.data);
    const categoryChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]?.data);

    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    return (
        <DialogBody
            {...props}
            form
            title={create ?
                "tesseract.security.urlFiltering.exceptions.create.dialogTitle" :
                "tesseract.security.urlFiltering.exceptions.edit.dialogTitle"
            }
        >
            <ConnectedForm
                resource={apiResources.urlExceptions}
                // @ts-ignore
                form="urlExceptions"
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={(response) => {
                    registerAction(create ? "Create" : "Update", {
                        action: response.action,
                        sourceType: response.source?.type,
                        destinationType: response.destination?.type
                    });
                    onSuccess?.();
                    props.onClose();
                }}
                onCancel={props.onClose}
                formatRequestData={formatRequestCriteria(virtualWanId)}
                formatResourceData={formatResourceCriteria}
                initialValues={create ? {source: {allSites: true}, destination: {allSites: true}} : undefined}
                allowDirtyNavigation
            >
                <TextInput
                    source="name"
                    label="tesseract.security.urlFiltering.exceptions.name"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.name"
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.security.urlFiltering.exceptions.description"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.description"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                />
                <SelectInput
                    source="action"
                    label="tesseract.security.urlFiltering.exceptions.action"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.action"
                    // @ts-ignore
                    icons={settingsIcons}
                    iconMap={settingsIcons}
                    choices={actionChoices}
                    isRequired
                />
                <BooleanInput
                    source="silent"
                    label="tesseract.security.urlFiltering.exceptions.silent"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.silent"
                    disable={hideInput("action", "block")}
                />
                {useGeneratedSourceCriteriaSection(["network", "site", "userOrGroup"])}
                {generateDestinationCriteriaSection(["category", "domain", "customCategory"], categoryChoices)}
            </ConnectedForm>
        </DialogBody>
    );
};


export const propsAreEqual = (val1: any, val2: any) => isEqual(val1.id, val2.id) && isEqual(val1.create, val2.create);

export default React.memo(CreateEditUrlExceptions, propsAreEqual);
import {Series} from "highcharts";
import {get} from "lodash";
import {ChartRef, SetLocationTooltip} from "./UseLocationsMap";


export const isLinkSeries = (series: Series) => ["linked-points-line-series", "linked-points-point-series", "linked-points-source-point-series"]
    .includes(get(series, "userOptions.id") as any);

/**
 * Clears the linked point/line series, and resets the tooltip.
 *
 * @function
 * @param chart
 * @param setTooltip
 */
export const clearLinkedSeries = (chart: ChartRef, setTooltip: SetLocationTooltip, redraw: boolean = true) => {
    setTooltip(null);
    if (chart.current?.series) {
        chart.current.series
            .filter(isLinkSeries)
            .forEach((series) => {
                series.update({type: "mappoint", showInLegend: false, visible: false}, false);
            });
        chart.current.series
            .filter((series) => get(series, "userOptions.originalData"))
            .forEach((series) => {
                const data = get(series, "userOptions.originalData");
                series.setData(data as any, false);
            });

        if (redraw) {
            chart.current.redraw();
        }
    }
};
import {createContext, useContext} from "react";
import {Account, Workspace} from "@cuda-react/core";


export interface TenantWorkspaceProps {
    availableAccounts: Account[],
    changeWorkspace: (account: Account, workspaceId: string | number) => void,
    currentAccount?: Account,
    defaultAccount?: Account,
    refreshAccounts: () => void,
    workspace?: Workspace
    workspaceChoices: Workspace[],
    swapAccount: (account: Account) => void
}

export const TenantWorkspaceContext = createContext<TenantWorkspaceProps>({
    availableAccounts: [],
    changeWorkspace: () => {},
    refreshAccounts: () => {},
    swapAccount: () => {},
    workspaceChoices: []
});


export const useTenantWorkspace = () => useContext(TenantWorkspaceContext);
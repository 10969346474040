import {
    ActionButtonsField,
    ActionDialog,
    Card,
    ConnectedTable,
    CrudTypes,
    DateField,
    DeleteDialog,
    getArrayDataContent,
    getDataContent,
    PropGateway,
    SelectedChoiceField,
    useCrudSubscription,
    useDeepCompareMemo,
    useMixpanel
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import React, {useRef} from "react";
import DefaultUpdateWindow from "./DefaultUpdateWindow";
import CreateUpdateDialog from "./dialog/CreateUpdateDialog";
import EditUpdateDialog from "./dialog/EditUpdateDialog";
import apiResources from "../../../../../apiResources";
import {useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {GatewayIcon, SiteIcon} from "@cuda-react/icons";


const useStyles = makeStyles((theme) => ({
    message: {
        margin: theme.spacing(2, 4, 0)
    },
    tableContainer: {
        padding: theme.spacing(0, 6, 2)
    }
}));

export const convertApplianceId = (type: any) => (appliance: any) => ({
    ...appliance,
    key: `${type}:${appliance.key}`,
    type
});

const getAvailableAppliances = (appliances: any, type: any, updateWindowsContent: any, availableUpdates: any) => {
    const appliancesWithUpdateWindows = updateWindowsContent
        .filter((window: any) => window.configurationType === type)
        .map((window: any) => window.configurationId);

    return appliances
        .filter((appliance: any) => {
            if (appliancesWithUpdateWindows.includes(appliance.key)) {
                return false;
            }
            return availableUpdates?.configurationUpdates?.some((item: any) => item.type === type && item.id === appliance.key);
        })
        .map(convertApplianceId(type));
};

const FirmwareUpdates = () => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const registerAction = useMixpanel("Network Settings Firmware Updates", false);
    const tableRefreshRef = useRef<any>();
    const [updateNowData, , refreshUpdateNow] = useCrudSubscription(CrudTypes.GET, apiResources.updateNow, {}, {pollInterval: 30000});
    const updateNow = getDataContent(updateNowData?.data, false);

    const [availableUpdatesData, , refreshAvailableUpdates] = useCrudSubscription(CrudTypes.GET, apiResources.updatesAvailable, {}, {pollInterval: 30000});
    const availableUpdates = getDataContent(availableUpdatesData, {});
    const [updateWindowsData, , refreshUpdatesList] = useCrudSubscription(CrudTypes.GET, apiResources.updatesList);
    const updateWindowsContent = getArrayDataContent(updateWindowsData);
    const [currentUpdateDefaultData, , refreshDefault] = useCrudSubscription(CrudTypes.GET, apiResources.updateDefault, {filter: {allConfigurations: true}});
    const currentUpdateDefault = getArrayDataContent(currentUpdateDefaultData)[0];

    const [gatewaysData, , refreshGateways] = useCrudSubscription(CrudTypes.GET, apiResources.gatewayNames, {}, {pollInterval: 30000});
    // @ts-ignore as not sure to remove another argument as this function is expecting only one argument
    const gateways = getArrayDataContent(gatewaysData, []);
    const [sitesData, , refreshSites] = useCrudSubscription(CrudTypes.GET, apiResources.siteNames, {}, {pollInterval: 30000});
    // @ts-ignore as not sure to remove another argument as this function is expecting only one argument
    const sites = getArrayDataContent(sitesData, []);

    const onUpdateNow = () => {
        refreshUpdateNow();
        refreshGateways();
        refreshSites();
        refreshAvailableUpdates();
        refreshDefault();
        registerAction("Install Now");
    };
    const onUpdatesChange = () => {
        refreshUpdatesList();
        refreshDefault();
        tableRefreshRef.current && tableRefreshRef.current();
    };

    const availableAppliances = useDeepCompareMemo(() => {
        const availableGateways = getAvailableAppliances(gateways, "gateway", updateWindowsContent, availableUpdates);
        const availableSites = getAvailableAppliances(sites, "site", updateWindowsContent, availableUpdates);
        return [...availableGateways, ...availableSites];
    }, [gateways, sites, updateWindowsContent, availableUpdates]);

    return (
        <Card title="tesseract.settings.tabs.updates.firmware.title">
            <Typography className={classes.message}>
                {translate("tesseract.settings.tabs.updates.firmware.description")}
            </Typography>
            <div className={classes.tableContainer} id="FirmwareUpdateWindows">
                <ConnectedTable
                    actions={[
                        <CreateUpdateDialog
                            key={0}
                            disabled={!updateNow || availableAppliances.length === 0}
                            disabledMessage="tesseract.settings.tabs.updates.firmware.noAppliancesMessage"
                            appliances={availableAppliances}
                            availableUpdates={availableUpdates}
                            onSuccess={() => {
                                onUpdatesChange();
                                registerAction("Create");
                            }}
                        />,
                        <ActionDialog
                            key={1}
                            crudType={CrudTypes.CREATE}
                            resource={apiResources.updateNow}
                            buttonText="tesseract.settings.tabs.updates.firmware.updateNow.buttonLabel"
                            disabledMessage="tesseract.settings.tabs.updates.firmware.updateNow.disabledMessage"
                            title="tesseract.settings.tabs.updates.firmware.updateNow.dialogTitle"
                            message="tesseract.settings.tabs.updates.firmware.updateNow.dialogMessage"
                            disabled={!updateNow}
                            onSuccess={onUpdateNow}
                        />,
                        <DefaultUpdateWindow
                            key={2}
                            // @ts-ignore as type definition already in the file
                            left
                            onSuccess={() => {
                                onUpdatesChange();
                                registerAction("Default Update");
                            }}
                            data={currentUpdateDefault}
                            availableUpdates={availableUpdates}
                            disabled={!updateNow}
                        />
                    ]}
                    resource={updateNow && apiResources.update}
                    // @ts-ignore  as type definition already in the file
                    defaultSort={{field: "from", order: "ASC"}}
                    defaultFilter={{allConfigurations: false, installNow: false}}
                    noDataWithFilterMessage={
                        availableUpdates?.configurationUpdates
                            ? "tesseract.settings.tabs.updates.firmware.noWindowsMessage"
                            : "tesseract.settings.tabs.updates.firmware.noUpdatesMessage"
                    }
                    flat
                    noRouter
                    refreshRef={tableRefreshRef}
                >
                    <PropGateway
                        source="configurationId"
                        label="tesseract.settings.tabs.updates.firmware.fields.appliance"
                        editProps={(props: any) => {
                            // eslint-disable-next-line react/prop-types
                            const isGateway = props?.data?.configurationType === "gateway";
                            return {
                                ...props,
                                choices: isGateway ? gateways : sites,
                                icon: isGateway ? <GatewayIcon/> : <SiteIcon/>
                            };
                        }}
                    >
                        <SelectedChoiceField source="configurationId"/>
                    </PropGateway>
                    <DateField
                        source="from"
                        label="tesseract.settings.tabs.updates.firmware.fields.from"
                    />
                    <DateField
                        source="to"
                        label="tesseract.settings.tabs.updates.firmware.fields.to"
                    />
                    <ActionButtonsField
                        source="gatewayId"
                        width={128}
                    >
                        <DeleteDialog
                            resource={apiResources.update}
                            title="tesseract.settings.tabs.updates.firmware.delete.title"
                            message="tesseract.settings.tabs.updates.firmware.delete.message"
                            onSuccess={() => {
                                onUpdatesChange();
                                registerAction("Delete");
                            }}
                        />
                        <EditUpdateDialog
                            appliances={availableAppliances}
                            availableUpdates={availableUpdates}
                            onSuccess={() => {
                                onUpdatesChange();
                                registerAction("Update");
                            }}
                        />
                    </ActionButtonsField>
                </ConnectedTable>
            </div>
        </Card>);
};

export default FirmwareUpdates;
import React from "react";
import {ButtonDialog} from "@cuda-react/core";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import CustomAppContent from "./CustomAppContent";

interface EditCustomAppDialogProps {
    id: ((data?: Record<string, any>) => string) | string | number;
    data?: Record<string, any>;
    onSuccess?: () => void;
}

const EditCustomAppDialog: React.FC<EditCustomAppDialogProps> = ({id, data, onSuccess}) => {
    const appId = (data && (typeof id === "function")) ? id(data) : id;
    return (
        <ButtonDialog buttonIcon={<Edit id="edit-rule-icon"/>}>
            <CustomAppContent title="tesseract.customApps.dialogs.edit.dialogTitle"
                // @ts-ignore
                              id={appId} onSuccess={onSuccess}/>
        </ButtonDialog>
    );
};

export default EditCustomAppDialog;
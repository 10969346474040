import React, {useState} from "react";
import {formatErrorMessage} from "../../../utils";
import {get} from "lodash";
import {useTranslation} from "react-i18next";
import {Button, ButtonProps, FormControl, FormHelperText, Grid, IconButton} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Close} from "@barracuda-internal/bds-core/dist/Icons/Core";

const styles = (theme: Theme) => createStyles({
    fieldSet: {
        width: 256
    },
    fileInput: {
        display: "none"
    },
    button: {
        width: "inherit",
        height: 40
    },
    fileUploadButton: {
        width: "auto",
        flexGrow: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    fileUploadDelete: {
        width: 28,
        flexGrow: 0,
        flexShrink: 0
    },
    fileUploadLabel: {
        width: "auto",
        flexGrow: 1,
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    fileUploadContainer: {
        width: "100%",
        height: 40,
        border: "1px solid " + theme.palette.divider,
        borderRadius: 4,
        "&> *": {
            display: "inline-block"
        }
    },
    error: {
        borderColor: theme.palette.error.main
    },
    fileUploadButtonContainer: {
        display: "inline-block",
        paddingRight: theme.spacing(1)
    },
    imageWrapper: {
        height: 40,
        width: 40,
        position: "absolute",
        right: `calc(-40px - ${theme.spacing(1)})`,
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center"
    }
});
const useStyles = makeOverrideableStyles("File", styles);

export interface FileProps extends StyledComponentProps<typeof styles> {
    /**
     * type of the file that should be accepted.
     * Passed directly to the HTML input.
     */
    accept?: string,
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: any,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: () => void,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (fileContents: {
        filename?: string,
        data?: string
    }) => void,
    /**
     * passed to the Button component as props.
     */
    options?: ButtonProps,
    /**
     * used to generate a unique ID for the input.
     */
    id: string,
    /**
     * type of the file to be upload.
     */
    readType?: "base64" | "string",
    /**
     * if true, a delete button is shown which clears the value back to an empty object {}.
     */
    showDelete?: boolean,
    /**
     * if provided, a preview image will be shown using the provided render method.
     */
    imagePreview?: (value?: { data?: any, filename?: string }) => JSX.Element,
    /**
     * text to show on the file upload button
     */
    buttonLabel?: string
}

/**
 * Renders an file input for choose/upload a file.
 *
 * Note that you cannot set the "value" of this input type. This is a limitation on the html input itself, which only allows empty strings "" to be set as value.
 */
export const File = (props: FileProps) => {
    const {
        accept,
        value,
        onChange,
        onBlur,
        error,
        id,
        options = {},
        disabled,
        showDelete,
        imagePreview,
        readType = "base64",
        buttonLabel = "cuda.inputs.file.buttonLabel"
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [uploadError, setUploadError] = useState<string | false | undefined | null>(false);
    const unsupportedMessage = !window.FileReader ? translate("cuda.inputs.file.browserUnsupported") : undefined;
    const errorMessage = unsupportedMessage || uploadError || error;
    return (
        <FormControl component="fieldset" className={classes.fieldSet} error={!!errorMessage}>
            <input
                type="file"
                id={id && "file-input-" + id}
                disabled={disabled || !!unsupportedMessage}
                accept={accept}
                className={classes.fileInput}
                value={(!value?.filename || !value?.data) ? "" : undefined}
                onChange={(event) => {
                    setUploadError(false);
                    const file = get(event, "target.files[0]", get(event, "target.dataTransfer.files[0]"));
                    if (file) {
                        const reader = new window.FileReader();

                        reader.onload = (onloadEvent) => {

                            const result = onloadEvent.target?.result as string;
                            if (result) {
                                onChange?.({
                                    filename: (file as File).name,
                                    data: readType === "base64" ? result.split(";")[1].split(",")[1] : result,
                                });
                                onBlur?.();
                            }
                        };
                        reader.onerror = (error) => {
                            setUploadError(get(error, "target.error.message") || translate("cuda.inputs.file.uploadError"));
                            onChange?.({});
                            onBlur?.();
                        };
                        if (readType === "string") {
                            reader.readAsBinaryString(file);
                        } else {
                            reader.readAsDataURL(file);
                        }
                    } else {
                        onChange?.({});
                        onBlur?.();
                    }
                }}
            />
            <Grid
                container
                wrap="nowrap"
                alignItems="center"
                className={classNames(classes.fileUploadContainer, errorMessage && classes.error)}
            >
                <Grid item className={classes.fileUploadButtonContainer}>
                    <label htmlFor={id && "file-input-" + id} className={classes.fileUploadButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            //@ts-ignore This prop does exist, not sure why BDS types are not reporting it
                            component="div"
                            className={classes.button}
                            disabled={disabled || !!unsupportedMessage}
                            {...options}
                        >
                            {translate(buttonLabel)}
                        </Button>
                    </label>
                </Grid>
                <Grid item className={classes.fileUploadLabel}>
                    {get(value, "filename", " ")}
                </Grid>
                {value?.data && showDelete && (
                    <Grid item className={classes.fileUploadDelete}>
                        <IconButton
                            size="small"
                            onClick={(event) => {
                                onChange?.({});
                            }}
                            disabled={disabled}
                        >
                            <Close fontSize="small"/>
                        </IconButton>
                    </Grid>
                )}
                {imagePreview && (
                    <Grid item className={classes.imageWrapper}>
                        {imagePreview(value)}
                    </Grid>
                )}
            </Grid>
            {errorMessage && (
                <FormHelperText>{formatErrorMessage(errorMessage)}</FormHelperText>
            )}
        </FormControl>
    );
};
export default File;
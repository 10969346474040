import React from "react";
import {
    FormSection,
    getArrayDataContent,
    SelectInput,
    StatusIconField,
    Tab,
    TableInput,
    TextArrayInput,
    TextField,
    TextInput,
    useCrudProps
} from "@cuda-react/core";
import {get} from "lodash";
import {useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";
import {useWatch} from "react-hook-form";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";
import {dhcpStatusIcons} from "../../../iconMapping";

interface DhcpNetworksTableInputProps {
    series?: string
}

const DhcpNetworksTableInput: React.FC<DhcpNetworksTableInputProps> = ({series}) => {
    const [translate] = useTranslation();
    const inputValidations = useSiteInputValidations();
    const wans = useWatch({name: "wans"});
    const isBridgeConfigured = wans && wans?.filter((wan: any) => wan.mode === "bridge").length > 0;
    const isSc: boolean | undefined = (series === "S");
    const dhcpTypeChoices = getArrayDataContent(useCrudProps(apiResources.siteConfigurationTypes)[0]?.data);
    const filteredDhcpTypeChoices = (isSc) ? dhcpTypeChoices.filter((choice: any) => choice.key !== "dhcpRelay") : dhcpTypeChoices;

    return (
        <TableInput
            source="lans"
            //@ts-ignore not sure why this one complains...
            id="dhcp-networks"
            optionValue="name"
            labels={{
                addTitle: "tesseract.appliances.settings.dhcp.addNetworkTitle",
                addButtonText: "tesseract.appliances.settings.dhcp.addNetworkButtonText",
                editTitle: "tesseract.appliances.settings.dhcp.editNetworkTitle",
                deleteTitle: "tesseract.appliances.settings.dhcp.deleteNetworkTitle",
                deleteMessage: "tesseract.appliances.settings.dhcp.deleteNetworkMessage"
            }}
            minCellWidth={164}
            noCreate
            noDelete
            defaultFormValues={{dhcp: {type: "dhcpService", defaultLeaseTime: "86400", maxLeaseTime: "86400"}}}
            formChildren={[
                <Tab label="tesseract.appliances.settings.dhcp.networks.basicTabTitle" key="basic">
                    <FormSection title="tesseract.appliances.settings.dhcp.networks.networkSectionTitle">
                        <SelectInput
                            source="dhcp.type"
                            label="tesseract.appliances.settings.dhcp.networks.dhcpRelayType"
                            description="tesseract.appliances.settings.dhcp.descriptions.dhcpRelayType"
                            choices={filteredDhcpTypeChoices}
                            isRequired
                            validate={(value, data) => {
                                if (get(data, "dhcp.type") !== "disabled" && isBridgeConfigured) {
                                    return translate("tesseract.appliances.settings.dhcp.validation.dhcpEnable");
                                } else {
                                    return undefined;
                                }
                            }}
                        />
                        <TextInput
                            source="name"
                            label="tesseract.appliances.settings.dhcp.networks.port"
                            description="tesseract.appliances.settings.dhcp.descriptions.networks.port"
                            require={(value, data) => isSc ? get(data, "dhcp.type") === "dhcpService" : get(data, "dhcp.type") === "dhcpService"}
                            disable={(value, data) => isSc ? get(data, "dhcp.type") === "disabled" : get(data, "dhcp.type") !== "dhcpService"}
                        />
                        <TextInput
                            source="dhcp.description"
                            label="tesseract.appliances.settings.dhcp.networks.description"
                            description="tesseract.appliances.settings.dhcp.descriptions.networks.description"
                        />
                    </FormSection>
                    <FormSection title="tesseract.appliances.settings.dhcp.networks.basicSectionTitle">
                        <TextInput
                            source="dhcp.firstIp"
                            label="tesseract.appliances.settings.dhcp.networks.firstIp"
                            description="tesseract.appliances.settings.dhcp.descriptions.networks.firstIp"
                            validate={inputValidations.validateIp}
                            require={(value, data) => isSc ? get(data, "dhcp.type") === "dhcpService" : get(data, "dhcp.type") === "dhcpService"}
                            disable={(value, data) => isSc ? get(data, "dhcp.type") === "disabled" : get(data, "dhcp.type") !== "dhcpService"}
                        />
                        <TextInput
                            source="dhcp.lastIp"
                            label="tesseract.appliances.settings.dhcp.networks.lastIp"
                            description="tesseract.appliances.settings.dhcp.descriptions.networks.lastIp"
                            validate={inputValidations.validateIp}
                            require={(value, data) => isSc ? get(data, "dhcp.type") === "dhcpService" : get(data, "dhcp.type") === "dhcpService"}
                            disable={(value, data) => isSc ? get(data, "dhcp.type") === "disabled" : get(data, "dhcp.type") !== "dhcpService"}
                        />
                        <TextInput
                            source="dhcp.domain"
                            label="tesseract.appliances.settings.dhcp.networks.domain"
                            description="tesseract.appliances.settings.dhcp.descriptions.networks.domain"
                            disable={(value, data) => isSc ? get(data, "dhcp.type") === "disabled" : get(data, "dhcp.type") !== "dhcpService"}
                        />
                    </FormSection>
                </Tab>,
                !isSc ?
                    <Tab
                        label="tesseract.appliances.settings.dhcp.networks.advancedTabTitle"
                        key="advanced"
                    >
                        <FormSection title="tesseract.appliances.settings.dhcp.networks.advancedSectionTitle">
                            <TextArrayInput
                                source="dhcp.dnsServers"
                                label="tesseract.appliances.settings.dhcp.networks.dnsServers"
                                description="tesseract.appliances.settings.dhcp.descriptions.networks.dnsServers"
                                validate={inputValidations.validateIpArray}
                                disable={(value, data) => get(data, "dhcp.type") !== "dhcpService"}
                            />
                            <TextInput
                                source="dhcp.bootFileName"
                                label="tesseract.appliances.settings.dhcp.networks.bootFileName"
                                description="tesseract.appliances.settings.dhcp.descriptions.networks.bootFileName"
                                disable={(value, data) => get(data, "dhcp.type") !== "dhcpService"}
                            />
                            <TextInput
                                source="dhcp.defaultLeaseTime"
                                label="tesseract.appliances.settings.dhcp.networks.defaultLeaseTime"
                                description="tesseract.appliances.settings.dhcp.descriptions.networks.defaultLeaseTime"
                                validate={inputValidations.validateDefaultLeaseTime}
                                require={(value, data) => get(data, "dhcp.type") === "dhcpService"}
                                disable={(value, data) => get(data, "dhcp.type") !== "dhcpService"}
                            />
                            <TextInput
                                source="dhcp.maxLeaseTime"
                                label="tesseract.appliances.settings.dhcp.networks.maxLeaseTime"
                                description="tesseract.appliances.settings.dhcp.descriptions.networks.maxLeaseTime"
                                validate={inputValidations.validateMaxLeaseTime}
                                require={(value, data) => get(data, "dhcp.type") === "dhcpService"}
                                disable={(value, data) => get(data, "dhcp.type") !== "dhcpService"}
                            />
                            <TextArrayInput
                                source="dhcp.winsServers"
                                label="tesseract.appliances.settings.dhcp.networks.winsServers"
                                description="tesseract.appliances.settings.dhcp.descriptions.networks.winsServers"
                                validate={inputValidations.validateIpArray}
                                disable={(value, data) => get(data, "dhcp.type") !== "dhcpService"}
                            />
                            <TextInput
                                source="dhcp.tftpHostname"
                                label="tesseract.appliances.settings.dhcp.networks.tftpHostname"
                                description="tesseract.appliances.settings.dhcp.descriptions.networks.tftpHostname"
                                disable={(value, data) => get(data, "dhcp.type") !== "dhcpService"}
                            />
                            <TextInput
                                source="dhcp.tftpIpAddress"
                                label="tesseract.appliances.settings.dhcp.networks.tftpIpAddress"
                                description="tesseract.appliances.settings.dhcp.descriptions.networks.tftpIpAddress"
                                validate={inputValidations.validateIp}
                                disable={(value, data) => get(data, "dhcp.type") !== "dhcpService"}
                            />
                            <TextInput
                                source="dhcp.vendorOptions"
                                label="tesseract.appliances.settings.dhcp.networks.vendorOptions"
                                description="tesseract.appliances.settings.dhcp.descriptions.networks.vendorOptions"
                                disable={(value, data) => get(data, "dhcp.type") !== "dhcpService"}
                            />
                        </FormSection>
                    </Tab>
                    : null
            ]}
            tabbed
        >
            <StatusIconField
                source="dhcp.type"
                label="tesseract.appliances.settings.dhcp.networks.enable"
                width={192}
                iconMap={dhcpStatusIcons}
                text={(status: string) => translate("tesseract.appliances.settings.dhcp.networks." + status)}
            />
            <TextField
                source="name"
                label="tesseract.appliances.settings.dhcp.networks.port"
            />
            <TextField
                source="dhcp.firstIp"
                label="tesseract.appliances.settings.dhcp.networks.firstIp"
            />
            <TextField
                source="dhcp.lastIp"
                label="tesseract.appliances.settings.dhcp.networks.lastIp"
            />
        </TableInput>
    );
};


export default DhcpNetworksTableInput;
type NumberFormatter = (bytes: number, units?: string[]) => string;

/* Formatter for formatting a large number into a suffixed smaller number */
export const numberFormatter: NumberFormatter = (number, units = ["", "K", "M", "B", "T"], showDecimal = false) => {
    let value = number || 0;
    let unitIndex = 0;

    while (value >= 1000 && unitIndex < units.length) {
        value = value / 1000;
        unitIndex++;
    }

    const formattedValue = (showDecimal || number >= 10 || number <= -10) && value < 100.0 && value > 0.0 ? Number(value.toPrecision(2)) : value.toFixed(0);
    return formattedValue + units[unitIndex];
};

const byteUnits = [" B", " KB", " MB", " GB", " TB", " PB"];

/* Formatter for formatting axis/tooltip labels with correct Bytes unit */
export const bytesFormatter: NumberFormatter = (bytes) => numberFormatter(bytes, byteUnits);
import {Breadcrumbs, Link, Typography} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {Link as RouterLink} from 'react-router-dom';
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.text.primary,
        margin: 0
    },
    title: {
        fontWeight: 300,
        fontSize: 18,
        display: "inline-block",
        color: theme.palette.common.black,
        lineHeight: 1
    },
    link: {
        '&:hover': {
            color: theme.palette.primary.main
        }
    }
});

const useStyles = makeOverrideableStyles("BreadcrumbsTitle", styles);

export interface BreadcrumbsTitleProps {
    /** the title to render */
    title: string | { text: string, path: string },

    /** the subtitle to render after the main title. Used to designate child pages */
    subtitle?: string |
        { text: string, path: string } |
        (string | {text: string, path: string })[],

    /** className to be passed down to root element */
    className?: string
}

/**
 * Renders a page title
 */
export const BreadcrumbsTitle = (props: BreadcrumbsTitleProps): JSX.Element => {
    const {title, subtitle, className} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);
    const titleArray = [title, ...(!Array.isArray(subtitle) ? [subtitle] : subtitle)];

    return (
        <div
            className={classNames(classes.root, className)}
            id="cuda-react-page-title"
        >
            <Breadcrumbs separator="›">
                {titleArray.map((title, index) => {
                    if (!title) {
                        return null;
                    }
                    return typeof title === "string" ? (
                        <Typography
                            key={index}
                            variant="h6"
                            component="div"
                            className={classes.title}
                        >
                            {translate(title)}
                        </Typography>
                    ) : (
                        <Link
                            component={RouterLink}
                            underline="none"
                            // @ts-ignore this is passed through to the RouterLink
                            to={title.path}
                            key={index}
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                className={classNames(classes.title, classes.link)}
                            >
                                {translate(title.text)}
                            </Typography>
                        </Link>
                    );
                })}
        </Breadcrumbs>
    </div>
    );
};

export default BreadcrumbsTitle;
/*
* To override settings in CudaReact, put an object in the overrides object with the name
* CR<name_that_you_pass_to_the_overrideableStyles_function>
*/
// TODO: Removed styling overrides so it uses the default colors from BDS
export default {
    // components: {
    //     CRAppLayout: {
    //         styleOverrides: {
    //             main: {
    //                 minWidth: 720
    //             }
    //         }
    //     }
    // }
} as any;
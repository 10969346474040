import React from "react";
import {Critical, Success, Warning} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import {get} from "lodash";
import {Help} from "../";

export type StatusIconProps = {
    /**
     * A single icon to render, which has the value "status" passed to it.
     */
    icon?: JSX.Element,
    /**
     * A map of icons to render, for each expected value of "status". Additionally, a value can be provided for the key "default",
     * and this icon will be rendered whenever status fails to match the other entries. Otherwise "Help" con will be rendered.
     */
    iconMap?: Record<any, JSX.Element>,
    /**
     * The current status value. Either passed through to "icon" or used to select icon from iconMap.
     */
    status?: any
}

export const StatusIcon = ({icon, iconMap, status, ...props}: StatusIconProps & Record<string, any>) => {
    if (icon) {
        return React.cloneElement(icon, {...props, status});
    }

    return React.cloneElement(get(iconMap, status, iconMap?.["default"]) || <Help/>, {...props});
};

StatusIcon.defaultProps = {
    iconMap: {
        //@ts-ignore it complains about using boolean as an index, but this seems to work fine (and is needed)
        [true]: <Success id="cuda-icon-okay" sx={{color: "success.main"}}/>,
        ok: <Success id="cuda-icon-okay" sx={{color: "success.main"}}/>,
        warning: <Warning sx={{color: "warning.main"}}/>,
        //@ts-ignore it complains about using boolean as an index, but this seems to work fine (and is needed)
        [false]: <Critical sx={{color: "error.main"}}/>,
        error: <Critical sx={{color: "error.main"}}/>,
        errorWarning: <Warning sx={{color: "error.main"}}/>,
        //@ts-ignore it complains about sx TODO: fix this (or remove the sx line)
        default: <Help sx={{color: "neutral.main"}}/>
    }
};

export default StatusIcon;
import React from "react";
import DialogBody from "../../dialog/DialogBody/DialogBody";
import {Form, TabbedForm} from "../../forms";
import {CommonFormProps} from "../../forms/interfaces";
import {ClonableChildren} from "../../../utils/commonTypes";

export interface BaseTableInputAddEditFormProps {
    tabbed: true
}
export interface TableInputAddEditFormProps {
    /**
     * the passed components for the Form.
     */
    children?: ClonableChildren,
    /**
     * title of the Add/Edit form.
     */
    title?: string,
    /**
     * function to call when clicking on save.
     * @function
     * @param {object} data the data to send back to save.
     */
    onSubmit?: (submittedValues?: any, initialValues?: any) => void,
    /**
     * function to call when closing the dialog. It's also called after saving the form.
     */
    onClose?: CommonFormProps["onCancel"],
    /**
     * initial errors to display. If set, this prop should be:
     * - an object with one or more error message matching a provided input.
     * */
    initialErrors?: object,
    /**
     * object with the initial values of the form.
     */
    initialValue?: CommonFormProps["initialValues"],
    /**
     * function to validate all the form data.
     * @param {object} values all the form values.
     * @param {object} initialValues the initial values passed to the component as prop
     * @return array of errors to display
     */
    formValidate?: (values: any, initialValues: any) => object | undefined,
    /**
     * function to process the data structure to send back.
     * @param {values} data of the form.
     */
    formatFormData?: (values: any) => any,
    /**
     * The index of the row currently being edited, undefined if an item is being added
     */
    rowIndex?: number,
    /**
     * passed to the TableInputAddEditDialog as prop. If true, component will get rendered inside a Tab component.
     */
    tabbed?: boolean,
    /**
     * override Form/TabbedForm props
     */
    formProps?: Partial<Omit<CommonFormProps, "validate" | "initialValues" | "save" | "onCancel" | "formButtonToolbarProps" | "children">>
}

/**
 * This component is part of the TableInput component.
 * It renders the form of the Add/Edit action.
 */
export const TableInputAddEditForm = (props: TableInputAddEditFormProps) => {
    const {
        children,
        title,
        initialErrors,
        initialValue,
        onSubmit,
        formValidate,
        formatFormData,
        onClose,
        rowIndex,
        tabbed,
        formProps
    } = props;

    return (
        <DialogBody
            title={title}
            form
        >
            {React.createElement(
                // @ts-ignore Not sure what this is complaining about...
                tabbed ? TabbedForm : Form,
                {
                    validate: (values: any) => {
                        const errors = formValidate ? formValidate(values, initialValue) || {} : {};

                        return errors;
                    },
                    initialValues: initialValue,
                    initialErrors,
                    save: (values: any) => {
                        onSubmit?.(formatFormData ? formatFormData(values) : values, initialValue);
                        onClose && onClose();
                        return Promise.resolve(values);
                    },
                    onCancel: onClose,
                    formButtonToolbarProps: {
                        label: "cuda.state.ok"
                    },
                    ...formProps
                },
                React.Children.toArray(children).filter((child): child is React.ReactElement => !!child).map((child) => React.cloneElement(child, {rowIndex}))
            )}
        </DialogBody>
    );
};

export default TableInputAddEditForm;
import {Button, IconButton, Tooltip} from "@barracuda-internal/bds-core";
import MenuButton, {MenuButtonProps} from "../MenuButton/MenuButton";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import React from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {ConditionalWrapper} from "../../functional";

const styles = (theme: Theme) => createStyles({
    buttonIcon: {},
    button: {},
    tooltip: {
        ...theme.typography.body2
    }
});
const useStyles = makeOverrideableStyles("DiseableableButton", styles);

export interface DisableableButtonProps extends StyledComponentProps<typeof styles> {
    /**
     * if a buttonIcon is supplied, but no buttonText nor useMenu, then an IconButton will be rendered, using the provided icon.
     *
     * Otherwise a MenuButton or Button will be rendered, with the provided icon.
     */
    buttonIcon?: React.ReactNode,
    /**
     * if a buttonEndIcon is supplied, then an IconButton will be rendered using the provided icon on the right side of the label.
     */
    buttonEndIcon?: React.ReactNode,
    /**
     * additional props to be provided to the button component.
     */
    buttonProps?: any,
    /**
     * text to be shown on the Button/MenuButton.
     */
    buttonText?: string,
    /**
     * if true, disables the button.
     */
    disabled?: boolean,
    /**
     * if disabled, and a message is provided, then a tooltip is shown with the provided message.
     */
    disabledMessage?: string,
    /**
     * the on click handler for Icon/Plain buttons
     */
    onClick?: () => void,
    /**
     * if provided, the button is rendered as a MenuButton and provided the menuItems
     */
    menuItems?: MenuButtonProps["menuItems"]
}

const DisableableButton = (props: DisableableButtonProps) => {
    const {
        buttonIcon,
        buttonEndIcon,
        buttonText,
        disabledMessage,
        disabled,
        onClick,
        menuItems,
        buttonProps
    } = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);

    return (
        <ConditionalWrapper
            condition={disabledMessage && disabled || false}
            wrapper={(children) => (
                <Tooltip
                    title={disabledMessage ? translate(disabledMessage) : ""}
                    placement="top"
                    classes={{tooltip: classes.tooltip}}
                >
                <span>
                    {children}
                </span>
                </Tooltip>
            )}
        >
            {menuItems && (
                <MenuButton
                    menuItems={menuItems}
                    label={buttonText || ""}
                    icon={buttonIcon}
                    endIcon={buttonEndIcon}
                    disabled={disabled}
                    {...buttonProps}
                />
            ) || ((buttonIcon && !buttonText) ? (
                <IconButton
                    size="small"
                    {...buttonProps}
                    disabled={disabled}
                    onClick={onClick}
                    className={classes.buttonIcon}
                >
                    {buttonIcon}
                </IconButton>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    {...buttonProps}
                    disabled={disabled}
                    onClick={onClick}
                    className={classes.button}
                    startIcon={buttonIcon}
                >
                    {translate(buttonText || "")}
                </Button>
            ))}
        </ConditionalWrapper>
    );
};

export default DisableableButton;
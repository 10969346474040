import SvgIcon from "@mui/material/SvgIcon";
import {useTheme} from "@mui/material";
import React from "react";

export interface UsersIconProps {
    /** size (in pixels) of icon */
    size?: number
}

export const UsersIcon = (props: UsersIconProps) => {
    const theme = useTheme();
    return (
        <SvgIcon viewBox="2 2 21 21" style={{width: props.size ?? 50, height: props.size ?? 50}}>
            <g transform="matrix(1,0,0,1,-5.42152,-5.995)">
                <rect x="5.422" y="5.995" width="24.026" height="24.026" style={{fill: "none"}}/>
                <g transform="matrix(1,0,0,1,7.2213,8.64218)">
                    <path
                        d="M14.833,4.746C14.833,2.194 12.766,0.127 10.213,0.127C7.661,0.127 5.594,2.194 5.594,4.746C5.594,7.299 7.661,9.366 10.213,9.366C12.766,9.366 14.833,7.299 14.833,4.746ZM12.523,4.746C12.523,6.017 11.484,7.056 10.213,7.056C8.943,7.056 7.903,6.017 7.903,4.746C7.903,3.476 8.943,2.436 10.213,2.436C11.484,2.436 12.523,3.476 12.523,4.746ZM0.974,16.295L0.974,18.605L19.452,18.605L19.452,16.295C19.452,13.223 13.297,11.676 10.213,11.676C7.13,11.676 0.974,13.223 0.974,16.295ZM3.284,16.295C3.515,15.475 7.095,13.985 10.213,13.985C13.32,13.985 16.889,15.464 17.143,16.295L3.284,16.295Z"
                        style={{fill: theme.palette.reporting.fontColor, fillRule: "nonzero"}}
                    />
                </g>
            </g>
        </SvgIcon>
    );
};
import {Divider, Grid, Skeleton, Typography} from "@barracuda-internal/bds-core";
import React, {ReactNode} from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {StyledComponentProps} from "@cuda-react/theme";
import {CardSizeOptions} from "../../../typesAndConstants";

const styles = (theme: Theme) => createStyles({
    container: {
        minHeight: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6)})`
    },
    overviewContainer: {
        margin: theme.spacing(4, 0, 0)
    },
    overviewText: {
        marginRight: theme.spacing(1),
        fontSize: 30,
        color: theme.palette.reporting.fontColor,
        fontWeight: 300
    },
    overviewValue: {
        color: theme.palette.general.graphite,
        fontWeight: 700,
        fontSize: 30
    },
    dividerRow: {
        marginTop: theme.spacing(2),
        marginBottom: 0,
        width: "calc(100% - 80px)"
    },
    divider: {
        width: "100%"
    },
    detailsRow: {
        width: "100%",
        display: "flex",
        height: 112,
        alignItems: "center",
        flexBasis: "unset"
    },
    detailsContainer: {
        maxHeight: 112,
        flexWrap: "nowrap"
    }
});
const useStyles = makeStyles(styles);

export interface StatisticsOverviewProps extends StyledComponentProps<typeof styles> {
    /** overview statistic title. */
    overviewTitle: string,
    /** the overview value to display */
    overviewValue: ReactNode | undefined,
    /** array of StatisticsDetails children to display under the overview. */
    children?: ReactNode | ReactNode[] | undefined,
}

/**
 * Renders a statistics overview title and value, with space for additional detailed statistics.
 */
export const StatisticsOverview = (props: StatisticsOverviewProps) => {
    const {children, overviewTitle, overviewValue} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <Grid container direction="column" alignItems="center" className={classes.container}>
            <Grid item xs={12}>
                <Grid container alignItems="center" className={classes.overviewContainer}>
                    <Typography variant="h4" className={classes.overviewText}>
                        {translate(overviewTitle)}
                    </Typography>
                    {overviewValue !== undefined ?
                        <Typography variant="h4" className={classes.overviewValue}>
                            {overviewValue}
                        </Typography>
                        :
                        <Skeleton variant="rounded" width={28} height={34}/>
                    }
                </Grid>
            </Grid>
            <React.Fragment>
                <Grid item xs={12} className={classes.dividerRow}>
                    <Divider className={classes.divider}/>
                </Grid>
                {children ? (
                        <Grid item xs={12} className={classes.detailsRow}>
                            <Grid container className={classes.detailsContainer} alignItems="flex-end" justifyContent="space-evenly">
                                {children}
                            </Grid>
                        </Grid>)
                    :
                    <Grid container alignItems="flex-end" justifyContent="center" className={classes.detailsContainer}>
                        <Grid item xs={11}>
                            <Skeleton variant="rounded" height={102}/>
                        </Grid>
                    </Grid>
                }
            </React.Fragment>
        </Grid>
    );
};
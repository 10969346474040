import {Appliance} from "./useAppliances";
import {generateQueryString, parseQueryString, useDeepCompareEffect, usePersistedState} from "@cuda-react/core";
import {timeframes} from "./useFilterOptions";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {get, unset} from "lodash";

export default (presetAppliance?: Appliance) => {
    const [applianceId, setApplianceId] = usePersistedState("dashboardSelectedAppliance", presetAppliance?.key || "_NONE_");
    const [timeframe, setTimeframe] = usePersistedState("dashboardSelectedTimeframe", timeframes[0].key);
    const [otherFilters, setOtherFilters] = useState<Record<string, any>>({});
    const {location, replace} = useHistory();

    // Get current state from location (on initial load only)
    useEffect(() => {
        try {
            const initialFilters: any = get(parseQueryString(location.search), "filter", {});
            const {applianceId, timeframe, ...otherFilters} = initialFilters;
            applianceId && setApplianceId(applianceId);
            timeframe && setTimeframe(timeframe);
            Object.keys(otherFilters).length > 0 && setOtherFilters(otherFilters);
        } catch {
            // do nothing on failure to parse, just use default starting values
        }
    }, []);

    // Update router location with filters
    useDeepCompareEffect(() => {
        const search = generateQueryString({
            applianceId,
            timeframe,
            ...otherFilters
        });

        if (search !== location.search) {
            replace({
                ...location,
                search
            });
        }
    }, [applianceId, timeframe, otherFilters]);

    const updateFilter = (filterValue: any, filterId: string) => {
        if (filterId === "applianceId") {
            setApplianceId(filterValue);
        } else if (filterId === "timeframe") {
            setTimeframe(filterValue);
        } else {
            setOtherFilters((state) => ({
                ...state,
                [filterId]: filterValue
            }));
        }
    };
    const removeFilter = (filterId: string, filterValue?: any) => {
        setOtherFilters((state) => {
            const newState = {...state};
            unset(newState, filterId);
            return newState;
        });
    };
    const clearAllFilters = () => {
        setOtherFilters({});
    };

    return {
        activeFilters: {applianceId, timeframe, ...otherFilters},
        updateFilter,
        removeFilter,
        clearAllFilters
    };
};
/* eslint-disable id-length */
import {useDeepCompareMemo} from "@cuda-react/core";
import {ComponentType, useState} from "react";
import {CardSizes, CardsRecord, DashboardCardProps, GridSizeOptions} from "../typesAndConstants";

export interface CardComponent {
    componentId: string,
    component: ComponentType<any>,
    props: Partial<DashboardCardProps>,
}

export type LayoutLocation = {
    x: number,
    y: number,
    w: number,
    h: number,
}
export type LayoutItem = LayoutLocation & {
    id: string,
    card?: CardComponent,
    hasInsert?: boolean,
    isMoving?: boolean,
    onDragEvent?: (event: any, draggedData: { x: number, y: number }) => void,
    onDragFinish?: (event: any, draggedData: { x: number, y: number }) => void,
    onAdd?: (selectedCardId?: string, selectedSize?: CardSizes) => void,
    forceSmall?: boolean
    addWidget?: boolean
}

const SizeToRows = {
    [CardSizes.small]: 1,
    [CardSizes.medium]: 2,
    [CardSizes.large]: 2,
};
const SizeToColumns = {
    [CardSizes.small]: 1,
    [CardSizes.medium]: 1,
    [CardSizes.large]: 2
};

const positionConflictsX = (itemA: LayoutLocation, itemB: LayoutLocation): boolean =>
    !(itemA.x + itemA.w <= itemB.x || itemB.x + itemB.w <= itemA.x);
const positionConflictsY = (itemA: LayoutLocation, itemB: LayoutLocation): boolean =>
    !(itemA.y + itemA.h <= itemB.y || itemB.y + itemB.h <= itemA.y);
const positionConflicts = (itemA: LayoutLocation, itemB: LayoutLocation): boolean =>
    positionConflictsX(itemA, itemB) && positionConflictsY(itemA, itemB);
const positionConflictsInLayout = (item: LayoutLocation, layout: LayoutLocation[]) =>
    layout.some((existingItem) => positionConflicts(item, existingItem));

const getFiller = (xPosition: number, yPosition: number, draggedItem?: LayoutLocation, forceSmall?: boolean, widget?: boolean): LayoutItem => {
    const fillerItem = {
        id: `gapFiller.${xPosition}.${yPosition}`,
        x: xPosition,
        y: yPosition,
        w: 1,
        h: 1,
        forceSmall
    };
    const hasInsert = draggedItem && positionConflicts(draggedItem, fillerItem);
    return {
        ...fillerItem,
        hasInsert,
        addWidget: widget
    };
};

const getNewPosition = <T extends LayoutLocation>(location: T, deltaX: number, deltaY: number, columns: number): T => {
    const xIndexOffset = Math.ceil((deltaX + GridSizeOptions.columnMargin) / (GridSizeOptions.columnWidth + GridSizeOptions.columnMargin)) - 1;
    const yIndexOffset = Math.ceil((deltaY + GridSizeOptions.columnMargin) / (GridSizeOptions.rowHeight + GridSizeOptions.columnMargin)) - 1;
    const newX = location.x + xIndexOffset > columns ? columns : (location.x + xIndexOffset < 0 ? 0 : location.x + xIndexOffset);
    const newY = location.y + yIndexOffset < 0 ? 0 : location.y + yIndexOffset;
    return {...location, x: newX, y: newY};
};

const useEditableGridLayout = (
    cardComponents: Array<CardComponent>,
    onChange: (cards: CardComponent[]) => void,
    editMode: boolean,
    cards: CardsRecord,
    width: number
): LayoutItem[] => {
    const maxColumnsForWidth = Math.floor((width + GridSizeOptions.columnMargin) / (GridSizeOptions.columnWidth + GridSizeOptions.columnMargin));
    const columns = maxColumnsForWidth >= 1 ? maxColumnsForWidth : 1;
    const forceSmall = columns <= 1;
    const [draggedLayoutItem, setDraggedLayoutItem] = useState<LayoutItem | undefined>(undefined);

    // drag handlers
    const onDragFinishHandler = (layoutItem: LayoutItem, layout: LayoutItem[]): LayoutItem["onDragFinish"] => (event, {
        x: deltaX,
        y: deltaY
    }) => {
        const draggedLocation = getNewPosition(layoutItem, deltaX, deltaY, columns);

        // Only perform update if new drag location does not match current location of card
        if (!positionConflicts(draggedLocation, layoutItem)) {
            const removeDragged = layout.filter((item) => item.id !== layoutItem.id);
            const targetIndex = removeDragged.findIndex((item) => positionConflicts(draggedLocation, item));
            const newCardComponents = [
                ...removeDragged.slice(0, targetIndex >= 0 ? targetIndex : removeDragged.length),
                layoutItem,
                ...removeDragged.slice(targetIndex >= 0 ? targetIndex : removeDragged.length),
            ].map((item) => item.card).filter((card): card is CardComponent => !!card);
            onChange?.(newCardComponents);
        }
        setDraggedLayoutItem(undefined);
    };
    const onAddHandler = (layoutItem: LayoutItem, layout: LayoutItem[]) => (selectedCardId?: string, selectedSize?: CardSizes) => {
        if (selectedCardId && cards[selectedCardId] && selectedSize) {
            const targetIndex = layout.findIndex((item) => layoutItem.id === item.id);
            const newCardComponents = [
                ...layout.slice(0, targetIndex >= 0 ? targetIndex : layout.length),
                {
                    card: {
                        componentId: selectedCardId,
                        component: cards[selectedCardId].component,
                        props: {size: selectedSize}
                    }
                },
                ...layout.slice(targetIndex >= 0 ? targetIndex : layout.length),
            ].map((item) => item.card).filter((card): card is CardComponent => !!card);
            onChange?.(newCardComponents);
        }
    };

    // Calculate the layout from the cards and current page columns
    const layout = useDeepCompareMemo(() => cardComponents.reduce((layout, card, index) => {
        const size = forceSmall ? CardSizes.small : card.props.size || CardSizes.small;
        const lastLayoutItem = layout[layout.length - 1] || {x: -1, y: 0, w: 1, h: 1, id: null};
        const layoutItem = {
            x: lastLayoutItem.x + lastLayoutItem.w,
            y: lastLayoutItem.y,
            w: SizeToRows[size],
            h: SizeToColumns[size],
            id: card.componentId,
            forceSmall,
            card
        };
        const newLayout = [...layout];

        // Check the card does not conflict another location, or overhang the row, otherwise move it until it doesn't
        while (positionConflictsInLayout(layoutItem, newLayout) || layoutItem.x + layoutItem.w > columns) {
            let xPosition = layoutItem.x + 1;
            let yPosition = layoutItem.y;
            // If location of card is going to overhang the end of the row, put it on the next row down
            if (layoutItem.x + layoutItem.w > columns) {
                xPosition = 0;
                yPosition += 1;
            }
            // If movement leaves a fillable gap, add a filler card
            if (!positionConflictsInLayout({
                x: layoutItem.x,
                y: layoutItem.y,
                w: 1,
                h: 1
            }, newLayout) && layoutItem.x + 1 <= columns) {
                newLayout.push(getFiller(layoutItem.x, layoutItem.y, draggedLayoutItem, forceSmall, editMode));
            }
            layoutItem.x = xPosition;
            layoutItem.y = yPosition;
        }

        const hasInsert = draggedLayoutItem && positionConflicts(draggedLayoutItem, layoutItem);
        const isMoving = draggedLayoutItem?.id === layoutItem.id;

        newLayout.push({
            ...layoutItem,
            hasInsert,
            isMoving,
            onDragEvent: (event, {x: deltaX, y: deltaY}) => {
                const draggedLocation = getNewPosition(layoutItem, deltaX, deltaY, columns);
                setDraggedLayoutItem({...draggedLocation, w: 1, h: 1});
            },
        });

        // In edit mode, add extra filler at the end
        if (index + 1 === cardComponents.length && editMode) {
            const widgetLocation = {x: layoutItem.x + layoutItem.w, y: layoutItem.y, w: 1, h: 1};
            while (positionConflictsInLayout(widgetLocation, newLayout) || widgetLocation.x + widgetLocation.w > columns) {
                widgetLocation.x += 1;
                if (widgetLocation.x + widgetLocation.w > columns) {
                    widgetLocation.x = 0;
                    widgetLocation.y += 1;
                }
            }
            newLayout.push(getFiller(widgetLocation.x, widgetLocation.y, draggedLayoutItem, forceSmall, true));
        }

        // If there is only 1 row filled, ensure entire row has an entry by adding blanks
        if (index + 1 === cardComponents.length) {
            while (newLayout[newLayout.length - 1].x + newLayout[newLayout.length - 1].w < columns && newLayout[newLayout.length - 1].y === 0) {
                newLayout.push(getFiller(newLayout[newLayout.length - 1].x + newLayout[newLayout.length - 1].w, 0, draggedLayoutItem, forceSmall, false));
            }
        }
        return newLayout;
    }, [] as LayoutItem[]), [columns, draggedLayoutItem, cardComponents, editMode, forceSmall]);

    // If item is being dragged, and is not over anything, set last index
    if (draggedLayoutItem && !layout.some(({hasInsert}) => hasInsert)) {
        layout[layout.length - 1].hasInsert = true;
    }

    return layout.map((layoutItem) => ({
        ...layoutItem,
        onDragFinish: onDragFinishHandler(layoutItem, layout),
        onAdd: onAddHandler(layoutItem, layout)
    }));
};

export default useEditableGridLayout;
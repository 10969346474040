import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const DashboardIcon = ({className}: { className?: string }) => (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-dashboard"
            viewBox="0 0 60 60"
        >
            <path
                style={{fill: "currentColor", strokeWidth: 0}}
                d="M7.4,32.5h20.1V7.4H7.4v25.1ZM7.4,52.6h20.1v-15H7.4v15ZM32.5,52.6h20.1v-25.1h-20.1v25.1ZM32.5,7.4v15h20.1V7.4h-20.1Z"
            />
        </SvgIcon>
    );

export default DashboardIcon;
import React from "react";
import {
    ConnectedFormPage,
    FormSection,
    getArrayDataContent,
    SelectInput,
    TextInput,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    message: {
        padding: theme.spacing(1, 1, 4, 3),
        color: theme.palette.text.secondary
    }
}));

const VmActivation = () => {
    const countries = getArrayDataContent(useCrudProps(apiResources.vmActivationCountries, {}, {}, true)[0]?.data);
    const [translate] = useTranslation();
    const classes = useStyles();

    const registerAction = useMixpanel("VM Activation");

    return (
        <ConnectedFormPage
            title="tesseract.network.pageTitle"
            subtitle="tesseract.network.vmActivation"
            resource={apiResources.vmActivation}
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
            canReset
        >
            <FormSection title="tesseract.settings.tabs.vmActivation.billingDetailsSection" newStyle hideBorder>
                <Typography variant="body1" className={classes.message}>
                    {translate("tesseract.network.vmActivationMessage")}
                </Typography>
                <TextInput
                    source="companyName"
                    label="tesseract.settings.tabs.vmActivation.companyName"
                    isRequired
                    newStyle
                />
                <TextInput
                    source="firstName"
                    label="tesseract.settings.tabs.vmActivation.firstName"
                    isRequired
                    newStyle
                />
                <TextInput
                    source="lastName"
                    label="tesseract.settings.tabs.vmActivation.lastName"
                    isRequired
                    newStyle
                />
                <TextInput
                    source="emailAddress"
                    label="tesseract.settings.tabs.vmActivation.emailAddress"
                    isRequired
                    newStyle
                />
                <TextInput
                    source="phoneNumber"
                    label="tesseract.settings.tabs.vmActivation.phoneNumber"
                    isRequired
                    newStyle
                />
            </FormSection>
            <FormSection title="tesseract.settings.tabs.vmActivation.billingAddressSection" newStyle>
                <TextInput
                    source="address"
                    label="tesseract.settings.tabs.vmActivation.address"
                    isRequired
                    newStyle
                />
                <TextInput
                    source="address2"
                    label="tesseract.settings.tabs.vmActivation.address2"
                    newStyle
                />
                <TextInput
                    source="city"
                    label="tesseract.settings.tabs.vmActivation.city"
                    isRequired
                    newStyle
                />
                <TextInput
                    source="state"
                    label="tesseract.settings.tabs.vmActivation.state"
                    isRequired
                    newStyle
                />
                <SelectInput
                    source="country"
                    label="tesseract.settings.tabs.vmActivation.country"
                    choices={countries}
                    isRequired
                    newStyle
                />
                <TextInput
                    source="zip"
                    label="tesseract.settings.tabs.vmActivation.zip"
                    isRequired
                    newStyle
                />
            </FormSection>
        </ConnectedFormPage>
    );
};

export default VmActivation;
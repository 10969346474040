import React, {useEffect} from 'react';
import {CreatedUserDirectory} from '../UserDirectoriesTypes';
import {CrudTypes, DialogBody, useCrudFetch, useMixpanel} from '@cuda-react/core';
import apiResources from '../../../../../apiResources';
import {useTranslation} from 'react-i18next';
import {StatusIcon} from '@cuda-react/icons';
import {Box, CircularProgress, Typography} from '@barracuda-internal/bds-core';

interface TestConnectivityDialogProps {
    directory: CreatedUserDirectory;
    onClose: () => void;
}

export const TestConnectivityDialog = ({directory, onClose}: TestConnectivityDialogProps) => {
    const [translate] = useTranslation();
    const registerAction = useMixpanel('User Directories', false);

    const [{data, error}, loading, performConnectionTest] = useCrudFetch(CrudTypes.CREATE, apiResources.testConnection);
    useEffect(() => {
        performConnectionTest({id: directory.id});

        registerAction('LDAP Connection Test');
    }, []);

    return (
        <DialogBody
            title={translate('tesseract.identity.userDirectories.connectivity.title', {name: directory.displayName})}
            onClose={onClose}
        >
            <Box display="flex" height={150} width={400} p={3} alignItems="center" justifyContent="center">
                {
                    loading ? (
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography variant="body1" pr={2}>
                                {translate('tesseract.identity.userDirectories.connectivity.connecting')}
                            </Typography>
                            <CircularProgress size={20} key="spinner"/>
                        </Box>
                    ) : (
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <StatusIcon status={data?.status === 'success' ? 'ok':'error'} />
                            <Typography pl={1}>{translate(`tesseract.identity.userDirectories.connectivity.${data?.status}`)}</Typography>
                        </Box>
                    )
                }
            </Box>
        </DialogBody>
    );
};
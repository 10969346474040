import {DashboardCardProps} from "../typesAndConstants";
import {usePersistedState} from "@cuda-react/core";
import {useCallback} from "react";

export interface ActiveDashboardCard {
    componentId: string,
    size: DashboardCardProps["size"],
    additionalProps?: DashboardCardProps["additionalProps"],
}

export interface Dashboard {
    /** name of the dashboard (must be unique) */
    name: string,
    /** cards in the dashboard */
    cards: ActiveDashboardCard[],
    /** name of the dashboard template (if created from a template) */
    template?: string
}

export interface DashboardsResponse {
    activeDashboard?: Dashboard,
    dashboards: Dashboard[]
    setActiveDashboard: (dashboardName: Dashboard["name"]) => void,
    addUpdateDashboard: (newDashboardValue: Dashboard, originalDashboardName?: Dashboard["name"]) => void,
    deleteDashboard: (dashboardName: Dashboard["name"]) => void
}

export default (pageId: string, defaultDashboards: Dashboard[], remoteStoredDashboards?: Dashboard[], onDashboardsChange?: (dashboards: Dashboard[]) => Promise<void>): DashboardsResponse => {
    const [localStoredDashboards = [], setLocalDashboards] = usePersistedState<Dashboard[]>(pageId, defaultDashboards);
    const [activeDashboardName = "", setActiveDashboardName] = usePersistedState<Dashboard["name"]>(pageId + "_active", "");
    const useLocal = !remoteStoredDashboards;
    const savedDashboards = useLocal ? localStoredDashboards : remoteStoredDashboards;
    const resolvedDashboards = !Array.isArray(savedDashboards) || savedDashboards?.length === 0 ? defaultDashboards : savedDashboards;
    const activeDashboard = resolvedDashboards?.find((dashboard) => dashboard?.name === activeDashboardName) || resolvedDashboards?.[0] || {};

    const addUpdateDashboard = useCallback((newDashboardValue: Dashboard, originalDashboardName?: Dashboard["name"]) => {
        const dashboardToUpdateIndex = resolvedDashboards.findIndex((dashboard) => dashboard.name === originalDashboardName);
        let newDashboards;
        if (dashboardToUpdateIndex >= 0) {
            newDashboards = [
                ...resolvedDashboards.slice(0, dashboardToUpdateIndex),
                newDashboardValue,
                ...resolvedDashboards.slice(dashboardToUpdateIndex + 1)
            ];
        } else {
            newDashboards = [...resolvedDashboards, newDashboardValue];
        }

        if (useLocal) {
            setLocalDashboards(newDashboards);
            setActiveDashboardName(newDashboardValue?.name);
        } else if (onDashboardsChange) {
            onDashboardsChange(newDashboards)
                .then(() => setActiveDashboardName(newDashboardValue?.name));
        }
    }, [pageId, onDashboardsChange, useLocal, resolvedDashboards]);

    const deleteDashboard = useCallback((dashboardName: Dashboard["name"]) => {
        const dashboardToRemoveIndex = resolvedDashboards.findIndex((dashboard) => dashboard.name === dashboardName);
        if (dashboardToRemoveIndex >= 0) {
            const newDashboards = [
                ...resolvedDashboards.slice(0, dashboardToRemoveIndex),
                ...resolvedDashboards.slice(dashboardToRemoveIndex + 1)
            ];
            if (useLocal) {
                setLocalDashboards(newDashboards);
                setActiveDashboardName(newDashboards[dashboardToRemoveIndex]?.name || newDashboards[dashboardToRemoveIndex - 1]?.name);
            } else if (onDashboardsChange) {
                onDashboardsChange(newDashboards)
                    .then(() => {
                        setActiveDashboardName(newDashboards[dashboardToRemoveIndex]?.name || newDashboards[dashboardToRemoveIndex - 1]?.name);
                    });
            }
        }
    }, [pageId, onDashboardsChange, useLocal, resolvedDashboards]);

    return {
        activeDashboard,
        dashboards: resolvedDashboards,
        setActiveDashboard: setActiveDashboardName,
        addUpdateDashboard,
        deleteDashboard
    };
};
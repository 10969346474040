import {previewDataGenerator} from "../../typesAndConstants";

const dataGenerator: previewDataGenerator = () => ({
    total: 597,
    countries: {
        "AU": 51,
        "AT": 32,
        "DE": 13,
        "NL": 4,
        "GB": 65,
        "US": 5,
        "SK": 95,
        "CZ": 190,
        "HU": 79,
        "TR": 35,
        "ZZ": 28
    }
});

export default dataGenerator;
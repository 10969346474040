import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const DoNotScanIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-do-not-scan"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.error?.main, strokeWidth: 0}}
                  d="M15.4,31.4c-6.4,0-11.6,5.2-11.6,11.6s5.2,11.6,11.6,11.6,11.6-5.2,11.6-11.6-5.2-11.6-11.6-11.6ZM20.9,46.7l-1.8,1.8-3.7-3.7-3.7,3.7-1.8-1.8,3.7-3.7-3.7-3.7,1.8-1.8,3.7,3.7,3.7-3.7,1.8,1.8-3.7,3.7,3.7,3.7Z"/>
            <g>
                <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                      d="M15.4,28.9c1.3,0,2.6.2,3.9.6l2.4-6.9,3,9.9c1.1.9,2,2,2.7,3.2h2l3.1-9.7,3,5.9h4.2v-3.8h-1.9l-3.8-7.6h-3.8l-2.7,8.4-3.8-12.8h-3.8l-4.2,12H5.7v3.8h1.1c2.4-1.9,5.4-3,8.6-3Z"/>
                <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                      d="M44.3,37.9c1.3-1.7,2.4-3.6,3.1-5.7s1.1-4.3,1.1-6.6c0-5.6-2-10.4-5.9-14.3-3.9-3.9-8.7-5.9-14.3-5.9s-10,1.8-13.8,5.4c-3.8,3.6-5.9,8.1-6.3,13.4h5c.3-3.9,1.9-7.2,4.8-9.9,2.9-2.7,6.3-4,10.2-4s7.8,1.5,10.7,4.4c2.9,2.9,4.4,6.5,4.4,10.7s-1.5,7.8-4.4,10.7c-2.7,2.7-6,4.2-9.8,4.4.1.8.2,1.5.2,2.3s0,1.8-.3,2.7c2,0,4-.4,5.8-1.1s4-1.8,5.7-3.1l11.8,11.8,3.5-3.5-11.8-11.8Z"/>
            </g>
        </SvgIcon>
    );
};

export default DoNotScanIcon;
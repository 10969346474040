import React from "react";
import {
    BooleanInput,
    ConnectedFormPage,
    FormSection,
    getArrayDataContent,
    PasswordConfirmInput,
    PasswordInput,
    SelectInput,
    TextInput,
    useCrudProps,
    useMixpanel,
    validateRegexMemo
} from "@cuda-react/core";
import {get} from "lodash";
import apiResources from "../../../apiResources";
import {Divider, Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {parseJson} from "../../../utils/jsonUtils";
import {useSiteInputValidations} from "../../../hooks/createEditSiteHooks";

const useStyles = makeStyles((theme) => ({
    skeleton: {
        height: 250
    },
    introductionText: {
        padding: theme.spacing(1, 1, 4, 3),
        color: theme.palette.text.secondary
    }
}));

export const AzureMonitor = () => {
    const inputValidations = useSiteInputValidations();
    const registerAction = useMixpanel("Log Analytics");
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.azureMonitorRegions)[0]?.data);
    const validateUuid = validateRegexMemo(/^([a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12})?$/, "tesseract.validation.uuid");
    const classes = useStyles();

    return (
        <ConnectedFormPage
            title="tesseract.integration.pageTitle"
            subtitle="tesseract.integration.azureMonitor"
            resource={apiResources.settings}
            formatRequestData={(data) => ({
                settings: {
                    oms_workspace: JSON.stringify(data?.settings?.oms_workspace?.enabled ? data?.settings?.oms_workspace : {enabled: false}),
                    azure_monitor: JSON.stringify(data?.settings?.azure_monitor?.enabled ? data?.settings?.azure_monitor : {enabled: false})
                }
            })}
            formatResourceData={(data) => {
                const azureMonitor = parseJson(data?.settings?.azure_monitor || "{\"enabled\":false}");
                return {
                    settings: {
                        oms_workspace: parseJson(data?.settings?.oms_workspace || "{\"enabled\":false,\"id\":\"\",\"key\":\"\"}"),
                        azure_monitor: azureMonitor?.enabled ? azureMonitor : {
                            enabled: false,
                            clientId: "",
                            clientSecret: "",
                            tenantId: "",
                            subscriptionId: "",
                            resourceGroup: "",
                            region: "australiacentral",
                            correlationId: ""
                        }
                    }
                };
            }}
            params={{filter: {type: "virtualWan"}}}
            onSubmitSuccess={(response) => {
                registerAction("Update", {
                    oms_enabled: parseJson(response.settings.oms_workspace).enabled,
                    azure_monitor_enabled: parseJson(response.settings.azure_monitor).enabled
                });
            }}
            clearOnSuccess
            canReset
        >
            <FormSection title="tesseract.integration.azureMonitor" newStyle hideBorder>
                <Typography className={classes.introductionText}>
                    <Trans i18nKey="tesseract.settings.tabs.oms.introduction"/>
                </Typography>
                <BooleanInput
                    source="settings.azure_monitor.enabled"
                    label="tesseract.settings.tabs.oms.azureMonitorAgent"
                    toggleEnabledText="tesseract.settings.tabs.oms.enabled"
                    additionalInfoLabel="tesseract.settings.tabs.oms.descriptions.azureMonitorAgent"
                    newStyle
                />
                <TextInput
                    source="settings.azure_monitor.clientId"
                    label="tesseract.settings.tabs.oms.clientId"
                    disable={(value, allValues) => !get(allValues, "settings.azure_monitor.enabled")}
                    validate={validateUuid}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <PasswordInput
                    source="settings.azure_monitor.clientSecret"
                    label="tesseract.settings.tabs.oms.clientSecret"
                    isRequired
                    disable={(value, allValues) => !get(allValues, "settings.azure_monitor.enabled")}
                    validate={inputValidations.validatePassword}
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <TextInput
                    source="settings.azure_monitor.tenantId"
                    label="tesseract.settings.tabs.oms.tenantId"
                    disable={(value, allValues) => !get(allValues, "settings.azure_monitor.enabled")}
                    validate={validateUuid}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <TextInput
                    source="settings.azure_monitor.subscriptionId"
                    label="tesseract.settings.tabs.oms.subscriptionId"
                    disable={(value, allValues) => !get(allValues, "settings.azure_monitor.enabled")}
                    validate={validateUuid}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <TextInput
                    source="settings.azure_monitor.resourceGroup"
                    label="tesseract.settings.tabs.oms.resourceGroup"
                    disable={(value, allValues) => !get(allValues, "settings.azure_monitor.enabled")}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <SelectInput
                    source="settings.azure_monitor.region"
                    label="tesseract.settings.tabs.oms.region"
                    choices={regionChoices}
                    disable={(value, allValues) => !get(allValues, "settings.azure_monitor.enabled")}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <TextInput
                    source="settings.azure_monitor.correlationId"
                    label="tesseract.settings.tabs.oms.correlationId"
                    disable={(value, allValues) => !get(allValues, "settings.azure_monitor.enabled")}
                    validate={validateUuid}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <Divider sx={{marginTop: 1, marginBottom: 4}}/>
                <BooleanInput
                    source="settings.oms_workspace.enabled"
                    label="tesseract.settings.tabs.oms.oms"
                    additionalInfoLabel="tesseract.settings.tabs.oms.descriptions.oms"
                    toggleEnabledText="tesseract.settings.tabs.oms.enabled"
                    newStyle
                />
                <TextInput
                    source="settings.oms_workspace.id"
                    label="tesseract.settings.tabs.oms.id"
                    disable={(value, allValues) => !get(allValues, "settings.oms_workspace.enabled")}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <PasswordConfirmInput
                    label="tesseract.settings.tabs.oms.key"
                    source="settings.oms_workspace.key"
                    disable={(value, allValues) => !get(allValues, "settings.oms_workspace.enabled")}
                    validate={[]}
                    noConfirm
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
            </FormSection>
        </ConnectedFormPage>
    );
};

export default AzureMonitor;
import React from 'react';
import {SvgIcon, useTheme} from "@mui/material";
import classNames from "classnames";

export const Large = ({className}: { className?: string })=> {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-gridsize-large"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} d="M7.7,7.7v44.5h44.5V7.7H7.7Z"/>
        </SvgIcon>
    );
};
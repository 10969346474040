import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
    ActionButtonsField,
    ChipArrayField,
    CrudTypes,
    DeleteDialog,
    EmptyPageContent,
    getArrayDataContent,
    SelectField,
    TablePage,
    TextField,
    useCrudFetch,
    useCrudProps,
    useMixpanel,
    useTableChoices
} from "@cuda-react/core";
import React, {useRef, useState} from "react";
import {categoryIcons, fallbackIcons, priorityIcons} from "../../../../components/iconMapping";
import CreateExplicitPolicyDialog from "../dialogs/CreateExplicitPolicyDialog";
import EditExplicitPolicyDialog from "../dialogs/EditExplicitPolicyDialog";
import apiResources from "../../../../apiResources";
import {GetStartedBackgroundGraphic, OverrideGraphic} from "@cuda-react/icons";

export const ExplicitPolicies = () => {
    const registerAction = useMixpanel("SDWAN Explicit");
    const resource = apiResources.sdwanExplicitPolicies;
    const tableRefreshRef = useRef<any>();
    const [actionVisible, setActionVisible] = useState<any>(false);
    const [, , updateOrder] = useCrudFetch(CrudTypes.CREATE, resource);
    const handleClick = (id: any, direction: string, data: any) => {
        updateOrder({id, changeOrder: direction, data}).then(tableRefreshRef.current);
    };
    // TODO: Temporarily disabled "traffic type" as this feature is not working correctly in 8.2.0
    // const trafficTypeData = useCrudProps(apiResources.trafficType, {}, {cache: true})[0]?.data;
    // @ts-ignore
    const priorityData = useCrudProps(apiResources.priority, {}, {cache: true})[0]?.data;
    // @ts-ignore
    const actionData = useCrudProps(apiResources.action, {}, {cache: true})[0]?.data;
    // @ts-ignore
    const fallbackData = useCrudProps(apiResources.fallback, {}, {cache: true})[0]?.data;
    // @ts-ignore
    const loadBalancingData = useCrudProps(apiResources.loadBalancing, {}, {cache: true})[0]?.data;
    const forwardErrorCorrectionData = [
        {
            "key": "true",
            "name": "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.options.on"
        }, {
            "key": "false",
            "name": "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.options.off"
        }
    ];
    const [renderApplication, getApplicationIconId] = useTableChoices(apiResources.applications, {
        filterId: "applicationId",
        optionValue: "id"
    }, "categoryId");

    const onSuccess = (field: string) => (response: {
        applications: string | any[];
        sources: string | any[];
    }) => {
        tableRefreshRef.current?.();
        registerAction("Update", {
            applications: response.applications?.length,
            sources: response.sources?.length,
            field
        });
    };

    return (
        <TablePage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.sdwan", "tesseract.securityAndAccess.explicit"]}
            resource={resource}
            titleActions={actionVisible &&
                <CreateExplicitPolicyDialog
                    // @ts-ignore
                    resource={resource} onSuccess={tableRefreshRef.current}/>
            }
            minCellWidth={96}
            compact
            refreshRef={tableRefreshRef}
            onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
            noDataPage={
                <EmptyPageContent
                    background={<GetStartedBackgroundGraphic/>}
                    icon={<OverrideGraphic/>}
                    iconHeader="tesseract.policies.explicit.noData.iconHeader"
                    text="tesseract.policies.explicit.noData.text"
                    actions={<CreateExplicitPolicyDialog label="tesseract.policies.explicit.noData.labelButton"
                        // @ts-ignore
                                                         resource={resource} onSuccess={tableRefreshRef.current}/>}
                />
            }
        >
            <ActionButtonsField
                label="tesseract.policies.explicit.table.order"
                width={96}
                source="id"
                left
            >
                <TextField source="order"/>
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: { id: any; }) => handleClick(data.id, "increase", data)}
                    disabled={(data: { index: number; }) => data.index <= 0}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: { id: any; }) => handleClick(data.id, "decrease", data)}
                    disabled={(data: { index: number; total: number; }) => data.index >= (data.total - 1)}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField source="name" label="tesseract.policies.explicit.table.name"/>
            <ChipArrayField
                label="tesseract.policies.explicit.table.apps"
                source="applications"
                render={renderApplication}
                iconMap={categoryIcons}
                optionIcon={getApplicationIconId}
            />
            <ChipArrayField
                label="tesseract.policies.explicit.table.sources"
                source="sources"
            />
            {/*TODO: Temporarily disabled "traffic type" as this feature is not working correctly in 8.2.0*/}
            {/*<ListField*/}
            {/*    iconMap={trafficTypeIcons}*/}
            {/*    label="tesseract.policies.explicit.table.traffic"*/}
            {/*    render={(val) => {*/}
            {/*        const choices = getArrayDataContent(trafficTypeData);*/}
            {/*        return get(choices.find((choice) => val === get(choice, "key")), "name");*/}
            {/*    }}*/}
            {/*    source="trafficType"*/}
            {/*    width={120}*/}
            {/*/>*/}
            <SelectField
                source="priority"
                label="tesseract.policies.explicit.table.priority"
                resource={resource}
                id={(data) => data.id}
                choices={getArrayDataContent(priorityData)}
                iconMap={priorityIcons}
                width={142}
                columnProps={{
                    width: 156
                }}
                onSuccess={onSuccess("priority")}
            />
            <SelectField
                source="action"
                label="tesseract.policies.explicit.table.action"
                resource={resource}
                id={(data) => data.id}
                choices={getArrayDataContent(actionData)}
                width={144}
                columnProps={{
                    width: 168
                }}
                onSuccess={onSuccess("action")}
            />
            <SelectField
                source="fallback"
                label="tesseract.policies.explicit.table.fallback"
                resource={resource}
                id={(data) => data.id}
                choices={getArrayDataContent(fallbackData)}
                iconMap={fallbackIcons}
                width={124}
                columnProps={{
                    width: 148
                }}
                onSuccess={onSuccess("fallback")}
            />
            <SelectField
                source="loadBalancing"
                label="tesseract.policies.sdwan.table.loadBalancing"
                resource={resource}
                id={(data) => data.id}
                choices={getArrayDataContent(loadBalancingData)}
                width={120}
                columnProps={{
                    width: 144
                }}
                onSuccess={onSuccess("loadBalancing")}
            />
            <SelectField
                source="forwardErrorCorrection"
                label="tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.label"
                resource={resource}
                id={(data) => data.id}
                choices={forwardErrorCorrectionData}
                width={120}
                columnProps={{
                    width: 144
                }}
                onSuccess={onSuccess("forwardErrorCorrection")}
            />
            <ActionButtonsField
                width={96}
                source="id"
            >
                <EditExplicitPolicyDialog id={(data: any) => data.id} onSuccess={tableRefreshRef.current}/>
                <DeleteDialog
                    resource={resource}
                    title={"tesseract.sdwanExplicitPolicies.delete.title"}
                    message={"tesseract.sdwanExplicitPolicies.delete.body"}
                    onSuccess={() => {
                        registerAction("Delete");
                        tableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default ExplicitPolicies;
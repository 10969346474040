import get from "lodash/get";
import React from "react";
import {Checkmark, Close} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

export const styles = createStyles({
    root: {
        height: "1rem",
        position: "relative",
        top: "calc(0.5rem - 12px)"
    }
});

const useStyles = makeOverrideableStyles("BooleanField", styles);

export interface BooleanFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps {

}

/**
 * Renders a Boolean value represented by an icon.
 */
export const BooleanField = (props: BooleanFieldProps) => {
    const {data = {}, source = ""} = props;
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            {get(data, source) ? <Checkmark/> : <Close/>}
        </div>);
};

export default BooleanField;
import React from "react";
import mainApiResources from "../../../mainApiResources";
import {DeleteDialog, useMixpanel, Workspace} from "@cuda-react/core";
import WorkspaceCreateEditDialog from "./WorkspaceCreateEditDialog";

export interface WorkspaceActionsProps {
    activeWorkspace?: Workspace,
    onChange?: () => void
}

const WorkspaceActions = ({activeWorkspace, onChange}: WorkspaceActionsProps) => {
    const registerAction = useMixpanel("Workspace", false);
    return (
        <>
            {activeWorkspace ? (
                <DeleteDialog
                    data={activeWorkspace}
                    onSuccess={() => {
                        registerAction("Delete");
                        onChange?.();
                    }}
                    resource={mainApiResources.virtualWans}
                    title="stratos.accounts.dialog.titleDelete"
                    message="stratos.accounts.dialog.messageDelete"
                />
            ) : null}
            <WorkspaceCreateEditDialog
                activeWorkspace={activeWorkspace}
                onChange={() => {
                    registerAction(activeWorkspace ? "Update" : "Create");
                    onChange?.();
                }}
            />
        </>
    );
};

export default WorkspaceActions;
import React from "react";
import EnableSupportDialog from "./support/EnableSupportDialog";
import RestartSiteDialog from "./restart/RestartSiteDialog";
import {Dashboard, useMixpanel} from "@cuda-react/core";
import SiteSummaryCard from "./cards/SiteSummaryCard";
import IoTStatusCard from "./cards/IoTStatusCard";
import DetailsCard from "./cards/DetailsCard";
import FirmwareCard from "./cards/FirmwareCard";
import SiteStatusCard from "./cards/SiteStatusCard";
import DeploymentCard from "./cards/DeploymentCard";
import TunnelLocationsCard from "./cards/TunnelLocationsCard";
import {useMediaQuery, useTheme} from "@mui/material";
import GatewaySummaryCard from "./cards/GatewaySummaryCard";
import VPNTunnelsCard from "./cards/VPNTunnelsCard";


interface ApplianceDashboardProps {
    details: {
        id: string | number;
        name?: string;
        serials?: string[];
        uuid?: string;
        modelSeries?: "S" | "T";
        gatewayType?: "cloud" | "on-prem" | "managed";
        model?: string;
        applicationId?: string;
    };
    type: "gateways" | "sites";
}

const ApplianceDashboard: React.FC<ApplianceDashboardProps> = (props) => {
    const {type, details: {gatewayType, id, model, modelSeries, name, serials, uuid, applicationId}} = props;
    const theme = useTheme();
    const smallMedium = useMediaQuery(theme.breakpoints.down(1130));
    const isSc = modelSeries === "S" || !modelSeries;
    let actions;
    if (type === "sites" && !isSc) {
        actions = [
            <EnableSupportDialog key={0} uuid={uuid} name={name} applianceType="site"/>,
            <RestartSiteDialog key={1} id={id} serials={serials}/>
        ];
    } else if (type !== "sites") {
        actions = [
            <EnableSupportDialog
                key={0}
                uuid={uuid}
                name={name}
                applianceType="gateway"
                gatewayType={gatewayType}
                applicationId={applicationId}
            />
        ];
    }

    useMixpanel(
        gatewayType ? "Gateways Dashboard" : "Sites Dashboard",
        !!model,
        {model: model || "", type: gatewayType || modelSeries || ""}
    );

    return (
        <Dashboard
            authenticated
            columns={
                type === "sites" ? [{xs: 12, md: 6, lg: 4}, {xs: 12, md: 6, lg: 8}]
                    : [
                        {xs: 12, md: smallMedium ? 12 : 6, lg: 5, xl: 4},
                        {xs: 12, md: smallMedium ? 12 : 6, lg: 7, xl: 8}
                    ]
            }
            actions={actions}
        >
            {type === "sites" ? (
                <SiteSummaryCard column={0} {...props} />
            ) : (
                <GatewaySummaryCard column={0} {...props} />
            )}
            <FirmwareCard column={0} {...props}/>
            <SiteStatusCard column={0} {...props}/>
            <TunnelLocationsCard column={1} {...props}/>
            <IoTStatusCard column={1} {...props}/>
            <VPNTunnelsCard column={1} {...props}/>
            <DetailsCard column={1} {...props}/>
            <DeploymentCard column={1} {...props}/>
        </Dashboard>
    );
};


export default ApplianceDashboard;
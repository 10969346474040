import {Grid, Skeleton, Typography} from "@barracuda-internal/bds-core";
import React, {ReactNode} from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {CardSizes} from "../../../typesAndConstants";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
        margin: theme.spacing(0.25, 0)
    },
    expandColumn: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: 28,
    },
    titleColumn: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
        "& > svg": {
            marginRight: theme.spacing(1)
        },
        "& > p": {
            maxWidth: "calc(100% - 24)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        width: ({size}: { size: CardSizes }) => size === CardSizes.small ? 142 : 192,
        height: 30,
        color: theme.palette.reporting.fontColor
    },
    contentColumn: {
        width: "100%",
        maxWidth: ({size}: { size: CardSizes }) => `calc(100% - ${size === CardSizes.small ? 174 : 224}px)`
    },
    expandIcon: {
        transform: "rotate(90deg)",
        transition: "transform 250ms"
    },
    expanded: {
        transform: "rotate(180deg)"
    },
    skeleton: {
        width: `calc(100% - ${theme.spacing(2)})`,
        height: 30,
        margin: theme.spacing(0, 1)
    },
    text: {
        fontSize: 14,
        color: theme.palette.reporting.fontColor
    }
});
const useStyles = makeStyles(styles);

export interface OverviewCardRowProps extends StyledComponentProps<typeof styles> {
    /**
     * Icon of the row
     */
    icon?: JSX.Element,
    /**
     * Title of the row
     */
    title: string,
    /**
     * the right part of the row
     * | ICON TITLE        CONTENT |
     */
    children?: ReactNode | ReactNode[],
    /**
     * Is this entry expanded (must also provide callback onExpand).
     */
    expanded?: boolean,
    /**
     * callback function when the expand more or less icon is clicked
     */
    onExpand?: () => void,
    /**
     * size of the container card, used for styling.
     */
    size: CardSizes,
    /**
     * Does not wrap each row with a grid element
     * When css grid is used container elements are superfluous
     */
    noContainer?: boolean,
    /**
     * Does not the content part of each row with a grid element
     * When css grid is used container elements are superfluous
     */
    noContentContainer?: boolean
    /**
     * if true, loading skeleton bar is rendered instead
     */
    loading?: boolean,
    /**
     * pass row on click action to the label popover
     */
    onClick?: () => void
}

/**
 * A single row for an overview statistic, with icon, title, and content area.
 */
export const OverviewCardRow = (props: OverviewCardRowProps) => {
    const {children, icon, loading, title, expanded, onExpand, noContainer, noContentContainer, onClick} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);

    // TODO: Disabled the popover due to SWCS-4291, will restore in SWCS-4445
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handlePopoverClose = () => {
    //     setAnchorEl(null);
    // };
    // const isPopoverOpen = Boolean(anchorEl);

    const content = (
        <>
            {onExpand && !loading && (
                <Grid item className={classes.expandColumn}>
                    <ExpandLessIcon
                        className={`${classes.expandIcon} ${expanded ? classes.expanded : ""}`}
                        onClick={onExpand}
                    />
                </Grid>
            )}
            <Grid item className={classes.titleColumn}>
                {loading ? (
                    <Skeleton className={classes.skeleton}/>
                ) : (
                    <>
                        {icon}
                        <Typography
                            // TODO: Disabled the popover due to SWCS-4291, will restore in SWCS-4445
                            // aria-owns={isPopoverOpen ? 'mouse-over-popover' : undefined}
                            // aria-haspopup="true"
                            // onMouseEnter={handlePopoverOpen}
                            // onMouseLeave={handlePopoverClose}
                            variant="body2"
                        >
                            {translate(title)}
                        </Typography>
                        {/*TODO: Disabled the popover due to SWCS-4291, will restore in SWCS-4445*/}
                        {/*<Popover*/}
                        {/*    id={isPopoverOpen ? 'simple-popover' : undefined}*/}
                        {/*    disableRestoreFocus*/}
                        {/*    open={isPopoverOpen}*/}
                        {/*    anchorEl={anchorEl}*/}
                        {/*    onClose={handlePopoverClose}*/}
                        {/*    anchorOrigin={{*/}
                        {/*        vertical: 'top',*/}
                        {/*        horizontal: 'left',*/}
                        {/*    }}*/}
                        {/*    transformOrigin={{*/}
                        {/*        vertical: 'top',*/}
                        {/*        horizontal: 'left',*/}
                        {/*    }}*/}
                        {/*    PaperProps={{style: {boxShadow: "none", overflow: "hidden", cursor: onClick ? "pointer" : undefined}}}*/}
                        {/*    transitionDuration={0}*/}
                        {/*>*/}
                        {/*    <Typography className={classes.text} variant="body2">{translate(title)}</Typography>*/}
                        {/*</Popover>*/}
                    </>
                )}
            </Grid>
            {(noContentContainer && !loading) ? children : (
                <Grid item className={classes.contentColumn}>
                    {loading ? <Skeleton className={classes.skeleton}/> : children}
                </Grid>
            )}
        </>
    );

    return noContainer ? content : (
        <Grid container spacing={0} className={classes.root}>
            {content}
        </Grid>
    );
};
import React from "react";
import {BaseCard, BaseCardProps} from "../index";
import {createStyles, makeStyles} from "@mui/styles";
import {CardFooter} from "../CardFooter";
import {CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import {StyledComponentProps} from "@cuda-react/theme";
import {BarGrid, BarGridEntry} from "../../statistics/BarGrid";
import {colorFunction, progressFunction} from "../../../utils";
import {useTheme} from "@mui/material";

const styles = createStyles<string, Pick<DashboardCardProps, "size" | "preview">>({
    hideBoxShadow: {
        boxShadow: "none",
    },
    footerBarGrid: {
        minWidth: 580,
        maxWidth: 580
    }
});
const useStyles = makeStyles(styles);

export interface BaseBarCardProps extends Pick<BaseCardProps, "size" | "preview" | "title" | "noData" | "activeFilter">, StyledComponentProps<typeof styles> {
    /** Rows of the card each with one progress bar */
    entries: BarGridEntry[],
    /** Total number of values of the set the entries where taken from, used in footer.*/
    total?: number,
    /** Translation key for the footer. */
    footerKey?: string,
    /** Hide main box-shadow if true. */
    hideBoxShadow?: boolean,
    /**
     * Default value for the icon of every row
     */
    rowIcon?: JSX.Element,
    /**
     * if true, loading skeleton bars are displayed instead of data
     */
    loading?: boolean,
    /**
     * Function for the color of the bar of the row
     *
     * It expects the row number starting with 1 and returns the color.
     * @param index
     */
    rowColorFunction?: (index: number) => string,
}

// TODO: Remove this once all dependent cards have updated not to need it.
/**
 * Props for a child that is a specialization of this card
 */
export interface StandardChildProps extends DashboardCardProps {
    entries?: Pick<BarGridEntry, "value" | "title">[],
    total: number,
}

/**
 * Base Card for use to construct simple Bar Chart cards.
 */
export const BaseBarCard = (props: BaseBarCardProps) => {
    const {activeFilter, title, entries, total, loading, noData, footerKey, size, preview, rowIcon, rowColorFunction} = props;
    const classes = useStyles(props);
    const theme = useTheme();
    const large = size === CardSizes.large;
    const usedColorFunction = rowColorFunction || colorFunction;
    const entriesToDisplay = entries.map((entry, index) => ({
        ...entry,
        icon: entry.icon ?? rowIcon,
        barProgress: entry.barProgress ?? progressFunction(index + 1),
        color: entry.color ?? usedColorFunction(index + 1, theme),
    }));
    const showFooter = !loading && large && footerKey && total;

    return (
        <BaseCard
            title={title}
            className={props.hideBoxShadow ? classes.hideBoxShadow : undefined}
            size={size}
            preview={preview}
            noData={noData}
            activeFilter={activeFilter}
        >
            <BarGrid
                size={size}
                entries={entriesToDisplay}
                loading={loading}
                className={showFooter ? classes.footerBarGrid : undefined}
            />
            {showFooter && (
                <CardFooter
                    footerKey={footerKey}
                    length={entries.length}
                    total={total}
                />
            )}
        </BaseCard>
    );
};
import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    BooleanCheckListInput,
    ConnectedForm,
    DialogBody,
    getArrayDataContent,
    TextArrayInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import {Divider} from "@barracuda-internal/bds-core";

export const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        minWidth: 840
    },
    divider: {
        margin: theme.spacing(1, 4, 1, 4),
    },
    domainContainer: {
        margin: theme.spacing(1, 0, 1, 0),
        flex: "1 1 auto",
        overflowY: "auto",
        display: "flex",
        flexDirection: "row"
    }
}));


export const CreateEditCustomCategory = (props: {create?: boolean, id?: number, onClose?: () => void, onSuccess?: () => void}) => {
    const create = props.create;
    const id = props.id;
    const onSuccess = props.onSuccess;
    const registerAction = useMixpanel("Custom Category Rule", false);
    const categoryChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterCategories, {}, {},true)[0]?.data);
    const groupChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterGroups)[0]?.data);
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <DialogBody
            {...props}
            form
            title={create ?
                "tesseract.customCategories.dialog.create.dialogTitle" :
                "tesseract.customCategories.dialog.edit.dialogTitle"
            }
            classes={{dialogContent: classes.dialogContent}}
        >
            <ConnectedForm
                resource={apiResources.customCategories}
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={(response) => {
                    registerAction(create ? "Create" : "Update", {
                        length: response.categories?.length || response.rules?.length
                    });
                    onSuccess?.();
                    props.onClose?.();
                }}
                onCancel={props.onClose}
                formatRequestData={({categories, rules, ...data}) => ({
                    ...data,
                    categories,
                    virtualWanId: create ? virtualWanId : data.virtualWanId,
                })}
                formatResourceData={(data) => ({
                    ...data,
                    categories: data.categories || []
                })}
                allowDirtyNavigation
            >
                <TextInput
                    source="name"
                    label="tesseract.customCategories.dialog.name"
                    description="tesseract.customCategories.dialog.descriptions.name"
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.customCategories.dialog.description"
                    description="tesseract.customCategories.dialog.descriptions.description"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                />
                <Divider className={classes.divider}/>
                <div className={classes.domainContainer}>
                    <TextArrayInput
                        source="domains"
                        label="tesseract.customCategories.dialog.includedDomains"
                        description="tesseract.customCategories.dialog.descriptions.includedDomains"
                    />
                    {/* EXCLUDED DOMAIN WILL BE IMPLEMENTED IN FUTURE */}
                    {/*<Divider orientation="vertical" flexItem className={classes.divider}/>*/}
                    {/*<TextArrayInput*/}
                    {/*    source="excludedDomains"*/}
                    {/*    label="tesseract.customCategories.dialog.excludedDomains"*/}
                    {/*    description="tesseract.customCategories.dialog.descriptions.excludedDomains"*/}
                    {/*/>*/}
                </div>
                <BooleanCheckListInput
                    source="categories"
                    label=" "
                    categoryChoices={categoryChoices}
                    groupChoices={groupChoices}
                    validate={(value, data) => (data?.categories?.length > 0 || data?.domains?.length > 0) ? undefined : translate("tesseract.customCategories.dialog.validation.emptyCategories")}
                />
            </ConnectedForm>
        </DialogBody>
    );
};

CreateEditCustomCategory.propTypes = {
    create: PropTypes.bool,
    id: PropTypes.number,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    title: PropTypes.string
};


export default CreateEditCustomCategory;
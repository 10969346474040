import {ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent} from "@barracuda-internal/bds-core";
import {isEmpty} from "lodash";
import get from "lodash/get";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CrudTypes} from "../../../clients";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useCrudFetch} from "../../../hooks";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

export interface BaseSelectFieldProps extends Omit<BaseFieldProps, "width"> {
    /**
     * array of choice objects to search in.
     */
    choices?: any[],
    /**
     * the default choice to display.
     */
    defaultChoice?: string,
    /**
     * when true, will mark the field as disabled.
     */
    disabled?: boolean,
    /**
     * width of the select dropdown
     */
    width?: number | string,
    /**
     * icon to display next to each choice. If iconMap is provided as well, this icon will be used whenever a matching icon is not found in iconMap.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons to display inside of each choice. The "optionIcon" value of each choice is used to identify the icon from this map.
     */
    iconMap?: {[key: string]: React.ReactNode},
    /**
     * used to identify wich entry needs to be updated.
     * It can be either a string, a number or a function
     * @function
     * @param {object} data the full data array
     * @return {string} the identifier to the entry that will be updated.
     */
    id: string | number | ((data: any) => string | number),
    /**
     * Property for rendering the main text for each choice. Should be a dot-notation path to the property in
     * each choice object to display as text in the dropdown.
     */
    optionText?: string,
    /**
     * Callback called after a successful update request.
     */
    onSuccess?: (responseData?: any) => void,
    /**
     * dot-notation path to the property in each choice object that defines the value for matching against the data.
     */
    optionValue?: string,
    /**
     * the [CRUD](/?path=/docs/key-concepts-crud) resource to fetch/update data from.
     */
    resource?: string,
    /**
     * if the data array contains objects, instead of values, this identifies the value within each object that should be compared to the choice's optionValue.
     */
    source?: string
}

export const styles = (theme: Theme) => createStyles<string, BaseSelectFieldProps>({
    select: {
        width: (props) => props.width ? props.width : 200,
        "& fieldset": {
            borderWidth: 0,
        },
        "&:hover,:focus": {
            "& fieldset": {
                borderWidth: 1
            }
        },
        marginLeft: -8
    },
    selectInput: {
        overflow: "visible"
    },
    listText: {
        display: "inline-flex",
        margin: 0,
        color: theme.palette.common.black
    },
    listItemIcon: {
        minWidth: 32,
        verticalAlign: "middle",
        // marginTop: -2
    },
    inset: {
        paddingLeft: 32
    }
});

const useStyles = makeOverrideableStyles("SelectField", styles);

export interface SelectFieldProps extends StyledComponentProps<typeof styles>, BaseSelectFieldProps {

}

/**
 * Renders an editable Select dropdown field.
 * It updates and fetch the data on each change.
 */
export const SelectField = (props: SelectFieldProps) => {
    const {data = {}, resource, source = "", id, defaultChoice, choices, optionValue =  "key", optionText = "name", disabled, icon, iconMap = {}, onSuccess} = props;
    const classes = useStyles(props);
    const dataValue = get(data, source);
    const [translate] = useTranslation();
    const [option, setOption] = useState(dataValue || defaultChoice || get(choices?.[0], optionValue));
    const requestResourceId = typeof id === "function" ? id(data) : id;

    const [, ,performFetch] = useCrudFetch(CrudTypes.UPDATE, resource);
    const handleChange = (event: SelectChangeEvent) => {
        performFetch(
            {
                id: requestResourceId,
                data: {
                    ...data,
                    [source]: event.target.value
                }
            }
        ).then((response) => onSuccess?.(response?.data));
        setOption(event.target.value);
    };

    useEffect(() => {
        setOption(dataValue);
    }, [dataValue, requestResourceId]);

    return choices && choices.length > 0 && (
        <Select
            value={option}
            // @ts-ignore bad TS on component
            onChange={handleChange}
            inputProps={{className: classes.selectInput}}
            className={classes.select}
            disabled={disabled}
        >
            {choices.map((choice) => {
                const choiceName = get(choice, optionText, "");
                const listIcon = iconMap[get(choice, optionValue)] || icon;
                const choiceValue = get(choice, optionValue);
                return (
                    <MenuItem key={choiceValue} value={choiceValue}>
                        {listIcon && (
                            <ListItemIcon className={classes.listItemIcon}>
                                {listIcon}
                            </ListItemIcon>
                        )}
                        {typeof choiceName === "string" ? (
                            <ListItemText
                                primary={translate(choiceName)}
                                className={classes.listText}
                                classes={{inset: classes.inset}}
                                inset={!isEmpty(iconMap) && !listIcon}
                            />
                        ) : choiceName}
                    </MenuItem>
                );
            })}
        </Select>
    ) || null;
};

export default SelectField;
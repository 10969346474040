import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const WidgetErrorIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-widget-error"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.error?.main, strokeWidth: 0}}
                  d="M47.4,12.6c-9.6-9.6-25.1-9.6-34.7,0-9.6,9.6-9.6,25.1,0,34.7,9.6,9.6,25.1,9.6,34.7,0,9.6-9.6,9.6-25.1,0-34.7ZM16.1,43.9c-7.7-7.7-7.7-20.1,0-27.8,7.7-7.7,20.1-7.7,27.8,0,7.7,7.7,7.7,20.1,0,27.8-7.7,7.7-20.1,7.7-27.8,0Z"/>
            <g>
                <rect style={{fill: theme.palette.error?.main, strokeWidth: 0}} x="27.5" y="37.4" width="4.9" height="4.9"/>
                <rect style={{fill: theme.palette.error?.main, strokeWidth: 0}} x="27.5" y="17.7" width="4.9" height="14.7"/>
            </g>
        </SvgIcon>
    );
};

export default WidgetErrorIcon;
import React from "react";
import CustomResponse from "./customResponse/CustomResponse";
import {Route, Switch} from "react-router";

const Settings = () => (
    <Switch>
        <Route path="/policies/settings/customresponse">
            <CustomResponse />
        </Route>
    </Switch>
);

export default Settings;
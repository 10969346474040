import React, {useEffect} from "react";
// @ts-ignore
import {parse} from "query-string";
import {useLocation} from "react-router";
import AuthenticatingPage from "../AuthenticatingPage/AuthenticatingPage";
import {useAuthEvents} from "../../../hooks";
import {AuthEventType} from "../../providers/AuthProvider/AuthProvider";

/**
 * Login page to use alongside the BccRedirectAuthClient.
 *
 * This is the default page at '/#/login' when using CudaReactApp with bccAuthentication.
 * It renders an [AuthenticatingPage](/?path=/docs/authentication-authenticatingpage), and triggers an
 * [Auth login](/?path=/docs/cudareactapp-authentication--page) event after render, providing the current query
 * as the event params.
 *
 * There are no props for this component.
 */
export const BccRedirectLoginPage = () => {
    const location = useLocation();
    const postAuthEvent = useAuthEvents();
    const queryString = parse(location.search);

    useEffect(() => {
        postAuthEvent({type: AuthEventType.LOGIN, params: queryString});
    }, []);

    return (
        <AuthenticatingPage/>
    );
};

export default BccRedirectLoginPage;
import React from "react";
import Input, {InputProps} from "../Input/Input";
import Boolean from "./Boolean";

export interface BooleanInputProps extends Omit<InputProps<typeof Boolean>, "component"> {}

/**
 * This is a version of the Boolean basic input that can be used seemlessly with the Form and Wizards.
 *
 * All props provided are passed to Input and Boolean.
 *
 * See [Input](/?path=/docs/core-components-inputs-input--input) for more information on the props required for Input.
 *
 * See [Boolean](/?path=/docs/core-components-inputs-basic-boolean--boolean) for props required for Boolean. Note that state related props
 * (such as "value" and "onChange") will already be provided by the Input component.
 */
export const BooleanInput = (props: BooleanInputProps) => (
    <Input {...props} component={Boolean} />
);

export default BooleanInput;
import {DashboardCardProps, ResourceStatisticsCard, useFetchStatisticsCardDetailsAndTotal} from "@stratos/reporting";
import React from "react";
import apiResources from "../../../../apiResources";
import {getStatusColorFromTheme} from "../../../../utils/themeUtils";
import {Theme, useTheme} from "@mui/material";
import {StatisticsDetails} from "@stratos/reporting/lib/hooks/useFetchStatisticsCardDetailsAndTotal";

export interface ApplianceStatusCardProps extends DashboardCardProps {}

const previewData = (theme: Theme) => [
    [
        {
            "title": "tesseract.dashboard.cards.status.applianceConfiguration.completed",
            "value": 10,
            "color": getStatusColorFromTheme("completed", theme)
        },
        {
            "title": "tesseract.dashboard.cards.status.applianceConfiguration.connecting",
            "value": 10,
            "color": getStatusColorFromTheme("connecting", theme)
        },
        {
            "title": "tesseract.dashboard.cards.status.applianceConfiguration.failed",
            "value": 3,
            "color": getStatusColorFromTheme("failed", theme)
        },
        {
            "title": "tesseract.dashboard.cards.status.applianceConfiguration.pending",
            "value": 3,
            "color": getStatusColorFromTheme("pending", theme)
        }
    ],
    26
] as [StatisticsDetails[], number];

const ApplianceStatusCard: React.FC<ApplianceStatusCardProps> = (props) => {
    const theme = useTheme();
    const {preview} = props;
    const apiResourceData = useFetchStatisticsCardDetailsAndTotal(
        {url: preview ? undefined : apiResources.applianceConfiguration},
        {url: apiResources.applianceStates},
        {
            getKeyColor: (key: any) => (getStatusColorFromTheme(key, theme))
        }
    );
    const [details, total] = preview ? previewData(theme) : apiResourceData;

    return (
        <ResourceStatisticsCard
            {...props}
            overViewData={details}
            overViewValue={total}
            overviewTitle="tesseract.dashboard.cards.status.applianceConfiguration.tabTitle"
        />
    );
};


export default ApplianceStatusCard;
import {useDeepCompareCallback} from "./UtilHooks";
import {UIEvent, useEffect, useState} from "react";

export interface useInfiniteScrollProps {
    /**
     * indicates whether there is more data that can be requested by loadMoreRows
     */
    canLoadMoreRows: boolean,
    /**
     * which paging strategy is being used for the data in the current table
     */
    pageMode?: "all" | "dynamic" | "paginate",
    /**
     * indicates that data is currently being loaded into the table
     *
     * used to prevent duplicate requests for further data
     */
    loading: boolean,
    /**
     * callback to request more data be loaded into the table
     */
    loadMoreRows: () => void
}


export interface useInfiniteScrollReturn {
    /**
     * onScroll event handler for the DataTable
     *
     * This is used to determine when further table rows need to be loaded
     */
    onScroll: (event: UIEvent<HTMLElement>) => void;
}

/**
 * A hook to manage the "infinite scroll" logic for ConnectedTable
 *
 * @internal
 */
export const useInfiniteScroll = ({canLoadMoreRows, pageMode, loading, loadMoreRows}: useInfiniteScrollProps): useInfiniteScrollReturn => {
    const [shouldLoadMoreRows, setShouldLoadMoreRows] = useState(false);
    useEffect(() => {
        if (shouldLoadMoreRows) {
            loadMoreRows();
        }
    }, [shouldLoadMoreRows]);
    useEffect(() => {
        if (!loading) {
            setShouldLoadMoreRows(false);
        }
    }, [loading]);

    const onScroll = useDeepCompareCallback((event) => {
        if (!canLoadMoreRows) {
            // do nothing if there are no more rows available to fetch
            return;
        }

        const {clientHeight, scrollHeight, scrollTop} = event.nativeEvent.target;
        const initialBelowFoldHeight = scrollHeight - clientHeight;
        const currentBelowFoldHeight = initialBelowFoldHeight - scrollTop;

        // load data once there is less than one table-height worth of data left
        if (!loading && pageMode === "dynamic" && currentBelowFoldHeight < clientHeight) {
            setShouldLoadMoreRows(true);
        }
    }, [canLoadMoreRows, pageMode, loading]);

    return {
        onScroll
    };
};
import React, {useCallback, useState} from 'react';
import {
    CrudTypes,
    MenuButton,
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    useCrudFetch,
    useMixpanel,
    usePostNotification
} from '@cuda-react/core';
import apiResources from '../../../../../apiResources';
import {useTranslation} from 'react-i18next';
import {CreatedUserDirectory} from '../UserDirectoriesTypes';
import {Dialog} from '@barracuda-internal/bds-core';
import {TestConnectivityDialog} from './TestConnectivityDialog';

const i18nScope = "tesseract.identity.userDirectories";

export const UserDirectoryMenu = ({data}: { data?: CreatedUserDirectory}) => {
    const registerAction = useMixpanel('User Directories', false);
    const postNotification = usePostNotification();
    const [, , performSync] = useCrudFetch(CrudTypes.CREATE, apiResources.directoriesSync);
    const [showTestConnectivity, setShowTestConnectivity] = useState(false);

    const [translate] = useTranslation();

    const handleSync = useCallback(() => {
        if (!data) {
            return;
        }

        performSync({id: data?.id});
        postNotification({
            event: NotificationEventType.SHOW,
            display: NotificationDisplayType.TOAST,
            params: {
                content: translate(`${i18nScope}.syncedNotification`),
                level: NotificationLevel.INFO,
                duration: 5000
            }
        });
        registerAction('manually synced');
    }, [data, performSync, postNotification, registerAction, translate]);

    const handleTestConnectivity = useCallback(async() => {
        setShowTestConnectivity(true);
    }, [data]);

    const menuItems = [
        {
            label: 'tesseract.identity.userDirectories.sync',
            onClick: handleSync
        },
    ];

    if (data?.type === 'ldap') {
        menuItems.push({
            label: 'tesseract.identity.userDirectories.testConnectivity',
            onClick: handleTestConnectivity,
        });
    }
    return (
        <>
        <MenuButton
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            transformOrigin={{horizontal: 'left', vertical: 'top'}}
            menuItems={menuItems}
            iconButton
        />
        <Dialog
            open={showTestConnectivity}
        >
            { showTestConnectivity && data &&
                <TestConnectivityDialog
                    directory={data}
                    onClose={() => setShowTestConnectivity(false)}
                />
            }
        </Dialog>
        </>
    );
};
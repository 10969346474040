import React from "react";
import ApplicationControl from "./tabs/ApplicationControl/ApplicationControl";
import IngressRule from "./tabs/Ingress/IngressRule";
import {Route, Switch} from "react-router";

const NetworkAccess = () => (
    <Switch>
        <Route path="/policies/network/gateway">
            <ApplicationControl key="gateway" type="gateway"/>
        </Route>
        <Route path="/policies/network/site">
            <ApplicationControl key="site" type="site"/>
        </Route>
        <Route path="/policies/network/ingress">
            <IngressRule/>
        </Route>
    </Switch>
);

export default NetworkAccess;
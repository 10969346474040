import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

// Just a temporary icon until we have the official one
export const GatewayIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-gateway"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M42.1,38.2l8.2-8.2-8.3-8.3-3.5,3.5,2.5,2.5h-14c1.1-7,6.6-12.5,13.6-13.5l-2,2,3.5,3.5,8.2-8.2-8.3-8.3-3.5,3.5,2.6,2.6c-9.9.9-17.9,8.7-19,18.5h-5.1c-.1-.2-.3-.4-.4-.5-1.6-1.6-4.3-1.5-5.8.1-1.6,1.6-1.5,4.3.1,5.8.8.7,1.8,1.2,2.9,1.2s0,0,0,0c1.1,0,2.1-.5,2.9-1.3.1-.1.2-.3.3-.4h5.1c1.1,9.8,9,17.5,18.7,18.5l-2.2,2.2,3.5,3.5,8.2-8.2-8.3-8.3-3.5,3.5,2.4,2.4c-7.1-.9-12.8-6.5-13.9-13.6h13.7l-2.2,2.2,3.5,3.5ZM13.2,30.7c-.1-.1-.2-.3-.2-.5s0-.4.2-.6h0s0,0,0,0c.1-.1.3-.2.5-.2.2,0,.4,0,.6.2,0,0,.1.2.2.3v.6c0,0,0,.2-.2.2-.3.3-.8.3-1.1,0Z"/>
        </SvgIcon>
    );
};

export default GatewayIcon;
import React, {useCallback, useRef} from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";

const styles = createStyles({
    root: {
        display: "flex"
    },
    inputRoot: {
        width: "auto",
        marginRight: 0,
    },
    firstInput: {
        paddingRight: 8
    },
    secondInput: {
        paddingLeft: 0
    }
});
const useStyles = makeOverrideableStyles("Double", styles);

export interface DoubleProps<InputComponent extends React.ElementType> extends StyledComponentProps<typeof styles> {
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * additional props to pass to the first of the two rendered child inputs.
     */
    firstInputProps?: Partial<React.ComponentProps<InputComponent>>,
    /**
     * id of the component for unique identification. This value is prefixed with "boolean-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
    /**
     * component to use for child inputs. Is expected to accept the same "input" and "meta" objects that this component expects,
     * in order to managed value and error states.
     */
    inputComponent: InputComponent,
    /**
     * additional props to pass to both rendered child inputs.
     */
    inputProps?: Partial<React.ComponentProps<InputComponent>>,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (value: [any, any]) => void,
    /**
     * callback to called when input get focus.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function
     */
    onFocus?: () => void,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: () => void,
    /**
     * additional props to pass to the second of the two rendered child inputs.
     */
    secondInputProps?: Partial<React.ComponentProps<InputComponent>>,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: [any, any],
}

/**
 * Creates a "double input", which is simply two inputs rendered against a single label/description and data source.
 * The value is stored as a 2 part array, with the first array value passed to the first child input, and the second to the second.
 */
export const Double = <InputComponent extends React.ElementType>(props: DoubleProps<InputComponent>) => {
    const {
        error,
        firstInputProps,
        id,
        inputComponent,
        onBlur,
        onChange,
        onFocus,
        secondInputProps,
        value,
        inputProps,
        ...otherProps
    } = props;
    const classes = useStyles(props);
    const currentValue = useRef<[any, any]>(['', '']);
    currentValue.current = Array.isArray(value) ? value : ['', ''];
    const childOnChange = useCallback((inputIndex: 0 | 1) => (eventOrValue: any) => {
        const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
        const newValue = [
            inputIndex === 0 ? value : currentValue.current[0],
            inputIndex === 1 ? value : currentValue.current[1]
        ] as [any, any];
        currentValue.current = newValue;
        onChange && onChange(newValue);
    }, [value]);
    const ComponentToRender = inputComponent;

    return (
        <div className={classes.root}>
            <div className={classes.firstInput}>
                {React.createElement(ComponentToRender, {
                    id: id + "[0]",
                    value: currentValue.current[0],
                    onChange: childOnChange(0),
                    onBlur: () => onBlur && onBlur(),
                    onFocus: () => onFocus && onFocus(),
                    error: Array.isArray(error) ? (error[0] ? error[0] : undefined) : error,
                    ...firstInputProps,
                    ...inputProps,
                    ...otherProps
                })}
            </div>
            <div className={classes.secondInput}>
                {React.createElement(ComponentToRender, {
                    id: id + "[1]",
                    value: currentValue.current[1],
                    onChange: childOnChange(1),
                    onBlur: () => onBlur && onBlur(),
                    onFocus: () => onFocus && onFocus(),
                    error: Array.isArray(error) ? (error[1] ? error[1] : undefined) : error,
                    ...secondInputProps,
                    ...inputProps,
                    ...otherProps
                })}
            </div>
        </div>
    );
};

export default Double;
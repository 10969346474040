import React from 'react';
import {
    ChipArrayField,
    getArrayDataContent,
    SelectedChoiceField,
    TextField,
    useCrudProps,
    WizardSummary
} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../../apiResources";
import {SELECT_EMPTY_VALUE} from "../../../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    haMessage: {
        color: theme.palette.warning.main
    },
    summaryChipArray: {
        maxWidth: 220
    },
}));

interface SiteChoice {
    id: number;
    name?: string;
    model?: string;
    serials?: string[];
}

interface PromoteSubmitStepProps {
    choices?: SiteChoice[];
    data?: {
        siteId: number;
    };
}

const PromoteSubmitStep: React.FC<PromoteSubmitStepProps> = ({choices, data}) => {
    const [translate] = useTranslation();
    const classes = useStyles();
    const gatewaysData = getArrayDataContent(useCrudProps(apiResources.gatewayNames)[0]?.data);
    const gatewayChoices = [
        {
            key: SELECT_EMPTY_VALUE,
            name: "tesseract.appliances.dialog.submit.noGateway"
        },
        ...gatewaysData
    ];
    const selectedSite = choices?.find((site) => site.id === data?.siteId);
    const siteIsHa = selectedSite && selectedSite.serials && selectedSite.serials.length > 1;
    return (
        <>
            <WizardSummary columns={[{xs: 12, sm: 5}, {xs: 12, sm: 7}]} data={selectedSite}>
                <TextField
                    column={0}
                    source="name"
                    label="tesseract.appliances.dialog.submit.name"
                />
                <TextField
                    column={0}
                    source="model"
                    label="tesseract.appliances.dialog.submit.model"
                />
                <ChipArrayField
                    column={0}
                    source="serials"
                    label="tesseract.appliances.dialog.submit.serial"
                />
                <SelectedChoiceField
                    source="gateway"
                    label="tesseract.appliances.dialog.submit.gateway"
                    column={1}
                    choices={gatewayChoices}
                />
                <ChipArrayField
                    source="wans"
                    label="tesseract.appliances.dialog.submit.wan"
                    render={(wan) => wan && Object.keys(wan)}
                    column={1}
                    classes={{root: classes.summaryChipArray}}
                />
            </WizardSummary>
            {!siteIsHa ? (
                <Typography className={classes.haMessage}>
                    {translate("tesseract.appliances.dialog.submit.haWarning")}
                </Typography>
            ) : null}
        </>
    );
};


export default PromoteSubmitStep;
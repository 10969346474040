import React from "react";
import {BaseBarCard} from "../../baseCards";
import {bytesFormatter, colorFunctionSuccess} from "../../../utils";
import {DashboardCardProps, DomainDataPoint} from "../../../typesAndConstants";
import {Trans} from "react-i18next";
import {BarGridEntry} from "../../statistics/BarGrid";
import {useReportingData} from "../../../contexts";
import {Typography} from "@barracuda-internal/bds-core";
import {sortBy} from "lodash";
import {DomainsIcon} from "@cuda-react/icons";
import {useTheme} from "@mui/material";

/**
 * Shows a list of allowed domains and a "progress bar".
 * The progress bar does not actually show the ratio of the values but is dependent on the position of the value.
 */
export const TopAllowedDomainsPerTrafficCard = (props: DashboardCardProps) => {
    const {preview, size} = props;
    const {data, loading, addFilter} = useReportingData("TopAllowedDomainsPerTrafficCard", preview);
    const {domainDataPoints = []} = data || {};
    const maxEntry = Math.max(...domainDataPoints.map((domainDataPoint: DomainDataPoint) => domainDataPoint.numberOfBytes));
    const theme = useTheme();

    const entries: BarGridEntry[] = sortBy(domainDataPoints, "numberOfBytes").reverse().map((domainDataPoint: DomainDataPoint) => ({
        title: domainDataPoint.label,
        value: bytesFormatter(domainDataPoint.numberOfBytes),
        barProgress: (100.0 / maxEntry) * domainDataPoint.numberOfBytes,
        icon: <DomainsIcon size={20}/>,
        color: colorFunctionSuccess((100.0 / maxEntry) * domainDataPoint.numberOfBytes, theme),
        onClick: addFilter && domainDataPoint.id !== "Consolidated" ? () => addFilter(domainDataPoint.id, "domainDataPoints") : undefined,
        tooltipMessage: (addFilter && domainDataPoint.id !== "Consolidated") ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            entries={entries}
            title={
                <Trans i18nKey="stratosReporting.topAllowedDomainsCard.titlePerTraffic">
                    <Typography variant="body1" component="span"/>
                </Trans>
            }
            size={size}
            preview={preview}
            loading={loading && !data.domainDataPoints}
            noData={!loading && !data.domainDataPoints}
            activeFilter
        />
    );
};
import React from "react";
import {getArrayDataContent, Select, SelectArray, useCrudProps} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {Grid} from "@barracuda-internal/bds-core";
import apiResources from "../../../apiResources";

export const SIZE_FILTER_CHOICE_KEY = "size-type";
const useStyles = makeStyles((theme) => ({
    container: {
        width: 256,
    },
    selectFilter: {
        marginTop: theme.spacing(1),
        width: "100%",
        maxWidth: 256,
    },
    typeSelect: {
        width: "100%",
        maxWidth: 256,
    },
}));
const sizeTypeChoices = [
    {key: "scaleUnit", name: "tesseract.gateways.table.filters.scaleUnit"},
    {key: "model", name: "tesseract.gateways.table.filters.model"},
    {key: "bandwidth", name: "tesseract.gateways.table.filters.bandwidth"},
];

interface SizeFilterProps {
    onChange: (value: any) => void;
    scaleUnitChoices?: {
        key: string;
        name: string
    }[];
    value?: {
        [SIZE_FILTER_CHOICE_KEY]: "scaleUnit" | "model" | "bandwidth";
        model?: string;
        scaleUnit?: string;
        bandwidth?: string;
    };
}

const SizeFilter: React.FC<SizeFilterProps> = ({
                                                   onChange,
                                                   scaleUnitChoices = [],
                                                   value = {[SIZE_FILTER_CHOICE_KEY]: ""},
                                               }) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const update = (field: string) => (eventOrValue: any) =>
        onChange({
            [SIZE_FILTER_CHOICE_KEY]: value[SIZE_FILTER_CHOICE_KEY] || "",
            [field]: eventOrValue?.target ? eventOrValue.target.value : eventOrValue,
        });
    const modelChoices = getArrayDataContent(useCrudProps(apiResources.modelNames)[0]?.data)
        .map((model: string) => ({key: model, name: model}));
    const filter =
        value[SIZE_FILTER_CHOICE_KEY] === "model" ? (
            <SelectArray
                key="gateway"
                label="tesseract.gateways.table.filters.model"
                id="model"
                choices={modelChoices}
                value={value.model}
                onChange={update("model")}
                className={classes.selectFilter}
            />
        ) : value[SIZE_FILTER_CHOICE_KEY] === "bandwidth" ? (
            <SelectArray
                key="gateway"
                id="bandwidth"
                label="tesseract.gateways.table.filters.bandwidth"
                choices={scaleUnitChoices}
                value={value.bandwidth}
                onChange={update("bandwidth")}
                className={classes.selectFilter}
            />
        ) : (
            <SelectArray
                id="scaleUnit"
                label="tesseract.gateways.table.filters.scaleUnit"
                choices={scaleUnitChoices}
                value={value.scaleUnit}
                onChange={update("scaleUnit")}
                className={classes.selectFilter}
            />
        );
    return (
        <Grid container direction="column" className={classes.container}>
            <Select
                id="cuda-filter-input-type"
                value={value[SIZE_FILTER_CHOICE_KEY]}
                onChange={(eventOrValue) =>
                    onChange({[SIZE_FILTER_CHOICE_KEY]: eventOrValue.target ? eventOrValue.target.value : eventOrValue})
                }
                label={translate("tesseract.gateways.table.filters.sizeSelector")}
                choices={sizeTypeChoices}
                classes={{select: classes.typeSelect, formControl: classes.typeSelect}}
            />
            {filter}
        </Grid>
    );
};

export default SizeFilter;
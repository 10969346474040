import get from "lodash/get";
import React from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

export const styles = (theme: Theme) => createStyles({
    root: {
        lineHeight: "24px",
        color: theme.palette.common.black,
        display: "inline-flex",
        verticalAlign: "middle",
        fontSize: theme.typography.pxToRem(14)
    }
});

const useStyles = makeOverrideableStyles("BooleanField", styles);

export interface CustomFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps {
    /**
     * function to render the content of the custom field
     * @function
     * @param {*} value the data value of the current custom field
     * @param {object} data the full data array
     * @returns {node} the content to display in the custom field, if null is returned then nothing will be rendered
     */
    render: (value: any, data?: any) => React.ReactNode,
}

/**
 * Renders a customized field wrapped in a div with an id build from the source prop.
 */
export const CustomField = (props: CustomFieldProps) => {
    const {source = "", data, render} = props;
    const classes = useStyles(props);
    const renderedValue = render(get(data || {}, source), data);

    return renderedValue !== null ? (
        <div id={"custom-field-" + source.replace(/[.]/g, "-")} className={classes.root}>
            {renderedValue}
        </div>
    ) : null;
};

export default CustomField;
import React, {useState} from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {concat, isEqual, omit, without} from "lodash";
import {ButtonDialog, DialogBody} from "../../dialog";
import {Theme} from "@mui/material";
import {Toolbar} from "../../layout";
import {Button, Checkbox, List, ListItem, ListItemIcon, ListItemText} from "@barracuda-internal/bds-core";
import {useDeepCompareEffect} from "../../../hooks";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    listContainer: {
        display: "flex",
        flexDirection: "row"
    },
    selectAllText: {
        fontWeight: theme.typography.fontWeightBold as number
    }
});
const useStyles = makeOverrideableStyles("ColumnSelectorDialog", styles);

type TableColumn = {
    /**
     * this column will not be hide-able by the user and must always be shown on the table
     */
    alwaysVisible?: boolean,
    /**
     * unique identifier for the column
     */
    field: string,
    /**
     * label to show in the dialog for selecting the column
     */
    title: string,
    /**
     * if false the column will not be shown in the selector dialog
     */
    show?: boolean,
    /**
     * width of the column
     */
    width?: number
}

export interface ColumnSelectorDialogContentProps extends StyledComponentProps<typeof styles> {
    /**
     * The columns which are currently being displayed in the table
     */
    columns: TableColumn[],
    /**
     * The columns which the table displays by default
     */
    defaultColumns: TableColumn[],
    /**
     * closes the dialog
     */
    onClose?: () => void
    /**
     * A callback which will be called with the updated columns when the user clicks save
     */
    onSave: (columns: TableColumn[]) => void
}

export const ColumnSelectorDialogContent = (props: ColumnSelectorDialogContentProps) => {
    const {columns, defaultColumns, onClose, onSave} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [selectedColumnFields, setSelectedColumnFields] = useState<string[]>([]);

    const selectableColumns = columns.filter((column) => !column.alwaysVisible);
    const someSelected = selectedColumnFields.length > 0 && selectedColumnFields.length < selectableColumns.length;
    const allSelected = !someSelected && selectedColumnFields.length === selectableColumns.length;

    const getFieldsFromColumns = (columnsToTransform: TableColumn[]) => columnsToTransform
        .filter((column) => column.show && !column.alwaysVisible)
        .map((column) => column.field);

    useDeepCompareEffect(() => {
        setSelectedColumnFields(getFieldsFromColumns(selectableColumns));
    }, [selectableColumns]);

    const handleReset = () => {
        onSave(defaultColumns);
        onClose?.();
    };
    const handleSave = () => {
        onSave(columns.map((column) => ({
            ...column,
            show: column.alwaysVisible || selectedColumnFields.includes(column.field)
        })));
        onClose?.();
    };
    const toggleColumn = (column: TableColumn) => {
        setSelectedColumnFields((currentFields) => selectedColumnFields.includes(column.field)
            ? without(currentFields, column.field)
            : concat(currentFields, column.field)
        );
    };
    const toggleAll = () => {
        if (!allSelected) {
            setSelectedColumnFields(selectableColumns.map((column) => column.field));
        } else {
            setSelectedColumnFields([]);
        }
    };

    const renderItem = (column: TableColumn) => (
        <ListItem
            key={column.field}
            onClick={() => toggleColumn(column)}
            dense
            button
        >
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={selectedColumnFields.includes(column.field)}
                    tabIndex={-1}
                    disableRipple
                />
            </ListItemIcon>
            <ListItemText primary={column.title}/>
        </ListItem>
    );

    const simplifyColumn = (col: TableColumn) => omit(col, "alwaysVisible");
    return (
        <React.Fragment>
            <DialogBody title="cuda.table.columnSelector.dialogTitle" onClose={onClose}>
                <ListItem onClick={toggleAll} dense button>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={allSelected}
                            indeterminate={someSelected}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All" primaryTypographyProps={{className: classes.selectAllText}}/>
                </ListItem>
                <div className={classes.listContainer}>
                    <List>
                        {selectableColumns.filter((column, index) => index % 2 === 0).map(renderItem)}
                    </List>
                    <List>
                        {selectableColumns.filter((column, index) => index % 2 === 1).map(renderItem)}
                    </List>
                </div>
            </DialogBody>
            <Toolbar>
                <Button
                    onClick={handleSave}
                    disabled={selectedColumnFields.length <= 0}
                    variant="contained"
                    color="primary"
                    size="small"
                >
                    {translate("cuda.buttons.save")}
                </Button>
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="secondary"
                    size="small"
                >
                    {translate("cuda.buttons.cancel")}
                </Button>
                <Button
                    onClick={handleReset}
                    disabled={isEqual(columns.map(simplifyColumn), defaultColumns.map(simplifyColumn))}
                    variant="contained"
                    color="primary"
                    size="small"
                    // @ts-ignore consumed by Toolbar
                    left
                >
                    {translate("cuda.buttons.reset")}
                </Button>
            </Toolbar>
        </React.Fragment>
    );
};

type ColumnSelectorDialogProps = Omit<ColumnSelectorDialogContentProps, "onClose">

// wrapped to let us get the onClose prop from the ButtonDialog
const ColumnSelectorDialog = (props: ColumnSelectorDialogProps) => (
    <ButtonDialog buttonText="cuda.table.columnSelector.buttonLabel">
        <ColumnSelectorDialogContent {...props}/>
    </ButtonDialog>
);

export default ColumnSelectorDialog;
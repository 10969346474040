import React, {useRef, useState} from "react";
import ChipArrayField from "../../fields/ChipArrayField/ChipArrayField";
import ActionButtonsField from "../../fields/ActionButtonsField/ActionButtonsField";
import {Add, Delete, Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import SelectArray, {SelectArrayProps} from "../SelectArrayInput/SelectArray";
import {get, isEqual, merge, set, uniq} from "lodash";
import ConnectedAutocompleteSearch, {
    ConnectedAutocompleteSearchProps
} from "../../search/ConnectedAutocompleteSearch/ConnectedAutocompleteSearch";
import AutocompleteSearch, {AutocompleteSearchProps} from "../../search/AutocompleteSearch/AutocompleteSearch";
import {useChoices} from "../../../hooks";
import {useTranslation} from "react-i18next";
import Toolbar from "../../layout/Toolbar/Toolbar";
import {Button, FormHelperText, IconButton, Popover} from "@barracuda-internal/bds-core";
import {Grid, GridColumn, GridNoRecords} from "@progress/kendo-react-grid";
import {DialogBody} from "../../dialog";
import {formatErrorMessage} from "../../../utils";
import classNames from "classnames";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {CrudParams} from "../../../clients";

const styles =  (theme: Theme) => createStyles({
    root: {
        textAlign: "left",
        display: "inline-block",
        maxWidth: "100%",
        padding: theme.spacing(1, 2),
        width: "calc(100% - " + theme.spacing(4) + ")"
    },
    dataTable: {
        "& .k-grid-content": {
            overflowY: "auto",
        },
        "& th": {
            textTransform: "uppercase"
        }
    },
    tableContainerError: {
        border: "solid 1px " + theme.palette.error.main
    },
    primaryColumn: {
        minWidth: "40%"
    },
    popover: {
        border: theme.palette.primary.main + " solid 1px",
        width: 512
    },
    popoverTitle: {
        paddingBottom: 0
    },
    popoverContent: {
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "flex-end",
        "& > *": {
            width: "50%",
            margin: theme.spacing(0, 1, 1)
        }
    },
    secondarySelectChips: {
        marginLeft: "calc(-100% - 8px)",
        maxWidth: "calc(200% + 8px)",
        width: "calc(200% + 8px)"
    },
    error: {
        marginLeft: theme.spacing(1)
    },
    rowError: {
        borderTop: "solid 1px " + theme.palette.error.main + "!important",
        borderBottom: "solid 1px " + theme.palette.error.main + "!important",
        "&:first-child": {
            borderLeft: "solid 1px " + theme.palette.error.main + "!important",
        },
        "&:last-child": {
            borderRight: "solid 1px " + theme.palette.error.main + "!important",
        }
    },
    cellError: {
        border: "solid 1px " + theme.palette.error.main + "!important"
    },
    actionButtons: {
        padding: 2
    }
});

const useStyles = makeOverrideableStyles("SelectPairArray", styles);

export interface SelectPairArrayProps extends StyledComponentProps<typeof styles> {
    /**
     * title for the add dialog.
     */
    addTitle?: string,
    /**
     * array of possible choices for primary column.
     */
    choices?: any[],
    /**
     * if true, icon buttons will be disabled.
     */
    disabled?: boolean,
    /**
     * title for the edit dialog.
     */
    editTitle?: string,
    /**
     * error message to display.
     */
    error?: any,
    /**
     * key on the list of choices to filter by.
     */
    filterKey?: string,
    /**
     * id of the component for unique identification. This value is prefixed with "select-pair-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
    /**
     * message to display in case there are not entries to display in the table. By default is set to "cuda.inputs.table.noDataMessage"
     */
    noDataMessage?: string,
    /**
     * @function onBlur callback to called when component stops being interacted with.
     */
    onBlur?: () => void,
    /**
     * @function onChange callback to call when input's value wants to be updated.
     * @param {object} choice the currently selected choice.
     */
    onChange?: (value: any[]) => void,
    /**
     * @function onFocus callback to called when input get focus.
     */
    onFocus?: () => void,
    /**
     * header title for the primary column.
     */
    primaryLabel?: string,
    /**
     * a dot-notation path to the primary column value in the choice objects that should be used as the identifying "value" when it is selected.
     */
    primaryOptionValue?: string,
    /**
     * additional props to pass to the (primary) underlying ChipArrayField and ConnectedAutocompleteSearch/AutocompleteSearch components.
     */
    primaryOptions?: Partial<ConnectedAutocompleteSearchProps> | Partial<AutocompleteSearchProps<any, any, any, any>>,
    /**
     * used to generate a unique ID for the primary Select input with the pattern 'select-input-{id}'.
     *
     * Additionally, if rendered within a [Form](/?path=/docs/core-components-forms-form--form)/[TabbedForm](/?path=/docs/core-components-forms-tabbedform--tabbed-form)
     * or [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) this value is treated as a dot-notation path to associate the input with a field in the form data.
     */
    primarySource: string,
    /**
     * [CRUD](/?path=/docs/cudareactapp-crud--page) resource to fetch choices from for the primary column. The fetched choices are added to the
     * provided choices (if any).
     */
    resource?: string,
    /**
     * can be either:
     *
     * - array of possible choices for Secondary column.
     * - a function, called with the activePrimaryChoice.
     * @function
     * @param {string} activePrimaryChoice current selected primary value.
     */
    secondaryChoices?: any[] | string | ((primaryChoice: any) => any[]),
    /**
     * header title for the secondary column.
     */
    secondaryLabel?: string,
    /**
     * for secondary entry: a dot-notation path to the value in the choice objects that should be displayed in the label of each choice.
     */
    secondaryOptionText?: string,
    /**
     * for secondary entry: a dot-notation path to the value in the choice objects that should be used as the identifying "value" when it is selected.
     */
    secondaryOptionValue?: string,
    /**
     * additional props to pass to the (primary) underlying ChipArrayField and SelectArray components.
     */
    secondaryOptions?: Partial<SelectArrayProps>,
    /**
     * [CRUD](/?path=/docs/cudareactapp-crud--page) resource to fetch choices from for the secondary column. The fetched choices are added to the
     * provided choices (if any).
     */
    secondaryResource?: string,
    /**
     * function, called with the activePrimaryChoice to filter choices by primary selected choice.
     * @function
     * @param {string} activePrimaryChoice current selected primary value.
     */
    secondaryResourceParams?: (primaryChoice: any) => CrudParams,
    /**
     * if true, new choice will be added with the label provided by the prop secondarySelectAllText ("all" in case is not provided) allowing users to select all choices for the second entry.
     * The value of that choice will be provided by selectAllValue prop.
     */
    secondarySelectAll?: boolean,
    /**
     * text for the select all choice.
     */
    secondarySelectAllText?: string,
    /**
     * value for the select all choice.
     */
    secondarySelectAllValue?: string,
    /**
     * used to generate a unique ID for the primary Select input with the pattern 'select-pair-{id}'.
     *
     * Additionally, this value is treated as a dot-notation path to associate the input with a field in the form data.
     */
    secondarySource: string,
    /**
     * current value of the table.
     */
    value?: string | any[]
}

interface PopoverState {
    anchorEl?: any,
    primaryValue?: string,
    secondaryValues?: string[],
    title?: string,
    editIndex?: number
}
interface PageState {skip: number, take: number}

/**
 * Renders a editable Grid with three colums. First two columns represent a 1 => n values mapping. Last column render action buttons for each row. Each row renders edit/remove icon buttons that will open a dialog (when clicked).
 * It also renders an add icon button to add new mappings to the table.
 * If resource prop is provided, choices for the primary column will be requested via * [CRUD](/?path=/docs/cudareactapp-crud--page).
 * If secondaryResource prop is provided, choices for the secondary column will be requested via * [CRUD](/?path=/docs/cudareactapp-crud--page).
 */
export const SelectPairArray = (props: SelectPairArrayProps) => {
    const {
        addTitle = "cuda.buttons.add",
        disabled,
        editTitle = "cuda.buttons.edit",
        error,
        filterKey,
        choices,
        onBlur,
        onChange,
        onFocus,
        primaryLabel,
        primaryOptionValue = "key",
        primaryOptions,
        id,
        primarySource,
        noDataMessage = "cuda.inputs.table.noDataMessage",
        secondaryChoices,
        secondaryLabel,
        secondaryOptions,
        secondaryOptionText = "name",
        secondaryOptionValue = "key",
        secondaryResource,
        secondaryResourceParams,
        secondarySource,
        secondarySelectAll,
        secondarySelectAllText = "cuda.inputs.selectArray.all",
        secondarySelectAllValue = "*",
        resource,
        value
    } = props;
    const tableRef = useRef(null);
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [popover, setPopover] = useState<PopoverState | null>(null);
    const [pageState, setPageState] = useState<PageState>({skip: 0, take: 5});
    const selectAllChoice = secondarySelectAll && set(set({}, secondaryOptionValue, secondarySelectAllValue), secondaryOptionText, secondarySelectAllText);

    const tableData = Array.isArray(value) ? value : (value ? [value] : []);

    const onSelectChange = (newValue: any) => {
        setPopover({
            ...popover,
            primaryValue: get(newValue, primaryOptionValue),
            secondaryValues: []
        });
    };

    const selectedValues = [
        ...tableData.map((item) => get(item, primarySource)),
        ...(popover && popover.primaryValue && [popover.primaryValue] || [])
    ];
    const [primarySelectedItems, addSelection] = useChoices(
        selectedValues,
        {
            choices,
            onAdd: onSelectChange,
            filterKey: filterKey || primaryOptionValue,
            optionValue: primaryOptionValue,
            resource,
            params: {
                pagination: {
                    page: 1,
                    perPage: tableData.length
                }
            }
        }
    );

    let allSecondaryChoices = Array.isArray(secondaryChoices) ? secondaryChoices : [];
    let activeSecondaryChoices = Array.isArray(secondaryChoices) ? secondaryChoices : [];
    let activePrimaryChoice = popover && primarySelectedItems.find((item) => isEqual(get(item, primaryOptionValue), popover.primaryValue));
    if (typeof secondaryChoices === "string") {
        allSecondaryChoices = uniq(primarySelectedItems.flatMap((item) => get(item, secondaryChoices)));
        activeSecondaryChoices = activePrimaryChoice && get(activePrimaryChoice, secondaryChoices) || [];
    }
    if (typeof secondaryChoices === "function") {
        allSecondaryChoices = uniq(primarySelectedItems.flatMap(secondaryChoices));
        activeSecondaryChoices = activePrimaryChoice && secondaryChoices(activePrimaryChoice) || [];
    }

    const fields: React.ReactElement[] = [
        <ChipArrayField
            source={primarySource}
            key={primarySource}
            label={primaryLabel}
            choices={primarySelectedItems}
            optionValue={primaryOptionValue}
            resource={resource}
            filterKey={filterKey}
            cellClassName={classes.primaryColumn}
            {...primaryOptions}
        />,
        <ChipArrayField
            source={secondarySource}
            key={secondarySource}
            label={secondaryLabel}
            choices={[...(secondarySelectAll ? [selectAllChoice] : []), ...allSecondaryChoices]}
            resource={secondaryResource}
            optionValue={secondaryOptionValue}
            optionText={secondaryOptionText}
            {...secondaryOptions}
        />,
        <ActionButtonsField
            source="actions"
            width={96}
            key="actions"
        >
            <IconButton
                size="small"
                onClick={(event: any, data?: any) => {
                    setPopover({
                        anchorEl: tableRef.current,
                        primaryValue: get(data, primarySource),
                        secondaryValues: get(data, secondarySource),
                        title: editTitle,
                        editIndex: data.index
                    });
                    onFocus?.();
                }}
                disabled={disabled}
                className={classes.actionButtons}
            >
                <Edit/>
            </IconButton>
            <IconButton
                size="small"
                onClick={(event: any, data?: any) => {
                    onChange?.(tableData.filter((item, index) => index !== data.index));
                    onBlur?.();
                }}
                disabled={disabled}
                className={classes.actionButtons}
            >
                <Delete />
            </IconButton>
        </ActionButtonsField>
    ];

    return (
        <div className={classes.root} id={"select-pair-array-input-" + id}>
            <div ref={tableRef}>
                <Grid
                    data={tableData.slice(pageState.skip || 0, ((pageState.skip || 0) + 5))}
                    className={classNames(classes.dataTable, error && classes.tableContainerError)}
                    pageable={tableData.length ? {
                        buttonCount: 5,
                        info: true,
                        previousNext: true,
                        pageSizes: [5]
                    } : undefined}
                    onPageChange={(event: {page: PageState}) => setPageState(event.page)}
                    total={tableData.length}
                    {...pageState}
                    headerCellRender={(header, {field}) => {
                        if (field === "actions" && header) {
                            return React.cloneElement(header as React.ReactElement, {}, (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setPopover({
                                            anchorEl: tableRef.current,
                                            primaryValue: "",
                                            secondaryValues: [],
                                            title: addTitle
                                        });
                                        onFocus?.();
                                    }}
                                    disabled={disabled}
                                >
                                    <Add id="cuda-icon-add"/>
                                </IconButton>
                            ));
                        }
                        return header;
                    }}
                    cellRender={(cell, {className, columnIndex, dataIndex, dataItem}) => {
                        const rowError = error && typeof error[dataIndex] === "string";
                        const field: React.ReactElement | undefined = get(fields, `[${columnIndex}]`);
                        const cellError = get(error, `[${dataIndex}].${get(field, `props.source`)}`);

                        return (
                            <td className={classNames(className, cellError && classes.cellError, rowError && classes.rowError)}>
                                {field && React.cloneElement(field, {
                                    data: {
                                        ...(dataItem || {}),
                                        index: dataIndex
                                    },
                                    total: tableData.length,
                                    disabled
                                }) || null}
                            </td>
                        );
                    }}
                >
                    <GridNoRecords>
                        {translate(noDataMessage)}
                    </GridNoRecords>
                    {fields.map((field) => (
                        <GridColumn
                            key={field.props.source}
                            field={field.props.source}
                            title={field.props.label && translate(field.props.label) || " "}
                            className={field.props.cellClassName}
                            headerClassName={field.props.cellClassName}
                            width={field.props.width}
                            {...field.props.columnProps}
                        />
                    )) as any /** Need to do 'as any' here as the Kendo table isnt properly typescripted to accept arrays (even though it works fine). */}
                </Grid>
                <Popover
                    onClose={() => {
                        setPopover(null);
                        onBlur?.();
                    }}
                    transformOrigin={{horizontal: "center", vertical: "center"}}
                    anchorOrigin={{horizontal: "center", vertical: "center"}}
                    open={!!(popover && popover.anchorEl)}
                    anchorEl={popover && popover.anchorEl || null}
                    classes={{paper: classes.popover}}
                >
                    <DialogBody
                        title={popover ? popover.title : undefined}
                        onClose={() => {
                            setPopover(null);
                            onBlur?.();
                        }}
                    >
                        <div className={classes.popoverContent}>
                            {resource ? (
                                <ConnectedAutocompleteSearch
                                    value={activePrimaryChoice || undefined}
                                    onChange={addSelection}
                                    label={primaryLabel}
                                    resource={resource}
                                    disabled={!!popover && popover.editIndex !== undefined}
                                    id={"select-input-" + primarySource.replace(/[.]/g, "-")}
                                    {...primaryOptions}
                                />
                            ) : (
                                <AutocompleteSearch
                                    value={activePrimaryChoice || undefined}
                                    onChange={addSelection}
                                    label={primaryLabel}
                                    disabled={!!popover && popover.editIndex !== undefined}
                                    choices={choices}
                                    id={"select-input-" + primarySource.replace(/[.]/g, "-")}
                                    {...primaryOptions}
                                />
                            )}
                            <SelectArray
                                value={popover && popover.secondaryValues || []}
                                onChange={(newValue) => setPopover({...popover, secondaryValues: newValue})}
                                choices={activeSecondaryChoices}
                                resource={secondaryResource}
                                options={{
                                    label: secondaryLabel,
                                    params: (secondaryResourceParams ?  secondaryResourceParams(activePrimaryChoice) : undefined)
                                }}
                                classes={{chipContainer: classes.secondarySelectChips}}
                                disabled={!activeSecondaryChoices || activeSecondaryChoices.length < 1}
                                id={secondarySource.replace(/[.]/g, "-")}
                                optionValue={secondaryOptionValue}
                                optionText={secondaryOptionText}
                                selectAll={secondarySelectAll}
                                selectAllText={secondarySelectAllText}
                                selectAllValue={secondarySelectAllValue}
                                {...secondaryOptions}
                            />
                        </div>
                        <Toolbar>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const newArrayValue = [...tableData];
                                    const existingIndex = popover?.editIndex !== undefined ? popover?.editIndex : newArrayValue.findIndex((value) => get(value, primarySource) === popover?.primaryValue);
                                    const secondaryValues = [...(popover?.editIndex === undefined && get(tableData[existingIndex], secondarySource) || []), ...(popover?.secondaryValues || [])];
                                    newArrayValue.splice(
                                        existingIndex >= 0 ? existingIndex : newArrayValue.length,
                                        1,
                                        set(
                                            set(merge({}, tableData[existingIndex]), primarySource, popover?.primaryValue),
                                            secondarySource,
                                            secondaryValues.includes(secondarySelectAllValue) ? [secondarySelectAllValue] : uniq(secondaryValues)
                                        )
                                    );
                                    onChange?.(newArrayValue);
                                    setPopover(null);
                                    onBlur?.();
                                }}
                                disabled={!popover || !popover.primaryValue || !popover.secondaryValues || popover.secondaryValues.length < 1}
                            >
                                {translate("cuda.state.ok")}
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => {
                                    setPopover(null);
                                    onBlur?.();
                                }}
                            >
                                {translate("cuda.buttons.cancel")}
                            </Button>
                        </Toolbar>
                    </DialogBody>
                </Popover>
                {error && (
                    <FormHelperText error className={classes.error}>
                        {formatErrorMessage(error)}
                    </FormHelperText>
                )}
            </div>
        </div>
    );
};

export default SelectPairArray;
import React from "react";

import {Box, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {UserDirectoryType} from "../UserDirectoriesTypes";
import {AddUserDirectoryButton} from "./AddUserDirectoryButton";

const i18nScope = "tesseract.identity.userDirectories";

interface UserDirectoriesNoDataProps {
    onSelect: (type: UserDirectoryType) => void;
}

export const UserDirectoriesNoData = ({onSelect}: UserDirectoriesNoDataProps) => {
    const [translate] = useTranslation();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="300px"
        >
            <Typography>
                {translate(`${i18nScope}.noDataMessage`)}
            </Typography>
            <Typography mt={1} mb={2}>
                {translate(`${i18nScope}.clickBelow`)}
            </Typography>
            <AddUserDirectoryButton onSelect={onSelect} />
        </Box>
    );
};
import SvgIcon from "@mui/material/SvgIcon";
import {useTheme} from "@mui/material";
import React from "react";
import classNames from "classnames";

export interface UrlCategoriesIconProps {
    className?: string,
    /** size (in pixels) of icon */
    size?: number,
}

export const UrlCategoriesIcon = (props: UrlCategoriesIconProps) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(props.className, "cuda-react-icon")}
            id="cuda-icon-private-edge"
            viewBox="0 0 60 60"
            style={props.size ? {width: props.size, height: props.size} : {}}
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M38.6,19.8c-9,0-16.2,7.3-16.2,16.4s7.3,16.4,16.2,16.4,16.2-7.3,16.2-16.4-7.3-16.4-16.2-16.4ZM25.7,36.1c0-1,.1-2,.4-2.9l7.8,7.8v1.6c0,1.8,1.5,3.3,3.2,3.3v3.2c-6.4-.8-11.4-6.3-11.4-13ZM48.2,45c-.4-1.3-1.6-2.3-3.1-2.3h-1.6v-4.9c0-.9-.7-1.6-1.6-1.6h-9.7v-3.3h3.2c.9,0,1.6-.7,1.6-1.6v-3.3h3.2c1.8,0,3.2-1.5,3.2-3.3v-.7c4.8,1.9,8.1,6.6,8.1,12.1s-1.3,6.5-3.4,8.8h0Z"/>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M20.5,40.4h-10.8V12.2h12l4.7,4.7h20.6v2.6c1.7.9,3.3,2,4.7,3.4v-5.9c0-2.6-2.1-4.7-4.7-4.7h-18.6l-4.7-4.7h-14c-2.6,0-4.7,2.1-4.7,4.7v28.2c0,2.6,2.1,4.7,4.6,4.7h12.5c-.8-1.5-1.4-3-1.7-4.7h0Z"/>
        </SvgIcon>
    );
};

export default UrlCategoriesIcon;
import {DialogBody, LoadingMessage, useCrudProps} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import React from "react";
import {IdentityProviderForm} from "./IdentityProviderForm/IdentityProviderForm";
import useDemoApi from "../../../../../hooks/useDemoApi";

interface EditIdentityProviderWrapperProps {
    id: string;
    onClose: () => void;
    onSuccess: () => void;
}

export const EditIdentityProviderWrapper = ({id, onClose, onSuccess}: EditIdentityProviderWrapperProps) => {
    const identityProviderApiResource = useDemoApi(apiResources.identityProvider, apiResources.demoSingleProvider);
    const identityProvider = useCrudProps(identityProviderApiResource, {id})[0]?.data;

    if (!identityProvider){
        return <LoadingMessage />;
    }

    return (
        <DialogBody form>
            <IdentityProviderForm
                create={false}
                id={id}
                identityProvider={identityProvider}
                onClose={(refresh) => {
                    if (refresh) {
                        onSuccess();
                    }
                    onClose();
                }}
            />
        </DialogBody>
    );
};
import React from "react";
import {
    ConnectedForm,
    DialogBody,
    getArrayDataContent,
    SelectInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {useGeneratedSourceCriteriaSection} from "../../../../../hooks/sourceAndDestinationHooks";
import {formatRequestCriteria, formatResourceCriteria} from "../../../../../components/sourceAndDestination/formUtils";
import generateDestinationCriteriaSection
    from "../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import {scanIcons} from "../../../../../components/iconMapping";
import apiResources from "../../../../../apiResources";

export const styles: any = {
    dialogContent: {},
    multiInputButtons: {
        width: 74,
        flexGrow: "0 !important",
        flexShrink: 0,
        height: 50,
        marginLeft: 0,
        marginRight: 8
    },
    multiInputInputGroup: {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        "& > *": {
            flexGrow: 1,
            maxWidth: "calc(100% - 122px)"
        }
    },
    multiInputDivider: {
        width: "calc(100% - 64px)",
        maxWidth: "calc(100% - 64px) !important",
        marginBottom: -4
    },
    multiInputNetworkSelectChips: {
        marginLeft: "calc(-100% - 8px)",
        maxWidth: "calc(200% + 186px)",
        width: "calc(200% + 186px)"
    }
};
const useStyles = makeStyles<any>(styles);

/**
 * For now we are using this workaround to get the karma tests working.
 */

interface CreateEditMalwareRuleProps {
    create?: boolean;
    id?: string | number;
    onClose?: () => void;
    onSuccess?: () => void;
}

export const CreateEditMalwareRule: React.FC<CreateEditMalwareRuleProps> = ({create, id, onSuccess, ...props}) => {
    const registerAction = useMixpanel("Malware", false);
    // @ts-ignore
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.malwareActions, {}, {cache: true})[0]?.data);
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const classes = useStyles();

    return (
        <DialogBody
            {...props}
            form
            title={create ?
                "tesseract.security.protection.settings.create.dialogTitle" :
                "tesseract.security.protection.settings.edit.dialogTitle"
            }
            classes={{dialogContent: classes.dialogContent}}
        >
            <ConnectedForm
                resource={apiResources.malware}
                // @ts-ignore
                form="malware"
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={(response) => {
                    registerAction(create ? "Create" : "Update", {
                        action: response.action,
                        sourceType: response.source?.type,
                        destinationType: response.destination?.type
                    });
                    onSuccess?.();
                    // @ts-ignore
                    props.onClose();
                }}
                onCancel={props.onClose}
                formatRequestData={formatRequestCriteria(virtualWanId)}
                formatResourceData={formatResourceCriteria}
                initialValues={create ? {source: {allSites: true}, destination: {allSites: true}} : undefined}
                allowDirtyNavigation
            >
                <TextInput
                    source="name"
                    label="tesseract.security.protection.settings.name"
                    description="tesseract.security.protection.settings.descriptions.name"
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.security.protection.settings.description"
                    description="tesseract.security.protection.settings.descriptions.description"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                />
                <SelectInput
                    source="action"
                    label="tesseract.security.protection.settings.action"
                    description="tesseract.security.protection.settings.descriptions.action"
                    iconMap={scanIcons}
                    choices={actionChoices}
                    isRequired
                />
                {useGeneratedSourceCriteriaSection(["network", "site", "userOrGroup"])}
                {generateDestinationCriteriaSection(["application", "network", "domain", "site"])}
            </ConnectedForm>
        </DialogBody>
    );
};


export default CreateEditMalwareRule;
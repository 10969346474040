import {Button, ButtonProps, Tooltip} from "@barracuda-internal/bds-core";
import {Download} from "@barracuda-internal/bds-core/dist/Icons/Core";
import fileDownload from "js-file-download";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {convertRESTRequestToHTTP, CrudTypes} from "../../../clients";
import {getDataContent} from "../../../utils";
import {useCrudFetch, useGlobalParam} from "../../../hooks";
import {IconButton, Theme} from "@mui/material";
import ConditionalWrapper from "../../functional/ConditionalWrapper/ConditionalWrapper";
import {get, merge} from "lodash";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";

export const styles = (theme: Theme) => createStyles<string, CoreDownloadButtonProps>({
    downloadButton: {
        minWidth: "50px !important"
    },
    downloadIcon: {
        color: (props) => props.disabled ? theme.palette.text.disabled : undefined
    },
    tooltip: {
        ...theme.typography.body2
    }
});

const useStyles = makeOverrideableStyles("DownloadButton", styles);

export interface CoreDownloadButtonProps {
    /** String, is passed on as secondary if left undefined */
    color?: ButtonProps["color"] | undefined,
    /** Boolean, if true the browser downloads the file from the server directly. This is mainly used when the file to download has a big size */
    directDownload?: boolean,
    /** Disables the button if true */
    disabled?: boolean,
    /** Turns on and displays tooltip text if provided and disabled is true. */
    disabledTooltipText?: string,
    /** Default downloaded file name to use. Can either be a plain string, or a callback that returns the string */
    filename?: string | (() => string),
    /** file data to use for the download. Either filedata or resource should be provided. */
    filedata?: string | ArrayBuffer | ArrayBufferView | Blob,
    /** file type extension. Can either be a plain string, or a callback that returns the string **/
    filetype?: string | (() => string),
    /** callback for formatting the data immediately before download **/
    formatData?: ((downloadData: any, props: DownloadButtonProps) => {}),
    /** Uses a plain IconButton instead of large textual button if true. */
    iconButton?: boolean,
    /** Label to display on the button */
    label?: string,
    /** [CRUD](/?path=/docs/key-concepts-crud) fetch params. Can either be a param object, or a callback that accepts props and returns a param object */
    params?: {} | ((props: {}) => {}),
    /** Suppress error message popups if request fails */
    quietErrors?: boolean,
    /** The type of CRUD operation to perform */
    requestMethod?: string,
    /** The resource to call to fetch the file data, can either be a callback that accepts props and returns a resource or a string */
    resource: ((props: {}) => string) | string,
    /** Turns on and displays tooltip text if provided. */
    tooltipText?: string
}

// seperated out to prevent self-referencing types, as styles is dependent on CoreTableProps too
export interface DownloadButtonProps extends CoreDownloadButtonProps, StyledComponentProps<typeof styles> {
}

const convertStringToCrudType = (stringCrudType: string) =>{
    switch (stringCrudType) {
        case "PUT":
            return CrudTypes.UPDATE;
        case "POST":
            return CrudTypes.CREATE;
        case "GET":
            return CrudTypes.GET;
        case "DELETE":
            return CrudTypes.DELETE;
        default:
            return CrudTypes.GET;
    }
};

/**
 * Button for downloading data to a file. The data to download can be either provided directly as a prop, or from a [CRUD](/?path=/docs/cudareactapp-crud-overview) resource that is called on click.
 */
export const DownloadButton = (props: DownloadButtonProps) => {
    const {resource: originalResource, filename, filedata, filetype, formatData, params, quietErrors, label, iconButton, tooltipText, disabled, disabledTooltipText, directDownload, requestMethod} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [download, setDownload] = useState<boolean>();
    const [globalParams] = useGlobalParam();
    const resolvedParams = typeof params === "function" ? params(props) : params;
    const resource = typeof originalResource === "function" ? originalResource(props) : originalResource;
    const crudType = requestMethod ? convertStringToCrudType(requestMethod) : undefined;
    const {url: resolvedUrl} = directDownload ? convertRESTRequestToHTTP(crudType || CrudTypes.GET, resource, merge({}, params, globalParams)) : {url: undefined};
    const [data, loading, fetch] = useCrudFetch(crudType || CrudTypes.GET, resource, resolvedParams, {quietErrors});
    const downloadData = getDataContent(data) || filedata;
    const getFileInfo = (propToGet?: string | ((downloadData: string, props: {}) => string), type?: string | (() => string)) => (typeof propToGet === "function" ? propToGet(downloadData, props) : type === "name" ? translate(propToGet || resource) : propToGet);
    const fileName = getFileInfo(filename, "name");
    const fileType = getFileInfo(filetype, "typeFile");

    useEffect(() => {
        if (!loading && downloadData && download && !get(data, "error")) {
            fileDownload(formatData ? formatData(downloadData, props) : downloadData, fileName + "." + fileType);
            setDownload(false);
        }
    }, [loading, download]);

    return (
        <ConditionalWrapper
            condition={!!tooltipText || !!(disabledTooltipText && disabled)}
            wrapper={(children) => (
                <Tooltip
                    title={translate(disabled ? disabledTooltipText as string : tooltipText as string)}
                    placement="top"
                    classes={{tooltip: classes.tooltip}}
                >
                    <span>
                        {children}
                    </span>
                </Tooltip>
            )}
        >
            {iconButton ? (
                <IconButton
                    size="small"
                    onClick={() => {
                        resource && fetch();
                        setDownload(true);
                    }}
                    disabled={disabled}
                >
                    <Download className={classes.downloadIcon} />
                </IconButton>
            ) : (
                <Button
                    onClick={!directDownload ? () => {
                        resource && fetch();
                        setDownload(true);
                    } : undefined}
                    href={resolvedUrl}
                    // @ts-ignore Not sure why this property is missing, it does exist on the underlying dom element.
                    download={!directDownload ? undefined : fileName + "." + fileType}
                    variant="contained"
                    size="small"
                    startIcon={<Download/>}
                    className={classes.downloadButton}
                    color={props.color || "secondary"}
                    disabled={disabled}
                >
                    {translate(label || "cuda.buttons.downloadCsv")}
                </Button>
            )}
        </ConditionalWrapper>
    );
};

DownloadButton.defaultProps = {
    disabled: false,
    filetype: "csv"
};

export default DownloadButton;
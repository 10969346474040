import {ButtonDialog} from "@cuda-react/core";
import React from 'react';
import UpdateDialogContent from "./UpdateDialogContent";


interface Appliance {
    key?: number;
    name?: string;
    type?: "site" | "gateway" | string;
}

interface CreateUpdateDialogProps {
    disabled?: boolean;
    disabledMessage?: string;
    appliances: Appliance[];
    availableUpdates: Record<string, any>;
    onSuccess?: () => void;
}

const CreateUpdateDialog: React.FC<CreateUpdateDialogProps> = ({disabled, appliances, onSuccess, availableUpdates, disabledMessage}) => (
    <ButtonDialog buttonText="tesseract.settings.tabs.updates.firmware.create.buttonText" disabled={disabled}
                  disabledMessage={disabledMessage}>
        <UpdateDialogContent
            appliances={appliances}
            title="tesseract.settings.tabs.updates.firmware.create.dialogTitle"
            onSuccess={onSuccess}
            availableUpdates={availableUpdates}
        />
    </ButtonDialog>
);


export default CreateUpdateDialog;
import {useI18next} from "./i18n";
import {useTranslation} from "react-i18next";
import React from "react";

export interface LanguageFullResetProps {
    /** children to render within the language reset context */
    children?: React.ReactNode
}

/**
 * By wrapping your entire layout in this component, the layout will be fully re-rendered if the language changes (allowing external dependencies, such as server side
 * translated components, to be refetched).
 * This is done by temporarily unmounting then mounting the children whenever the i18n language is changed via the useI18next() hook.
 * @param children
 * @constructor
 */
export const LanguageFullReset = ({children}: LanguageFullResetProps) => {
    const {activeLanguage} = useI18next();
    const [, {language}] = useTranslation();

    return (
        <>
            {activeLanguage === language ? children : null}
        </>
    );
};
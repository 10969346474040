import en from "./en.json";
import fr from "./fr.json";
import ja from "./ja.json";
import de from "./de.json";

export default {
    de,
    en,
    fr,
    ja
};
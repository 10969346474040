import React from "react";
import {Route, Switch, withRouter} from "react-router";
import GatewayTable from "./GatewayTable";
import GatewayDetails from "./GatewayDetails";

interface GatewaysProps {
    match: any
}

export const Gateways: React.FC<GatewaysProps> = ({match}) => (
    <Switch>
        <Route
            exact
            path={match.path}
            component={GatewayTable}
        />
        <Route
            path={`${match.path}/:id`}
            component={GatewayDetails}
        />
    </Switch>
);


export default withRouter(Gateways) as React.ComponentClass;
import {CardFetchProps} from "./useCardFetch";
import {CrudOptions, CrudParams, getArrayDataContent, useCrudProps} from "@cuda-react/core";
import {Status, useStatisticsCardDetailsAndTotal} from "./useStatisticsCardDetailsAndTotal";
import {useCardFetch} from "./index";

export interface CrudProps {
    url?: string,
    params?: CrudParams,
    options?: CrudOptions
}

export interface Formatters {
    linkCreator?: (status: Status) => string,
    getKeyColor?: (key: string) => string
}

export interface StatisticsDetails {
    title: string,
    value: number,
    color: string,
    link?: string
}

export type FormatResourceDataFunction = (
    states: any,
    overviewData: any,
    linkCreator?: (status: Status) => string,
    getKeyColor?: (key: string) => string
) => [StatisticsDetails[], number | undefined];

export const formatResourceData: FormatResourceDataFunction = (states, overviewData, linkCreator, getKeyColor) => {
    const [formattedStates, totalFunc] = useStatisticsCardDetailsAndTotal(getArrayDataContent(states),
        linkCreator, getKeyColor);
    const staticData = formattedStates.map((status): StatisticsDetails => ({
        ...status,
        value: overviewData?.[status.value]
    }));
    const total = totalFunc(overviewData);
    return [staticData, total];
};

export type UseFetchStatisticsCardDetailsAndTotalHook = (
    cardFetchParams: CardFetchProps,
    crudProps: CrudProps,
    formatters?: Formatters
) => [StatisticsDetails[] | undefined, number | undefined];

export const useFetchStatisticsCardDetailsAndTotal: UseFetchStatisticsCardDetailsAndTotalHook = (cardFetchParams, crudProps, formatters) => {
    const {data: overviewData} = useCardFetch(cardFetchParams);
    const states = useCrudProps(crudProps?.url, crudProps?.params, crudProps?.options)?.[0]?.data;
    const [staticData, total] = formatResourceData(states, overviewData, formatters?.linkCreator, formatters?.getKeyColor);
    return [overviewData ? staticData : undefined, overviewData ? total : undefined];
};
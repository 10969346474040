import React from "react";
import {Route, Switch, withRouter} from "react-router";
import SiteDetails from "./SiteDetails";
import SiteTable from "./SiteTable";

interface SitesProps {
    match: any;
}

export const Sites: React.FC<SitesProps> = ({match}) => (
    <Switch>
        <Route
            exact
            path={match.path}
            component={SiteTable}
        />
        <Route
            path={`${match.path}/:id`}
            component={SiteDetails}
        />
    </Switch>
);


export default withRouter(Sites) as React.ComponentClass;
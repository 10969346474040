import {ButtonDialog} from "@cuda-react/core";
import React from "react";
import NotificationContent from "./NotificationContent";

interface AddNotificationDialogProps {
    onSuccess?: any
}

const AddNotificationDialog: React.FC<AddNotificationDialogProps> = ({onSuccess}) => (
    <ButtonDialog buttonText="tesseract.settings.tabs.notification.components.button.add">
        <NotificationContent
            create
            title="tesseract.settings.tabs.notification.components.title.createNotification"
            onSuccess={onSuccess}
        />
    </ButtonDialog>
);

export default AddNotificationDialog;
import React, {useRef} from "react";
import {ActionButtonsField, ConnectedTable, DateField, DeleteDialog, TextField, useMixpanel} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import CreateEditButtonDialog from "../../../../../../components/CreateEditButtonDialog";
import CreateEditSslCertificates from "./CreateEditSslCertificates";
import ExpiryStatus from "./ExpiryStatus";
import apiResources from "../../../../../../apiResources";

export const styles = (theme: { spacing: (arg0: number) => string; }) => ({
    cardActions: {
        paddingRight: theme.spacing(2),
        width: "calc(100% - " + theme.spacing(2) + ")"
    },
    actions: {
        paddingRight: 0,
        "&> span": {
            position: "relative",
            left: "5%"
        }
    }
});
const useStyles = makeStyles(styles);

export const SslInterceptionCAs = () => {
    const registerAction = useMixpanel("SSL CA", false);
    const classes = useStyles();
    const tableRefreshRef = useRef<any>();

    return (
        <div id="ssl-certificate-authorities">
            <ConnectedTable
                resource={apiResources.sslCertificateAuthorities}
                actions={[
                    <CreateEditButtonDialog
                        key="create"
                        create
                        component={CreateEditSslCertificates}
                        type="trustedRoot"
                        label="tesseract.security.sslInterception.settings.certificates.create.label"
                        onSuccess={() => {
                            registerAction("Create");
                            tableRefreshRef.current?.();
                        }}
                    />
                ]}
                classes={{cardActions: classes.cardActions}}
                params={{filter: {type: "trustedRoot"}}}
                minCellWidth={128}
                refreshRef={tableRefreshRef}
                flat
            >
                <ExpiryStatus source="end"
                    // @ts-ignore
                              label="tesseract.security.sslInterception.settings.status" width={96}/>
                <TextField source="name" label="tesseract.security.sslInterception.settings.name"/>
                <TextField source="description" label="tesseract.security.sslInterception.settings.description"/>
                <TextField source="issuer" label="tesseract.security.sslInterception.settings.issuer"/>
                <DateField source="start" label="tesseract.security.sslInterception.settings.startDate"/>
                <DateField source="end" label="tesseract.security.sslInterception.settings.endDate"/>
                <ActionButtonsField
                    cellClassName={classes.actions}
                    source="id"
                    width={120}
                >
                    <CreateEditButtonDialog
                        component={CreateEditSslCertificates}
                        type="trustedRoot"
                        onSuccess={() => {
                            registerAction("Update");
                            tableRefreshRef.current?.();
                        }}
                    />
                    <DeleteDialog
                        resource={apiResources.sslCertificateAuthorities}
                        title="tesseract.security.sslInterception.settings.certificates.delete.title"
                        message="tesseract.security.sslInterception.settings.certificates.delete.body"
                        onSuccess={() => {
                            registerAction("Delete");
                            tableRefreshRef.current?.();
                        }}
                    />
                </ActionButtonsField>
            </ConnectedTable>
        </div>
    );
};

export default SslInterceptionCAs;
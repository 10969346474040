import React from "react";
import {useTranslation} from "react-i18next";
import {
    Card,
    CrudTypes,
    CustomField,
    DateField,
    formatDateTime,
    getArrayDataContent,
    getDataContent,
    ListCardContent,
    StatusIconField,
    TableCardContent,
    TextField,
    useCrudSubscription
} from "@cuda-react/core";
import {hotfixUpdateStatusIcons} from "../../../../components/iconMapping";
import {get} from "lodash";
import apiResources from "../../../../apiResources";


interface FirmwareCardProps {
    details: {
        gatewayType?: "on-prem" | "cloud" | "managed";
        id?: string | number;
        activeSerial?: string;
        modelSeries?: "S" | "T";
    };
    type: "gateways" | "sites";
    column?: number;
}

const FirmwareCard: React.FC<FirmwareCardProps> = ({type, details}) => {
    const [translate] = useTranslation();

    const gatewayType = details?.gatewayType;

    const isCloud = !(type === "sites" || gatewayType === "on-prem");
    const [applianceFirmwareData] = useCrudSubscription(
        CrudTypes.GET,
        isCloud ? apiResources.gatewayFirmwareStatus : (details.activeSerial && (gatewayType === "on-prem" ? apiResources.configurationOnPremFirmware : apiResources.configurationFirmware)),
        isCloud ? {id: details.id} : {id: details.activeSerial, siteId: details.id},
        {pollInterval: 30000}
    );
    const applianceFirmwareStatus = getDataContent(applianceFirmwareData);
    const [updateWindowsData] = useCrudSubscription(CrudTypes.GET, apiResources.update, {}, {pollInterval: 30000});
    const updateWindows = getArrayDataContent(updateWindowsData);
    const currentApplianceUpdateWindow =
        updateWindows
            .find((update) => update.configurationId === details.id && update.configurationType === (type === "sites" ? "site" : "gateway"))
        ?? updateWindows
            .find((update) => update.allConfigurations === true);
    const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");

    const detailsParams = {
        firmwareStatus: applianceFirmwareStatus,
        firmwareUpdate: currentApplianceUpdateWindow
    };

    return (
        <>
            <Card
                id="FirmwareDetailsCard"
                title="tesseract.appliances.dashboard.firmware.details.tabTitle"
            >
                <ListCardContent data={detailsParams}>
                    <TextField label="tesseract.appliances.dashboard.firmware.details.version"
                               source="firmwareStatus.firmwareVersion"/>
                    {type === "gateways" || !isSc ?
                        <TextField
                            label="tesseract.appliances.dashboard.firmware.details.hotfixes"
                            source="firmwareStatus.hotfixes"
                        /> : null}
                    {currentApplianceUpdateWindow ?
                        <CustomField
                            label="tesseract.appliances.dashboard.firmware.details.window"
                            source="firmwareUpdate"
                            render={(value) => translate(
                                "tesseract.appliances.dashboard.firmware.details.nextUpdate",
                                {
                                    ...value,
                                    from: formatDateTime(value.from),
                                    to: formatDateTime(value.to)
                                }
                            )}
                        /> : null
                    }
                </ListCardContent>
            </Card>
            {(type === "sites" && !isSc) || gatewayType === "on-prem" ? (
                <Card
                    id="FirmwareHotfixesCard"
                    title="tesseract.appliances.dashboard.firmware.hotfixes.tabTitle"
                >
                    <TableCardContent
                        resource={details.activeSerial ? (gatewayType === "on-prem" ? apiResources.configurationOnPremHotfixes : apiResources.configurationHotfixes) : undefined}
                        pollInterval={300000}
                        params={{id: details.activeSerial, siteId: details.id}}
                        formatData={(data) => data?.hotfixes || []}
                    >
                        <StatusIconField
                            label="tesseract.appliances.dashboard.firmware.hotfixes.status"
                            source="state"
                            tooltipSource="state"
                            tooltipPrefix="tesseract.appliances.dashboard.firmware.state."
                            iconMap={hotfixUpdateStatusIcons}
                            width={72}
                        />
                        <TextField
                            label="tesseract.appliances.dashboard.firmware.hotfixes.name"
                            source="name"
                        />
                    </TableCardContent>
                </Card>
            ) : null}
            {(type === "sites" || gatewayType === "on-prem") ? (
                <Card
                    id="ConfigurationUpdatesCard"
                    title="tesseract.appliances.dashboard.firmware.updates.tabTitle"
                >
                    <ListCardContent
                        getFrom={{
                            resource: details.activeSerial ? (gatewayType === "on-prem" ? apiResources.configurationOnPremUpdate : apiResources.configurationUpdate) : undefined,
                            options: {pollInterval: 300000},
                            params: {id: details.activeSerial, siteId: details.id}
                        }}
                    >
                        <DateField label="tesseract.appliances.dashboard.firmware.updates.lastUpdate"
                                   source="lastUpdate"/>
                        <DateField label="tesseract.appliances.dashboard.firmware.updates.lastSeen" source="lastSeen"/>
                        <TextField label="tesseract.appliances.dashboard.firmware.updates.remoteIp" source="remoteIp"/>
                    </ListCardContent>
                </Card>
            ) : null}
        </>
    );
};

export default FirmwareCard;
import React, {useRef} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTable,
    ConnectedTableRefresh,
    CrudTypes,
    CustomField,
    DeleteDialog,
    getArrayDataContent,
    getDataContent,
    InputLabel,
    Select,
    StatusIconField,
    TextField,
    useCrudFetch,
    useCrudProps,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {makeStyles} from "@mui/styles";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditUrlFiltering from "./createEdit/CreateEditUrlFiltering";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {settingsIcons} from "../../../../../components/iconMapping";
import apiResources from "../../../../../apiResources";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {sortBy} from "lodash";

export const styles = (theme: Theme) => ({
    cardActions: {
        paddingRight: theme.spacing(2),
        width: "calc(100% - " + theme.spacing(2) + ")"
    },
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    },
    actionsCell: {
        paddingRight: "0 !important"
    }
});
const useStyles = makeStyles(styles);

export interface SettingsTableProps {
    actionChoices: any[],
    categoryChoices: any[]
}

export const SettingsTable = ({actionChoices, categoryChoices}: SettingsTableProps) => {
    const registerAction = useMixpanel("Web Filter", false);
    const settingsTableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const groupChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.urlFilterGroups)[0]?.data), "key");
    const [rulesetsData, , refreshRulesets] = useCrudSubscription(CrudTypes.GET, apiResources.urlFilterRulesets);
    const rulesets = getArrayDataContent(rulesetsData);
    const defaultActionChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterDefaultActions, {}, {}, true)[0]?.data);
    const [defaultActionData, defaultActionLoading, refreshDefaultAction] = useCrudSubscription(CrudTypes.GET, apiResources.urlFilterDefault);
    const [, defaultActionUpdating, updateDefaultAction] = useCrudFetch(CrudTypes.UPDATE, apiResources.urlFilterDefault);
    const defaultAction = getDataContent(defaultActionData);
    const [translate] = useTranslation();
    const classes = useStyles();
    const defaultLoading = typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating;
    const customCategories = getArrayDataContent(useCrudProps(apiResources.customCategories)[0]?.data);

    const handleUpdateDefaultAction = (action: string) => {
        if (action && !defaultLoading && action !== defaultAction) {
            registerAction("Default Update", {action});
            return updateDefaultAction({action}).then(refreshDefaultAction);
        }
    };
    const [, , callUrlFilters] = useCrudFetch(CrudTypes.CREATE, apiResources.urlFilterRules, {});
    const handleFiltersOrderClick = (id: string, direction: string, data: string) => callUrlFilters({
        id,
        changeOrder: direction,
        data
    }).then(settingsTableRefreshRef.current);
    const onRuleChange = () => {
        settingsTableRefreshRef.current && settingsTableRefreshRef.current();
        refreshRulesets();
    };

    return (
        <ConnectedTable
            resource={rulesets.length > 0 ? apiResources.urlFilterRules : undefined}
            exportResource={{resource: apiResources.urlFiltersExport, filename: "urlFilters"}}
            actions={[
                <InputLabel
                    key="defaultAction"
                    label="tesseract.security.urlFiltering.settings.defaultAction"
                    minimised
                    //@ts-ignore this is consumed by parent action bar
                    left
                >
                    <Select
                        id="defaultAction"
                        value={!defaultLoading ? defaultAction : ""}
                        onChange={(eventOrValue) => handleUpdateDefaultAction(eventOrValue?.target?.value)}
                        disabled={defaultLoading}
                        choices={defaultActionChoices}
                    />
                </InputLabel>,
                <CreateEditButtonDialog
                    key="create"
                    create
                    component={CreateEditUrlFiltering}
                    onSuccess={onRuleChange}
                />
            ]}
            params={(params) => ({
                ...params,
                filter: {
                    ...(params.filter || {}),
                    type: params.filter && params.filter.rules && params.filter.rules.type || [],
                    rulesetId: rulesets.map((ruleset) => ruleset.id),
                    rules: undefined,
                    rule: [...((params.filter && params.filter.rules && params.filter.rules.category || [])), (params.filter && params.filter.rules && params.filter.rules.domain)]
                }
            })}
            classes={{cardActions: classes.cardActions}}
            minCellWidth={128}
            refreshRef={settingsTableRefreshRef}
            expandableFields={[
                <ChipArrayField
                    source="domains"
                    key="domains"
                    label="tesseract.security.urlFiltering.settings.domains"
                    skipMissing
                />,
                <ChipArrayField
                    arraySource="categories"
                    source="active"
                    key="categories"
                    sourceValue="name"
                    skipMissing
                />,
                <ChipArrayField
                    source="customCategories"
                    key="customCategories"
                    sourceValue="name"
                    label="tesseract.security.urlFiltering.settings.customCategories"
                    skipMissing
                />
            ]}
            rowId="id"
            formatData={(data) => getArrayDataContent(data).map((rowData) => {
                const activeCategories = rowData.type === "category" ?
                    categoryChoices.filter((category) => rowData.rules.includes(category.key)) :
                    rowData.type === "customCategory" ?
                        customCategories.filter((category) => rowData.rules.includes(category.id.toString())) : [];
                return {
                    ...rowData,
                    activeCategories,
                    categories: groupChoices.map((group) => ({
                        ...group,
                        active: activeCategories.filter((category) => category.group === group.key)
                    })).filter((group) => group.active.length > 0),
                    domains: rowData.type === "domain" ? rowData.rules.sort() : [],
                    customCategories: customCategories.map((cat) => ({
                        ...cat,
                        category: activeCategories.filter((category) => category.id === cat.id)
                    })).filter((group) => group.category.length > 0),
                };
            })}
            flat
        >
            <ActionButtonsField
                left
                label="tesseract.security.urlFiltering.settings.order"
                cellClassName={classes.orderCell}
                width={96}
                source="id"
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: any) => handleFiltersOrderClick(data.id, "increase", data)}
                    disabled={(data: any) => data.index <= 0}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: any) => handleFiltersOrderClick(data.id, "decrease", data)}
                    disabled={(data: any) => data.index >= (data.total - 1)}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.urlFiltering.settings.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.urlFiltering.settings.description"
                filter="text"
            />
            <StatusIconField
                source="action"
                text={(action) => (actionChoices.find((item) => item.key === action) || {}).name}
                label="tesseract.security.urlFiltering.settings.action"
                iconMap={settingsIcons}
                cellClassName={classes.actionCell}
                filter="select"
                filterProps={{
                    choices: actionChoices
                }}
                width={140}
            />
            <CustomField
                source="rules"
                label="tesseract.security.urlFiltering.settings.rule"
                render={(rules, data) => translate(
                    `tesseract.security.urlFiltering.settings.destination${data.type === "category" ?
                        "Categories" : data.type === "customCategory" ? "CustomCategories" :
                            "Domains"}`,
                    {...data, context: rules.length, additional: rules.length - 1}
                )}
                filter="custom"
                filterProps={{
                    component: DestinationFilter,
                    filterSources: ["domain", "category", "customCategory"],
                    table: "WebFilter",
                    categoryChoices,
                    customCategories
                }}
            />
            <ActionButtonsField
                width={96}
                source="id"
                cellClassName={classes.actionsCell}
            >
                <CreateEditButtonDialog component={CreateEditUrlFiltering} onSuccess={onRuleChange}/>
                <DeleteDialog
                    resource={apiResources.urlFilterRules}
                    title="tesseract.security.urlFiltering.settings.delete.title"
                    message="tesseract.security.urlFiltering.settings.delete.body"
                    onSuccess={() => {
                        registerAction("Rule Delete");
                        onRuleChange();
                    }}
                />
            </ActionButtonsField>
        </ConnectedTable>
    );
};

export default SettingsTable;
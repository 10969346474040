import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

const EventFailedIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-event-failed"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M49.7,5.4H10.3c-2.7,0-4.9,2.2-4.9,4.9v44.2l9.8-9.8h34.4c2.7,0,4.9-2.2,4.9-4.9V10.3c0-2.7-2.2-4.9-4.9-4.9ZM49.7,39.8H15.3l-4.9,4.9V10.3h39.3v29.5Z"/>
            <g>
                <rect style={{fill: theme.palette.error?.main, strokeWidth: 0}} x="28.1" y="31.2" width="3.8" height="3.8"/>
                <rect style={{fill: theme.palette.error?.main, strokeWidth: 0}} x="28.1" y="15.8" width="3.8" height="11.5"/>
            </g>
        </SvgIcon>
    );
};

export default EventFailedIcon;
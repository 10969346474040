import React, {ReactElement, useCallback, useState} from "react";
import {BaseFieldProps} from "../../components/fields";
import classNames from "classnames";
import {useDeepCompareMemo} from "../UtilHooks";
import {Collapse, IconButton} from "@barracuda-internal/bds-core";
import {GridColumn} from "@progress/kendo-react-grid";
import {Theme} from "@mui/material";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {ListCardContent} from "../../components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {CellRender, ClonableChildren, RowRender} from "../../utils/commonTypes";
import {get} from "lodash";

const styles = (theme: Theme) => createStyles({
    expandRow: {
        backgroundColor: theme.palette.background.default,
    },
    expandCell: {
        padding: "10px 0 !important"
    },
    detailCellClosed: {
        "&.k-detail-cell": {
            transition: "padding linear 250ms",
            padding: "0 8px",
            borderBottom: 0
        }
    },
    details: {
        padding: "0 !important",
        boxShadow: "none !important",
        backgroundColor: "transparent !important"
    },
    expandIcon: {
        transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms"
    },
    expandedIcon: {
        transform: "rotate(180deg)"
    },
    expandColumnHeader: {
        "& a.k-link": {
            cursor: "default"
        }
    }
});

const useStyles = makeOverrideableStyles("DataTableExpandableRows", styles);

export interface useDataTableExpandableRowsProps extends StyledComponentProps<typeof styles> {
    /**
     * the fields to present in the expanded row data, presented as 2 columns (label | value)
     *
     * The *Field components in cuda-react are all made to seamlessly work here.
     *
     * If expandable fields is not provided, the data and children will be returned as provided, with no expand functionality set.
     */
    expandableFields?: ClonableChildren<BaseFieldProps>,
    /**
     * dot-notation path to a field in the row data that should be used for unique identifying of a row.
     *
     * This helps to keep the right row expanded, as the default is just to use the row index (which then won't follow if the row
     * moves due to filtering or sorting etc.).
     */
    rowId?: string
}

export type useDataTableExpandableRowsReturn = {
    /**
     * the augmented data to pass to the DataTable.
     */
    data: any[],
    /**
     * Cell render method to pass to the DataTable (or to use in a new cell render method alongside other cell renderers).
     *
     * This is not returned if no expandable fields were provided.
     */
    cellRender: CellRender,
    /**
     * Row render method to pass to the DataTable (or to use in a new cell render method alongside other cell renderers).
     *
     * This is not returned if no expandable fields were provided.
     */
    rowRender: RowRender,
    /**
     * Augmented Children to pass to the DataTable
     */
    children: ReactElement[]
};


/**
 * Hook for providing the functionality for showing details about a row in a BDS DataTable (or other tables based on it,
 * such as Table and ConnectedTable) in an expandable row below it.
 */
export const useDataTableExpandableRows = (data: any[], children: ReactElement[], props: useDataTableExpandableRowsProps): useDataTableExpandableRowsReturn => {
    const [expandedItems, setExpandedItems] = useState<(number | string)[]>([]);
    const {expandableFields, rowId = ""} = props;
    const classes = useStyles(props);

    const memoData = useDeepCompareMemo(() => expandableFields ? data.map((item, index) => ({
        ...item,
        isExpanded: expandedItems.includes(get(item, rowId, index))
    })) : data, [!!expandableFields, data, expandedItems]);

    const cellRender = useCallback((cell, cellProps, additionalRender) => {
        if (cellProps.field === "isExpanded" && expandableFields) {
            return React.cloneElement(cell, {
                className: classNames(cellProps.className, classes.expandCell),
                children: (
                    <IconButton
                        size="small"
                        onClick={() => setExpandedItems((entries) => {
                            const id = get(cellProps.dataItem, rowId, cellProps.dataIndex);
                            if (entries.includes(id)) {
                                return entries.filter((entry) => entry !== id);
                            }
                            return [...entries, id];
                        })}
                    >
                        <ExpandMoreIcon
                            className={classNames(cellProps?.dataItem?.isExpanded && classes.expandedIcon, classes.expandIcon)}
                        />
                    </IconButton>
                )
            });
        }
        return additionalRender ? additionalRender(cell, cellProps) : cell;
    }, [!expandableFields]);

    const rowRender = useCallback((row, rowProps) => {
        const rowExpanded = rowProps?.dataItem?.isExpanded;
        return expandableFields ? [
            row,
            <tr
                key={"details-" + get(rowProps?.dataItem, rowId, rowProps?.dataIndex)}
                className={classNames(classes.expandRow, "k-detail-row")}
            >
                <td className={classNames(!rowExpanded && classes.detailCellClosed, "k-detail-cell")}
                    colSpan={children.length + 1}>
                    <Collapse in={rowExpanded} className={classes.details}>
                        <ListCardContent data={rowProps.dataItem}>
                            {expandableFields}
                        </ListCardContent>
                    </Collapse>
                </td>
            </tr>
        ] : row;
    }, [expandableFields]);

    return {
        data: memoData,
        cellRender,
        rowRender,
        children: expandableFields ? [
            ...children,
            <GridColumn
                headerClassName={classes.expandColumnHeader}
                key="expanded"
                field="isExpanded"
                title=" "
                width={64}
                sortable={false}
            />
        ] : children
    };
};
import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const ScanIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-scan"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M51.7,53.9l-11.8-11.8c-1.7,1.3-3.6,2.4-5.7,3.1-2.1.8-4.3,1.1-6.6,1.1-3.8,0-7.2-.9-10.2-2.8-3-1.8-5.4-4.3-7.2-7.3h6.2c1.4,1.6,3.1,2.8,5,3.7,1.9.9,4,1.4,6.3,1.4,4.2,0,7.8-1.5,10.7-4.4,2.9-2.9,4.4-6.5,4.4-10.7s-1.5-7.8-4.4-10.7c-2.9-2.9-6.5-4.4-10.7-4.4s-7.4,1.3-10.2,4c-2.9,2.7-4.5,6-4.8,9.9h-5c.3-5.3,2.4-9.8,6.3-13.4,3.8-3.6,8.5-5.4,13.8-5.4s10.4,2,14.3,5.9c3.9,3.9,5.9,8.7,5.9,14.3s-.4,4.6-1.1,6.6c-.8,2.1-1.8,4-3.1,5.7l11.8,11.8-3.5,3.5ZM24.8,36.3l-4-13.1-3.3,9.3H4.8v-3.8h10.1l4.2-12h3.8l3.8,12.8,2.7-8.4h3.8l3.8,7.6h1.9v3.8h-4.2l-3-5.9-3.1,9.7h-3.7Z"/>
        </SvgIcon>
    );
};

export default ScanIcon;
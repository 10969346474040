import React, {ReactNode} from "react";
import {Switch, useHistory, useLocation} from "react-router";
import NotificationDialog from "../../dialog/NotificationDialog/NotificationDialog";
import Footer from "../Footer/Footer";
import {NavigationBar, NavigationRoute, Options} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {StratosBarracudaIcon} from "@cuda-react/icons";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import UserAvatar from "../../authentication/UserAvatar/UserAvatar";

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    main: {
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        minWidth: "600px"
    },
    body: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        flex: "1 1 auto",
        scroll: "auto",
        height: "100vh"
    },
    content: {
        flex: "1 1 auto",
        overflowX: "auto"
    },
    barracudaIcon: {
        width: 24,
        marginRight: 32
    },
    navSubMenu: {
        "& > :nth-child(2)": {
            height: "100%"
        },
        "& > :nth-child(2) > div > :nth-child(2)": {
            height: "calc(100% - 62px)",
        }
    },
    topBar: {
        zIndex: 5
    }
});
const useStyles = makeOverrideableStyles("AppLayout", styles);

export interface AppLayoutProps extends StyledComponentProps<typeof styles> {
    /**
     * the web app title to display next to the barracuda logo.
     */
    title: string,
    /**
     * additional buttons to show on the navigation bar, alongside the logout button
     */
    additionalOptions?: Options[],
    /**
     * the content to rendered. These are rendered inside a react-router Switch, so should be <Route /> or similar components.
     */
    children?: ReactNode,
    /**
     * additional items to render in the footer.
     */
    footerItems?: ReactNode | ReactNode[],
    /**
     * The logout menu to display when the logout button is clicked.
     */
    logout?: ReactNode,
    /**
     * Navigation routes to pass to the NavigationBar.
     * as the "routes" prop, in order to generate the navigation buttons.
     */
    navigation: NavigationRoute[]
}

const translateRoutes = (routes: any[], translate: TFunction): any => routes.map((route) => ({
    ...route,
    name: translate(route.name),
    children: route.children ? translateRoutes(route.children, translate) : route.children
}));
const translateOptions = (options: any[], translate: TFunction): any => options.map((option) => ({
    ...option,
    name: translate(option.name)
}));

/**
 * Main full page application layout.
 *
 * Includes a [NavigationBar](/?path=/docs/core-components-layout-navigationbar--navigation-bar) for main pages (with icons) from
 * the provided children, the [NotificationDialog](/?path=/docs/core-components-dialogs-notificationdialog--notification-dialog)
 * for displaying banners/dialogs and toast [notifications](/?path=/docs/cudareactapp-notifications--notifications),
 * a content section, and a [Footer](/?path=/docs/core-components-layout-footer--footer).
 *
 * Children are rendered in the content section inside a react-router "Switch", so should be react-router "Route"s, or similar components.
 */
export const AppLayout = (props: AppLayoutProps) => {
    const {children, additionalOptions = [], footerItems, logout, navigation, title} = props;
    const classes = useStyles(props);
    const location = useLocation();
    const [translate] = useTranslation();
    const {push} = useHistory();


    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <ErrorBoundary>
                    <NavigationBar
                        // Prop not present in navigation bar props, but needed for showing the scroll bar
                        // @ts-ignore
                        classes={{topBar: classes.topBar, navigationSubMenu: classes.navSubMenu}}
                        currentPath={location.pathname}
                        title={translate(title)}
                        logoOnClick={() => push("/")}
                        logo={<StratosBarracudaIcon className={classes.barracudaIcon}/>}
                        routes={translateRoutes(navigation, translate)}
                        options={translateOptions([...additionalOptions, {
                            name: "cuda.auth.profile",
                            icon: <UserAvatar size={28}/>,
                            menu: logout
                        }], translate)}
                        onNavigate={(path) => push(path)}
                    />
                </ErrorBoundary>
                <div className={classes.body}>
                    <div className={classes.content}>
                        <ErrorBoundary>
                            <NotificationDialog/>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <Switch>
                                {/* @ts-ignore */}
                                {children}
                            </Switch>
                        </ErrorBoundary>
                    </div>
                    <ErrorBoundary>
                        <Footer footerItems={footerItems}/>
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    );
};

export default AppLayout;
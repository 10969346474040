import React, {useState} from "react";
import {MenuButton} from "@cuda-react/core";
import PropTypes from "prop-types";
import {Dialog} from "@barracuda-internal/bds-core";
import RestartIpsecTunnel from "./RestartIpsecTunnel";

export interface Ipsecv2TableMenuProps {
    data: any,
    onSuccess: () => void
}

const Ipsecv2TableMenu = (props: Ipsecv2TableMenuProps) => {
    const {data, onSuccess} = props;
    const [restartDialogOpen, setRestartDialogOpen] = useState(false);

    return (
        <React.Fragment>
            <MenuButton
                anchorOrigin={{horizontal: "left", vertical: "center"}}
                transformOrigin={{horizontal: "left", vertical: "center"}}
                menuItems={[
                    {
                        label: "tesseract.integration.ipSecV2.table.ellipsisMenu.restart",
                        onClick: () => setRestartDialogOpen(true),
                    }
                ]}
                iconButton
            />
            <Dialog open={restartDialogOpen} maxWidth={false} onClose={() => (setRestartDialogOpen(false))}>
                <RestartIpsecTunnel
                    {...props}
                    resourceName={data.name}
                    resourceId={data.id}
                    onSuccess={onSuccess}
                    onClose={() => (setRestartDialogOpen(false))}
                />
            </Dialog>
        </React.Fragment>
    );
};

Ipsecv2TableMenu.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })
};

export default Ipsecv2TableMenu;
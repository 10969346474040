import en from "./en";
import ja from "./ja";
import fr from "./fr";
import de from "./de";

export default {
    de,
    en,
    ja,
    fr
};
import React from "react";
import Input, {InputProps} from "../Input/Input";
import Hidden from './Hidden';

export interface HiddenInputProps extends Omit<InputProps<typeof Hidden>, "component" | "displayError" | "hideInput"> {}

export const HiddenInput = (props: HiddenInputProps) => (
    <Input {...props} displayError={false} hideInput component={Hidden}/>
);

export default HiddenInput;
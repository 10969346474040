import React from "react";
import {get, set} from "lodash";
import ConnectedAutocompleteSearch from "../../search/ConnectedAutocompleteSearch/ConnectedAutocompleteSearch";
import AutocompleteSearch from "../../search/AutocompleteSearch/AutocompleteSearch";
import {FormControl} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useChoices} from "../../../hooks";
import {useTranslation} from "react-i18next";
import {createStyles} from "@mui/styles";

const styles = createStyles({
    selectArray: {
        width: 256,
        paddingTop: 4
    }
});
const useStyles = makeOverrideableStyles("SelectArrayInput", styles);

interface BaseSearchableSelectProps extends StyledComponentProps<typeof styles> {
    /**
     * if true, the currently selected value is cleared when the input is clicked.
     */
    clearOnFocus?: boolean,
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * key on the list of choices to filter by.
     */
    filterKey?: string,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: (value: string) => void,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (value: string) => void,
    /**
     * callback to called when input get focus.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function
     */
    onFocus?: () => void,
    /**
     * icons to display on each option.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons to display inside of each option. The "optionIcon" value of each choice is used to identify the icon from this map.
     */
    iconMap?: {[key: string]: React.ReactNode},
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: string,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * dot-notation path to the property in each choice object that defines the icon to use from 'iconMaps'.
     */
    optionIcon?: string,
    /**
     * a dot-notation path to the value in the choice objects that should be displayed in the label of each choice.
     *
     * Can also be provided as a function, that is called with the current selected choice.
     *
     * @function
     * @param {object} choice the currently selected choice.
     * @returns {string} the text to display.
     */
    optionText?: string,
    /**
     * a dot-notation path to the value in the choice objects that should be used as the identifying "value" when it is selected.
     */
    optionValue?: string,
    /**
     * placeholder of the input
     */
    placeholder?: string,
    /**
     * if true, new choice will be added with the label provided by the prop selectAllText ("all" in case is not provided) allowing users to select all choices.
     * The value of that choice will be provided by selectAllValue prop.
     */
    selectAll?: boolean,
    /**
     * value for the select all choice.
     */
    selectAllValue?: string,
    /**
     * text for the select all choice.
     */
    selectAllText?: string,
    /**
     * id of the component for unique identification. This value is prefixed with "search-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
}

interface ConnectedSearchableSelectProps extends BaseSearchableSelectProps {
    /**
     * [CRUD](/?path=/docs/cudareactapp-crud--page) resource to fetch choices from. The fetched choices are added to the
     * provided choices (if any).
     */
    resource?: string,
    choices?: undefined,
    /**
     * passed to the ConnectedAutocompleteSearch child component.
     */
    options?: Partial<React.ComponentProps<typeof ConnectedAutocompleteSearch>>,
    /**
     * additional locally defined choices to add to the resource fetched choices
     */
    staticChoices?: any[]
}
interface OfflineSearchableSelectProps extends BaseSearchableSelectProps {
    /**
     * the list of choices to show.
     */
    choices?: any[],
    resource?: undefined,
    /**
     * passed to the AutocompleteSearch child component.
     */
    options?: Partial<React.ComponentProps<typeof AutocompleteSearch>>,
    staticChoices?: undefined
}

export type SearchableSelectProps = ConnectedSearchableSelectProps | OfflineSearchableSelectProps;

/**
 * Renders a searchable by typing dropdown input.
 * If resource prop is provided, choices will be requested via * [CRUD](/?path=/docs/cudareactapp-crud--page)
 * Use the default [Input](/?path=/docs/core-components-inputs-input) format, and work natively with redux form "input" prop.
 */
export const SearchableSelect = (props: SearchableSelectProps) => {
    const {
        clearOnFocus,
        choices = [],
        disabled,
        filterKey,
        icon,
        iconMap,
        error,
        value = [],
        optionIcon,
        onChange,
        onBlur,
        options = {},
        optionText = "name",
        optionValue = "key",
        placeholder = "cuda.inputs.selectArray.typeToSearch",
        resource,
        selectAll,
        selectAllText = "cuda.inputs.selectArray.all",
        selectAllValue = "*",
        id,
        staticChoices = []
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const selectAllChoice = selectAll && set(set({}, optionValue, selectAllValue), optionText, translate(selectAllText));
    const getChoiceId = (choice: any) => (choice && get(choice, optionValue)) || "";

    const [chipsToShow, addChipToShow] = useChoices(
        (value ? [value] : []) as string[],
        {
            choices: choices && [...(selectAllChoice ? [selectAllChoice] : []), ...staticChoices, ...choices],
            filterKey: filterKey || optionValue,
            optionValue,
            onAdd: (choice) => {
                onChange?.(getChoiceId(choice));
                onBlur?.(getChoiceId(choice));
            },
            resource,
            params: {
                pagination: {
                    page: 1,
                    perPage: 20
                }
            }
        }
    );

    const searchProps = {
        onChange: addChipToShow,
        optionText,
        disabled,
        iconMap,
        icon,
        optionIcon,
        noSearchIcon: true,
        placeholder,
        id: "select-input-" + id,
        error,
        ...options
    };

    return (
        <FormControl className={classes.selectArray}>
            {resource ? (
                <ConnectedAutocompleteSearch
                    value={chipsToShow[0]}
                    resource={resource}
                    filterChoices={(choice) => value !== getChoiceId(choice)}
                    staticChoices={selectAllChoice ? [selectAllChoice, ...staticChoices] : staticChoices}
                    totalText="cuda.inputs.selectArray.showing"
                    clearOnFocus={clearOnFocus}
                    {...searchProps}
                />
            ) : (
                <AutocompleteSearch
                    //Set value to empty object to disable displaying the last selected value
                    value={chipsToShow[0]}
                    filterSelectedOptions
                    choices={
                        choices &&
                        [...(selectAllChoice ? [selectAllChoice] : []), ...choices]
                            .filter((choice) => value !== getChoiceId(choice))
                    }
                    clearOnFocus={clearOnFocus}
                    selectOnFocus={!clearOnFocus}
                    {...searchProps}
                />
            )}
        </FormControl>
    );
};

export default SearchableSelect;
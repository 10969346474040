import React, {useRef} from "react";
import {
    ActionButtonsField,
    Boolean,
    ChipArrayField,
    ConnectedTable,
    CrudTypes,
    DeleteDialog,
    getArrayDataContent,
    getDataContent,
    InputLabel,
    PropGateway,
    Select,
    StatusIconField,
    TextField,
    useCrudFetch,
    useCrudProps,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {makeStyles} from "@mui/styles";
import {TextField as BdsTextField} from "@barracuda-internal/bds-core";
import CreateEditButtonDialog from "../../../../../../components/CreateEditButtonDialog";
import CreateEditSslRules from "./CreateEditSslRules";
import SourceFilter from "../../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../../components/sourceAndDestination/DestinationFilter";
import {useSourceDestinationField} from "../../../../../../hooks/sourceAndDestinationHooks";
import {
    formatSourceDestinationFilters,
    formatUserGroupInTableData
} from "../../../../../../components/sourceAndDestination/tableUtils";
import {sslExplicitIcons} from "../../../../../../components/iconMapping";
import apiResources from "../../../../../../apiResources";

export const styles = (theme: { spacing: (arg0: number) => string; }) => ({
    cardActions: {
        paddingRight: theme.spacing(2),
        width: "calc(100% - " + theme.spacing(2) + ")"
    },
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    },
    actions: {
        paddingRight: 0,
        "&> span": {
            position: "relative",
            left: "5%"
        }
    },
    buttons: {
        paddingLeft: theme.spacing(2)
    }
});
const useStyles = makeStyles(styles);

interface SslInterceptionExplicitProps {
    enabled: boolean;
    enabledLoading: boolean;
    refreshEnabled: () => void;
}

export const SslInterceptionExplicit: React.FC<SslInterceptionExplicitProps> = ({
                                                                                    enabled,
                                                                                    enabledLoading,
                                                                                    refreshEnabled
                                                                                }) => {
    const registerAction = useMixpanel("SSL", false);
    const tableRefreshRef = useRef<any>();
    const [, enabledUpdating, updateEnabled] = useCrudFetch(CrudTypes.UPDATE, apiResources.sslInspectionEnabled);
    const loadingEnabled = typeof enabled !== "boolean" || enabledLoading || enabledUpdating;
    const handleUpdateEnabled = (newValue: boolean) => {
        if (!loadingEnabled && newValue !== enabled) {
            registerAction("Rule Default Update", {enabled: newValue});
            return updateEnabled({enabled: newValue ? "enable" : "disable"}).then(refreshEnabled);
        }
    };
    // @ts-ignore
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.sslExplicitActions, {}, {cache: true})[0]?.data);
    // @ts-ignore
    const categoryChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterCategories, {}, {cache: true})[0]?.data);
    const customCategories = getArrayDataContent(useCrudProps(apiResources.customCategories)[0]?.data);
    const [defaultActionData, defaultActionLoading, refreshDefaultAction] = useCrudSubscription(CrudTypes.GET, apiResources.sslExplicitDefault);
    const [, defaultActionUpdating, updateDefaultAction] = useCrudFetch(CrudTypes.UPDATE, apiResources.sslExplicitDefault);
    const defaultAction = getDataContent(defaultActionData);
    const defaultLoading = typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating;
    const handleUpdateDefaultAction = (action: string) => {
        if (action && !defaultLoading && action !== defaultAction) {
            registerAction("Rule Default Update", {action});
            return updateDefaultAction({action}).then(refreshDefaultAction);
        }
    };
    const [, , callSslInterception] = useCrudFetch(CrudTypes.CREATE, apiResources.sslExplicit);
    const handleOrderClick = (id: any, direction: string, data: any) => callSslInterception({
        id,
        changeOrder: direction,
        data
    }).then(tableRefreshRef.current);
    const generateChipArrayProps = useSourceDestinationField(categoryChoices);
    const classes = useStyles();

    return (
        <ConnectedTable
            resource={apiResources.sslExplicit}
            exportResource={{resource: apiResources.sslExplicitExport, filename: "sslExplicit"}}
            actions={[
                <InputLabel key="defaultAction" label="tesseract.security.sslInterception.settings.defaultAction"
                            minimised
                    // @ts-ignore
                            left>
                    <Select
                        key="defaultAction"
                        id="defaultAction"
                        // @ts-ignore
                        source="defaultAction"
                        value={!defaultLoading ? defaultAction : ""}
                        onChange={(eventOrValue) => handleUpdateDefaultAction((eventOrValue.target ? eventOrValue.target.value : eventOrValue) as any)}
                        onBlur={() => {
                        }}
                        disabled={!!defaultLoading}
                        choices={actionChoices}
                    />
                </InputLabel>,
                <InputLabel key="enabled" label="tesseract.security.sslInterception.settings.inputs.enabled" minimised
                    // @ts-ignore
                            left>
                    <Boolean
                        id="enabled"
                        // @ts-ignore
                        onChange={handleUpdateEnabled}
                        options={{disabled: !!loadingEnabled}}
                        value={typeof enabled !== "boolean" ? false : enabled}
                    />

                </InputLabel>,
                <CreateEditButtonDialog key="create" create component={CreateEditSslRules}
                                        onSuccess={tableRefreshRef.current}/>
            ]}
            // @ts-ignore
            filters={[
                <BdsTextField
                    label="tesseract.security.sslInterception.settings.name"
                    key="name"
                    // @ts-ignore
                    source="name"
                />,
                <BdsTextField
                    label="tesseract.security.sslInterception.settings.description"
                    key="description"
                    // @ts-ignore
                    source="description"
                />,
                <Select
                    label="tesseract.security.sslInterception.settings.action"
                    // @ts-ignore
                    source="action"
                    key="action"
                    id="action"
                    choices={actionChoices}
                />
            ]}
            params={formatSourceDestinationFilters}
            classes={{cardActions: classes.cardActions}}
            formatData={formatUserGroupInTableData}
            minCellWidth={128}
            refreshRef={tableRefreshRef}
            flat
        >
            <ActionButtonsField
                left
                label="tesseract.security.sslInterception.settings.order"
                cellClassName={classes.orderCell}
                source="id"
                width={96}
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClick(data.id, "increase", data)}
                    disabled={(data: { index: number; }) => data.index <= 0}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClick(data.id, "decrease", data)}
                    disabled={(data: { index: number; total: number; }) => data.index >= (data.total - 1)}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.sslInterception.settings.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.sslInterception.settings.description"
                filter="text"
            />
            <StatusIconField
                source="action"
                text={(action) => (actionChoices.find((item) => item.key === action) || {}).name}
                label="tesseract.security.sslInterception.settings.action"
                iconMap={sslExplicitIcons}
                cellClassName={classes.actionCell}
                filter="select"
                filterProps={{
                    choices: actionChoices
                }}
            />
            <PropGateway
                source="source"
                label="tesseract.security.sslInterception.settings.source"
                editProps={generateChipArrayProps("source")}
                filter="custom"
                filterProps={{
                    component: SourceFilter,
                    filterSources: ["network", "site", "userOrGroup"]
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <PropGateway
                source="destination"
                label="tesseract.security.sslInterception.settings.destination"
                editProps={generateChipArrayProps("destination")}
                filter="custom"
                filterProps={{
                    component: DestinationFilter,
                    filterSources: ["application", "network", "site", "domain", "category", "customCategory"],
                    table: "SslInspection",
                    categoryChoices,
                    customCategories
                }}
            >
                <ChipArrayField source="destination"/>
            </PropGateway>
            <ActionButtonsField
                source="id"
                width={96}
            >
                <CreateEditButtonDialog component={CreateEditSslRules} onSuccess={tableRefreshRef.current}/>
                <DeleteDialog
                    resource={apiResources.sslExplicit}
                    title="tesseract.security.sslInterception.settings.delete.title"
                    message="tesseract.security.sslInterception.settings.delete.body"
                    onSuccess={() => {
                        registerAction("Rule Delete");
                        tableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </ConnectedTable>
    );
};

export default SslInterceptionExplicit;
// @ts-ignore
import {parse, stringify} from "query-string";

/**
 * Reads a URL query string into a query object.
 *
 * Used by [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table).
 *
 * @function
 * @param {string} queryString the URL query string to parse.
 * @returns {unknown}
 */
export const parseQueryString = (queryString: string): object => {
    const query = parse(queryString);

    if (query.filter && typeof query.filter === "string") {
        query.filter = JSON.parse(query.filter);
    }
    return query;
};

export type SortOrder = "asc" | "desc"
/**
 * Creates a URL query string given filters, sorting and pagination values. Use to form a URL query string, as read by
 * [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table).
 *
 * All values provided are optional.
 *
 * @function
 * @returns {string} the generated URL query string.
 */
export const generateQueryString = (
    /** a map of the current filter keys and values */
    filter: object = {},
    /** the current sort field name */
    sortField?: string,
    /** the current sort direction */
    sortOrder?: SortOrder | Uppercase<SortOrder>,
    /** the current page number */
    pageNumber?: number,
    /** the current number of items per page. */
    resultsPerPage?: number
): string => {
    const reformatQuery = {
        filter: filter && Object.keys(filter).length > 0 && JSON.stringify(filter) || undefined,
        sort: sortField,
        dir: sortOrder,
        pageStart: pageNumber,
        perPage: resultsPerPage
    };
    return `?${stringify(reformatQuery)}`;
};
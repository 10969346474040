import React from "react";

import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@barracuda-internal/bds-core";

import {Close} from "@mui/icons-material";
import {CreatedUserDirectory} from "../UserDirectoriesTypes";
import {useTranslation} from "react-i18next";

export const ErrorDialog = ({directory, open, onClose}: {directory: CreatedUserDirectory, open:boolean, onClose: () => void}) => {
    const [translate] = useTranslation();
    return (
        <Dialog open={open} scroll="paper" fullWidth>
            <DialogTitle display="flex" justifyContent="space-between">
                <Typography>{translate('tesseract.identity.userDirectories.syncErrors')}</Typography>
                <IconButton onClick={onClose}><Close /></IconButton>
            </DialogTitle>
            <DialogContent>
                {directory.syncErrors.map((error, index) => (
                    <Typography key={index} mb={2}>{error}</Typography>
                ))}
            </DialogContent>
        </Dialog>
    );
};
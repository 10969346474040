import React, {useRef, useState} from "react";
import apiResources from "../../../apiResources";
import {
    ActionButtonsField,
    ChipArrayField,
    ConditionalWrapper,
    ConnectedTableRefresh,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    getArrayDataContent,
    SelectedChoiceField,
    TablePage,
    TextField,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import {get} from "lodash";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import AddPointOfEntryContent from "./AddPointOfEntryContent";
import EditPointOfEntryContent from "./EditPointOfEntryContent";
import {GetStartedBackgroundGraphic, PointOfEntryGraphic} from "@cuda-react/icons";
import {Dialog} from "@barracuda-internal/bds-core";

const PointsOfEntry = () => {
    const configurationTypes = useCrudProps(apiResources.pointsOfEntryConfigurationTypes)[0]?.data;
    const registerAction = useMixpanel("Points Of Entry");
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);

    return (
        <>
            <TablePage
                resource={apiResources.pointsOfEntry}
                title="tesseract.endpoint.pageTitle"
                subtitle="tesseract.endpoint.pointsOfEntry.title"
                tableName="EndpointPointsOfEntryTable"
                resetOnGlobalParamChange
                resizable
                reorderable
                editColumns
                refreshRef={tableRefreshRef}
                exportResource={{resource: apiResources.pointsOfEntryExport, filename: "pointsOfEntry"}}
                titleActions={actionVisible &&
                    <DisableableButton
                        buttonText="tesseract.endpoint.pointsOfEntry.createButton"
                        onClick={() => setCreateOpen(true)}
                    />
                }
                onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<PointOfEntryGraphic/>}
                        iconHeader="tesseract.endpoint.pointsOfEntry.noData.iconHeader"
                        textHeader="tesseract.endpoint.pointsOfEntry.noData.textHeader"
                        text="tesseract.endpoint.pointsOfEntry.noData.text"
                        actions={
                            <DisableableButton
                                buttonText="tesseract.endpoint.pointsOfEntry.noData.labelButton"
                                onClick={() => setCreateOpen(true)}
                            />
                        }
                    />
                }
            >
                <TextField
                    source="configuration.name"
                    label="tesseract.endpoint.pointsOfEntry.table.name"
                    filter="text"
                    filterProps={{
                        source: "name"
                    }}
                    sortable
                />
                <SelectedChoiceField
                    source="configurationType"
                    label="tesseract.endpoint.pointsOfEntry.table.type"
                    filter="select"
                    filterProps={{
                        choices: getArrayDataContent(configurationTypes),
                        source: "type"
                    }}
                    choices={getArrayDataContent(configurationTypes)}
                    sortable
                />
                <ChipArrayField
                    source="ipAddresses"
                    label="tesseract.endpoint.pointsOfEntry.table.publicIp"
                />
                <TextField source="configuration.sizeName" label="tesseract.endpoint.pointsOfEntry.table.size"/>
                <TextField source="configuration.locationName" label="tesseract.endpoint.pointsOfEntry.table.location"/>
                <ActionButtonsField
                    source="id"
                    width={96}
                    alwaysVisible
                >
                    <ConditionalWrapper
                        wrapper={() => null}
                        condition={(props) => get(props, "data.configurationType") !== "cgf"}
                    >
                        <CreateEditButtonDialog
                            component={EditPointOfEntryContent}
                            onSuccess={() => {
                                registerAction("Update");
                                tableRefreshRef.current?.();
                            }}
                        />
                    </ConditionalWrapper>
                    <DeleteDialog
                        resource={apiResources.pointsOfEntry}
                        title="tesseract.endpoint.pointsOfEntry.delete.title"
                        message="tesseract.endpoint.pointsOfEntry.delete.body"
                        onSuccess={() => {
                            registerAction("Delete");
                            tableRefreshRef.current?.();
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                disableEnforceFocus
                onClose={() => setCreateOpen(false)}
            >
                <AddPointOfEntryContent
                    onClose={() => setCreateOpen(false)}
                    onSuccess={() => {
                        registerAction("Create");
                        tableRefreshRef.current?.();
                    }}
                />
            </Dialog>
        </>
    );
};

export default PointsOfEntry;
const apiBase = "/api/v1/webui/{userData.currentAccount}";
export default {
    agentVersions: apiBase + "/zerotrust/dashboard/devices/versions",
    userStatus: apiBase + "/zerotrust/users/{id}",
    userDevices: apiBase + "/zerotrust/devices",
    zeroTrustUsers: apiBase + "/zerotrust/dashboard/users",
    ZTNAResources: apiBase + "/zerotrust/dashboard/resources",
    zeroTrustPolicies: apiBase + "/zerotrust/policies",
    zeroTrustPoliciesSecurityPosture: apiBase + "/zerotrust/policies/securityPosture",
    users: apiBase + "/directory/users",
    groups: apiBase + "/directory/groups",
    applications: apiBase + "/applications",
    agentOperatingSystems: apiBase + "/zerotrust/dashboard/devices/os",
    ZTNAUserCountries: apiBase + "/zerotrust/dashboard/users/countries",
};
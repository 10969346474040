import React from "react";
import {Route, Switch, withRouter} from "react-router";
import SiteDetails from "./SiteDetails";
import IotTable from "./IotTable";

interface IoTProps {
    match: any
}

export const IoT: React.FC<IoTProps> = ({match}) => (
    <Switch>
        <Route
            exact
            path={match.path}
            component={IotTable}
        />
        <Route
            path={`${match.path}/:id`}
            component={SiteDetails}
        />
    </Switch>
);


export default withRouter(IoT) as React.ComponentClass;
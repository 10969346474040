import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

const LiveLogOneWayInactiveIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-access-live-log-one-way-inactive"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M37.4,47.4l-3.5-3.5,11.4-11.4H5.2v-5h40.1l-11.4-11.4,3.5-3.5,17.4,17.4-17.4,17.4Z"/>
        </SvgIcon>
    );
};


export default LiveLogOneWayInactiveIcon;
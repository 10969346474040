import React from "react";
import ZeroTrustAccess from "./remoteAccessPolicies/ZeroTrustAccess";
import {Route, Switch} from "react-router";

const Endpoint = () => (
    <Switch>
        <Route path="/policies/ztna/zerotrustaccess">
            <ZeroTrustAccess/>
        </Route>
    </Switch>
);

export default Endpoint;
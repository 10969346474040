import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {Button, IconButton, ListItemIcon, ListItemText, Tooltip} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {ConditionalWrapper} from "../../functional";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {ExpandMore, Settings} from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const styles = (theme: Theme) => createStyles({
    button: {},
    menuEntry: {
    },
    labelWithIcon: {
        paddingLeft: theme.spacing(1)
    },
    tooltip: {
        ...theme.typography.body2
    }
});

const useStyles = makeOverrideableStyles("MenuButton", styles);

export interface MenuButtonProps {
    /** the anchor origin definition, as per Material UI Popover component. */
    anchorOrigin: { horizontal: "center" | "left" | "right", vertical: "center" | "top" | "bottom" },
    /** disables the main menu button. */
    disabled?: boolean,
    /** icon to display in the main button */
    icon?: React.ReactNode,
    /** whether the button should be an icon button */
    iconButton?: boolean,
    /** text to display in the main button */
    label?: string,
    /** array of menu item definitions. Each menu item consists of label, onClick callback and optional icon */
    menuItems?: {
        disabled?: boolean
        disabledTooltipText?: string,
        icon?: React.ReactNode,
        label: string,
        onClick?: () => void,
        selected?: boolean,
        subLabel?: string
    }[],
    /** the transform origin definition, as per Material UI Popover component. */
    transformOrigin: { horizontal: "center" | "left" | "right", vertical: "center" | "top" | "bottom" },
    /** Whether the menu is to be displayed on top right the dashboard.
     * We should remove this prop when custom icons for menu button need to be used at other places.
     * The buttonIcon prop should be replaced with "buttonIcons" which will accept an array of icons to be displayed.*/
    dashboardMenu?: boolean
}

/**
 * Standard button that opens a dropdown menu.
 */
export const MenuButton = (props: MenuButtonProps) => {
    const {disabled, label, menuItems, icon, anchorOrigin, transformOrigin, iconButton, dashboardMenu, ...buttonProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    return (
        <React.Fragment>
            {iconButton ?
                <IconButton
                    size="small"
                    disabled={disabled}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    {...buttonProps}
                >
                    {!dashboardMenu ? <MoreVertIcon id="cuda-icon-menu"/> :
                        <React.Fragment>
                            <ExpandMore/>
                            <Settings/>
                        </React.Fragment>}
                </IconButton> :
                <Button
                    className={classes.button}
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    size="small"
                    // Current target needs to be set to any because the element is stored in a useState and the native react MouseEvent can not be used
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    startIcon={icon}
                    {...buttonProps}
                >
                    {label ? translate(label) : null}
                </Button>
            }
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {menuItems && menuItems.map((menuItem, index) => (
                    <div key={menuItem.label + index}>
                        <ConditionalWrapper<React.ReactElement, any>
                            key={menuItem.label + index}
                            condition={menuItem.disabledTooltipText && menuItem.disabled}
                            wrapper={(children: React.ReactElement) => (
                                <Tooltip
                                    title={translate(menuItem.disabledTooltipText as string) as string}
                                    placement="left"
                                    classes={{tooltip: classes.tooltip}}
                                >
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <MenuItem
                                onClick={() => {
                                    if (menuItem.disabled) {
                                        return;
                                    }
                                    setAnchorEl(null);
                                    menuItem.onClick && menuItem.onClick();
                                }}
                                className={classes.menuEntry}
                                selected={menuItem.selected}
                                disabled={menuItem.disabled}
                            >
                                {menuItem.icon
                                    ? <ListItemIcon>{menuItem.icon}</ListItemIcon>
                                    : null
                                }
                                <ListItemText
                                    className={menuItem.icon && classes.labelWithIcon || undefined}
                                    primary={translate(menuItem.label)}
                                    secondary={translate(menuItem.subLabel || "")}
                                />
                            </MenuItem>
                        </ConditionalWrapper>
                    </div>
                )) || null}
            </Menu>
        </React.Fragment>
    );
};

MenuButton.propTypes = {};

MenuButton.defaultProps = {
    anchorOrigin: {
        horizontal: "left",
        vertical: "bottom"
    },
    transformOrigin: {
        horizontal: "left",
        vertical: "top"
    }
};

export default MenuButton;
import {FormControlLabel, IconButton, Radio, RadioGroup, RadioGroupProps, Tooltip} from "@barracuda-internal/bds-core";
import {Info} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import {get, isEmpty} from "lodash";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";

const styles = (theme: Theme) => createStyles({
    root: {},
    radioGroup: {
        float: "left",
        marginLeft: theme.spacing(1.5),
        maxWidth: "100%"
    },
    inputHelp: {
        display: "inline-block",
        float: "left",
        height: "auto",
        margin: "5px 12px 5px 0",
        width: 20
    },
    infoIcon: {
        fill: theme.palette.text.secondary
    },
    tooltip: {
        ...theme.typography.body2,
        // @ts-ignore Added by Cuda-react. Default (undefined), is fine
        color: theme.palette.text.tooltip,
    }
});
const useStyles = makeOverrideableStyles("RadioGroup", styles);

export interface BasicRadioButtonGroupProps extends StyledComponentProps<typeof styles> {
    /**
     * if true, no option selected can be left.
     */
    allowEmpty?: boolean,
    /**
     * array of choices to select from
     */
    choices?: any[],
    /**
     * the choice to select instead of the first available choice when input.value is empty.
     */
    defaultChoice?: string,
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * id of the component for unique identification. This value is prefixed with "radio-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: () => void,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (value: string | number) => void,
    /**
     * passed to the Radio children component as props.
     */
    options?: Partial<RadioGroupProps>,
    /**
     * a dot-notation path to the value in the choice objects that should be displayed in the label of each radio button.
     *
     * Can also be provided as a function, that is called with the currently selected choice.
     *
     * @function
     * @param {object} choice the currently selected choice.
     * @returns {string} the text to display.
     */
    optionText?: string | React.ReactNode | ((choice: any) => string | React.ReactNode),
    /**
     * a dot-notation path to the value in the choice objects that should be used as the identifying "value" when it is selected.
     */
    optionValue?: string,
    /**
     * a dot-notation path to the description in the choice objects.
     * A tooltip component will be render for each radio button.
     */
    optionHelp?: string,
    /**
     * [CRUD](/?path=/docs/cudareactapp-crud--page) resource to fetch choices from. The fetched choices are added to the
     * provided choices (if any).
     */
    resource?: string,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: string | number
}

/**
 * Renders a Radio button input with a list of choices.
 * Based on the BDS "Radio/RadioGroup" component, but modified to use the default [Input](/?path=/docs/core-components-inputs-input) format, and work natively with redux form "input" prop.
 * Use the default [Input](/?path=/docs/core-components-inputs-input) format, and work natively with redux form "input" prop.
 */
export const BasicRadioButtonGroup = (props: BasicRadioButtonGroupProps) => {
    const {
        value = "",
        onChange,
        onBlur,
        choices = [],
        id,
        allowEmpty,
        optionText = "name",
        defaultChoice,
        options = {},
        optionHelp,
        optionValue = "key",
        disabled
    } = props;

    useEffect(() => {
        if (!isEmpty(choices) && !allowEmpty && !choices.find((choice) => get(choice, optionValue) === value)) {
            onChange?.(defaultChoice || get(choices[0], optionValue));
            onBlur?.();
        }
    }, [choices, value, onChange]);

    const renderRadioButton = (choice: any) => {
        let choiceName: string | React.ReactNode = "";
        const [translate] = useTranslation();
        if (React.isValidElement(optionText)) {
            choiceName = React.cloneElement<any>(optionText, {data: choice});
        } else if (typeof optionText === "function") {
            choiceName = optionText(choice);
        } else if (typeof optionText === "string") {
            choiceName = get(choice, optionText);
        }

        return (
            <FormControlLabel
                control={<Radio color="primary"/>}
                key={get(choice, optionValue)}
                label={typeof choiceName === "string" ? translate(choiceName) : choiceName}
                value={get(choice, optionValue)}
                disabled={disabled}
            />
        );
    };

    const getCurrentChoice = () => {
        if (value) {
            for (let index = 0; index < choices.length; index++) {
                if (get(choices[index], optionValue) === value) {
                    return choices[index];
                }
            }
        }
        return {};
    };
    const classes = useStyles(props);

    return (
        <React.Fragment>
            <RadioGroup
                name={id}
                value={value}
                {...options}
                className={classes.radioGroup}
                onChange={(event, index) => {
                    onChange && onChange(index);
                    onBlur && onBlur();
                }}
                id={id && "radio-input-" + id}
            >
                {choices.map(renderRadioButton)}
            </RadioGroup>
            <div className={classes.inputHelp}>
                {optionHelp && getCurrentChoice()[optionHelp] && (
                    <Tooltip
                        placement="top-end"
                        title={getCurrentChoice()[optionHelp]}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <IconButton size="small">
                            <Info className={classes.infoIcon}/>
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </React.Fragment>
    );
};

export default BasicRadioButtonGroup;
import get from "lodash/get";
import React from "react";
import {BaseFieldProps} from "../index";
import {formatDate, formatDateTime} from "../../../utils";

export interface DateFieldProps extends BaseFieldProps {
    /**
     * if provided, will cut off time information.
     */
    dateOnly?: boolean,
    /**
     * the format it should use to render.
     */
    format?: string
}

export const DateField = (props: DateFieldProps) => {
    const {source = "", data =  {}, dateOnly, format} = props;

    return (
        <span>
            {dateOnly ? formatDate(get(data, source)) : formatDateTime(get(data, source), format)}
        </span>
    );
};

export default DateField;
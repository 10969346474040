import React from "react";
import {getArrayDataContent, SelectInput, TextInput, useCrudProps} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../../apiResources";

interface AdvancedStepProps {
    applianceType?: "site" | "gateway";
    series?: string;
}

const AdvancedStep: React.FC<AdvancedStepProps> = ({applianceType, series}) => {
    const inputValidations = useSiteInputValidations();
    const timezonesData = getArrayDataContent(useCrudProps(apiResources.siteTimezones)[0]?.data);
    const [translate] = useTranslation();
    const isSc = series === "S";
    return (
        <React.Fragment>
            <SelectInput
                choices={timezonesData}
                defaultChoice={isSc ? "UTC" : undefined}
                source="timezone"
                label="tesseract.appliances.dialog.advanced.updateWindowTZ"
                description={translate("tesseract.appliances.dialog.advanced.descriptions.updateWindowTZ", {context: applianceType})}
                hideInput={isSc}
                isRequired
            />
            <TextInput
                source="primaryDns"
                label="tesseract.appliances.dialog.advanced.primaryDns"
                description="tesseract.appliances.dialog.advanced.descriptions.primaryDns"
                validate={inputValidations.validateIp}
            />
            <TextInput
                source="secondaryDns"
                label="tesseract.appliances.dialog.advanced.secondaryDns"
                description="tesseract.appliances.dialog.advanced.descriptions.secondaryDns"
                validate={inputValidations.validateIp}
                disable={(value, data) => !(data && (data.primaryDns || value))}
            />
        </React.Fragment>
    );
};
export default AdvancedStep;
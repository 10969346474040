import React from "react";
import Input, {InputProps} from "../Input/Input";
import IconSelector from './IconSelector';

export interface IconSelectorInputProps extends Omit<InputProps<typeof IconSelector>, "component" | "displayError"> {
}

/**
 * [Input](/?path=/docs/core-components-inputs-input) Wrapper component format, to work natively with redux form "Field" prop.
 * form ready version of the File component.
 */
export const IconSelectorInput = (props: IconSelectorInputProps) => (
    <Input {...props} component={IconSelector}/>
);
export default IconSelectorInput;
import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const AllowIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-allow"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.success.main, strokeWidth: 0}}
                  d="M30,5.5c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5,24.5-11,24.5-24.5S43.5,5.5,30,5.5ZM25.1,42.3l-9.8-9.8,3.5-3.5,6.4,6.3,16.2-16.2,3.5,3.5-19.6,19.6Z"/>
        </SvgIcon>
    );
};

export default AllowIcon;
import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {useTheme} from "@mui/material";

export const BlockIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-block"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.risk.high, strokeWidth: 0}}
                  d="M30,5.5c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5,24.5-11,24.5-24.5S43.5,5.5,30,5.5ZM42.2,32.4h-24.5v-4.9h24.5v4.9Z"/>
        </SvgIcon>
    );
};

export default BlockIcon;
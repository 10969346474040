import {useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    interfacesLabels: {
        marginTop: 8,
        marginBottom: -4,
        paddingLeft: 275,
        width: "calc(100% - 320px)",
        display: "inline-flex",
        "&> div": {
            minWidth: 132,
            paddingLeft: 12
        }
    }
});

const ExpressRouteHeader = () => {
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <Typography
            component="div"
            className={classes.interfacesLabels}
        >
            <div>
                {translate("tesseract.appliances.settings.wan.primary")}
            </div>
            <div>
                {translate("tesseract.appliances.settings.wan.secondary")}
            </div>
        </Typography>
    );
};

export default ExpressRouteHeader;
import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {useTheme} from "@mui/material";

export const FirewallIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-firewall"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M53.4,15.4s-10.1,4.6-11.8,14.3c-.9,5.1,0,9.1.3,9.8,0,0,0,.1,0,.1,0,0,0,0,0-.1-.1-.3-.5-1.2-2.2-3.3-2.1-2.6-8.3-1.3-8.3-1.3,0,0,4.3,1.8,5.3,4.9.8,2.4-2.3,1.8-2.7,6.8-.3,4.1,3.9,5.7,7.6,5.9,7.2.4,10-2.1,11.3-4.4,1.9-3.3-2.2-9.7-3.5-15.8-1.3-6.1,4-17,4-17ZM46.1,42.9c.4,1.9,1.7,3.9,1.1,4.9-.4.7-1.3,1.5-3.5,1.4-1.1,0-2.4-.6-2.3-1.8.1-1.6,1.1-1.4.8-2.1-.3-1-1.7-1.5-1.7-1.5,0,0,1.9-.4,2.6.4.5.7.6.9.7,1,0-.2-.4-1.5,0-3,.5-3,3.6-4.4,3.6-4.4,0,0-1.6,3.4-1.2,5.3Z"/>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M31,46.5c0-.7.1-1.3.3-1.8h-1.3s0-7.1,0-7.1l-8.4-3.6,6.7-1.4h-2.6v-8h14.5c.9-1.9,2-3.6,3.2-5h-11.4v-7.2h13.8v4.7c1.9-1.7,3.8-2.9,5-3.6v-3.6c0-1.4-1.1-2.5-2.5-2.5H9.1c-1.4,0-2.5,1.1-2.5,2.5v37.4c0,1.4,1.1,2.5,2.5,2.5h22.3c-.4-1-.5-2.1-.4-3.2ZM11.6,12.3h15.3v7.2h-15.3v-7.2ZM11.6,24.5h9v8h-9v-8ZM25,44.7h-13.3s0-7.2,0-7.2h13.3v7.2Z"/>
        </SvgIcon>
    );
};

export default FirewallIcon;
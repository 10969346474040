import React from "react";
import {getArrayDataContent, PasswordConfirmInput, SelectInput, TextInput, useCrudProps} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../../apiResources";
import {SELECT_EMPTY_VALUE} from "../../../../../utils/constants";

interface BasicStepProps {
    applianceType?: "site" | "gateway";
    enforceGateway?: boolean;
}

const BasicStep: React.FC<BasicStepProps> = ({applianceType, enforceGateway}) => {
    const gatewayResource = applianceType === "gateway" ? apiResources.gatewaysOnPremUpstream : apiResources.gatewayNames;
    const gatewaysData = getArrayDataContent(useCrudProps(gatewayResource)[0]?.data);
    const gatewayChoices = enforceGateway ? gatewaysData : [
        {
            key: SELECT_EMPTY_VALUE,
            name: "tesseract.appliances.dialog.basic.noGateway"
        },
        ...gatewaysData
    ];
    const inputValidations = useSiteInputValidations();
    const [translate] = useTranslation();

    return (
        <React.Fragment>
            <TextInput
                source="name"
                label="tesseract.appliances.dialog.basic.name"
                description={translate("tesseract.appliances.dialog.basic.descriptions.name", {context: applianceType})}
                isRequired
                validate={inputValidations.validateSiteName}
            />
            <SelectInput
                source={applianceType === "gateway" ? "upstream" : "gateway"}
                label={applianceType === "gateway" ? "tesseract.appliances.dialog.basic.upstream_gateway" : "tesseract.appliances.dialog.basic.gateway"}
                description={translate(applianceType === "gateway" ? "tesseract.appliances.dialog.basic.descriptions.upstream" : "tesseract.appliances.dialog.basic.descriptions.gateway", {context: applianceType})}
                choices={gatewayChoices}
            />
            <PasswordConfirmInput
                source="password"
                label="tesseract.appliances.dialog.basic.rootPassword"
                description={translate("tesseract.appliances.dialog.basic.descriptions.rootPassword", {context: applianceType})}
                confirmLabel="tesseract.appliances.dialog.basic.rootPasswordConfirmation"
                isRequired
                viewable
                validate={inputValidations.validatePassword}
            />
        </React.Fragment>
    );
};

export default BasicStep;
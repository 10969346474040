import React from "react";
import {FormSection, SelectedChoiceField, SelectInput, TableInput, TextField, TextInput,} from "@cuda-react/core";
import {get, merge, set} from "lodash";
import {getNetworkId, useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";

export interface Reservation {
    networkId?: string;
}

export interface DhcpReservationsTableInputProps {
    activeLans?: any[];
    reservableLans: any[];
    reservationFormValidation?: any;
    disabled?: boolean;
}

export const getReservationsFormProps = ({
                                             reservableLans,
                                             reservationFormValidation,
                                             inputValidations,
                                         }: {
    reservableLans?: any[];
    reservationFormValidation?: any;
    inputValidations?: any;
}) => ({
    formValidate: reservationFormValidation,
    formatInput: (lans: any[]) =>
        lans && lans.flatMap(
            (lan) => (get(lan, "dhcp.reservations") || []).map((reservation: Reservation) => ({
                ...reservation,
                networkId: getNetworkId(lan),
            }))
        ) || [],
    formatOutput: (reservations: any, lans: any) => lans.map(
        (lan: any) => set(
            merge({}, lan),
            "dhcp.reservations",
            reservations
                .filter((reservation: any) => reservation.networkId === getNetworkId(lan))
                .map((reservation: any) => ({
                    ...reservation,
                    mac: reservation.mac && reservation.mac.replace(/-/g, ":")
                }))
        )
    ),
    formatError: (error: any, lans: any) => Array.isArray(error) ? lans.flatMap(
        (lan: any, index: any) => {
            const lanError = get(error, `[${index}]dhcp.reservations`) || [];
            const reservations = (get(lan, "dhcp.reservations") || []).map(() => undefined);

            if (Array.isArray(lanError)) {
                return [...lanError, ...reservations.slice(lanError.length)];
            }
            return reservations.map(() => lanError);
        }
    ) : error,
    formChildren: [
        <TextInput
            key="name"
            source="name"
            label="tesseract.appliances.settings.dhcp.reservations.name"
            description="tesseract.appliances.settings.dhcp.descriptions.reservations.name"
            isRequired
        />,
        <TextInput
            key="description"
            source="description"
            label="tesseract.appliances.settings.dhcp.reservations.description"
            description="tesseract.appliances.settings.dhcp.descriptions.reservations.description"
        />,
        <SelectInput
            key="networkId"
            source="networkId"
            label="tesseract.appliances.settings.dhcp.reservations.port"
            choices={reservableLans}
            optionValue="networkId"
            description="tesseract.appliances.settings.dhcp.descriptions.reservations.port"
            isRequired
        />,
        <FormSection
            title="tesseract.appliances.settings.dhcp.reservations.reservationSectionTitle"
            key="reservations"
        >
            <TextInput
                source="ipAddress"
                label="tesseract.appliances.settings.dhcp.reservations.ip"
                description="tesseract.appliances.settings.dhcp.descriptions.reservations.ip"
                validate={inputValidations.validateIp}
                isRequired
            />
            <TextInput
                source="mac"
                label="tesseract.appliances.settings.dhcp.reservations.mac"
                description="tesseract.appliances.settings.dhcp.descriptions.reservations.mac"
                validate={inputValidations.validateMac}
                isRequired
            />
            <TextInput
                source="clientId"
                label="tesseract.appliances.settings.dhcp.reservations.clientId"
                description="tesseract.appliances.settings.dhcp.descriptions.reservations.clientId"
            />
            <TextInput
                source="hostname"
                label="tesseract.appliances.settings.dhcp.reservations.hostname"
                description="tesseract.appliances.settings.dhcp.descriptions.reservations.hostname"
                validate={inputValidations.validateHostname}
            />
        </FormSection>
    ]
    // Rest of the object properties remain unchanged
    // ...
});
const DhcpReservationsTableInput: React.FC<DhcpReservationsTableInputProps> = ({
                                                                                   activeLans,
                                                                                   reservableLans,
                                                                                   reservationFormValidation,
                                                                                   ...tableInputProps
                                                                               }: DhcpReservationsTableInputProps) => {
    const inputValidations = useSiteInputValidations();
    return (
        <TableInput
            source="lans"
            // @ts-ignore, appears to be error in cuda-react typing
            id="dhcp-reservations"
            optionValue="name"
            labels={{
                addTitle: "tesseract.appliances.settings.dhcp.addReservationTitle",
                addButtonText: "tesseract.appliances.settings.dhcp.addReservationButtonText",
                editTitle: "tesseract.appliances.settings.dhcp.editReservationTitle",
                deleteTitle: "tesseract.appliances.settings.dhcp.deleteReservationTitle",
                deleteMessage: "tesseract.appliances.settings.dhcp.deleteReservationMessage"
            }}
            {...getReservationsFormProps({reservableLans, reservationFormValidation, inputValidations})}
            {...tableInputProps}
            addButtonProps={{
                disabled: reservableLans.length < 1
            }}
            editButtonProps={{
                disabled: reservableLans.length < 1
            }}
            minCellWidth={164}
        >
            <TextField
                source="name"
                label="tesseract.appliances.settings.dhcp.reservations.name"
            />
            <TextField
                source="description"
                label="tesseract.appliances.settings.dhcp.reservations.description"
            />
            <SelectedChoiceField
                source="networkId"
                label="tesseract.appliances.settings.dhcp.reservations.port"
                choices={activeLans}
                optionValue="networkId"
            />
            <TextField
                source="ipAddress"
                label="tesseract.appliances.settings.dhcp.reservations.ip"
            />
            <TextField
                source="mac"
                label="tesseract.appliances.settings.dhcp.reservations.mac"
            />
            <TextField
                source="hostname"
                label="tesseract.appliances.settings.dhcp.reservations.hostname"
            />
        </TableInput>
    );
};

export default DhcpReservationsTableInput;
import {BooleanInput, ConnectedForm, PasswordConfirmInput, useGlobalParam, useMixpanel} from "@cuda-react/core";
import React from "react";
import apiResources from "../../../apiResources";
import {useSiteInputValidations} from "../../../hooks/createEditSiteHooks";

interface EditCloudGatewayContentProps {
    id: number | string;
    details: {
        gatewayType?: "cloud" | "on-prem" | "managed";
        model?: string;
    };
}

const EditCloudGatewayContent: React.FC<EditCloudGatewayContentProps> = ({id, details: {model, gatewayType}}) => {
    const registerAction = useMixpanel(
        "Gateways Settings",
        !!model,
        {model: model || "", type: gatewayType || ""}
    );
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const inputValidations = useSiteInputValidations();

    return (
        <ConnectedForm
            resource={apiResources.cloudGateways}
            id={id}
            formatRequestData={(data) => ({
                ...data,
                id,
                virtualWanId
            })}
            onSubmitSuccess={(response) => {
                registerAction("Update", {
                    model: response.model,
                    type: "cloud"
                });
            }}
            topToolbar
            clearOnSuccess
        >
            <PasswordConfirmInput
                source="password"
                label="tesseract.appliances.settings.advanced.rootPassword"
                description="tesseract.appliances.settings.advanced.descriptions.rootPassword_gateway"
                confirmLabel="tesseract.appliances.settings.advanced.rootPasswordConfirmation"
                validate={inputValidations.validatePassword}
            />
            <BooleanInput
                source="securePeeredNetworks"
                label="tesseract.gateways.settings.labels.secureInternetTraffic"
                description="tesseract.gateways.settings.descriptions.secureInternetTraffic"
            />
            <BooleanInput
                source="disableSyslogStreaming"
                label="tesseract.syslog.syslogOverride"
                description="tesseract.syslog.descriptions.syslogOverride_gateway"
            />
        </ConnectedForm>
    );
};

export default EditCloudGatewayContent;
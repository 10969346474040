import {get, merge} from "lodash";
// @ts-ignore
import {parse} from "query-string";
import {useHistory} from "react-router";
import React, {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState} from "react";
import {CrudParams, CrudTypes} from "../clients";
import {
    convertFromSimpleFilters,
    convertToSimpleFilters,
    generateQueryString,
    getArrayDataContent,
    getDataContent,
    SortOrder
} from "../utils";
import {useDeepCompareEffect, useDeepCompareMemo, useDeepCompareMemoize, usePrevious} from "./UtilHooks";
import {useGlobalParam} from "./GlobalParamsHooks";
import {useCrudSubscription} from "./CrudHooks";
import {useInfiniteScroll, useInfiniteScrollReturn} from "./TableScrollHooks";
import TableFooterRow from "../components/table/TableFooterRow/TableFooterRow";
import {
    GridFilterChangeEvent,
    GridPageChangeEvent,
    GridPagerSettings,
    GridSortChangeEvent,
    GridSortSettings
} from "@progress/kendo-react-grid";
import {FilterDescriptor, SortDescriptor} from "@progress/kendo-data-query";
import {Skeleton} from "@barracuda-internal/bds-core";
import {createStyles, makeStyles} from "@mui/styles";
import {RowRender} from "../utils/commonTypes";

export interface useConnectedTableProps {
    /**
     * array of page sizes to expose to the user
     */
    pageSizes?: number[],
    /**
     * if true, saving and reading the table state to/from the page URI is disabled.
     */
    noRouter?: boolean,
    /**
     * default filter state for the table (in the simple format used by CRUD).
     */
    defaultFilter?: object,
    /**
     * default sort state for the table
     */
    defaultSort?: {
        /**
         * direction to sort in
         */
        dir?: SortOrder,
        /**
         * field to apply the sort to
         */
        field?: string
    },
    /**
     * default page size for the table
     */
    defaultItemsPerPage?: number,
    /**
     * if true, the sort/filter/pagination state will be reset if the global param sort/filters/pagination is changed.
     */
    resetOnGlobalParamChange?: boolean,
    /**
     * the method of handling data when the table has lots of rows
     */
    pageMode?:
    /** regular pagination */
        "paginate"
        /** show up to "pageSizeAll" items in one long table, there is no pagination */
        | "all"
        /** automatically increase page size as the table is scrolled down */
        | "dynamic",
    /**
     * number of items to request when pageMode is set to "all"
     */
    pageSizeAll?: number,
    /**
     * static CRUD params to be merged with the calculated params OR a function to edit the calculated params prior to passing to the CRUD request.
     */
    params?: CrudParams | ((currentParams: CrudParams) => CrudParams),
    /**
     * the CRUD resource name to use in the CRUD requests
     */
    resource?: string,
    /**
     * function to format the returned server data, prior to passing to DataTable props.
     */
    formatData?: (crudValues: any) => object[]
    /**
     * interval (in ms) between each repeated requests in the CRUD subscription.
     */
    pollInterval?: number
}

interface PageState {
    skip: number,
    take: number
}

interface SortItem {
    dir?: SortOrder,
    field: string,
}

type SortState = SortItem[]

type FilterState = { filters?: { filters?: Record<string, any> }[] }

export type ConnectedTableRefresh = () => void;

/**
 * Object returned by useConnectedTable hook. Includes the props to provide to DataTable, as well as additional objects/methods for directly
 * monitoring and managing the requests.
 */
interface useConnectedTableReturn {
    /**
     * props to pass to DataTable
     */
    dataTableProps: {
        onPageChange: (event: GridPageChangeEvent) => void,
        pageable: GridPagerSettings | false,
        total: number,
        sortable: GridSortSettings;
        sort: SortDescriptor[];
        onSortChange: (event: GridSortChangeEvent) => void;
        filter: FilterDescriptor[];
        onFilterChange: (event: GridFilterChangeEvent) => void;
        data: object[],
        rowRender: RowRender
    } & useInfiniteScrollReturn & PageState,
    /**
     * the current page state.
     */
    pageState: PageState,
    /**
     * callback to update the page state to a new state.
     */
    setPageState: Dispatch<SetStateAction<PageState>>,
    /**
     *  the current sort state.
     */
    sortState: SortState,
    /**
     * callback to update the sort state to a new state.
     */
    setSortState: Dispatch<SetStateAction<SortState>>,
    /**
     * the current filter state, in the format expected by DataTable.
     */
    filterState: FilterState,
    /**
     * callback to update the filter state to a new state. The new state should be in the format expected by DataTable.
     */
    setFilterState: Dispatch<SetStateAction<FilterState>>,
    /**
     * the calculated params used in the latest CRUD request, BEFORE merging/formatted with the supplied params.
     */
    crudParams: CrudParams,
    /**
     * the calculated params used in the latest CRUD request, AFTER merging/formatted with the supplied params.
     */
    requestParams: CrudParams,
    /**
     * the total number of data results found (as returned by CRUD request)
     */
    total: number,
    /**
     * callback to refresh data in the table.
     */
    refresh: ConnectedTableRefresh
}

/**
 * Provides functionality for a CRUD-driven DataTable. Handles pagination, sorting, filtering, using a CRUD subscription to
 * collect the data.
 *
 * Additionally, it stores the current table settings in the page URI query, so that the current state of the table can be deep-linked.
 */
export const useConnectedTable = (props: useConnectedTableProps): useConnectedTableReturn => {
    const {
        pageSizes,
        noRouter,
        defaultFilter,
        defaultSort,
        defaultItemsPerPage = 40,
        resetOnGlobalParamChange,
        pageMode,
        pageSizeAll = 1000,
        params,
        resource,
        formatData,
        pollInterval
    } = props;
    // Get current state from location (on initial load only)
    const {location, replace} = useHistory();
    const initialStates = noRouter ? {} : parse(location.search);
    let initialFilter;
    try {
        initialFilter = get(initialStates, "filter") && convertFromSimpleFilters(JSON.parse(get(initialStates, "filter") as string));
    } catch (err) {
        // do nothing
    }

    // State holders for page/sort/filter
    const [pageState, setPageState] = useState<PageState>(pageMode === "paginate" ? {
        skip: get(initialStates, "pageStart") && parseInt(get(initialStates, "pageStart") as string) || 0,
        take: get(initialStates, "perPage") && parseInt(get(initialStates, "perPage") as string) || defaultItemsPerPage
    } : {
        // make sure to ignore any uri params regarding pagination when not in use
        skip: 0,
        take: defaultItemsPerPage
    });
    const [sortState, setSortState] = useState<SortState>([{
        field: get(initialStates, "sort") as string || get(defaultSort, "field") as string || "",
        dir: get(initialStates, "dir") as SortOrder || get(defaultSort, "dir") as SortOrder || undefined,
    }]);
    const [filterState, setFilterState] = useState<FilterState>(
        initialFilter || (defaultFilter && convertFromSimpleFilters(defaultFilter)) || {}
    );

    // Format into CRUD params
    const crudParams = useDeepCompareMemo<CrudParams>(() => ({
        sort: {
            field: get(sortState, "[0].field"),
            order: get(sortState, "[0].dir", "").toUpperCase()
        },
        filter: convertToSimpleFilters(filterState),
        pagination: pageMode === "all" ? {
            page: 1,
            perPage: pageSizeAll
        } : {
            page: pageState.skip && pageState.take && ((pageState.skip / pageState.take) + 1) || 1,
            perPage: pageState.take
        }
    }), [pageState, sortState, filterState, pageMode]);

    //Update router location
    useDeepCompareEffect(() => {
        if (noRouter) {
            return;
        }

        const search = generateQueryString(
            convertToSimpleFilters(filterState),
            get(sortState, "[0].field"),
            get(sortState, "[0].dir"),
            pageMode === "paginate" ? pageState.skip : undefined,
            pageMode === "paginate" ? pageState.take : undefined
        );

        if (search !== location.search) {
            replace({
                ...location,
                search
            });
        }
    }, [pageState, sortState, filterState]);

    // Update per page if props are updated
    useDeepCompareEffect(() => {
        setPageState({...pageState, take: defaultItemsPerPage});
    }, [defaultItemsPerPage], false);

    // Reset on global param change (if flag is set)
    const {filter: globalFilter, sort: globalSort, pagination: globalPagination} = useGlobalParam()[0] || {};
    useDeepCompareEffect(() => {
        if (resetOnGlobalParamChange) {
            setPageState({
                skip: 0,
                take: defaultItemsPerPage
            });
            setSortState([{
                field: get(defaultSort, "field", ""),
                dir: get(defaultSort, "dir", undefined),
            }]);
            setFilterState((defaultFilter && convertFromSimpleFilters(defaultFilter)) || {});
        }
    }, [globalFilter, globalSort, globalPagination], false);

    // Collect data
    const requestParams = typeof params === "function" ? params(crudParams) : merge({}, crudParams, params);
    const [resourceData, loading, fetch] = useCrudSubscription(
        CrudTypes.GET,
        resource,
        requestParams,
        {
            pollInterval,
            crudOptions: {quietErrors: true},
            debounceWait: 250
        }
    );
    // parameters are intentionally stripped to prevent accidental modification of the request
    const refresh = () => fetch();
    const total = resourceData && resourceData.data?.total || 0;
    const returnedData = (resourceData && !resourceData.error) ? resourceData : {data: []};
    let formattedData = (formatData ? formatData(getDataContent(returnedData)) : getArrayDataContent(returnedData)).map((item: object): object => ({
        ...item,
        total
    }));

    // Update page number to first page if data returns empty
    const previousLoading = usePrevious(loading);
    useEffect(() => {
        if (previousLoading && !loading && formattedData.length === 0 && pageMode === "paginate" && pageState.skip > 0) {
            setPageState({...pageState, skip: 0});
        }
    }, [loading, pageState.take]);


    const canLoadMoreRows = pageState.take < total;
    const loadMoreRows = () => {
        setPageState((currentVal) => ({take: currentVal.take + defaultItemsPerPage, skip: 0}));
    };
    const infiniteScrollTableProps = useInfiniteScroll({canLoadMoreRows, pageMode, loading, loadMoreRows});

    // show info row at end of table
    if (loading || (canLoadMoreRows && pageMode === "dynamic")) {
        formattedData = [...formattedData, {"cuda-connected-table-footer": "loading"}];
    } else if (!canLoadMoreRows && pageMode === "dynamic" && formattedData.length > 0) {
        formattedData = [...formattedData, {"cuda-connected-table-footer": "end"}];
    }
    const rowRender = useCallback((row, rowProps) => rowProps.dataItem["cuda-connected-table-footer"]
            ? <TableFooterRow colSpan={rowProps.children?.length} state={rowProps.dataItem["cuda-connected-table-footer"]}/>
            : row
        , []);

    const tableDataMemo = useDeepCompareMemoize(formattedData);

    return {
        // @ts-ignore don't know why it know says its wrong. We're replacing this soon so not going to waste time trying to fix the TS references.
        dataTableProps: {
            ...useDeepCompareMemoize({
                pageable: pageMode === "paginate" && total ? {
                    buttonCount: 5,
                    info: true,
                    pageSizes: pageSizes || false,
                    previousNext: true
                } : false,
                onPageChange: useCallback(
                    (event) => {
                        event.nativeEvent?.preventDefault();
                        setPageState(event.page);
                    }, []),
                total,
                ...pageState
            }),
            ...useDeepCompareMemoize({
                sortable: true,
                onSortChange: useCallback((event) => setSortState(event.sort), []),
                sort: sortState
            }),
            ...useDeepCompareMemoize({
                // @ts-ignore don't know why it know says it's wrong. We're replacing this soon so not going to waste time trying to fix the TS references.
                onFilterChange: useCallback((event) => setFilterState(event.filter), []),
                filter: filterState
            }),
            data: tableDataMemo,
            rowRender,
            ...infiniteScrollTableProps
        },
        pageState,
        setPageState,
        sortState,
        setSortState,
        filterState,
        setFilterState,
        crudParams,
        requestParams,
        total,
        refresh
    };
};

const styles = createStyles({
    skeleton: {
        width: "100%",
        height: 450,
        marginTop: -110
    }
});
const useStyles = makeStyles(styles);

export type PageStatus = "initializing" | "loading" | "noDataPage" | "table";

export const useNoDataPage = (tableData: any[], noDataPage?: ReactNode, filterActive?: boolean, onPageStatusChange?: (data: PageStatus) => void) => {
    const [pageStatus, setPageStatus] = useState<PageStatus>(noDataPage ? "initializing" : "table");
    const classes = useStyles({});

    useEffect(() => {
        onPageStatusChange?.(pageStatus);
    }, [pageStatus]);

    useEffect(() => {
        if (filterActive && pageStatus !== "table") {
            setPageStatus("table");
        } else if (pageStatus === "initializing" && tableData[0]?.["cuda-connected-table-footer"] === "loading") {
            setPageStatus("loading");
        } else if (pageStatus === "loading" && tableData.length === 0) {
            setPageStatus("noDataPage");
        } else if (tableData.length > 0 && tableData[0]?.["cuda-connected-table-footer"] !== "loading") {
            setPageStatus("table");
        }
    }, [pageStatus, tableData.length, tableData[0]?.["cuda-connected-table-footer"], filterActive]);

    if (["initializing", "loading"].includes(pageStatus)) {
        return (
            <Skeleton className={classes.skeleton}/>
        );
    }
    return pageStatus === "noDataPage" ? noDataPage : null;
};
import Dialog from "@mui/material/Dialog";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import React, {useState} from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import {pick} from "lodash";
import {ClonableChildren} from "../../../utils/commonTypes";
import DisableableButton, {DisableableButtonProps} from "../../buttons/DisableableButton/DisableableButton";
import {DialogProps} from "@barracuda-internal/bds-core";

const styles = (theme: Theme) => createStyles({
    buttonIcon: {},
    button: {},
    dialog: {},
    dialogPaper: {
        minWidth: "fit-content"
    },
    tooltip: {
        ...theme.typography.body2
    },
});
const useStyles = makeOverrideableStyles("ButtonDialog", styles);

interface MenuChildProps {
    /** the menu option label. This must be present and unique for each child. */
    label: string,
    /** the menu option icon (optional). */
    icon?: React.ReactNode,
}

export interface ButtonDialogProps extends StyledComponentProps<typeof styles>,
    Pick<DisableableButtonProps, "disabled" | "disabledMessage" | "buttonText" | "buttonIcon" | "buttonEndIcon" | "buttonProps"> {
    /**
     * content to be rendered in the dialog. Ideally this should be a DialogBody.
     *
     * The function "onClose" is passed down to all children. Calling this function will close the dialog (if open).
     *
     * If the "useMenu" prop is true, only one child will be rendered in the Dialog (the selected child). The following props
     * are expected on the children, and are used to generate the menu options:
     *
     * @param {string} child.props.label the menu option label. This must be present and unique for each child.
     * @param {node} child.props.icon the menu option icon (optional).
     */
    children: ClonableChildren<MenuChildProps>,
    /**
     * if true, sets Dialog to be "fullWidth".
     */
    fullWidth?: boolean,
    /**
     * callback, called when the dialog is closed.
     */
    onClose?: () => void,
    /**
     * callback, called when the dialog is opened.
     */
    onOpen?: () => void,
    /**
     * if true, then stopPropagation is called on the button click event that opens the dialog.
     */
    stopPropagation?: boolean,
    /**
     * if true, the button is rendered as a MenuButton. The choices for the menu are calculated from the children, and only the
     * selected child is rendered in the Dialog when opened. See the children prop for more information on the props required on each child.
     */
    useMenu?: boolean,
    /**
     * override the classes on the underlying DisableableButton
     */
    buttonClasses?: DisableableButtonProps["classes"],
    maxWidth?: false | DialogProps['maxWidth'],
}

/**
 * A button which opens a Dialog when pressed.
 *
 * The button for this dialog can either be:
 *  - an IconButton,
 *  - a Menu button,
 *  - a regular Button
 *
 * An onClose function is passed down to all children which will close the dialog when called
 */
const ButtonDialog = (props: ButtonDialogProps) => {
    const {
        children,
        onClose,
        useMenu,
        onOpen,
        disabled,
        disabledMessage,
        buttonText,
        buttonProps,
        buttonClasses,
        fullWidth,
        buttonIcon,
        buttonEndIcon,
        stopPropagation,
        maxWidth = false
    } = props;
    const classes = useStyles(props);
    const childArray = React.Children.toArray(children).filter((child): child is React.ReactElement => !!child);
    const [open, setOpen] = useState(false);
    const [activeChild, setActiveChild] = useState<null | string | number>(null);
    const resolvedActiveChild = activeChild && childArray.find((child, index) => child.props && child.props.label ? child.props.label === activeChild : index === activeChild);

    const handleOpen = (event?: any, activeChild?: any): void => {
        stopPropagation && event.stopPropagation();
        setOpen(true);
        setActiveChild(activeChild);
        onOpen && onOpen();
    };
    const handleClose = () => {
        setOpen(false);
        setActiveChild(null);
        onClose && onClose();
    };

    const menuItems = !useMenu ? [] : childArray.map((child, index) => ({
        ...pick(child.props, ["disabled", "disabledTooltipText", "label", "icon"]),
        onClick: (event?: any) => handleOpen(event, child.props.label || index)
    }));

    return (
        // @ts-ignore as its showing this is not a valid JSX element
        <ErrorBoundary>
            <DisableableButton
                buttonIcon={buttonIcon}
                buttonEndIcon={buttonEndIcon}
                buttonText={buttonText}
                menuItems={useMenu ? menuItems : undefined}
                disabledMessage={disabledMessage}
                disabled={disabled}
                onClick={handleOpen}
                buttonProps={buttonProps}
                classes={buttonClasses}
            />
            <Dialog
                open={open}
                className={classes.dialog}
                fullWidth={fullWidth}
                classes={!maxWidth ? {paper: classes.dialogPaper}: {}}
                maxWidth={maxWidth}
                // fixes a bug where the Select component would fight the dialog for focus and freeze the browser
                // https://stackoverflow.com/questions/54133326/material-ui-uncaught-rangeerror-maximum-call-stack-size-exceeded
                disableEnforceFocus
            >
                {(useMenu ? (resolvedActiveChild && React.cloneElement(
                    resolvedActiveChild,
                    {onClose: handleClose}
                )) : childArray.map((child) => React.cloneElement(
                    child,
                    {onClose: handleClose}
                ))) || <div/>}
            </Dialog>
        </ErrorBoundary>
    );
};

export default ButtonDialog;
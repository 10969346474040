import {useCallback, useEffect, useState} from "react";
import mixpanel, {Config} from "mixpanel-browser";
import {useCustomEvents, useGlobalParam, useOneTrust} from "../../../hooks";

export interface MixpanelConfig {
    /** the API token to authenticate mixpanel **/
    token?: string,
    /** any configuration options that can be set in [mixpanel.init](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit) **/
    options?: Partial<Config>,
    /** a function which accepts the globalParams object and returns a unique user identifier from it, it should return null if no tracking is desired **/
    identifier?: (globalParams: any | undefined) => string | null | undefined,
    /** a prefix (or function to get a prefix from global params) to append to all events published by this app. **/
    appPrefix?: string | ((globalParams: any | undefined) => string | undefined)
}

export interface AnalyticsProviderProps {
    /** the configuration for mixpanel **/
    mixpanelConfig?: MixpanelConfig;
}

export const MixpanelEventKey = "@CUDA/MIXPANEL_EVENT";

export interface MixpanelEvent {
    action: string,
    additionalDetails?: any
}

const AnalyticsProvider = ({
                               mixpanelConfig: {
                                   token,
                                   options = {},
                                   identifier,
                                   appPrefix
                               } = {}
                           }: AnalyticsProviderProps) => {
    const [prefix, setPrefix] = useState<string | undefined>();
    const [hasInitialized, setHasInitialized] = useState(false);
    const [globalData] = useGlobalParam();
    const userIdentifier = globalData && identifier?.(globalData);
    const disabled = document.cookie.includes("DISABLE_MIXPANEL=true");
    const {allowedGroups} = useOneTrust();
    const optedIn = allowedGroups.includes("C0005");

    useEffect(() => {
        if (token && !disabled) {
            mixpanel.init(
                token,
                options
            );
            setHasInitialized(true);
        }
    }, [token, disabled]);

    useEffect(() => {
        if (hasInitialized) {
            if (!optedIn) {
                mixpanel.opt_out_tracking();
            } else {
                mixpanel.opt_in_tracking();
            }
        }
    }, [optedIn, hasInitialized]);

    useEffect(() => {
        hasInitialized && userIdentifier && mixpanel.identify(userIdentifier);
    }, [hasInitialized, userIdentifier]);

    useEffect(() => {
        if (typeof appPrefix === "function") {
            setPrefix(appPrefix(globalData));
        } else {
            setPrefix(appPrefix);
        }
    }, [appPrefix, globalData]);

    const eventHandler = useCallback((event?: MixpanelEvent) => {
        if (event && hasInitialized) {
            const trackingEvent = (prefix ? prefix + " " : "") + event.action;
            if (process.env.NODE_ENV === "development") {
                // eslint-disable-next-line no-console
                console.info("[mixpanel]", trackingEvent, event.additionalDetails);
            }
            mixpanel && mixpanel.track(
                trackingEvent,
                event.additionalDetails
            );
        }
    }, [hasInitialized]);

    useCustomEvents<MixpanelEvent>(MixpanelEventKey, eventHandler);

    return null;
};

export default AnalyticsProvider;
import React from "react";
import {BasicPage, getArrayDataContent, Tab, Tabs, useCrudProps, useMixpanel} from "@cuda-react/core";
import {Paper} from "@barracuda-internal/bds-core";
import apiResources from "../../../../../apiResources";
import SettingsTable from "./SettingsTable";
import ExplicitTable from "./ExplicitTable";
import {sortBy} from "lodash";

export const UrlFilters = () => {
    useMixpanel("Web Filter");
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterActions, {}, {}, true)[0]?.data);
    const categoryChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]?.data), "key");

    return (
        <BasicPage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.webFilter", "tesseract.securityAndAccess.urlFilter"]}
            authenticated
        >
            <Paper>
                <Tabs>
                    <Tab label="tesseract.security.urlFiltering.settings.tabTitle">
                        <SettingsTable
                            actionChoices={actionChoices}
                            categoryChoices={categoryChoices}
                        />
                    </Tab>
                    <Tab label="tesseract.security.urlFiltering.exceptions.tabTitle">
                        <ExplicitTable
                            actionChoices={actionChoices}
                            categoryChoices={categoryChoices}
                        />
                    </Tab>
                </Tabs>
            </Paper>
        </BasicPage>
    );
};

export default UrlFilters;
import {Account, useAccounts} from "../../../hooks";
import React from "react";
import {useTranslation} from "react-i18next";
import Select, {SelectProps} from "../../inputs/Select/Select";
import {overrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles, makeStyles} from "@mui/styles";

export interface BaseChangeAccountButtonProps {
    /**
     * Resource url or name which provides a list of available accounts for the logged in user. If provided, the account switcher option in the Logout menu is displayed.
     */
    listAccountsResource?: string,
    /**
     * callback, called when a new account is selected.
     * @function
     * @param {object} account the details of the selected account
     * @returns
     */
    onAccountChange?: (account: Account) => void,
    /**
     * Override props on the underlying Select component.
     */
    selectProps?: Partial<SelectProps>,
    /**
     * If true, component will be rendered with transparent background
     */
    transparent?: boolean,
    /**
     * Resource url or name which provides a details about the accounts of the logged in user.
     */
    userDataResource?: string
}

const styles = createStyles<string, BaseChangeAccountButtonProps>({
    root: {
        width: 192
    },
    select: {
        width: 192,
        backgroundColor: (props) => props.transparent ? "rgba(0,0,0,0) !important" : undefined,
        "& > div": {
            backgroundColor: (props) => props.transparent ? "rgba(0,0,0,0) !important" : undefined,
        }
    }
});

const useStyles = makeStyles(overrideableStyles("ChangeAccountButton", styles));

export interface ChangeAccountButtonProps extends BaseChangeAccountButtonProps, StyledComponentProps<typeof styles> {
}

/**
 * Account selector button, for use with [BCC Authentication](/?path=/docs/cudareactapp-authentication--page).
 *
 * Lists the accounts available to the user, and generates the change account auth action on selection.
 */
export const ChangeAccountButton = (props: ChangeAccountButtonProps) => {
    const {listAccountsResource, onAccountChange, selectProps, userDataResource} = props;
    const [availableAccounts, currentAccount, defaultAccount, swapAccount] = useAccounts(listAccountsResource, userDataResource);
    const [translate] = useTranslation();
    const classes = useStyles(props);

    return availableAccounts.length > 1 ? (
        <Select
            label={translate("cuda.auth.switchAccount")}
            id="select-account"
            value={currentAccount.id}
            choices={availableAccounts.map((account) => ({
                id: account.id,
                label: account.name,
                subLabel: account.id === defaultAccount.id ? "cuda.auth.defaultAccount" : undefined
            }))}
            onChange={(event: { target?: { value?: string } }) => {
                const selectedAccount = availableAccounts.find((account) => account.id === event?.target?.value);
                selectedAccount && onAccountChange && onAccountChange(selectedAccount);
                selectedAccount && swapAccount(selectedAccount);
            }}
            optionValue="id"
            optionText="label"
            classes={{
                formControl: classes.root,
                select: classes.select
            }}
            {...selectProps}
        />
    ) : null;
};

export default ChangeAccountButton;
import React from "react";
import {SearchableSelectInput} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {useWatch} from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    haMessage: {
        color: theme.palette.warning.main
    },
    secondaryMessage: {
        marginTop: theme.spacing(1)
    }
}));

interface SiteChoice {
    id: number;
    serials: string[];
}

interface SelectSiteStepProps {
    choices: SiteChoice[];
}

const SelectSiteStep: React.FC<SelectSiteStepProps> = ({choices}) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const siteId = useWatch({name: "siteId"});
    const selectedSite = choices.find((site) => site.id === siteId);
    const siteIsHa = (selectedSite?.serials?.length || 0) > 1;
    return (
        <>
            <Typography>
                {translate("tesseract.appliances.dialog.siteSelection.mainMessage")}
            </Typography>
            <Typography className={classes.secondaryMessage}>
                {translate("tesseract.appliances.dialog.siteSelection.secondaryMessage")}
            </Typography>
            <SearchableSelectInput
                isRequired
                choices={choices}
                optionValue="id"
                label="tesseract.appliances.dialog.siteSelection.site"
                source="siteId"
                clearOnFocus
            />
            {selectedSite && !siteIsHa ? (
                <Typography className={classes.haMessage}>
                    {translate("tesseract.appliances.dialog.siteSelection.haWarning")}
                </Typography>
            ) : null}
        </>
    );
};
export default SelectSiteStep;
import {
    Card,
    ConnectedForm,
    FormSection,
    PropGateway,
    TextInput,
    useGlobalParam,
    validatePrivateIpv4CIDR
} from "@cuda-react/core";
import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";
import {formatRequestCriteria} from "../../../../components/sourceAndDestination/formUtils";

export const useStyles = makeStyles((theme) => ({
    message: {
        padding: theme.spacing(0, 2, 2, 4)
    }
}));

export const SdwanConnectorNetwork = () => {
    const [translate] = useTranslation();
    const classes = useStyles();
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    return (
        <Card title="tesseract.settings.tabs.sdwanConnectorNetwork.title">
            <ConnectedForm
                formatRequestData={formatRequestCriteria(virtualWanId)}
                resource={apiResources.sdwanConnectorNetwork}
            >
                <FormSection title="tesseract.settings.tabs.sdwanConnectorNetwork.subMenu">
                    <PropGateway>
                        <Typography className={classes.message}>
                            {translate("tesseract.settings.tabs.sdwanConnectorNetwork.description")}
                        </Typography>
                    </PropGateway>
                    <TextInput
                        source="clientNetworkConfiguration.range"
                        label="tesseract.network.sdwanConnector.dialog.poolNetwork.network"
                        description="tesseract.network.sdwanConnector.dialog.description.poolNetwork.network"
                        isRequired
                        validate={validatePrivateIpv4CIDR}
                    />
                </FormSection>
            </ConnectedForm>
        </Card>
    );
};

export default SdwanConnectorNetwork;
import React from "react";
import {Route, Switch} from "react-router";
import SubscriptionTable from "./Pam/SubscriptionTable";

const Subscription = () => (
    <Switch>
        <Route path="/subscription/pam">
            <SubscriptionTable />
        </Route>
    </Switch>
);

export default Subscription;
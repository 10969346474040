import React from "react";
import Input from "../Input/Input";
import ReadOnly from "./ReadOnly";
import {BaseFieldProps} from "../../fields";
import {ReadOnlyInputProps} from "./ReadOnlyInputProps";

/**
 * Renders a read only input.
 * Type of the input is defined by the field prop. The input value will be unmutable from the UI.
 */
export const ReadOnlyInput = <FieldComponent extends React.ElementType<BaseFieldProps>>(props: ReadOnlyInputProps<FieldComponent>) => (
    <Input {...props} component={ReadOnly}/>
);

ReadOnlyInput.defaultProps = {
    options: {}
};

export default ReadOnlyInput;
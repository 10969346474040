import React, {useMemo} from "react";
import {UserDirectory} from "../../UserDirectoriesTypes";
import {LoadingMessage, useGlobalParam} from "@cuda-react/core";
import {UserDirectoryBCCForm} from './UserDirectoryBCCForm';
import {UserDirectoryEntraIDForm} from "./UserDirectoryEntraIDForm";
import {UserDirectoryLdapForm} from "./UserDirectoryLdapForm";
import {UserDirectoryOktaForm} from "./UserDirectoryOktaForm";
import {UserDirectoryGoogleForm} from "./UserDirectoryGoogleForm";

type UserDirectoryDialogProps = {
    directory: UserDirectory;
    create: boolean;
    id?: string;
    onClose: (refresh: boolean) => void;
}

export const UserDirectoryForm: React.FC<UserDirectoryDialogProps> = (props: UserDirectoryDialogProps) => {
    const account = useGlobalParam()?.[0]?.pageTitle?.account ?? null;
    const accountName = useMemo(() => account?.join(" - "), [account]);

    if (!account || !accountName){
        return <LoadingMessage />;
    }

    switch (props.directory.type) {
        case 'bcc':
            return <UserDirectoryBCCForm {...props} directory={{...props.directory, accountName}} />;
        case 'entraid':
            return <UserDirectoryEntraIDForm {...props} directory={{...props.directory, accountName}} />;
        case 'ldap':
            return <UserDirectoryLdapForm {...props} directory={{...props.directory, accountName}} />;
        case 'okta':
            return <UserDirectoryOktaForm {...props} directory={{...props.directory, accountName}} />;
        case 'google':
            return <UserDirectoryGoogleForm {...props} directory={{...props.directory, accountName}} />;
        default:
            return null;
    }
};
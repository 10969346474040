import React, {useEffect, useState} from "react";
import {Skeleton} from "@mui/material";
import {BasicPage} from "@cuda-react/core";

interface LoadMfeChildProps {
    loadout?: {
        url: string,
        name: string,
        htmlTag: string
    }
}

const LoadMfeChild = ({loadout}: LoadMfeChildProps) => {
    const [moduleLoaded, setModuleLoaded] = useState(false);

    useEffect(() => {
        if (loadout?.url && !moduleLoaded) {
            import(/* @vite-ignore */loadout.url).then(() => setModuleLoaded(true));
        }
    }, [loadout?.url, moduleLoaded]);

    if (!loadout || !moduleLoaded) {
        return (
            <BasicPage loading>
                <Skeleton sx={{height: "50vh", transform: "none"}}/>
            </BasicPage>
        );
    }

    return React.createElement(loadout.htmlTag, {});
};

export default LoadMfeChild;
import React from "react";
import Input, {InputProps} from "../Input/Input";
import {get} from 'lodash';
import Password from "./Password";
import {Validator} from "../../../utils";

const getConfirmName = (name: string): string => name + "_confirm";

export const confirmPasswordValidation: Validator = (confirmValue, allValues, props, confirmName) => {
    const name = (confirmName || "").substring(0, (confirmName || "").length - 8);
    const value = get(allValues, name, "");
    const initialValue = props?.initialValues && name && get(props?.initialValues, name);
    const valueIsIgnorable = initialValue === value && (confirmValue === "" || confirmValue === undefined || confirmValue === null);
    const valueIsConfirmed = value === confirmValue;
    return (!valueIsIgnorable && !valueIsConfirmed) ? props.t("cuda.validation.passwordConfirm") : undefined;
};

export interface PasswordConfirmInputProps extends Omit<InputProps<typeof Password>, "component" | "displayError"> {
    /** Label for the confirm input */
    confirmLabel?: InputProps<typeof Password>["label"],
    /** Removes the confirm input if true */
    noConfirm?: boolean
}

export const PasswordConfirmInput = (props: PasswordConfirmInputProps) => {
    const {source, confirmLabel, clearOnFocus = false, viewable = false, validate, noConfirm} = props;

    return (
        <React.Fragment>
           <Input
               {...props}
               source={source}
               clearOnFocus={clearOnFocus}
               viewable={viewable}
               displayError={false}
               validate={validate}
               component={Password}
           />
            {!noConfirm &&
                <Input
                    {...props}
                    label={confirmLabel}
                    clearOnFocus={clearOnFocus}
                    description=""
                    viewable={viewable}
                    displayError={false}
                    isRequired={false}
                    validate={[confirmPasswordValidation]}
                    source={getConfirmName(source)}
                    component={Password}
                />
            }
        </React.Fragment>
    );
};

export default PasswordConfirmInput;
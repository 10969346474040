import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {ButtonDialog, Workspace} from "@cuda-react/core";
import WorkspaceDialogContent from "./WorkspaceDialogContent";
import {createStyles, makeStyles} from "@mui/styles";

export interface WorkspaceDialogProps {
    activeWorkspace?: Workspace,
    onChange?: () => void
}

const useStyles = makeStyles(createStyles({
    editIcon: {
        "&:hover": {
            backgroundColor: "#E4F4FF" // TODO: Does this need tokenizing?
        },
        width: 20,
        height: 20
    },
    addIcon: {
        '&:hover': {
            backgroundColor: '#E4F4FF', // TODO: Does this need tokenizing?
        },
        width: 20,
        height: 20
    },
    buttonIcon: {
        width: 24,
        height: 24
    }
}));

const WorkspaceCreateEditDialog = ({activeWorkspace, onChange}: WorkspaceDialogProps) => {
    const classes = useStyles();

    return (
        <ButtonDialog
            buttonIcon={activeWorkspace ? (
                <EditIcon id="cuda-edit-workspace" className={classes.editIcon}/>
            ) : (
                <AddCircleOutlineIcon id="cuda-add-workspace" className={classes.addIcon}/>
            )}
            classes={{buttonIcon: classes.buttonIcon}}
        >
            <WorkspaceDialogContent activeWorkspace={activeWorkspace} onChange={onChange}/>
        </ButtonDialog>
    );
};

export default WorkspaceCreateEditDialog;
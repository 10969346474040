import React from "react";
import ButtonDialog, {ButtonDialogProps} from "../ButtonDialog/ButtonDialog";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import ActionDialogContent, {ActionDialogContentProps} from "../ActionDialogContent/ActionDialogContent";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const styles = (theme: Theme) => createStyles({
    iconDisabledPrimaryFill: {
        "& .cuda-react-icon-primary-fill": {
            fill: theme.palette.text.disabled
        }
    }
});
const useStyles = makeOverrideableStyles("ActionDialog", styles);


export interface ActionDialogProps extends StyledComponentProps<typeof styles>, Omit<ActionDialogContentProps<any>, "classes"> {
    /**
     * icon to provide to the [ButtonDialog](/?path=/docs/core-components-dialogs-buttondialog--button-dialog) button.
     */
    buttonIcon?: React.ReactElement,
    /**
     * additional props to pass to the [ButtonDialog](/?path=/docs/core-components-dialogs-buttondialog--button-dialog) button.
     */
    buttonProps?: Partial<ButtonDialogProps>,
    /**
     * text to provided to the [ButtonDialog](/?path=/docs/core-components-dialogs-buttondialog--button-dialog) button.
     */
    buttonText?: string,
    /**
     * data to provide to [ActionDialogContent](/?path=/docs/core-components-dialogs-actiondialogcontent--action-dialog-content).
     *
     * Components such as [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table),
     * [ActionButtonsField](/?path=/docs/core-components-fields-actionbuttonsfield--action-buttons-field), and others
     * will automatically pass this down.
     */
    data?: any,
    /**
     * callback function to determine if the button should be disabled. Useful when disabling should be based on data provided
     * by a parent component.
     *
     * @function
     * @param {object} data the data, as provided to ActionDialog.
     * @returns {boolean} if true, button is disabled.
     */
    disable?: (data: any) => boolean,
    /**
     * if true, button is disabled.
     */
    disabled?: boolean,
    /**
     * tooltip message to display when button is disabled.
     */
    disabledMessage?: string,
}

/**
 * Renders a button, that opens a preset dialog for performing user-confirmed CRUD requests.
 *
 * Once the dialog is confirmed, the provided CRUD resource will be called with the provided params and CRUD type, before closing the dialog.
 *
 * See the consumed content component, [ActionDialogContent](/?path=/docs/core-components-dialogs-actiondialogcontent--action-dialog-content),
 * for additional information (such as required props). All props not mentioned below are passed to the
 * [ActionDialogContent](/?path=/docs/core-components-dialogs-actiondialogcontent--action-dialog-content) component.
 */
const ActionDialog = (props: ActionDialogProps) => {
    const {
        buttonIcon,
        buttonText,
        disable,
        disabled,
        disabledMessage,
        buttonProps = {},
        classes: _ignore,
        ...contentProps
    } = props;
    const classes = useStyles(props);
    const isDisabled = disabled || (!!disable && disable(contentProps.data));

    return (
        <ButtonDialog
            buttonIcon={buttonIcon && React.cloneElement(
                buttonIcon,
                {className: isDisabled ? classes.iconDisabledPrimaryFill : undefined}
            )}
            buttonText={buttonText}
            disabled={isDisabled}
            disabledMessage={disabledMessage}
            {...buttonProps}
        >
            <ActionDialogContent {...contentProps} />
        </ButtonDialog>
    );
};

export default ActionDialog;
import {CrudTypes, getArrayDataContent, useCrudSubscription, useDeepCompareEffect} from "@cuda-react/core";
import apiResources from "../../apiResources";
import {useState} from "react";
import {sortBy} from "lodash";

export type Appliance = {
    key: string,
    name: string,
    type: string
};

export default (presetAppliance?: Appliance) => {
    const [{data: siteData}, sitesLoading] = useCrudSubscription(
        CrudTypes.GET,
        !presetAppliance ? apiResources.siteNames : undefined,
        {filter: {modelSeries: "T"}}
    );
    const [{data: onPremGatewayData}, onPremGatewaysLoading] = useCrudSubscription(
        CrudTypes.GET,
        !presetAppliance ? apiResources.gatewayNames : undefined,
        {filter: {type: "on-prem"}}
    );
    const [{data: managedGatewayData}, managedGatewaysLoading] = useCrudSubscription(
        CrudTypes.GET,
        !presetAppliance ? apiResources.gatewayNames : undefined,
        {filter: {type: "managed"}}
    );
    const [appliances, setAppliances] = useState<Appliance[]>([]);
    const [neverLoaded, setNeverLoaded] = useState(!presetAppliance);

    useDeepCompareEffect(() => {
        if (!sitesLoading && !onPremGatewaysLoading && !managedGatewaysLoading && !neverLoaded) {
            const applianceItems = sortBy([
                ...getArrayDataContent(siteData).map((item) => ({...item, type: "site"})),
                ...getArrayDataContent(onPremGatewayData).map((item) => ({...item, type: "on-prem"})),
                ...getArrayDataContent(managedGatewayData).map((item) => ({...item, type: "managed"}))
            ], "name");
            setAppliances(applianceItems.length > 0 ? applianceItems : [{
                key: "_NONE_",
                name: "tesseract.dashboard.filters.noAppliances"
            }]);
        } else if ((sitesLoading || onPremGatewaysLoading || managedGatewaysLoading) && neverLoaded) {
            setNeverLoaded(false);
        }
    }, [sitesLoading, onPremGatewaysLoading, managedGatewaysLoading, neverLoaded]);

    return {
        appliances: presetAppliance ? [presetAppliance] : appliances,
        appliancesLoading: neverLoaded || sitesLoading || onPremGatewaysLoading || managedGatewaysLoading
    };
};
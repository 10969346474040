import React from "react";
import {
    BasicPage,
    CrudTypes,
    FormSection,
    getDataContent,
    Tab,
    Tabs,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import SslInterceptionCertificates from "./certificates/SslInterceptionCertificates";
import SslInterceptionCAs from "./certificates/SslInterceptionCAs";
import SslInterceptionExplicit from "./explicit/SslInterceptionExplicit";
import {Paper} from "@barracuda-internal/bds-core";
import apiResources from "../../../../../apiResources";

const SslInterception = () => {
    useMixpanel("SSL Inspection");
    const [enabledData, enabledLoading, refreshEnabled] = useCrudSubscription(CrudTypes.GET, apiResources.sslInspectionEnabled);
    const enabled = getDataContent(enabledData.data, false);

    return (
        <BasicPage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.security", "tesseract.securityAndAccess.ssl"]}
            authenticated
        >
            <Paper>
                <Tabs>
                    <Tab label="tesseract.security.sslInterception.settings.explicitTabTitle">
                        <SslInterceptionExplicit enabled={enabled} enabledLoading={enabledLoading} refreshEnabled={refreshEnabled}/>
                    </Tab>
                    <Tab label="tesseract.security.sslInterception.settings.tabTitle">
                        <FormSection title="tesseract.security.sslInterception.settings.sslCertificates">
                            <SslInterceptionCertificates enabled={enabled}/>
                        </FormSection>
                        <FormSection title="tesseract.security.sslInterception.settings.sslCertificateAuthorities">
                            <SslInterceptionCAs/>
                        </FormSection>
                    </Tab>
                </Tabs>
            </Paper>
        </BasicPage>
    );
};

export default SslInterception;
import React from "react";
import Input, {InputProps} from "../Input/Input";
import File from './File';

export interface FileInputProps extends Omit<InputProps<typeof File>, "component" | "displayError"> {}

/**
 * [Input](/?path=/docs/core-components-inputs-input) Wrapper component format, to work natively with redux form "Field" prop.
 * form ready version of the File component.
 */
export const FileInput = (props: FileInputProps) => (
    <Input {...props} displayError={false} component={File} />
);
export default FileInput;
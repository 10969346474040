import React from "react";
import {Small} from "./Small";
import {Medium} from "./Medium";
import {Large} from "./Large";
import {None} from "./None";

export enum GridSizes {
    small = "small",
    medium = "medium",
    large = "large",
}

const SizedIcons: Record<GridSizes | "None", React.ReactNode> = {
    [GridSizes.small]: <Small />,
    [GridSizes.medium]: <Medium />,
    [GridSizes.large]: <Large />,
    ["None"]: <None />,
};

export interface GridSizeIconProps {
    size: GridSizes | "None";
}

export const GridSizeIcon = ({size}: GridSizeIconProps)=> (
        <>
            {SizedIcons[size]}
        </>
    );

export default GridSizeIcon;
import {ButtonDialog} from "@cuda-react/core";
import React from "react";
import CustomAppContent from "./CustomAppContent";

interface CreateCustomAppDialogProps {
    onSuccess?: () => void
}

const CreateCustomAppDialog: React.FC<CreateCustomAppDialogProps> = ({onSuccess}) => (
    <ButtonDialog buttonText="tesseract.customApps.dialogs.create.buttonText" useMenu>
        <CustomAppContent
            create
            type="network"
            title="tesseract.customApps.dialogs.create.dialogTitleNetwork"
            label="tesseract.customApps.dialogs.create.menuLabelNetwork"
            onSuccess={onSuccess}
        />
        <CustomAppContent
            create
            type="web"
            title="tesseract.customApps.dialogs.create.dialogTitleWeb"
            label="tesseract.customApps.dialogs.create.menuLabelWeb"
            onSuccess={onSuccess}
        />
    </ButtonDialog>
);


export default CreateCustomAppDialog;
import React from "react";
import {BaseStepProps} from "../StepType";

export interface MultiStepProps extends Partial<BaseStepProps> {}

/**
 * Wizard step, that signifies that all children Steps should be grouped as "sub steps" under this step.
 *
 * This component should be used a child to the [Wizard](/?path=/docs/core-components-wizard-wizard--wizard), and simply acts as a placeholder and
 * does not directly render anything.
 *
 * Children should be other Wizard *Step components.
 */
export const MultiStep = (props: MultiStepProps) => <React.Fragment />;

export default MultiStep;
import React from "react";
import {CardSizeOptions, CardSizes} from "../../../typesAndConstants";
import {BaseCard} from "../../baseCards";
import generatePreviewData from "../../../utils/previewDataGenerators/zeroTrustDevices";
import apiResources from "../../../apiResources";
import {useCardFetch} from "../../../hooks";
import {CustomField, DateField, LocationsCardContent, TextField} from "@cuda-react/core";
import {StatisticsTable} from "../../statistics";
import {makeStyles} from "@mui/styles";
import {usei18nCountries} from "../../../utils/countries";
import {useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";

export interface ZTNADeviceLocationCardProps {
    preview?: boolean,
    size: CardSizes,
    additionalProps?: {id: number}
}

const useStyles = makeStyles((theme) => ({
    cardContent: {
        height: "calc(100% - 52px)",
        overflowY: "hidden",
        "& .in-highchart": {
            height: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6)})`,
            overflow: "show",
            width: "100%"
        }
    },
}));

export const ZTNADeviceLocationCard = (props: ZTNADeviceLocationCardProps) => {
    const {preview, size} = props;
    const {data = []} = useCardFetch(
        {
            url: !preview ? apiResources.userDevices : undefined,
            params: {filter: {userId: props?.additionalProps?.id}}
        }
    );
    const content = (preview ? generatePreviewData() : data) || [];
    const classes = useStyles(props);
    const getNameByCountryCode = usei18nCountries();
    const theme = useTheme();
    const [translate] = useTranslation();

    return (
        <BaseCard
            title="stratosReporting.ZTNADeviceLocation.title"
            size={size}
            preview={preview}
            classes={{cardContent: classes.cardContent}}
        >
            <LocationsCardContent
                series={[
                    {
                        label: "stratosReporting.ZTNADeviceLocation.devices",
                        color: theme.palette.general.graphite,
                        zIndex: 1,
                        details: {
                            fields: [
                                <TextField
                                    source="userFullName"
                                    key="userFullName"
                                    label="stratosReporting.ZTNADeviceLocation.user"
                                />,
                                <TextField
                                    source="brand"
                                    key="brand"
                                    label="stratosReporting.ZTNADeviceLocation.brand"
                                />,
                                <TextField
                                    source="hardwareModel"
                                    key="hardwareModel"
                                    label="stratosReporting.ZTNADeviceLocation.hardwareModel"
                                />,
                                <TextField
                                    source="operatingSystem"
                                    key="operatingSystem"
                                    label="stratosReporting.ZTNADeviceLocation.operatingSystem"
                                />,
                                <TextField
                                    source="operatingSystemVersion"
                                    key="operatingSystemVersion"
                                    label="stratosReporting.ZTNADeviceLocation.operatingSystemVersion"
                                />,
                                <TextField
                                    source="agentVersion"
                                    key="agentVersion"
                                    label="stratosReporting.ZTNADeviceLocation.agentVersion"
                                />,
                                <CustomField
                                    source="countryCode"
                                    key="location.countryCode"
                                    label="stratosReporting.ZTNADeviceLocation.country"
                                    render={getNameByCountryCode}
                                />,
                                <DateField
                                    source="enrollmentDate"
                                    key="enrollmentDate"
                                    label="stratosReporting.ZTNADeviceLocation.enrollmentDate"
                                />,
                            ]
                        },
                        data: content.map((item: any) => ({...item, ...item.location}))
                    }
                ]}
                chartOverrides={{
                    chart: {
                        height: CardSizeOptions.small.height - 48,
                        width: CardSizeOptions[props.size].width - 2,
                        spacing: [2, 2, 2, 2]
                    },
                    legend: {
                        floating: true,
                        backgroundColor: "rgba(255,255,255,0.4)",
                        shadow: {
                            color: "rgba(255,255,255, 0.4)",
                            opacity: 0.2,
                            width: 16
                        },
                        borderRadius: 4
                    }
                }}
            />
            {props.size === CardSizes.large && (
                <StatisticsTable
                    tableTitle="stratosReporting.ZTNADeviceLocation.devices"
                    noDataMessage="stratosReporting.ZTNADeviceLocation.noData"
                    resource={apiResources.userDevices}
                    params={{filter: {userId: props?.additionalProps?.id}}}
                >
                    <TextField
                        source="userFullName"
                        label="stratosReporting.ZTNADeviceLocation.user"
                    />
                    <TextField
                        source="brand"
                        label="stratosReporting.ZTNADeviceLocation.brand"
                    />
                    <TextField
                        source="operatingSystem"
                        label="stratosReporting.ZTNADeviceLocation.operatingSystem"
                    />
                    <CustomField
                        source="location.countryCode"
                        label="stratosReporting.ZTNADeviceLocation.country"
                        render={(value) => getNameByCountryCode(value) !== translate("stratosReporting.ZTNAUserGeoLocation.nonRoutableOrIPUnknown") ? getNameByCountryCode(value) : translate("stratosReporting.ZTNADeviceLocation.noCountry")}
                    />
                    <DateField
                        source="enrollmentDate"
                        label="stratosReporting.ZTNADeviceLocation.enrollmentDate"
                    />
                </StatisticsTable>
            )}
        </BaseCard>
    );
};
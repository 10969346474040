import {Button, Grid, Typography} from "@barracuda-internal/bds-core";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {StyledComponentProps} from "@cuda-react/theme";

const styles = (theme: Theme) => createStyles({
    detailLinkButton: {
        textTransform: "none",
        padding: theme.spacing(1),
        flexGrow: 1
    },
    detailContainer: {
        padding: theme.spacing(1)
    },
    detailsText: {
        color: theme.palette.reporting.fontColor,
        margin: theme.spacing(0, 0.5),
        fontSize: 14,
        textAlign: "center"
    },
    detailsValue: {
        fontWeight: "bold",
        color: theme.palette.primary.main,
        fontSize: 24,
        textAlign: "center"
    }
});
const useStyles = makeStyles(styles);

export interface StatisticsDetailProps extends StyledComponentProps<typeof styles> {
    /** id to assign to route component.*/
    id?: string,
    /** if provided, statistic is rendered as a clickable link to the target */
    link?: string,
    /** title to render for the statistic */
    title: string,
    /** value to render for the statistic */
    value: ReactNode,
    /** text color for the statistic value */
    color?: string,
    /** icon to display next to the title */
    icon?: ReactNode
}

/** Component for displaying a single statistic Title - Value pair */
export const StatisticsDetail = (props: StatisticsDetailProps) => {
    const {id, link, title, value, color, icon} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <Grid
            item
            id={id}
            href={link ? link : undefined}
            component={link ? Button : "div"}
            className={link ? classes.detailLinkButton : classes.detailContainer}
        >
            <Grid container direction="column" alignItems="center">
                <Grid container alignItems="center" direction="column">
                    {icon}
                    <Typography className={classes.detailsText}>
                        {translate(title)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        className={classes.detailsValue}
                        style={color ? {color} : undefined}
                    >
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
import ForwardedDomains from "./cards/ForwardedDomains";
import React from "react";
import {BasicPage, useMixpanel} from "@cuda-react/core";
import FirmwareUpdates from "./cards/updates/FirmwareUpdates";
import SecurityUpdates from "./cards/updates/SecurityUpdates";
import {makeStyles} from "@mui/styles";
import SdwanConnectorNetwork from "./cards/SdwanConnectorNetwork";
import DcAgent from "./cards/DcAgent";
import DhcpServer from "./cards/DhcpServer";
import ReportCreatorAcl from "./cards/ReportCreatorAcl";
import SIPProxy from "./cards/SIPProxy";

const useStyles = makeStyles((theme) => ({
    content: {
        "& > *:not(:first-child)": {
            marginTop: theme.spacing(3)
        }
    }
}));

export const NetworkSettings = () => {
    const classes = useStyles();
    useMixpanel("Network Settings");

    return (
        <BasicPage
            title="tesseract.network.pageTitle"
            subtitle="tesseract.network.settings"
            // @ts-ignore as type definition for classes already there
            classes={{content: classes.content}}
        >
            <FirmwareUpdates/>
            <SecurityUpdates/>
            <ForwardedDomains/>
            <ReportCreatorAcl/>
            <SdwanConnectorNetwork/>
            <DcAgent/>
            <DhcpServer/>
            <SIPProxy/>
        </BasicPage>
    );
};

export default NetworkSettings;
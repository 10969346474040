import React from "react";
import {CardSizeOptions, CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import {Theme, useTheme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Divider, Grid, Typography} from "@barracuda-internal/bds-core";
import BaseCard from "../../../components/baseCards/BaseCard";
import StatisticsOverview from "../../statistics/StatisticsOverview";
import StatisticsDetail from "../../statistics/StatisticsDetail";
import {colorFunction, getStatusColorByIndex} from "../../../utils";
import apiResources from "../../../apiResources";
import generatePreviewData from "../../../utils/previewDataGenerators/ztnaAgentOperatingSystems";
import {useCardFetch} from "../../../hooks";
import {useTranslation} from "react-i18next";
import {BarGrid} from "../../statistics/BarGrid";
import classNames from "classnames";

const styles = (theme: Theme) => createStyles<string, Pick<DashboardCardProps, "size" | "preview">>(({
    dividerCards: {
        width: "95%"
    },
    cardContent: {
        height: "calc(100% - 50px)",
        overflowY: "hidden"
    },
    titleBar: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.palette.reporting.fontColor,
        padding: "16px 16px 8px",
    },
    barGrid: {
        maxHeight: 300,
        minHeight: 300,
        overflowY: "scroll",
    },
    noDataContainer: {
        height: ({size}) => `calc(${CardSizeOptions[size].height}px - ${theme.spacing(50)})`,
        width: "100%"
    },
}));

const useStyles = makeStyles(styles);

export interface ZTNAAgentInUseCardProps {
    preview?: boolean,
    size: CardSizes,
}

export const ZTNAAgentInUseCard = (props: ZTNAAgentInUseCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data = {}} = useCardFetch({url: !preview ? apiResources.agentOperatingSystems : undefined});
    const {total, operatingSystems} = (preview ? generatePreviewData() : data) || {};
    const totalValue = total ? total : 0;
    const classes = useStyles(props);
    const theme = useTheme();

    // BarChart Data
    const operatingSystemsKeys = (operatingSystems ? Object.keys(operatingSystems) : [])
        .sort((osA, osB) => operatingSystems[osA] - operatingSystems[osB])
        .reverse();
    const colorsOsObj: {[key: number]: string} = {};
    const highestValue = operatingSystems && operatingSystems[operatingSystemsKeys[0]];
    const entries = operatingSystemsKeys.map((osKey: string, index: number) => {
        let osColor;
        if (operatingSystems[osKey] in colorsOsObj) {
            osColor = colorsOsObj[operatingSystems[osKey]];
        } else {
            osColor = colorFunction((100.0 / highestValue) * operatingSystems[osKey], theme);
            colorsOsObj[operatingSystems[osKey]] = osColor;
        }

        return {
            title: osKey,
            value: translate("stratosReporting.ZTNAAgentInUse.devices", {value: operatingSystems[osKey], context: operatingSystems[osKey] === 1 ? undefined : "plural"}),
            count: operatingSystems[osKey],
            barProgress: (100.0 / highestValue) * operatingSystems[osKey],
            color: osColor,
            statusColor: getStatusColorByIndex(index, theme)
        };
    });

    // Overview Data
    const overviewData = entries.slice(0, size === "small" ? 3 : 5);

    return (
        <BaseCard title={"stratosReporting.ZTNAAgentInUse.title"} size={size} preview={preview} classes={{cardContent: classes.cardContent}}>
            <StatisticsOverview
                overviewTitle="stratosReporting.ZTNAAgentInUse.total"
                overviewValue={totalValue}
            >
                {Array.isArray(overviewData) && overviewData.map((data: any) => (
                    <StatisticsDetail
                        title={data.title}
                        value={data?.count}
                        color={data?.statusColor}
                        key={data.title}
                    />
                ))}
            </StatisticsOverview>
            {size === CardSizes.large &&
                <>
                    <Grid container direction="column" alignItems="center">
                        <Divider className={classes.dividerCards}/>
                    </Grid>
                    <Typography className={classes.titleBar}>
                        {translate("stratosReporting.ZTNAAgentInUse.tableName")}
                    </Typography>

                    <BarGrid
                        size={CardSizes.large}
                        entries={entries}
                        className={classNames(classes.barGrid, classes.noDataContainer)}
                    />
                </>
            }
        </BaseCard>
    );
};
import {
    CrudTypes,
    getArrayDataContent,
    getDataContent,
    NoResourcePage,
    Tab,
    TabbedPage,
    useCrudProps,
    useCrudSubscription,
    useGlobalParam
} from "@cuda-react/core";
import React from "react";
import ApplianceAuditLog from "../appliances/logs/ApplianceAuditLog";
import HistoryLog from "../appliances/logs/HistoryLog";
import LiveLog from "../appliances/logs/LiveLog";
import ApplianceDashboard from "../appliances/ApplianceDashboard";
import apiResources from "../../../apiResources";
import EditTVTContent from "../../../components/appliances/edit/EditTVTContent";
import EditCloudGatewayContent from "../../../components/gateways/edit/EditCloudGatewayContent";
import EditManagedGatewayContent from "../../../components/gateways/edit/EditManagedGatewayContent";


interface GatewayDetailsProps {
    match: {
        params: {
            id: string
        }
    }
}

const GatewayDetails: React.FC<GatewayDetailsProps> = ({match}) => {
    const id = match.params.id;
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [gatewayDetailsData, loading] = useCrudSubscription(CrudTypes.GET, apiResources.gateways, {id});

    const gatewayDetails = getDataContent(gatewayDetailsData, {});
    const gatewayVirtualWanId = gatewayDetails?.virtualWan?.id;
    const gatewayNotFound = !!gatewayDetailsData?.error || gatewayVirtualWanId && virtualWanId !== gatewayVirtualWanId;
    const gatewayType = gatewayDetails?.gatewayType;

    const configStatus = getArrayDataContent(useCrudProps(id && gatewayType === "on-prem" ? apiResources.configurationOnPremStatus : undefined, {id})[0]?.data);
    const activeAppliance = configStatus.find((appliance) => appliance.activeHA) || configStatus[0];
    const activeSerial = activeAppliance && activeAppliance.instanceId;

    return !gatewayNotFound ?
        <TabbedPage
            title="tesseract.network.pageTitle"
            subtitle={[
                {text: "tesseract.network.gateways", path: "/infrastructure/gateways"},
                gatewayDetailsData && gatewayDetailsData.data && gatewayDetailsData.data.name || " "
            ]}
            defaultTab={0}
        >
            <Tab
                value="#gatewaydashboard"
                label="tesseract.gateways.dashboard.tabTitle"
            >
                <ApplianceDashboard
                    details={{id, activeSerial, ...gatewayDetails, loading}}
                    type="gateways"
                />
            </Tab>
            <Tab
                value="#settings"
                label="tesseract.gateways.settings.tabTitle"
            >
                {//This check ensures our crud data has loaded, without it the cloud settings page is shown while we wait for data to load
                    gatewayType
                        ? (gatewayType === "on-prem" ?
                                <EditTVTContent id={id} type="gateway" details={gatewayDetails}/> :
                                gatewayType === "managed" ?
                                    <EditManagedGatewayContent id={id} details={gatewayDetails}/> :
                                    <EditCloudGatewayContent id={id} details={gatewayDetails}/>
                        ) : null
                }
            </Tab>
            <Tab
                value="#connectionHistoryLog"
                label="tesseract.gateways.connectionHistory.tabTitle"
            >
                <HistoryLog
                    id={id}
                    serial={activeSerial}
                    messagesType="gateways"
                    tableName="gatewayHistory"
                    resource={gatewayType && (gatewayType === "on-prem" ? apiResources.gatewayOnPremHistory : apiResources.gatewayCloudHistory)}
                    details={gatewayDetails}
                />
            </Tab>
            <Tab
                value="#liveConnectionLog"
                label="tesseract.gateways.liveLog.tabTitle"
            >
                <LiveLog
                    id={id}
                    serial={activeSerial}
                    messagesType="gateways"
                    tableName="gatewayLive"
                    resource={gatewayType && (gatewayType === "on-prem" ? apiResources.gatewayOnPremLive : apiResources.gatewayCloudLive)}
                    details={gatewayDetails}
                />
            </Tab>
            <Tab
                value="#auditLog"
                label="tesseract.gateways.auditLog.tabTitle"
            >
                <ApplianceAuditLog
                    id={id}
                    messagesType="gateways"
                    resource={apiResources.gatewayAuditLog}
                    details={gatewayDetails}
                />
            </Tab>
        </TabbedPage>
        : <NoResourcePage content="tesseract.gateways.gatewayNotFound"/>;
};


export default GatewayDetails;
import {
    ChipArrayField,
    CrudTypes,
    CustomField,
    DateField,
    getArrayDataContent,
    getDataContent,
    StatusIconField,
    TablePage,
    TextField,
    useCrudProps,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import React, {memo, useEffect, useState} from "react";
import {logsIconMap} from "../../../../components/iconMapping";
import apiResources from "../../../../apiResources";
import {isString} from "lodash";


interface HistoryLogProps {
    id: number | string,
    messagesType?: "sites" | "gateways",
    resource?: string,
    serial?: number | string,
    tableName?: string,
    details: {
        gatewayType?: "cloud" | "on-prem" | "managed",
        model?: string,
        modelSeries?: "S" | "T"
    }
}

export const createdRanges = [
    {key: "1", name: "tesseract.audit.filter.created.days1"},
    {key: "3", name: "tesseract.audit.filter.created.days3"},
    {key: "7", name: "tesseract.audit.filter.created.weeks1"},
    {key: "14", name: "tesseract.audit.filter.created.weeks2"},
    {key: "30", name: "tesseract.audit.filter.created.months1"},
    {key: "60", name: "tesseract.audit.filter.created.months2"},
    {key: "90", name: "tesseract.audit.filter.created.months3"},
    {key: "180", name: "tesseract.audit.filter.created.months6"},
    {key: "270", name: "tesseract.audit.filter.created.months9"},
    {key: "365", name: "tesseract.audit.filter.created.years1"}
];


export const HistoryLog: React.FC<HistoryLogProps> = (props) => {
    const {id, messagesType, resource, serial, tableName, details: {model, gatewayType, modelSeries}} = props;
    const [pollInterval, setPollInterval] = useState(5000);
    const connectionHistoryStates = getArrayDataContent(useCrudProps(
        gatewayType === "on-prem" ? apiResources.connectionOnPremHistoryStates : apiResources.connectionHistoryStates,
        {}, {}, true
    )[0]?.data);
    const detailsLoaded = id && (resource === apiResources.gatewayCloudHistory || serial);

    let historyUpdatedUrl = apiResources.siteConnectionHistoryUpdated;
    if (gatewayType === "on-prem") historyUpdatedUrl = apiResources.gatewayOnPremHistoryUpdated;
    else if (resource === apiResources.gatewayCloudHistory) historyUpdatedUrl = apiResources.gatewayCloudHistoryUpdated;

    const historyUpdated = getDataContent(useCrudSubscription(
        CrudTypes.GET,
        historyUpdatedUrl,
        {id},
        {
            pollInterval,
            crudOptions: {quietErrors: true},
            debounceWait: 500
        }
    )[0]);
    const emptyDataMessage = `tesseract.${messagesType}.connectionHistory.${historyUpdated?.content === null ? "waitingForConnections" : "emptyData"}`;

    useMixpanel(
        gatewayType ? "Gateways History" : "Sites History",
        !!model,
        {model: model || "", type: gatewayType || modelSeries || ""}
    );

    useEffect(() => {
        if (isString(historyUpdated)) setPollInterval(600000);
    }, [historyUpdated]);

    return (
        <TablePage
            authenticated
            tableName={tableName}
            resource={detailsLoaded ? resource : undefined}
            params={{id, serial}}
            pageMode="paginate"
            defaultSort={{field: "lastTime", dir: "desc"}}
            noDataMessage={emptyDataMessage}
            resizable
            reorderable
            editColumns
            sortable
            compact
            noStaleData
            hasTitle
            rowDetails={{
                title: "tesseract.logs.connectionHistory.detailsTitle",
                fields: [
                    <StatusIconField
                        key="state"
                        source="state"
                        label="tesseract.logs.connectionHistory.labels.state"
                        iconMap={logsIconMap}
                        column={0}
                    />,
                    <DateField
                        key="lastTime"
                        source="lastTime"
                        label="tesseract.logs.connectionHistory.labels.lastTime"
                        dateOnly={false}
                        column={0}
                    />,
                    <TextField
                        key="count"
                        source="count"
                        label="tesseract.logs.connectionHistory.labels.count"
                        column={0}
                    />,
                    <TextField
                        key="ipProtocol"
                        source="ipProtocol"
                        label="tesseract.logs.connectionHistory.labels.ipProtocol"
                        column={0}
                    />,
                    <TextField
                        key="sourceIp"
                        source="sourceIp"
                        label="tesseract.logs.connectionHistory.labels.sourceIP"
                        column={0}
                    />,
                    <TextField
                        key="sourceMac"
                        source="sourceMac"
                        label="tesseract.logs.connectionHistory.labels.sourceMac"
                        column={0}
                    />,
                    <TextField
                        key="destinationIp"
                        source="destinationIp"
                        label="tesseract.logs.connectionHistory.labels.destinationIP"
                        column={0}
                    />,
                    <CustomField
                        key="destinationPort"
                        source="destinationPort"
                        label="tesseract.logs.connectionHistory.labels.destinationPort"
                        render={(value) => value || ""}
                    />,
                    <TextField
                        key="nextHop"
                        source="nextHop"
                        label="tesseract.logs.connectionHistory.labels.nextHop"
                    />,
                    <TextField
                        key="user"
                        source="user"
                        label="tesseract.logs.connectionHistory.labels.user"
                    />,
                    <ChipArrayField
                        key="urlCategory"
                        source="urlCategory"
                        label="tesseract.logs.connectionHistory.labels.urlCategory"
                    />,
                    <ChipArrayField
                        key="application"
                        source="application"
                        label="tesseract.logs.connectionHistory.labels.application"
                    />,
                    <TextField
                        key="information"
                        source="information"
                        label="tesseract.logs.connectionHistory.labels.information"
                    />
                ]
            }}
        >
            <StatusIconField
                source="state"
                label="tesseract.logs.connectionHistory.labels.state"
                width={96}
                iconMap={logsIconMap}
                filter="select"
                filterProps={{
                    choices: connectionHistoryStates
                }}
                alwaysVisible
            />
            <DateField
                source="lastTime"
                label="tesseract.logs.connectionHistory.labels.lastTime"
                dateOnly={false}
                width={164}
                filter="select"
                filterProps={{
                    choices: createdRanges
                }}
            />
            <TextField
                source="count"
                label="tesseract.logs.connectionHistory.labels.count"
                filter="text"
                filterProps={{
                    type: "number"
                }}
                width={96}
                hideByDefault
            />
            <TextField
                source="ipProtocol"
                label="tesseract.logs.connectionHistory.labels.ipProtocol"
                width={150}
                filter="text"
            />
            <TextField
                source="sourceIp"
                label="tesseract.logs.connectionHistory.labels.sourceIP"
                width={150}
                filter="text"
            />
            <TextField
                source="sourceMac"
                label="tesseract.logs.connectionHistory.labels.sourceMac"
                width={180}
                filter="text"
                hideByDefault
            />
            <TextField
                source="destinationIp"
                label="tesseract.logs.connectionHistory.labels.destinationIP"
                width={150}
                filter="text"
            />
            <CustomField
                source="destinationPort"
                label="tesseract.logs.connectionHistory.labels.destinationPort"
                width={150}
                render={(value) => value || ""}
                filter="text"
                filterProps={{
                    type: "integer"
                }}
            />
            <TextField
                source="nextHop"
                label="tesseract.logs.connectionHistory.labels.nextHop"
                width={150}
                filter="text"
                hideByDefault
            />
            <TextField
                source="user"
                label="tesseract.logs.connectionHistory.labels.user"
                width={100}
                filter="text"
            />
            <ChipArrayField
                source="urlCategory"
                label="tesseract.logs.connectionHistory.labels.urlCategory"
                filter="text"
                width={140}
            />
            <ChipArrayField
                source="application"
                label="tesseract.logs.connectionHistory.labels.application"
                width={130}
                filter="text"
            />
            <TextField
                source="information"
                label="tesseract.logs.connectionHistory.labels.information"
                width={140}
                filter="text"
            />
        </TablePage>
    );
};


export default memo(HistoryLog);
import React, {useRef, useState} from "react";
import {
    ActionButtonsField,
    BooleanField,
    ChipArrayField,
    ConnectedTableRefresh,
    DeleteDialog,
    EmptyPageContent,
    StatusIconField,
    TablePage,
    TextField,
    useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import CreateFirewallTokenDialog from "./CreateFirewallTokenDialog";
import {FirewallGraphic, GetStartedBackgroundGraphic} from "@cuda-react/icons";

const FirewallsTable = () => {
    const registerAction = useMixpanel("Firewalls");
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);
    const [actionVisible, setActionVisible] = useState(false);

    return (
        <TablePage
            titleActions={actionVisible &&
                <CreateFirewallTokenDialog/>
            }
            authenticated
            resource={apiResources.firewall}
            title="tesseract.integration.pageTitle"
            subtitle="tesseract.integration.firewalls"
            minCellWidth={90}
            tableName="Firewalls"
            resetOnGlobalParamChange
            refreshRef={tableRefreshRef}
            resizable
            reorderable
            editColumns
            onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
            noDataPage={
                <EmptyPageContent
                    background={<GetStartedBackgroundGraphic/>}
                    icon={<FirewallGraphic/>}
                    iconHeader="tesseract.firewalls.noData.iconHeader"
                    textHeader="tesseract.firewalls.noData.textHeader"
                    text="tesseract.firewalls.noData.text"
                    actions={<CreateFirewallTokenDialog/>}
                />
            }
        >
            <StatusIconField
                source="aggregateState"
                alwaysVisible
                width={90}
            />
            <TextField
                source="name"
                label="tesseract.firewalls.table.name"
                filter="text"
                sortable
            />
            <ChipArrayField
                source="serials"
                label="tesseract.firewalls.table.serials"
                filter="text"
            />
            <BooleanField
                source="ccManaged"
                label="tesseract.firewalls.table.isCcManaged"
                width={120}
                sortable
            />
            <TextField
                source="model"
                label="tesseract.firewalls.table.model"
                filter="text"
                sortable
            />
            <ActionButtonsField width={96} source="id" alwaysVisible>
                <DeleteDialog
                    disable={(data) => !data || data?.ztEnabled}
                    resource={apiResources.firewall}
                    title="tesseract.firewalls.delete.title"
                    message="tesseract.firewalls.delete.body"
                    disabledMessage="tesseract.firewalls.delete.forbidden"
                    onSuccess={() => {
                        registerAction("Delete CloudGen Firewall");
                        tableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default FirewallsTable;
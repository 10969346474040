import EventSuccessIcon from "./EventSuccessIcon";
import EventFailedIcon from "./EventFailedIcon";
import EventAllIcon from "./EventAllIcon";
import {Critical, Success} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import {Help} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React from "react";
import {Theme} from "@mui/material";

export const NotificationStatusIconMap = {
    successful: <EventSuccessIcon/>,
    failed: <EventFailedIcon/>,
    all: <EventAllIcon/>,
    default: <Help/>
};

export const getNotificationStatusColorFromTheme = (status: string, theme: Theme) => {
    if (status === "successful") {
        return theme.palette.success.main;
    }
    if (status === "failed") {
        return theme.palette.error.main;
    }
    if (status === "all") {
        return theme.palette.success.main;
    }
    return theme.palette.info.main;
};

export const AuditStatusIconMap = {
    successful: <Success sx={{color: "success.main"}}/>,
    failed: <Critical sx={{color: "error.main"}}/>,
    default: <Help sx={{color: "info.main"}}/>
};

export const getAuditStatusColorFromTheme = (status: string, theme: Theme) => {
    if (status === "successful") {
        return theme.palette.success.main;
    }
    if (status === "failed") {
        return theme.palette.error.main;
    }
    return theme.palette.info.main;
};
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {Button, CircularProgress, Container, Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {CrudTypes, DialogBody, getDataContent, Toolbar, useCrudFetch, useCrudSubscription} from "@cuda-react/core";
import apiResources from "../../../apiResources";

const styles = createStyles({
    body: {
        textAlign: "center",
        padding: 16
    }
});
const useStylesContent = makeOverrideableStyles("RestartDialogContent", styles);

export interface RestartIpsecTunnelProps {
    resourceName: string,
    fullWidth?: boolean,
    onSuccess: () => void,
    onClose: () => void,
    resourceId: string
}

export const RestartIpsecTunnel = (props: RestartIpsecTunnelProps) => {
    const {resourceName, onSuccess, onClose, resourceId} = props;
    const classes = useStylesContent(props);
    const [translate] = useTranslation();
    const [, restarting, performRestart] = useCrudFetch(CrudTypes.CREATE, apiResources.restartIpsecV2Tunnel, {id: resourceId});
    const canRestart = Boolean(getDataContent(useCrudSubscription(
        CrudTypes.GET,
        apiResources.restartIpsecV2TunnelAvailable,
        {id: resourceId},
        {pollInterval: 30000}
    )[0].data));

    return (
        <React.Fragment>
            <DialogBody
                title={translate("tesseract.integration.ipSecV2.dialog.restart.title", {name: resourceName})}
            >
                <Container className={classes.body}>
                    <Typography variant="body1">
                        {!canRestart ? translate("tesseract.integration.ipSecV2.dialog.restart.message") : translate("tesseract.integration.ipSecV2.dialog.restart.body")}
                    </Typography>
                </Container>
            </DialogBody>
            <Toolbar>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!canRestart}
                    startIcon={(!canRestart ? (
                        <CircularProgress id="cuda-loading-spinner" size={16} thickness={2}/>
                    ) : undefined)}
                    onClick={() => performRestart({id: resourceId}).then((result) => {
                            if (!result.error) {
                                onSuccess?.();
                                onClose?.();
                            } else {
                                onClose?.();
                            }
                        })}
                >
                    {translate("tesseract.integration.ipSecV2.dialog.restart.ok")}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                >
                    {translate("tesseract.integration.ipSecV2.dialog.restart.cancel")}
                </Button>
            </Toolbar>
        </React.Fragment>
    );
};

RestartIpsecTunnel.defaultProps = {};

export default RestartIpsecTunnel;
import React from "react";
import MalwareProtection from "./tabs/MalwareProtection/MalwareProtection";
import IPS from "./tabs/IPS/IPS";
import SslInterception from "./tabs/SslInterception/SslInterception";
import {Route, Switch} from "react-router";

const Security = () => (
    <Switch>
        <Route path="/policies/security/malware">
            <MalwareProtection/>
        </Route>
        <Route path="/policies/security/ssl">
            <SslInterception />
        </Route>
        <Route path="/policies/security/ips">
            <IPS />
        </Route>
    </Switch>
);

export default Security;
import React from 'react';
import {RadioButtonGroupInput} from "@cuda-react/core";

const CreateTypeStep = () => (
    <RadioButtonGroupInput
        source="createMode"
        choices={[
            {key: "new", name: "tesseract.appliances.dialog.createType.new"},
            {key: "promote", name: "tesseract.appliances.dialog.createType.promote"}
        ]}
    />
);

export default CreateTypeStep;
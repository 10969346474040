import {useEffect} from "react";
import {usePostCustomEvent} from "./CustomEventHooks";
import {MixpanelEvent, MixpanelEventKey} from "../components/providers/AnalyticsProvider/AnalyticsProvider";

const PAGE_VIEW_EVENT = "Pageview";

/**
 * Enables tracking analytics on the current page
 *
 * @example Simple page view
 * // tracks the event "<PREFIX> Dashboard Pageview" when the page loads
 * useMixpanel("Dashboard");
 *
 * @example Page view with additional detail
 * // tracks the event "<PREFIX> Dashboard Pageview" when the page loads, passing the additional details and waiting for model data to be present
 * useMixpanel("Dashboard", !!model, {model});
 *
 * @example Custom onLoad event name w/additional manual trigger
 * // tracks the event "<PREFIX> Create Site Start" when the page loads
 * const registerAction = useMixpanel("Create Site", "Start", {type: "S"});
 * // tracks another event "<PREFIX> Create Site Success" when called, along with the additional data
 * registerAction("Success", {lans: 5})
 */
export const useMixpanel = (
    /**
     * Name of the page being viewed
     */
    pageName: string,
    /**
     * Name of the default action to register when the component is first rendered.
     * if false it will not register on initialisation,
     * if true it will use the default page view event.
     *
     * this allows you to wait for a condition to be true before tracking an event,
     * such as waiting for additionalDetails to load,
     * by changing from false to true when it's ready
     */
    onLoadAction: boolean | string = PAGE_VIEW_EVENT,
    /**
     * extra data to record with the on load action
     */
    additionalDetails: Record<string, string | number | boolean> = {}
) => {
    const postEvent = usePostCustomEvent<MixpanelEvent>(MixpanelEventKey);

    const registerAction = (
        /**
         * Name of the action to register
         */
        action: string,
        /**
         * additional details for this action, merged with the additionalDetails provided to the hook
         */
        additionalDetails: Record<string, string | number | boolean> = {}
    ) => postEvent({action: `${pageName} ${action}`, additionalDetails});

    useEffect(() => {
        if (onLoadAction) {
            registerAction(
                onLoadAction === true ? PAGE_VIEW_EVENT : onLoadAction,
                additionalDetails
            );
        }
    }, [onLoadAction]);

    return registerAction;
};
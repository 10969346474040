import {StyledComponentProps} from "@cuda-react/theme";
import React, {ReactChild} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Box, Button, CircularProgress, Divider, Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";
import {Step, ZtnaSetupWizardGraphic} from "@cuda-react/icons";
import {BasicPage, FormButtonToolbar, VerticalStepperCardContent, VerticalStepperCardSizes} from "@cuda-react/core";
import EmailTemplate from "../endpoint/users/enroll/EmailTemplate";
import {Steps} from "../../hooks/StateWizardHooks";
import {Success} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import {useHistory} from "react-router-dom";
import {Theme} from "@mui/material";

interface WizardPageContentBaseProps {
    textHeader?: string,
    textSubHeader?: string,
    onNext?: () => void,
    mainText?: string,
    extraText?: string,
    children?: ReactChild | ReactChild[],
    wizardStates: {
        [Steps.step1]: Step,
        [Steps.step2]: Step,
        [Steps.step3]: Step,
        [Steps.step4]: Step,
        [Steps.step5]: Step,
        loading: boolean
    },
    activeStep: number,
    introductionPage?: boolean
    done?: boolean,
    disabled?: boolean,
    registerStepAction: (action: string, additionalData?: any) => void
}

const styles = (theme: Theme) => createStyles<string, WizardPageContentBaseProps>({
    formToolbarButton: {
        padding: 0
    },
    textHeader: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "left"
    },
    textSubHeader: {
        fontSize: 12,
        color: "#646464",
        textAlign: "left"
    },
    titleText: {
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: "24px"
    },
    text: {
        textAlign: "left",
        marginBottom: 16
    },
    introGraphic: {
        "& > *": {
            height: 270,
            width: 656,
            marginTop: 24,
            marginBottom: 24,
            marginLeft: "-10px"
        }
    },
    basicInfrastructureGraphic: {
        "& > *": {
            height: 274,
            width: 656,
            marginTop: 24,
            marginBottom: 24
        }
    },
    emailPreview: {
        transform: "scale(0.7) translateY(-17%) translateX(-12%)",
        height: 580
    },
    pageRoot: {
        height: "100%"
    },
    content: {
        margin: 0,
        borderTop: `1px solid ${theme.palette.divider}`,
        height: "calc(100% - 60px)",
        overflowX: "auto"
    }
});
const useStyles = makeStyles(styles);

export const getStepIcon = (state: string, size?: string) => {
    if (state === "Completed") {
        return (
            <Success
                id="step-completed-state-icon"
                sx={(theme: any) => size === "large" ? {
                    color: theme.palette.success.main,
                    width: "30px",
                    height: "30px",
                    marginLeft: "-2px"
                } : {
                    color: theme.palette.success.main,
                    width: "22px",
                    height: "22px",
                    marginLeft: "-2px"
                }}
            />
        );
    } else if (state === "Pending") {
        return <CircularProgress size={size === "large" ? 25 : 20}/>;
    }
    return undefined;
};


export interface WizardPageProps extends StyledComponentProps<typeof styles>, WizardPageContentBaseProps {
}

const WizardPage = (props: WizardPageProps) => {
    const {
        textHeader,
        textSubHeader,
        mainText,
        extraText,
        children,
        onNext,
        wizardStates,
        activeStep,
        introductionPage,
        done,
        disabled,
        registerStepAction
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const {push} = useHistory();

    const wizardSteps = ([Steps.step1, Steps.step2, Steps.step3, Steps.step4, Steps.step5] as const).map((step) => ({
        label: "tesseract.dashboard.cards.ztnaSetup." + step,
        state: wizardStates[step].state,
        icon: getStepIcon(wizardStates[step].state, "large")
    }));

    return (
        <BasicPage
            title="tesseract.setupWizard.pageTitle"
            actions={[
                <Button
                    key="exitSetup"
                    variant={done ? "contained" : "text"}
                    color="primary"
                    size="small"
                    onClick={() => {
                        registerStepAction(done ? "Done" : "Exit");
                        push("/");
                    }}
                >
                    {translate(`tesseract.setupWizard.buttons.${done ? "done" : "exitSetup"}`)}
                </Button>,
                !done && onNext ? (
                    <Button
                        key="setupButton"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={onNext}
                        disabled={disabled}
                    >
                        {translate(`tesseract.setupWizard.buttons.${introductionPage ? "setup" : "next"}`)}
                    </Button>
                ) : !done ? (
                    <FormButtonToolbar
                        key="toolbar"
                        buttonsOnly
                        label="tesseract.setupWizard.buttons.next"
                        classes={{buttonContainer: classes.formToolbarButton}}
                    />
                ) : null
            ]}
            // @ts-ignore prop does exist
            classes={{
                root: classes.pageRoot,
                content: classes.content
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                    marginRight: "auto",
                    minWidth: 990,
                    padding: "32px 16px",
                    justifyContent: "center",
                    alignContent: "center",
                    overflowY: "auto",
                    maxHeight: "100%"
                }}
            >
                {(!introductionPage && wizardStates) ? (
                    <Box
                        sx={(theme) => ({
                            width: 224,
                            height: "290px",
                            [theme.breakpoints.up(1077)]: {
                                position: "fixed",
                                left: "calc(50% - 450px)"
                            }
                        })}
                    >
                        <VerticalStepperCardContent
                            steps={wizardSteps}
                            size={VerticalStepperCardSizes.large}
                            activeStep={activeStep}
                            loading={wizardStates.loading}
                        />
                    </Box>
                ) : null}
                <Box sx={(theme) => ({
                    padding: 1.5,
                    minWidth: 716,
                    maxWidth: 716,
                    [theme.breakpoints.up(1077)]: {
                        marginLeft: !introductionPage ? "230px" : undefined,
                    },
                    display: "flex",
                    flexDirection: "row"
                })}>
                    <Box sx={{display: "flex", flexDirection: "row", paddingBottom: 2, height: "fit-content"}}>
                        {(!introductionPage && wizardStates) ? (
                            <Box sx={{paddingLeft: "32px", paddingRight: "24px", paddingBottom: 1}}>
                                <Divider orientation="vertical" sx={{paddingTop: 1.5}}/>
                            </Box>
                        ) : null}
                        <Box sx={{display: "flex", flexDirection: "column", width: 656}}>
                            <Box>
                                <Typography component="span" className={classes.textHeader}>
                                    <Trans i18nKey={textHeader}/>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography component="span" className={classes.textSubHeader}>
                                    <Trans i18nKey={textSubHeader}/>
                                </Typography>
                            </Box>
                            {introductionPage ?
                                <Box className={classes.introGraphic}>
                                    <img
                                        src="/images/introductionGraphic.svg"
                                        alt={translate("tesseract.setupWizard.wizardIntroductionPage.graphic")}
                                    />
                                </Box> :
                                <Box className={classes.basicInfrastructureGraphic}>
                                    <ZtnaSetupWizardGraphic
                                        steps={wizardStates}
                                        activeStep={activeStep + 1}
                                    />
                                </Box>
                            }
                            {mainText ? (
                                <Box className={classes.text}>
                                    <Trans i18nKey={mainText}>
                                        <Typography component="h3" className={classes.titleText}/>
                                        <Typography component="p" className={classes.text}>
                                            <ul>
                                                <li/>
                                            </ul>
                                        </Typography>
                                    </Trans>
                                </Box>
                            ) : null}
                            <Box className={classes.mainComponent}>
                                {children}
                            </Box>
                            {extraText && (
                                <Box className={classes.text}>
                                    <Typography component="span" className={classes.text}>
                                        <Trans i18nKey={extraText}>
                                            <br/>
                                        </Trans>
                                    </Typography>
                                    <div className={classes.emailPreview}>
                                        <EmailTemplate username="John Doe"/>
                                    </div>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BasicPage>
    );
};

export default WizardPage;
import React from "react";
import {
    BooleanInput,
    getArrayDataContent,
    SelectInput,
    TextInput,
    useCrudProps,
    usePreview,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";

const SdwanConnectorGeneralStep = () => {
    const [{data: gatewaysData}] = useCrudProps(apiResources.gatewayNames);
    const secPreview = usePreview("sec2");

    return (
        <React.Fragment>
            <TextInput
                source="name"
                label="tesseract.network.sdwanConnector.dialog.general.name"
                description="tesseract.network.sdwanConnector.dialog.description.general.name"
                isRequired
                validate={[validateMaxLengthMemo(32), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
            />
            <TextInput
                source="description"
                label="tesseract.network.sdwanConnector.dialog.general.description"
                description="tesseract.network.sdwanConnector.dialog.description.general.description"
                validate={[validateMaxLengthMemo(255)]}
            />
            <SelectInput
                source="configurationUuid"
                label="tesseract.network.sdwanConnector.dialog.general.appliance"
                description="tesseract.network.sdwanConnector.dialog.description.general.appliance"
                optionValue="uuid"
                choices={getArrayDataContent(gatewaysData)}
                isRequired
            />
            {secPreview ? (
                <BooleanInput
                    source="routedMode"
                    label="tesseract.network.sdwanConnector.dialog.general.routedMode"
                    description="tesseract.network.sdwanConnector.dialog.description.general.routedMode"
                />
            ) : null}
        </React.Fragment>
    );
};

SdwanConnectorGeneralStep.propTypes = {};

export default SdwanConnectorGeneralStep;
import React from "react";
import {useDeepCompareEffect, usePrevious} from "@cuda-react/core";
import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import WanTableInput from "../../inputs/wanTableInput/WanTableInput";
import {Typography} from "@barracuda-internal/bds-core";
import {useFormContext, useWatch} from "react-hook-form";

interface PortsData {
    port: string;
    available: boolean;
}

interface Wan {
    mode: string;
    serial: string;
}

interface WansTabProps {
    applianceType?: "site" | "gateway";
    portsData: PortsData[];
    series?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    tableWarning: {
        color: theme.palette.warning.main,
        marginLeft: theme.spacing(2),
    },
}));
const WansTab: React.FC<WansTabProps> = ({applianceType, portsData, series}) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const {setValue} = useFormContext();
    const [serials = [], wans = [] as Wan[]] = useWatch({name: ["serials", "wans"]});
    const tableRowDimmed = (row: any) => {
        if (!(row.port || row.doublePort) || row.mode === "wwan") {
            return false;
        }
        let applianceAvailable: any[] = [];
        portsData.forEach((applianceInterface) => {
            if (row.mode === "expressRoute" ? row.doublePort.some((port: any) => port === applianceInterface.port) : applianceInterface.port === row.port) {
                applianceAvailable.push(applianceInterface.available);
            }
        });
        return !applianceAvailable.length || !applianceAvailable.every((available) => available);
    };
    const wanHasPortDisabled = wans.some(tableRowDimmed);
    const oldSerials = usePrevious<string[]>(serials);
    useDeepCompareEffect(() => {
        const removedSerial = oldSerials?.filter((serial: any) => !serials.includes(serial));
        const newSerial = oldSerials && serials && serials.filter((serial: any) => !oldSerials.includes(serial));
        wans.forEach((wan: any, wanIndex: any) => {
            if (wan.mode === "wwan" && removedSerial && newSerial) {
                if (removedSerial.length === newSerial.length) {
                    wans[wanIndex].pins.forEach((pin: any, pinIndex: any) => {
                        pin.serial === removedSerial[0] && setValue(`wans[${wanIndex}].pins[${pinIndex}].serial`, newSerial[0]);
                    });
                } else if (oldSerials?.length === 2 && newSerial.length < removedSerial.length) {
                    wans[wanIndex].pins.forEach((pin: any, pinIndex: any) => {
                        pin.serial === removedSerial[0] &&
                        wans[wanIndex].pins.splice(pinIndex, 1);
                    });
                } else if (oldSerials?.length === 1 && newSerial.length > removedSerial.length && newSerial.length === 1) {
                    setValue(`wans[${wanIndex}].pins[1].serial`, newSerial[0]);
                }
            }
        });
    }, [serials]);
    return (
        <React.Fragment>
            <WanTableInput
                serials={serials}
                tableRowDimmed={tableRowDimmed}
                portsData={portsData}
                applianceType={applianceType}
                series={series}
            />
            {wanHasPortDisabled && (
                <Typography className={classes.tableWarning}>
                    {translate("tesseract.appliances.settings.wan.portWarning")}
                </Typography>
            )}
        </React.Fragment>
    );
};
export default WansTab;
import {ButtonDialog} from "@cuda-react/core";
import React from "react";
import CreateFirewallTokenContent from "./CreateFirewallTokenContent";


interface CreateFirewallTokenDialogProps {
    onSuccess?: () => void;
}

const CreateFirewallTokenDialog: React.FC<CreateFirewallTokenDialogProps> = ({onSuccess}) => (
    <ButtonDialog buttonText="tesseract.firewalls.dialog.create.buttonText">
        <CreateFirewallTokenContent/>
    </ButtonDialog>
);

export default CreateFirewallTokenDialog;
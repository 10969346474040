import {HiddenInput, SelectInput, TextInput} from "@cuda-react/core";
import React from "react";
import {useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";
import {get} from "lodash";

interface SCWanTableInputFormProps {
    rowIndex?: number,
    wanTypes?: any,
    serial?: string,
    wansLength?: number
}

const SCWanTableInputForm: React.FC<SCWanTableInputFormProps> = ({rowIndex, wanTypes, wansLength, serial}) => {
    const wirelessTypes = wanTypes.filter((type: any) => get(type, "key") === "wwan");
    const wiredTypes = wanTypes.filter((type: any) => get(type, "key") !== "wwan");

    const inputValidations = useSiteInputValidations();
    // there is no row index, so we must be creating a new wan, not editing an existing one
    const forcedWwan = rowIndex === undefined;

    let modeChoices = wanTypes;
    if (forcedWwan) {
        modeChoices = wirelessTypes;
    } else if (wansLength === 2) {
        // we are editing a wan and there are 2, so the first one must be wired and the second wireless(wwan)
        modeChoices = rowIndex === 1 ? wirelessTypes : wiredTypes;
    }

    return (
        <>
            <TextInput
                source="name"
                label="tesseract.sites.settings.wan.name"
                description="tesseract.sites.settings.wan.descriptions.name"
                validate={inputValidations.validateName}
                isRequired
            />
            <SelectInput
                source="mode"
                label="tesseract.sites.settings.wan.type"
                description="tesseract.sites.settings.wan.descriptions.type"
                disabled={forcedWwan}
                choices={modeChoices}
            />
            {forcedWwan ? (
                <HiddenInput
                    source="mode"
                    hiddenValue="wwan"
                />
            ) : null}
            <HiddenInput
                source="port"
                hiddenValue="wan"
            />
            <TextInput
                source="address"
                label="tesseract.sites.settings.wan.address"
                hide={(value, data) => !(data && data.mode === "static")}
                description="tesseract.sites.settings.wan.descriptions.address"
                validate={inputValidations.validateStaticAddress}
                isRequired
            />
            <TextInput
                source="netmask"
                label="tesseract.sites.settings.wan.netmask"
                hide={(value, data) => !(data && data.mode === "static")}
                description="tesseract.sites.settings.wan.descriptions.netmask"
                validate={inputValidations.validateNetmask}
                isRequired
            />
            <TextInput
                source="gateway"
                label="tesseract.sites.settings.wan.gateway"
                description="tesseract.sites.settings.wan.descriptions.gateway"
                hide={(value, data) => !(data && data.mode === "static")}
                validate={inputValidations.validateIp}
                isRequired
            />
            <HiddenInput
                source="pin.serial"
                hiddenValue={serial}
            />,
            <TextInput
                source="pin.pin"
                label="tesseract.sites.settings.wan.scPin"
                hide={(value, data) => !(data && data.mode === "wwan")}
                description="tesseract.sites.settings.wan.descriptions.pin"
                validate={inputValidations.validatePin}
            />
        </>
    );
};


export default SCWanTableInputForm;
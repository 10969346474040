import React from "react";
import WizardPage, {WizardPageProps} from "../WizardPage";

interface WizardCompleteProps extends Pick<WizardPageProps, "wizardStates" | "registerStepAction"> {
};

const WizardComplete = (props: WizardCompleteProps) => (
    <WizardPage
        textHeader="tesseract.setupWizard.wizardComplete.textHeader"
        textSubHeader="tesseract.setupWizard.wizardComplete.textSubHeader"
        mainText="tesseract.setupWizard.wizardComplete.mainText"
        activeStep={-1}
        done
        {...props}
    />
);

export default WizardComplete;
import React from "react";
import {CardSizes, DashboardCardProps, RecentEventsCard, useCardFetch} from "@stratos/reporting";
import {generateQueryString} from "@cuda-react/core";
import {useHistory} from "react-router";

export interface RecentSystemEventsProps extends DashboardCardProps {}

export const previewData = {
    content: [
        {
            created: "2023-02-27T05: 55: 28.351365Z",
            event: "updateSetting",
            eventName: "Update Setting",
            type: "system",
            typeName: "System Action",
            status: "successful",
            statusName: "Successful",
            description: "Updated setting 'Point to Site VPN' from 'No' to 'Yes'.",
            remoteAddress: "54.161.91.20"
        },
        {
            created: "2023-02-27T05: 55: 23.654988Z",
            event: "updateSetting",
            eventName: "Update Setting",
            type: "system",
            typeName: "System Action",
            status: "successful",
            statusName: "Successful",
            description: "Updated setting 'Point to Site VPN' from 'No' to 'Yes'.",
            remoteAddress: "54.161.91.20"
        },
        {
            created: "2023-02-27T05: 55: 11.154232Z",
            event: "updateSetting",
            eventName: "Update Setting",
            type: "system",
            typeName: "System Action",
            status: "successful",
            statusName: "Successful",
            description: "Updated setting 'Hotfix Update Window' from '' to '00: 00 - 02: 00'.",
            remoteAddress: "54.161.91.20"
        },
        {
            created: "2023-02-27T05: 55: 11.154082Z",
            event: "updateSetting",
            eventName: "Update Setting",
            type: "system",
            typeName: "System Action",
            status: "successful",
            statusName: "Successful",
            description: "Updated setting 'Hotfix Update Window' from '' to '00: 00 - 02: 00'.",
            remoteAddress: "54.161.91.20"
        },
        {
            created: "2023-02-27T05: 55: 09.348291Z",
            event: "updateSetting",
            eventName: "Update Setting",
            type: "system",
            typeName: "System Action",
            status: "successful",
            statusName: "Successful",
            description: "Updated setting 'Default Explicit SSL Inspection Action' from '' to 'Inspect'.",
            remoteAddress: "54.161.91.20"
        }
    ],
    page: {totalElements: 5}
};

export const RecentSystemEvents = (props: RecentSystemEventsProps) => {
    const {size, preview} = props;
    const {data = {}, loading} = useCardFetch({
        url: !preview ? "/api/v1/webui/{userData.currentAccount}/audit/entries" : undefined,
        params: {
            pagination: {page: 1, perPage: size === CardSizes.large ? 10 : 5},
            sort: {field: "created", order: "DESC"},
            filter: {type: "system"}
        },
        formatData: (data: any) => data
    });
    const {push} = useHistory();
    const systemEvents = preview ? previewData : data;
    const auditLogTypeLinks = (entryType: any) => `/logs/audit${generateQueryString({type: [entryType]}, 'created', 'desc')}`;

    return (
        <RecentEventsCard
            title="tesseract.dashboard.cards.recentEvents.systemTabTitle"
            size={size}
            preview={preview}
            entries={systemEvents.content || []}
            total={systemEvents?.page?.totalElements}
            footerKey="tesseract.dashboard.cards.recentEvents.latest"
            loading={!systemEvents.page && loading}
            onEventClick={(event: any) => push(auditLogTypeLinks(event.type))}
        />
    );
};
import {Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    root: {},
    content: {
        padding: theme.spacing(2)
    },
    stepTitle: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        fontSize: 18,
        fontWeight: 500
    }
});
const useStyles = makeOverrideableStyles("StepContent", styles);

export interface StepContentProps extends StyledComponentProps<typeof styles> {
    /**
     * the children to render with the main content div.
     */
    children?: React.ReactElement | (React.ReactElement | null)[] | null,
    /**
     * additional props to pass down to all children.
     */
    childProps?: any,
    /**
     * title text to display at the top of the content area.
     */
    title?: string
}


/**
 * Formats the content for a wizard step. Rendered by wizard *Step components.
 */
export const StepContent = (props: StepContentProps) => {
    const {children, childProps, title} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <div className={classes.root}>
            {title && (
                <Typography className={classes.stepTitle}>
                    {translate(title)}
                </Typography>
            )}
            <div className={classes.content}>
                {childProps && React.Children.map(children, (child) => child && React.cloneElement(child, childProps))
                || children}
            </div>
        </div>
    );
};

export default StepContent;
import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const GetStartedBackgroundGraphic = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-getstarted"
            viewBox="0 0 128 128"
        >
            <path style={{fill: theme.palette.general?.purple, strokeWidth: 0}} d="M96.9,57.7l1.9-.8-.6,1.9.8,1.9-2-.6-1.9.8.6-1.9-.8-1.9,2,.6Z"/>
            <path style={{fill: theme.palette.neutral?.main, strokeWidth: 0}} d="M98.1,62.9l-.4-.8-.4.8-.8.4.8.4.4.8.4-.8.8-.4-.8-.4Z"/>
            <path className="cls-6" d="M93.2,58.1l-.4-.8-.4.8-.8.4.8.4.4.8.4-.8.8-.4-.8-.4Z"/>
            <path style={{fill: theme.palette.neutral?.main, strokeWidth: 0, opacity: 0.5, isolation: "isolate"}}
                  d="M77.6,107.6c-.9,0-1.6-.3-2.3-.9-.6-.6-.9-1.4-.9-2.2s.2-1.4.7-2c.5-.6,1.1-1,1.8-1.1.2-.9.7-1.6,1.5-2.2.7-.6,1.6-.8,2.5-.8s2.1.4,2.9,1.2,1.2,1.8,1.2,2.9c.7,0,1.2.4,1.7.9s.7,1.1.7,1.8-.3,1.4-.8,1.9-1.1.8-1.9.8h-7Z"/>
            <path style={{fill: theme.palette.neutral?.main, strokeWidth: 0, opacity: 0.2, isolation: "isolate"}}
                  d="M59.5,121.3c-1,0-1.9-.4-2.6-1.1-.7-.7-1.1-1.6-1.1-2.6s.3-1.6.8-2.3c.5-.7,1.2-1.1,2.1-1.3.3-1,.8-1.9,1.7-2.5.8-.6,1.8-1,2.8-1s2.4.5,3.3,1.4,1.4,2,1.4,3.3c.8,0,1.4.4,1.9,1,.5.6.8,1.2.8,2s-.3,1.5-.9,2.1-1.3.9-2.1.9h-8Z"/>
            <path style={{fill: theme.palette.neutral?.main, strokeWidth: 0, opacity: 0.2, isolation: "isolate"}}
                  d="M35.3,121.3c-.7,0-1.4-.3-1.9-.8-.5-.5-.8-1.1-.8-1.9s.2-1.2.6-1.7c.4-.5.9-.8,1.5-.9.2-.7.6-1.3,1.2-1.8s1.3-.7,2-.7,1.7.3,2.4,1c.7.7,1,1.5,1,2.4.6,0,1,.3,1.4.7s.5.9.5,1.5-.2,1.1-.6,1.5-.9.6-1.5.6h-5.8Z"/>
            <path style={{fill: theme.palette.neutral?.main, strokeWidth: 0, opacity: 0.5, isolation: "isolate"}}
                  d="M22.4,126.4c-1.5,0-2.8-.5-3.8-1.5-1.1-1-1.6-2.3-1.6-3.8s.4-2.4,1.2-3.4c.8-1,1.8-1.6,3-1.9.4-1.5,1.2-2.7,2.5-3.7,1.2-.9,2.6-1.4,4.2-1.4s3.5.7,4.9,2c1.3,1.3,2,3,2,4.9,1.1.1,2.1.6,2.8,1.5.7.8,1.1,1.8,1.1,3s-.4,2.3-1.3,3.1c-.9.9-1.9,1.3-3.1,1.3h-11.8Z"/>
            <path style={{fill: theme.palette.neutral?.main, strokeWidth: 0, opacity: 0.5, isolation: "isolate"}}
                  d="M101.6,118.6c-2,0-3.6-.7-5-2s-2.1-3-2.1-5,.5-3.2,1.5-4.5c1-1.3,2.3-2.1,4-2.5.5-2,1.6-3.6,3.2-4.8s3.4-1.8,5.5-1.8,4.6.9,6.4,2.6c1.8,1.8,2.6,3.9,2.6,6.4,1.5.2,2.7.8,3.7,1.9,1,1.1,1.5,2.4,1.5,3.9s-.6,3-1.7,4.1-2.5,1.7-4.1,1.7h-15.5Z"/>
            <path style={{fill: theme.palette.neutral?.main, strokeWidth: 0, opacity: 0.8, isolation: "isolate"}}
                  d="M77.5,126.5c-2.8,0-5.1-1-7.1-2.9-2-1.9-3-4.3-3-7s.7-4.5,2.2-6.4c1.4-1.9,3.3-3.1,5.6-3.6.8-2.8,2.3-5.1,4.6-6.8,2.3-1.7,4.9-2.6,7.8-2.6s6.6,1.2,9.1,3.7c2.5,2.5,3.7,5.5,3.7,9.1,2.1.2,3.9,1.2,5.2,2.7,1.4,1.6,2.1,3.4,2.1,5.5s-.8,4.2-2.4,5.8c-1.6,1.6-3.5,2.4-5.8,2.4h-22Z"/>
            <g>
                <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                      d="M110.6,42.3c-.8,0-1.5.3-2.1.8-.6.6-.8,1.2-.8,2.1s.3,1.5.8,2.1,1.2.8,2.1.8,1.5-.3,2.1-.8.8-1.2.8-2.1-.3-1.5-.8-2.1c-.6-.6-1.2-.8-2.1-.8Z"/>
                <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                      d="M121.3,34.5c-3.1-.4-6.1-.1-9,1-3,1.1-5.8,3.1-8.5,5.8h-.1c0,.1-4.1-.7-4.1-.7-.5,0-1,0-1.4,0-.5.1-.9.4-1.2.7l-6.3,6.4,7.5,3.2,6.8,6.9,3.2,7.4,6.4-6.4c.3-.3.6-.7.7-1.2.1-.5.2-.9,0-1.4l-.8-4.1h.1c2.7-2.8,4.6-5.7,5.7-8.7,1.1-2.9,1.4-5.9.9-9ZM99,48.1v.2c-.1,0-3.8-1.6-3.8-1.6l3.7-3.7,2.8.5-.2.3c-.5.7-.9,1.4-1.3,2-.4.7-.8,1.4-1.1,2.1ZM109,60.6l-1.6-3.7h.2c.7-.5,1.4-.9,2.1-1.2.7-.4,1.4-.8,2-1.3l.3-.2.5,2.8-3.7,3.7ZM117.5,43.5c-1.1,2.3-2.5,4.4-4.4,6.2-1.1,1.1-2.3,2.1-3.6,3-1.3.9-2.5,1.5-3.6,2h-.2c0,0-4.8-4.7-4.8-4.7v-.2c.5-1.1,1.2-2.3,2-3.6.9-1.3,1.9-2.5,3-3.6,1.9-1.9,4-3.3,6.3-4.4,2.3-1.1,4.4-1.5,6.4-1.4h.2s0,.2,0,.2c.1,1.9-.3,4.1-1.4,6.4Z"/>
            </g>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} d="M82.5,82.1c-.3,1.5-.4,2.7-.5,3.1l1.6.2c0-.5.2-1.6.5-3l-1.6-.3Z"/>
            <g>
                <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} d="M88.4,68.2c-.8,1.2-1.6,2.4-2.3,3.6l1.4.8c.7-1.2,1.4-2.3,2.2-3.4l-1.3-1Z"/>
                <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} d="M84.6,74.8c-.5,1.2-1,2.6-1.4,4l1.6.4c.4-1.4.8-2.6,1.3-3.8l-1.5-.6Z"/>
            </g>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} d="M92.6,63.1c-.3.4-1.1,1.2-2.1,2.4l1.3,1c.9-1.1,1.7-1.9,2-2.2l-1.1-1.2Z"/>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M83.6,88.6c-.2-.1-.4-.2-.7-.2s-.2,0-.2,0c-.3,0-.6.2-.8.5-.2.3-.2.6-.2.9l.6,3.2c.1.6.7,1.1,1.4.9.6-.1,1.1-.7.9-1.4l-.6-3.2c0-.3-.2-.6-.5-.8Z"/>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M79.6,94.4c0-.3-.2-.6-.5-.8l-2.7-1.8c-.2-.1-.4-.2-.7-.2s-.2,0-.2,0c-.3,0-.6.2-.8.5-.2.3-.2.6-.2.9,0,.3.2.6.5.8l2.7,1.8c.5.4,1.3.2,1.6-.3.2-.3.2-.6.2-.9Z"/>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M91.9,90.9c.2-.3.2-.6.2-.9,0-.3-.2-.6-.5-.8-.2-.1-.4-.2-.7-.2s-.2,0-.2,0c-.3,0-.6.2-.8.5l-1.8,2.7c-.2.3-.2.6-.2.9,0,.3.2.6.5.8.5.4,1.3.2,1.6-.3l1.8-2.7Z"/>
        </SvgIcon>
    );
};

export default GetStartedBackgroundGraphic;
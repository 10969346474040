import React from "react";
import {BaseCard, BaseCardProps} from "@stratos/reporting";
import {useMixpanel, VerticalStepperCardContent} from "@cuda-react/core";
import {useZtnaWizardState} from "../../../../hooks/StateWizardHooks";
import {useHistory} from "react-router";
import {getStepIcon} from "../../../setupWizard/WizardPage";
import {StepState} from "@cuda-react/icons";

export interface ZTNASetupCardProps extends Omit<BaseCardProps, "children"> {
}

export const ZTNASetupCard = (props: ZTNASetupCardProps) => {
    const {preview, size} = props;
    const {step1, step2, step3, step4, step5} = useZtnaWizardState();
    const history = useHistory();
    const registerAction = useMixpanel("Wiz ZTNA", false);

    const steps = [
        {
            label: "tesseract.dashboard.cards.ztnaSetup.step1",
            state: step1?.state,
            icon: getStepIcon(step1?.state, size)
        },
        {
            label: "tesseract.dashboard.cards.ztnaSetup.step2",
            state: step2?.state,
            icon: getStepIcon(step2?.state, size)
        },
        {
            label: "tesseract.dashboard.cards.ztnaSetup.step3",
            state: step3?.state,
            icon: getStepIcon(step3?.state, size)
        },
        {
            label: "tesseract.dashboard.cards.ztnaSetup.step4",
            state: step4?.state,
            icon: getStepIcon(step4?.state, size)
        },
        {
            label: "tesseract.dashboard.cards.ztnaSetup.step5",
            state: step5?.state,
            icon: getStepIcon(step5?.state, size),
            linkText: "/setupWizard/steps/"
        }
    ];

    const activeStep = steps.findIndex((step) => step.state === StepState.Incomplete);
    const isDataLoading = step1?.loading || step2?.loading || step3?.loading || step4?.loading || step5?.loading;

    return (
        <BaseCard
            id="ZTNASetupCard"
            title="tesseract.dashboard.cards.ztnaSetup.title"
            size={props.size}
            preview={preview}
        >
            <VerticalStepperCardContent
                steps={steps}
                //@ts-ignore
                size={props.size}
                activeStep={activeStep >= steps.length ? -1 : activeStep}
                loading={isDataLoading}
                onClickAction={() => {
                    registerAction("Start");
                    history.push("/setupWizard/steps");
                }}
            />
        </BaseCard>
    );
};

export default ZTNASetupCard;
import React from "react";
import Input, {InputProps} from "../Input/Input";
import DualList from "./DualList";

export interface DualListInputProps extends Omit<InputProps<typeof DualList>, "component" | "displayError"> {}

/**
 * This is a version of the DualList basic input that can be used seamlessly with the Form and Wizards.
 *
 * All props provided are passed to Input and DualList.
 *
 * See [Input](/?path=/docs/core-components-inputs-input--input) for more information on the props required for Input.
 *
 * See [Boolean](/?path=/docs/core-components-inputs-basic-duallist--dual-list) for props required for DualLIst. Note that state related props
 * (such as "value" and "onChange") will already be provided by the Input component.
 */
export const DualListInput = (props: DualListInputProps) => (
    <Input {...props} displayError={false} component={DualList}/>
);

export default DualListInput;
import React, {ReactNode} from "react";

export interface TableActionProps {
    /** the action to render */
    children: ReactNode
    /**
     * this action should be styled to use the full available width of the action bar
     */
    fullWidth?: boolean,
    /**
     * by default actions are shown on the right, when true this will render the action on the left
     */
    left?: boolean,
}

/**
 * A simple component that just renders it's children. Used to wrap actions that are to be passed to TableActionAndFilters form
 * with either the fullWidth and/or left prop.
 * @param children
 * @constructor
 */
const TableAction = ({children}: TableActionProps): JSX.Element => (<>{children}</>);

export default TableAction;
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Grid, Tooltip, Typography} from "@barracuda-internal/bds-core";
import {OverviewCardRow} from "../OverviewCardRow";
import {CardSizeOptions, CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import {BorderLinearProgress} from "../BorderLinearProgress";
import React from "react";
import classNames from "classnames";
import {StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {WidgetInfoIcon} from "@cuda-react/icons";

/** data structure expected by the BaseBarCard for each entry */
export interface BarGridEntry {
    /**
     * title of the entry
     * Shown in the second column of the card, right of the icon if there is one.
     */
    title: string,
    /**
     * Value of the entry
     *
     * E.g. 999 Sessions
     */
    value: string,
    /**
     * Some designs have a small text between the value and the bar.
     *
     */
    smallText?: string,
    /**
     * Progress for the bar of the entry
     */
    barProgress?: number,
    /**
     * Icon for the entry
     */
    icon?: JSX.Element,
    /**
     * color of the bar
     */
    color?: string,
    /**
     * optional on click action for the row
     */
    onClick?: () => void,
    /**
     * optional message to display as a tooltip when row is hovered over
     */
    tooltipMessage?: string
}

const styles = (theme: Theme) => createStyles<string, Pick<DashboardCardProps, "size" | "preview">>(({
    caption: {
        fontSize: 14,
        fontWeight: 300,
    },
    container: {
        maxHeight: ({size}) => `calc(${CardSizeOptions[size].height}px - 68px)`,
        minHeight: ({size}) => `calc(${CardSizeOptions[size].height}px - 68px)`,
        padding: theme.spacing(1, 1, 1)
    },
    contentColumn: {
        width: "100%",
        maxWidth: "100%",
    },
    value: {
        width: ({size}) => size === CardSizes.small ? 60 : 110,
        paddingRight: theme.spacing(1),
        textAlign: "right",
        "& > *": {
            fontSize: 14,
            fontWeight: "lighter",
            color: theme.palette.reporting.fontColor
        }
    },
    bar: {
        flexGrow: 1
    },
    row: {
        padding: theme.spacing(0, 1),
        margin: theme.spacing(0.5, 0)
    },
    rowClick: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgb(217, 217, 217, 0.5)", // #D9D9D9 at 0.5 opacity
            borderRadius: theme.spacing(1)
        }
    },
    title: {
        minWidth: ({size}) => size === CardSizes.small ? 210 : 350,
        maxWidth: ({size}) => size === CardSizes.small ? 210 : 350,
        color: theme.palette.reporting.fontColor,
        "&> p": {
            fontSize: 14
        }
    },
    noDataContainer: {
        height: ({size}) => `calc(${CardSizeOptions[size].height}px - 56px)`,
        width: "100%"
    },
    noDataIcon: {
        width: 64,
        height: 64,
    },
    noDataMessage: {
        textAlign: "center",
        color: theme.palette.reporting.fontColor
    }
}));
const useStyles = makeStyles(styles);


export interface BarGridProps extends StyledComponentProps<typeof styles> {
    /**
     * size of the parent card
     */
    size: CardSizes,
    /**
     * data structure for each entry
     */
    entries: BarGridEntry[],
    /**
     * if true, loading skeleton bars are displayed instead of data
     */
    loading?: boolean,
    /**
     * classname to be applied to the base component
     */
    className?: string,
}

/**
 * Shows rows  of data
 *
 * Icon title   value         (smallText)   progessbar
 * @ domain.com 200 Sessions (out of 3000) ##########
 *
 * small text is not rendered in small mode
 * @param props
 */
export const BarGrid = (props: BarGridProps) => {
    const {size, entries, className, loading} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);

    return !loading && entries.length === 0 ? (
        <Grid container justifyContent="center" alignContent="center" className={classNames(classes.noDataContainer, className)}>
            <Grid xs={12} style={{textAlign: "center"}}>
                <WidgetInfoIcon className={classes.noDataIcon}/>
            </Grid>
            <Grid xs={12}>
                <Typography variant="h6" className={classes.noDataMessage}>
                    {translate("stratosReporting.barGrid.noData")}
                </Typography>
            </Grid>
        </Grid>
    ) : (
        <Grid container className={classNames(classes.container, className)} alignContent="flex-start">
            {loading && (new Array(size === CardSizes.large ? 12 : 5)).fill(null).map((empty, index) => (
                <Grid
                    key={index}
                    item
                    xs={12}
                    container
                    wrap="nowrap"
                    alignItems="center"
                    className={classes.row}
                >
                    <OverviewCardRow
                        title=""
                        size={size}
                        classes={{titleColumn: classes.title}}
                        noContainer
                        noContentContainer
                        loading
                    />
                </Grid>
            ))}
            {!loading && entries.length > 0 && entries.slice(0, size === CardSizes.large ? entries.length : 5).map((entry, key) => (
                <Tooltip
                    key={key}
                    title={entry.tooltipMessage ? translate(entry.tooltipMessage) : undefined}
                    placement="top"
                    arrow
                    disableInteractive
                    sx={(theme) => ({
                        ...theme.typography.body2
                    })}
                >
                    <Grid
                        item
                        xs={12}
                        container
                        wrap="nowrap"
                        alignItems="center"
                        className={classes.row + (entry.onClick ? " " + classes.rowClick : "")}
                        onClick={entry.onClick}
                    >
                        <OverviewCardRow
                            size={size}
                            title={entry.title}
                            icon={entry.icon}
                            classes={{titleColumn: classes.title}}
                            onClick={entry.onClick}
                            noContainer
                            noContentContainer
                        >
                            <Grid container alignItems="center" wrap="nowrap" justifyContent="flex-end" spacing={1}>
                                <Grid item className={classes.value}>
                                    <Typography variant="caption" noWrap>
                                        {entry.value}
                                    </Typography>
                                </Grid>
                                {size === CardSizes.small || !entry.smallText ? null : (
                                    <Grid item>
                                        <Typography variant="caption">
                                            {entry.smallText ? `(${entry.smallText})` : ""}
                                        </Typography>
                                    </Grid>
                                )}
                                {entry.barProgress !== undefined ? (
                                    <Grid item className={classes.bar}>
                                        <BorderLinearProgress
                                            variant="determinate"
                                            barColor={entry?.color || '#1a90ff'}
                                            value={entry.barProgress}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </OverviewCardRow>
                    </Grid>
                </Tooltip>
            ))}
        </Grid>
    );
};
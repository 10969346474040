import React from 'react';
import {
    Card,
    generateQueryString,
    getArrayDataContent,
    ResourceStatisticsCardContent,
    useCrudProps
} from "@cuda-react/core";
import {useStatisticsCardDetailsAndTotal} from "../../../../hooks/useStatisticsCardDetailsAndTotal";
import apiResources from "../../../../apiResources";

interface IoTStatusCardProps {
    details: {
        id: number | string;
    };
    type: "gateways" | "sites";
    column?: number;
}

const IoTStatusCard: React.FC<IoTStatusCardProps> = ({type, details}) => {
    const siteStatusChoices = useCrudProps(type === "gateways" ? apiResources.siteStates : undefined)[0]?.data;
    const [iotStatusDetails, iotStatusTotal] = useStatisticsCardDetailsAndTotal(
        getArrayDataContent(siteStatusChoices),
        (status) => `/infrastructure/iot${generateQueryString({
            gateway: [+details.id],
            connectionStatus: [status.key]
        })}`
    );

    return type === "gateways" ? (
        <Card
            id="ConnectedIoTCard"
            title="tesseract.appliances.dashboard.iotStatus.tabTitle"
        >
            <ResourceStatisticsCardContent
                overviewValue={iotStatusTotal}
                details={iotStatusDetails}
                resource={apiResources.siteStatus}
                params={{filter: {upstream: details.id, modelSeries: "S"}}}
            />
        </Card>
    ) : null;
};


export default IoTStatusCard;
import {ButtonDialog} from "@cuda-react/core";
import React from "react";
import {PreviewDialogContent, PreviewDialogContentProps} from "../PreviewDialogContent";

export interface AddWidgetButtonProps extends Pick<PreviewDialogContentProps, "onAdd" | "cards" | "forceSmall"> {
}

/**
 * Button for adding a new widget (card) to a dashboard.
 */
export const AddWidgetButton = ({cards, onAdd, forceSmall}: AddWidgetButtonProps) => (
    <ButtonDialog
        buttonText="stratosReporting.previewDialog.addWidgetButton"
        buttonProps={{color: "secondary"}}
        stopPropagation
        disabled={Object.values(cards).length < 1}
    >
        <PreviewDialogContent
            onAdd={onAdd}
            cards={cards}
            forceSmall={forceSmall}
        />
    </ButtonDialog>
);
import React from "react";
import apiResources from "../../../apiResources";
import {ConnectedForm, DialogBody, ReadOnlyInput, TextArrayInput, TextField} from "@cuda-react/core";

interface EditPointOfEntryContentProps {
    onClose?: any,
    onSuccess?: any,
    id?: string | number
}


const EditPointOfEntryContent: React.FC<EditPointOfEntryContentProps> = ({onClose, onSuccess, id}) => (
    <DialogBody
        title="tesseract.endpoint.pointsOfEntry.edit.title"
        onClose={onClose}
        form
    >
        <ConnectedForm
            resource={apiResources.pointsOfEntry}
            onSubmitSuccess={() => {
                onSuccess?.();
                onClose?.();
            }}
            params={{id}}
            onCancel={onClose}
            allowDirtyNavigation
        >
            <ReadOnlyInput
                source="configuration.name"
                label="tesseract.endpoint.pointsOfEntry.edit.name"
                field={TextField}
            />
            <TextArrayInput
                source="ipAddresses"
                label="tesseract.endpoint.pointsOfEntry.edit.ipAddress"
                description="tesseract.endpoint.pointsOfEntry.edit.descriptions.ipAddress"
            />
        </ConnectedForm>
    </DialogBody>
);


export default EditPointOfEntryContent;
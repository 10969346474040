import {get} from "lodash";

/**
 * Converts from the CRUD-style simple filters object to the complex filters object expected by BDS' DataTable.
 *
 * @param {object} filters the CRUD-style simple filters object to convert
 * @return {object} complex BDS' DataTable style filter object.
 */
export const convertFromSimpleFilters = (
    filters?: Record<string, any>
): object => ({
    filters: [{
        filters: filters && Object.keys(filters).map((filter: string) => ({
            field: filter,
            value: filters[filter],
            operator: "eq"
        })) || [],
        logic: "and"
    }],
    logic: "and"
});

/**
 * Converts from the complex filters object expected by BDS' DataTable to the CRUD-style simple filters object.
 *
 * @param {object} filters the complex BDS' DataTable style filter object to convert.
 * @return {object} simple CRUD style filter object.
 */
export const convertToSimpleFilters = (
    filters?: Record<string, any> | { filters?: Record<string, any> } | { filters?: Record<string, any> }[]
): Record<string, any> =>
    get(filters, "filters", [])
        .flatMap((filter: {filters: any}) => filter.filters || filter)
        .reduce((filters: {filters: any}, filter: any) => ({
            ...filters,
            [filter.field]: filter.value
        }), {});
import React from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import Authenticated from "../../authentication/Authenticated/Authenticated";
import {Button, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export const styles = (theme: Theme) => createStyles({
    root: {
        width: "calc(100% - 32px)",
        margin: 16
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "50vh",
        height: "100%"
    },
    subtitle: {
        paddingBottom: theme.spacing(3),
        fontSize: 30,
        fontWeight: 300,
    },
    content: {
        paddingBottom: theme.spacing(3),
        fontSize: 20,
        fontWeight: 300,
    }
});

const useStyles = makeOverrideableStyles("NoResourcePage", styles);

export interface NoResourcePageProps extends StyledComponentProps<typeof styles> {
    /**
     * the button text to render. It is a simple optional string. When set it overrides the default one.
     */
    button?: string,
    /**
     * the content to render. It is a simple optional string. When set it overrides the default one.
     */
    content: string,
    /**
     * the subtitle to render. It is a simple optional string. When set it overrides the default one.
     */
    subtitle?: string,
    /**
     * url target for the navigation bar item. When set it overrides the default redirect to the homepage.
     */
    to?: string
}

export const NoResourcePage = (props: NoResourcePageProps) => {
    const {button, content, subtitle, to} = props;
    const [translate] = useTranslation();
    const {push} = useHistory();
    const classes = useStyles(props);

    return (
        <div className={classes.root} id="cuda-react-page">
            <Authenticated>
                <div className={classes.container}>
                    {subtitle && <Typography className={classes.subtitle}>
                        {translate(subtitle)}
                        </Typography>
                    }
                    <Typography className={classes.content}>
                        {translate(content)}
                    </Typography>
                    {button && <Button
                            color="primary"
                            onClick={() => push(to ? to : "/")}
                            variant="contained"
                        size="small"
                        >
                            {translate(button)}
                        </Button>
                    }
                </div>
            </Authenticated>
        </div>
    );
};

NoResourcePage.defaultProps = {
    content: "cuda.resourceNotFound.content",
    subtitle: "cuda.resourceNotFound.subtitle",
    button: "cuda.buttons.return"
};

export default NoResourcePage;
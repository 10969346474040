import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        height: "70px",
        width: "70px"
    }
}));

export const ZtnaApplicationIcon = ({className, fillColor}: {className?: string, fillColor?: string}) => {
    const classes = useStyles();

    return(
        <SvgIcon
            className={classNames(className, classes.root, "cuda-react-icon")}
            id="cuda-icon-ztna-application"
            fill="none"
        >
            <g clipPath="url(#clip0_3104_11596)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M18.4464 2.59558L13.9 2.59558V7.28694H18.4464V2.59558ZM12.225 0.867188V9.01534H20.1214V0.867188L12.225 0.867188Z"
                      fill={fillColor ?? "#52667A"}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7.43927 14.08H2.89285L2.89285 18.7713H7.43927V14.08ZM1.21786 12.3516L1.21786 20.4997H9.11426V12.3516H1.21786Z"
                      fill={fillColor ?? "#52667A"}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.80713 7.66049C6.26082 7.66049 7.43927 6.44448 7.43927 4.94444C7.43927 3.44441 6.26082 2.2284 4.80713 2.2284C3.35344 2.2284 2.175 3.44441 2.175 4.94444C2.175 6.44448 3.35344 7.66049 4.80713 7.66049ZM4.80713 9.38889C7.18589 9.38889 9.11426 7.39904 9.11426 4.94444C9.11426 2.48985 7.18589 0.5 4.80713 0.5C2.42837 0.5 0.5 2.48985 0.5 4.94444C0.5 7.39904 2.42837 9.38889 4.80713 9.38889Z"
                      fill={fillColor ?? "#52667A"}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M16.233 14.2354L15.0814 15.0957L13.9327 15.9599L14.3697 17.3559L14.8113 18.7503L16.233 18.7528L17.6547 18.7503L18.0962 17.3559L18.5333 15.9599L17.3868 15.0974L16.233 14.2354ZM18.3691 13.6974L16.233 12.1016L14.0969 13.6974L11.966 15.3006L12.7767 17.89L13.5958 20.4766L16.233 20.4812L18.8701 20.4766L19.6893 17.89L20.5 15.3006L18.3691 13.6974Z"
                      fill={fillColor ?? "#52667A"}/>
            </g>
            <defs>
                <clipPath id="clip0_3104_11596">
                    <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export default ZtnaApplicationIcon;
export default [
    {
        "id": 5,
        "name": "UnitedStates",
        "virtualWan": {
            "id": 1,
            "name": "Production",
            "description": "My production workspace",
        },
        "cloudVirtualWan": {
            "id": 1,
            "name": "Production",
            "resourceId": "VWAN1",
            "virtualWan": {
                "id": 1,
                "name": "Production",
                "description": "My production workspace",
            }
        },
        "hubName": "NorthAmerica",
        "region": "westus",
        "scaleUnit": 20,
        "gatewayType": "cloud",
        // "sites": 4,
        "gateways": 0,
        "instances": 2,
        "aggregateState": "ok",
        "latitude": 37.751,
        "longitude": -97.549,
        "summary": "United States",
        "sites": [
            {
                "id": 9,
                "name": "AnnArbor",
                "gateway": 5,
                "gatewayName": "UnitedStates",
                "cloudVirtualWan": 1,
                "cloudVirtualWanName": "Production",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 42.278694,
                "longitude": -83.741776,
                "summary": "Ann Arbor, Michigan, 48104, United States",
            },
            {
                "id": 11,
                "name": "SanJose",
                "gateway": 5,
                "gatewayName": "UnitedStates",
                "cloudVirtualWan": 1,
                "cloudVirtualWanName": "Production",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 37.270303,
                "longitude": -121.794737,
                "summary": "5225 Hellyer Ave, San Jose, CA 95138, United States"
            },
            {
                "id": 10,
                "name": "Chelmsford",
                "gateway": 5,
                "gatewayName": "UnitedStates",
                "cloudVirtualWan": 1,
                "cloudVirtualWanName": "Production",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 42.598508,
                "longitude": -71.329444,
                "summary": "Chelmsford, MA 01824, United States"
            },
            {
                "id": 12,
                "name": "Alpharetta",
                "gateway": 5,
                "gatewayName": "UnitedStates",
                "cloudVirtualWan": 1,
                "cloudVirtualWanName": "Production",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 34.089738,
                "longitude": -84.265901,
                "summary": "12725 Morris Rd Ext, Alpharetta, GA30004, United States",
            }
        ]
    },
    {
        "id": 1,
        "name": "Austria",
        "virtualWan": {
            "id": 1,
            "name": "Production",
            "description": "My production workspace",
        },
        "scaleUnit": 0,
        "gatewayType": "on-prem",
        // "sites": 5,
        "gateways": 0,
        "aggregateState": "ok",
        "latitude": 48.356296,
        "longitude": 14.297544,
        "summary": "Austria",
        "sites": [
            {
                "id": 1,
                "name": "Vienna",
                "gateway": 1,
                "gatewayName": "Austria",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 48.2,
                "longitude": 16.3667,
                "summary": "Vienna, 1100, Austria"
            },
            {
                "id": 2,
                "name": "Innsbruck",
                "gateway": 1,
                "gatewayName": "Austria",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 47.26498,
                "longitude": 11.43446,
                "summary": "Innsbruck, 6020, Austria",
            },
            {
                "id": 22,
                "name": "InnsbruckFridge1",
                "gateway": 1,
                "gatewayName": "Austria",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 0,
                "longitude": 0,
                "summary": "",
            },
            {
                "id": 24,
                "name": "InnsbruckFridge3",
                "gateway": 1,
                "gatewayName": "Austria",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 0,
                "longitude": 0,
                "summary": "",
            },
            {
                "id": 23,
                "name": "InnsbruckFridge2",
                "gateway": 1,
                "gatewayName": "Austria",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 0,
                "longitude": 0,
                "summary": "",
            }
        ]
    },
    {
        "id": 9,
        "name": "UnitedArabEmirates",
        "virtualWan": {
            "id": 1,
            "name": "Production",
            "description": "My production workspace",
        },
        "region": "uaenorth",
        "scaleUnit": 0,
        "gatewayType": "managed",
        // "sites": 1,
        "gateways": 0,
        "instances": 1,
        "aggregateState": "ok",
        "latitude": 24.429387,
        "longitude": 54.466258,
        "summary": "United Arab Emirates",
        "sites": [
            {
                "id": 16,
                "name": "Dubai",
                "gateway": 9,
                "gatewayName": "UnitedArabEmirates",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 25.197104,
                "longitude": 55.274312,
                "summary": "Downtown Dubai, Dubai, 57WF+RP, United Arab Emirates"
            }
        ]
    },
    {
        "id": 10,
        "name": "Singapore",
        "virtualWan": {
            "id": 1,
            "name": "Production",
            "description": "My production workspace"
        },
        "scaleUnit": 0,
        "gatewayType": "on-prem",
        // "sites": 1,
        "gateways": 0,
        "instances": 2,
        "aggregateState": "ok",
        "latitude": 1.2855,
        "longitude": 103.8565,
        "summary": "Singapore, Central Singapore Community Development Council, Singapore",
        "sites": [
            {
                "id": 17,
                "name": "PulauUjong",
                "gateway": 10,
                "gatewayName": "Singapore",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 1.365645,
                "longitude": 103.772879,
                "summary": "Dairy Farm Ln, Pulau Ujong, 9Q8F+75, Singapore",
            }
        ]
    },
    {
        "id": 7,
        "name": "Brazil",
        "model": "VTC16PAYG",
        "virtualWan": {
            "id": 1,
            "name": "Production",
            "description": "My production workspace",
            "associations": 0
        },
        "cloudVirtualWan": {
            "id": 1,
            "name": "Production",
            "virtualWan": {
                "id": 1,
                "name": "Production",
                "description": "My production workspace"
            }
        },
        "hubName": "BrazilSouth",
        "region": "brazilsouth",
        "scaleUnit": 20,
        "gatewayType": "cloud",
        // "sites": 3,
        "gateways": 0,
        "instances": 2,
        "latitude": -21.914533,
        "longitude": -41.165915,
        "summary": "Brazil",
        "sites": [
            {
                "id": 14,
                "name": "SaoPaulo",
                "gateway": 7,
                "gatewayName": "Brazil",
                "cloudVirtualWan": 1,
                "cloudVirtualWanName": "Production",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": -23.5733,
                "longitude": -46.6417
            },
            {
                "id": 19,
                "name": "Uberlandia",
                "gateway": 7,
                "gatewayName": "Brazil",
                "cloudVirtualWan": 1,
                "cloudVirtualWanName": "Production",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                    "description": "My production workspace",
                },
                "latitude": 0,
                "longitude": 0
            },
            {
                "id": 20,
                "name": "CamposDosGoytacazes",
                "gateway": 7,
                "gatewayName": "Brazil",
                "cloudVirtualWan": 1,
                "cloudVirtualWanName": "Production",
                "virtualWan": {
                    "id": 1,
                    "name": "Production",
                },
                "latitude": -21.78018,
                "longitude": -41.30241,
            }
        ]
    }
];
import React from "react";
import {UserDirectoryOkta} from "../../UserDirectoriesTypes";
import {ConnectedForm, TextArrayInput, TextInput} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";

type UserDirectoryOktaFormProps = {
    directory: UserDirectoryOkta;
    create: boolean;
    id?: string;
    onClose: (refresh: boolean) => void;
}

const i18nScope = "tesseract.identity.userDirectories.form";

export const UserDirectoryOktaForm: React.FC<UserDirectoryOktaFormProps> = ({
    onClose,
    create,
    directory,
    id,
}: UserDirectoryOktaFormProps) => (
        <ConnectedForm
            create={create}
            resource={apiResources.directories}
            params={create ? undefined : {id}}
            onSubmitSuccess={() => onClose(true)}
            onCancel={() => onClose(false)}
            initialValues={directory}
        >
            <TextInput
                source="displayName"
                label={`${i18nScope}.displayName`}
                isRequired
            />
            <TextInput
                source="options.oktaDomainName"
                label={`${i18nScope}.okta.domainname`}
                isRequired
                disabled={!create}
            />
            <TextInput
                source="options.oktaAuthToken"
                label={`${i18nScope}.okta.authToken`}
                isRequired
            />
            <TextArrayInput
                source="options.groupsIncluded"
                label="tesseract.identity.userDirectories.form.groupsIncluded"
            />
        </ConnectedForm>
    );
import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const WebTrafficIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-web-traffic"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M30,5.7c-13.4,0-24.3,10.9-24.3,24.3s10.9,24.3,24.3,24.3,24.3-10.9,24.3-24.3S43.4,5.7,30,5.7ZM10.6,30c0-1.5.2-2.9.5-4.3l11.6,11.6v2.4c0,2.7,2.2,4.9,4.9,4.9v4.7c-9.5-1.2-17-9.4-17-19.3ZM44.3,43.1c-.6-2-2.4-3.4-4.6-3.4h-2.4v-7.3c0-1.3-1.1-2.4-2.4-2.4h-14.6v-4.9h4.9c1.3,0,2.4-1.1,2.4-2.4v-4.9h4.9c2.7,0,4.9-2.2,4.9-4.9v-1c7.1,2.9,12.2,9.9,12.2,18s-2,9.7-5.1,13.1Z"/>
        </SvgIcon>
    );
};

export default WebTrafficIcon;
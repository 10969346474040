import {
    CrudTypes,
    getDataContent,
    NoResourcePage,
    Tab,
    TabbedPage,
    useCrudSubscription
} from "@cuda-react/core";
import PropTypes from "prop-types";
import React from "react";
import apiResources from "../../../../apiResources";
import UserSettings from "./UserSettings";
import UserDashboard from "./UserDashboard";

const UserDetails = ({match}: any) => {
    const id = match?.params?.id;
    const [userDetailsData, loading] = useCrudSubscription(CrudTypes.GET, apiResources.zeroTrustUsers, {id});

    const userDetails = getDataContent(userDetailsData, {});
    const userNotFound = !!userDetailsData?.error || !id;


    return !userNotFound ?
        <TabbedPage
            defaultTab={0}
            title="tesseract.endpoint.pageTitle"
            subtitle={[
                {text: "tesseract.endpoint.users", path: "/ztna/users"},
                userDetails?.fullName || " "
            ]}
        >
            <Tab
                value="#usersdashboard"
                label="tesseract.endpoint.ztnaUsers.dashboardTabTitle"
            >
                <UserDashboard
                    details={{id, loading}}
                />
            </Tab>
            <Tab
                value="#usersettings"
                label="tesseract.endpoint.ztnaUsers.settingsTabTitle"
            >
                <UserSettings
                    details={{id, loading}}
                />
            </Tab>
        </TabbedPage>
        : <NoResourcePage content="tesseract.endpoint.ztnaUsers.userNotFound"/>;
};

UserDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.number.isRequired
        })
    })
};

export default UserDetails;
import {Card, ConnectedForm, TextInput, validateIpv4} from "@cuda-react/core";
import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";
import {parseJson} from "../../../../utils/jsonUtils";
import {get} from "lodash";

export const useStyles = makeStyles((theme: Theme) => ({
    message: {
        padding: theme.spacing(2, 2, 2, 4)
    }
}));

export const DhcpServer = () => {
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <Card title="tesseract.settings.tabs.dhcpServer.title">
            <ConnectedForm
                formatRequestData={(data) => ({
                        settings: {
                            dhcp_relay: JSON.stringify({ips: [data["ipAddress"]]})
                        }
                    })}
                formatResourceData={(data) => ({
                    ipAddress: parseJson(data?.settings?.["dhcp_relay"])?.ips?.[0] || ""
                })}
                formatError={(errors) => get(errors, "settings.dhcp_relay")}
                resource={apiResources.settings}
                params={{filter: {type: "virtualWan"}}}
            >
                <Typography className={classes.message}>
                    {translate("tesseract.settings.tabs.dhcpServer.description")}
                </Typography>
                <TextInput
                    source="ipAddress"
                    label="tesseract.settings.tabs.dhcpServer.ipAddress"
                    validate={validateIpv4}
                />
            </ConnectedForm>
        </Card>
    );
};

export default DhcpServer;
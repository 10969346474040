import React from 'react';
import {Route, Switch} from 'react-router';
import {IdentitySettings} from './identitySettings/IdentitySettings';
import {Users} from './users/Users';
import {Groups} from './groups/Groups';

export const Identity = () => (
    <Switch>
        <Route path='/identity/users'>
            <Users />
        </Route>
        <Route path='/identity/groups'>
            <Groups />
        </Route>
        <Route path='/identity/settings'>
            <IdentitySettings />
        </Route>
    </Switch>
);
import React from "react";
import Input, {InputProps} from "../Input/Input";
import SelectArray from "./SelectArray";

export interface SelectArrayInputProps extends Omit<InputProps<typeof SelectArray>, "component" | "displayError"> {}

/**
 * Based on the SelectArray component, but modified to use the default [Input](/?path=/docs/core-components-inputs-input) format. Works natively with redux form "input" prop.
 */
export const SelectArrayInput = (props: SelectArrayInputProps) => (
        <Input {...props} displayError={false} component={SelectArray}/>
    );

export default SelectArrayInput;
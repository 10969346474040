import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {getArrayDataContent, TextInput, useCrudProps} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {useWatch} from "react-hook-form";
import apiResources from "../../../../../apiResources";

const useStyles = makeStyles((theme) => ({
    message: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export const SelectLanLengthStep = () => {
    const inputValidations = useSiteInputValidations();
    const classes = useStyles();
    const [translate] = useTranslation();
    const model = useWatch({name: "serials[0].model"});
    const portsData = getArrayDataContent(useCrudProps(apiResources.modelPorts, {id: model})[0]?.data);
    const availablePorts = portsData.filter((port) => port.port && !port.port.includes("wan"));
    const portsAvailable = availablePorts.length;

    return (
        <React.Fragment>
            <Typography className={classes.message} variant="body1">
                {translate("tesseract.sites.dialog.lans.SCmessage", {portsAvailable})}
            </Typography>
            <Typography className={classes.message} variant="body1">
                {translate("tesseract.sites.dialog.lans.message2")}
            </Typography>
            <TextInput
                label="tesseract.sites.dialog.lans.size"
                source="lanTotal"
                isRequired
                validate={inputValidations.validateSCLansLength(portsAvailable || 3)}
                type="integer"
                options={{
                    type: "number",
                    InputProps: {
                        inputProps: {
                            min: 1,
                            max: portsAvailable,
                            step: 1
                        }
                    }
                }}
            />
        </React.Fragment>
    );
};

export default SelectLanLengthStep;
import React from "react";

import {CreatedUserDirectory} from "../UserDirectoriesTypes";
import {useTranslation} from "react-i18next";

interface UserDirectorySyncResultField {
    directory: CreatedUserDirectory
};

export const emptyPlaceholder = "—";

export const UserDirectorySyncResultField: React.FC<UserDirectorySyncResultField> = ({directory}: UserDirectorySyncResultField) => {
    if(!directory.lastSuccessfulSync) {
        return (<>{emptyPlaceholder}</>);
    }

    const [translate] = useTranslation();
    const users = translate("tesseract.identity.userDirectories.users", {count: directory.totalUsers});
    const groups = translate("tesseract.identity.userDirectories.groups", {count: directory.totalGroups});

    const synced = translate("tesseract.identity.userDirectories.syncStatus", {
        users,
        groups,
    });

    return (
        <>{synced}</>
    );
};
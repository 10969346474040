import React from "react";
import {
    FormSection,
    getArrayDataContent,
    PasswordConfirmInput,
    SelectInput,
    TextInput,
    useCrudProps
} from "@cuda-react/core";
import {useWatch} from "react-hook-form";
import {useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../apiResources";


interface SCLanTableInputFormProps {
    portsData?: any
}

const SCLanTableInputForm: React.FC<SCLanTableInputFormProps> = ({portsData}) => {
    const inputValidations = useSiteInputValidations();
    const wifiEncryptionMode = getArrayDataContent(useCrudProps(apiResources.wifiEncryptionMode)[0]?.data);
    const port = useWatch({name: "port"});

    return (
        <React.Fragment>
            <TextInput
                source="name"
                label="tesseract.sites.dialog.lans.name"
                description="tesseract.sites.dialog.lans.descriptions.name"
                isRequired
                validate={inputValidations.validateName}
            />
            <SelectInput
                source="port"
                label="tesseract.sites.dialog.lans.port"
                description="tesseract.sites.dialog.lans.descriptions.port"
                choices={portsData}
                optionValue="port"
                optionText="description"
            />
            <TextInput
                source="address"
                label="tesseract.sites.dialog.lans.address"
                description="tesseract.sites.dialog.lans.descriptions.address"
                validate={inputValidations.validateIp}
                isRequired
            />
            <TextInput
                source="netmask"
                label="tesseract.sites.dialog.lans.netmask"
                description="tesseract.sites.dialog.lans.descriptions.netmask"
                validate={inputValidations.validateNetmask}
                isRequired
            />
            {port === "wifi" && (
                <FormSection title="tesseract.sites.dialog.lans.wifiSectionTitle">
                    <TextInput
                        source="ssid"
                        label="tesseract.sites.dialog.lans.wifi.ssid"
                        description="tesseract.sites.dialog.lans.descriptions.wifi.ssid"
                        validate={inputValidations.validateWifiSsid}
                        isRequired
                    />
                    <SelectInput
                        source="mode"
                        label="tesseract.sites.dialog.lans.wifi.securityMode"
                        description="tesseract.sites.dialog.lans.descriptions.wifi.securityMode"
                        choices={wifiEncryptionMode}
                        isRequired
                    />
                    <PasswordConfirmInput
                        source="passphrase"
                        label="tesseract.sites.dialog.lans.wifi.passphrase"
                        confirmLabel="tesseract.sites.dialog.lans.wifi.passphraseConfirm"
                        description="tesseract.sites.dialog.lans.descriptions.wifi.passphrase"
                        validate={inputValidations.validateWifiPassphrase}
                        isRequired
                    />
                </FormSection>
            )}
        </React.Fragment>
    );
};


export default SCLanTableInputForm;
import {
    ConnectedForm,
    getArrayDataContent,
    Tab,
    useCrudProps,
    useDeepCompareMemo,
    useMixpanel,
    validatePrivateIpv4
} from "@cuda-react/core";
import {get, set} from "lodash";
import React, {useState} from "react";
import {getNetworkId, validateNames, validateNetworks} from "../../../hooks/createEditSiteHooks";
import {useTranslation} from "react-i18next";
import BasicTab from "./tabs/common/BasicTab";
import WansTab from "./tabs/tvt/WansTab";
import LansTab from "./tabs/tvt/LansTab";
import AdvancedSettingsTab from "./tabs/common/AdvancedSettingsTab";
import DhcpTab from "./tabs/tvt/DhcpTab";
import apiResources from "../../../apiResources";
import {DEFAULT_MTU} from "./inputs/wanTableInput/WanTableInput";

export const SELECT_EMPTY_VALUE = "__SELECT_EMPTY_VALUE";


interface EditTVTContentProps {
    id: any;
    type: "site" | "gateway";
    details: {
        gatewayType?: "cloud" | "on-prem" | "managed";
        model?: string;
        modelSeries?: "S" | "T";
        serials?: string[];
        serial?: string;
        uuid?: string;
    };
}

const parseVirtualLanId = (virtualLanId: any) => virtualLanId ? parseInt(virtualLanId) : null;

export const format = {
    error: (error: any, data: any) => {
        const wanErrors: any = [];
        const lanErrors: any = [];
        let indexes = {wans: 0, lans: 0, expressRoutes: 0, bridges: 0};
        data.wans?.forEach?.((wan: any, index: any) => {
            if (wan.mode === "expressRoute") {
                if (get(error, "expressRoutes")) {
                    if (Array.isArray(get(error, "expressRoutes"))) {
                        if (get(error, `expressRoutes[${indexes.expressRoutes}]`)) {
                            const expressError = get(error, `expressRoutes[${indexes.expressRoutes}]`);
                            set(wanErrors, `[${index}]`, expressError);
                            if (get(expressError, "interfaces")) {
                                get(expressError, "interfaces", []).forEach((interfaceErrors: any, interfaceIndex: any) => {
                                    set(wanErrors, `[${index}].doublePort[${interfaceIndex}]`, get(interfaceErrors, "port"));
                                    set(wanErrors, `[${index}].doubleVirtualLanId[${interfaceIndex}]`, get(interfaceErrors, "virtualLanId"));
                                    set(wanErrors, `[${index}].doubleAddress[${interfaceIndex}]`, get(interfaceErrors, "address"));
                                    set(wanErrors, `[${index}].doubleNetmask[${interfaceIndex}]`, get(interfaceErrors, "netmask"));
                                });
                            }
                        }
                    } else {
                        set(wanErrors, `[${index}]`, get(error, "expressRoutes"));
                    }
                }
                indexes.expressRoutes++;
            } else if (wan.mode === "bridge") {
                if (get(error, `bridges[${indexes.bridges}]`)) {
                    set(wanErrors, `[${index}]`, get(error, `bridges[${indexes.bridges}]`));
                    if (get(error, `bridges[${indexes.bridges}].type`)) {
                        set(wanErrors, `[${index}].mode`, get(error, `bridges[${indexes.bridges}].type`));
                    }
                } else if (get(error, `bridges`) && !Array.isArray(get(error, "bridges"))) {
                    set(wanErrors, `[${index}]`, get(error, `bridges[${indexes.bridges}]`));
                }
                indexes.bridges++;
            } else {
                if (get(error, `wans[${indexes.wans}]`)) {
                    set(wanErrors, `[${index}]`, get(error, `wans[${indexes.wans}]`));
                }
                indexes.wans++;
            }
        });
        data.lans?.forEach?.((lan: any, index: any) => {
            if (lan.mode === "bridge") {
                if (get(error, `bridges[${indexes.bridges}]`)) {
                    set(lanErrors, `[${index}]`, get(error, `bridges[${indexes.bridges}]`));
                    if (get(error, `bridges[${indexes.bridges}].type`)) {
                        set(lanErrors, `[${index}].mode`, get(error, `bridges[${indexes.bridges}].type`));
                    }
                } else if (get(error, `bridges`) && !Array.isArray(get(error, "bridges"))) {
                    set(lanErrors, `[${index}]`, get(error, `bridges[${indexes.bridges}]`));
                }
                indexes.bridges++;
            } else {
                if (get(error, `lans[${indexes.lans}]`)) {
                    set(lanErrors, `[${index}]`, get(error, `lans[${indexes.lans}]`));
                }
                indexes.lans++;
            }
        });

        if (wanErrors.length > 0) set(error, "wans", wanErrors);
        if (lanErrors.length > 0) set(error, "lans", lanErrors);

        return error;
    },
    requestData: ({gateway, upstream, ...data}: any) => {
        const buildBridgeJson = (data: any) => {
            const inlineBridge = data.wans.filter((wan: any) => wan.mode === "bridge").map((bridge: any) => ({
                ...bridge,
                type: "inline",
                dhcp: {
                    type: "disabled"
                },
            }));
            const switchBridge = data.lans.filter((lan: any) => lan.mode === "bridge").map((bridge: any) => ({
                ...bridge,
                type: "switch",
            }));

            return inlineBridge.concat(switchBridge);
        };

        return {
            ...data,
            wans: data.wans
                .filter((wan: any) => wan.mode !== "expressRoute")
                .filter((wan: any) => wan.mode !== "bridge")
                .map((wan: any) => ({...wan, virtualLanId: parseVirtualLanId(wan.virtualLanId)})),
            lans: data.lans
                .filter((wan: any) => wan.mode !== "bridge")
                .map((lan: any) => ({...lan, virtualLanId: parseVirtualLanId(lan.virtualLanId)})),
            expressRoutes: data.wans.filter((wan: any) => wan.mode === "expressRoute").map((expressRoute: any) => ({
                ...expressRoute,
                interfaces: [{
                    port: get(expressRoute, "doublePort[0]"),
                    virtualLanId: parseVirtualLanId(get(expressRoute, "doubleVirtualLanId[0]")),
                    address: get(expressRoute, "doubleAddress[0]"),
                    netmask: get(expressRoute, "doubleNetmask[0]"),
                    primary: true
                }, {
                    port: get(expressRoute, "doublePort[1]"),
                    virtualLanId: parseVirtualLanId(get(expressRoute, "doubleVirtualLanId[1]")),
                    address: get(expressRoute, "doubleAddress[1]"),
                    netmask: get(expressRoute, "doubleNetmask[1]"),
                    primary: false
                }],
                transferNetwork: expressRoute.transferNetwork + "/28"
            })),
            bridges: buildBridgeJson(data),
            gateway: gateway === SELECT_EMPTY_VALUE ? null : gateway,
            upstream: upstream === SELECT_EMPTY_VALUE ? null : upstream
        };
    },
    resourceData: ({gateway, upstream, ...data}: any) => ({
        ...data,
        wans: data.wans && data.wans.concat((data.expressRoutes || []).map((expressRoute: any) => {
            const interfaces = get(expressRoute, "interfaces", [])
                .sort((routeA: any, routeB: any) => routeA.primary ? -1 : (routeB.primary ? 1 : 0));
            return {
                ...expressRoute,
                mode: "expressRoute",
                doublePort: interfaces.map(({port}: any) => port),
                doubleVirtualLanId: interfaces.map(({virtualLanId}: any) => virtualLanId),
                doubleAddress: interfaces.map(({address}: any) => address),
                doubleNetmask: interfaces.map(({netmask}: any) => netmask),
                transferNetwork: expressRoute.transferNetwork && expressRoute.transferNetwork.split("/")[0],
                mtu: DEFAULT_MTU
            };
        }), ((data.bridges) || []).filter((bridge: any) => bridge.type === "inline").map((bridge: any) => ({
            ...bridge,
            mode: "bridge",
            port: bridge.wanPort,
            mtu: DEFAULT_MTU
        }))),
        lans: data.lans && data.lans.concat((data.bridges || []).filter((bridge: any) => bridge.type === "switch").map((bridge: any) => ({
            ...bridge,
            mode: "bridge",
            port: bridge.lanPorts[0]
        }))),
        gateway: gateway === null ? SELECT_EMPTY_VALUE : gateway,
        upstream: upstream === null ? SELECT_EMPTY_VALUE : upstream
    })
};

const EditTVTContent: React.FC<EditTVTContentProps> = ({id, type, details: {model, gatewayType, modelSeries, serial, serials, uuid}}) => {
    // Fetch select choices, current form values and validation methods
    const [translate] = useTranslation();
    const [{activeLans = [], wans = []}, setFormValues] = useState<any>({});
    const portsData = getArrayDataContent(useCrudProps(
        type === "site" ? apiResources.sitePorts : apiResources.gatewaysOnPremPorts,
        {id}
    )[0]?.data);
    const pointOfEntryApplicable = (type === "gateway" && gatewayType === "on-prem") || (type === "site" && modelSeries === "T");
    const isPointOfEntry = useCrudProps(pointOfEntryApplicable ? apiResources.pointsOfEntry : undefined, {filter: {configurationUuid: uuid}})[0].data?.content?.[0];

    const gatewaySerials = gatewayType === "on-prem" ? serial?.split(",") : undefined;

    // Verify if any configured ports are disabled, to present warning on save
    const [disabledPorts, breaksPointOfEntry] = useDeepCompareMemo(() => {
        const tableRowDimmed = (row: any) => {
            if (!row.port || row.mode === "wwan") {
                return false;
            }
            return portsData && !portsData.some((applianceInterface) => {
                if (row.mode === "expressRoute" ? row.doublePort.some((port: any) => port === applianceInterface.port) : applianceInterface.port === row.port) {
                    return applianceInterface.available;
                }
            });
        };
        const wanHasPortDisabled = wans.some(tableRowDimmed);
        const lanHasPortDisabled = activeLans.some(tableRowDimmed);
        const disabledPorts = wanHasPortDisabled || lanHasPortDisabled;

        const breaksPointOfEntry = isPointOfEntry && !wans
            .some((wan: any) => wan.mode === "static" && validatePrivateIpv4(wan?.address, {}, {t: translate}));

        return [disabledPorts, breaksPointOfEntry];
    }, [activeLans, wans, portsData, isPointOfEntry]);

    const registerAction = useMixpanel(
        gatewayType ? "Gateways Settings" : "Sites Settings",
        !!model,
        gatewayType ? {model: model || "", type: gatewayType || ""} : {model: model || "", type: modelSeries || ""}
    );

    let confirmMessage;
    if (disabledPorts) {
        confirmMessage = [translate("tesseract.appliances.settings.confirmPortNotActivated", {context: type})];
    }
    if (breaksPointOfEntry) {
        confirmMessage = confirmMessage ? [
            ...confirmMessage,
            translate("tesseract.appliances.settings.confirmPointOfEntryBroken", {context: type})
        ] : [
            translate("tesseract.appliances.settings.confirmPointOfEntryBroken", {context: type})
        ];
    }
    if (confirmMessage) {
        confirmMessage = [...confirmMessage, translate("tesseract.appliances.settings.confirm")];
    }

    return (
        <ConnectedForm
            resource={type === "site" ? apiResources.siteConfiguration : apiResources.gatewaysOnPrem}
            formButtonToolbarProps={confirmMessage ? {confirmMessage} : undefined}
            id={id}
            canReset
            tabbed
            topToolbar
            validate={(values = {}) => {
                // Verify lan/wan/routes port usage and names
                const errors = {};
                const usedNetworks = validateNetworks(values, "wans", undefined, translate, errors);
                validateNetworks(values, "lans", undefined, translate, errors, usedNetworks);
                const usedNetworkNames = validateNames(values, "wans", undefined, translate, errors);
                validateNames(values, "lans", undefined, translate, errors, usedNetworkNames);
                validateNames(values, "routes", undefined, translate, errors);
                const wanBridgeCount = get(values, "wans", []).filter((wan: any) => wan.mode === "bridge").length;

                if (get(values, "lans", []).length < 1 && wanBridgeCount === 0) {
                    set(errors, "lans", translate("tesseract.appliances.dialog.validation.noLans"));
                }

                if (wanBridgeCount > 1) {
                    values.wans.forEach((wan: any, index: any) => {
                        if (wan.mode === "bridge") {
                            set(errors, `wans[${index}].mode`, translate("tesseract.appliances.dialog.validation.oneInlineBridge"));
                        }
                    });
                }

                //Verify secondary DNS is not set if there is no primary
                values.secondaryDns && !values.primaryDns && set(errors, "secondaryDns", translate("tesseract.appliances.dialog.validation.secondaryDns"));

                // Return errors
                return errors;
            }}
            formatRequestData={format.requestData}
            formatResourceData={format.resourceData}
            formatError={format.error}
            onChange={(newValues) => {
                setFormValues({
                    wans: get(newValues, "wans"),
                    activeLans: get(newValues, "lans", []).map((lan: any) => ({...lan, networkId: getNetworkId(lan)}))
                });
            }}
            onSubmitSuccess={(response) => {
                registerAction("Update", {
                    model: response.model,
                    hasUpstream: type === "gateway" ? !!response.gateway : "",
                    type: type === "site" ? "T" : "on-prem",
                    wansLength: response.wans.length,
                    lansLength: response.lans.length,
                    isHa: response.serials.length > 1
                });
            }}
        >
            <Tab label="tesseract.appliances.settings.basic.tabTitle">
                <BasicTab id={id} applianceType={type} series="T"/>
            </Tab>
            <Tab label="tesseract.appliances.settings.wan.tabTitle">
                <WansTab applianceType={type} portsData={portsData} series="T"/>
            </Tab>
            <Tab label="tesseract.appliances.settings.lan.tabTitle">
                <LansTab portsData={portsData}/>
            </Tab>
            <Tab label="tesseract.appliances.settings.dhcp.tabTitle">
                <DhcpTab id={id} applianceType={type}/>
            </Tab>
            <Tab label="tesseract.appliances.settings.advanced.tabTitle">
                <AdvancedSettingsTab applianceType={type} serials={gatewayType ? gatewaySerials : serials}
                                     gatewayType={gatewayType}/>
            </Tab>
        </ConnectedForm>
    );
};


export default EditTVTContent;
import {
    BooleanInput,
    ConnectedForm,
    getArrayDataContent,
    PropGateway,
    ReadOnlyInput,
    SelectedChoiceField,
    SelectInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel
} from "@cuda-react/core";
import React from "react";
import apiResources from "../../../apiResources";
import {useSiteInputValidations} from "../../../hooks/createEditSiteHooks";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {Trans, useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    helpText: {
        margin: theme.spacing(2, 4, 0, 8)
    }
}));

interface EditManagedGatewayContentProps {
    id: number | string;
    type?: "site" | "gateway";
    details: {
        gatewayType?: "cloud" | "on-prem" | "managed";
        model?: string;
    };
}

const EditManagedGatewayContent: React.FC<EditManagedGatewayContentProps> = ({id, details: {model, gatewayType}}) => {
    const registerAction = useMixpanel(
        "Gateways Settings",
        !!model,
        {model: model || "", type: gatewayType || ""}
    );

    const inputValidations = useSiteInputValidations();
    const bandwidthChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysBandwidths)[0]);
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]);
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const classes = useStyles();
    const [translate] = useTranslation();
    const bandwidthData = useCrudProps(apiResources.managedBandwidthsUsage)[0]?.data;
    const availableBandwidth = bandwidthData?.available > 0 ? bandwidthData.available : 0;

    return (
        <ConnectedForm
            resource={apiResources.managedGateways}
            onSubmitSuccess={(response) => {
                registerAction("Update", {
                    model: response.model,
                    type: "managed"
                });
            }}
            id={id}
            formatRequestData={(data) => ({
                ...data,
                id,
                virtualWanId
            })}
            canReset
            topToolbar
        >
            <TextInput
                source="name"
                label="tesseract.gateways.dialog.create.managed.name"
                validate={inputValidations.validateSiteName}
            />
            <ReadOnlyInput
                source="region"
                label="tesseract.gateways.dialog.create.managed.region"
                field={SelectedChoiceField}
                options={{choices: regionChoices}}
            />
            <SelectInput
                source="model"
                label="tesseract.gateways.dialog.create.managed.bandwidth"
                choices={bandwidthChoices}
                additionalInfoLabel={availableBandwidth <= 99999 ? {
                    i18nKey: "tesseract.gateways.dialog.create.managed.bandwidthAvailable",
                    values: {availableBandwidth}
                } : undefined}
                validate={(value, data, formValues) => {
                    const totalBandwidth = parseInt(formValues?.initialValues?.model) + bandwidthData?.available;
                    const availableBandwidth = totalBandwidth > 0 ? totalBandwidth : 0;

                    if (value && value > availableBandwidth) {
                        return translate("tesseract.gateways.settings.managed.bandwidth.invalidBandwidth", {availableBandwidth});
                    } else {
                        return undefined;
                    }
                }}
            />
            <PropGateway>
                <Typography className={classes.helpText}>
                    <Trans i18nKey="tesseract.gateways.settings.editBandwidthWarning"/>
                </Typography>
            </PropGateway>
            <BooleanInput
                source="disableSyslogStreaming"
                label="tesseract.syslog.syslogOverride"
                description="tesseract.syslog.descriptions.syslogOverride_gateway"
            />
        </ConnectedForm>
    );
};

export default EditManagedGatewayContent;
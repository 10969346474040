import React from "react";
import DateTime from "./DateTime";
import Input, {InputProps} from "../Input/Input";

export interface DateTimeInputProps extends Omit<InputProps<typeof DateTime>, "component" | "displayError"> {}

/**
 * [Input](/?path=/docs/core-components-inputs-input) Wrapper component format, to work natively with redux form "Field" prop.
 * form ready version of the DateTime component.
 */
export const DateTimeInput = (props: DateTimeInputProps) => (
        <Input {...props} displayError={false} component={DateTime} />
    );

export default DateTimeInput;
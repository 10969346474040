import React, {useState} from "react";
import {
    ConnectedForm,
    DialogBody,
    getArrayDataContent,
    IconSelectorInput,
    SearchableSelectInput,
    SelectArrayInput,
    SelectInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    validateMaxLengthMemo,
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import {get, sortBy} from "lodash";
import {categoryIcons} from "../../../components/iconMapping";
import {User, UserGroup} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import useDemoApi from "../../../hooks/useDemoApi";

const useStyles = makeStyles((theme: Theme) => ({
    headerText: {
        margin: theme.spacing(2),
        color: "#646464"
    },

}));

const hideInput = (key: string, type: string) => (value: any, data: any) => !get(data, key) || get(data, key) !== type;

export interface CreateEditApplicationProps {
    onClose: () => void,
    onSuccess: () => void,
    create?: boolean,
    id?: number | string,
}

export const CreateEditApplication = ({onClose, onSuccess, create, id}: CreateEditApplicationProps) => {
    const [appName, setAppName] = useState('');
    const [virtualWanId,] = useGlobalParam("filter.virtualWanId");
    const [zeroTrustTenant] = useCrudProps(apiResources.zeroTrustSettings);
    const directoriesApi = useDemoApi(apiResources.directories, apiResources.demoDirectories);
    const hasUserDirectory = getArrayDataContent(useCrudProps(directoriesApi)[0].data).length > 0;
    const imageFileTypes = getArrayDataContent(useCrudProps(apiResources.customResponseImageTypes)[0]);
    const visibilityChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.visibility)[0]), "name");
    const [translate] = useTranslation();

    return (
        <DialogBody
            title={create ? "tesseract.endpoint.appCatalog.create.title" : "tesseract.endpoint.appCatalog.edit.title"}
            onClose={onClose}
            form
        >
            <ConnectedForm
                resource={apiResources.appCatalog}
                formatRequestData={(data) => ({
                    ...data,
                    icon: data?.icon?.data || null,
                    iconFilename: data?.icon?.filename || null,
                    virtualWanId
                })}
                formatResourceData={(data) => ({
                    ...data,
                    icon: {data: data?.icon, filename: data?.iconFilename}
                })}
                onChange={(data) => {
                    setAppName(get(data, 'name', ''));
                }}
                validate={(data) => {
                    if (data?.visibility === "usersAndGroups" && !data?.users?.length && !data?.groups?.length) {
                        return {
                            users: translate("tesseract.endpoint.appCatalog.validate.userGroupRequired"),
                            groups: translate("tesseract.endpoint.appCatalog.validate.userGroupRequired")
                        };
                    }
                    return {};
                }}
                params={create ? undefined : {id}}
                onSubmitSuccess={() => {
                    onSuccess();
                    onClose();
                }}
                onCancel={onClose}
                create={create}
            >
                <TextInput
                    label="tesseract.endpoint.appCatalog.create.displayName"
                    description="tesseract.endpoint.appCatalog.create.descriptions.name"
                    source="name"
                    validate={[validateMaxLengthMemo(64)]}
                    isRequired
                />
                <IconSelectorInput
                    label="tesseract.endpoint.appCatalog.create.displayIcon"
                    description="tesseract.endpoint.appCatalog.create.descriptions.icon"
                    source="icon"
                    supportedFiles={imageFileTypes}
                    name={appName}
                />
                <TextInput
                    label="tesseract.endpoint.appCatalog.launchURL"
                    description="tesseract.endpoint.appCatalog.create.descriptions.launchUrl"
                    source="url"
                    validate={[validateMaxLengthMemo(255)]}
                    isRequired
                />
                <SelectInput
                    label="tesseract.endpoint.appCatalog.visibility"
                    description="tesseract.endpoint.appCatalog.create.descriptions.visibility"
                    source="visibility"
                    choices={visibilityChoices}
                />
                <SearchableSelectInput
                    label="tesseract.endpoint.appCatalog.create.associatedApplication"
                    description="tesseract.endpoint.appCatalog.create.descriptions.application"
                    source="app"
                    resource={apiResources.zeroTrustApplicationsZtnaBased}
                    optionValue="id"
                    filterKey="applicationId"
                    options={{
                        dropdownAutoWidth: true
                    }}
                    iconMap={categoryIcons}
                    optionIcon="categoryId"
                    hide={hideInput("visibility", "ztnaBased")}
                    clearOnFocus
                    isRequired
                />
                {!zeroTrustTenant?.data || hasUserDirectory  ? (
                    <>
                        <SelectArrayInput
                            label="tesseract.endpoint.appCatalog.create.users"
                            description="tesseract.endpoint.appCatalog.create.descriptions.users"
                            source="users"
                            resource={hasUserDirectory ? apiResources.users : undefined}
                            disabled={!hasUserDirectory}
                            optionValue="name"
                            options={{dropdownAutoWidth: true}}
                            hide={hideInput("visibility", "usersAndGroups")}
                            icon={<User/>}
                        />
                        <SelectArrayInput
                            label="tesseract.endpoint.appCatalog.create.groups"
                            description="tesseract.endpoint.appCatalog.create.descriptions.groups"
                            source="groups"
                            resource={hasUserDirectory ? apiResources.groups : undefined}
                            disabled={!hasUserDirectory}
                            optionValue="id"
                            options={{
                                dropdownAutoWidth: true
                            }}
                            hide={hideInput("visibility", "usersAndGroups")}
                            icon={<UserGroup/>}
                        />
                    </>
                ) : (
                    <>
                        <SelectArrayInput
                            label="tesseract.endpoint.appCatalog.create.users"
                            description="tesseract.endpoint.appCatalog.create.descriptions.users"
                            source="users"
                            resource={hasUserDirectory ? apiResources.users : undefined}
                            disabled={!hasUserDirectory}
                            // @ts-ignore
                            error={translate("tesseract.endpoint.appCatalog.validate.tenantIdRequired")}
                            optionValue="name"
                            options={{dropdownAutoWidth: true}}
                            hide={hideInput("visibility", "usersAndGroups")}
                            icon={<User/>}
                        />
                        <SelectArrayInput
                            label="tesseract.endpoint.appCatalog.create.groups"
                            description="tesseract.endpoint.appCatalog.create.descriptions.groups"
                            source="groups"
                            resource={hasUserDirectory ? apiResources.groups : undefined}
                            disabled={!hasUserDirectory}
                            // @ts-ignore
                            error={translate("tesseract.endpoint.appCatalog.validate.tenantIdRequired")}
                            optionValue="id"
                            options={{dropdownAutoWidth: true}}
                            hide={hideInput("visibility", "usersAndGroups")}
                            icon={<UserGroup/>}
                        />
                    </>
                )}
            </ConnectedForm>
        </DialogBody>
    );
};

export default CreateEditApplication;
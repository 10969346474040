import React from "react";
import {BCCEnvironment, UserDirectoryBCC} from "../../UserDirectoriesTypes";
import {ConnectedForm, getArrayDataContent, SelectInput, TextArrayInput, useCrudProps} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import useDemoApi from "../../../../../../hooks/useDemoApi";

type UserDirectoryBCCFormProps = {
    directory: UserDirectoryBCC;
    create: boolean;
    id?: string;
    onClose: (refresh: boolean) => void;
}

export const UserDirectoryBCCForm: React.FC<UserDirectoryBCCFormProps> = ({
                                                                              onClose,
                                                                              create,
                                                                              directory,
                                                                              id,
                                                                          }: UserDirectoryBCCFormProps) => {
    const bccEnvironmentsApi = useDemoApi(apiResources.identityBCCEnvironments, apiResources.demoBCCEnvironments);
    const directoryEnvironmentsData = getArrayDataContent(useCrudProps(bccEnvironmentsApi));
    let directoryEnvironments = directoryEnvironmentsData[0]?.data?.content;

    directoryEnvironments = directoryEnvironments?.map((directoryEnvironment: BCCEnvironment) => ({
        ...directoryEnvironment,
        disabled: directoryEnvironment.directory_disabled
    }));

    return (
        <ConnectedForm
            create={create}
            resource={apiResources.directories}
            params={create ? undefined : {id}}
            onSubmitSuccess={() => onClose(true)}
            onCancel={() => onClose(false)}
            initialValues={directory}
            formatRequestData={(data) => ({
                ...data,
                displayName: directoryEnvironments.find((directoryEnvironment: BCCEnvironment) => directoryEnvironment.id === data.options.environmentId)?.name,
            } as UserDirectoryBCC)}
        >
            <SelectInput
                source="options.environmentId"
                label="tesseract.identity.userDirectories.form.bcc.environment"
                optionValue="id"
                choices={directoryEnvironments}
                emptyLabel="tesseract.identity.userDirectories.form.bcc.environment"
                disabled={!create}
            />

            <TextArrayInput
                source="options.groupsIncluded"
                label="tesseract.identity.userDirectories.form.groupsIncluded"
            />
        </ConnectedForm>
    );
};
import React, {useContext} from "react";
import {useWatch} from "react-hook-form";
import StepContent, {StepContentProps} from "../StepContent/StepContent";
import WizardToolbar from "../WizardToolbar/WizardToolbar";
import FormErrorReporter from "../../forms/FormErrorReporter";
import {BaseStepProps} from "../StepType";
import {FormPropsContext} from "../../forms/FormProvider/FormProvider";

export interface SubmitStepProps extends BaseStepProps, StepContentProps {
}

/**
 * Wizard step component for submitting the form.
 *
 * For use as a child of the [Wizard]() component, as it provides most of the context and required props.
 *
 * Renders the appropriate content section and toolbar. Children are rendered within the content section
 * (and are usually *Input components, *Field components, or WizardSummary).
 *
 * All additional props not described below are passed to the [StepContent](/?path=/docs/core-components-wizard-wizardtoolbar--wizard-toolbar).
 *
 * Some additional props can be set on InputStep, and are consumed by the parent [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) (such as "label").
 */
export const SubmitStep = (props: SubmitStepProps) => {
    const {
        active,
        back,
        children,
        form,
        formErrorReporterValue,
        formErrorReporterSetError,
        preventBackNavigation,
        result,
        secondaryButtonOnClick,
        secondaryButtonLabel,
        primaryLabel = "cuda.buttons.save",
        title,
        toolbarDisabled,
        secondaryColor,
        ...contentProps
    } = props;
    const data = useWatch();
    const {handleSubmitWithOptions} = useContext(FormPropsContext);

    return (
        <div style={active ? undefined : {display: "none"}}>
            <FormErrorReporter
                value={formErrorReporterValue}
                setError={formErrorReporterSetError}
            >
                <StepContent
                    childProps={{data, result}}
                    title={title}
                    {...contentProps}
                >
                    {children}
                </StepContent>
            </FormErrorReporter>
            <WizardToolbar
                back={!preventBackNavigation && back || undefined}
                primaryLabel={primaryLabel}
                primaryOnClick={handleSubmitWithOptions?.(true)}
                secondaryLabel={secondaryButtonLabel}
                secondaryOnClick={secondaryButtonOnClick ? () => secondaryButtonOnClick(data, result) : undefined}
                secondaryColor={secondaryColor}
                toolbarDisabled={toolbarDisabled}
            />
        </div>
    );
};

SubmitStep.defaultProps = {
    hideErrors: true
};

export default SubmitStep;
import React from "react";

export type PassdownProps<Props extends object> = Omit<Props, "alternateWrapper" | "children" | "condition" | "wrapper">;

export interface ConditionalWrapperProps<Props extends object, Children extends (React.ReactElement | React.ReactElement[])> {
    /**
     * callback function that returns the React nodes to render when condition is false.
     * The default function for alternateWrapper simply renders the children, passing through all of the props.
     *
     * @function
     * @param {node} children the child/children as passed to ConditionWrapper.
     * @param {object} props all the other props that were provided to ConditionalWrapper.
     * @returns {node} the React nodes to render.
     */
    alternateWrapper?: (children: Children, props: PassdownProps<Props>)=> React.ReactNode | React.ReactNode[],
    /**
     * React children. Passed to the wrapper/alternateWrapper, or rendered by themselves if condition is false and alternateWrapper is not provided.
     *
     * If no children are provided, nothing is rendered, regardless of condition state.
     */
    children: Children,
    /**
     * boolean, or function that returns a boolean, defining the current condition state.
     *  - If true, the provided wrapper is rendered.
     *  - If false, the provided alternateWrapper is rendered.
     *
     *  @function
     *  @param {object} props all the other props that were provided to ConditionalWrapper.
     *  @returns {boolean} the current condition state.
     */
    condition?: boolean | ((props: PassdownProps<Props>) => boolean),
    /**
     * callback function that returns the React nodes to render when condition is true.
     *
     * @function
     * @param {node} children the child/children as passed to ConditionWrapper.
     * @param {object} props all the other props that were provided to ConditionalWrapper.
     * @returns {node} the React nodes to render.
     */
    wrapper: (children: Children, props: PassdownProps<Props>) => React.ReactNode | React.ReactNode[],
}

export const defaultAlternateConditionalWrapper = (children: React.ReactElement | React.ReactElement[], props: any) => React.Children.map(
    children, (child) => child && React.cloneElement((child as React.ReactElement), props) || null
);

/**
 * Renders children by using either the "wrapper" or "alternativeWrapper" method, according to the provided condition.
 *
 * This can be used to clarify or help keep code readable, reduce replication (especially of the children), and provide
 * flexibility to components that should render based on a "passed down" prop that is not directly accessible to the defining component.
 */
export const ConditionalWrapper = <Children extends (React.ReactElement | React.ReactElement[]), Props extends ConditionalWrapperProps<Props, Children>>({alternateWrapper = defaultAlternateConditionalWrapper, condition, wrapper, children, ...props}: Props) => {
    const conditionValue = typeof condition === "function" ? condition(props) : condition;

    return (
        <React.Fragment>
            {conditionValue ? wrapper(children, props) : alternateWrapper(children, props)}
        </React.Fragment>
    );
};

export default ConditionalWrapper;
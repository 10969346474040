import apiResources from "../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import React from "react";
import {ChipArrayField, ConnectedForm, TextInput} from "@cuda-react/core";
import {createStyles, makeStyles} from "@mui/styles";

interface WizardLocalUsersProps {
    userData: any[],
    userDataLoading?: boolean,
    onSuccess: () => void
    onSubmit: () => void
    onError: (error: any) => void
}

const styles = createStyles({
    subtitle: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 16
    },
    chips: {
        marginLeft: 268
    }
});
const useStyles = makeStyles(styles);

export const WizardLocalUsers = (props: WizardLocalUsersProps) => {
    const {userData, onSuccess, onSubmit, onError} = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <ConnectedForm
                disabled={userData.length > 4 || props.userDataLoading}
                resource={apiResources.identityUsers}
                formButtonToolbarProps={{
                    label: "tesseract.setupWizard.wizardStep2.userDirectories.addUser",
                    color: "secondary",
                    buttonsOnly: true
                }}
                initialValues={{name: "", email: ""}}
                formatRequestData={(data: any) => {
                    onSubmit();
                    return data;
                }}
                formatError={(error: any) => {
                    onError(error);
                    return error;
                }}
                onSubmitSuccess={onSuccess}
                clearOnSuccess
                create
                flat
            >
                <Typography className={classes.subtitle}>
                    <Trans i18nKey="tesseract.setupWizard.wizardStep2.userDirectories.localUserDirectory"/>
                </Typography>
                <Typography sx={{paddingBottom: "16px"}}>
                    <Trans i18nKey="tesseract.setupWizard.wizardStep2.userDirectories.addingUsers"/>
                </Typography>
                <ChipArrayField classes={{root: classes.chips}} data={{userData}} source="userData"/>
                <TextInput
                    source="name"
                    label="tesseract.setupWizard.wizardStep2.userDirectories.localUserName"
                    isRequired
                    disabled={userData.length > 4}
                    newStyle
                />
                <TextInput
                    source="email"
                    label="tesseract.setupWizard.wizardStep2.userDirectories.localUserEmail"
                    isRequired
                    disabled={userData.length > 4}
                    newStyle
                />
            </ConnectedForm>
            <Typography className={classes.subtitle}>
                <Trans i18nKey="tesseract.setupWizard.wizardStep2.identityProviders.identityProvider"/>
            </Typography>
            <Typography>
                <Trans i18nKey="tesseract.setupWizard.wizardStep2.identityProviders.localUserDirectoryDescription">
                    <strong/>
                </Trans>
            </Typography>
        </React.Fragment>
    );
};
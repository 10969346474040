import React from 'react';
import {BasicPage} from '@cuda-react/core';
import {IdentityProviders} from './identityProviders/IdentityProviders';
import {UserDirectories} from './userDirectories/UserDirectories';

export const IdentitySettings = () => (
        <BasicPage
            title='tesseract.identity.pageTitle'
            subtitle='tesseract.identity.pageSubtitle'
        >
            <IdentityProviders />
            <UserDirectories />
        </BasicPage>
    );

export default IdentitySettings;
import {CustomField, DateField, getArrayDataContent, StatusIconField, TablePage, TextField, useCrudProps, useMixpanel} from "@cuda-react/core";
import React from "react";
import apiResources from "../../../../apiResources";

export const createdRanges = [
    {key: "1", name: "tesseract.audit.filter.created.days1"},
    {key: "3", name: "tesseract.audit.filter.created.days3"},
    {key: "7", name: "tesseract.audit.filter.created.weeks1"},
    {key: "14", name: "tesseract.audit.filter.created.weeks2"},
    {key: "30", name: "tesseract.audit.filter.created.months1"},
    {key: "60", name: "tesseract.audit.filter.created.months2"},
    {key: "90", name: "tesseract.audit.filter.created.months3"},
    {key: "180", name: "tesseract.audit.filter.created.months6"},
    {key: "270", name: "tesseract.audit.filter.created.months9"},
    {key: "365", name: "tesseract.audit.filter.created.years1"}
];

export interface ApplianceAuditLogProps {
    details: {
        gatewayType?: "cloud" | "on-prem" | "managed",
        model?: string,
        modelSeries?: "S" | "T"
    },
    id: string | number,
    messagesType?: string,
    resource?: string
}

const ApplianceAuditLog: React.FC<ApplianceAuditLogProps> = (props) => {
    const {id, resource, details: {model, gatewayType, modelSeries}} = props;
    const auditStatuses = useCrudProps(apiResources.auditStatuses, {}, {}, true)[0]?.data;
    const auditTypes = useCrudProps(apiResources.auditTypes, {}, {}, true)[0]?.data;
    const auditEvents = useCrudProps(apiResources.auditEvents, {}, {}, true)[0]?.data;

    useMixpanel(
        gatewayType ? "Gateways Audit" : "Sites Audit",
        !!model,
        {model: model || "", type: gatewayType || modelSeries || ""}
    );

    return (
        <TablePage
            authenticated
            resource={id ? resource : undefined}
            params={{id}}
            minCellWidth={140}
            pageMode="paginate"
            hasTitle
            sortable
        >
            <StatusIconField
                isAudit
                source="status"
                label="tesseract.logs.auditLog.labels.status"
                width={96}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(auditStatuses)
                }}
            />
            <DateField
                source="created"
                label="tesseract.logs.auditLog.labels.created"
                dateOnly={false}
                width={164}
                filter="select"
                filterProps={{
                    choices: createdRanges
                }}
            />
            <CustomField
                source="type"
                label="tesseract.logs.auditLog.labels.type"
                render={(value, record) => record.typeName}
                width={160}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(auditTypes)
                }}
            />
            <CustomField
                source="event"
                label="tesseract.logs.auditLog.labels.event"
                render={(value, record) => record.eventName}
                width={160}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(auditEvents)
                }}
            />
            <TextField
                source="description"
                label="tesseract.logs.auditLog.labels.description"
                sortable={false}
            />
            <TextField
                source="remoteAddress"
                label="tesseract.logs.auditLog.labels.remoteAddress"
                width={142}
                filter="text"
            />
            <TextField
                source="createdUsername"
                label="tesseract.logs.auditLog.labels.createdUsername"
                width={200}
                filter="text"
            />
        </TablePage>
    );
};

export default ApplianceAuditLog;
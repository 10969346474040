import React, {ChangeEvent} from "react";

export interface CommonInputProps {
    /**
     * custom css className to apply to the filter
     */
    className?: string,
    /**
     * html id to add to the filter
     */
    id?: string,
    /**
     * props to pass through to the input inside the component
     */
    InputProps?: { classes: { root: string } },
    /**
     * callback for when the value changes in the given filter component
     */
    onChange?: (event: ChangeEvent<HTMLDivElement>) => void,
    /**
     * the current value for the filter
     */
    value?: any,
    /**
     * label for the filter
     */
    label?: string,
    /**
     * the display mode of the component, specifically for material-ui components
     * @ignore
     */
    variant?: string
}
export type TableFilterProps<Component extends React.ElementType> = Omit<React.ComponentProps<Component>, "source" | "component" | "id"> & CommonInputProps & {
    /**
     * unique identifier for the filter, used by filterValues
     */
    source: string,
    /**
     * element to render as the filter input.
     */
    component: Component
}

const TableFilter = <Component extends React.ElementType>({component, source: _ignore, ...props}: TableFilterProps<Component>) => React.createElement(component, props);

export default TableFilter;
import {StatusIcon} from "@cuda-react/icons";
import {Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    successIcon: {
        margin: theme.spacing(2, "auto"),
        width: 64,
        height: 64,
        display: "inherit"
    },
    successHeader: {
        margin: theme.spacing(1, 2),
        textAlign: "center",
        width: `calc(100% - ${theme.spacing(4)})`
    },
    successMessage: {
        textAlign: "center",
        width: `calc(100% - ${theme.spacing(4)})`,
        margin: theme.spacing(1, 2, 2)
    }
}));
export type EnrollSuccessStepProps = {
    data?: any
    result?: any,
    create?: boolean
}

export const EnrollSuccessStep = ({data, result, create}: EnrollSuccessStepProps) => {
    const classes = useStyles();
    const [translate] = useTranslation();

    return create ? (
        <React.Fragment>
            <StatusIcon status="ok" className={classes.successIcon}/>
            <Typography variant="h6" className={classes.successHeader}>
                {translate(
                    result?.data?.newlyEnrolled === 0 ?
                        "tesseract.endpoint.enrollEmailDialog.unSuccessHeaderAllUsersAlreadyEnrolled" :
                        `tesseract.endpoint.enrollEmailDialog.${data?.enrollmentEmailEnabled ? "successHeader" : "noEmailSuccessHeader"}`
                    ,
                    {
                        count: result?.data?.newlyEnrolled,
                        context: result?.data?.newlyEnrolled === 0 && (result?.data?.alreadyEnrolled + result?.data?.unenrollable > 1) ? "plural" : result?.data?.newlyEnrolled > 1 ? "plural" : undefined
                    }
                )}
            </Typography>
            {result?.data?.alreadyEnrolled + result?.data?.unenrollable > 0 && (
                <Typography className={classes.successMessage}>
                    {translate("tesseract.endpoint.enrollEmailDialog.alreadyEnrolledUsers",
                        {
                            context: `${data?.enrollmentEmailEnabled ? "email" : ""}${result?.data?.unenrollable > 0 ? "workspace": ""}${result?.data?.alreadyEnrolled + result?.data?.unenrollable > 1 ? "plural" : ""}`,
                            totalCount: result?.data?.alreadyEnrolled + result?.data?.unenrollable,
                            unenrollableCount: result?.data?.unenrollable
                        }
                    )}
                </Typography>
            )}
            <Typography className={classes.successMessage}>
                {result?.data?.newlyEnrolled > 0 ?
                    translate("tesseract.endpoint.enrollEmailDialog.successMessage", {context: result?.data?.newlyEnrolled > 1 ? "plural" : undefined})
                    : null
                }
            </Typography>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <StatusIcon status="ok" className={classes.successIcon}/>
            <Typography variant="h6" className={classes.successHeader}>
                {translate(
                    "tesseract.endpoint.enrollEmailDialog.successHeaderOld",
                    {
                        count: data?.users?.length,
                        context: data?.users?.length > 1 ? "plural" : undefined,
                        users: data?.users
                    }
                )}
            </Typography>
            <Typography className={classes.successMessage}>
                {translate("tesseract.endpoint.enrollEmailDialog.successMessage", {context: data?.users?.length > 1 ? "plural" : undefined})}
            </Typography>
        </React.Fragment>
    );
};

export default EnrollSuccessStep;
import React from "react";
import {ListCardContent, TextField} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import {CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import {BaseCard} from "../../baseCards";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    userSummaryWrapper: {
        maxHeight: 280,
        overflow: 'hidden'
    }
}));

export interface UserSummaryCardProps extends DashboardCardProps {
    size: CardSizes,
    additionalProps: {id: number, loading: boolean}
}

/**
 * Card that displays a Summary of the User
 *
 * This Card shows in a summary Full Name, Email and Device Count of a user.
 *
 */
export const UserSummaryCard = (props: UserSummaryCardProps) => {
    const classes = useStyles();

    return (
        <>
            <BaseCard
                id="UserSummaryCard"
                title="stratosReporting.userSummaryCard.title"
                size={props.size}
                classes={{cardContent: classes.userSummaryWrapper}}
            >
                <ListCardContent
                    getFrom={{
                        resource: apiResources.userStatus,
                        params: {id: props?.additionalProps?.id}
                    }}
                    visible
                >
                    <TextField label="stratosReporting.userSummaryCard.fullName" source="fullName"/>
                    <TextField label="stratosReporting.userSummaryCard.email" source="email"/>
                    <TextField label="stratosReporting.userSummaryCard.deviceCount" source="deviceCount"/>
                    <TextField label="stratosReporting.userSummaryCard.deviceCount" source="completed"/>
                </ListCardContent>
            </BaseCard>
        </>
    );
};
import React from "react";
import {LoadingMessage, useCrudProps} from '@cuda-react/core';
import apiResources from "../../../../../../apiResources";
import {Box, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import useDemoApi from "../../../../../../hooks/useDemoApi";

export const RedirectUriInfo: React.FC<{}> = () => {
    const [translate] = useTranslation();
    const configurationApi = useDemoApi(apiResources.identityProvidersConfiguration, apiResources.demoIdentityProvidersConfiguration);
    const [{data}, loading] = useCrudProps(configurationApi);

    return (
        <Box pl={8} mt={2} mb={4}>
            <Typography color="secondary" mb={1}
                        fontWeight="bold">{translate('tesseract.identity.identityProviders.add.redirectUri')}</Typography>
            {
                loading ? <LoadingMessage/> : (
                    <Typography color="secondary">{data?.oidcRedirectURL}</Typography>
                )
            }
        </Box>
    );
};
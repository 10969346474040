import {CudaTheme, makeOverrideableStyles} from "@cuda-react/theme";
import {Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const styles = (theme: Theme & typeof CudaTheme) => createStyles({
    root: {
        paddingBottom: theme.spacing(1.2)
    },
    title: {
        padding: theme.spacing(1.5, 3, 1.5),
        marginBottom: theme.spacing(1),
        textTransform: "uppercase",
        fontSize: 16,
        color: theme.palette.text.secondary
    },
    titleNewStyle: {
        padding: theme.spacing(1.5, 3, 0, 1.5),
        marginBottom: theme.spacing(1),
        fontSize: 20,
        color: "#4E4D4D"
    },
    borderNewStyle: {
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        borderWidth: "0 0 1px",
        margin: theme.spacing(1.5, 2, 1.5, 2)
    },
    subtitle: {
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    titleWithoutBorder: {
        padding: theme.spacing(1.5, 3.5, 0, 1.5),
        marginBottom: theme.spacing(1),
        fontSize: 20,
        color: "#4E4D4D"
    }
});
const useStyles = makeOverrideableStyles("FormSection", styles);


export type FormSectionProps = {
    /** it defines the children of a form section */
    children: React.ReactNode,

    /** the subtitle of the form section */
    subtitle?: string,

    /** the title of the form section */
    title?: string,

    /** whether to display the contents with the new style */
    newStyle?: boolean,

    /** whether to display a border under the title */
    hideBorder?: boolean,
}

/**
 * FormSection
 *
 * it is a section containing as children forms. It can have title and subtitle if the values are defined
 */
export const FormSection = (props: FormSectionProps): JSX.Element => {
    const {title, children, subtitle, newStyle, hideBorder, ...childProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    return (
        !newStyle ?
        <div className={classes.root}>
            {title ?
                <Typography variant="h6" className={classes.title}>{translate(title)}</Typography>
                : null}
            {subtitle ? (
                <Typography variant="subtitle1" className={classes.subtitle}>{translate(subtitle)}</Typography>
            ) : null}
            {React.Children.map(children, (child) => React.isValidElement(child) ? React.cloneElement(child, childProps) : child)}
        </div>
            :
        <div className={classes.root}>
            {title ? <div className={!hideBorder ? classes.borderNewStyle : classes.titleWithoutBorder}><Typography variant="h6" className={classes.titleNewStyle}>{translate(title)}</Typography></div>
                : null}
            {React.Children.map(children, (child) => React.isValidElement(child) ? React.cloneElement(child, childProps) : child)}
        </div>
    );
};

export default FormSection;
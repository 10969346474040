import React from "react";
import {
    BooleanInput,
    getArrayDataContent,
    MultiInput,
    SelectInput,
    TextInput,
    useCrudProps,
    useDeepCompareMemo
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../../apiResources";
import {get} from "lodash";
import {useWatch} from "react-hook-form";
import NewBooleanInput from "../../../../setupWizard/stepComponents/NewBooleanInput";

const useStyles = makeStyles({
    appMinimumVersion: {
        paddingLeft: ({newStyle}: any) => newStyle ? 66 : 32
    },
    osPlatform: {
        paddingLeft: ({newStyle}: any) => newStyle ? 66 : 32
    },
    osMinimumVersion: {
        paddingLeft: 0
    },
    inputWide: {
        width: ({newStyle}: any) => newStyle ? 432 : 128
    },
    inputNarrow: {
        width: ({newStyle}: any) => newStyle ? 104 : 128,
        backgroundColor: ({newStyle}: any) => newStyle ? "#FFF" : undefined
    },
    input: {
        marginTop: ({newStyle}: any) => newStyle ? 0 : undefined,
        width: 128
    },
    multiInputGroup: {
        minHeight: 50
    },
    multiInputButtons: ({newStyle}: any) => newStyle ? {
        margin: "0 0 0 16px",
        paddingTop: "4px"
    } : {}
});

export const defaultDeviceAttributes = {
    deviceCriteria: {
        requireScreenLock: true,
        requireFirewall: true,
        requireNotJailbroken: true,
        requireDiskEncryption: false,
        requireAntivirus: true,
    }
};

const validatePlatforms = (value: any, data: any, {t: translate}: any) =>
    get(data, "deviceCriteria.osMinimumVersions", [])
        .map(({platform}: any) => platform)
        .filter((platform: any) => value === platform)
        .length > 1 ? translate("tesseract.endpoint.remoteAccessPolicies.validatePlatform") : undefined;

export const validateDeviceAttributes = (translate: any) => (data: any) => {
    const error = get(data, "accessCriteria.securityPosture", "disable") !== "disable" && !(
        get(data, "deviceCriteria.requireScreenLock") ||
        get(data, "deviceCriteria.requireFirewall") ||
        get(data, "deviceCriteria.requireNotJailbroken") ||
        get(data, "deviceCriteria.requireDiskEncryption") ||
        get(data, "deviceCriteria.appUpdates") ||
        get(data, "deviceCriteria.osUpdates") ||
        get(data, "deviceCriteria.requireAntivirus")
    ) ? translate("tesseract.endpoint.remoteAccessPolicies.validateDeviceCriteria") : undefined;
    return error ? {
        deviceCriteria: {
            requireScreenLock: error,
            requireFirewall: error,
            requireNotJailbroken: error,
            requireDiskEncryption: error,
            appUpdates: error,
            osUpdates: error,
            requireAntivirus: error,
        }
    } : {};
};

export const DeviceAttributesInputs = ({newStyle}: { newStyle?: boolean }) => {
    const platformChoices = getArrayDataContent(useCrudProps(apiResources.zeroTrustPoliciesOperatingSystem)[0].data);
    const classes = useStyles({newStyle});
    const activePlatformChoices = (useWatch({name: "deviceCriteria.osMinimumVersions"}) || []).map(({platform}: any) => platform);
    const nextPlatformDefault = useDeepCompareMemo(
        // I have no idea why the last entry needs to be sliced off. It seems like somehow the activePlatformChoices gets the selected defaultChoice prior to the Select
        // component stabilising, so you need to ignore that last value, otherwise it will skip it.
        () => platformChoices.find((choice) => !activePlatformChoices.slice(0, activePlatformChoices.length - 1).includes(choice.key))?.key,
        [activePlatformChoices, platformChoices]
    );

    const BooleanInputComponent = newStyle ? NewBooleanInput : BooleanInput;

    return (
        <React.Fragment>
            <BooleanInputComponent
                label="tesseract.endpoint.remoteAccessPolicies.screenLock"
                description={newStyle ? undefined : "tesseract.endpoint.remoteAccessPolicies.descriptions.screenLock"}
                additionalInfoLabel={newStyle ? "tesseract.endpoint.remoteAccessPolicies.descriptions.screenLock" : undefined}
                source="deviceCriteria.requireScreenLock"
                newStyle={newStyle}
            />
            <BooleanInputComponent
                label="tesseract.endpoint.remoteAccessPolicies.firewall"
                description={newStyle ? undefined : "tesseract.endpoint.remoteAccessPolicies.descriptions.firewall"}
                additionalInfoLabel={newStyle ? "tesseract.endpoint.remoteAccessPolicies.descriptions.firewall" : undefined}
                source="deviceCriteria.requireFirewall"
                newStyle={newStyle}
            />
            <BooleanInputComponent
                label="tesseract.endpoint.remoteAccessPolicies.antivirus"
                description={newStyle ? undefined : "tesseract.endpoint.remoteAccessPolicies.descriptions.antivirus"}
                additionalInfoLabel={newStyle ? "tesseract.endpoint.remoteAccessPolicies.descriptions.antivirus" : undefined}
                source="deviceCriteria.requireAntivirus"
                newStyle={newStyle}
            />
            <BooleanInputComponent
                label="tesseract.endpoint.remoteAccessPolicies.jailbroken"
                description={newStyle ? undefined : "tesseract.endpoint.remoteAccessPolicies.descriptions.jailbroken"}
                additionalInfoLabel={newStyle ? "tesseract.endpoint.remoteAccessPolicies.descriptions.jailbroken" : undefined}
                source="deviceCriteria.requireNotJailbroken"
                newStyle={newStyle}
            />
            <BooleanInputComponent
                label="tesseract.endpoint.remoteAccessPolicies.encryption"
                description={newStyle ? undefined : "tesseract.endpoint.remoteAccessPolicies.descriptions.encryption"}
                additionalInfoLabel={newStyle ? "tesseract.endpoint.remoteAccessPolicies.descriptions.encryption" : undefined}
                source="deviceCriteria.requireDiskEncryption"
                newStyle={newStyle}
            />
            <BooleanInputComponent
                label="tesseract.endpoint.remoteAccessPolicies.appUpdates"
                description={newStyle ? undefined : "tesseract.endpoint.remoteAccessPolicies.descriptions.appUpdates"}
                additionalInfoLabel={newStyle ? "tesseract.endpoint.remoteAccessPolicies.descriptions.appUpdates" : undefined}
                source="deviceCriteria.appUpdates"
                newStyle={newStyle}
            />
            <TextInput
                label="tesseract.endpoint.remoteAccessPolicies.minimumVersion"
                source="deviceCriteria.appMinimumVersion"
                hide={(value, data) => !get(data, "deviceCriteria.appUpdates")}
                inputLabelProps={{minimised: true, classes: {root: classes.appMinimumVersion}}}
                inputClasses={{textField: classes.inputWide}}
                newStyle={newStyle}
                isRequired
            />
            <BooleanInputComponent
                label="tesseract.endpoint.remoteAccessPolicies.osUpdates"
                description={newStyle ? undefined : "tesseract.endpoint.remoteAccessPolicies.descriptions.osUpdates"}
                additionalInfoLabel={newStyle ? "tesseract.endpoint.remoteAccessPolicies.descriptions.osUpdates" : undefined}
                source="deviceCriteria.osUpdates"
                newStyle={newStyle}
            />
            <MultiInput
                source="deviceCriteria.osMinimumVersions"
                hide={(value, data) => !get(data, "deviceCriteria.osUpdates")}
                classes={{inputGroup: classes.multiInputGroup, buttons: classes.multiInputButtons}}
                maxInputs={4}
                isRequired
            >
                <SelectInput
                    label="tesseract.endpoint.remoteAccessPolicies.platform"
                    source="platform"
                    choices={platformChoices}
                    inputLabelProps={{minimised: true, classes: {root: classes.osPlatform}}}
                    inputClasses={{select: classes.inputNarrow, formControl: classes.inputNarrow}}
                    inputProps={{defaultChoice: nextPlatformDefault}}
                    validate={validatePlatforms}
                    newStyle={newStyle}
                    isRequired
                />
                <TextInput
                    label="tesseract.endpoint.remoteAccessPolicies.minimumVersion"
                    source="version"
                    inputLabelProps={{minimised: true, classes: {root: classes.osMinimumVersion}}}
                    inputClasses={{textField: classes.input}}
                    newStyle={newStyle}
                    isRequired
                />
            </MultiInput>
        </React.Fragment>
    );
};

export default DeviceAttributesInputs;
import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ColumnSelectorDialog from "../../components/table/ColumnSelectorDialog/ColumnSelectorDialog";
import {debounce, isEqual} from "lodash";
import {useDeepCompareCallback, useDeepCompareMemo, usePersistedState} from "../UtilHooks";
import {TFunction} from "i18next";
import {GridColumnReorderEvent, GridColumnResizeEvent} from "@progress/kendo-react-grid";
import {HeaderCellRender} from "../../utils/commonTypes";

export interface ColumnFromChild extends Pick<useDataTablePersonalisedColumnsChildProps, "alwaysVisible" | "field" | "width" | "minResizableWidth"> {
    childIndex: number,
    orderIndex: number,
    show: boolean,
    title: string
}

const generateColumnsFromChildren = (
    childArray: useDataTablePersonalisedColumnsChild[],
    translate: TFunction
): ColumnFromChild[] => childArray.map(({
                                            props: {
                                                alwaysVisible,
                                                field,
                                                title,
                                                hideByDefault,
                                                editColumnLabel,
                                                width,
                                                minResizableWidth
                                            }
                                        }, index) => ({
    childIndex: index,
    orderIndex: index,
    show: !hideByDefault,
    title: translate(editColumnLabel || title || " "),
    alwaysVisible,
    field,
    width,
    minResizableWidth
}));

export interface useDataTablePersonalisedColumnsChildProps {
    /**
     * if true, this column will always visible cannot be removed by the editColumns functionality.
     */
    alwaysVisible?: boolean,
    /**
     * alternative label to show for this column in the edit column menu. If not provided, the title prop is used.
     */
    editColumnLabel?: string,
    /**
     * the name of the field which the column relates to
     */
    field: string,
    /**
     * if true, this column will not be visible initially (but can be added by the user if editColumns is active).
     */
    hideByDefault?: boolean,
    /**
     * used by the DataTable to determine how much to allow the user to resize that column
     */
    minResizableWidth?: number,
    /**
     * which position the column should be displayed in
     */
    orderIndex?: number,
    /**
     * title for the column
     */
    title: string,
    /**
     * width of column in pixels
     *
     * takes precedence over minCellWidth prop
     */
    width: number
}

export type useDataTablePersonalisedColumnsChild = ReactElement<useDataTablePersonalisedColumnsChildProps>

export interface useDataTablePersonalisedColumnsProps {
    /**
     * if true, functionality for editing which columns are visible will be added.
     */
    editColumns?: boolean,
    /**
     * if true, functionality for resizing the columns will be added.
     */
    resizable?: boolean,
    /**
     * if true, functionality for reordering the columns will be added.
     */
    reorderable?: boolean,
    /**
     * the minimum width (in pixels) that columns can be shrunk to when resizing.
     */
    minCellWidth: number,
    /**
     * a unique table name, used for saving the personalised table columns for next use. Not providing a tableName will mean that changes will not persist.
     */
    tableName?: string
}

/**
 * Hook for providing personalisable column functionality to a BDS DataTable (or other tables based on it, such as Table and ConnectedTable).
 *
 * If you already make use of "headerCellRender", you should instead provide your method to the returned render functions as the third argument. I.e:
 *
 * @example
 * headerCellRender={(cell, cellProps) => dataTableSelectProps.headerCellRender(cell, cellProps, yourRenderMethod)}
 */
export const useDataTablePersonalisedColumns = (
    /**
     * array of the child DataTableColumns for the DataTable.
     */
    children: useDataTablePersonalisedColumnsChild[],
    props: useDataTablePersonalisedColumnsProps
) => {
    const {editColumns, resizable, reorderable, minCellWidth, tableName} = props;
    const [persistentSavedColumns, setPersistentSavedColumns] = usePersistedState<ColumnFromChild[]>("cudaPersonalisedTableColumns-" + tableName);
    const [translate] = useTranslation();
    const [localSavedColumns, setLocalSavedColumns] = useState<ColumnFromChild[]>();
    const saveColumns = (columns: ColumnFromChild[]) => tableName ? setPersistentSavedColumns(columns) : setLocalSavedColumns(columns);
    const savedColumns: ColumnFromChild[] = (tableName ? persistentSavedColumns : localSavedColumns) || generateColumnsFromChildren(children, translate);

    // On first load, verify if the columns provided to the table have changed since last persistent save (and update them if so)
    useEffect(() => {
        if (persistentSavedColumns) {
            const inputColumns = generateColumnsFromChildren(children, translate);
            const comparableColumnFields = ({childIndex, title, alwaysVisible, field}: ColumnFromChild) => ({
                childIndex,
                title,
                alwaysVisible,
                field
            });

            // If the key values (child index, title, alwaysVisible, source field) or the number of columns have changed, then we need to regenerate the columns
            // but still preserve the "edited" settings where possible
            if (!isEqual(inputColumns.map(comparableColumnFields), persistentSavedColumns.map(comparableColumnFields))) {
                const unresolvedColumns = [...persistentSavedColumns];
                const assignedOrderIndexes: number[] = [];

                const resolvedColumns = inputColumns.map((column) => {
                    // Find if the column exists in the old state, via first matching "field".
                    const existingColumnIndex = unresolvedColumns.findIndex(({field}) => column.field === field);
                    let resolvedColumn = column;

                    // If found, remove the column from the old state( so it cannot be "matched" again). then apply the edited values (orderIndex, show, width).
                    if (existingColumnIndex >= 0) {
                        const existingColumn = unresolvedColumns[existingColumnIndex];
                        unresolvedColumns.splice(existingColumnIndex, 1);
                        resolvedColumn = {
                            ...column,
                            orderIndex: existingColumn.orderIndex,
                            show: column.alwaysVisible || existingColumn.show,
                            width: existingColumn.width || column.width,
                        };
                    }

                    // Ensure that the orderIndex is unique, by progressing it onwards if already in use.
                    while (assignedOrderIndexes.includes(resolvedColumn.orderIndex)) {
                        resolvedColumn.orderIndex++;
                    }
                    assignedOrderIndexes.push(resolvedColumn.orderIndex);

                    return resolvedColumn;
                });

                saveColumns(resolvedColumns);
            }
        }
    }, []);

    const actions = <ColumnSelectorDialog
        columns={savedColumns}
        defaultColumns={generateColumnsFromChildren(children, translate)}
        onSave={(columns) => saveColumns((columns as ColumnFromChild[]).map((column) => ({
            ...column,
            // If the column is being added, we need to ensure it has a width set, otherwise it can appear with 0 width
            width: column.width || column.minResizableWidth || minCellWidth
        })))}
    />;

    const onColumnReorder = useDeepCompareCallback((event: GridColumnReorderEvent) => {
        const updatedColumns = savedColumns.map((column) => ({
            ...column,
            orderIndex: event.columns.find((col) => col.field === column.field)?.orderIndex ?? column.orderIndex
        }));
        saveColumns(updatedColumns);
    }, [savedColumns]);

    const onColumnResize = useDeepCompareCallback(debounce((event: GridColumnResizeEvent) => {
        const resizedColumn = event.columns.sort((column1, column2) => (column1.orderIndex ?? 0) - (column2.orderIndex ?? 0))[event.index];
        const columnMinWidth = resizedColumn.minResizableWidth || 0;
        const newWidth = event.oldWidth < columnMinWidth ? columnMinWidth : event.oldWidth;
        const updatedColumns = savedColumns.map((column) => ({
            ...column,
            width: column.orderIndex === resizedColumn.orderIndex ? newWidth : column.width
        }));
        saveColumns(updatedColumns);
    }, 250), [minCellWidth, savedColumns]);

    const displayedColumns = savedColumns && savedColumns.filter((column) => column.show).map((column) => column.field) || [];
    const newChildren = useDeepCompareMemo(() => children
        .filter((child) => child.props.alwaysVisible || displayedColumns.includes(child.props.field))
        .map((child) => {
            if (reorderable || resizable || editColumns) {
                const column = savedColumns && savedColumns.find((col) => col.field === child.props.field);
                return React.cloneElement(child, {
                    width: column ? column.width : child.props.width || child.props.minResizableWidth || minCellWidth,
                    orderIndex: column?.orderIndex,
                    minResizableWidth: child.props.minResizableWidth || minCellWidth
                });
            }
            return child;
        }), [children, displayedColumns, reorderable, resizable, editColumns, savedColumns, minCellWidth]);

    return {
        actions: editColumns ? actions : undefined,
        children: newChildren,
        onColumnReorder: reorderable ? onColumnReorder : undefined,
        onColumnResize: resizable ? onColumnResize : undefined,
        headerCellRender: useCallback((cell, cellProps, additionalRender) => {
            // @ts-ignore Typescript is not allowing arbritray props to be provided to cloneElement.
            const newCell = React.Children.map(cell, (cellChild) => React.isValidElement(cellChild) ? React.cloneElement(cellChild, {draggable: false}) : cellChild);
            return additionalRender ? additionalRender(newCell, cellProps) : newCell;
        }, []) as HeaderCellRender,
        resizable,
        reorderable,
    };
};
import React, {useCallback, useState} from "react";

import {Box, Button} from "@barracuda-internal/bds-core";
import {CreatedUserDirectory} from "../UserDirectoriesTypes";
import {useTranslation} from "react-i18next";
import {StatusIconField, useCrudProps, useMixpanel} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import {StatusIcon} from "@cuda-react/icons";
import SyncIcon from '@mui/icons-material/Sync';
import {ErrorDialog} from './ErrorDialog';
import BlockIcon from '@mui/icons-material/Block';
import useDemoApi from "../../../../../hooks/useDemoApi";

export const statusToIconMap = {
    running: <SyncIcon />,
    pending: <SyncIcon />,
    cancelled: <BlockIcon sx={{color: "error.main"}} />,
    errored: <StatusIcon status="error" />,
    completed: <StatusIcon status="ok" />,
    default: <StatusIcon status="error" />
};

interface UserDirectoryStatusFieldProps {
    directory: CreatedUserDirectory;
}

export const UserDirectoryStatusField = ({directory}: UserDirectoryStatusFieldProps) => {
    const registerAction = useMixpanel('User Directories', false);
    const authUrl = useDemoApi(apiResources.directoriesAuthorizationURL, apiResources.demoAuthorizationURL);

    const [translate] = useTranslation('translation');
    const authorizationURL = useCrudProps(directory ? authUrl : undefined, {id: directory.id})[0].data?.authorizationURL;
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const handleAuthorize = useCallback(async () => {
        registerAction('Authorize Directory');
        window.open(authorizationURL);
    }, [authorizationURL]);

    const openErrorsDialog = useCallback(() => {
        setErrorDialogOpen(true);
    }, [setErrorDialogOpen]);


    if (directory.connectionStatus === 'needs_consent') {
        return (
            <Button
                size="small"
                variant="outlined"
                color="warning"
                startIcon={<StatusIcon status="warning" />}
                onClick={handleAuthorize}
            >
                {translate(`tesseract.identity.userDirectories.statusField.${directory.connectionStatus}`)}
            </Button>
        );
    }

    if (directory.syncStatus === 'errored' && directory.syncErrors?.length > 0) {
        return (
            <>
                <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    startIcon={<StatusIcon status="error" />}
                    onClick={openErrorsDialog}
                >
                    {translate(`tesseract.identity.userDirectories.statusField.${directory.syncStatus}`)}
                </Button>
                <ErrorDialog open={errorDialogOpen} onClose={useCallback(() => setErrorDialogOpen(false), [])} directory={directory} />
            </>
        );
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <StatusIconField
                data={directory}
                source="syncStatus"
                iconMap={statusToIconMap}
                text={translate(`tesseract.identity.userDirectories.statusField.${directory.syncStatus}`) ?? ""}
            />
        </Box>
    );
};
import React from "react";
import WizardPage, {WizardPageProps} from "../WizardPage";

interface WizardStepIntroductionProps extends Pick<WizardPageProps, "wizardStates" | "registerStepAction"> {
    onSuccess?: () => void
}

const WizardStepIntroduction = (props: WizardStepIntroductionProps) => (
    <WizardPage
        textHeader="tesseract.setupWizard.wizardIntroductionPage.textHeader"
        textSubHeader="tesseract.setupWizard.wizardIntroductionPage.textSubHeader"
        mainText="tesseract.setupWizard.wizardIntroductionPage.mainText"
        activeStep={0}
        introductionPage
        onNext={props.onSuccess}
        {...props}
    />
);

export default WizardStepIntroduction;
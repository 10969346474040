import React, {useRef} from "react";
import {SvgIcon, SvgIconProps, useTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {RiskLevels} from "../../../typesAndConstants";

export interface RiskGaugeIconProps extends SvgIconProps {
    riskLevel?: RiskLevels,
}

const useStyles = makeStyles({
    icon: {
        width: 267,
        height: 186,
        fill: "none"
    }
});

const riskRotations: Record<RiskLevels, number> = {
    low: -100,
    lowMedium: -50,
    medium: 0,
    mediumHigh: 50,
    high: 100
};

export const RiskGaugeIcon = (props: RiskGaugeIconProps) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const riskLevel = props.riskLevel || RiskLevels.low;
    const riskColor = theme.palette.risk[riskLevel];
    const riskRotation = riskRotations[riskLevel];
    const gradientId = useRef(`gauge_gradient_${Math.random() * 1000}`);

    return (
        <SvgIcon
            className={classes.icon}
            viewBox="0 0 267 186"
            {...props}
        >
            <path
                d="M 15.8659,177.871 C 10.6943,164.127 7.87354,149.232 7.87354,133.686 7.87354,64.2851 64.2199,7.93872 133.621,7.93872 c 69.4,0 125.746,56.34638 125.746,125.74728 0,15.53 -2.82,30.41 -7.976,44.146"
                stroke={`url(#${gradientId.current})`}
                strokeWidth="15"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <radialGradient
                    id={gradientId.current}
                    gradientTransform="translate(133.5,130)"
                    cx="0"
                    cy="-124"
                    fr="20"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={riskColor}>
                        <animate
                            attributeName="stop-color"
                            values={
                                Object.values(RiskLevels)
                                    .slice(0, Object.values(RiskLevels).findIndex((level) => riskLevel === level) + 1)
                                    .map((level) => theme.palette.risk[level])
                                    .join("; ")
                            }
                            dur="1000ms"
                        />
                    </stop>
                    <stop offset={1} stopColor="#EDEDED"/>
                    <animateTransform
                        attributeName="gradientTransform"
                        attributeType="XML"
                        type="rotate"
                        values={`-110;${riskRotation}`}
                        dur="1000ms"
                        additive="sum"
                        fill="freeze"
                    />
                </radialGradient>
            </defs>
            <g transform={`translate(133.5,130)`} style={{fill: "none"}}>
                <path
                    d="M 0,-124 0,-94"
                    stroke={riskColor}
                    strokeWidth="6.25012"
                    strokeMiterlimit="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <animate
                        attributeName="stroke"
                        values={
                            Object.values(RiskLevels)
                                .slice(0, Object.values(RiskLevels).findIndex((level) => riskLevel === level) + 1)
                                .map((level) => theme.palette.risk[level])
                                .join("; ")
                        }
                        dur="1000ms"
                    />
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="-110 0 0"
                        to={`${riskRotation} 0 0`}
                        dur="1000ms"
                        additive="sum"
                        fill="freeze"
                    />
                </path>
            </g>
        </SvgIcon>
    );
};
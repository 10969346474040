import React from "react";

import {Box, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {IdentityProvider} from "../IdentityProviderTypes";
import {AddIdentityProviderButton} from "./AddIdentityProviderButton";

const i18nScope = "tesseract.identity.identityProviders";

interface IdentityProvidersNoDataProps {
    onSelect: (type: IdentityProvider['type']) => () => void;
}

export const IdentityProvidersNoData = ({onSelect}: IdentityProvidersNoDataProps) => {
    const [translate] = useTranslation();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="300px"
        >
            <Typography>
                {translate(`${i18nScope}.noDataMessage`)}
            </Typography>
            <Typography mt={1} mb={2}>
                {translate(`${i18nScope}.clickBelow`)}
            </Typography>
            <AddIdentityProviderButton onSelect={onSelect} />
        </Box>
    );
};
import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const PriorityLowIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-priority-low"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M30,55c-13.8,0-25-11.2-25-25S16.2,5,30,5s25,11.2,25,25-11.2,25-25,25ZM30,9.9c-11.1,0-20.1,9-20.1,20.1s9,20.1,20.1,20.1,20.1-9,20.1-20.1-9-20.1-20.1-20.1Z"/>
            <path style={{fill: theme.palette.error?.main, strokeWidth: 0}}
                  d="M33.3,26.5c-1-1-2.2-1.5-3.6-1.5-1.4,0-2.6.5-3.6,1.5s-.7.8-1,1.3c-.3.5-.4,1-.5,1.5l-8.5,3.7c-.7.3-1.2.7-1.4,1.4s-.3,1.3,0,2c.2.7.7,1.1,1.3,1.4s1.3.3,2,0l8.5-3.7c1,.7,2.2,1.1,3.5,1,1.3,0,2.4-.6,3.3-1.5,1-1,1.5-2.2,1.5-3.6,0-1.4-.5-2.6-1.5-3.6Z"/>
        </SvgIcon>
    );
};

export default PriorityLowIcon;
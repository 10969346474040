import React, {useCallback, useEffect, useState} from "react";
import DialogBody, {DialogBodyProps} from "../../dialog/DialogBody/DialogBody";
import Form, {FormProps} from "../../forms/Form/Form";
import MultiStep from "../MultiStep/MultiStep";
import WizardStepper, {SubStepDefinition, WizardStepperProps} from "../WizardStepper/WizardStepper";
import {uniq} from "lodash";
import {FieldValues} from "react-hook-form";
import {ClonableChildren} from "../../../utils/commonTypes";
import {FormProviderProps} from "../../forms/FormProvider/FormProvider";
import {useDeepCompareCallback} from "../../../hooks";

interface SubStepImplementation extends SubStepDefinition {
    content?: React.ReactElement
}

interface StepImplementation extends SubStepImplementation {
    hideWizardStepper?: boolean;
    subSteps?: SubStepImplementation[]
}

const toChildArray = (children?: React.ReactNode[] | React.ReactNode): React.ReactElement[] => children ? React.Children.toArray(children)
    .filter((child): child is React.ReactElement => !!child && React.isValidElement(child)) : [];
const generateSteps = (visitedSteps: string[], stepsWithErrors: string[], parentStep: string = "") => (child: React.ReactElement, index: number): StepImplementation => {
    const visited = visitedSteps.includes(parentStep + index);
    return {
        label: child.props.label,
        subSteps: child.type === MultiStep && child.props.children ? toChildArray(child.props.children).map(generateSteps(visitedSteps, stepsWithErrors, parentStep + index + ".")) : undefined,
        content: child.type !== MultiStep ? child : undefined,
        hideErrors: child.props.hideErrors,
        preventBackNavigation: child.props.preventBackNavigation,
        hideSubNavigation: child.props.hideSubNavigation,
        completed: visited && visitedSteps.some((visitedStep) => visitedStep > (parentStep + index)),
        error: stepsWithErrors.some((errorStep) => errorStep === parentStep + index || errorStep.startsWith(parentStep + index + ".")),
        hideWizardStepper: child.props.hideWizardStepper,
        visited,
        index
    };
};

export interface WizardProps extends FormProps {
    /**
     * callback to call to perform asynchronous validations prior to form submission or step progression.
     *
     * @function
     * @param {object}
     * @param {any}
     * @returns {Promise<undefined|object>} a promise that resolves when validation passes, or rejects with an object defining the validation error messages.
     */
    asyncValidate?: (formValues: FieldValues, stepProps: any) => Promise<FieldValues>,
    /**
     * The steps to render within the stepper.
     *
     * Each child is expected to be a *Step component. If a child is a [MultiStep]()
     * component, then it's children are also expected to be *Step components.
     *
     * Along with the props used by the *Step component, the following props can be added to each child (and the children of [MultiStep]())
     * to control how the Wizard renders the step in the map:
     *
     * @param {string} child.props.label the text to show in the map for this step. Defaults to the index of the step if not set.
     * @param {boolean} child.props.hideErrors if true, the step will not be highlighted when validation errors are present for inputs within the step.
     * @param {boolean} child.props.preventBackNavigation overrides the global preventBackNavigation for this step if present. Once this step is reached, back navigation to any step before this is prevented.
     * @param {boolean} child.props.hideSubNavigation if child is MultiStep, and this is true, then the sub navigation stepper will not be shown when traversing the sub-steps.
     * @param {boolean} child.props.hideWizardStepper if child wizardStepper, and this is false, then the wizard stepper will not be shown.
     */
    children?: ClonableChildren,
    /**
     * additional props to be passed to the root [DialogBody](/?path=/docs/core-components-dialogs-dialogbody--dialog-body) component.
     */
    dialogBodyProps?: Omit<DialogBodyProps, "children" | "onClose" | "title">,
    /**
     * if true, direct navigation is disabled.
     *
     * Direct navigation is the ability to click on any previously visited step in the map to jump straight to it.
     */
    disableDirectNavigation?: boolean,
    /**
     * callback passed to DialogBody and the final step. Is called when either the "close" icon in [DialogBody](/?path=/docs/core-components-dialogs-dialogbody--dialog-body)
     * is clicked, or the final step is passed.
     *
     * @function
     */
    onClose?: () => void,
    /**
     * callback called on step change, immediately before the step changes.
     *
     * @function
     * @param {number} newStepIndex the index of the new step.
     * @param {number} previousStepIndex the index of the previous step.
     * @param {number} newSubStepIndex the index of the new sub step.
     * @param {number} previousSubStepIndex the index of the previous sub step.
     */
    onStepChange?: (newStepIndex: number, previousStepIndex: number, newSubStepIndex: number, previousSubStepIndex: number) => void
    /**
     * callback called on step progression, immediately before the step changes.
     *
     * @function
     * @param {number} activeStep the index of the active step.
     * @param {number} activeSubStep the index of the active sub step (if applicable).
     */
    onStepSuccess?: (activeStep: number, activeSubStep?: number) => void,
    /**
     * if true, no back button is presented on the step toolbars.
     */
    preventBackNavigation?: boolean,
    /**
     * called when the submit button is clicked on the [SubmitStep](/?path=/docs/core-components-wizard-submitstep--submit-step).
     */
    save?: FormProviderProps["save"],
    /**
     * additional props to pass to the material-ui Stepper component.
     */
    stepperProps?: Partial<Omit<WizardStepperProps, "children">>,
    /**
     * the title to pass to [DialogBody](/?path=/docs/core-components-dialogs-dialogbody--dialog-body).
     */
    title?: string,
    /**
     * if true, the step toolbars are disabled.
     */
    toolbarDisabled?: boolean
}


/**
 * A wizard redux form, styled for use within a dialog.
 *
 * This wizard relies on the *Step components as children, to define the layout and behaviour of each step.
 *
 * Within the \*Step components, you can use \*Input (and \*Field/WizardSummary for SubmitStep and ResultStep). They will be automatically connected, with the same supported props,
 * just as they would if used within a [Form](/?path=/docs/core-components-forms-form--form) (or [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table)).
 */
export const Wizard = (props: WizardProps) => {
    const {
        asyncValidate,
        children,
        dialogBodyProps = {},
        disableDirectNavigation,
        onClose,
        onStepChange,
        onStepSuccess,
        preventBackNavigation,
        save,
        stepperProps,
        title,
        toolbarDisabled,
        ...formProps
    } = props;
    const [result, setResult] = useState<any>();
    const [activeStep, setActiveStep] = useState(0);
    const [activeSubStep, setActiveSubStep] = useState(0);
    const [visitedSteps, setVisitedSteps] = useState<string[]>([]);
    const [stepsWithErrors, setStepsWithErrors] = useState<string[]>([]);
    const childArray = toChildArray(children);
    const steps = childArray.map(generateSteps(visitedSteps, stepsWithErrors));

    // Methods for updating the steps
    const onActiveStepChange = useDeepCompareCallback((stepIndex: number) => {
        const subSteps = steps[activeStep]?.subSteps;
        onStepChange?.(stepIndex, activeStep, subSteps && stepIndex < activeStep ? subSteps.length - 1 : 0, activeSubStep);
        setActiveSubStep(subSteps && stepIndex < activeStep ? subSteps.length - 1 : 0);
        setActiveStep(stepIndex);
    }, [steps, activeStep]);
    const nextStep = useDeepCompareCallback(() => {
        const subSteps = steps[activeStep]?.subSteps;

        if (subSteps && subSteps.length > activeSubStep + 1) {
            onStepChange?.(activeStep, activeStep, activeSubStep + 1, activeSubStep);
            setActiveSubStep(activeSubStep + 1);
        } else {
            onStepChange?.(activeStep + 1, activeStep, activeSubStep, activeSubStep);
            setActiveSubStep(0);
            setActiveStep(activeStep + 1);
        }

    }, [activeStep, activeSubStep, steps]);
    const previousStep = useDeepCompareCallback(() => {
        if (steps[activeStep]?.subSteps && activeSubStep > 0) {
            setActiveSubStep(activeSubStep - 1);
            onStepChange?.(activeStep, activeStep, activeSubStep - 1, activeSubStep);
        } else {
            setActiveStep(activeStep - 1);
            const subSteps = steps[activeStep - 1]?.subSteps;
            if (subSteps) {
                onStepChange?.(activeStep -1, activeStep, subSteps.length - 1, activeSubStep);
                setActiveSubStep(subSteps.length - 1);
            } else {
                onStepChange?.(activeStep - 1, activeStep, 0, activeSubStep);
                setActiveSubStep(0);
            }
        }
    }, [activeStep, activeSubStep, steps]);
    const setStepError = useCallback((value, error) => {
        setStepsWithErrors((currentValues) => [...currentValues.filter(((currentValue) => currentValue !== value)), ...(error ? [value] : [])]);
    }, []);

    const showWizardStepper = !steps[activeStep]?.hideWizardStepper;

    // Add step to visited steps on change.
    useEffect(() => {
        if (steps[activeStep]?.subSteps) {
            setVisitedSteps(uniq([...visitedSteps, activeStep + "", activeStep + "." + activeSubStep]));
        } else {
            setVisitedSteps(uniq([...visitedSteps, activeStep + ""]));
        }
    }, [activeSubStep, activeStep]);

    return (
        <DialogBody title={title} onClose={onClose} form {...dialogBodyProps}>
            {showWizardStepper ?
                <WizardStepper
                    steps={steps}
                    activeStep={activeStep}
                    activeSubStep={activeSubStep}
                    onStepChange={onActiveStepChange}
                    onSubStepChange={setActiveSubStep}
                    disableDirectNavigation={disableDirectNavigation}
                    {...stepperProps}
                /> : null
            }
            <Form
                save={(values, fullSubmit) => {
                    if (fullSubmit && save) {
                        return save(values);
                    } else if (!fullSubmit && asyncValidate) {
                        const subSteps = steps[activeStep].subSteps;
                        return asyncValidate(
                            values,
                            subSteps ? subSteps[activeSubStep]?.content?.props : steps[activeStep]?.content?.props
                        );
                    } else {
                        return Promise.resolve(values);
                    }
                }}
                onSubmitSuccess={(submitResult) => {
                    setResult(submitResult);
                    onStepSuccess?.(activeStep, activeSubStep);
                    nextStep();
                }}
                noToolbar
                {...formProps}
            >
                {steps
                    .slice(0, activeStep + 1)
                    .flatMap((step): ClonableChildren => {
                        if (step.subSteps) {
                            const subSteps = step.index === activeStep ? step.subSteps.slice(0, activeSubStep + 1) : step.subSteps;
                            return subSteps.map((subStep) => subStep.content ? React.cloneElement(
                                subStep.content,
                                {
                                    active: step.index === activeStep && subStep.index === activeSubStep,
                                    key: step.index + "-" + subStep.index,
                                    back: !(subStep.index === 0 && step.index === 0) && !preventBackNavigation ? previousStep : undefined,
                                    close: onClose,
                                    toolbarDisabled,
                                    result,
                                    formErrorReporterValue: step.index + "." + subStep.index,
                                    formErrorReporterSetError: setStepError
                                }
                            ) : null);
                        }
                        return step.content ? React.cloneElement(
                            step.content,
                            {
                                active: step.index === activeStep,
                                key: step.index,
                                back: step.index !== 0 && !preventBackNavigation ? previousStep : undefined,
                                close: onClose,
                                toolbarDisabled,
                                result,
                                formErrorReporterValue: step.index + "",
                                formErrorReporterSetError: setStepError
                            }
                        ) : null;
                    })
                }
            </Form>
        </DialogBody>
    );
};

export default Wizard;
import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

const ResourceIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-resource"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M48.5,22.6c0-.2,0,0,0,0l-4.6-4.4-28,27.5,4.4,4.4c1-1,1.7-.5,2.4,0,.7.7,1,1.5,0,2.2l1.2,1.2c1-1,1.7-.5,2.4,0,.7.7,1,1.5,0,2.2l3.2,3.2,13.4-13.2-2.2-2.2,1.5-1.5,2.4,2.7,13.4-13.2-3.2-3.2h0c-.7.7-1.7.5-2.4,0-.7-.7-.7-1.7,0-2.2h0l-1.2-1.2h0c-.7.7-1.7.7-2.4,0-.7-.7-.7-1.9-.2-2.4ZM32.4,48.4l-4.1,4.1-7.3-7.3,4.1-4.1,7.3,7.3ZM38.5,42.6l-4.1,4.1-7.3-7.3,4.1-4.1,7.3,7.3ZM44.6,36.7l-4.1,4.1-7.3-7.3,4.1-4.1,7.3,7.3ZM50.4,30.9l-4.1,4.1-7.3-7.3,4.1-4.1,7.3,7.3Z"/>
            <circle style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} cx="20.7" cy="20.5" r="3.1"/>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M20.7,31.7c1.5,0,2.9-.3,4.2-.8l6.2-6.1c.6-1.3.9-2.8.9-4.3,0-6.2-5-11.2-11.2-11.2s-11.2,5-11.2,11.2,5,11.2,11.2,11.2ZM20.7,13.4c3.9,0,7.1,3.2,7.1,7.1s-3.2,7.1-7.1,7.1-7.1-3.2-7.1-7.1,3.2-7.1,7.1-7.1Z"/>
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M15.9,39.7H6.9V6h27.7v15.4l4.9-4.8V3.6c0-1.3-1.1-2.4-2.4-2.4H4.4c-1.3,0-2.4,1.1-2.4,2.4v38.5c0,1.3,1.1,2.4,2.4,2.4h6.6l4.9-4.9Z"/>
        </SvgIcon>
    );
};

export default ResourceIcon;
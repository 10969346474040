import {AUTHENTICATION_FAILED} from "./crudClient";
import {get, merge} from "lodash";
import {AuthClient, AuthEventType} from "../components/providers/AuthProvider/AuthProvider";

export const AUTHENTICATE_URL = "/api/v1/webui/cloud/authenticate";
export const EXPIRE_URL = "https://" + location.hostname + ":" + location.port + "/api/v1/webui/cloud/authenticate/expire";
export const DEFAULT_LOGIN_URL = "https://login.barracudanetworks.com";

const logout = (redirectTarget?: string | null) => fetch(EXPIRE_URL, {mode: "no-cors", credentials: "include"})
    .catch(() => {
        // Ignore failures
    }).then(() => getLoginRedirect(redirectTarget));

const getLoginRedirect = (target?: string | null) => Promise.reject({
    redirect: (target || DEFAULT_LOGIN_URL) + "/auth/login?service=" + location.href,
    userData: null,
    loginUrl: null
});

const performLogin = (params?: any, loginUrl?: string, noRedirect: boolean = false) =>
    fetch(loginUrl || AUTHENTICATE_URL, {credentials: "include"})
        .then(
            (response) => response.json().then(
                (json) => {
                    if (response.status === 403) {
                        return Promise.reject({
                            redirect: "/unauthorized",
                            userData: json,
                            loginUrl: null
                        });
                    } else if (response.status >= 300) {
                        return logout(json.portalUrl || response.headers.get("Portal-Location"));
                    }

                    return {
                        redirect: noRedirect ? undefined : get(params, "previousOrigin", get(params, "origin")) || "/",
                        userData: json,
                        loginUrl: null
                    };
                },
                () => logout(response.headers.get("Portal-Location"))
            ),
            () => logout()
        );

export const sessionStatuses = {
    checkedSessionAuth: false,
    authenticating: false
};
const authLogin = (params?: any, globalParams?: any) => {
    const {loginUrl, ...resolvedGlobalParams} = globalParams || {};
    if (sessionStatuses.authenticating) {
        return Promise.reject();
    }
    sessionStatuses.authenticating = true;
    sessionStatuses.checkedSessionAuth = true;

    return performLogin(merge({}, resolvedGlobalParams, params), loginUrl)
        .finally(() => {
            sessionStatuses.authenticating = false;
        });
};

const authChangeAccount = (params?: any, globalParams?: any) => {
    const accountId = get(globalParams, "userData.currentAccount");
    const newAccountId = get(params, "accountId");
    const changeAccountUrl = `/api/v1/webui/${accountId}/accounts/switch/${newAccountId}`;
    const updateInPlace = get(params, "updateInPlace");

    if (accountId === newAccountId) {
        return Promise.resolve();
    }

    if (updateInPlace) {
        return performLogin(merge({}, globalParams, params), changeAccountUrl, true);
    } else {
        return Promise.resolve({redirect: "/login", loginUrl: changeAccountUrl});
    }
};

const authLogout = (globalParams?: object) => logout(get(globalParams, "userData.portalUrl"));

const authError = (params?: any) => {
    if (params?.status === 308 || params?.status === 403) {
        return Promise.reject();
    } else if (params === AUTHENTICATION_FAILED || params.status === 401) {
        return Promise.reject({redirect: "/login"});
    }

    return Promise.resolve();
};

const authCheck = (authenticated?: any, globalParams?: any) => {
    if (!get(globalParams, "userData.currentAccount") || (authenticated && typeof authenticated === "function" && !authenticated(globalParams))) {
        return Promise.reject();
    }

    if (!sessionStatuses.checkedSessionAuth) {
        sessionStatuses.checkedSessionAuth = true;
        return performLogin(globalParams).then(({redirect, ...response}) => response);
    }

    return Promise.resolve();
};

export default ((type: AuthEventType, params?: any, globalParams?: any) => {
    switch (type) {
        case AuthEventType.LOGIN:
            return authLogin(params, globalParams);
        case AuthEventType.CHANGE_ACCOUNT:
            return authChangeAccount(params, globalParams);
        case AuthEventType.LOGOUT:
            return authLogout(globalParams);
        case AuthEventType.ERROR:
            return authError(params);
        case AuthEventType.CHECK:
            return authCheck(params, globalParams);
        default:
            return Promise.reject("Unknown Method");
    }
}) as AuthClient;
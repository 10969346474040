import {BaseBarCard} from "../../baseCards";
import React from "react";
import {colorFunctionError, numberFormatter} from "../../../utils";
import {AppsIcon} from "../../icons";
import {CardSizes, DashboardCardProps, DomainDataPoint} from "../../../typesAndConstants";
import {useTranslation} from "react-i18next";
import {useReportingData} from "../../../contexts";
import {BarGridEntry} from "../../statistics/BarGrid";
import {sortBy} from "lodash";
import {useTheme} from "@mui/material";

export interface TopBlockedApplicationsCardProps extends DashboardCardProps {
}

export const TopBlockedApplicationsCard = (props: TopBlockedApplicationsCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("TopBlockedApplicationsCard", preview);
    const {blockedApplicationDataPoints = []} = data || {};
    const maxEntry = Math.max(...blockedApplicationDataPoints.map((domainDataPoint: DomainDataPoint) => domainDataPoint.numberOfSessions));
    const theme = useTheme();

    const entries: BarGridEntry[] = sortBy(blockedApplicationDataPoints, "numberOfSessions").reverse().map((domainDataPoint: DomainDataPoint) => ({
        title: domainDataPoint.label,
        value: size === CardSizes.small ?
            numberFormatter(domainDataPoint.numberOfSessions) :
            translate("stratosReporting.blockedApplications.sessions", {
                count: domainDataPoint.numberOfSessions,
                formattedCount: numberFormatter(domainDataPoint.numberOfSessions)
            }),
        barProgress: (100.0 / maxEntry) * domainDataPoint.numberOfSessions,
        color: colorFunctionError((100.0 / maxEntry) * domainDataPoint.numberOfSessions, theme),
        onClick: addFilter ? () => addFilter({key: domainDataPoint.id, name: domainDataPoint.label}, "applicationDataPoints") : undefined,
        tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            {...props}
            entries={entries}
            title="stratosReporting.blockedApplications.title"
            rowIcon={<AppsIcon size={20}/>}
            loading={loading && !data.blockedApplicationDataPoints}
            noData={!loading && !data.blockedApplicationDataPoints}
            activeFilter
        />
    );
};
import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const PriorityRealtimeIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-priority-realtime"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M30,55c-13.8,0-25-11.2-25-25S16.2,5,30,5s25,11.2,25,25-11.2,25-25,25ZM30,9.9c-11.1,0-20.1,9-20.1,20.1s9,20.1,20.1,20.1,20.1-9,20.1-20.1-9-20.1-20.1-20.1Z"/>
            <path style={{fill: theme.palette.success?.main, strokeWidth: 0}}
                  d="M25.2,30.1c0,1.4.5,2.6,1.5,3.6s2,1.4,3.3,1.5c1.3,0,2.4-.3,3.5-1l8.5,3.7c.7.3,1.3.3,2,0s1.1-.7,1.3-1.4c.3-.7.3-1.4,0-2s-.7-1.1-1.4-1.4l-8.5-3.7c0-.5-.2-1.1-.5-1.5-.3-.5-.6-.9-1-1.3-1-1-2.2-1.5-3.6-1.5-1.4,0-2.6.5-3.6,1.5s-1.5,2.2-1.5,3.6Z"/>
        </SvgIcon>
    );
};

export default PriorityRealtimeIcon;
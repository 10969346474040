import React from "react";
import {FormSection, getArrayDataContent, useCrudProps} from "@cuda-react/core";
import SCLanTableInput from "../../inputs/SCLanTableInput";
import RoutesTableInput from "../../inputs/RoutesTableInput";
import apiResources from "../../../../../apiResources";

interface LansTabProps {
    id?: string | number;
}

const LansTab: React.FC<LansTabProps> = ({id}) => {
    const portsData = getArrayDataContent(useCrudProps(apiResources.sitePorts, {id})[0]?.data);
    const availablePorts = portsData.filter((port) => port.port && !port.port.includes("wan"));

    return (
        <React.Fragment>
            <FormSection title="tesseract.sites.settings.lan.sectionTitle">
                <SCLanTableInput portsData={availablePorts}/>
            </FormSection>
            <FormSection title="tesseract.sites.settings.route.sectionTitle">
                <RoutesTableInput/>
            </FormSection>
        </React.Fragment>
    );
};

export default LansTab;
import {IconButton, TextField} from "@barracuda-internal/bds-core";
import {Hide, Show} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React, {useState} from "react";
import {formatErrorMessage} from "../../../utils";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {InputAdornment, Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    disabledShowHide: {
        color: theme.palette.text.disabled
    },
    enabledShowHide: {
        color: theme.palette.common.black
    },
    passwordInputVisibility: {
        position: "relative",
        top: 0,
        left: 4,
        verticalAlign: "top",
        padding: 0,
        margin: 0,
        height: "auto",
        width: "auto",
        transition: "none",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    root: {
        width: 256
    }
});
const useStyles = makeOverrideableStyles("Password", styles);

export interface PasswordProps extends StyledComponentProps<typeof styles> {
    /**
     * if true, input will get emptied when clicking in it.
     */
    clearOnFocus?: boolean,
    /**
     * if true, inputs will get disabled
     */
    disabled?: boolean,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * id of the component for unique identification. This value is prefixed with "boolean-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
    /**
     * callback to call when input loses focus. Called with the new value as the only argument.
     */
    onBlur?: (event: string | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    /**
     * callback to call when input value changes.
     */
    onChange?: (valueOrEvent: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    /**
     * callback to call when input gets focus. Called with the new value as the only argument.
     */
    onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    /**
     * props passed to the TextField.
     */
    options?: Partial<React.ComponentProps<typeof TextField>>,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: string,
    /**
     * the viewable prop allows you to render an eye icon button that (when clicked)
     * toggles the type of the input to password/text, allowing to mask/unmask the value of the inputs.
     */
    viewable?: boolean,
    /**
     * if true, input value is not masked with *.
     */
    isVisible?: boolean
}

/**
 * Renders a TextField input (by default) of password type. Entry is masked with '*' symbols.
 * The viewable prop allows you to render an eye icon button that (when clicked)
 * toggles the type of the input to password/text, allowing to mask/unmask the value of the input.
 */
export const Password = (props: PasswordProps) => {
    const {
        clearOnFocus,
        disabled,
        error,
        id,
        onBlur,
        onChange,
        onFocus,
        options = {},
        value = "",
        isVisible,
        viewable
    } = props;
    const classes = useStyles();
    const [visible, setVisible] = useState(isVisible);
    const [prevValue, setPrevValue] = useState<string|null>(null);

    return (
        <TextField
            value={value}
            onBlur={(eventOrValue) => {
                let value: string | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> = eventOrValue;

                if (clearOnFocus && !value && prevValue) {
                    value = prevValue || "";
                    setPrevValue(null);
                }
                onBlur?.(value);
            }}
            onFocus={(event) => {
                if (clearOnFocus) {
                    setPrevValue(value);
                    onChange?.("");
                }
                onFocus?.(event);
            }}
            onChange={(value) => {
                onChange?.(value);
            }}
            type={visible ? "text" : "password"}
            helperText={formatErrorMessage(error)}
            error={!!error}
            {...options}
            disabled={disabled}
            id={id && "text-input-" + id}
            className={classes.root}
            variant="outlined"
            InputProps={{
                endAdornment: viewable &&
                     (
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            className={classes.passwordInputVisibility}
                            onClick={() => setVisible(!visible)}
                            disabled={disabled}
                        >
                            {visible ? (
                                <Hide
                                    className={disabled ? classes.disabledShowHide : classes.enabledShowHide}/>
                            ) : (
                                <Show
                                    className={disabled ? classes.disabledShowHide : classes.enabledShowHide}/>
                            )}
                        </IconButton>
                    </InputAdornment>
                ) || undefined
            }}
        />
    );
};


export default Password;
import {
    ConnectedForm,
    DialogBody,
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    TextInput,
    useGlobalParam,
    usePostNotification
} from "@cuda-react/core";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {get} from "lodash";
import apiResources from "../../../../apiResources";
import classNames from "classnames";
import {getAzureResourceLinkFromResourceID} from "../../../../utils/azureUtils";

const useStyles = makeStyles((theme) => ({
    fullHeight: {
        "& > div": {
            height: "auto",
            minHeight: theme.spacing(20),
            alignItems: "flex-start"
        }
    },
    fullWidth: {
        "& > div": {
            width: 410
        }
    },
    narrowLabel: {
        width: 120
    },
    dialogContent: {
        width: 610
    },
    message: {
        padding: theme.spacing(2)
    }
}));

interface EnableSupportContentProps {
    applianceType?: "gateway" | "site",
    name?: string,
    onClose?: () => void,
    uuid?: string,
    gatewayType?: "cloud" | "managed" | "on-prem",
    applicationId?: string
}

export const EnableSupportContent: React.FC<EnableSupportContentProps> = ({
                                                                              applianceType,
                                                                              uuid,
                                                                              name,
                                                                              onClose,
                                                                              gatewayType,
                                                                              applicationId
                                                                          }) => {
    const [translate] = useTranslation();
    const classes = useStyles();
    const postNotification = usePostNotification();
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";

    return (
        <DialogBody
            title="tesseract.appliances.support.dialogTitle"
            classes={{dialogContent: classes.dialogContent}}
            onClose={onClose}
        >
            <ConnectedForm
                resource={apiResources.enableSupport}
                params={{uuid}}
                onSubmitSuccess={() => {
                    postNotification({
                        event: NotificationEventType.SHOW,
                        display: NotificationDisplayType.TOAST,
                        params: {
                            level: NotificationLevel.OK,
                            content: "tesseract.appliances.support.success",
                            translateParams: {name, context: applianceType}
                        }
                    });
                    onClose?.();
                }}
                onCancel={onClose}
                submitOnEnter={false}
                formatRequestData={(data) => ({
                    ...data,
                    networks: get(data, "networks", "")
                        .split(",")
                        .map((network: string) => network.trim())
                })}
                create
                allowDirtyNavigation
            >
                <Typography className={classes.message}>
                    {translate("tesseract.appliances.support.message", {context: applianceType})}
                </Typography>
                {gatewayType === "cloud" ?
                    <Typography className={classes.message}>
                        <Trans i18nKey="tesseract.appliances.support.cloudGatewayMessage">
                            <a
                                href={getAzureResourceLinkFromResourceID(applicationId, isDemo)}
                                rel="noreferrer noopener"
                                target="_blank"
                            />
                        </Trans>
                    </Typography> : null}
                <TextInput
                    source="networks"
                    label="tesseract.appliances.support.networks"
                    description={translate("tesseract.appliances.support.description.networks", {context: applianceType})}
                    inputClasses={{
                        textField: classes.fullWidth
                    }}
                    inputLabelProps={{
                        classes: {
                            inputLabel: classes.narrowLabel
                        }
                    }}
                    isRequired
                />
                <TextInput
                    source="publicKey"
                    label="tesseract.appliances.support.publicKey"
                    description={translate("tesseract.appliances.support.description.publicKey", {context: applianceType})}
                    inputClasses={{
                        textField: classNames(classes.fullWidth, classes.fullHeight)
                    }}
                    inputLabelProps={{
                        classes: {
                            inputLabel: classes.narrowLabel
                        }
                    }}
                    options={{
                        multiline: true
                    }}
                />
            </ConnectedForm>
        </DialogBody>
    );
};


export default EnableSupportContent;
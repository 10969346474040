import {ButtonDialog} from "@cuda-react/core";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React from "react";
import NotificationContent from "./NotificationContent";

interface EditNotificationDialogProps {
    id: ((data?: any) => string) | string,
    data?: any,
    onSuccess?: any
}

const EditNotificationDialog: React.FC<EditNotificationDialogProps> = ({id, data, onSuccess}) => {
    const appId = typeof id === "function" ? id(data) : id;
    return (
        <ButtonDialog buttonIcon={<Edit id="edit-notification-icon"/>}>
            <NotificationContent
                title="tesseract.settings.tabs.notification.components.title.editNotification"
                id={appId}
                onSuccess={onSuccess}
            />
        </ButtonDialog>
    );
};

export default EditNotificationDialog;
/** Card props and enums for DashboardCards */

export enum CardSizes {
    small = "small",
    medium = "medium",
    large = "large",
}

export enum RiskLevels {
    low = "low",
    lowMedium = "lowMedium",
    medium = "medium",
    mediumHigh = "mediumHigh",
    high = "high"
}

const singleCardWidth = 380;
const singleCardHeight = 260;
const columnMargin = 16;
export const CardSizeOptions: Record<CardSizes, any> = {
    small: {
        width: singleCardWidth,
        height: singleCardHeight,
        gridLayout: {
            width: 1,
            height: 1
        }
    },
    medium: {
        width: (singleCardWidth * 2) + columnMargin,
        height: singleCardHeight,
        gridLayout: {
            width: 2,
            height: 1
        }
    },
    large: {
        width: (singleCardWidth * 2) + columnMargin,
        height: (singleCardHeight * 2) + columnMargin,
        gridLayout: {
            width: 2,
            height: 2
        }
    }
};

export const GridSizeOptions = {
    rowHeight: singleCardHeight,
    columnWidth: singleCardWidth,
    columnMargin
};

export interface DashboardCardProps {
    /** render size of the card */
    size: CardSizes,
    /** show preview data */
    preview?: boolean
    /** optional object that can be used to send data to a card */
    additionalProps?: { [index: string]: any }
}

export interface DashboardCardMetadata<CardProps extends DashboardCardProps> {
    /** DashboardCard component */
    component: React.ComponentType<CardProps>,
    /** Display name for DashboardCard */
    name: string,
    /** Category of the DashboardCard */
    type?: string
}

export type CardsRecord<Cards extends string = string> = Record<Cards, DashboardCardMetadata<any>>;


export type previewDataGenerator = () => any;
import React, {useEffect} from "react";
import {Redirect, Route, Switch} from "react-router";
import Dashboard from "./pages/dashboard/Dashboard";
import TesseractMessages from "./i18n/TesseractMessages";
import {StratosReportingMessages} from "@stratos/reporting";
import {useI18next} from "@cuda-react/core";
import Network from "./pages/network/Network";
import Endpoint from "./pages/endpoint/Endpoint";
import {Identity} from "./pages/identity/Identity";
import {LogsAndReporting} from "./pages/logsAndReporting/LogsAndReporting";
import SecurityAndAccess from "./pages/securityAndAccess/SecurityAndAccess";
import {Integration} from "./pages/integration/Integration";
import Subscription from "./pages/subscription/Subscription";
import SetupWizard from "./pages/setupWizard/SetupWizard";

const CgwanModule = () => {
    const {addToMessages} = useI18next();

    useEffect(() => {
        // @ts-ignore
        addToMessages(TesseractMessages);
        // @ts-ignore
        addToMessages(StratosReportingMessages);
    }, []);

    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path="/"
                    component={Dashboard}
                />
                <Route
                    path="/infrastructure/*"
                    component={Network}
                />
                <Route
                    path="/ztna/*"
                    component={Endpoint}
                />
                <Route
                    path="/policies/*"
                    component={SecurityAndAccess}
                />
                <Route
                    exact
                    path="/logs/*"
                    component={LogsAndReporting}
                />
                <Route
                    path="/integration/*"
                    component={Integration}
                />
                <Route
                    path="/subscription/*"
                    component={Subscription}
                />
                <Route
                    path="/identity/*"
                    component={Identity}
                />
                <Route
                    path="/setupWizard/*"
                    component={SetupWizard}
                />
                <Redirect to="/"/>
            </Switch>
        </React.Fragment>
    );
};

export default CgwanModule;
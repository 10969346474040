import React from "react";
import {CardSizeOptions, CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import generatePreviewData from "../../../utils/previewDataGenerators/devicesVersion";
import {useCardFetch} from "../../../hooks";
import StatisticsOverview from "../../statistics/StatisticsOverview";
import StatisticsDetail from "../../statistics/StatisticsDetail";
import BaseCard from "../../baseCards/BaseCard";
import {Divider, Grid, Typography} from "@barracuda-internal/bds-core";
import {Theme, useTheme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {BarGrid} from "../../statistics/BarGrid";
import {useTranslation} from "react-i18next";
import {colorFunction, compareSemanticVersions, getStatusColorByIndex, groupBySemanticVersions} from "../../../utils";
import apiResources from "../../../apiResources";
import classNames from "classnames";

export interface AccessDevicesAgentVersionCardProps extends DashboardCardProps {
}

const styles = (theme: Theme) => createStyles<string, Pick<DashboardCardProps, "size" | "preview">>(({
    dividerCards: {
        width: "95%"
    },
    appliancesContainer: {
        maxHeight: 300,
        minHeight: 300,
    },
    hideBoxShadow: {
        boxShadow: "none",
        borderWidth: 0,
    },
    cardContent: {
        height: "calc(100% - 50px)",
        overflowY: "hidden"
    },
    titleBar: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.palette.reporting.fontColor,
        padding: "16px 16px 8px",
    },
    barGrid: {
        maxHeight: 300,
        minHeight: 300,
        overflowY: "scroll",
    },
    noDataContainer: {
        height: ({size}) => `calc(${CardSizeOptions[size].height}px - ${theme.spacing(50)})`,
        width: "100%"
    },
}));
const useStyles = makeStyles(styles);

const defaultAmountByCardSize = {
    small: 3,
    medium: 5,
    large: 5,
};

/**
 * Card that displays an overview of Agent version in use
 *
 * This Card shows in an overview the total number of agent version used.
 *
 * In the large size may also show a table with the top five agent used.
 *
 */
export const AccessDevicesAgentVersionCard = (props: AccessDevicesAgentVersionCardProps) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const [translate] = useTranslation();
    const {preview, size} = props;

    const {data = {}} = useCardFetch({url: !preview ? apiResources.agentVersions : undefined});
    const content = preview ? generatePreviewData() : data;
    const {total, versions} = content;
    const overviewValue = total ? total : 0;

    const valueOrderedVersions = (versions ? Object.keys(versions) : [])
        .sort((versionA, versionB) => versions[versionA] - versions[versionB])
        .reverse();
    const valueOrderedVersionsLength = valueOrderedVersions.length;

    let groupedByVersion: {[string: string]: number} = {};
    if (valueOrderedVersionsLength > defaultAmountByCardSize[size]) {
        groupedByVersion = groupBySemanticVersions(versions, "minor");

        if (groupedByVersion && Object.keys(groupedByVersion).length > defaultAmountByCardSize[size]) {
            groupedByVersion = groupBySemanticVersions(versions, "major");

            if (groupedByVersion && Object.keys(groupedByVersion).length > defaultAmountByCardSize[size]) {
                const keys: string[] = Object.keys(groupedByVersion).sort(compareSemanticVersions).reverse();
                const droppedValues: {[string: string]: number} = {};
                let older: number = 0;

                keys.forEach((key: string, index: number) => {
                    if (index < defaultAmountByCardSize[size] - 1) {
                        droppedValues[key] = groupedByVersion[key];
                    } else {
                        older += groupedByVersion[key];
                    }
                });
                droppedValues["stratosReporting.accessDevicesAgentVersionCard.older"] = older;
                groupedByVersion = droppedValues;
            }
        }

    } else {
        valueOrderedVersions.forEach((vers:string) => (
            groupedByVersion[vers] = versions[vers]
        ));
    }

    // Overview Data
    const orderedKeys: string[] = Object.keys(groupedByVersion).sort(compareSemanticVersions).reverse();
    const overviewData = orderedKeys.length > 0 ? orderedKeys.map((key, index) => ({
        title: orderedKeys[index],
        value: groupedByVersion[orderedKeys[index]],
        color: getStatusColorByIndex(index, theme)
    })) : [];

    // Chart Data
    const highestValue = versions && versions[valueOrderedVersions[0]];
    const barChartData = valueOrderedVersions.map((version: string) => ({
        title: translate("stratosReporting.accessDevicesAgentVersionCard.version", {version}),
        value: translate("stratosReporting.accessDevicesAgentVersionCard.devices", {value: versions[version], context: versions[version] === 1 ? undefined : "plural"}),
        color: colorFunction((100.0 / highestValue) * versions[version], theme),
        barProgress: (100 * versions[version]) / highestValue
    }));

    return (
        <BaseCard title={"stratosReporting.accessDevicesAgentVersionCard.title"} size={size} preview={preview} classes={{cardContent: classes.cardContent}}>
            <StatisticsOverview
                overviewTitle="stratosReporting.resourceStatisticsCard.overview"
                overviewValue={overviewValue}
            >
                {Array.isArray(overviewData) && overviewData.map((data: any) => (
                    <StatisticsDetail
                        title={data.title}
                        value={data?.value}
                        color={data?.color}
                        key={data.title}
                    />
                ))}
            </StatisticsOverview>
            {size === CardSizes.large && (
                <>
                    <Grid container direction="column" alignItems="center">
                        <Divider className={classes.dividerCards}/>
                    </Grid>
                    <Typography className={classes.titleBar}>
                        {translate("stratosReporting.accessDevicesAgentVersionCard.tableTitle")}
                    </Typography>
                    <BarGrid
                        size={CardSizes.large}
                        entries={barChartData}
                        className={classNames(classes.barGrid, classes.noDataContainer)}
                    />
                </>
            )}
        </BaseCard>
    );
};
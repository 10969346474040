import React from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";

export interface BaseIconPreviewProps {
    /** base64 data for the icon */
    icon?: string,
    /** name, used for default icon if no data is provided */
    name?: string,
    /** filename for the icon, to establish mimeType */
    filename?: string,
    /** supported files, required to work out mimeType from filename */
    supportedFiles: {
        fileExtensions: string[],
        mimeType: string
    }[],
    /** override default size of the icon */
    size?: number
}

const defaultIconSize = 32;
const styles = createStyles<string, BaseIconPreviewProps>({
    initialsIcon: {
        width: (props) => props.size || defaultIconSize,
        height: (props) => props.size || defaultIconSize,
        lineHeight: (props) => `${props.size || defaultIconSize}px`,
        borderRadius: "50%",
        textAlign: "center",
        color: "#ffffff",
        background: "#4DC6BB"
    },
    iconImage: {
        width: (props) => props.size || defaultIconSize,
        height: (props) => props.size || defaultIconSize,
        padding: "3px",
        borderRadius: "8px",
        objectFit: "contain"
    }
});
const useStyles = makeOverrideableStyles("IconPreview", styles);

export interface IconPreviewProps extends BaseIconPreviewProps, StyledComponentProps<typeof styles> {
}


/**
 * Displays an icon using provided raw base64 data, given the filename and supported filetypes (used to determine mimetype).
 *
 * If no data is provided, will display a "default" Avatar style icon using the initial of the provided name.
 * @param props
 * @constructor
 */
const IconPreview = (props: IconPreviewProps) => {
    const classes = useStyles(props);
    const fileExtension = props.filename && props.filename.split(".").slice(-1)[0];
    const iconMimeType = fileExtension && props.supportedFiles.find((fileType) => fileType.fileExtensions.includes(fileExtension.toLowerCase()))?.mimeType;
    const imageSource = props.icon && iconMimeType && `data:${iconMimeType};base64,${props.icon}`;
    const initialsText = ((props.name || "").match(/[a-zA-Z0-9]/)?.[0] || "").toUpperCase();

    return imageSource ? (
        <img src={imageSource} className={classes.iconImage} alt={props.filename}/>
    ) : (
        <div className={classes.initialsIcon}>{initialsText}</div>
    );
};


export default IconPreview;
import React from "react";
import {
    BooleanInput,
    ConnectedFormPage,
    FileInput,
    FormSection,
    getArrayDataContent,
    PropGateway,
    TextInput,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    helpText: {
        padding: theme.spacing(1, 1, 4, 3),
        color: theme.palette.text.secondary
    },
    multiLineTextInput: {
        width: "50%",
        "& > div": {
            "& > div": {
                height: "auto",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }
        }
    }
}));

const CustomResponse = () => {
    const classes = useStyles();
    const registerAction = useMixpanel("Custom Response Page Settings");
    const imageFileTypes = getArrayDataContent(useCrudProps(apiResources.customResponseImageTypes)[0]);
    const imageAccept = imageFileTypes.map(({mimeType}: any) => mimeType).join(',');

    return (
        <ConnectedFormPage
            resource={apiResources.customResponse}
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.settings", "tesseract.securityAndAccess.customResponse"]}
            params={{filter: {type: "virtualWan"}}}
            formatResourceData={(data) => {
                const {image, imageFilename, ...formData} = data || {};
                return {...formData, image: {filename: imageFilename, data: image}};
            }}
            formatRequestData={(data) => ({
                ...data,
                image: data?.image?.data === "" ? undefined : data.image.data,
                imageFilename: data?.image?.filename
            })
            }
            formatError={(error) => ({...error, image: error?.imageFilename || error?.image})}
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
            canReset
        >
            <FormSection title="tesseract.customResponse.setup" newStyle hideBorder>
                <PropGateway>
                    <Typography className={classes.helpText}>
                        <Trans i18nKey="tesseract.customResponse.setupHelpText"/>
                    </Typography>
                </PropGateway>
                <TextInput
                    source="message"
                    label="tesseract.customResponse.customText"
                    classes={{
                        field: classes.multiLineTextInput
                    }}
                    options={{
                        multiline: true
                    }}
                    additionalInfoLabel="tesseract.customResponse.descriptions.customText"
                    newStyle
                />
                <FileInput
                    source="image"
                    label="tesseract.customResponse.customLogo"
                    additionalInfoLabel="tesseract.customResponse.descriptions.customLogo"
                    accept={imageAccept}
                    newStyle
                    showDelete
                />
                <BooleanInput
                    source="branding"
                    label="tesseract.customResponse.barracudaBranding"
                    toggleEnabledText="tesseract.customResponse.showBarracudaBranding"
                    newStyle
                />
            </FormSection>
        </ConnectedFormPage>
    );
};

export default CustomResponse;
import React from "react";
import {
    ConnectedForm,
    DialogBody,
    getArrayDataContent,
    SelectArrayInput,
    SelectInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import generateDestinationCriteriaSection
    from "../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import {formatRequestCriteria, formatResourceCriteria} from "../../../../../components/sourceAndDestination/formUtils";
import {ipsIcons} from "../../../../../components/iconMapping";
import {useGeneratedSourceCriteriaSection} from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";

export const styles: any = {
    dialogContent: {},
    multiInputButtons: {
        width: 74,
        flexGrow: "0 !important",
        flexShrink: 0,
        height: 50,
        marginLeft: 0,
        marginRight: 8
    },
    multiInputInputGroup: {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        "& > *": {
            flexGrow: 1,
            maxWidth: "calc(100% - 122px)"
        }
    },
    multiInputDivider: {
        width: "calc(100% - 64px)",
        maxWidth: "calc(100% - 64px) !important",
        marginBottom: -4
    },
    multiInputNetworkSelectChips: {
        marginLeft: "calc(-100% - 8px)",
        maxWidth: "calc(200% + 186px)",
        width: "calc(200% + 186px)"
    }
};
const useStyles = makeStyles(styles);

/**
 * For now we are using this workaround to get the karma tests working.
 */

interface CreateEditIpsRulesProps {
    create?: boolean;
    id?: string | number;
    onClose?: () => void;
    resource: string;
    onSuccess?: () => void;
}

export const CreateEditIpsRules: React.FC<CreateEditIpsRulesProps> = ({create, id, resource, onSuccess, ...props}) => {
    const isFalsePositives = resource === apiResources.ips;
    const registerAction = useMixpanel(isFalsePositives ? "IPS False Positive" : "IPS Rule", false);
// @ts-ignore
    const actionChoices = getArrayDataContent(useCrudProps(isFalsePositives ? apiResources.ipsActions : apiResources.ipsExplicitActions, {}, {cache: true})[0]?.data);
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const classes = useStyles();

    return (
        <DialogBody
            {...props}
            form
            title={create ?
                "tesseract.security.ips.settings.create.dialogTitle" :
                "tesseract.security.ips.settings.edit.dialogTitle"
            }
            classes={{dialogContent: classes.dialogContent}}
        >
            <ConnectedForm
                resource={resource}
                // @ts-ignore
                form={resource}
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={(response) => {
                    registerAction(create ? "Create" : "Update", {
                        action: response.action,
                        sourceType: response.source?.type,
                        destinationType: response.destination?.type
                    });
                    onSuccess?.();
                    // @ts-ignore
                    props.onClose();
                }}
                onCancel={props.onClose}
                formatRequestData={formatRequestCriteria(virtualWanId)}
                formatResourceData={formatResourceCriteria}
                initialValues={create ? {source: {allSites: true}, destination: {allSites: true}} : undefined}
                allowDirtyNavigation
            >
                <TextInput
                    source="name"
                    label="tesseract.security.ips.settings.name"
                    description="tesseract.security.ips.settings.descriptions.name"
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.security.ips.settings.description"
                    description="tesseract.security.ips.settings.descriptions.description"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                />
                <SelectInput
                    source="action"
                    label="tesseract.security.ips.settings.action"
                    description="tesseract.security.ips.settings.descriptions.action"
                    iconMap={ipsIcons}
                    choices={actionChoices}
                    isRequired
                />
                {isFalsePositives && (
                    <SelectArrayInput
                        source="exploits"
                        label="tesseract.security.ips.settings.exploits"
                        resource={apiResources.ipsExploits}
                        optionValue="id"
                        isRequired
                        options={{
                            optionText: "name",
                            dropdownAutoWidth: true
                        }}
                    />
                ) || null}
                {useGeneratedSourceCriteriaSection(isFalsePositives ? ["network", "site"] : ["network", "site", "userOrGroup"])}
                {generateDestinationCriteriaSection(isFalsePositives ? ["network", "site"] : ["application", "network", "domain", "site"])}
            </ConnectedForm>
        </DialogBody>
    );
};

CreateEditIpsRules.defaultProps = {
    resource: apiResources.ips
};

export default CreateEditIpsRules;
import {List, ListItem, ListItemIcon, ListItemText} from "@barracuda-internal/bds-core";
import {isEmpty} from "lodash";
import get from "lodash/get";
import React from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

export const styles = createStyles({
    listItem: {
        paddingTop: 2,
        paddingBottom: 2
    },
    listItemText: {
        margin: 0
    },
    listItemIcon: {
        minWidth: 32,
        verticalAlign: "middle"
    },
    inset: {
        paddingLeft: 32
    }
});


const useStyles = makeOverrideableStyles("ListField", styles);

export interface ListFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps {
    /**
     * icon to display next to each item of the list. If iconMap is provided as well, this icon will be used whenever a matching icon is not found in iconMap.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons to display inside of each item of the list.
     */
    iconMap?: {[key: string]: React.ReactNode},
    /**
     * function to render the text of each item on the list.
     * If not provided, the item text will get directly translated.
     * @function
     * @param {*} row the text for each item of the list.
     * @returns {node} the text to display in the item of the list
     */
    render?: (row: any) => React.ReactNode,
}

/**
 * Renders a list of items. Items can be preceded with an icon.
 */
export const ListField = (props: ListFieldProps) => {
    const {source = "", data = {}, icon, iconMap = {}, render} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);
    let fieldValues = get(data, source) || [];

    if (!Array.isArray(fieldValues)) {
        fieldValues = [fieldValues];
    }

    return (
        <List dense disablePadding>
            {fieldValues.map((row: any, index: number) => {
                const iconToDisplay = iconMap[row] || icon;
                return (
                    <ListItem disableGutters key={index} className={classes.listItem}>
                        {iconToDisplay && (
                            <ListItemIcon className={classes.listItemIcon}>
                                {iconToDisplay}
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primary={render ? render(row) : translate(row)}
                            primaryTypographyProps={{variant: "body1"}}
                            className={classes.listItemText}
                            classes={{inset: classes.inset}}
                            inset={!isEmpty(iconMap) && !iconToDisplay}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default ListField;
import React from "react";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {ConnectedForm, DialogBody, TextInput, Workspace} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import mainApiResources from "../../../mainApiResources";
import {useTranslation} from "react-i18next";

const styles = (theme: Theme) => createStyles({
    root: {},
    descriptionText: {
        margin: theme.spacing(2)
    },
    lastInput: {
        marginBottom: theme.spacing(4)
    }
});

const useStyles = makeOverrideableStyles("WorkspaceDialogContent", styles);

export interface WorkspaceDialogContentProps extends StyledComponentProps<typeof styles> {
    activeWorkspace?: Workspace,
    onChange?: () => void
    onClose?: () => void
}

const WorkspaceDialogContent = (props: WorkspaceDialogContentProps) => {
    const {activeWorkspace, onChange, onClose} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const mode = activeWorkspace ? "edit" : "create";
    return (
        <DialogBody form onClose={onClose} title={mode === "edit" ? "stratos.accounts.dialog.titleEdit" : "stratos.accounts.dialog.titleAdd"}>
            <ConnectedForm
                resource={mainApiResources.virtualWans}
                create={mode === "create"}
                id={activeWorkspace?.id}
                onCancel={onClose}
                onSubmitSuccess={() => {
                    onChange?.();
                    onClose?.();
                }}
            >
                <Typography className={classes.descriptionText}>
                    {translate("stratos.accounts.dialog.message")}
                </Typography>
                <TextInput source="name" label="stratos.accounts.dialog.name" isRequired/>
                <TextInput source="description" label="stratos.accounts.dialog.description" inputLabelProps={{classes: {root: classes.lastInput}}}/>
            </ConnectedForm>
        </DialogBody>
    );
};

export default WorkspaceDialogContent;
import React from "react";
import {StatusIconField} from "@cuda-react/core";
import {get} from "lodash";
import {daysBetween} from "../../../../../../utils/dateTime";

interface ExpiryStatusProps {
    source: string;
    data?: Record<string, any>;
}

const ExpiryStatus: React.FC<ExpiryStatusProps> = ({data, source}) => {
    const expireDate = get(data, source);
    const daysTilExpiry = expireDate && daysBetween(new Date(expireDate), new Date());
    let status = expireDate ? "ok" : "unknown";
    status = expireDate && daysTilExpiry < 28 ? "warning" : status;
    status = expireDate && daysTilExpiry < 0 ? "error" : status;

    return (
        <StatusIconField source="status" data={{status}}/>
    );
};

export default ExpiryStatus;
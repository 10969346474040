import React from "react";
import {BaseBarCard} from "../../baseCards";
import {colorFunctionSuccess, numberFormatter} from "../../../utils";
import {CardSizes, DashboardCardProps, DomainDataPoint} from "../../../typesAndConstants";
import {Trans, useTranslation} from "react-i18next";
import {BarGridEntry} from "../../statistics/BarGrid";
import {useReportingData} from "../../../contexts";
import {Typography} from "@barracuda-internal/bds-core";
import {sortBy} from "lodash";
import {useTheme} from "@mui/material";
import {DomainsIcon} from "@cuda-react/icons";

/**
 * Shows a list of allowed domains and a "progress bar".
 * The progress bar does not actually show the ratio of the values but is dependent on the position of the value.
 */
export const TopAllowedDomainsPerSessionCard = (props: DashboardCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("TopAllowedDomainsPerSessionCard", preview);
    const {domainDataPoints = []} = data || {};
    const maxEntry = Math.max(...domainDataPoints.map((domainDataPoint: DomainDataPoint) => domainDataPoint.numberOfSessions));
    const theme = useTheme();

    const entries: BarGridEntry[] = sortBy(domainDataPoints, "numberOfSessions").reverse().map((domainDataPoint: DomainDataPoint) => ({
        title: domainDataPoint.label,
        value: size === CardSizes.small ?
            numberFormatter(domainDataPoint.numberOfSessions) :
            translate("stratosReporting.topAllowedDomainsCard.sessions", {
                count: domainDataPoint.numberOfSessions,
                formattedCount: numberFormatter(domainDataPoint.numberOfSessions)
            }),
        barProgress: (100.0 / maxEntry) * domainDataPoint.numberOfSessions,
        icon: <DomainsIcon size={20}/>,
        color: colorFunctionSuccess((100.0 / maxEntry) * domainDataPoint.numberOfSessions, theme),
        onClick: addFilter && domainDataPoint.id !== "Consolidated" ? () => addFilter(domainDataPoint.id, "domainDataPoints") : undefined,
        tooltipMessage: (addFilter && domainDataPoint.id !== "Consolidated") ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            entries={entries}
            title={
                <Trans i18nKey="stratosReporting.topAllowedDomainsCard.titlePerSession">
                    <Typography variant="body1" component="span"/>
                </Trans>
            }
            size={size}
            preview={preview}
            loading={loading && !data.domainDataPoints}
            noData={!loading && !data.domainDataPoints}
            activeFilter
        />
    );
};
import {validateDateOnAfter, validateDateOnBefore} from "@cuda-react/core";
import {differenceInHours, subHours} from "date-fns";

export const updateWindowValidation = (mustInstallBy: any, translate: any) => (values: any) => {
    const minDate = subHours(new Date(), 26);
    const mustInstallByDate = new Date(mustInstallBy);
    const start = new Date(values.from);
    const end = new Date(values.to);
    const windowWholeHours = values.from && values.to && differenceInHours(end, start);
    const lengthError = windowWholeHours < 3 ? translate("tesseract.settings.tabs.updates.firmware.validation.insufficientWindow") : undefined;

    return {
        from: lengthError || validateDateOnAfter(minDate)(values.from, values, {t: translate}),
        to: lengthError || validateDateOnBefore(mustInstallByDate)(values.to, values, {t: translate})
    };
};
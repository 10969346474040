import React from "react";
import Input, {InputProps} from "../Input/Input";
import TimeWindow from "./TimeWindow";

export interface TimeWindowInputProps extends Omit<InputProps<typeof TimeWindow>, "displayError" | "component">{}

/**
 * Based on the TimeWindow component, but modified to use the default [Input](/?path=/docs/core-components-inputs-input) format. Works natively with redux form "input" prop.
 */
export const TimeWindowInput = (props: TimeWindowInputProps) => (
    <Input {...props} displayError={false} component={TimeWindow}/>
);

export default TimeWindowInput;
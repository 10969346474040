import React, {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import {OnConsentChangedEvent, OneTrustContext} from "../../../hooks";

const getInitialActiveGroups = () => (window?.OptanonActiveGroups || "").split(",").filter((group) => !!group);

/**
 * One Trust Provider. Auto-detects if a OneTrust global option has been configured, and provides context for interacting with it
 * @param children
 * @constructor
 */
export const OneTrustProvider = ({children}: {children: ReactNode}): JSX.Element => {
    const [enabled, setEnabled] = useState<boolean>(!!window.Optanon);
    const [completed, setCompleted] = useState<boolean>(!!window.Optanon?.IsAlertBoxClosedAndValid?.());
    const [allowedGroups, setAllowedGroups] = useState<string[]>(getInitialActiveGroups());
    const reopenPopup = useCallback(() => {
        window.Optanon?.ToggleInfoDisplay?.();
    }, []);
    const onConsentChange = (event: OnConsentChangedEvent) => {
        setCompleted(true);
        setAllowedGroups(event.detail);
    };
    const setupAttempts = useRef(0);
    const rejectAllCookie = document.cookie.includes("ONETRUST_REJECT_ALL=true");
    const initialSetup = () => {
        if (window.Optanon) {
            setEnabled(true);
            setCompleted(window.Optanon.IsAlertBoxClosedAndValid());
            setAllowedGroups(getInitialActiveGroups());
            window.Optanon.OnConsentChanged(onConsentChange);
            if (rejectAllCookie) {
                window.Optanon.RejectAll();
            }
        } else if (setupAttempts.current++ < 3) {
            setTimeout(initialSetup, 5000);
        }
    };

    useEffect(() => {
        initialSetup();
    }, []);

    return (
        <OneTrustContext.Provider value={{enabled, completed, allowedGroups, reopenPopup}}>
            {children}
        </OneTrustContext.Provider>
    );
};

export default OneTrustProvider;
import {CrudOptions, useCrudFetch} from "./CrudHooks";
import {CrudParams, CrudTypes} from "../clients";
import React, {useCallback, useEffect, useRef} from "react";
import {debounce, get} from "lodash";
import {CircularProgress} from "@barracuda-internal/bds-core";
import {getArrayDataContent} from "../utils";
import {useGlobalParam} from "./GlobalParamsHooks";

export type TableChoicesOptions = {
    /**
     * By default, the 1st ("value") argument from the render call is expected to be the unique id that will be used
     * to find its matching choice from the CRUD. However you can override this by providing a dot-notation path to a
     * field in the 2nd ("data") argument you wish to use instead.
     */
    source?: string,
    /**
     * additional params to add to the CRUD requests.
     */
    crudParams?: CrudParams,
    /**
     * configure the crud request options.
     */
    crudOptions?: CrudOptions,
    /**
     * Configure how the "found" data should be rendered as part of the returned "render" method (for ChipArrayField, CustomField etc.).
     * @param choice the matching choice object that was returned from the CRUD. Will be undefined prior to the CRUD call resolving.
     * @param loading the loading state of the CRUD call.
     * @param value the original value from the table fields render call.
     * @param data the original data from the table fields render call.
     * @param args the original additional args from the table fields render call.
     */
    render?: (choice: any, loading: boolean, value: any, data: any, ...args: any[]) => any,
    /**
     * set the debounce delay (in ms) between the last "id" that is collected, and the triggering of the CRUD call. Default is 50ms
     */
    debounceWait?: number,
    /**
     * set the filter id in the CRUD request. Defaults to "id".
     */
    filterId?: string,
    /**
     * dot-notation path to the field in each returned choice from the CRUD call that is used to match the choice with the
     * value from each render call. Defaults to "key".
     */
    optionValue?: string,
    /**
     * dot-notation path to the field in each matched choice from the CRUD call that should be rendered. Defaults to "name".
     */
    optionText?: string,
    /**
     * an array of choices that have been defined locally, and want to be added to the array returned from the CRUD call.
     */
    staticChoices?: any[]
}

type RenderMethod = (value: any, data: any, ...args: any[]) => (JSX.Element | string);
type GetIconMethod = (value: any, data: any) => string | undefined;

/**
 * This is a convenience hook, intended to group requests for choice data from multiple table item "render" methods into a single request.
 *
 * The returned method should be passed to a table field component (such as ChipArrayField). Then when each table cells are rendered for the first
 * time, the "id"s that are needed are collected, a single CRUD call is made, and then the returned values are then used to render the desired content.
 *
 * @param url the URL path to fetch the choices from.
 * @param options optional options to further configure the behaviour of this hook.
 * @param optionIcon optional "optionIcon", to gather the identifier of an icon from the choice. If provided, both render method and optionIcon method is returned.
 * @returns a render method, suitable for passing into table field components such as ChipArrayField, CustomField, etc.
 */
export const useTableChoices = <OptionIcon extends string | undefined>(url: string, options?: TableChoicesOptions, optionIcon?: OptionIcon): OptionIcon extends undefined ? RenderMethod : [RenderMethod, GetIconMethod] => {
    const knownIds = useRef<any[]>([]);
    const [virtualWanId,] = useGlobalParam("filter.virtualWanId");
    useEffect(() => {
        knownIds.current = [];
    }, [virtualWanId]);
    const [data, loading, performFetch] = useCrudFetch(CrudTypes.GET, url, options?.crudParams, options?.crudOptions);
    const debounceFetch = debounce(() => performFetch(
        {
            filter: {[options?.filterId || "id"]: knownIds.current},
            pagination: {
                page: 1,
                perPage: 2000
            }
        }
    ), options?.debounceWait || 50);
    const choiceArray = [...getArrayDataContent(data.data), ...(options?.staticChoices || [])];

    const renderText = useCallback((value: any, data: any, ...args) => {
        const id = options?.source ? get(data, options.source) : value;
        const choice = choiceArray.find((item: any) => get(item, options?.optionValue || "key") === id);
        const choiceNeedsLoading = !choice && !knownIds.current.includes(id);

        if (choiceNeedsLoading) {
            knownIds.current.push(id);
            debounceFetch();
        }

        return options?.render ?
            options.render(choice, choiceNeedsLoading || loading, value, data, ...args) :
            get(choice, options?.optionText || "name", (choiceNeedsLoading || loading) ?
                <CircularProgress size={14}/> : null);
    }, [choiceArray, loading]);
    const getIconId = useCallback((value: any, data: any) => {
        const id = options?.source ? get(data, options.source) : value;
        const choice = choiceArray.find((item: any) => get(item, options?.optionValue || "key") === id);

        return get(choice, optionIcon || "");
    }, [optionIcon, choiceArray]);
    // @ts-ignore not sure why this complains, as far as I can tell I'm using the type gate correctly...
    return optionIcon ? [renderText, getIconId] : renderText;
};
import {Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.text.primary,
    },
    title: {
        fontSize: 12,
        display: "inline-block",
        color: theme.palette.text.disabled
    }
});

const useStyles = makeOverrideableStyles("AccountTitle", styles);

export interface AccountTitleProps {
    title: string | string[]
}

export const AccountTitle = (props: AccountTitleProps) : JSX.Element => {
    const {title} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);
    return (
        <div
            className={classes.root}
            id="cuda-react-account-title"
        >
            {(!Array.isArray(title) ? [title] : title).map((text, index) => (
                <Typography
                    key={index}
                    component="div"
                    className={classes.title}
                >
                    {index > 0 ? "/" : ""}{translate(text)}
                </Typography>
            ))}
        </div>
    );
};

export default AccountTitle;
import React from "react";
import {CardSizeOptions, CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import apiResources from "../../../apiResources";
import {TableCardContent, TextField} from "@cuda-react/core";
import {BaseCard} from "../../baseCards";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    cardContent: {
        height: "calc(100% - 50px)",
        overflowY: "hidden"
    }
});

export interface UserDevicesCardProps extends DashboardCardProps  {
    preview?: boolean,
    size: CardSizes,
    additionalProps: {id: number, loading: boolean}
}

export const UserDevicesCard = (props: UserDevicesCardProps) => {
    const {size} = props;
    const classes = useStyles(props);
    const userDeviceTableColumns = [
        <TextField key="brand" source="brand" label="stratosReporting.userDevicesCard.table.brand"/>,
        <TextField key="hardwareModel" source="hardwareModel" label="stratosReporting.userDevicesCard.table.hardwareModel"/>,
        <TextField key="enrollmentDate" source="enrollmentDate" label="stratosReporting.userDevicesCard.table.enrollmentDate"/>,
        <TextField key="operatingSystem" source="operatingSystem" label="stratosReporting.userDevicesCard.table.operatingSystem" hidden={true}/>,
        <TextField key="operatingSystemVersion"source="operatingSystemVersion" label="stratosReporting.userDevicesCard.table.operatingSystemVersion" hidden/>
    ];
    const userDeviceData =  userDeviceTableColumns;

    return (
        <BaseCard title="stratosReporting.userDevicesCard.title" size={size} classes={{cardContent: classes.cardContent}}>
            <TableCardContent
                resource={apiResources.userDevices}
                pollInterval={300000}
                params={{filter: {userId: props?.additionalProps?.id}}}
                maxHeight={CardSizeOptions[size].height - 50}
            >
                {userDeviceData}
            </TableCardContent>
        </BaseCard>
    );
};
import React from "react";
import {ButtonDialog} from "@cuda-react/core";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import ExplicitPolicyContent from "./ExplicitPolicyContent";

interface EditExplicitPolicyDialogProps {
    id: (() => void) | string | any;
    data?: Record<string, any>;
    onSuccess?: () => void;
}

export const EditExplicitPolicyDialog: React.FC<EditExplicitPolicyDialogProps> = ({id, data, onSuccess}) => {
    const explicitPolicyId = (data && (typeof id === "function")) ? id(data) : id;
    return (
        <ButtonDialog buttonIcon={<Edit id="edit-explicit-policy-icon"/>}>
            <ExplicitPolicyContent title="tesseract.policies.dialogs.explicit.edit.dialogTitle" id={explicitPolicyId}
                                   onSuccess={onSuccess}/>
        </ButtonDialog>
    );
};


export default EditExplicitPolicyDialog;
/* eslint-disable id-length */
import React from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {CardSizeOptions, CardSizes, CardsRecord, GridSizeOptions} from "../../../typesAndConstants";
import {Close, Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {IconButton} from "@barracuda-internal/bds-core";
import Draggable from "react-draggable";
import classNames from "classnames";
import useEditableGridLayout, {CardComponent} from "../../../hooks/useEditableGridLayout";
import {AddWidgetCard} from "../AddWidgetCard";
import {StyledComponentProps} from "@cuda-react/theme";
import {ButtonDialog, useWidthTracking} from "@cuda-react/core";
import {PreviewDialogContent} from "../PreviewDialogContent";

const styles = (theme: Theme) => createStyles({
    layout: {
        transition: "height 200ms ease",
    },
    deleteButton: {
        border: "none",
    },
    editModeButtons: {
        top: theme.spacing(1),
        right: theme.spacing(1),
        position: "absolute",
        width: 64,
        zIndex: 100,
        backgroundColor: theme.palette.background.default
    },
    editButtonIcon: {
        height: 20,
        width: 20
    },
    editButton: {
        border: "none"
    },
    responsive: {
        display: "grid",
        gap: GridSizeOptions.columnMargin,
        gridTemplateColumns: GridSizeOptions.columnWidth,
        gridTemplateRows: GridSizeOptions.rowHeight,
        paddingBottom: theme.spacing(2),
    },
    insertCard: {
        borderLeft: `2px solid #067CC1 !important`
    },
    dragged: {
        zIndex: 25
    },
    cardSlot: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#f0f0f0",
        "& > div > :not(:first-child)": {
            opacity: 0.5
        },
        cursor: "pointer",
    },
    fillerCard: {
        width: GridSizeOptions.columnWidth
    },
    clickBarrier: {
        zIndex: 50,
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0
    }
});
const useStyles = makeStyles(styles);

export interface GridProps extends StyledComponentProps<typeof styles> {
    cardComponents: Array<CardComponent>,
    cards: CardsRecord,
    onChange: (cards: CardComponent[]) => void,
    editMode: boolean,
    allCards: CardsRecord,
    onAdd?: (cardId: string | undefined, size: CardSizes) => void,
}


export const Grid = (props: GridProps) => {
    const classes = useStyles(props);
    const [ref, width] = useWidthTracking(0);
    const {cardComponents, onChange, editMode, cards, allCards, onAdd} = props;
    const layout = useEditableGridLayout(cardComponents, onChange, editMode, cards, width);

    const addSelectedCardToFilteredCards = (card: CardComponent, filteredCards: CardsRecord): CardsRecord => ({
        [card.componentId]: allCards[card.componentId],
        ...filteredCards
    });

    // Round the max width down to the maximum cards that can fit in the current screen
    const columnWidth = GridSizeOptions.columnWidth + GridSizeOptions.columnMargin;
    const gridWidth = (Math.floor((width + GridSizeOptions.columnMargin) / columnWidth) * columnWidth) -  GridSizeOptions.columnMargin;

    return (
        <div ref={ref}>
            <div className={classes.responsive} style={{maxWidth: gridWidth}}>
                {layout.map((item) => (
                    <div
                        key={item.id}
                        className={classNames(
                            !item.card && classes.fillerCard,
                            editMode && classes.cardSlot,
                            item.hasInsert && classes.insertCard,
                            item.isMoving && classes.dragged
                        )}
                        style={{
                            gridColumnStart: item.x + 1,
                            gridColumnEnd: item.x + item.w + 1,
                            gridRowStart: item.y + 1,
                            gridRowEnd: item.y + item.h + 1,
                            width: CardSizeOptions[item?.card?.props?.size || "small"].width,
                        }}
                    >
                        {item.card ? (
                            // @ts-ignore
                            <Draggable
                                disabled={!editMode}
                                onStart={item.onDragEvent}
                                onStop={item.onDragFinish}
                                onDrag={item.onDragEvent}
                                position={{x: 0, y: 0}}
                            >
                                <div>
                                    {editMode ? (
                                        <div className={classes.editModeButtons}>
                                            <ButtonDialog
                                                buttonIcon={
                                                    <Edit id="edit-widget-icon" className={classes.editButtonIcon}/>
                                                }
                                                stopPropagation
                                                buttonClasses={{buttonIcon: classes.editButton}}
                                            >
                                                <PreviewDialogContent
                                                    cards={addSelectedCardToFilteredCards(item.card, cards)}
                                                    selectedCard={{
                                                        componentId: item.card.componentId,
                                                        size: item.card.props.size || CardSizes.small
                                                    }}
                                                    onAdd={(selectedCardId?: string, selectedSize?: CardSizes) => {
                                                        const newCardComponents = cardComponents.map((card) => {
                                                            if (selectedCardId && card.componentId === item.id) {
                                                                return {
                                                                    componentId: selectedCardId,
                                                                    component: allCards[selectedCardId].component,
                                                                    props: {
                                                                        size: selectedSize
                                                                    }
                                                                };
                                                            }
                                                            return card;
                                                        });
                                                        onChange(newCardComponents);
                                                    }}
                                                />
                                            </ButtonDialog>
                                            <IconButton
                                                size="small"
                                                onClick={(event) => {
                                                    onChange?.(cardComponents.filter((card) => card.componentId !== item.id));
                                                    event?.stopPropagation?.();
                                                }}
                                                className={classes.deleteButton}
                                            >
                                                <Close id="stratos-remove-card"/>
                                            </IconButton>
                                        </div>
                                    ) : null}
                                    {React.createElement<any>(
                                        item.card.component,
                                        {
                                            ...item.card.props,
                                            size: item.forceSmall ? CardSizes.small : item.card.props.size
                                        }
                                    )}
                                    {editMode ? (
                                        <div
                                            onClick={(event) => event.stopPropagation()}
                                            className={classes.clickBarrier}
                                        />
                                    ) : null}
                                </div>
                            </Draggable>
                        ) : (editMode && item.addWidget ? (
                            <AddWidgetCard cards={cards} onAdd={item.onAdd}/>
                        ) : (
                            <div/>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
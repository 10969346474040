import React, {useEffect} from "react";
import {CrudParams, CrudTypes} from "../../../clients";
import Wizard, {WizardProps} from "../Wizard/Wizard";
import {useCrudFetch} from "../../../hooks";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import {BaseStepProps} from "../StepType";
import {FieldValues} from "react-hook-form";

export interface BaseConnectedWizardStepProps extends BaseStepProps {
    /**
     * Resource to use for async validation of this step.
     * Consumed by parent [ConnectedWizard](/?path=/docs/core-components-wizard-connectedwizard--connectedwizard) component.
     */
    validateResource?: string
    /**
     * Formatter function to format data prior to submission to server.
     *
     * Consumed by parent [ConnectedWizard](/?path=/docs/core-components-wizard-connectedwizard--connectedwizard) component.
     */
    formatRequestData?: (data?: FieldValues) => any
    /**
     * Disables async validation for this step.
     *
     * Consumed by parent [ConnectedWizard](/?path=/docs/core-components-wizard-connectedwizard--connectedwizard) component.
     */
    noAsyncValidation?: boolean
}

export interface ConnectedWizardProps extends WizardProps {
    /**
     * if true, the form is submitted as an CrudTypes.UPDATE [CRUD](/?path=/docs/cudareactapp-crud--page) request, rather than a CrudTypes.CREATE.
     */
    update?: boolean,
    /**
     * the [CRUD](/?path=/docs/cudareactapp-crud--page) params to use when fetching from the initial values from the defaultResource.
     */
    defaultParams?: CrudParams,
    /**
     * if provided, this [CRUD](/?path=/docs/cudareactapp-crud--page) resource will be used in a CrudTypes.GET request to get the initial values for the wizard form.
     */
    defaultResource?: string,
    /**
     * it handles the error when the save or async validation action is triggered on a connected wizard
     *
     * @param {object} error the ReferenceError
     * @param {object} values the data that needs to be passed to be saved
     * @param {object} formattedData the object containing the data formatted
     * @returns {object} the formatted data.
     */
    formatError?: (error: any, values: FieldValues, formattedData: any) => any,
    /**
     * if provided, this function will be called prior to sending the form data in a [CRUD](/?path=/docs/cudareactapp-crud--page) submit request, and the values returned by this function
     * will be used as the "data" instead of the original form data.
     *
     * @function
     * @param {object} data the form data values.
     * @returns {object} the data to set in the [CRUD](/?path=/docs/cudareactapp-crud--page) submit request.
     */
    formatRequestData?: (data: FieldValues) => any,
    /**
     * the [CRUD](/?path=/docs/cudareactapp-crud--page) resource to use when submitting the wizard form data.
     */
    resource?: string,
    /**
     * the title for the wizard dialog.
     */
    title?: string,
    /**
     * if provided, the current form values will be pushed as a CrudTypes.CREATE/CrudTypes.UPDATE [CRUD](/?path=/docs/cudareactapp-crud--page) request to this resource at each step transition as an asynchronous validation.
     */
    validateResource?: string,
    /**
     * the [CRUD](/?path=/docs/cudareactapp-crud--page) params to add to the CrudTypes.CREATE/CrudTypes.UPDATE [CRUD](/?path=/docs/cudareactapp-crud--page) request when the wizard form is submitted.
     */
    submitParams?: CrudParams,
    /**
     * the [CRUD](/?path=/docs/cudareactapp-crud--page) params to add to the CrudTypes.CREATE/CrudTypes.UPDATE [CRUD](/?path=/docs/cudareactapp-crud--page) request when the wizard form is asynchronously validated using validateResource.
     */
    validateParams?: CrudParams,
    /**
     * callback called when the wizard has been successfully submitted.
     */
    onSubmitSuccess?: (data?: any) => void
}

/**
 * An implementation of [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) that is pre-setup to work against a [CRUD](/?path=/docs/cudareactapp-crud--page) resource.
 *
 * Any additional props provided that are not detailed below will be passed through to the [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) component.
 */
export const ConnectedWizard = (props: ConnectedWizardProps) => {
    const {update, children, defaultResource, defaultParams, formatError, formatRequestData, resource, validateResource, submitParams, validateParams, onSubmitSuccess, ...wizardProps} = props;
    const [, saving, performSubmit] = useCrudFetch(props.update ? CrudTypes.UPDATE : CrudTypes.CREATE, resource, submitParams);
    const [, validating, performValidate] = useCrudFetch(props.update ? CrudTypes.UPDATE : CrudTypes.CREATE, validateResource, validateParams);
    const [defaultData, defaultLoading, performFetch] = useCrudFetch(CrudTypes.GET, defaultResource, defaultParams);
    const initialValues = defaultData && defaultData.data || {};

    useEffect(() => {
        if (defaultResource) {
            performFetch();
        }
    }, []);

    const submitData = (values: any) => {
        const formattedData = formatRequestData && formatRequestData(values) || values;
        return performSubmit(
            {data: formattedData},
            {formPromise: true}
        ).then((response) => {
            onSubmitSuccess?.(response?.data);
            return response;
        }).catch((error) => {
            throw formatError ? formatError(error, values, formattedData) : error;
        });
    };


    const validateData = (values: any, stepProps: BaseConnectedWizardStepProps) => {
        const resourceToUse = (stepProps && stepProps.validateResource) || validateResource;
        const formatFunction = (stepProps && stepProps.formatRequestData) || formatRequestData;

        if (!resourceToUse || (stepProps && stepProps.noAsyncValidation)) {
            return Promise.resolve(values);
        }

        const formattedData = formatFunction?.(values) || values;
        return performValidate(
            {data: formattedData},
            {formPromise: true},
            resourceToUse,
        ).catch((error) => {
            throw formatError ? formatError(error, values, formattedData) : error;
        });
    };

    return (
        <ErrorBoundary>
            <Wizard
                save={submitData}
                asyncValidate={validateData}
                toolbarDisabled={validating || saving || defaultLoading}
                disabled={validating || saving || defaultLoading}
                initialValues={initialValues}
                {...wizardProps}
            >
                {children}
            </Wizard>
        </ErrorBoundary>
    );
};

export default ConnectedWizard;
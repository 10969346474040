import React from "react";
import {ColumnField, CustomField, DialogBody, DownloadButton} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {Trans, useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import apiResources from "../../../../../apiResources";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "512px"
    },
    dialogContent: {
        whiteSpace: "pre-line",
        margin: theme.spacing(0, 2)
    },
    downloadButtonDiv: {
        margin: theme.spacing(0.5, 0, 1, 0)
    }
}));

interface DownloadBootstrapConfigDialogProps {
    onClose?: () => void,
    serials?: any,
    applianceType?: "site" | "gateway",
    isSc?: boolean
}

const DownloadBootstrapConfigDialog: React.FC<DownloadBootstrapConfigDialogProps> = ({onClose, serials, applianceType, isSc}) => {
    const classes = useStyles();
    const [translate] = useTranslation();

    const renderDownloadButton = (value: any) => (<div className={classes.downloadButtonDiv}>
        <DownloadButton
            label="tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.downloadButtonLabel"
            filename={"cloudgenwan-" + value}
            filetype="gz"
            resource={applianceType === "site" ? apiResources.downloadSiteBootstrapConfiguration : apiResources.downloadGatewayBootstrapConfiguration}
            params={{serial: value}}
            color="primary"
            directDownload
        />
    </div>);

    return (
        <div className={classes.root}>
            <DialogBody
                onClose={onClose}
                title="tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.title"
            >
                <Typography className={classes.dialogContent}>
                    <Trans
                        i18nKey={isSc ? "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.dialogMessageSc"
                            : "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.dialogMessage"}
                        values={{button: translate(serials[1] ? "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.buttons" : "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.button")}}
                    >
                        <a target="_blank" rel="noopener noreferrer"
                           href="https://campus.barracuda.com/to/secureedge-bootstrap"/>
                    </Trans>
                </Typography>
                <ColumnField
                    columnCount={1}
                    columns={[{xs: 10, md: 8}]}
                    data={{serials}}
                >
                    <CustomField
                        label={translate("tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.serial", {serial: serials[0]})}
                        source="serials[0]"
                        render={renderDownloadButton}
                    />
                    {serials[1] ?
                        <CustomField
                            label={translate("tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.serial", {serial: serials[1]})}
                            source="serials[1]"
                            render={renderDownloadButton}
                        /> : null}
                </ColumnField>
            </DialogBody>
        </div>
    );
};

export default DownloadBootstrapConfigDialog;
import React from "react";
import SelectPairArray from "./SelectPairArray";
import Input, {InputProps} from "../Input/Input";

export interface SelectPairArrayInputProps extends Omit<InputProps<typeof SelectPairArray>, "displayError" | "component"> {}

/**
 * Based on the SelectPairArray component, but modified to use the default [Input](/?path=/docs/core-components-inputs-input) format, and work natively with redux form "input" prop.
 * Renders a SelectPairArray component wrapped on a div element, passing the input to the SelectPairArray child component.
 */
export const SelectPairArrayInput = (props: SelectPairArrayInputProps) => (
    <Input {...props} displayError={false} component={SelectPairArray}/>
);

export default SelectPairArrayInput;
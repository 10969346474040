import {differenceInDays, format, formatDistanceToNow} from "date-fns";
import {CreatedUserDirectory} from "../UserDirectoriesTypes";
import {useTranslation} from "react-i18next";
import React from "react";

interface LastSyncFieldProps{
    directory: CreatedUserDirectory;
}

const i18nScope = "tesseract.identity.userDirectories";

export const LastSyncField: React.FC<LastSyncFieldProps> = ({directory}: LastSyncFieldProps) => {
    const [translate] = useTranslation();

    if (directory.lastSuccessfulSync) {
        if (differenceInDays(new Date(), new Date(directory.lastSuccessfulSync)) > 7) {
            return (<>{translate(`${i18nScope}.lastSyncAt`, {date: format(new Date(directory.lastSuccessfulSync), 'LLLL d, yyyy')})}</>);
        } else {
            return (<>{formatDistanceToNow(new Date(directory.lastSuccessfulSync), {addSuffix: true})}</>);
        }
    } else {
        return (<>{translate(`${i18nScope}.neverSynced`)}</>);
    }
};
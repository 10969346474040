import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        height: "60px",
        width: "60px"
    }
}));

export const ZtnaPolicyIcon = ({className, fillColor}: {className?: string, fillColor?: string}) => {
    const classes = useStyles();

    return (
        <SvgIcon
            className={classNames(className, classes.root, "cuda-react-icon")}
            id="cuda-icon-ztna-policy"
            fill="none"
        >
            <g clipPath="url(#clip0_3104_11571)">
                <circle cx="1.77581" cy="1.77581" r="1.77581" transform="matrix(1 0 0 -1 5.2796 8.73438)"
                        fill={fillColor ?? "#52667A"}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M5.74745 11.9022H2.11188V8.26879C0.891816 8.62343 0.00012207 9.74965 0.00012207 11.0841C0.00012207 12.7032 1.3126 14.0156 2.93161 14.0156C4.26672 14.0156 5.39337 13.1231 5.74745 11.9022Z"
                      fill={fillColor ?? "#52667A"}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.26611 2.11175L11.9028 2.11175L11.9028 5.74637C13.122 5.39121 14.0129 4.26539 14.0129 2.93149C14.0129 1.31247 12.7005 1.1474e-07 11.0814 2.56279e-07C9.74696 3.72943e-07 8.62075 0.891691 8.26611 2.11175Z"
                      fill={fillColor ?? "#52667A"}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.9028 8.26926L11.9028 11.9022L8.26561 11.9022C8.6197 13.1231 9.74634 14.0156 11.0814 14.0156C12.7005 14.0156 14.0129 12.7032 14.0129 11.0841C14.0129 9.75023 13.122 8.62441 11.9028 8.26926Z"
                      fill={fillColor ?? "#52667A"}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.11188 5.74683L2.11188 2.11175L5.74695 2.11175C5.39232 0.891689 4.2661 -5.59415e-07 2.93161 -3.84419e-07C1.3126 -1.7211e-07 0.000122242 1.31247 0.000122455 2.93149C0.00012263 4.26598 0.891817 5.3922 2.11188 5.74683Z"
                      fill={fillColor ?? "#52667A"}/>
            </g>
            <defs>
                <clipPath id="clip0_3104_11571">
                    <rect width="14" height="14" fill="white"/>
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export default ZtnaPolicyIcon;
import {
    BannerNotificationParams,
    CrudTypes,
    getDataContent,
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    useCrudSubscription,
    useDeepCompareEffect,
    useGlobalParam,
    usePostNotification,
    usePrevious
} from "@cuda-react/core";
import {DAY_AS_MILLISECONDS, daysBetweenAbs} from "../utils/dateTime";
import apiResources from "../mainApiResources";

const getDaysUntilSubscriptionExpires = (expiryDate: number | string | Date) => daysBetweenAbs(new Date(), new Date(expiryDate));
const subscriptionExpiresSoon = (daysUntilExpiry: number | false, isDemoMode: boolean) => daysUntilExpiry ? daysUntilExpiry < 15 && !isDemoMode : false;
const getDaysUntilIsDeprecated = (deprecatedDate: number | string | Date) => daysBetweenAbs(new Date(), new Date(deprecatedDate));
const deprecatedExpiresSoon = (daysUntilIsDeprecated: number | false) => daysUntilIsDeprecated !== false && daysUntilIsDeprecated < 15;

const getNotification = (numberOfDays: number | false, type: string): BannerNotificationParams => {
    let notification;
    let timeframe;
    if (numberOfDays && numberOfDays < 1) {
        timeframe = "Today";
    } else if (numberOfDays && numberOfDays < 2) {
        timeframe = "OneDay";
    } else {
        timeframe = "Soon";
    }
    notification = {
        content: "tesseract.statusBanner." + type + "Expires" + timeframe,
        level: NotificationLevel.WARNING,
        dismissible: true,
        spinner: false,
        translateParams: {
            days: typeof numberOfDays === 'number' ? Math.floor(numberOfDays) : undefined,
            linkStart: '<a target="_blank" rel="noopener noreferrer" href="https://portal.azure.com/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources">',
            linkEnd: '</a>',
        }
    };
    return notification;
};
export default () => {
    const [subscriptionStatus] = useCrudSubscription(CrudTypes.GET, apiResources.subscriptions, {}, {
        pollInterval: DAY_AS_MILLISECONDS
    });
    const [mode] = useGlobalParam("userData.mode");
    const subscriptionData = getDataContent(subscriptionStatus);
    const daysUntilSubscriptionExpires = subscriptionData?.expiry && getDaysUntilSubscriptionExpires(subscriptionData.expiry) !== null ? getDaysUntilSubscriptionExpires(subscriptionData.expiry) : false;
    const daysUntilIsDeprecated = subscriptionData && !subscriptionData.deprecated && getDaysUntilIsDeprecated(subscriptionData.deprecatedDatetime) !== null ? getDaysUntilIsDeprecated(subscriptionData.deprecatedDatetime) : false;
    const postNotification = usePostNotification();

    let notification: BannerNotificationParams | undefined = undefined;

    if (deprecatedExpiresSoon(daysUntilIsDeprecated)) {
        notification = getNotification(daysUntilIsDeprecated, 'deprecated');
    } else if (subscriptionExpiresSoon(daysUntilSubscriptionExpires, mode === "demo")) {
        notification = getNotification(daysUntilSubscriptionExpires, 'subscription');
    }
    const previousNotification = usePrevious(notification);
    useDeepCompareEffect(() => {
        if (previousNotification) {
            postNotification({
                event: NotificationEventType.HIDE,
                display: NotificationDisplayType.BANNER,
                params: previousNotification
            });
        }
        if (notification) {
            postNotification({
                event: NotificationEventType.SHOW,
                display: NotificationDisplayType.BANNER,
                params: notification
            });
        }
    }, [notification]);
    return null;
};
import React from "react";
import {LogoutMenu} from "@cuda-react/core";
import {useLogoutMenu} from "../hooks/logoutMenuHooks";
import {LicenseIcon} from "@cuda-react/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

const CustomLogoutMenu = () => {
    const {supportAccessEnabled, onSupportAccessAccept} = useLogoutMenu();
    const [translate] = useTranslation();
    const history = useHistory();

    return (
        <LogoutMenu
            additionalChoices={[{name: translate("stratos.subscription.pam.menuTitle"), icon: <LicenseIcon/>, onClick: () => history.push("/subscription/pam")}]}
            supportAccessEnabled={supportAccessEnabled}
            onSupportAccessAccept={onSupportAccessAccept}
            showSupportAccess
        />
    );
};


export default CustomLogoutMenu;
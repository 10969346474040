import React from "react";
import Input, {InputProps} from "../Input/Input";
import SelectSerial from './SelectSerial';

export interface SelectSerialInputProps extends Omit<InputProps<typeof SelectSerial>, "displayError" | "component"> {}

export const SelectSerialInput = (props: SelectSerialInputProps) => {
    const {children, ...restProps} = props;
    return (
        <Input noLabel {...restProps} displayError={false} component={SelectSerial}>
                {children}
        </Input>
    );
};

export default SelectSerialInput;
import React from "react";
import {ConnectedForm, DialogBody, SelectArrayInput, TextInput} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {User} from '../Users.types';
import useDemoApi from "../../../../hooks/useDemoApi";

interface CreateEditUserProps {
    create?: boolean;
    id?: string | number;
    onClose?: () => void;
    onSuccess?: () => void;
}

export const CreateEditUser: React.FC<CreateEditUserProps> = ({create, id, onSuccess, ...props}) => (
    <DialogBody
        {...props}
        form
        title={create ?
            "tesseract.identity.users.create" :
            "tesseract.identity.users.edit"
        }
    >
        <ConnectedForm
            resource={useDemoApi(apiResources.identityUsers, apiResources.demoSingleUser)}
            create={create}
            params={create ? undefined : {id}}
            onSubmitSuccess={() => {
                onSuccess && onSuccess();
                props.onClose?.();
            }}
            formatResourceData={(data: User) => {
                if (!data) {
                    return;
                }
                return {
                    ...data,
                    groups: data?.groups?.map((group: any) => group.id) ?? []
                };
            }}
            onCancel={props.onClose}
            formatRequestData={(data: Omit<User, 'groups'> & { groups: string[] }) => {
                const newData: Omit<User, 'groups'> & {
                    groupIds: string[],
                } = {
                    ...data,
                    groupIds: data.groups,
                };

                // @ts-ignore
                delete newData.groups;
                return newData;
            }}
        >
            <TextInput
                source="name"
                label="tesseract.identity.users.name"
                isRequired
            />
            <TextInput
                source="email"
                label="tesseract.identity.users.email"
                isRequired
            />
            <SelectArrayInput
                resource={useDemoApi(apiResources.identityGroups, apiResources.demoGroups)}
                source="groups"
                optionValue="id"
                label="tesseract.identity.users.groups"
                options={{
                    params: {
                        filter: {
                            type: 'local',
                        },
                    },
                }}
            />
        </ConnectedForm>
    </DialogBody>
);
import React from "react";
import {makeStyles} from "@mui/styles";
import get from "lodash/get";
import {categoryIcons} from "../../../../components/iconMapping";

export const styles = () => ({
    icon: {
        minWidth: 32,
        verticalAlign: "middle"
    }
});

const useStyles = makeStyles(styles);

interface PolicyIconFieldProps {
    source: string;
    data?: Record<string, any>;
}

export const PolicyIconField: React.FC<PolicyIconFieldProps> = ({source, data}) => {
    const icon = get(categoryIcons, get(data, source));
    const classes = useStyles();

    return icon ? React.cloneElement(icon, {classes}) : null;
};


export default PolicyIconField;
import React from "react";
import {Card, StatusIconField, TableCardContent, TextField} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {get} from "lodash";
import {getVpnTunnelStatusIcon} from "../../../../components/iconMapping";

interface VPNTunnelsCardProps {
    details: {
        id: number | string,
    },
    gatewayType?: "on-prem" | "cloud" | "managed",
    type: "gateways" | "sites",
    column?: number;
}

const flattenVPNTunnels = (content: any) => content.flatMap((instance: any) => instance.tunnels);

const VPNTunnelsCard: React.FC<VPNTunnelsCardProps> = ({type, details, gatewayType}) => {
    const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");
    const apiResourcesLink = type === "sites" ? apiResources.configurationSiteVPNTunnels :
        (gatewayType === "on-prem" ? apiResources.configurationOnPremVPNTunnels : apiResources.configurationGatewayVPNTunnels);

    return (type === "sites" && isSc) ? null : (
        <Card
            id="VPNTunnelsCard"
            title="tesseract.appliances.dashboard.details.vpnTunnels.title"
        >
            <TableCardContent
                resource={apiResourcesLink}
                pollInterval={300000}
                params={{id: details.id}}
                formatData={(data) => Array.isArray(data) ? flattenVPNTunnels(data) : []}
            >
                <StatusIconField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.status"
                    source="status"
                    icon={getVpnTunnelStatusIcon}
                    textSource="status"
                    textPrefix="tesseract.appliances.dashboard.details.vpnTunnels.state."
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.name"
                    source="name"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.peer"
                    source="peer"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.local"
                    source="local"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.type"
                    source="type"
                />
            </TableCardContent>
        </Card>
    );
};


export default VPNTunnelsCard;
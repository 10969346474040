import {Options, PointClickEventObject, Series, SeriesMappointOptions, SeriesOptionsType} from "highcharts";
import {Theme} from "@mui/material";
import {TFunction} from "i18next";
import {get, merge} from "lodash";
import {ChartRef, ExtendedSeriesMappointOptions, LocationTooltipRef, SetLocationTooltip} from "./UseLocationsMap";
import {clearLinkedSeries, isLinkSeries} from "./clearLinkedSeries";
import {drawLinkedLines} from "./drawLinkedLines";
import {handlePointClick} from "./handlePointClick";
import mapDataWorld from "@highcharts/map-collection/custom/world-highres.geo.json";
import {CustomMapPoint} from "./getLinkedPoints";

/***
 * Generates the highchart options from the given data
 */
export const generateOptions = (chart: ChartRef, theme: Theme, legendEnabled: boolean, seriesData: Partial<SeriesMappointOptions>[], setTooltip: SetLocationTooltip, tooltipRef: LocationTooltipRef, translate: TFunction, chartOverrides?: any): Options => {
    const series = [
        {
            mapData: mapDataWorld,
            name: "Countries",
            nullColor: '#D4D4D4',
            borderColor: theme.palette.background.paper,
            enableMouseTracking: false,
            showInLegend: false,
            type: "map"
        }
    ] as ((ExtendedSeriesMappointOptions) | SeriesOptionsType)[];

    seriesData.forEach((dataPoint) => {
        series.push({
            animation: false,
            cursor: "pointer",
            type: "mappoint",
            dataLabels: {
                enabled: true,
                format: "{point.capital}"
            },
            name: dataPoint.label as string,
            data: dataPoint.data,
            color: dataPoint.color,
            borderColor: theme.palette.background.paper,
            zIndex: dataPoint.zIndex,
            marker: {
                symbol: "circle",
                lineWidth: 1,
                radius: 6,
                states: {
                    select: {
                        fillColor: dataPoint.color
                    }
                }
            },
            point: {
                events: {
                    click: (event: PointClickEventObject | { target: PointClickEventObject }) => {
                        const clickedTarget = "point" in event ? event : event.target;
                        const clickedPoint = clickedTarget.point as CustomMapPoint;

                        // If graphic field is null, point was a cluster
                        // @ts-ignore this property is missing from Highcharts click event point object description.
                        if (!clickedPoint.graphic) {
                            return false;
                        }

                        return handlePointClick(chart, clickedPoint, setTooltip, translate, theme);
                    }
                }
            },
            allowPointSelect: true,
            states: {
                hover: {
                    enabled: false
                },
                inactive: {
                    enabled: false
                }
            },
            originalData: dataPoint.data
        });
    });

    return merge({
        chart: {
            animation: false,
            events: {
                click: () => {
                    if (
                        chart.current &&
                        chart.current.series.some((series) => isLinkSeries(series) && get(series, "visible"))
                    ) {
                        clearLinkedSeries(chart, setTooltip);
                    }
                },
                render: (event: any) => {
                    // Verify tootlip on each render
                    if (tooltipRef && tooltipRef.current) {
                        const currentXaxis = get(event, "target.xAxis[0]");
                        const currentYaxis = get(event, "target.yAxis[0]");
                        const tooltipPoint = tooltipRef.current.point;
                        const tooltipElement = tooltipRef.current.element;

                        // Kill tooltip if ref data is missing
                        if (!tooltipPoint) {
                            setTooltip(null);
                            // Hide the tooltip by unsetting the anchor element if the point is no longer visible
                            // @ts-ignore complains that tooltipPoint could be undefined, even though it cant as it would've been caught be the previous if...
                        } else if (tooltipPoint.x > currentXaxis.max || tooltipPoint.x < currentXaxis.min || tooltipPoint.y > currentYaxis.max || tooltipPoint.y < currentYaxis.min) {
                            tooltipElement && setTooltip({element: null});
                            // Update the tooltip anchor element (so it moves correctly as the map is zoomed/dragged)
                        } else {
                            const pointSeries = get(event, "target.series", [])
                                .find((series: Series) => series.userOptions.id === "linked-points-source-point-series");
                            const tooltipElement = get(pointSeries, "points[0].graphic.element", null);

                            setTooltip({element: tooltipElement});
                        }
                    }
                    drawLinkedLines(chart, theme, true);
                }
            },
            height: "56.25%"
        },
        plotOptions: {
            mappoint: {
                cluster: {
                    enabled: true,
                    animation: false,
                    drillToCluster: false
                }
            }
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: legendEnabled,
            itemStyle: {
                ...theme.typography.body1,
                fontSize: "12px",
                fontWeight: "normal"
            }
        },
        mapNavigation: {
            enabled: true
        },
        series,
        title: {
            //@ts-ignore TODO: Is this even needed to be set if its null?
            text: null
        },
        tooltip: {
            enabled: false
        }
    }, chartOverrides || {});
};
import React from "react";
import {TableInput, TextField, TextInput} from "@cuda-react/core";
import {useRouteFormValidation, useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";

const RoutesTableInput = () => {
    const inputValidations = useSiteInputValidations();
    const routeFormValidation = useRouteFormValidation();

    return (
        <TableInput
            source="routes"
            optionValue="name"
            labels={{
                addTitle: "tesseract.appliances.settings.route.addTitle",
                addButtonText: "tesseract.appliances.settings.route.addButtonText",
                editTitle: "tesseract.appliances.settings.route.editTitle",
                deleteTitle: "tesseract.appliances.settings.route.deleteTitle",
                deleteMessage: "tesseract.appliances.settings.route.deleteMessage"
            }}
            formValidate={routeFormValidation}
            orderable
        >
            <TextField
                source="name"
                label="tesseract.appliances.settings.route.name"
                inputComponent={TextInput}
                inputProps={{
                    validate: inputValidations.validateName,
                    description: "tesseract.appliances.settings.route.descriptions.name",
                    isRequired: true
                }}
            />
            <TextField
                source="address"
                label="tesseract.appliances.settings.route.address"
                inputComponent={TextInput}
                inputProps={{
                    validate: inputValidations.validateIp,
                    description: "tesseract.appliances.settings.route.descriptions.address",
                    isRequired: true
                }}
            />
            <TextField
                source="netmask"
                label="tesseract.appliances.settings.route.netmask"
                inputComponent={TextInput}
                inputProps={{
                    validate: inputValidations.validateNetmask,
                    description: "tesseract.appliances.settings.route.descriptions.netmask",
                    isRequired: true
                }}
            />
            <TextField
                source="gateway"
                label="tesseract.appliances.settings.route.gateway"
                width={164}
                inputComponent={TextInput}
                inputProps={{
                    validate: inputValidations.validateIp,
                    description: "tesseract.appliances.settings.route.descriptions.gateway",
                    isRequired: true
                }}
            />
        </TableInput>
    );
};

export default RoutesTableInput;
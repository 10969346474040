import React, {Fragment} from 'react';
import {CircularProgress, Typography} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export const styles = (theme: Theme) => createStyles({
    root: {
        textAlign: "center",
    },
    container: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(1)
    },
    spinner: {
        marginTop: 1
    }
});
const useStyles = makeOverrideableStyles("TableFooterRow", styles);

export interface TableFooterRowProps extends StyledComponentProps<typeof styles> {
    /** the number of columns in the table */
    colSpan: number,

    /** the state to show in the indicator */
    state: "loading" | "end"
}

/**
 * An information row designed to be rendered inside ConnectedTable
 *
 * Shows the state of data in the table as if it were a row of data within the table, particularly useful when the table loads more data as you approach the end of the table
 *
 * @internal
 */
const TableFooterRow = (props: TableFooterRowProps): JSX.Element => {
    const {colSpan, state} = props;
    const styles = useStyles(props);
    const [translate] = useTranslation();
    return (
        <tr>
            <td colSpan={colSpan} className={styles.root}>
                <span className={styles.container}>
                    {state === "loading" && (
                        <Fragment>
                            <Typography>{translate("cuda.table.footer.loading")}&hellip;</Typography>
                            <CircularProgress id="cuda-loading-spinner" size={16} thickness={2} className={styles.spinner}/>
                        </Fragment>
                    )}
                    {state === "end" &&
                        <Typography>{translate("cuda.table.footer.end")}</Typography>
                    }
                </span>
            </td>
        </tr>
    );
};

export default TableFooterRow;
import React from "react";
import {useDeepCompareEffect, usePrevious,} from "@cuda-react/core";
import SCWanTableInput from "../../inputs/SCWanTableInput";
import {useFormContext, useWatch} from "react-hook-form";

interface Wan {
    mode: string;
}

interface WansTabProps {
    id?: string | number;
}

const WansTab: React.FC<WansTabProps> = ({id}) => {
    const {setValue} = useFormContext();
    const [serials = [], wans = []] = useWatch({name: ["serials", "wans"]});
    const oldSerials = usePrevious<string[]>(serials);

    useDeepCompareEffect(() => {
        const removedSerial = oldSerials?.filter((serial: any) => !serials.includes(serial));
        const newSerial = oldSerials && serials && serials.filter((serial: any) => !oldSerials?.includes(serial));
        wans.forEach((wan: Wan, wanIndex: number) => {
            if (wan.mode === "wwan" && removedSerial && newSerial) {
                setValue(`wans[${wanIndex}].pin.serial`, newSerial[0]);
            }
        });
    }, [serials]);

    return (
        <SCWanTableInput />
    );
};
export default WansTab;
import {parse} from "query-string";
import {useCallback, useEffect, useState} from "react";
import {Account, CrudTypes, useAccounts, useCrudFetch, useGlobalParam, useMixpanel} from "@cuda-react/core";
import {get} from "lodash";
import {useHistory, useLocation} from "react-router-dom";
import apiResources from "../mainApiResources";

export const STATE = {
    LOADED: "LOADED",
    PERFORMING_VALIDATE: "PERFORMING_VALIDATE",
    ERROR: "ERROR",
    VALIDATE_SUCCEEDED: "VALIDATE_SUCCEEDED",
    PERFORMING_SUBSCRIBE: "PERFORMING_SUBSCRIBE"
};

const useSubscriptionValidation = () => {
    const registerSubscriptionAction = useMixpanel("Subscription", false);
    const location = useLocation();
    const {push} = useHistory();
    const queryValues = parse(location.search);
    const [status, setStatus] = useState(STATE.LOADED);
    const [, , performValidate] = useCrudFetch(CrudTypes.CREATE, apiResources.validateSubscription, {data: queryValues}, {quietErrors: true});
    const [, , performSubscribe] = useCrudFetch(CrudTypes.CREATE, apiResources.purchaseSubscription, {data: queryValues}, {quietErrors: true});
    const [approvedAccount, setApprovedAccount] = useState(false);
    const [availableAccounts, currentAccount] = useAccounts(undefined, apiResources.accountUser);
    const [userData, setUserData] = useGlobalParam("userData");
    const {subscription, previousSubscribe} = userData || {};
    const subscriptionValid = get(subscription, "licensed") && !get(subscription, "expiry") && !get(subscription, "deprecated");
    const previousSelectedAccount = previousSubscribe && currentAccount && get(previousSubscribe, "account") === get(currentAccount, "id") && get(previousSubscribe, "serial") === get(queryValues, "serial");

    const setPreviousSubscribe = useCallback((account: Account) => setUserData({
        ...userData,
        previousSubscribe: {account: account.id, serial: queryValues.serial}
    }), [userData]);
    const registerError = () => {
        registerSubscriptionAction("Failed", {referrer: document.referrer});
        setStatus(STATE.ERROR);
    };
    const complete = () => {
        setUserData(null);
        push("/");
    };
    const subscribe = () => {
        setStatus(STATE.PERFORMING_SUBSCRIBE);
        performSubscribe().then(({error}) => {
            if (error) {
                registerError();
            } else {
                registerSubscriptionAction("Subscribed", {referrer: document.referrer});
                complete();
            }
        }).catch(() => {
            registerError();
        });
    };

    useEffect(() => {
        if (queryValues && queryValues.serial) {
            performValidate().then(({error}) => {
                if (error) {
                    registerError();
                } else {
                    // only one account, which does not have a valid subscription, can be auto-subscribed to
                    if ((availableAccounts.length === 1) && !subscriptionValid) {
                        subscribe();
                        // accounts not yet loaded, multiple accounts, or already has a valid subscription, so need to stop and ask user what to do
                    } else {
                        setStatus(STATE.VALIDATE_SUCCEEDED);
                    }
                }
            }).catch(() => {
                registerError();
            });
            setStatus(STATE.PERFORMING_VALIDATE);
        } else if (subscriptionValid) {
            complete();
        } else {
            push("/welcome");
        }
    }, []);

    useEffect(() => {
        // Subscribe when either: user has approved current account, selected one previously, or accounts have loaded and there is only one.
        if (status === STATE.VALIDATE_SUCCEEDED && (availableAccounts.length === 1 || approvedAccount || previousSelectedAccount) && !subscriptionValid) {
            subscribe();
        }
    }, [subscriptionValid, previousSelectedAccount, status, approvedAccount, availableAccounts.length]);

    return {
        status,
        continueSubscribing: () => subscriptionValid ? complete() : setApprovedAccount(true),
        availableAccounts,
        currentAccount,
        subscriptionValid,
        onAccountChange: setPreviousSubscribe
    };
};

export default useSubscriptionValidation;
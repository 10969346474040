import {List, ListItem, ListItemIcon, ListItemText} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {get} from "lodash";
import React from "react";
import {StatusIcon} from "@cuda-react/icons";
import {formatDateTime} from "../../../utils";
import LoadingMessage from "../../loading/LoadingMessage/LoadingMessage";
import NoDataMessage from "../../loading/NoDataMessage/NoDataMessage";
import {getFrom, getFromSupportedProps, useCardGetFrom} from "../../../hooks";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

interface BaseEventCardContentProps {
    /**
     * The dot-path key to a property in each array item that defines the text to display as the event's date. No date is displayed if no dateKey is provided.
     */
    dateKey?: string,
    /**
     * optional array filter function for filtering which items to show. Should return "true" for each provided item that is wanted to be rendered.
     */
    filter?: (item: any) => boolean,
    /**
     * classname provided to each item component
     *
     * This is now deprecated, please use the 'classes' method of overriding styles.
     * @deprecated
     */
    itemClassName?: string,
    /**
     * icon to display on each entry when "statusKey" is provided
     */
    itemIcon?: React.ElementType,
    /**
     * map of icons to display, as matched by the entry status. Passed as the prop "iconMap" on each rendered StatusIcon or provided itemIcon.
     */
    itemIconMap?: {[key: string]: React.ElementType},
    /**
     * message to display if no data or an empty array is provided (either by data prop or returned by the CRUD request).
     */
    noDataMessage: string,
    /**
     * callback function called whenever an event item is clicked. Callback is called with the item data.
     */
    onClick?: (item: any) => void,
    /**
     * The dot-path key to a property in each array item that defines the serial to append to the event's main text.
     */
    serialKey: string,
    /**
     * the dot-path key to a property in each array item that defines the status of the item.
     *
     * If provided, a status Icon will be rendered for each item. By default this is @cuda-react/icons/StatusIcon.
     * The status of each item is passed down to the prop "status" on the rendered icon.
     */
    statusKey?: string,
    /**
     * The dot-path key to a property in each array item that defines the text to display as the event's main text.
     */
    textKey: string,
    /**
     * The dot-path key to a property in each array item that defines the text to display as the event's title.
     */
    titleKey: string,
    /**
     *  if false, content is not rendered (although CRUD data is still collected).
     *
     *  This prop is passed down by [TabbedCard](/?path=/docs/) when tab is not currently selected.
     */
    visible: boolean
}

export const styles = (theme: Theme) => createStyles<string, BaseEventCardContentProps>({
    secondaryText: {
        color: theme.palette.text.secondary,
        // @ts-ignore complains the value isnt valid for this, but it is.
        wordWrap: "break-word !important",
        fontSize: 14,
        lineHeight: "16px",
        margin: theme.spacing(0.5, 0, 0)
    },
    secondaryTextDate: {
        float: "right",
        marginLeft: theme.spacing(4)
    },
    listItem: (props) => ({
        cursor: props.onClick ? "pointer" : "default",
        backgroundColor: theme.palette.background.default,
        "&:hover": {
            // @ts-ignore hover is a property added by CudaTheme
            backgroundColor: theme.palette.background.hover
        },
        "&:nth-child(odd)": {
            backgroundColor: "transparent"
        },
        "&:nth-child(odd):hover": {
            // @ts-ignore hover is a property added by CudaTheme
            backgroundColor: theme.palette.background.hover
        },
        padding: theme.spacing(0, 2)
    }),
    listItemIcon: {
        width: 40,
        float: "left",
        display: "inline-block",
        position: "relative",
        paddingLeft: theme.spacing(1)
    },
    listItemText: {
        width: "calc(100% - 56px)",
        minWidth: 160,
        display: "inline-block"
    },
    list: {
        padding: theme.spacing(0),
        margin: 0,
        maxHeight: 490,
        overflowX: "hidden"
    },
    message: {
        display: "flex",
        justifyContent: "center"
    }
});

const useStyles = makeOverrideableStyles("EventCardContent", styles);

export interface EventCardContentProps<Data extends any[], Props extends object> extends BaseEventCardContentProps, getFromSupportedProps<Data, Props>, StyledComponentProps<typeof styles> {
    /**
     * CRUD configuration, defining the values to use for fetching data via the CRUD framework. You can provide the CRUD parameters either
     * inside a getFrom object, or as individual props themselves.
     *
     * See [cardGetFromPropTypes](/?path=/docs/core-hooks-card-hooks--page#cardgetfromproptypes--codeobjectcode) for full definition.
     */
    getFrom?: getFrom<Data, Props>,
}

/**
 * A Card that contains a list of 'events' (which consist of a status
 * icon, title, text and a date). The status icon and date fields
 * are optional, and the title and text have defaults of 'title' and 'text'
 * respectively.
 * You can optionally provide a filter method to filter the data before it
 * is rendered into ListItems.
 */
export const EventCardContent = <Data extends any[], Props extends EventCardContentProps<Data, Props>>(props: Props) => {
    const {
        getFrom,
        filter,
        noDataMessage,
        visible,
        itemClassName,
        titleKey,
        textKey,
        serialKey,
        dateKey,
        statusKey,
        onClick,
        itemIcon,
        itemIconMap
    } = props;
    const classes = useStyles(props);

    const [getFromData, getFromStatus, getFromError] = useCardGetFrom(getFrom, props);
    const content = Array.isArray(getFromData) ? getFromData : [];
    const filteredContent = content.filter(filter ? filter : () => true);
    const isLoading = !!getFromStatus;
    const hasData = filteredContent.length > 0;

    if (!visible) {
        return null;
    }

    return (
        <React.Fragment>
            {!getFromError && hasData && (
                <List className={classes.list}>
                    {filteredContent.map((item, index) => {
                        const serialText = get(item, serialKey);
                        const secondaryText = get(item, textKey);
                        const primaryText = serialText && !secondaryText.includes(serialText) ? `${get(item, titleKey)} (${serialText})` : get(item, titleKey);
                        const dateTime = dateKey && formatDateTime(get(item, dateKey));
                        const LeftIcon = itemIcon || StatusIcon;
                        return (
                            <ListItem
                                className={classNames(classes.listItem, itemClassName)}
                                key={"copy-" + index}
                                onClick={onClick ? () => onClick(item) : undefined}
                            >
                                {statusKey && (
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <LeftIcon
                                            status={get(item, statusKey)}
                                            iconMap={itemIconMap}
                                        />
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    className={classes.listItemText}
                                    primary={primaryText}
                                    secondary={
                                        <React.Fragment>
                                            {secondaryText}
                                            {dateKey ? (
                                                <span className={classes.secondaryTextDate}>
                                                {dateTime}
                                                </span>
                                            ) : null}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            )}
            {(getFromError || !hasData) && [
                isLoading && !getFromError && (<LoadingMessage key="loading"/>),
                getFromError && (<NoDataMessage message={getFromError} key="error"/>),
                !isLoading && !getFromError && (<NoDataMessage message={noDataMessage} key="noData"/>)
            ]}
        </React.Fragment>
    );
};

EventCardContent.defaultProps = {
    textKey: "text",
    titleKey: "title",
    serialKey: "serial",
    noDataMessage: "cuda.cards.noEventData",
    visible: true
};

export default EventCardContent;
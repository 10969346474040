import React from "react";
import {BasicPage, Tab, Tabs, useMixpanel} from "@cuda-react/core";
import {Paper} from "@barracuda-internal/bds-core";
import IPSSettings from "./IPSSettings";
import IPSFalsePositives from "./IPSFalsePositives";

export const IPS = () => {
    useMixpanel("IPS");

    return (
        <BasicPage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.security", "tesseract.securityAndAccess.ips"]}
            authenticated
        >
            <Paper>
                <Tabs>
                    <Tab label="tesseract.security.ips.settings.tabTitle">
                        <IPSSettings/>
                    </Tab>
                    <Tab label="tesseract.security.ips.settings.falsePositivesTabTitle">
                        <IPSFalsePositives/>
                    </Tab>
                </Tabs>
            </Paper>
        </BasicPage>
    );
};

export default IPS;
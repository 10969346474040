import classNames from "classnames";
import get from "lodash/get";
import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

const styles = (theme: Theme) => createStyles({
    text: {
        lineHeight: "24px",
        color: theme.palette.common.black,
        fontSize: theme.typography.pxToRem(14)
    }
});

const useStyles = makeOverrideableStyles("TextField", styles);

export interface TextFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps {
    /**
     * className for the root component.
     */
    className?: string,
    /**
     * if source entry is not find (or not pass as prop), this will be the text ti render.
     */
    defaultText?: string,
}

/**
 * Renders a text field wrapped in a typography component.
 */
export const TextField = (props: TextFieldProps) => {
    const {data, defaultText, className, source = ""} = props;
    const classes = useStyles(props);

    return (
        <Typography variant="body2" component="span" className={classNames(className, classes.text)}>
            {get(data, source) ?? defaultText}
        </Typography>
    );
};

export default TextField;
import React, {useState} from "react";
import {ConnectedWizard, InputStep, ResultStep, SubmitStep, useCrudProps, useMixpanel,} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import EnrollSelectionStep from "./EnrollSelectionStep";
import EmailPreviewStep from "./EmailPreviewStep";
import ResolveSeatLimitStep from "./ResolveSeatLimitStep";
import EnrollSuccessStep from "./EnrollSuccessStep";
import {parseJson} from "../../../../utils/jsonUtils";

export const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 700
    },
    disableStepper: {
        display: "none"
    }
}));

export type CreateEnrollEmailDialogProps = {
    create?: boolean,
    id?: number | string,
    email?: string,
    onClose?: () => void,
    onSuccess?: () => void,
};

const CreateEnrollEmailDialog = (props: CreateEnrollEmailDialogProps) => {
    const {create, id, email, onClose, onSuccess} = props;
    const [translate] = useTranslation();
    const classes = useStyles();
    const [{data: seatsData}, , fetchRemainingSeats] = useCrudProps(apiResources.zeroTrustUsersRemainingSeats);
    const remainingSeats = seatsData?.count;
    const [isEmailEnabled, setEmailEnabled] = useState(true);
    const [seatLimitExceeded, setSeatLimitExceeded] = useState(false);
    const [resolvedUsersChoices, setResolvedUsersChoices] = useState(undefined);
    const registerAction = useMixpanel(create ? "Endpoint Users Invite" : "Endpoint Users Resend");

    return (
        <ConnectedWizard
            resource={create ? apiResources.sendZeroTrustEnrollEmail : apiResources.resendZeroTrustEnrollEmail}
            update={!create}
            title={create ? "tesseract.endpoint.enrollEmailDialog.emailTitle" : "tesseract.endpoint.enrollEmailDialog.resendEmailTitle"}
            onSubmitSuccess={() => {
                onSuccess?.();
                registerAction("Success");
                fetchRemainingSeats();
            }}
            onClose={onClose}
            initialValues={!create ? {
                users: [email],
                subject: translate("tesseract.endpoint.enrollEmailDialog.zeroTrustSubject"),
                enrollmentEmailEnabled: true,
                id
            } : {
                enrollmentEmailEnabled: true,
                subject: translate("tesseract.endpoint.enrollEmailDialog.zeroTrustSubject"),
            }}
            formatRequestData={(data) => ({
                ...data,
                users: seatLimitExceeded ? data?.resolvedUsersDualList : data?.users,
                groups: seatLimitExceeded ? null : data?.groups
            })}
            stepperProps={{
                classes: {
                    root: classes.disableStepper,
                    divider: classes.disableStepper,
                }
            }}
            dialogBodyProps={{classes: {dialogContent: classes.dialog}}}
            onChange={(data) => setEmailEnabled(data?.enrollmentEmailEnabled)}
            validate={(data) => {
                if (create && !data?.users?.length && !data?.groups?.length) {
                    return {
                        users: translate("tesseract.endpoint.enrollEmailDialog.validate.userGroupRequired"),
                        groups: translate("tesseract.endpoint.enrollEmailDialog.validate.userGroupRequired")
                    };
                }
                return {};
            }}
            formatError={(errors) => {
                setSeatLimitExceeded(errors?.resolvedUsers);
                setResolvedUsersChoices(errors?.resolvedUsers && parseJson(errors?.resolvedUsers));
                return errors;
            }}
            onStepChange={() => {
                setSeatLimitExceeded(false);
            }}
        >
            {isEmailEnabled ? (
                <InputStep >
                    <EnrollSelectionStep
                        create={create}
                        remainingSeats={remainingSeats}
                    />
                </InputStep>
                ) :
                !seatLimitExceeded ? (
                    <SubmitStep primaryLabel="tesseract.endpoint.enrollEmailDialog.enroll">
                        <EnrollSelectionStep
                            create={create}
                            remainingSeats={remainingSeats}
                        />
                    </SubmitStep>
                ): null
            }
            {!seatLimitExceeded && isEmailEnabled ?
                <SubmitStep
                    primaryLabel="tesseract.endpoint.enrollEmailDialog.send"
                >
                    <EmailPreviewStep />
                </SubmitStep> : null
            }
            {seatLimitExceeded && resolvedUsersChoices ?
                <SubmitStep
                    primaryLabel={isEmailEnabled ? "tesseract.endpoint.enrollEmailDialog.send" : "tesseract.endpoint.enrollEmailDialog.enroll"}
                    secondaryButtonOnClick={isEmailEnabled ? undefined : () => setSeatLimitExceeded(false)}
                    secondaryButtonLabel={isEmailEnabled ? undefined : "cuda.buttons.back"}
                    // TODO: Need to change the color of the secondary button from primary to secondary
                >
                    <ResolveSeatLimitStep
                        remainingSeats={remainingSeats}
                        resolvedUsersChoices={resolvedUsersChoices}
                    />
                </SubmitStep> : null
            }
            <ResultStep onClose={onClose}>
                <EnrollSuccessStep create={create} />
            </ResultStep>
        </ConnectedWizard>
    );
};

export default CreateEnrollEmailDialog;
import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const PointOfEntryGraphic = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-pointofentry"
            viewBox="0 0 96 96"
        >
            <g id="ArtBoard11">
                <g id="Ebene-1">
                    <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                          d="M25.9,33.7l7.2,7.2H7.6v5.1h25.5l-7.1,7.1,3.6,3.6,13.3-13.3-13.3-13.3-3.6,3.6Z"/>
                    <path style={{fill: theme.palette.general?.white, strokeWidth: 0}} d="M73.1,58.7h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z"/>
                    <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                          d="M13.8,18.1v19.5h4.7v-14.8h28.4v39.2h-28.4v-12.9h-4.7v17.6h36.1l1.6.6c0,0,0-.1,0-.2,0-.1,0-.3,0-.4h.1v-2c1-8.2,7-14.8,14.8-16.8V11.7l-16.5,6.4H13.8Z"/>
                    <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} d="M68.8,11.7v35.8c.9-.1,1.7-.2,2.6-.2s1.7,0,2.6.2V11.7h-5.2Z"/>
                    <path style={{fill: theme.palette.success?.main, strokeWidth: 0}}
                          d="M71.4,50.2c-9.4,0-17,7.6-17,17s7.6,17,17,17,17-7.6,17-17-7.6-17-17-17ZM79.9,68.9h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default PointOfEntryGraphic;
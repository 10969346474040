import React from "react";
import Input, {InputProps} from "../Input/Input";
import EditableTable from './EditableTable';

export interface TableInputProps extends Omit<InputProps<typeof EditableTable>, "component" | "displayError"> {}

/**
 * Based on the EditableTable component, but modified to use the default [Input](/?path=/docs/core-components-inputs-input) format.
 */
export const TableInput = (props: TableInputProps) => {
    const {children} = props;
    return (
        <Input {...props} displayError={false} component={EditableTable}>
            {children}
        </Input>
    );
};

export default TableInput;
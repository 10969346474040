import {isEmpty} from "lodash";
import React, {useEffect} from "react";
import {useAuthEvents, useGlobalParam} from "../../../hooks";
import {AuthEventType} from "../../providers/AuthProvider/AuthProvider";

export interface AuthenticatedProps {
    /**
     * Parameters to pass to the auth check event creator
     */
    authParams?: any,
    /**
     * the content to render if user is authenticated.
     */
    children: React.ReactNode
}

/**
 * Ensures user is authenticated before displaying children.
 *
 * Children elements are not rendered until the GlobalParam state contains a userData object.
 * Additionally, it triggers an auth check event on mount (with provided params).
 */
export const Authenticated = ({children, authParams}: AuthenticatedProps) => {
    const [authData] = useGlobalParam("userData");
    const postAuthEvent = useAuthEvents();

    useEffect(() => {
        postAuthEvent({type: AuthEventType.CHECK, params: authParams});
    }, []);

    return (
        <React.Fragment>
            {!isEmpty(authData) ? children : null}
        </React.Fragment>
    );
};

export default Authenticated;
import {
    ActionButtonsField,
    ChipArrayField,
    DeleteDialog,
    EmptyPageContent,
    getArrayDataContent,
    ListField,
    SelectedChoiceField,
    SelectField,
    TablePage,
    TextField,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import {AppsResourcesGraphic, CustomAppsIcon, GetStartedBackgroundGraphic} from "@cuda-react/icons";
import React, {useRef, useState} from "react";
import {categoryIcons} from "../../../components/iconMapping";
import CreateCustomAppDialog from "./dialogs/CreateCustomAppDialog";
import EditCustomAppDialog from "./dialogs/EditCustomAppDialog";
import {makeStyles} from "@mui/styles";
import {get} from "lodash";
import CustomAppDestinationFilter from "./CustomAppDestinationFilter";
import apiResources from "../../../apiResources";

const useStyles = makeStyles({
    destinations: {
        width: "30%"
    }
});

/**
 * For now we are using this workaround to get the karma tests working.
 */

const CustomApps = () => {
    const registerAction = useMixpanel("Apps");
    const choicesData = useCrudProps(apiResources.sdwanPolicies)[0]?.data;
    // @ts-ignore
    const protocolData = useCrudProps(apiResources.protocol, {}, {cache: true})[0]?.data;
    // @ts-ignore
    const typeData = useCrudProps(apiResources.customAppTypes, {}, {cache: true})[0]?.data;
    const classes = useStyles();
    const tableRefreshRef = useRef<any>();
    const [actionVisible, setActionVisible] = useState(false);

    return (
        <TablePage
            resource={apiResources.customApps}
            title="tesseract.securityAndAccess.pageTitle"
            subtitle="tesseract.securityAndAccess.apps"
            titleActions={actionVisible &&
                <CreateCustomAppDialog
                    // @ts-ignore
                    resource={apiResources.customApps}
                    onSuccess={tableRefreshRef.current}
                />
            }
            params={(data) => ({
                ...data,
                filter: {
                    ...get(data, "filter", {}),
                    ...get(data, "filter.destinations", {}),
                    destinations: undefined,
                    internal: 0
                }
            })}
            minCellWidth={224}
            authenticated
            resetOnGlobalParamChange
            refreshRef={tableRefreshRef}
            onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
            noDataPage={
                <EmptyPageContent
                    background={<GetStartedBackgroundGraphic/>}
                    icon={<AppsResourcesGraphic/>}
                    iconHeader="tesseract.security.applicationControl.noData.appResource.iconHeader"
                    textHeader="tesseract.security.applicationControl.noData.appResource.textHeader"
                    text="tesseract.security.applicationControl.noData.appResource.text"
                    actions={
                        <CreateCustomAppDialog
                            // @ts-ignore
                            create
                            label="tesseract.security.applicationControl.noData.appResource.labelButton"
                            onSuccess={tableRefreshRef.current}
                        />
                    }
                />
            }
        >
            <ListField
                source="name"
                label="tesseract.customApps.table.name"
                icon={<CustomAppsIcon/>}
                filter="text"
                // @ts-ignore
                cellClassName={classes.name}
                sortable
            />
            <TextField
                source="description"
                label="tesseract.customApps.table.description"
                filter="text"
                // @ts-ignore
                cellClassName={classes.description}
                sortable
            />
            <SelectedChoiceField
                source="type"
                label="tesseract.customApps.table.type"
                choices={getArrayDataContent(typeData)}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(typeData)
                }}
                sortable
                width={120}
            />
            <ChipArrayField
                source="destinations"
                render={(value) => {
                    const lastPort = value.lastPort && value.lastPort !== value.firstPort ? "-" + value.lastPort : "";
                    const portRange = (value.firstPort ? ":" + value.firstPort + lastPort : "");
                    const protocolName = value.protocol && value.protocol !== "any" && (getArrayDataContent(protocolData).find((protocol) => protocol.key === value.protocol) || {}).name;
                    return value.destination + portRange + (protocolName ? " (" + protocolName + ")" : "");
                }}
                label="tesseract.customApps.table.destination"
                filter="custom"
                filterProps={{
                    component: CustomAppDestinationFilter,
                    choices: getArrayDataContent(protocolData)
                }}
                cellClassName={classes.destinations}
                sortable
            />
            <SelectField
                source="categoryId"
                label="tesseract.customApps.table.category"
                resource={apiResources.customApps}
                id={(data) => data.id}
                choices={getArrayDataContent(choicesData)}
                optionValue="category"
                iconMap={categoryIcons}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(choicesData),
                    optionValue: "category",
                    iconMap: categoryIcons
                }}
                columnProps={{
                    width: 250
                }}
                sortable
                onSuccess={tableRefreshRef.current}
            />
            <ActionButtonsField
                width={128}
                source="id"
            >
                <EditCustomAppDialog id={(data: any) => data.id} onSuccess={tableRefreshRef.current}/>
                <DeleteDialog
                    resource={apiResources.customApps}
                    title="tesseract.customApps.delete.title"
                    message="tesseract.customApps.delete.body"
                    onSuccess={() => {
                        tableRefreshRef.current?.();
                        registerAction("Delete");
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default CustomApps;
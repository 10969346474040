import {Link} from "@barracuda-internal/bds-core";
import get from "lodash/get";
import React from "react";
import {useHistory} from "react-router-dom";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

export const styles = (theme: Theme) => createStyles({
    root: {
        color: theme.palette.primary.main
    },
    link: {
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(14)
    }
});

const useStyles = makeOverrideableStyles("LinkField", styles);

export interface LinkFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps {
    /**
     * value to display in case the data does not content the specified source.
     */
    defaultText?: React.ReactNode,
    /**
     * the proper link you want to point to. Can either be a plain string, or a callback that returns the link.
     * If the link points to an external resource, it will be passed as href prop to the Link component.
     * If internal, it will be passed to the on onClick method to push the link into the history of clicked internal links.
     *
     * @function
     * @param {object} data the full data array
     * @returns {string} the link you want to point to.
     */
    link?: string | ((data: any) => string),
}

/**
 * Renders a Link component with a specific name and link to point to.
 */
export const LinkField = (props: LinkFieldProps) => {
    const {data, source = "", link, defaultText} = props;
    const classes = useStyles(props);
    const linkTarget = link && (typeof link === "string" ? link : link(data));
    const history = useHistory();
    const isExternal = linkTarget && linkTarget.startsWith("http");

    return (
        <span className={classes.root}>
            {linkTarget && (
                <Link
                    className={classes.link}
                    href={isExternal ? linkTarget : undefined}
                    target={isExternal ? "_blank" : undefined}
                    rel={isExternal ? "noopener" : undefined}
                    onClick={isExternal ? undefined : () => history.push(linkTarget)}
                >
                    {get(data, source, defaultText)}
                </Link>
            ) || get(data, source, defaultText)}
        </span>
    );
};

export default LinkField;
import React from "react";
import {Route, Switch, withRouter} from "react-router";
import UsersTable from "./UsersTable";
import UserDetails from "./user/UserDetails";

interface UsersProps {
    match: any
}

export const Users: React.FC<UsersProps> = ({match}) => (
    <Switch>
        <Route
            exact
            path={match.path}
            component={UsersTable}
        />
        <Route
            path={`${match.path}/:id`}
            component={UserDetails}
        />
    </Switch>
);


export default withRouter(Users) as React.ComponentClass;
import React from "react";
import {
    Card,
    CrudTypes,
    CustomField,
    getArrayDataContent,
    LinkField,
    LocationsCardContent,
    SelectedChoiceField,
    TextField,
    useCrudProps,
    useCrudSubscription,
    useGlobalParam
} from "@cuda-react/core";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
import apiResources from "../../../../apiResources";
import {useTheme} from "@mui/material";

interface TunnelLocationsCardProps {
    details: {
        id?: number | string,
        name?: string,
        gateway?: string | number,
        modelSeries?: string
    },
    type: "gateways" | "sites"
    column?: number;
}


const TunnelLocationsCard: React.FC<TunnelLocationsCardProps> = ({details, type}) => {
    const isStandaloneSite = type === "sites" && !details.gateway;
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const isIoT = details?.modelSeries === "S";
    const theme = useTheme();
    const regionChoices = useCrudProps(apiResources.gatewayRegions)[0]?.data;
    const scaleUnitChoices = useCrudProps(apiResources.gatewayScaleUnits)[0]?.data;
    const gatewayLocationsData = getArrayDataContent(useCrudSubscription(
        CrudTypes.GET,
        type !== "sites" ? apiResources.gatewayTunnelLocation : undefined,
        {id: details.id}
    )[0].data).map((gateway) => ({
        ...gateway,
        tseries: gateway.sites.filter((site: TunnelLocationsCardProps["details"]) => site?.modelSeries === "T"),
        iot: gateway.sites.filter((site: TunnelLocationsCardProps["details"]) => site?.modelSeries === "S")
    }));

    // Don't display card until the details have loaded for the first time (i.e. until the "name" field is set).
    const siteCardContent = details.name && (isStandaloneSite ? (
        <LocationsCardContent
            params={{id: details.id}}
            resource={apiResources.standaloneSiteLocation}
            series={[{
                label: "tesseract.appliances.dashboard.tunnelLocations.standaloneSites.label",
                color: theme.palette.general.graphite,
            }]}
        />
    ) : (
        <LocationsCardContent
            params={{id: details.id}}
            resource={apiResources.siteTunnelLocations}
            series={[
                {
                    label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
                    color: theme.palette.general.purple,
                },
                {
                    label: isIoT ? "tesseract.appliances.dashboard.tunnelLocations.iot.label" : "tesseract.appliances.dashboard.tunnelLocations.sites.label",
                    color: isIoT ? theme.palette.general.turquoise : theme.palette.general.blue,
                    source: "sites"
                }
            ]}
        />
    ));

    const cardContent = type === "sites" ? siteCardContent : (
        <LocationsCardContent
            data={gatewayLocationsData}
            series={[
                {
                    label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
                    color: theme.palette.general.purple,
                    zIndex: 4,
                    linkedSeries: {
                        color: theme.palette.general.darkBlue,
                        zIndex: 3,
                        selectedZIndex: 5
                    },
                    details: {
                        resource: apiResources.gateways,
                        optionValue: "id",
                        fields: [
                            <LinkField
                                label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.name"
                                source="name"
                                key="name"
                                link={getAzureResourceLink("applicationId", isDemo)}
                            />,
                            <CustomField
                                label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.sites"
                                source="tseries"
                                key="tseries"
                                render={(sites, data) => (
                                    <LinkField
                                        defaultText={(sites?.length) || sites}
                                        link={`/infrastructure/sites?filter={"gateway":[${data.id}]}`}
                                    />
                                )}
                            />,
                            <CustomField
                                label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.iot"
                                source="iot"
                                key="iot"
                                render={(sites, data) => (
                                    <LinkField
                                        defaultText={(sites?.length) || sites}
                                        link={`/infrastructure/iot?filter={"gateway":[${data.id}]}`}
                                    />
                                )}
                            />,
                            <LinkField
                                label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.vwan"
                                source="virtualWan.name"
                                key="vwanName"
                                link={getAzureResourceLink("virtualWan.resourceId", isDemo)}
                            />,
                            <LinkField
                                label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.hub"
                                source="hubName"
                                key="hubName"
                                link={getAzureHubLink("hubId", isDemo)}
                            />,
                            <SelectedChoiceField
                                label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.region"
                                source="region"
                                key="region"
                                choices={getArrayDataContent(regionChoices)}
                            />,
                            <SelectedChoiceField
                                label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.size"
                                source="scaleUnit"
                                key="scaleUnit"
                                choices={getArrayDataContent(scaleUnitChoices)}
                            />
                        ]
                    }
                },
                {
                    label: "tesseract.sites.pageTitle",
                    color: theme.palette.general.blue,
                    source: "tseries",
                    zIndex: 1,
                    linkedSeries: {
                        color: theme.palette.general.magenta,
                        zIndex: 6,
                        selectedZIndex: 2
                    },
                    details: {
                        resource: apiResources.sites,
                        optionValue: "id",
                        fields: [
                            <LinkField
                                label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.name"
                                source="name"
                                key="name"
                                link={(data) => `/infrastructure/sites/${data.id}`}
                            />,
                            <TextField
                                label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.location"
                                source="summary"
                                key="summary"
                            />,
                            <LinkField
                                label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.gateway"
                                source="gatewayName"
                                key="gatewayName"
                                link={(data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`}
                            />,
                            <TextField
                                label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.providers"
                                source="wanNetworks.length"
                                key="wans"
                            />
                        ]
                    }
                },
                {
                    label: "tesseract.network.iot.menuTitle",
                    color: theme.palette.general.turquoise,
                    source: "iot",
                    zIndex: 1,
                    linkedSeries: {
                        color: theme.palette.general.magenta,
                        zIndex: 6,
                        selectedZIndex: 2
                    },
                    details: {
                        resource: apiResources.sites,
                        optionValue: "id",
                        fields: [
                            <LinkField
                                label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.name"
                                source="name"
                                key="name"
                                link={(data) => `/infrastructure/iot/${data.id}`}
                            />,
                            <TextField
                                label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.location"
                                source="summary"
                                key="summary"
                            />,
                            <LinkField
                                label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.gateway"
                                source="gatewayName"
                                key="gatewayName"
                                link={(data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`}
                            />,
                            <TextField
                                label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.providers"
                                source="wanNetworks.length"
                                key="wans"
                            />
                        ]
                    }
                }
            ]}
        />
    );

    return (
        <Card
            id="TunnelLocationsCard"
            title={isStandaloneSite ? "tesseract.appliances.dashboard.tunnelLocations.standaloneSites.title" : "tesseract.appliances.dashboard.tunnelLocations.title"}
        >
            {/* @ts-ignore Card props for children doesn't support null, even though its valid. */}
            {cardContent}
        </Card>
    );
};


export default TunnelLocationsCard;
import {createContext, useContext} from "react";

export interface LogoutMenuProps {
    supportAccessEnabled: boolean,
    onSupportAccessAccept: (value: boolean) => void,
}

export const LogoutMenuContext = createContext<LogoutMenuProps>({
    supportAccessEnabled: false,
    onSupportAccessAccept: () => {
    }
});

export const useLogoutMenu = () => useContext(LogoutMenuContext);
import React from "react";
import {ConnectedTable} from "../../table";
import BasicPage, {BasicPageProps} from "../BasicPage/BasicPage";
import {ConnectedTableProps} from "../../table/ConnectedTable/ConnectedTable";

export interface TablePageProps extends Omit<BasicPageProps, "children">, Omit<ConnectedTableProps, "actions"> {
    /**
     * provided actions are supplied inline with the title.
     */
    titleActions?: ConnectedTableProps["actions"],
}

/**
 * Creates a [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table), within a
 * [BasicPage](/?path=/docs/core-components-pages-basicpage--basic-page).
 *
 * All props provided are passed through to the [BasicPage](/?path=/docs/core-components-pages-basicpage--basic-page) and
 * [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table) components. Please see their respective
 * pages for more details.
 */
export const TablePage = ({children, title, titleActions, actions, ...props}: TablePageProps): JSX.Element => (
    <BasicPage {...props} title={title} actions={titleActions}>
        <ConnectedTable actions={actions} {...props} paper>
            {children}
        </ConnectedTable>
    </BasicPage>
);

export default TablePage;
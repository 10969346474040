import React from "react";
import PropTypes from "prop-types";
import {Success} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import OffIcon from "./OffIcon";

const BooleanIcon = ({status, ...props}: any) => status ?
    <Success id="cuda-icon-on" sx={{color: "success.main"}} {...props} /> : <OffIcon {...props}/>;

BooleanIcon.propTypes = {
    status: PropTypes.bool
};

export default BooleanIcon;
import React, {useRef} from "react";
import {
    ActionButtonsField,
    Boolean,
    ChipArrayField,
    ConnectedTable,
    CrudTypes,
    DeleteDialog,
    getArrayDataContent,
    getDataContent,
    InputLabel,
    PropGateway,
    Select,
    StatusIconField,
    TextField,
    useCrudFetch,
    useCrudProps,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {makeStyles} from "@mui/styles";
import CreateEditIpsRules from "./CreateEditIpsRules";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {
    formatSourceDestinationFilters,
    formatUserGroupInTableData
} from "../../../../../components/sourceAndDestination/tableUtils";
import {ipsIcons} from "../../../../../components/iconMapping";
import {useSourceDestinationField} from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";

export const useStyles = makeStyles((theme) => ({
    cardActions: {
        paddingRight: theme.spacing(2),
        width: "calc(100% - " + theme.spacing(2) + ")"
    },
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    }
}));

export const IPSSettings = () => {
    const registerAction = useMixpanel("IPS Rule", false);
    const classes = useStyles();
    const tableRefreshRef = useRef<any>();
    const explicitActionChoices = getArrayDataContent(useCrudProps(apiResources.ipsExplicitActions)[0]?.data);
    const [ipsDefaultData, ipsDefaultLoading, refreshIpsDefault] = useCrudSubscription(CrudTypes.GET, apiResources.ipsExplicitDefaultAction);
    const [, ipsDefaultUpdating, updateIpsDefault] = useCrudFetch(CrudTypes.UPDATE, apiResources.ipsExplicitDefaultAction);
    const ipsDefault = getDataContent(ipsDefaultData);
    const defaultLoading = typeof ipsDefault !== "string" || ipsDefaultLoading || ipsDefaultUpdating;
    const handleUpdateIpsDefault = (defaultAction: any) => {
        if (defaultAction && !defaultLoading && defaultAction !== ipsDefault) {
            registerAction("Default Update", {action: defaultAction});
            return updateIpsDefault({defaultAction}).then(refreshIpsDefault);
        }
    };
    const [enabledData, enabledLoading, refreshEnabled] = useCrudSubscription(CrudTypes.GET, apiResources.ipsEnable);
    const [, enabledUpdating, updateEnabled] = useCrudFetch(CrudTypes.UPDATE, apiResources.ipsEnable);
    const enabled = getDataContent(enabledData);
    const loadingEnabled = typeof enabled !== "boolean" || enabledLoading || enabledUpdating;
    const handleUpdateIpsEnable = (newValue: any) => {
        if (!loadingEnabled && newValue !== enabled) {
            registerAction("Default Update", {enabled: newValue});
            return updateEnabled({enabled: newValue ? "enable" : "disable"}).then(refreshEnabled);
        }
    };
    const [, , callIpsExplicitUpdate] = useCrudFetch(CrudTypes.CREATE, apiResources.ipsExplicit);
    const handleOrderClickIpsExplicit = (id: any, direction: any, data: any) => callIpsExplicitUpdate({
        id,
        changeOrder: direction,
        data
    }).then(tableRefreshRef.current);
    const generateChipArrayPropsExplicit = useSourceDestinationField();

    return (
        <ConnectedTable
            resource={apiResources.ipsExplicit}
            exportResource={{resource: apiResources.ipsExplicitExport, filename: "ipsExplicit"}}
            actions={[
                <InputLabel
                    key="defaultAction"
                    label="tesseract.security.ips.settings.defaultAction"
                    minimised
                    // @ts-ignore
                    left
                >
                    <Select
                        key="defaultAction"
                        id="defaultAction"
                        value={!defaultLoading ? ipsDefault : ""}
                        onChange={(eventOrValue) => eventOrValue?.target ? handleUpdateIpsDefault(eventOrValue.target.value) : handleUpdateIpsDefault(eventOrValue)}
                        onBlur={() => {
                        }}
                        disabled={!!defaultLoading}
                        choices={explicitActionChoices}
                    />
                </InputLabel>,
                <InputLabel
                    key="enabled"
                    label="tesseract.security.ips.settings.inputs.enabled"
                    minimised
                    // @ts-ignore
                    left
                >
                    <Boolean
                        id="enabled"
                        value={typeof enabled !== "boolean" ? false : enabled}
                        onChange={(eventOrValue: any) => eventOrValue?.target ? handleUpdateIpsEnable(eventOrValue.target.value) : handleUpdateIpsEnable(eventOrValue)}
                        options={{disabled: !!defaultLoading}}
                    />
                </InputLabel>,
                <CreateEditButtonDialog
                    key="create"
                    create
                    component={CreateEditIpsRules}
                    resource={apiResources.ipsExplicit}
                    onSuccess={tableRefreshRef.current}
                />
            ]}
            params={formatSourceDestinationFilters}
            classes={{cardActions: classes.cardActions}}
            formatData={formatUserGroupInTableData}
            minCellWidth={128}
            flat
            refreshRef={tableRefreshRef}
        >
            <ActionButtonsField
                left
                label="tesseract.security.ips.settings.order"
                cellClassName={classes.orderCell}
                width={96}
                source="id"
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClickIpsExplicit(data.id, "increase", data)}
                    disabled={(data: { index: number; }) => data.index <= 0}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClickIpsExplicit(data.id, "decrease", data)}
                    disabled={(data: { index: number; total: number; }) => data.index >= (data.total - 1)}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.ips.settings.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.ips.settings.description"
                filter="text"
            />
            <StatusIconField
                source="action"
                text={(action) => (explicitActionChoices.find((item) => item.key === action) || {}).name}
                label="tesseract.security.ips.settings.action"
                iconMap={ipsIcons}
                cellClassName={classes.actionCell}
                filter="select"
                filterProps={{
                    choices: explicitActionChoices
                }}
                width={200}
            />
            <PropGateway
                source="source"
                label="tesseract.security.ips.settings.source"
                editProps={generateChipArrayPropsExplicit("source")}
                filter="custom"
                filterProps={{
                    component: SourceFilter,
                    filterSources: ["application", "network", "domain", "site"]
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <PropGateway
                source="destination"
                label="tesseract.security.ips.settings.destination"
                editProps={generateChipArrayPropsExplicit("destination")}
                filter="custom"
                filterProps={{
                    component: DestinationFilter,
                    filterSources: ["network", "site", "application", "domain"],
                    table: "IpsSettings"
                }}
            >
                <ChipArrayField source="destination"/>
            </PropGateway>
            <ActionButtonsField
                width={96}
                source="id"
            >
                <CreateEditButtonDialog component={CreateEditIpsRules} resource={apiResources.ipsExplicit}
                                        onSuccess={tableRefreshRef.current}/>
                <DeleteDialog
                    resource={apiResources.ipsExplicit}
                    title="tesseract.security.ips.settings.delete.title"
                    message="tesseract.security.ips.settings.delete.body"
                    onSuccess={() => {
                        registerAction("Delete");
                        tableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </ConnectedTable>
    );
};

export default IPSSettings;
import React from "react";
import Input, {InputProps} from "../Input/Input";
import AutoSelect from "./AutoSelect";

export interface AutoSelectInputProps extends Omit<InputProps<typeof AutoSelect>, "displayError" | "component"> {}

/**
 * form ready version of the AutoSelect component.
 * Wrapper component with [Input](/?path=/docs/core-components-inputs-input) format, to work natively with redux form "Field" prop.
 */
export const AutoSelectInput = (props: AutoSelectInputProps) => (
        <Input {...props} displayError={false} component={AutoSelect}/>
    );

export default AutoSelectInput;
import React, {useContext} from "react";
import StepContent, {StepContentProps} from "../StepContent/StepContent";
import WizardToolbar from "../WizardToolbar/WizardToolbar";
import FormErrorReporter from "../../forms/FormErrorReporter";
import {BaseStepProps} from "../StepType";
import {FormPropsContext} from "../../forms/FormProvider/FormProvider";

export interface InputStepProps extends BaseStepProps, StepContentProps {
    /**
     * If true, perform a full form submission at the end of this step.
     */
    submit?: boolean
}

/**
 * Wizard step component for form inputs.
 *
 * For use as a child of the [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) component, as it provides most of the context and required props.
 *
 * Renders the appropriate content section and toolbar. Children are rendered within the content section
 * (and are usually  *Input components).
 *
 * All additional props not described below are passed to the [StepContent](/?path=/docs/core-components-wizard-wizardtoolbar--wizard-toolbar).
 *
 * Some additional props can be set on InputStep, and are consumed by the parent [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) (such as "label").
 */
export const InputStep = (props: InputStepProps) => {
    const {
        active,
        back,
        children,
        formErrorReporterValue,
        formErrorReporterSetError,
        preventBackNavigation,
        secondaryButtonOnClick,
        secondaryButtonLabel,
        primaryLabel,
        title,
        toolbarDisabled,
        submit,
        secondaryColor,
        ...contentProps
    } = props;
    const {handleSubmitWithOptions} = useContext(FormPropsContext);

    return (
        <div style={active ? undefined : {display: "none"}}>
            <FormErrorReporter
                value={formErrorReporterValue}
                setError={formErrorReporterSetError}
            >
                <StepContent title={title} {...contentProps} >
                    {children}
                </StepContent>
            </FormErrorReporter>
            <WizardToolbar
                back={!preventBackNavigation && back || undefined}
                primaryLabel={primaryLabel || (submit ? "cuda.buttons.save" : undefined)}
                primaryOnClick={handleSubmitWithOptions?.(submit || false)}
                secondaryLabel={secondaryButtonLabel}
                secondaryOnClick={secondaryButtonOnClick}
                secondaryColor={secondaryColor}
                toolbarDisabled={toolbarDisabled}
            />
        </div>
    );
};

export default InputStep;
import get from "lodash/get";
import React, {useEffect, useRef} from "react";
import Highcharts, {Chart, Options, SeriesBarOptions} from "highcharts";
import {Typography} from "@barracuda-internal/bds-core";
import {Theme, useTheme} from "@mui/material";
import {ClassKeyOfStyles, ClassNameMap, createStyles} from "@mui/styles";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {BaseFieldProps} from "../index";

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex"
    },
    percentage: {
        display: "inline-block",
        marginRight: theme.spacing(0.5),
        width: 28,
        paddingTop: 4,
        fontSize: 14,
        height: 16,
        lineHeight: "15px",
        color: theme.palette.text.secondary
    },
    chartContainer: {
        display: "inline-block",
        width: "calc(100% - " + (28 + parseInt(theme.spacing(0.5))) + "px)",
        height: 24
    },
    chart: {
        overflow: "visible !important",
        "& > svg": {
            overflow: "visible !important"
        }
    }
});

const useStyles = makeOverrideableStyles("PercentageBarChartField", styles);

export interface PercentageBarChartFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps {
    /**
     * @function to define a custom color scheme for the bar
     * @param {int} val, the value of the field.
     * @param {object} data, the full data provided
     * @param {object} theme, the current material-ui theme.
     * @returns {string} the colour to use for the bar
     */
    customColorScheme?: (value: any, data: any, theme: Theme) => string,
    /**
     * if true, will invert the colors of the red/green conditions.
     */
    invertColors?: boolean,
    /**
     * if tooltip or tooltipSource source not provided, this is used as part of the generated tooltip.
     */
    label?: string,
    /**
     * if tooltipSource is not provided or not an entry on the data, will be the tooltip of the bar chart.
     */
    tooltip?: string,
    /**
     * entry on the data structure to look for. This will be the tooltip of the bar chart.
     */
    tooltipSource?: string
}

const barChartConfig = (classes: ClassNameMap<ClassKeyOfStyles<typeof styles>>, theme: Theme, chartData: SeriesBarOptions["data"], props: PercentageBarChartFieldProps):
Options & {series: [SeriesBarOptions]} => ({
    chart: {
        type: "bar",
        height: 24,
        margin: [1, 3, 3, 2],
        borderWidth: 1,
        borderColor: theme.palette.divider,
        className: classes.chart
    },
    // @ts-ignore this is a valid option according to the docs.
    title: false,
    xAxis: {
        visible: false
    },
    // @ts-ignore these are valid options according to the docs.
    yAxis: {
        min: 0,
        max: 100,
        labels: false,
        title: false
    },
    // @ts-ignore this is a valid option according to the docs.
    legend: false,
    // @ts-ignore this is a valid option according to the docs.
    credits: false,
    series: [
        {
            type: "bar",
            groupPadding: 0,
            pointPadding: 0,
            minPointLength: 3,
            name: props.label ? props.label : props.source,
            data: chartData
        }
    ],
    tooltip: {
        outside: true,
        shared: true,
        formatter: function () {
            if (props.tooltip || props.tooltipSource) {
                return props.tooltipSource ? get(props.data, props.tooltipSource) : props.tooltip;
            }

            if (this.points && this.points.length > 0 && this.points[0].series) {
                return this.points[0].series.name + ": " + this.y + "%";
            }
            return "";
        }
    }
});

const defaultColorScheme = (val: any, data: any, theme: Theme, invertColors?: boolean) => {
    let color;

    if (val < 75) {
        color = invertColors ? theme.palette.error.main : theme.palette.success.main;
    } else if (val >= 75 && val < 90) {
        color = theme.palette.warning.main;
    } else {
        color = invertColors ? theme.palette.success.main : theme.palette.error.main;
    }
    return color;
};


/**
 * renders a Percentage bar chart field.
 * If the value to represents is lower than 75, bar color will be green.
 * If the value to represents is between 75 and 90, bar color will be orange.
 * Otherwise color will be red.
 * Negative values will leave and empty bar.
 * invertColors prop will invert the colors of the red/green conditions.
 */
export const PercentageBarChartField = (props: PercentageBarChartFieldProps) => {
    const {data, invertColors, label, source = "", tooltip, tooltipSource, customColorScheme} = props;
    const chart = useRef<Chart>();
    const divRef = useRef<HTMLDivElement>(null);
    const classes = useStyles(props);
    const theme = useTheme();
    const val = get(data, source) || 0;
    const color = !customColorScheme ? defaultColorScheme(val, data, theme, invertColors) : customColorScheme(val, data, theme);
    const chartData = [
        {
            name: label ? label : source,
            // eslint-disable-next-line id-length
            x: 1,
            // eslint-disable-next-line id-length
            y: val,
            color
        }
    ];
    const config = barChartConfig(classes, theme, chartData, props);


    useEffect(() => {
        if (chart.current) {
            chart.current.series[0].update(config.series[0], false);
            chart.current.update(config, false);
            chart.current.redraw();
        } else if (divRef.current) {
            chart.current = new Highcharts["Chart"](divRef.current, config);
        }
    });

    return (
        <div className={classes.root}>
            <Typography className={classes.percentage} component="div">
                {val + "%"}
            </Typography>
            <div className={classes.chartContainer} ref={divRef} />
        </div>
    );
};

export default PercentageBarChartField;
import {ConnectedForm, Tab, useMixpanel} from "@cuda-react/core";
import {get, set} from "lodash";
import React from "react";
import {formatSCErrors, validateNames, validateNetworks} from "../../../hooks/createEditSiteHooks";
import {useTranslation} from "react-i18next";
import BasicTab from "./tabs/common/BasicTab";
import WansTab from "./tabs/sc/WansTab";
import LansTab from "./tabs/sc/LansTab";
import DhcpTab from "./tabs/sc/DhcpTab";
import AdvancedSettingsTab from "./tabs/common/AdvancedSettingsTab";
import apiResources from "../../../apiResources";

export const SELECT_EMPTY_VALUE = "__SELECT_EMPTY_VALUE";

interface EditSiteContentProps {
    id: number | string;
    details: {
        model?: string;
        modelSeries?: "S" | "T";
        serials?: string[];
    };
}

const EditSiteContent: React.FC<EditSiteContentProps> = (props) => {
    const {id, details: {model, modelSeries, serials}} = props;
    const [translate] = useTranslation();
    const registerAction = useMixpanel(
        "Sites Settings",
        !!model,
        {model: model || "", type: modelSeries || ""}
    );
    return (
        <ConnectedForm
            resource={apiResources.siteScConfiguration}
            id={id}
            canReset
            tabbed
            topToolbar
            validate={(values: any = {}) => {
                // Verify lan/wan/routes port usage and names
                const errors: any = {};
                const usedNetworks = validateNetworks(values, "wans", undefined, translate, errors);
                validateNetworks(values, "lans", undefined, translate, errors, usedNetworks);
                const usedNetworkNames = validateNames(values, "wans", undefined, translate, errors);
                validateNames(values, "lans", undefined, translate, errors, usedNetworkNames);
                validateNames(values, "routes", undefined, translate, errors);
                // Verify secondary DNS is not set if there is no primary
                values.secondaryDns && !values.primaryDns && set(errors, "secondaryDns", translate("tesseract.appliances.dialog.validation.secondaryDns"));
                // Return errors
                return errors;
            }}
            formatResourceData={(data: any) => ({
                ...data,
                wans: get(data, "wans", []).sort((wanA: any) => wanA.provider === "fallback" ? 1 : -1),
                lans: get(data, "wifi.enabled") ? [
                    ...get(data, "lans", []),
                    get(data, "wifi")
                ] : get(data, "lans", [])
            })}
            formatRequestData={(data: any) => ({
                ...data,
                lans: get(data, "lans", []).filter((lan: any) => lan.port !== "wifi"),
                wifi: {enabled: true, ...(get(data, "lans", []).find((lan: any) => lan.port === "wifi") || {enabled: false})}
            })}
            formatError={formatSCErrors}
            onSubmitSuccess={(response: any) => {
                registerAction("Update", {
                    model: response.model,
                    type: "SC",
                    wansLength: response.wans.length,
                    lansLength: response.lans.length,
                    isHa: response.serials.length > 1
                });
            }}
        >
            <Tab label="tesseract.sites.settings.basic.tabTitle">
                <BasicTab id={id} noAddingSerials series="S" applianceType="site"/>
            </Tab>
            <Tab label="tesseract.sites.settings.wan.tabTitle">
                <WansTab id={id}/>
            </Tab>
            <Tab label="tesseract.sites.settings.lan.tabTitle">
                <LansTab id={id}/>
            </Tab>
            <Tab label="tesseract.sites.settings.dhcp.tabTitle">
                <DhcpTab/>
            </Tab>
            <Tab label="tesseract.sites.settings.advanced.tabTitle">
                <AdvancedSettingsTab series="S" applianceType="site" serials={serials}/>
            </Tab>
        </ConnectedForm>
    );
};

export default EditSiteContent;
import React from "react";
import {
    ConnectedForm,
    DialogBody,
    FileInput,
    PasswordInput,
    TextInput,
    useGlobalParam,
    validateMaxLengthMemo,
    validateRegexMemo,
} from "@cuda-react/core";
import {get} from "lodash";
import apiResources from "../../../../../../apiResources";

/**
 * For now we are using this workaround to get the karma tests working.
 */

interface CreateEditSslCertificatesProps {
    create?: boolean;
    id?: string | number;
    onClose?: () => void;
    onSuccess?: () => void;
    type: string;
}

const CreateEditSslCertificates: React.FC<CreateEditSslCertificatesProps> = ({create, id, type, onSuccess, ...props}) => {
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    return (
        <DialogBody
            {...props}
            form
            title={create ?
                "tesseract.security.sslInterception.settings.certificates.create.dialogTitle" :
                "tesseract.security.sslInterception.settings.certificates.edit.dialogTitle"
            }
        >
            <ConnectedForm
                resource={type === "interception" ? apiResources.sslCertificates : apiResources.sslCertificateAuthorities}
                // @ts-ignore
                form={"sslCertificates-" + type}
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={() => {
                    onSuccess && onSuccess();
                    // @ts-ignore
                    props.onClose();
                }}
                onCancel={props.onClose}
                formatRequestData={({contents, ...data}) => ({
                    ...data,
                    type,
                    contents: get(contents, "data"),
                    virtualWanId: data.virtualWanId || virtualWanId
                })}
                allowDirtyNavigation
            >
                <TextInput
                    source="name"
                    label="Name"
                    description="tesseract.security.sslInterception.settings.descriptions.certificateName"
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.security.sslInterception.settings.description"
                    description="tesseract.security.sslInterception.settings.descriptions.certificateDescription"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                />
                {create ? (
                    <FileInput
                        source="contents"
                        label={type === "interception" ? "tesseract.security.sslInterception.settings.uploadP12" : "tesseract.security.sslInterception.settings.uploadPEM"}
                        description={type === "interception" ? "tesseract.security.sslInterception.settings.descriptions.uploadP12" : "tesseract.security.sslInterception.settings.descriptions.uploadPEM"}
                        isRequired
                        accept="*"
                        readType={type === "interception" ? "base64" : "string"}
                    />
                ) : null}
                {type === "interception" && create ? (
                    <PasswordInput
                        source="passphrase"
                        label="tesseract.security.sslInterception.settings.passphrase"
                        description="tesseract.security.sslInterception.settings.descriptions.passphrase"
                    />
                ) : null}
            </ConnectedForm>
        </DialogBody>
    );
};


export default CreateEditSslCertificates;
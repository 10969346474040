import React, {useCallback, useState} from "react";
import {
    CrudTypes,
    domainRegex,
    Form,
    RadioButtonGroupInput,
    SearchableSelectInput,
    TextInput,
    useCrudFetch,
    useGlobalParam,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import WizardPage, {WizardPageProps} from "../WizardPage";
import apiResources from "../../../apiResources";
import {Trans, useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {SavedFormData} from "../SetupWizard";
import {makeStyles} from "@mui/styles";
import {categoryIcons} from "../../../components/iconMapping";

interface WizardStep3Props extends Pick<WizardPageProps, "wizardStates" | "registerStepAction"> {
    onSuccess?: () => void,
    updateSavedFormData: (values: SavedFormData) => void
}

const useStyles = makeStyles({
    inputLabel: {
        width: 0
    },
    subtitle: {
        fontSize: 16,
        fontWeight: "bold",
        marginTop: 16,
        marginBottom: 16
    },
    form: {
        height: "100%"
    }
});

const WizardStep3 = (props: WizardStep3Props) => {
    const [translate] = useTranslation();
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [, savingAppCatalog, crudSubmitAppCatalog] = useCrudFetch(CrudTypes.CREATE, apiResources.appCatalog, {});
    const [, savingCreateCustomApplication, crudCreateCustomApplication] = useCrudFetch(CrudTypes.CREATE, apiResources.customApps, {});
    const [isPredefinedApplicationSelected, setIsPredefinedApplicationSelected] = useState(true);
    const classes = useStyles();

    const extractProtocolAndDomainName = (url: string) => {
        const splitUrl = url.match(/^https?:\/\/([^\/]+)/);
        return {isValidProtocol: splitUrl?.[0], domainName: splitUrl?.[1]};
    };

    const registerSubmitAction = (values: any) => {
        props.registerStepAction("Submit", {
            applicationType: values.applicationType
        });
    };
    const registerFailAction = (error: any) => {
        props.registerStepAction("Fail", {error: JSON.stringify(error)});
    };

    const savePredefinedApplication = useCallback((values) => {
        registerSubmitAction(values);
        return crudSubmitAppCatalog(
            {
                data: {
                    name: values.name,
                    url: values.url,
                    visibility: "all",
                    icon: null,
                    iconFilename: null,
                    virtualWanId
                }
            },
            {formPromise: true}
        ).then((response: any) => {
            if (!response?.error) {
                props.updateSavedFormData({applicationId: values.application});
                props.onSuccess?.();
            }
            return response?.data;
        }).catch((error: any) => {
            registerFailAction(error);
            throw error;
        });
    }, [crudSubmitAppCatalog, props.onSuccess, props.updateSavedFormData, virtualWanId]);

    const saveCustomApplication = useCallback((values: any) => {
        registerSubmitAction(values);
        return crudCreateCustomApplication(
            {
                data: {
                    virtualWanId,
                    categoryId: "webtraffic",
                    type: "web",
                    destinations: [{destination: extractProtocolAndDomainName(values.url).domainName}],
                    name: values.name
                }
            },
            {formPromise: true}
        ).then((response1: { data?: { applicationId: string }, error?: any }) => {
            if (!response1?.error) {
                return crudSubmitAppCatalog(
                    {
                        data: {
                            name: values.name,
                            url: values.url,
                            visibility: "all",
                            icon: null,
                            iconFilename: null,
                            virtualWanId
                        }
                    },
                    {formPromise: true}
                ).then((response2: any) => {
                    if (!response2?.error) {
                        props.updateSavedFormData({applicationId: response1?.data?.applicationId});
                        props.onSuccess?.();
                    }
                    return response2?.data;
                });
            }
            return response1?.data;
        }).catch((error: any) => {
            registerFailAction(error);
            throw {...error, url: error?.url ?? error?.destinations?.[0]?.destination};
        });
    }, [crudCreateCustomApplication, crudSubmitAppCatalog, props.onSuccess, props.updateSavedFormData, virtualWanId]);

    return (
        <Form
            save={(formValues: any) => formValues.applicationType === "predefined" ? savePredefinedApplication(formValues) : saveCustomApplication(formValues)}
            disabled={savingAppCatalog || savingCreateCustomApplication}
            noToolbar
            flat
            onChange={(formValues: any) => setIsPredefinedApplicationSelected(formValues?.applicationType === "predefined")}
            // @ts-ignore prop does exist
            classes={{form: classes.form}}
        >
            <WizardPage
                textHeader="tesseract.setupWizard.wizardStep3.textHeader"
                textSubHeader="tesseract.setupWizard.wizardStep3.textSubHeader"
                activeStep={2}
                {...props}
            >
                <React.Fragment>
                    <Typography className={classes.subtitle}>
                        <Trans i18nKey="tesseract.setupWizard.wizardStep3.applicationTypeSection"/>
                    </Typography>
                    <RadioButtonGroupInput
                        source="applicationType"
                        choices={[
                            {key: "predefined", name: "tesseract.setupWizard.wizardStep3.radioButtonInput1"},
                            {key: "custom", name: "tesseract.setupWizard.wizardStep3.radioButtonInput2"}
                        ]}
                        inputLabelProps={{
                            classes: {inputLabel: classes.inputLabel}
                        }}
                    />
                    <Typography>
                        <Trans i18nKey="tesseract.setupWizard.wizardStep3.helpText">
                            <a href="https://campus.barracuda.com/doc/98225730/" rel='noopener noreferrer'
                               target='_blank'/>
                        </Trans>
                    </Typography>
                    {isPredefinedApplicationSelected ?
                        <React.Fragment>
                            <Typography className={classes.subtitle}>
                                <Trans i18nKey="tesseract.setupWizard.wizardStep3.applicationSection"/>
                            </Typography>
                            <SearchableSelectInput
                                source="application"
                                label="tesseract.setupWizard.wizardStep3.applicationDropdown"
                                resource={apiResources.zeroTrustApplications}
                                optionValue="id"
                                filterKey="applicationId"
                                options={{dropdownAutoWidth: true}}
                                iconMap={categoryIcons}
                                optionIcon="categoryId"
                                newStyle
                                isRequired
                            />
                        </React.Fragment> : null}
                    <Typography className={classes.subtitle}>
                        <Trans i18nKey="tesseract.setupWizard.wizardStep3.appCatalogSection"/>
                    </Typography>
                    <Typography>
                        <Trans i18nKey="tesseract.setupWizard.wizardStep3.helpTextAppCatalogSection"/>
                    </Typography>
                    <TextInput
                        source="name"
                        label="tesseract.setupWizard.wizardStep3.displayName"
                        validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                        isRequired
                        newStyle
                    />
                    <TextInput
                        source="url"
                        label="tesseract.setupWizard.wizardStep3.launchUrl"
                        validate={[validateMaxLengthMemo(255),
                            (value: string, data: any) => {
                                if (data?.applicationType === "custom") {
                                    const {isValidProtocol, domainName} = extractProtocolAndDomainName(value);
                                    if (!isValidProtocol) {
                                        return translate("tesseract.setupWizard.wizardStep3.protocolValidation", {});
                                    }
                                    if (domainName && !domainRegex.test(domainName))
                                        return translate("tesseract.setupWizard.wizardStep3.domainValidation", {});
                                } else {
                                    return undefined;
                                }
                            }
                        ]}
                        isRequired
                        newStyle
                    />
                </React.Fragment>
            </WizardPage>
        </Form>
    );
};


export default WizardStep3;
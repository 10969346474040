import React from 'react';
import {generateQueryString, LinkField, StatusIconField, TextField} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import {siteGatewayStatusIcons} from "../../../../../components/iconMapping";
import {StatisticsTable} from "@stratos/reporting";

interface ActiveTunnelsTableProps {
    preview?: boolean
}

export const ActiveTunnelsTable: React.FC<ActiveTunnelsTableProps> = ({preview}) => (
    <StatisticsTable
        tableTitle="tesseract.dashboard.cards.vpn.offline.tabTitle"
        resource={preview ? undefined : apiResources.VPNTunnelOffline}
        noDataMessage="tesseract.dashboard.cards.vpn.offline.noData"
    >
        <StatusIconField
            source="gateway.status"
            label="tesseract.dashboard.cards.vpn.offline.gatewayStatus"
            iconMap={siteGatewayStatusIcons}
            width={96}
        />
        <LinkField
            label="tesseract.dashboard.cards.vpn.offline.gatewayName"
            source="gateway.name"
            link={(data) => `/infrastructure/gateways${generateQueryString({
                name: [data.gateway.name],
                connectionStatus: [data.gateway.status]
            })}`}
        />
        <StatusIconField
            source="status"
            label="tesseract.dashboard.cards.vpn.offline.siteStatus"
            iconMap={siteGatewayStatusIcons}
            width={96}
        />
        <LinkField
            label="tesseract.dashboard.cards.vpn.offline.siteName"
            source="name"
            link={(data) => `/infrastructure/${data.model?.startsWith("F") ? "iot" : "sites"}/${data.id}`}
        />
        <TextField label="tesseract.dashboard.cards.vpn.offline.siteLocation" source="summary"/>
    </StatisticsTable>
);

export default ActiveTunnelsTable;
import {Point} from "highcharts";
import {get} from "lodash";
import {ChartRef, LocationMapSeriesDefinition} from "./UseLocationsMap";

export type CustomMapPoint =
    { source?: LocationMapSeriesDefinition, pointData?: LocationMapSeriesDefinition }
    & Partial<Point>;

/**
 * Returns all points that are linked to the provided point.
 */
export const getLinkedPoints = (chart: ChartRef, point: CustomMapPoint): CustomMapPoint[] => {
    // If the point is a child point, check for its parent and return it's .
    if (get(point.pointData, "linkedSeriesParent")) {
        return [{
            ...chart.current?.fromLatLonToPoint({
                lat: get(point.pointData, "linkedSeriesParent.latitude") as any,
                lon: get(point.pointData, "linkedSeriesParent.longitude") as any
            }) as CustomMapPoint,
            source: get(point.pointData, "linkedSeriesParent")
        }];
    }

    // If the point is a parent, get the linked points from its sources and convert them to x/y positions
    if (get(point.pointData, "linkedSeriesSources")) {
        const linkedPointData = (get(point.pointData, "linkedSeriesSources") as any).flatMap((source: string) => get(point.pointData, source)).filter((val: {
            latitude: number,
            longitude: number
        }) => val);
        return linkedPointData.map((point: { latitude: number, longitude: number }) => ({
            ...chart.current?.fromLatLonToPoint({
                lat: point.latitude,
                lon: point.longitude
            }),
            source: point
        }));
    }

    return [];
};
import React from "react";
import BasicPage, {BasicPageProps} from "../BasicPage/BasicPage";
import SideTabs, {SideTabsProps} from "../../tabs/SideTabs/SideTabs";

export interface TabbedPageProps extends Omit<BasicPageProps, "children">, SideTabsProps {}

/**
 * Creates a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs) tab layout inside a
 * [BasicPage](/?path=/docs/core-components-pages-basicpage--basic-page), perfect for nesting related pages.
 *
 * All provided props are passed through to [BasicPage](/?path=/docs/core-components-pages-basicpage--basic-page) and
 * [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs) components.
 */
export const TabbedPage = ({children, ...props}: TabbedPageProps) => (
    <BasicPage {...props} >
        <SideTabs {...props} >
            {children}
        </SideTabs>
    </BasicPage>
);

export default TabbedPage;
import React, {useRef, useState} from "react";
import {
    ActionButtonsField,
    Boolean,
    ChipArrayField,
    CrudTypes,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    getArrayDataContent,
    getDataContent,
    InputLabel,
    TableAction,
    TablePage,
    TextField,
    useCrudFetch,
    useCrudProps,
    useCrudSubscription,
    useMixpanel,
    useTableChoices
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../apiResources";
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import CreatePolicy from "./createEdit/CreatePolicy";
import EditPolicy from "./createEdit/EditPolicy";
import {GetStartedBackgroundGraphic, IpsGraphic} from "@cuda-react/icons";
import {Dialog} from "@barracuda-internal/bds-core";
import {categoryIcons} from "../../../../components/iconMapping";

export const useStyles = makeStyles(() => ({
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    },
    lastMileOptimization: {
        display: "inline-flex"
    },
}));

const ZeroTrustAccess = () => {
    const registerAction = useMixpanel("Zero Trust Access");
    const classes = useStyles();
    const tableRefreshRef = useRef<any>();
    const [, , updateOrder] = useCrudFetch(CrudTypes.CREATE, apiResources.zeroTrustPolicies);
    const handleOrderClick = (id: any, direction: string, data: any) => updateOrder({
        id,
        changeOrder: direction,
        data
    }).then(tableRefreshRef.current);
    const securityPostureChoices = useCrudProps(apiResources.zeroTrustPoliciesSecurityPosture)[0]?.data;
    const [renderApplication, getApplicationIconId] = useTableChoices(apiResources.applications, {
        optionValue: "id",
        filterId: "applicationId"
    }, "categoryId");
    const renderGroups = useTableChoices(apiResources.groups, {optionValue: "id", filterId: "id"});
    const renderUsers = useTableChoices(apiResources.users, {optionValue: "id", filterId: "id"});
    const [settingsData, settingsLoading, refreshSettings] = useCrudSubscription(CrudTypes.GET, apiResources.zeroTrustSettings);
    const [, settingsUpdating, updateSettings] = useCrudFetch(CrudTypes.UPDATE, apiResources.zeroTrustSettings);
    const stringFecLevel = getDataContent(settingsData)?.fecLevel;
    const fecLevel = stringFecLevel === "medium";
    const loadingSettings = typeof stringFecLevel !== "string" || settingsLoading || settingsUpdating;
    const handleUpdateFecLevel = (newValue: any) => {
        if (!loadingSettings && newValue !== fecLevel) {
            registerAction("Last Mile Optimization Update", {fecLevel: newValue ? "medium" : "off"});
            return updateSettings({
                data: {
                    ...getDataContent(settingsData),
                    fecLevel: newValue ? "medium" : "off"
                }
            }).then(refreshSettings);
        }
    };
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);


    return (
        <>
            <TablePage
                title="tesseract.securityAndAccess.pageTitle"
                subtitle={["tesseract.endpoint.pageTitle", "tesseract.endpoint.remoteAccessPolicies.zeroTrustAccess"]}
                resource={apiResources.zeroTrustPolicies}
                minCellWidth={128}
                compact
                refreshRef={tableRefreshRef}
                exportResource={{resource: apiResources.zeroTrustPoliciesExport, filename: "zeroTrustPolicies"}}
                titleActions={actionVisible &&
                    <DisableableButton
                        key="create"
                        buttonText="tesseract.endpoint.remoteAccessPolicies.createButton"
                        onClick={() => setCreateOpen(true)}
                    />
                }
                actions={
                    <TableAction key="lastMileOptimization" left>
                        <div className={classes.lastMileOptimization}>
                            <InputLabel label="tesseract.endpoint.remoteAccessPolicies.lastMileOptimization" minimised>
                                <Boolean
                                    id="lastMileOptimization"
                                    value={fecLevel}
                                    onChange={handleUpdateFecLevel}
                                    options={{disabled: !!loadingSettings}}
                                />
                            </InputLabel>
                        </div>
                    </TableAction>
                }
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<IpsGraphic/>}
                        iconHeader="tesseract.endpoint.remoteAccessPolicies.noData.iconHeader"
                        text="tesseract.endpoint.remoteAccessPolicies.noData.text"
                        actions={
                            <DisableableButton
                                key="create"
                                buttonText="tesseract.endpoint.remoteAccessPolicies.noData.labelButton"
                                onClick={() => setCreateOpen(true)}
                            />
                        }
                    />
                }
                onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
            >
                <ActionButtonsField
                    left
                    label="tesseract.endpoint.remoteAccessPolicies.order"
                    cellClassName={classes.orderCell}
                    width={96}
                    source="id"
                >
                    <TextField
                        source="order"
                    />
                    {/**@ts-ignore not sure why TS doesn't like this component*/}
                    <ArrowUpwardIcon
                        onClick={(event: any, data: { id: any; }) => handleOrderClick(data.id, "increase", data)}
                        disabled={(data: { index: number; }) => data.index <= 0}
                        id="cuda-icon-up"
                    />
                    {/**@ts-ignore not sure why TS doesn't like this component*/}
                    <ArrowDownwardIcon
                        onClick={(event: any, data: { id: any; }) => handleOrderClick(data.id, "decrease", data)}
                        disabled={(data: { index: number; total: number; }) => data.index >= (data.total - 1)}
                        id="cuda-icon-down"
                    />
                </ActionButtonsField>
                <TextField
                    source="name"
                    label="tesseract.endpoint.remoteAccessPolicies.name"
                    filter="text"
                />
                <TextField
                    source="description"
                    label="tesseract.endpoint.remoteAccessPolicies.description"
                    filter="text"
                />
                <ChipArrayField
                    source="accessCriteria.users"
                    label="tesseract.endpoint.remoteAccessPolicies.users"
                    filter="selectarray"
                    filterProps={{
                        resource: apiResources.users,
                        optionValue: "id"
                    }}
                    // @ts-ignore
                    render={renderUsers}
                    columnProps={{
                        field: "user"
                    }}
                />
                <ChipArrayField
                    source="accessCriteria.groups"
                    label="tesseract.endpoint.remoteAccessPolicies.groups"
                    filter="selectarray"
                    filterProps={{
                        resource: apiResources.groups,
                        optionValue: "id"
                    }}
                    // @ts-ignore
                    render={renderGroups}
                    columnProps={{
                        field: "group"
                    }}
                />
                <ChipArrayField
                    source="resourceCriteria.applications"
                    label="tesseract.endpoint.remoteAccessPolicies.resources"
                    filter="selectarray"
                    filterProps={{
                        resource: apiResources.applications,
                        optionValue: "id",
                        optionIcon: "categoryId",
                        iconMap: categoryIcons
                    }}
                    render={renderApplication}
                    iconMap={categoryIcons}
                    optionIcon={getApplicationIconId}
                    columnProps={{
                        field: "application"
                    }}
                />
                <ChipArrayField
                    source="accessCriteria.securityPosture"
                    label="tesseract.endpoint.remoteAccessPolicies.devicePosture"
                    filter="selectarray"
                    choices={getArrayDataContent(securityPostureChoices)}
                    filterProps={{
                        id: "security-posture",
                        choices: getArrayDataContent(securityPostureChoices)
                    }}
                    columnProps={{
                        field: "securityPosture"
                    }}
                />
                <ActionButtonsField
                    width={80}
                    source="id"
                >
                    <CreateEditButtonDialog
                        component={EditPolicy}
                        onSuccess={() => {
                            registerAction("Update");
                            tableRefreshRef.current?.();
                        }}
                    />
                    <DeleteDialog
                        resource={apiResources.zeroTrustPolicies}
                        title={"tesseract.endpoint.remoteAccessPolicies.delete.title"}
                        message={"tesseract.endpoint.remoteAccessPolicies.delete.body"}
                        onSuccess={() => {
                            registerAction("Delete");
                            tableRefreshRef.current?.();
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                disableEnforceFocus
                onClose={() => setCreateOpen(false)}
            >
                <CreatePolicy
                    onSuccess={() => {
                        registerAction("Create");
                        tableRefreshRef.current?.();
                    }}
                    onClose={() => setCreateOpen(false)}
                />
            </Dialog>
        </>
    );
};

export default ZeroTrustAccess;
/**
 * Returns the correct success/warning/error/info theme color according to the provided "status".
 *
 * @function
 * @param {string|boolean} status the status value.
 * @param {object} theme the material-ui theme.
 * @returns {color} the html color matching the provided status.
 */
import {Theme} from "@mui/material";

/**
 * Extracts the corresponding colour for a given status from the MUI theme
 * @param status the current status value.
 * @param theme a material-ui theme.
 * @return color the matching css color
 */
export const getStatusColorFromTheme = (status: "ok" | "warning" | "error" | "errorWarning" | string | boolean, theme: Theme): string => {
    switch (status) {
        case true:
        case "ok":
            return theme.palette.success.main;
        case "warning":
            return theme.palette.warning.main;
        case false:
        case "error":
        case "errorWarning":
            return theme.palette.error.main;
        default:
            return theme.palette.info.main;
    }
};
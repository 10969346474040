import React from "react";
import {Trans, useTranslation} from "react-i18next";
import useSubscriptionValidation, {STATE} from "../../hooks/useSubscriptionValidation";
import WholePageLayout from "../../components/subscription/WholePageLayout";

export const SubscriptionPage =  () => {
    const [translate] = useTranslation();
    const {status, continueSubscribing, availableAccounts, onAccountChange, subscriptionValid, currentAccount} = useSubscriptionValidation();
    let message = "stratos.subscription.body";
    let listKey;

    if (status === STATE.ERROR) {
        message = "stratos.subscription.error";
        listKey = "stratos.subscription.purchaseOrContactCustomerServices";
    } else if (status === STATE.VALIDATE_SUCCEEDED) {
        message = availableAccounts.length === 1 ? "stratos.subscription.existingSubscription" :
            translate("stratos.subscription.currentAccount", {currentAccount, context: subscriptionValid ? "valid" : undefined});
        listKey = availableAccounts.length === 1 ? "stratos.subscription.continueOrLogout" :
            "stratos.subscription.continueOrSwap" + (subscriptionValid ? "Valid" : "");
    }

    return (
        <WholePageLayout
            title="stratos.subscription.title"
            message={message}
            list={listKey ? (
                <Trans
                    i18nKey={listKey}
                >
                    <ul>
                        <li />
                        <li>
                            <a
                                href="https://www.barracuda.com/support/index"
                                rel="noreferrer noopener"
                                target="_blank"
                            />
                        </li>
                        <li />
                    </ul>
                </Trans>
            ) : undefined}
            logout={[STATE.ERROR, STATE.VALIDATE_SUCCEEDED].includes(status)}
            selectAccount={[STATE.ERROR, STATE.VALIDATE_SUCCEEDED].includes(status) && availableAccounts.length > 1}
            subscribe={[STATE.ERROR].includes(status)}
            onContinue={status === STATE.VALIDATE_SUCCEEDED ? continueSubscribing : undefined}
            onAccountChange={onAccountChange}
            loading={![STATE.ERROR, STATE.VALIDATE_SUCCEEDED].includes(status)}
        />
    );
};

export default SubscriptionPage;
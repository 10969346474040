import {Card, ConnectedForm, TextArrayInput, useMixpanel, validateArray, validateCIDR} from "@cuda-react/core";
import {get} from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {parseJson} from "../../../../utils/jsonUtils";

const useStyles = makeStyles((theme) => ({
    typography: {
        margin: theme.spacing(2)
    }
}));
const ReportCreatorAcl = () => {
    const registerAction = useMixpanel("Network Settings Report Creator", false);
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <Card title="tesseract.settings.tabs.reportCreatorAcl.title">
            <ConnectedForm
                resource={apiResources.settings}
                formatRequestData={(data) => ({
                    settings: {
                        report_generator: JSON.stringify(data)
                    }
                })}
                formatResourceData={(data) => parseJson(get(data, "settings.report_generator"))}
                formatError={(errors) => get(errors, "settings.report_generator")}
                params={{filter: {type: "virtualWan"}}}
                onSubmitSuccess={() => {
                    registerAction("Update");
                }}
            >
                <Typography className={classes.typography}>
                    {translate("tesseract.settings.tabs.reportCreatorAcl.description")}
                </Typography>
                <TextArrayInput
                    source="acls"
                    label="tesseract.settings.tabs.reportCreatorAcl.subMenu"
                    // @ts-ignore
                    validate={validateArray[validateCIDR]}
                />
            </ConnectedForm>
        </Card>
    );
};

export default ReportCreatorAcl;
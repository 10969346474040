import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const OverrideIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-override"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.error?.main, strokeWidth: 0}}
                  d="M30,5.5c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5,24.5-11,24.5-24.5S43.5,5.5,30,5.5ZM41,36.6l-3.5,3.5-7.1-7.1-7.1,7.1-3.5-3.5,7.1-7.1-7.1-7.1,3.5-3.5,7.1,7.1,7.1-7.1,3.5,3.5-7.1,7.1,7.1,7.1Z"/>
        </SvgIcon>
    );
};

export default OverrideIcon;
import React, {useState} from "react";
import {
    ConnectedForm,
    CrudTypes,
    DialogBody,
    FormSection,
    SearchableSelectInput,
    SelectInput,
    TextInput,
    useCrudSubscription,
    useGlobalParam,
    useMixpanel,
    validateMaxLengthMemo,
    validateMaxValueMemo,
    validateMinValueMemo,
    validateRegexMemo
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {formatRequestCriteria, formatResourceCriteria} from "../../../../../components/sourceAndDestination/formUtils";
import {useGeneratedSourceCriteriaSection} from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";
import generateDestinationCriteriaSection
    from "../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles<any>({});

interface CreateEditIngressRuleProps {
    create?: boolean,
    id?: string | number,
    onClose?: () => void,
    onSuccess?: () => void,
    defaultScope?: string | number,
    type?: string
}

const CreateEditIngressRule: React.FC<CreateEditIngressRuleProps> = (props) => {
    const {create, id, onSuccess, defaultScope, type, ...dialogProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [currentApplianceType, setCurrentApplianceType] = useState<any>(false);
    const [currentApplianceId, setCurrentApplianceId] = useState<any>(false);
    const wanApplianceChoices = useCrudSubscription(CrudTypes.GET, currentApplianceType && currentApplianceId && (currentApplianceType.includes('ingressSite') ? apiResources.supportedSiteWans : apiResources.supportedGatewayWans), {filter: {id: currentApplianceId}})[0]?.data?.content;
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const registerAction = useMixpanel("Ingress NAT Rules", false);
    const validatePatPort = [validateMinValueMemo(1), validateMaxValueMemo(65000)];
    const [selectedWanKey, setSelectedWanKey] = useState<any>(undefined);
    const selectedWan = wanApplianceChoices?.[0]?.wans?.find((wan: any) => wan.key === selectedWanKey && wan.address);
    const wanIpChoices = selectedWan ? [
        {key: selectedWan?.address, name: selectedWan?.address, type: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.source.primaryAddress")},
        ...(selectedWan?.addresses || []).map((ip: string) => ({key: ip, name: ip, type: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.source.additionalAddresses")}))
    ] : [];

    return (
        <DialogBody
            {...dialogProps}
            form
            title={`tesseract.security.ingressNatRules.settings.${create ? "create" : "edit"}.dialogTitle`}
            classes={{dialogContent: classes.dialogContent}}
        >
            <ConnectedForm
                resource={apiResources.ingressNatRules}
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={(response) => {
                    onSuccess?.();
                    // @ts-ignore
                    dialogProps.onClose();
                    registerAction(create ? "Create" : "Update", {
                        action: response.action,
                        sourceType: response.source?.type
                    });
                }}
                onCancel={dialogProps.onClose}
                onChange={(newValues) => {
                    if (newValues.destination?.type && newValues.destination?.type !== currentApplianceType) {
                        setCurrentApplianceType(newValues.destination?.type);
                        newValues.destination?.applianceId !== currentApplianceId ?
                            setCurrentApplianceId(newValues.destination?.applianceId) :
                            setCurrentApplianceId(false);
                    } else {
                        setCurrentApplianceId(newValues.destination?.applianceId);
                    }
                    if(newValues.destination?.wan !== selectedWanKey) {
                        setSelectedWanKey(newValues.destination?.wan);
                    }
                }}
                formatRequestData={formatRequestCriteria(virtualWanId)}
                formatResourceData={formatResourceCriteria}
                initialValues={create ? {
                    destination: {applianceId: defaultScope, type},
                    loggingEnabled: true
                } : undefined}
                allowDirtyNavigation
            >
                <TextInput
                    source="name"
                    label="tesseract.security.ingressNatRules.settings.name"
                    description="tesseract.security.ingressNatRules.settings.descriptions.name"
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.security.ingressNatRules.settings.description"
                    description="tesseract.security.ingressNatRules.settings.descriptions.description"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                />
                {useGeneratedSourceCriteriaSection(["internet", "networks"])}
                {generateDestinationCriteriaSection(["onPremGateway", "ingressSite"])}
                <SelectInput
                    source="destination.wan"
                    label="tesseract.security.ingressNatRules.settings.wan"
                    description="tesseract.security.ingressNatRules.settings.descriptions.wan"
                    choices={wanApplianceChoices?.[0].wans}
                    isRequired
                />
                <SelectInput
                    label="tesseract.security.ingressNatRules.settings.IPAddress"
                    source="destination.address"
                    choices={wanIpChoices}
                    optionCategory={wanIpChoices[0]?.type ? "type" : undefined}
                />
                <TextInput
                    label="PAT Public Port"
                    description="tesseract.security.ingressNatRules.settings.descriptions.patPublicPort"
                    source="destination.port"
                    type="integer"
                    validate={validatePatPort}
                    options={{
                        type: "number",
                        InputProps: {
                            inputProps: {
                                min: 1,
                                step: 1
                            }
                        }
                    }}
                />
                <FormSection title="tesseract.security.targetCriteria.section"
                    // @ts-ignore
                             collapsable>
                    <SearchableSelectInput
                        source="target"
                        label="tesseract.security.ingressNatRules.settings.targetApplicationResource"
                        description="tesseract.security.ingressNatRules.settings.descriptions.target"
                        resource={apiResources.validTargets}
                        clearOnFocus
                        isRequired
                    />
                </FormSection>
            </ConnectedForm>
        </DialogBody>
    );
};


export default CreateEditIngressRule;
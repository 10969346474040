import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {BooleanInput, HiddenInput, TextInput} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {useWatch} from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    message: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export const SelectWanFallbackStep = () => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const inputValidations = useSiteInputValidations();
    const wanFailover = useWatch({name: "wanFailover"});

    return (
        <React.Fragment>
            <Typography className={classes.message} variant="body1">
                {translate("tesseract.sites.dialog.wans.SCmessage", {context: "failover"})}
            </Typography>
            <Typography className={classes.message} variant="body1">
                {translate("tesseract.sites.dialog.wans.SCconfigureFailover")}
            </Typography>
            <BooleanInput
                label="tesseract.sites.dialog.wans.failover"
                source="wanFailover"
                isRequired
            />
            <HiddenInput
                source="wanTotal"
                hiddenValue={wanFailover ? "2" : "1"}
            />
            <TextInput
                label="tesseract.sites.dialog.wans.size"
                source="wanTotal"
                isRequired
                disabled
                validate={inputValidations.validateSCWansLength}
                type="integer"
                options={{
                    type: "number",
                    InputProps: {
                        inputProps: {
                            min: 1,
                            max: 2,
                            step: 1
                        }
                    }
                }}
            />
        </React.Fragment>
    );
};

export default SelectWanFallbackStep;
import {
    Card,
    ChipArrayField,
    ConnectedForm,
    TableInput,
    TextArrayInput,
    TextField,
    TextInput,
    useMixpanel,
    validateArrayMemo,
    validateDomain,
    validateIpv4
} from "@cuda-react/core";
import {get} from "lodash";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import {makeStyles} from "@mui/styles";
import {parseJson} from "../../../../utils/jsonUtils";

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(-6)
    }
}));

const ForwardedDomains = () => {
    const [translate] = useTranslation();
    const [existingDomains, setExistingDomains] = useState<any>([]);
    const classes = useStyles();
    const registerAction = useMixpanel("Network Settings Forwarded Domains", false);

    return (
        <Card title="tesseract.settings.tabs.forwardedDomains.title">
            <ConnectedForm
                resource={apiResources.settings}
                formatRequestData={(data) => ({
                    settings: {
                        dns_forwarding_zone: JSON.stringify(get(data, "settings.dns_forwarding_zone", {}))
                    }
                })}
                formatResourceData={(data) => ({
                    settings: {
                        dns_forwarding_zone: parseJson(get(data, "settings.dns_forwarding_zone"))
                    }
                })}
                params={{filter: {type: "virtualWan"}}}
                onChange={(newValues) => {
                    setExistingDomains(get(newValues, "settings.dns_forwarding_zone.zones", []).map((zone: { domain: any; }) => zone.domain));
                }}
                // @ts-ignore as type definition already there
                classes={{form: classes.form}}
                onSubmitSuccess={() => {
                    registerAction("Update");
                }}
            >
                <TableInput
                    source="settings.dns_forwarding_zone.zones"
                    optionValue="domain"
                    formValidate={(values, initialValues) => {
                        const domain = get(values, "domain");
                        if (domain && domain !== get(initialValues, "domain") && existingDomains.includes(domain)) {
                            return {domain: translate("tesseract.settings.tabs.forwardedDomains.duplicateName")};
                        }
                    }}
                    labels={{
                        addTitle: "tesseract.settings.tabs.forwardedDomains.addDomain",
                        addButtonText: "tesseract.settings.tabs.forwardedDomains.addDomain",
                        editTitle: "tesseract.settings.tabs.forwardedDomains.editDomain",
                        deleteTitle: "tesseract.settings.tabs.forwardedDomains.deleteDomain"
                    }}
                >
                    <TextField
                        source="domain"
                        label="tesseract.settings.tabs.forwardedDomains.domain"
                        inputComponent={TextInput}
                        inputProps={{isRequired: true, validate: [validateDomain]}}
                        width={296}
                    />
                    <ChipArrayField
                        source="forwarders"
                        label="tesseract.settings.tabs.forwardedDomains.forwarders"
                        inputComponent={TextArrayInput}
                        // @ts-ignore
                        inputProps={{validate: [validateArrayMemo(validateIpv4)], isRequired: true}}
                    />
                </TableInput>
            </ConnectedForm>
        </Card>
    );
};

export default ForwardedDomains;
import React from "react";
import {DashboardCardProps, ResourceStatisticsCard, useFetchStatisticsCardDetailsAndTotal} from "@stratos/reporting";
import apiResources from "../../../../apiResources";
import {getStatusColorFromTheme} from "../../../../utils/themeUtils";
import {Theme, useTheme} from "@mui/material";
import {ActionButtonsField, ChipArrayField, generateQueryString, StatusIconField, TextField} from "@cuda-react/core";
import {Forward} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {useHistory} from "react-router";
import {StatisticsDetails} from "@stratos/reporting/lib/hooks/useFetchStatisticsCardDetailsAndTotal";

const previewData = (theme: Theme) => [
    [
        {
            "title": "tesseract.dashboard.cards.iotStatusCard.healthy",
            "value": 3,
            "color": getStatusColorFromTheme("online", theme)
        },
        {
            "title": "tesseract.dashboard.cards.iotStatusCard.pending",
            "value": 0,
            "color": getStatusColorFromTheme("pending", theme)
        },
        {
            "title": "tesseract.dashboard.cards.iotStatusCard.connecting",
            "value": 4,
            "color": getStatusColorFromTheme("connecting", theme)
        },
        {
            "title": "tesseract.dashboard.cards.iotStatusCard.degraded",
            "value": 5,
            "color": getStatusColorFromTheme("degraded", theme)
        },
        {
            "title": "tesseract.dashboard.cards.iotStatusCard.offline",
            "value": 4,
            "color": getStatusColorFromTheme("offline", theme)
        }
    ],
    16
] as [StatisticsDetails[], number];
const filterDegraded = (details: any) => !(details.link || details.title).includes("degraded");

export interface IotStatusCardProps extends DashboardCardProps {}

const IotStatusCard: React.FC<IotStatusCardProps> = (props) => {
    const {preview} = props;
    const theme = useTheme();
    const apiResourceData = useFetchStatisticsCardDetailsAndTotal(
        {
            url: preview ? undefined : apiResources.siteStatus,
            params: {filter: {modelSeries: 'S'}}
        },
        {
            url: apiResources.siteStates
        },
        {
            linkCreator: (status: any) => `/#/infrastructure/iot${generateQueryString({connectionStatus: [status.key]})}`,
            getKeyColor: (key: any) => (getStatusColorFromTheme(key, theme))
        }
    );
    const [staticData, total] = preview ? previewData(theme) : apiResourceData;
    const history = useHistory();

    return (
        <ResourceStatisticsCard
            {...props}
            overViewData={staticData ? staticData.filter(filterDegraded) : staticData}
            overViewValue={total}
            overviewTitle="tesseract.dashboard.cards.status.iot.tabTitle"
            tableTitle="tesseract.network.iot.menuTitle"
        >
            <StatusIconField
                source="aggregateState"
                width={80}
            />
            <TextField
                source="name"
                label="tesseract.sites.table.name"
                sortable
            />
            <ChipArrayField
                source="serials"
                label="tesseract.sites.table.serial"
                columnProps={{
                    // @ts-ignore
                    id: "serials",
                    field: "serial"
                }}
            />
            <TextField
                source="gatewayName"
                label="tesseract.sites.table.gateway"
                columnProps={{
                    field: "gateway"
                }}
                sortable
            />
            <ActionButtonsField
                source="id"
                width={128}
            >
                <Forward
                    id="site-dashboard-icon"
                    onClick={(event: any, data: any) => history.push("/infrastructure/iot/" + data.id)}
                />
            </ActionButtonsField>
        </ResourceStatisticsCard>
    );
};


export default IotStatusCard;
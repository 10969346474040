import React from "react";
import {ButtonDialog} from "@cuda-react/core";
import ExplicitPolicyContent from "./ExplicitPolicyContent";

interface CreateExplicitPolicyDialogProps {
    label?: string;
    onSuccess?: () => void;
}

export const CreateExplicitPolicyDialog: React.FC<CreateExplicitPolicyDialogProps> = ({label, onSuccess}) => (
    <ButtonDialog buttonText={label || "tesseract.policies.dialogs.explicit.create.buttonText"}>
        <ExplicitPolicyContent create title="tesseract.policies.dialogs.explicit.create.dialogTitle"
                               onSuccess={onSuccess}/>
    </ButtonDialog>
);

export default CreateExplicitPolicyDialog;
import {CardSizes, CardsRecord,} from "../../../typesAndConstants";
import {ActiveDashboardCard, Dashboard} from "../../../hooks/useDashboards";
import React from "react";
import {useTranslation} from "react-i18next";
import {BasicPage, ButtonDialog} from "@cuda-react/core";
import {Button, Divider, Grid as MuiGrid, Typography} from "@barracuda-internal/bds-core";
import {AddWidgetButton} from "../AddWidgetButton";
import Grid from "../Grid";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {ExpandMore} from "@mui/icons-material";
import ResetDashboardDialog from "../ResetDashboardDialog";
import {AddDashboardDialogProps} from "../AddDashboardDialog/AddDashboardDialog";

const styles = (theme: Theme) => createStyles({
    topBorder: {
        borderTop: `${theme.palette.divider} solid 1px`,
        marginTop: -1
    },
    titleBar: {
        margin: 0,
        width: "100%"
    },
    emptyNotice: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(3),
        marginTop: theme.spacing(15)
    },
    contentDivider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    dashboardRoot: {
        marginTop: theme.spacing(1)
    },
    deleteButton: {
        backgroundColor: theme.palette.error.main
    },
    dashboardTitle: {
        display: "inline-flex",
        alignItems: "center",
    },
    optionsMenuButton: {
        marginLeft: "auto"
    },
    buttonBar: {
        width: "100%",
        margin: theme.spacing(1, 3, 0, 1),
        paddingTop: theme.spacing(1)
    },
    doneButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    cancelButton: {
        marginLeft: theme.spacing(1)
    },
    editButton: {
        marginLeft: "auto"
    },
    editButtonOneDashboard: {
        marginLeft: theme.spacing(1)
    },
    actions: {
        width: "100%"
    },
    action: {
        width: "100%",
        margin: theme.spacing(0, 1, 0, 0)
    }
});
const useStyles = makeStyles(styles);

export interface DashboardContentProps {
    /**
     * Show the selection the time frame
     */
    filters?: {filterButtons: React.ReactNode, activeFilters: React.ReactNode},
    /**
     * Whether the active dashboard is editable
     */
    editMode: boolean,
    /**
     * Callback function that can set the edit mode in the parent element.
     * In the current implementation the buttons of the parent fade away to lock the active tab.
     * @param mode
     */
    setEditMode: (mode: boolean) => void,
    /**
     * The dashboard this element renders
     */
    activeDashboard: Dashboard,
    /**
     * The cards available to the dashboard.
     * The list of cards that can be added to the dashboard.
     */
    availableCards: CardsRecord,
    /**
     * Callback function called whenever the order or number of active cards changes.
     * Meant to allow the parent to persist the active cards.
     * @param cards
     */
    onChange: (cards: ActiveDashboardCard[]) => void,
    cardEditBuffer: ActiveDashboardCard[],
    setCardEditBuffer: React.Dispatch<React.SetStateAction<ActiveDashboardCard[]>>
    /**
     * optional object that can be used to send additional data
     */
    additionalProps?: {[index: string]: any}
    /** templates to select for new dashboard. **/
    templates?: Dashboard[]
    /** disables tabs and action buttons, used when in edit mode */
    disabled?: boolean
    /**
     * boolean, if true it is a one tab dashboard. This means that there are not tabs, the Add Dashboard button is not enable,
     * the dashboard can not be deleted or renamed, but just reset to default. Reset the dashboard dialog is a confirm dialog.
     */
    oneTabDashboard?: boolean,
    /**
     * Function that updates a dashboard and saves its state
     */
    addUpdateDashboard: AddDashboardDialogProps["addUpdateDashboard"]
}

export const DashboardContent = (props: DashboardContentProps) => {
    const {
        editMode,
        activeDashboard,
        filters,
        setEditMode,
        availableCards,
        onChange,
        cardEditBuffer,
        setCardEditBuffer,
        additionalProps,
        templates,
        disabled,
        oneTabDashboard,
        addUpdateDashboard
} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);
    const activeCards = Array.isArray(activeDashboard?.cards) ?
        activeDashboard?.cards?.filter((card) => card.componentId in availableCards) : [];
    //we don´t allow to have the same kind of card twice
    const usedComponentIds = (editMode ? cardEditBuffer : activeCards).map((card: ActiveDashboardCard) => card.componentId);
    const filteredAvailableCards = Object.keys(availableCards)
        .filter((id) => !(usedComponentIds.includes(id)))
        .reduce((cards, id) => ({...cards, [id]: availableCards[id]}), {});
    const displayedCards = editMode ? cardEditBuffer : activeCards;

    const {filterButtons, activeFilters} = filters || {};

    const addCard = (selectedCardId?: string, selectedSize?: CardSizes) => {
        let cardBuffer = [...cardEditBuffer];
        if (!editMode) {
            setEditMode(true);
            cardBuffer = [...activeCards];
        }
        if (selectedCardId && availableCards[selectedCardId] && selectedSize) {
            const newCard: ActiveDashboardCard = {
                componentId: selectedCardId,
                size: selectedSize,
            };
            cardBuffer.push(newCard);
        }
        setCardEditBuffer(cardBuffer);
    };

    const doneButton = (
        <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
                onChange(cardEditBuffer);
                setEditMode(false);
            }}
            className={classes.doneButton}
        >
            {translate("stratosReporting.dashboardPage.done")}
        </Button>
    );

    const cancelButton = (
        <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
                setEditMode(false);
            }}
            className={classes.cancelButton}
        >
            {translate("stratosReporting.dashboardPage.cancel")}
        </Button>
    );
    const addButton = (
        <AddWidgetButton
            key={activeDashboard?.name}
            cards={filteredAvailableCards}
            onAdd={addCard}
        />
    );
    const editButton = (
        <Button
            variant="contained"
            color="primary"
            size="small"
            className={oneTabDashboard ? classes.editButtonOneDashboard : classes.editButton}
            onClick={() => {
                setCardEditBuffer(activeCards);
                setEditMode(true);
            }}
        >
            {translate("stratosReporting.dashboardPage.editDashboard")}
        </Button>
    );

    const resetMenu = (
        <ButtonDialog
            buttonEndIcon={<ExpandMore/>}
            buttonProps={{
                className: classes.optionsMenuButton,
                dashboardMenu: true,
                iconButton: false,
                color: "secondary",
                id: "open-dashboard-menu"
            }}
            buttonText="stratosReporting.dashboardPage.options"
            disabled={disabled}
            key="menu"
            useMenu
        >
            <ResetDashboardDialog
                key="reset-expand-menu-item"
                label="stratosReporting.dashboardPage.reset"
                dashboard={activeDashboard}
                addUpdateDashboard={addUpdateDashboard}
                templates={templates}
                oneTabDashboard={oneTabDashboard}
            />
        </ButtonDialog>
    );
    const editPageActions = (
        <MuiGrid
            container
            spacing={1}
            className={classes.buttonBar}
            direction="row-reverse"
            alignItems="center"
            wrap="wrap-reverse"
        >
            {doneButton}
            {cancelButton}
            {addButton}
        </MuiGrid>
    );

    const pageActions = (
        <MuiGrid
            container
            spacing={1}
            className={classes.buttonBar}
            direction="row-reverse"
            alignItems="center"
            wrap="wrap-reverse"
        >
            {editButton}
            {oneTabDashboard ? resetMenu : null}
            {filterButtons}
        </MuiGrid>
    );

    return (
        <BasicPage
            actions={editMode ? editPageActions : pageActions}
            // @ts-ignore
            classes={{
                root: !oneTabDashboard ? classes.topBorder : undefined,
                titleBar: classes.titleBar,
                actions: editMode ? undefined : classes.actions,
                action: editMode ? undefined : classes.action
            }}
        >
            <Divider className={classes.contentDivider}/>
            <MuiGrid direction="row" container>
                {activeFilters}
            </MuiGrid>
            <div className={classes.dashboardRoot}>
                {displayedCards.length < 1 ? (
                    <div className={classes.emptyNotice}>
                        <Typography variant="h5">
                            {translate("stratosReporting.dashboardPage.configureDashboard")}
                        </Typography>
                        <Typography variant="body1" align="center">
                            {translate("stratosReporting.dashboardPage.emptyMessage")}
                            <br/>
                            {translate("stratosReporting.dashboardPage.addMessage")}
                        </Typography>
                        <AddWidgetButton
                            cards={filteredAvailableCards}
                            onAdd={addCard}
                        />
                    </div>
                ) : (
                    <Grid
                        cardComponents={displayedCards.map(((card: ActiveDashboardCard) => ({
                            componentId: card?.componentId,
                            component: availableCards[card?.componentId]?.component,
                            props: {
                                size: card?.size || CardSizes.small,
                                additionalProps
                            }
                        })))}
                        onChange={(cards) => {
                            const mappedCards: ActiveDashboardCard[] = (cards || []).map((card) => (
                                {componentId: card.componentId, size: card.props.size || CardSizes.small})
                            );
                            setCardEditBuffer(mappedCards);
                        }}
                        editMode={editMode}
                        cards={filteredAvailableCards}
                        allCards={availableCards}
                    />
                )}
            </div>
        </BasicPage>
    );
};
import React from 'react';
import {BaseBarCard} from "../../baseCards";
import {colorFunctionSuccess, numberFormatter} from "../../../utils";
import {Trans, useTranslation} from "react-i18next";
import {useReportingData} from "../../../contexts";
import {CardSizes, CategoryDataPoint, DashboardCardProps} from "../../../typesAndConstants";
import {BarGridEntry} from "../../statistics/BarGrid";
import {sortBy} from "lodash";
import {Typography} from "@barracuda-internal/bds-core";
import {AppCategoriesIcon} from "../../icons";
import {useTheme} from "@mui/material";

export interface TopAllowedCategoriesSessionsCardProps extends DashboardCardProps {
}

export const TopAllowedCategoriesSessionsCard = (props: TopAllowedCategoriesSessionsCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("TopAllowedCategoriesSessions", preview);
    const {sdwanCategoryDataPoints = []} = data || {};
    const theme = useTheme();

    const maxValue = Math.max(...sdwanCategoryDataPoints.map((category: CategoryDataPoint) => category.numberOfSessions));
    const entries: BarGridEntry[] = sortBy(sdwanCategoryDataPoints, "numberOfSessions").reverse().map((category: CategoryDataPoint) => ({
        title: category.label,
        value: size === CardSizes.small ?
            numberFormatter(category.numberOfSessions) :
            translate("stratosReporting.topAllowedCategoriesSessions.sessions", {
                count: category.numberOfSessions,
                formattedCount: numberFormatter(category.numberOfSessions)
            }),
        barProgress: (100.0 / maxValue) * category.numberOfSessions,
        color: colorFunctionSuccess((100.0 / maxValue) * category.numberOfSessions, theme),
        // TODO: This icon can come from our set of icons for each category... as per the SDWAN page
        icon: <AppCategoriesIcon size={20}/>,
        onClick: (category.label && addFilter) ? () => addFilter(category.id, "sdwanCategoryDataPoints") : undefined,
        tooltipMessage: (category.label && addFilter) ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            {...props}
            entries={entries}
            title={
                <Trans i18nKey="stratosReporting.topAllowedCategoriesSessions.title">
                    <Typography variant="body1" component="span"/>
                </Trans>
            }
            loading={loading && !data.sdwanCategoryDataPoints}
            noData={!loading && !data.sdwanCategoryDataPoints}
            activeFilter
        />
    );
};
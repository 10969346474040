import React, {useRef} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTable,
    CrudTypes,
    DeleteDialog,
    getArrayDataContent,
    PropGateway,
    StatusIconField,
    TextField,
    useCrudFetch,
    useCrudProps,
    useMixpanel,
    useTableChoices
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditIpsRules from "./CreateEditIpsRules";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {formatSourceDestinationFilters} from "../../../../../components/sourceAndDestination/tableUtils";
import {ipsIcons} from "../../../../../components/iconMapping";
import {useSourceDestinationField} from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";

export const useStyles = makeStyles<any>((theme: Theme) => ({
    cardActions: {
        paddingRight: theme.spacing(2),
        width: "calc(100% - " + theme.spacing(2) + ")"
    },
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    },
    exploitList: {
        width: "512px !important"
    }
}));


export const IPSFalsePositives = () => {
    const registerAction = useMixpanel("IPS False Positive", false);
    const classes = useStyles();
    const tableRefreshRef = useRef<any>();
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.ipsActions)[0]?.data);
    const [, , callIpsUpdate] = useCrudFetch(CrudTypes.CREATE, apiResources.ips, {});
    const handleOrderClickIps = (id: any, direction: string, data: any) => callIpsUpdate({id, changeOrder: direction, data}).then(tableRefreshRef.current);
    const generateChipArrayPropsIps = useSourceDestinationField();
    const renderExploitChoice = useTableChoices(apiResources.ipsExploits, {optionValue: "id"});

    return (
        <ConnectedTable
            resource={apiResources.ips}
            exportResource={{resource: apiResources.ipsExport, filename: "ips"}}
            actions={[
                <CreateEditButtonDialog key="create" create component={CreateEditIpsRules}
                                        onSuccess={tableRefreshRef.current}/>
            ]}
            params={formatSourceDestinationFilters}
            classes={{cardActions: classes.cardActions}}
            minCellWidth={128}
            flat
            refreshRef={tableRefreshRef}
        >
            <ActionButtonsField
                left
                label="tesseract.security.ips.settings.order"
                cellClassName={classes.orderCell}
                width={96}
                source="id"
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClickIps(data.id, "increase", data)}
                    disabled={(data: { index: number; }) => data.index <= 0}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClickIps(data.id, "decrease", data)}
                    disabled={(data: { index: number; total: number; }) => data.index >= (data.total - 1)}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.ips.settings.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.ips.settings.description"
                filter="text"
            />
            <ChipArrayField
                source="exploits"
                label="tesseract.security.ips.settings.exploits"
                optionValue="id"
                // @ts-ignore
                render={renderExploitChoice}
                filter="selectarray"
                filterProps={{
                    resource: apiResources.ipsExploits,
                    optionValue: "id",
                    options: {
                        classes: {
                            popper: classes.exploitList
                        },
                        optionText: "name"
                    }
                }}
            />
            <StatusIconField
                source="action"
                text={(action) => (actionChoices.find((item) => item.key === action) || {}).name}
                label="tesseract.security.ips.settings.action"
                iconMap={ipsIcons}
                cellClassName={classes.actionCell}
                filter="select"
                filterProps={{
                    choices: actionChoices
                }}
                width={200}
            />
            <PropGateway
                source="source"
                label="tesseract.security.ips.settings.source"
                editProps={generateChipArrayPropsIps("source")}
                filter="custom"
                filterProps={{
                    component: SourceFilter,
                    filterSources: ["network", "site"]
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <PropGateway
                source="destination"
                label="tesseract.security.ips.settings.destination"
                editProps={generateChipArrayPropsIps("destination")}
                filter="custom"
                filterProps={{
                    component: DestinationFilter,
                    filterSources: ["network", "site"]
                }}
            >
                <ChipArrayField source="destination"/>
            </PropGateway>
            <ActionButtonsField
                width={96}
                source="id"
            >
                <CreateEditButtonDialog component={CreateEditIpsRules} onSuccess={tableRefreshRef.current}/>
                <DeleteDialog
                    resource={apiResources.ips}
                    title="tesseract.security.ips.settings.delete.title"
                    message="tesseract.security.ips.settings.delete.body"
                    onSuccess={() => {
                        registerAction("Delete");
                        tableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </ConnectedTable>
    );
};

export default IPSFalsePositives;
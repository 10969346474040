import {useDeepCompareEffect, useSharedData} from "../../../hooks";
import React, {createContext} from "react";
import {useHistory, useLocation} from "react-router";
import {get, uniq} from "lodash";

export type PreviewContextProps = string[];

export const PreviewContext = createContext<PreviewContextProps>([]);

const matcher = /(?:\?|&)preview=([^&|?\s]*)/;
const clearValues = ["none", "clear", "0"];

export const PREVIEW_ALL_FEATURES_ACTIVE = 'all';

export interface PreviewProviderProps {
    children?: React.ReactNode,
    stateOnly?: boolean
};

/**
 * Preview Provider. Provides the ability to monitor active "preview" modes, that are set using a query in the url.
 *
 * Add '?preview=somename' to turn on the "somename" preview.
 *
 * You can turn on multiple at once, either by repetitively adding new queries and refreshing, or via a single url query
 * with the values comma seperated, e.g.: '?preview=somename,anothername,foobar'
 *
 * To disable all active previews use:
 *
 * '?preview=none' or '?preview=0'.
 *
 * In code, to check whether a particular preview is active, use the "usePreview" hook, e.g.:
 *
 *     'const previewActive = usePreview("somename");'
 *
 * For child apps, to prevent race conditions on accepting the URL when it changes, please provide "stateOnly" prop to turn
 * off url watching and just monitor the shared preview state from the parent app.
 */
export const PreviewProvider = ({children, stateOnly}: PreviewProviderProps) => {
    const [activePreviews = [], setActivePreviews] = useSharedData<string[]>("activePreviews", []);
    const location = useLocation();
    const {push} = useHistory();

    useDeepCompareEffect(() => {
        if (!stateOnly) {
            const search = get(location, "search", "");
            const hash = get(location, "hash", "");
            const match = search.match(matcher) || hash.match(matcher);

            if (match && match[1]) {
                push({
                    ...location,
                    search: search.replace(match[0], ""),
                    hash: hash.replace(match[0], "")
                });

                if (clearValues.includes(match[1])) {
                    setActivePreviews(() => []);
                } else {
                    const previews = match[1].split(",");
                    setActivePreviews((currentPreviews = []) => uniq([...currentPreviews, ...previews]));
                }
            }
        }
    }, [location, stateOnly]);

    return (
        <PreviewContext.Provider value={activePreviews}>
            {children}
        </PreviewContext.Provider>
    );
};

export default PreviewProvider;
import classNames from "classnames";
import React, {ReactElement, ReactNode, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
// @ts-ignore
import ConditionalWrapper from "../../functional/ConditionalWrapper/ConditionalWrapper";
import {List, ListItem, ListItemText, Tooltip} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {get} from "lodash";
import {useHistory, useLocation} from "react-router";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {TabProps} from "../Tab/Tab";
import {ClonableChildren} from "../../../utils/commonTypes";

const styles = (theme: Theme) => createStyles({
    sideTabs: {
        width: "100%",
        display: "grid",
        gridGap: 0,
        gridTemplateColumns: "228px calc(100% - 228px)",
        transition: "all 1s"
    },
    sideBar: {
        gridColumn: 1,
        gridRow: 1,
        width: 220,
        padding: "0 8px 8px 0",
        transition: "all 1s",
        zIndex: 0
    },
    sideBarButton: {
        margin: 4,
        borderRadius: 7,
        padding: "6px 5px",
        width: "calc(100% - 8px)",
        display: "flex",
        alignItems: "center"
    },
    listItemText: {
        fontSize: 14,
        height: 18,
        lineHeight: '18px',
        marginRight: 'auto',
    },
    sideBarButtonText: {
        fontSize: 14,
        height: 18,
        lineHeight: '18px',
        marginRight: 'auto',
    },
    menuItemActive: {
        padding: theme.spacing(1, 2, 1, 1),
        borderRadius: 4,
        '&:hover': {
            backgroundColor:
            // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
                theme.palette.primary.background || theme.palette.primary.border,
        },
        '&:focus': {
            backgroundColor:
            // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
                theme.palette.primary.background || theme.palette.primary.border,
        },
        backgroundColor:
        // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
            theme.palette.primary.background || theme.palette.primary.border,
        color: theme.palette.primary.dark,
    },
    menuItemInactive: {
        padding: theme.spacing(1, 2, 1, 1),
        borderRadius: 4,
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor:
            // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
                theme.palette.primary.background || theme.palette.primary.border,
        },
        '&:focus': {
            backgroundColor:
            // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
                theme.palette.primary.background || theme.palette.primary.border,
        }
    },
    menuItemDisabled: {
        color: theme.palette.text.disabled
    },
    tabContent: {},
    tooltipPopper: {
        left: "-238px !important"
    },
    tooltip: {
        ...theme.typography.body2
    }
});

const useStyles = makeOverrideableStyles("SideTabs", styles);

export interface SideTabsProps extends StyledComponentProps<typeof styles> {
    /**
     * for each child provided, one tab button is created. The children are then rendered, and provided with a "activeValue" prop (and a unique "value" prop if not already set).
     * These can be used by the child to determine whether it is active, and should render or not.
     * The [Tab](/?path=/docs/core-components-tabs-tab--tab) has been created to seamlessly work with both [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) and SideTabs.
     *
     * The tab button that is rendered is determined by props added to each child. Additional behaviour and props are implemented by the [Tab](/?path=/docs/core-components-tabs-tab--tab),
     * see it's page for more information.
     */
    children: ClonableChildren<TabProps>,
    /**
     * The default tab to show initially (if a matching value is not already set in the URL's hash) or when an active tab becomes disabled.
     */
    defaultTab: number
}

/**
 * A tab solution with the tab buttons in a side menu.
 *
 * Most commonly used in a top level page, with *Page components as the children. For this use-case, you can simply use
 * [TabbedPage](/?path=/docs/core-components-pages-tabbedpage--tabbed-page), which internally uses SideTabs.
 */
const SideTabs = (props: SideTabsProps): JSX.Element => {
    const {children, defaultTab} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const location = useLocation();
    const {push} = useHistory();
    const childArray = React.Children.toArray(children).filter((child) => child) as ReactElement[];
    const currentTabIndex = location.hash ? childArray.findIndex((child, index) => `#${child.props.value || index}` === location.hash) : defaultTab;
    const activeValue = get(childArray[currentTabIndex], "props.value", currentTabIndex);

    useEffect(() => {
        if (currentTabIndex !== defaultTab && (!childArray[currentTabIndex] || childArray[currentTabIndex].props.disabled || childArray[currentTabIndex].props.hidden)) {

            push({
                ...location,
                hash: `#${get(childArray[defaultTab], "props.value", defaultTab)}`
            });
        }
    }, [location.hash, currentTabIndex]);

    return (
        <div className={classes.sideTabs}>
            <List className={classes.sideBar} id="cuda-react-sidebar">
                {childArray.map((child, index) => {
                    const childProps = child && child.props || {};
                    const tabIsActive = !childProps.disabled && (index === currentTabIndex);
                    const tabIsInactive = !childProps.disabled && (index !== currentTabIndex);

                    return (
                        <ConditionalWrapper
                            key={index}
                            condition={!!(childProps.disabledMessage && childProps.disabled)}
                            wrapper={(children: ReactNode) => (
                                <Tooltip
                                    title={translate(childProps.disabledMessage)}
                                    placement="right-start"
                                    classes={{popper: classes.tooltipPopper, tooltip: classes.tooltip}}
                                >
                                        <span>
                                            {children}
                                        </span>
                                </Tooltip>
                            )}
                        >
                            <ListItem
                                button
                                component={Link}
                                to={`#${childProps.value || index}`}
                                disabled={childProps.disabled}
                                className={classNames(
                                    classes.sideBarButton,
                                    childProps.disabled && classes.menuItemDisabled,
                                    tabIsActive && classes.menuItemActive,
                                    tabIsInactive && classes.menuItemInactive
                                )}
                            >
                                <ListItemText
                                    className={classes.listItemText}
                                    primary={translate(childProps.label)}
                                    primaryTypographyProps={{
                                        variant: "body2",
                                        className: classes.sideBarButtonText
                                    }}
                                />
                            </ListItem>
                        </ConditionalWrapper>
                    );
                })}
            </List>
            <div className={classes.tabContent}>
                {childArray.map((tab, index) => React.cloneElement(
                    tab,
                    {
                        key: tab.props.value || index,
                        value: tab.props.value || index,
                        activeValue
                    }
                ))}
            </div>
        </div>
    );
};

SideTabs.defaultProps = {
    defaultTab: 0
};

export default SideTabs;
import React from "react";
import {ConnectedForm, DialogBody, Tab} from "@cuda-react/core";
import BasicPolicyInputs from "./BasicPolicyInputs";
import DeviceAttributesInputs, {validateDeviceAttributes} from "./DeviceAttributesInputs";
import {formatError, formatRequestData, formatResourceData} from "./FormattingMethods";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../../apiResources";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    dialogContent: {
        width: 680
    }
});

interface EditPolicyProps {
    onClose?: any,
    onSuccess?: any,
    id?: number | string
}

export const EditPolicy: React.FC<EditPolicyProps> = ({onClose, onSuccess, id}) => {
    const classes = useStyles();
    const [translate] = useTranslation();

    return (
        <DialogBody
            title="tesseract.endpoint.remoteAccessPolicies.edit"
            onClose={onClose}
            classes={classes}
            form
        >
            <ConnectedForm
                tabbed
                resource={apiResources.zeroTrustPolicies}
                onSubmitSuccess={() => {
                    onSuccess?.();
                    onClose?.();
                }}
                params={{id}}
                onCancel={onClose}
                formatRequestData={formatRequestData}
                formatResourceData={formatResourceData}
                formatError={formatError}
                validate={validateDeviceAttributes(translate)}
                allowDirtyNavigation
            >
                <Tab label="tesseract.endpoint.remoteAccessPolicies.policy">
                    <BasicPolicyInputs/>
                </Tab>
                <Tab label="tesseract.endpoint.remoteAccessPolicies.deviceAttributes">
                    <DeviceAttributesInputs/>
                </Tab>
            </ConnectedForm>
        </DialogBody>
    );
};

export default EditPolicy;
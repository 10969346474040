import {CardHeader, Divider} from "@barracuda-internal/bds-core";
import React from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {StyledComponentProps} from "@cuda-react/theme";
import {TableCardContent} from "@cuda-react/core";
import {CardSizeOptions} from "../../../typesAndConstants";
import {TableCardContentProps} from "@cuda-react/core/lib/components/cards/TableCardContent/TableCardContent";

const styles = (theme: Theme) => createStyles({
    dividerCards: {
        margin: theme.spacing(0.5, 5, 0),
        width: `calc(100% -${theme.spacing(10)})`
    },
    titleTypography: {
        fontSize: 16,
        fontWeight: "bold",
        overflowY: "visible",
        paddingLeft: theme.spacing(1),
        color: theme.palette.reporting.fontColor
    },
    table: {
        maxHeight: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6.5)}) !important`,
        minHeight: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6.5)}) !important`,
        margin: theme.spacing(0, 2.5),
        border: "none",
        "& div.k-grid-content": {
            overflowY: "auto !important"
        }
    }
});
const useStyles = makeStyles(styles);

export interface StatisticsTableProps extends StyledComponentProps<typeof styles>, Omit<TableCardContentProps, "classes"> {
    /** Title of the table */
    tableTitle: string
}

export const StatisticsTable = (props: StatisticsTableProps) => {
    const {tableTitle, children, ...tableCardContentProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <>
            <Divider className={classes.dividerCards}/>
            <CardHeader classes={{title: classes.titleTypography}} title={translate(tableTitle)}/>
            <TableCardContent {...tableCardContentProps} classes={{dataTable: classes.table}}>
                {children}
            </TableCardContent>
        </>
    );
};
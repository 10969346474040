import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const FirewallGraphic = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-firewall"
            viewBox="0 0 96 96"
        >
            <g id="ArtBoard11">
                <g id="Ebene-1">
                    <path style={{fill: theme.palette.general?.white, strokeWidth: 0}} d="M74.4,57.7h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="19.6" y="49.6" width="5.7" height="5.7"/>
                    <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                          d="M20.4,21.6c1.1-2.1,3.5-2.8,4.9-2.8h33.2c1.2,0,2.6,1.2,3.5,2.8l6,16.2H14.3l6-16.2ZM75.1,42.7h0s0,0,0,0c0-.3-.2-.6-.3-.9l-8.2-22v-.2c-1.8-3.5-4.9-5.7-8.1-5.7H25.2c-3.3,0-7.5,1.8-9.4,5.7l-8.6,23.1h.2c-.2.7-.3,1.3-.3,1.9v15.6c0,3.7,3,6.8,6.8,6.8h39c0-.2,0-.5,0-.7,0-1.7.2-3.4.6-4.9H13.9c-.6,0-1.1-.5-1.1-1.1v-15.6c0-.6.5-1.1,1.1-1.1h54.7c.6,0,1.1.5,1.1,1.1v2c1-.2,2-.3,3-.3s1.8,0,2.6.2v-2c0-.7-.1-1.3-.3-1.9"/>
                    <path style={{fill: theme.palette.success?.main, strokeWidth: 0}}
                          d="M72.7,49.2c-9.4,0-17.1,7.6-17.1,17.1s7.6,17.1,17.1,17.1,17.1-7.6,17.1-17.1-7.6-17.1-17.1-17.1ZM81.3,67.9h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default FirewallGraphic;
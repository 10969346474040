import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

const ProcessorIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-processor"
            viewBox="0 0 60 60"
        >
            <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                  d="M42.1,44.6h-24.3c-1.4,0-2.5-1.1-2.5-2.5v-24.3c0-1.4,1.1-2.5,2.5-2.5h24.3c1.4,0,2.5,1.1,2.5,2.5v24.3c0,1.4-1.1,2.5-2.5,2.5ZM20.3,39.7h19.4v-19.4h-19.4v19.4Z"/>
            <g>
                <g>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="19.9" y="5.2" width="3.5" height="6.5"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="28.2" y="5.2" width="3.5" height="6.5"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="36.6" y="5.2" width="3.5" height="6.5"/>
                </g>
                <g>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="19.9" y="48.4" width="3.5" height="6.5"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="28.2" y="48.4" width="3.5" height="6.5"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="36.6" y="48.4" width="3.5" height="6.5"/>
                </g>
            </g>
            <g>
                <g>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="48.4" y="19.9" width="6.5" height="3.5"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="48.4" y="28.2" width="6.5" height="3.5"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="48.4" y="36.6" width="6.5" height="3.5"/>
                </g>
                <g>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="5.2" y="19.9" width="6.5" height="3.5"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="5.2" y="28.2" width="6.5" height="3.5"/>
                    <rect style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}} x="5.2" y="36.6" width="6.5" height="3.5"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default ProcessorIcon;
import React, {useMemo} from "react";
import WizardPage, {WizardPageProps} from "../WizardPage";
import {CrudTypes, getArrayDataContent, useCrudFetch, useCrudSubscription, useGlobalParam} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import {WizardLocalUsers} from "../stepComponents/WizardLocalUsers";
import {SavedFormData} from "../SetupWizard";

interface WizardStep2Props extends Pick<WizardPageProps, "wizardStates" | "registerStepAction"> {
    onSuccess?: () => void,
    updateSavedFormData: (values: SavedFormData) => void
}

// TODO: The external user directory will be done later, all commmented-out code was there to support the UD/IDP option
const WizardStep2 = (props: WizardStep2Props) => {
    const {onSuccess, updateSavedFormData} = props;
    // const [activeTab, setActiveTab] = useState(0);

    const [account] = useGlobalParam("pageTitle.account");
    const accountName = useMemo(() => account?.join?.(" - "), [account]);

    const [userData, userDataLoading, fetchUsers]: any = useCrudSubscription(CrudTypes.GET, apiResources.identityUsers);
    const userDataChips = getArrayDataContent(userData?.data).map((user: any) => user.email);

    // const [userDirectoryData, loadingUserDirectory, fetchUserDirectory]: any = useCrudSubscription(CrudTypes.GET, apiResources.directories);
    // const userDirectories = getArrayDataContent(userDirectoryData?.data);

    const [identityProvidersData,]: any = useCrudSubscription(CrudTypes.GET, apiResources.identityProvider);
    const identityProviders = getArrayDataContent(identityProvidersData?.data);
    const [, savingIdentityProvider, createIdentityProvider] = useCrudFetch(CrudTypes.CREATE, apiResources.identityProvider, {
        data: {
            type: "email",
            displayName: "Email",
            accountName
        }
    });
    const registerFailAction = (error: any) => {
        props.registerStepAction("Fail", {error: JSON.stringify(error)});
    };

    const complete = userDataChips.length;// || (userDirectories.length || identityProviders.length);

    return (
        <WizardPage
            textHeader="tesseract.setupWizard.wizardStep2.textHeader"
            textSubHeader="tesseract.setupWizard.wizardStep2.textSubHeader"
            activeStep={1}
            onNext={() => {
                // if (activeTab === 0) {
                updateSavedFormData({users: getArrayDataContent(userData?.data).slice(0, 5).map(({email}: any): string => email)});
                if (identityProviders.some(({type}: any) => type === "email")) {
                    onSuccess?.();
                } else {
                    props.registerStepAction("Submit", {identityProvider: "email"});
                    createIdentityProvider().then(onSuccess).catch(registerFailAction);
                }
                // } else {
                //     onSuccess?.();
                // }
            }}
            disabled={!complete || savingIdentityProvider}
            {...props}
        >
            {/*<Tabs activeTab={activeTab} onChange={setActiveTab}>*/}
            {/*    <Tab label="tesseract.setupWizard.wizardStep2.userDirectories.localUserDirectory">*/}
            <WizardLocalUsers
                userData={userDataChips}
                onSuccess={fetchUsers}
                userDataLoading={userDataLoading}
                onSubmit={() => props.registerStepAction("Submit", {userCreation: true})}
                onError={registerFailAction}
            />
            {/*</Tab>*/}
            {/*<Tab label="tesseract.setupWizard.wizardStep2.userDirectories.externalUserDirectory">*/}
            {/*    <WizardUserDirectories userDirectories={userDirectories} onSuccess={fetchUserDirectory}/>*/}
            {/*    <WizardIdentityProviders*/}
            {/*        identityProviders={identityProviders}*/}
            {/*        accountName={accountName}*/}
            {/*        onSuccess={fetchIdentityProviders}*/}
            {/*    />*/}
            {/*</Tab>*/}
            {/*</Tabs>*/}
        </WizardPage>
    );
};

export default WizardStep2;
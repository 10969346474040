import {Button, ButtonProps, CircularProgress} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import Toolbar from "../../layout/Toolbar/Toolbar";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";

const styles = createStyles({
    primary: {},
    secondary: {},
    back: {}
});

const useStyles = makeOverrideableStyles("WizardToolbar", styles);

export interface WizardToolbarProps extends StyledComponentProps<typeof styles> {
    /**
     * callback called when the back button is clicked.
     *
     * If not provided, no back button is rendered.
     */
    back?: () => void,
    /**
     * callback called when the primary button is clicked.
     */
    primaryOnClick?: () => void,
    /**
     * label for the primary button.
     */
    primaryLabel?: string,
    /**
     * callback called when the secondary button is clicked.
     *
     * if not provided, no secondary button is rendered.
     */
    secondaryOnClick?: () => void,
    /**
     * label for the secondary button.
     */
    secondaryLabel?: string,
    /**
     * color for the secondary button.
     */
    secondaryColor?: ButtonProps["color"],
    /**
     * if true, all rendered buttons are disabled. Additionally, the primary button shows a loading spinner.
     */
    toolbarDisabled?: boolean,
}

/**
 * Basic 1-3 button toolbar, used by the wizard *Step components.
 */
export const WizardToolbar = (props: WizardToolbarProps) => {
    const {back, primaryOnClick, toolbarDisabled, primaryLabel, secondaryOnClick, secondaryLabel, secondaryColor = "primary"} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <Toolbar>
            <Button
                onClick={primaryOnClick}
                disabled={toolbarDisabled}
                startIcon={(toolbarDisabled ? (
                    <CircularProgress id="cuda-loading-spinner" size={16} thickness={2}/>
                ) : undefined)}
                variant="contained"
                color="primary"
                size="small"
                className={classes.primary}
            >
                {primaryLabel && translate(primaryLabel)}
            </Button>
            {(secondaryLabel && secondaryOnClick) ? (
                <Button
                    onClick={secondaryOnClick}
                    disabled={toolbarDisabled}
                    variant="contained"
                    size="small"
                    color={secondaryColor}
                    className={classes.secondary}
                >
                    {translate(secondaryLabel)}
                </Button>
            ) : null}
            {back ? (
                <Button
                    onClick={back}
                    disabled={toolbarDisabled}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.back}
                >
                    {translate("cuda.buttons.back")}
                </Button>
            ) : null}
        </Toolbar>
    );
};

WizardToolbar.defaultProps = {
    primaryLabel: "cuda.buttons.next"
};

export default WizardToolbar;
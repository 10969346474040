import {previewDataGenerator} from "../../typesAndConstants";

const dataGenerator: previewDataGenerator = () => ({
    total: 165,
    operatingSystems:
        {
            "Mac OS": 28,
            "Android": 22,
            "iOS": 7,
            "Linux": 8,
            "Windows": 100,
            "Chrome OS": 100
        }
});

export default dataGenerator;
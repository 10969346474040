import React from "react";
import {useWatch} from "react-hook-form";
import StepContent from "../StepContent/StepContent";
import WizardToolbar from "../WizardToolbar/WizardToolbar";
import FormErrorReporter from "../../forms/FormErrorReporter";
import {BaseStepProps} from "../StepType";

export interface ResultStepProps extends BaseStepProps {
    /**
     * callback to be passed to the WixardToolbar primaryOnClick action, to close the wizard.
     */
    onClose?: () => void
}

/**
 * Wizard step component for form submission results.
 *
 * For use as a child of the [Wizard]() component, as it provides most of the context and required props.
 *
 * Renders the appropriate content section and toolbar. Children are rendered within the content section
 * (and are usually  *Field components, or WizardSummary).
 *
 * All additional props not described below are passed to the [StepContent](/?path=/docs/core-components-wizard-wizardtoolbar--wizard-toolbar).
 *
 * Some additional props can be set on InputStep, and are consumed by the parent [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) (such as "label").
 */
export const ResultStep = (props: ResultStepProps) => {
    const {
        active,
        back,
        children,
        form,
        formErrorReporterValue,
        formErrorReporterSetError,
        onClose,
        preventBackNavigation,
        primaryLabel = "cuda.buttons.finish",
        secondaryButtonOnClick,
        result,
        secondaryButtonLabel,
        title,
        toolbarDisabled,
        secondaryColor,
        ...contentProps
    } = props;
    const data = useWatch();

    return (
        <div style={active ? undefined : {display: "none"}}>
            <FormErrorReporter
                value={formErrorReporterValue}
                setError={formErrorReporterSetError}
            >
                <StepContent
                    childProps={{data, result}}
                    title={title}
                    {...contentProps}
                >
                    {children}
                </StepContent>
            </FormErrorReporter>
            <WizardToolbar
                back={!preventBackNavigation && back || undefined}
                primaryLabel={primaryLabel}
                primaryOnClick={onClose}
                secondaryLabel={secondaryButtonLabel}
                secondaryOnClick={secondaryButtonOnClick ? () => secondaryButtonOnClick(data, result) : undefined}
                secondaryColor={secondaryColor}
                toolbarDisabled={toolbarDisabled}
            />
        </div>
    );
};

ResultStep.defaultProps = {
    preventBackNavigation: true,
    hideErrors: true,
    hideWizardStepper: true,
};

export default ResultStep;
import {useCallback, useState} from "react";

export default () => {
    const [listeners, setListeners] = useState<string[]>([]);
    const register = useCallback((id: string) => {
        setListeners((currentListeners) => [...currentListeners, id]);
    }, []);
    const unregister = useCallback((id: string) => {
        setListeners((currentListeners) => currentListeners.filter((listener) => listener !== id));
    }, []);

    return {register, unregister, listeners};
};
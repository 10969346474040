import React from "react";
import {
    ConnectedForm,
    DialogBody,
    getArrayDataContent,
    SelectInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel
} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";

interface CreateManagedGatewayContentProps {
    title?: string,
    onSuccess?: () => void,
    onClose?: () => void,
    label?: string
}

const CreateManagedGatewayContent: React.FC<CreateManagedGatewayContentProps> = ({onSuccess, onClose, title}) => {
    const registerAction = useMixpanel("Gateways", false);
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const inputValidations = useSiteInputValidations();
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysRegions, {virtualWanId})[0]);
    const bandwidthChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysBandwidths)[0]);
    const [translate] = useTranslation();
    const bandwidthData = useCrudProps(apiResources.managedBandwidthsUsage)[0]?.data;
    const availableBandwidth = bandwidthData?.available > 0 ? bandwidthData.available : 0;
    regionChoices.sort((region1, region2) => (region1.key.localeCompare(region2.key)));

    return (
        <DialogBody
            title={title}
            onClose={onClose}
            form
        >
            <ConnectedForm
                create
                resource={apiResources.managedGateways}
                onSubmitSuccess={(response) => {
                    registerAction("Create", {
                        model: response.model,
                        region: response.region
                    });
                    onSuccess?.();
                    onClose?.();
                }}
                formatRequestData={(data) => ({
                    ...data,
                    virtualWanId
                })}
                onCancel={onClose}
            >
                <TextInput
                    source="name"
                    label="tesseract.gateways.dialog.create.managed.name"
                    isRequired
                    validate={inputValidations.validateSiteName}
                />
                <SelectInput
                    source="region"
                    label="tesseract.gateways.dialog.create.managed.region"
                    choices={regionChoices}
                />
                <SelectInput
                    source="model"
                    label="tesseract.gateways.dialog.create.managed.bandwidth"
                    choices={bandwidthChoices}
                    additionalInfoLabel={availableBandwidth <= 99999 ? {i18nKey: "tesseract.gateways.dialog.create.managed.bandwidthAvailable", values: {availableBandwidth}} : undefined}
                    validate={(value) => {
                        if (value && value > availableBandwidth) {
                            return translate("tesseract.gateways.dialog.create.managed.invalidBandwidth", {availableBandwidth});
                        } else {
                            return undefined;
                        }
                    }}
                />
            </ConnectedForm>
        </DialogBody>

    );
};


export default CreateManagedGatewayContent;
import React from "react";
import {
    Card,
    ChipArrayField,
    CustomField,
    DateField,
    getArrayDataContent,
    LinkField,
    ListCardContent,
    SelectedChoiceField,
    StatusIconField,
    TableCardContent,
    TextField,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import {StatusIcon} from "@cuda-react/icons";
import {makeStyles} from "@mui/styles";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
import {get} from "lodash";
import apiResources from "../../../../apiResources";
import {siteGatewayStatusIcons} from "../../../../components/iconMapping";

interface SiteSummaryCardProps {
    details?: {
        id?: number | string,
        gateway?: string | number,
        modelSeries?: "S" | "T"
    },
    column?: number
}

const useStyles = makeStyles((theme) => ({
    serialIconSpan: {
        lineHeight: 3,
        marginLeft: -7,
        "& svg": {
            position: "relative",
            top: 7,
            marginRight: theme.spacing(1)
        }
    }
}));

const SiteSummaryCard: React.FC<SiteSummaryCardProps> = ({details}) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]?.data);
    const scaleUnitChoices = getArrayDataContent(useCrudProps(apiResources.gatewayScaleUnits)[0]?.data);
    const licenseStateChoices = getArrayDataContent(useCrudProps(!isSc ? apiResources.licensesStates : undefined)[0]?.data);
    const licenseTypeChoices = getArrayDataContent(useCrudProps(!isSc ? apiResources.licensesTypes : undefined)[0]?.data);

    return (
        <>
            <Card
                id="SiteSummaryCard"
                title="tesseract.appliances.dashboard.summary.siteStatus.title"
            >
                <ListCardContent
                    getFrom={{
                        resource: apiResources.siteDashboardStatus,
                        params: {id: details?.id}
                    }}
                >
                    <TextField label="tesseract.appliances.dashboard.summary.siteStatus.model" source="model"/>
                    <ChipArrayField
                        label={`tesseract.appliances.dashboard.summary.siteStatus.serial${isSc ? "" : "s"}`}
                        source="connectionStatus.connectionStates"
                        render={(val) => (
                            <span className={classes.serialIconSpan}>
                            <StatusIcon
                                iconMap={siteGatewayStatusIcons}
                                status={val.state}
                            />
                                {val.id}
                        </span>
                        )}
                    />
                    <TextField label="tesseract.appliances.dashboard.summary.siteStatus.release" source="version"/>
                </ListCardContent>
            </Card>
            {!isSc && (
                <Card
                    id="SiteLicencesCard"
                    title="tesseract.appliances.dashboard.summary.licenses.title"
                >
                    <TableCardContent
                        resource={details?.id ? apiResources.licensesState : undefined}
                        params={{id: details?.id}}
                        formatData={(data) => data?.licenseStates || []}
                        minCellWidth={64}
                    >
                        <StatusIconField
                            label="tesseract.appliances.dashboard.summary.licenses.status"
                            source="state"
                        />
                        <TextField
                            label="tesseract.appliances.dashboard.summary.licenses.serial"
                            source="instanceId"
                        />
                        <SelectedChoiceField
                            label="tesseract.appliances.dashboard.summary.licenses.state"
                            source="state"
                            key="textState"
                            choices={licenseStateChoices}
                        />
                        <SelectedChoiceField
                            source="type"
                            label="tesseract.appliances.dashboard.summary.licenses.type"
                            choices={licenseTypeChoices}
                        />
                        <CustomField
                            source="expiryDate"
                            label="tesseract.appliances.dashboard.summary.licenses.expirationDate"
                            render={(expiryDate, data) => !(data.type === "payg" && data.state === "ok")
                                ? <DateField data={data} source="expiryDate"/>
                                : <TextField source="data"
                                             data={{data: translate("tesseract.appliances.dashboard.summary.licenses.noData")}}/>
                            }
                        />
                    </TableCardContent>
                </Card>
            )}
            <Card
                id="SiteGatewayCard"
                title="tesseract.appliances.dashboard.summary.gateway.tabTitle"
            >
                <ListCardContent
                    resource={details?.gateway ? apiResources.gateways : undefined}
                    params={{id: details?.gateway}}
                    noDataMessage={!details?.gateway ? "tesseract.appliances.dashboard.summary.gateway.noGateway" : undefined}
                >
                    <LinkField
                        source="name"
                        label="tesseract.appliances.dashboard.summary.gateway.name"
                        link={getAzureResourceLink("applicationId", isDemo)}
                        sortable
                    />
                    <LinkField
                        source="virtualWan.name"
                        label="tesseract.appliances.dashboard.summary.gateway.vwan"
                        link={getAzureResourceLink("virtualWan.resourceId", isDemo)}
                        sortable
                    />
                    <LinkField
                        source="hubName"
                        label="tesseract.appliances.dashboard.summary.gateway.hubName"
                        link={getAzureHubLink("hubId", isDemo)}
                        sortable
                    />
                    <SelectedChoiceField
                        source="region"
                        label="tesseract.appliances.dashboard.summary.gateway.region"
                        choices={regionChoices}
                        sortable
                    />
                    <SelectedChoiceField
                        label="tesseract.appliances.dashboard.summary.gateway.size"
                        source="scaleUnit"
                        key="scaleUnit"
                        choices={scaleUnitChoices}
                        skip={(value) => value.scaleUnit === 0}
                    />
                </ListCardContent>
            </Card>
        </>
    );
};


export default SiteSummaryCard;
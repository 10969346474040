import React from "react";
import WholePageLayout from "../../components/subscription/WholePageLayout";
import {Trans} from "react-i18next";

export const ExpiredPage = () => (
    <WholePageLayout
        title="stratos.subscription.expiredTitle"
        message="stratos.subscription.expiredBody"
        list={(
            <Trans
                i18nKey="stratos.subscription.purchaseOrContactCustomerServices"
            >
                <ul>
                    <li />
                    <li>
                        <a
                            href="https://www.barracuda.com/support/index"
                            rel="noreferrer noopener"
                            target="_blank"
                        />
                    </li>
                    <li />
                </ul>
            </Trans>
        )}
        subscribe
        logout
        selectAccount
    />
);

export default ExpiredPage;
import {createStyles} from "@mui/styles";
import {
    AutocompleteGroupedOption,
    createFilterOptions,
    InputAdornment,
    Theme,
    UseAutocompleteProps
} from "@mui/material";
import {
    FormHelperText,
    IconButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    TextField,
    Typography
} from "@barracuda-internal/bds-core";
import {Close, Search, SortDescending} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {useAutocomplete} from "@mui/lab";
import classNames from "classnames";
import {get} from "lodash";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDeepCompareEffect} from "../../../hooks";
import {formatErrorMessage} from "../../../utils";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";

interface BaseAutocompleteSearchProps  {
    /**
     * if true, the currently selected value is cleared when the input is clicked.
     */
    clearOnFocus?: boolean,
    /**
     * if true, input is disabled.
     */
    disabled?: boolean,
    /**
     * if true, the auto-complete dropdown width is set to scale to the size of its contents. If falsy, dropdown will stay as the width of the main input.
     */
    dropdownAutoWidth?: boolean,
    /**
     * if provided the error message is displayed below input, and an error border is rendered around input.
     */
    error?: string,
    /**
     * icon to display next to each choice. If iconMap is provided as well, this icon will be used whenever a matching icon is not found in iconMap.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons to display next to each choice. The "optionIcon" value of each choice is used to identify the icon from this map.
     */
    iconMap?: {[key: string]: React.ReactNode},
    /**
     * id to be set on the root component.
     */
    id?: string,
    /**
     * label to displayed on the input.
     */
    label?: string,
    /**
     * the maximum number of options to display in the auto-complete dropdown.
     */
    maxOptionsVisible: number,
    /**
     * message to display if there are no options to display.
     */
    noOptionsText: string,
    /**
     * if true, the search icon is not displayed.
     */
    noSearchIcon?: boolean,
    /**
     * dot-notation path to the property in each choice object that defines the icon to use from 'iconMaps'.
     */
    optionIcon?: string,
    /**
     * placeholder text to display in the search bar.
     */
    placeholder?: string,
    /**
     * the currently selected choice.
     */
    value?: any,
}

export const styles = (theme: Theme) => createStyles<string, BaseAutocompleteSearchProps>({
    autocomplete: {
        width: "100%"
    },
    autocompleteInput: {
        flexGrow: 1,
        "& input": {
            overflow: "hidden !important",
            textOverflow: "ellipsis",
            paddingRight: (props) => props.clearOnFocus ? "36px" : "64px"
        }
    },
    chip: {
        margin: "0.1rem"
    },
    chipContainer: {
        maxWidth: 256
    },
    endAdornment: {
        position: "absolute",
        right: 8,
        top: "calc(50% - 14px)"
    },
    clearIndicator: {
        marginRight: -2,
        padding: 4,
        color: theme.palette.action.active
    },
    clearIndicatorHidden: {
        visibility: "hidden",
    },
    popupIndicator: {
        padding: 2,
        marginRight: -2,
        color: theme.palette.action.active,
    },
    popupIndicatorOpen: {
        transform: "rotate(180deg)",
    },
    popper: {
        zIndex: theme.zIndex.modal,
        "& ul": {
            maxHeight: "80vh",
            overflowY: "auto"
        },
        width: (props) => props.dropdownAutoWidth ? "auto !important" : undefined,
        minWidth: (props) => props.dropdownAutoWidth ? 256 : undefined,
        maxWidth: (props) => props.dropdownAutoWidth ? "80vw" : undefined
    },
    paper: {
        ...theme.typography.body1,
        overflow: "hidden",
        margin: 0,
        "& > ul": {
            maxHeight: "40vh",
            overflow: "auto",
        },
    },
    listbox: {
        listStyle: "none",
        margin: 0,
        padding: "8px 0px",
        position: "relative",
    },
    showAll: {
        padding: "2px 8px 0",
        marginBottom: "-4px",
        boxShadow: "none",
        transition: "none"
    },
    showAllLink: {
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    option: {
        minHeight: 48,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer",
        paddingTop: 6,
        boxSizing: "border-box",
        outline: "0",
        WebkitTapHighlightColor: "transparent",
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
        [theme.breakpoints.up("sm")]: {
            minHeight: "auto",
        },
        "&[aria-selected=\"true\"]": {
            backgroundColor: theme.palette.action.selected,
        },
        "&[data-focus=\"true\"]": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:active": {
            backgroundColor: theme.palette.action.selected,
        },
        "&[aria-disabled=\"true\"]": {
            opacity: theme.palette.action.disabledOpacity,
            pointerEvents: "none",
        }
    },
    listItemIcon: {
        display: "inline-flex",
        minWidth: "unset",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(3),
        verticalAlign: "middle"
    },
    inset: {
        paddingLeft: theme.spacing(4)
    },
    listItemText: {
        display: "inline-flex",
        margin: 0,
        "& span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        }
    },
    error: {
        marginBottom: theme.spacing(-1),
        marginLeft: theme.spacing(1)
    }
});

const useStyles = makeOverrideableStyles("AutocompleteSearch", styles);

export interface AutocompleteSearchProps<
    TChoice extends object,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends StyledComponentProps<typeof styles>, BaseAutocompleteSearchProps, Omit<UseAutocompleteProps<TChoice, Multiple, DisableClearable, FreeSolo>, "value" | "onChange" | "options"> {
    /**
     * array of choice objects to search in.
     */
    choices?: TChoice[],
    /**
     * callback called on the selection of a choice. Called with the selected choice's data.
     *
     * @function
     * @param {object} the choice selected
     */
    onChange: (selectedChoice: TChoice | AutocompleteGroupedOption<TChoice> | null) => void,
    /**
     * Property for rendering a subtext on each choice. A dot-notation path to the property in each choice object to display as a subtext in the dropdown.
     */
    optionSubText?: string,
    /**
     * Property for rendering the main text for each choice. A dot-notation path to the property in each choice object to display as text in the dropdown.
     */
    optionText: string
}

/**
 * Search field component, utilising material-ui/labs' useAutocomplete functionality.
 *
 * Any additional props provided that are not detailed below, are passed through to the useAutocomplete call.
 */
export const AutocompleteSearch = <
    TChoice extends object,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
>(props: AutocompleteSearchProps<TChoice, Multiple, DisableClearable, FreeSolo>) => {
    const {
        choices,
        clearOnFocus,
        disabled,
        error,
        icon,
        iconMap = {},
        id,
        label,
        maxOptionsVisible,
        noOptionsText,
        noSearchIcon,
        onChange,
        optionIcon,
        optionSubText,
        optionText,
        placeholder,
        ...otherProps
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const getChoiceName = (choice: TChoice | AutocompleteGroupedOption<TChoice>) => choice && get(choice, optionText) || "";
    const getChoiceSubText = (choice: TChoice | AutocompleteGroupedOption<TChoice>) => optionSubText && choice && get(choice, optionSubText) || "";
    const onChangeEvent = (event: any, choice: TChoice | AutocompleteGroupedOption<TChoice> | null) => onChange(choice);
    const [showAll, setShowAll] = useState(false);
    const {
        dirty,
        getRootProps,
        getInputProps,
        getClearProps,
        getPopupIndicatorProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        popupOpen,
        anchorEl,
        setAnchorEl,
        inputValue
    } = useAutocomplete<TChoice>({
        autoHighlight: true,
        // @ts-ignore It claims "filterProps" doesn't exist, which it does...
        filterProps: createFilterOptions({
            stringify: getChoiceName
        }),
        onChange: onChangeEvent,
        noOptionsText: translate(noOptionsText),
        options: choices || [],
        disabled,
        getOptionLabel: getChoiceName,
        ...otherProps
    });

    useDeepCompareEffect(() => {
        setShowAll(false);
    }, [popupOpen, groupedOptions]);

    return (
        <div className={classes.autocomplete} {...getRootProps()}>
            <TextField
                inputProps={{...getInputProps(), id}}
                label={label && translate(label)}
                placeholder={placeholder && translate(placeholder) || undefined}
                className={classes.autocompleteInput}
                variant="outlined"
                fullWidth
                error={!!error}
                disabled={disabled}
                onClick={(event) => {
                    if (clearOnFocus) {
                        getClearProps().onClick?.(event as unknown as React.MouseEvent<HTMLButtonElement>);
                    }
                }}
                InputProps={{
                    ref: setAnchorEl,
                    startAdornment: !noSearchIcon && (
                        <InputAdornment position="start">
                            <Search/>
                        </InputAdornment>
                    ) || undefined,
                    endAdornment: (
                        <div className={classes.endAdornment}>
                            {/*@ts-ignore its complaining about the prop color, which is not coming from our props, but getClearProps()*/}
                            {!clearOnFocus && <IconButton
                                size="small"
                                {...getClearProps()}
                                className={classNames(classes.clearIndicator, !(dirty && inputValue) && classes.clearIndicatorHidden)}
                                disabled={disabled}
                            >
                                <Close fontSize="small"/>
                            </IconButton>}
                            {/*@ts-ignore its complaining about the prop color, which is not coming from our props, but getClearProps()*/}
                            <IconButton
                                size="small"
                                {...getPopupIndicatorProps()}
                                className={classNames(classes.popupIndicator, popupOpen && classes.popupIndicatorOpen)}
                                disabled={disabled}
                            >
                                <SortDescending/>
                            </IconButton>
                        </div>
                    )
                }}
                autoComplete="off"
            />
            {popupOpen && anchorEl ? (
                <Popper
                    className={classes.popper}
                    style={{
                        width: anchorEl ? anchorEl.clientWidth : undefined,
                    }}
                    role="presentation"
                    anchorEl={anchorEl}
                    open
                >
                    <Paper className={classes.paper}>
                        {groupedOptions.length > 0 ? (
                            <ul
                                className={classes.listbox}
                                {...getListboxProps()}
                            >
                                {groupedOptions.slice(0, showAll ? undefined : maxOptionsVisible).map((choice, index) => {
                                    const choiceName = getChoiceName(choice);
                                    const subListText = optionSubText && getChoiceSubText(choice);
                                    const listIcon = iconMap && optionIcon && iconMap[get(choice, optionIcon) as string] || icon;
                                    return (
                                        <li
                                            //@ts-ignore this works, even though typescript says it should be "option" rather than choice...
                                            {...getOptionProps({choice, index})}
                                            className={classes.option}
                                            key={index}>
                                            {listIcon && (
                                                <ListItemIcon className={classes.listItemIcon}>
                                                    {listIcon}
                                                </ListItemIcon>
                                            )}
                                            {typeof choiceName === "string" ? (
                                                <ListItemText
                                                    primary={translate(choiceName)}
                                                    secondary={optionSubText && translate(subListText)}
                                                    className={classes.listItemText}
                                                    classes={{inset: classes.inset}}
                                                    inset={!!listIcon}
                                                />
                                            ) : choiceName}
                                        </li>
                                    );
                                })}
                                {groupedOptions.length > maxOptionsVisible && !showAll && (
                                    <Paper className={classes.showAll}>
                                        <Typography variant="caption">
                                            {translate("cuda.table.autocomplete.showing", {
                                                current: maxOptionsVisible,
                                                total: groupedOptions.length
                                            })}
                                            <a
                                                onClick={(event) => {
                                                    setShowAll(true);
                                                    event?.preventDefault?.();
                                                }}
                                                className={classes.showAllLink}
                                            >
                                                {translate("cuda.table.autocomplete.showAll")}
                                            </a>
                                        </Typography>
                                    </Paper>
                                ) || null}
                            </ul>
                        ) : null}
                    </Paper>
                </Popper>
            ) : null}
            {error ? (
                <FormHelperText error className={classes.error}>{formatErrorMessage(error)}</FormHelperText>
            ) : null}
        </div>
    );
};

AutocompleteSearch.defaultProps = {
    choices: [],
    iconMap: {},
    noOptionsText: "cuda.select.noOptions",
    onChange: () => {},
    optionText: "name",
    maxOptionsVisible: 10,
    noSearchIcon: false,
    placeholder: "cuda.table.autocomplete.search"
};

export default AutocompleteSearch;
import {Button, ClickAwayListener, Collapse, Grid, IconButton, Menu, MenuItem} from "@barracuda-internal/bds-core";
import {Theme} from "@mui/material";
import {Close, Filter} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {isEmpty, sortBy} from "lodash";
import React, {ChangeEvent, ReactNode, useCallback, useState} from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {useDeepCompareCallback} from "../../../hooks";
import {createStyles} from "@mui/styles";
import {TableFilterProps} from "./TableFilter";

const styles = (theme: Theme) => createStyles({
    buttonBar: {
        width: "100%",
        margin: theme.spacing(1, 3, 0, 1)
    },
    filterButton: {
        right: theme.spacing(1)
    },
    list: {},
    leftRightSeperator: {
        marginLeft: "auto"
    },
    filterBar: {
        position: "relative",
        display: "block",
        marginLeft: "auto",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    filterContainer: {
        width: "auto",
        marginLeft: theme.spacing(1)
    },
    filterField: {
        marginTop: theme.spacing(1),
        verticalAlign: "bottom",
        width: 256
    },
    filterFieldInput: {
        backgroundColor: theme.palette.background.paper
    },
    filterRemoveIcon: {
        marginBottom: 2
    },
    buttonLabel: {
        whiteSpace: "nowrap"
    },
    actionFullWidth: {
        width: "100%"
    }
});

const useStyles = makeOverrideableStyles("TableActionsAndFilters", styles);

type filterValuesType = Record<string, any>
type TableFilterElement = React.ReactElement<TableFilterProps<any>>;

export interface TableActionsAndFiltersProps extends StyledComponentProps<typeof styles> {
    /**
     * One or more components to render as actions. Usually these are buttons, but they can be any components you like.
     *
     * To align your action button/component to the left, wrap it inside a TableAction with the prop "left".
     *
     * To have your action component take up the full width, wrap it inside a TableAction with the prop "fullWidth".
     */
    actions?: ReactNode | ReactNode[],
    /**
     * array of filters to render.
     *
     * If no filters are provided, the "Add Filter" button is not rendered.
     *
     * Filters should be an array of basic inputs (such as Select, SelectArray, BDS' TextField, etc.) that expect a "value" and "onChange" prop.
     *
     * A "source" prop on the child must be present, and is used to get the "value" from the filterValues object, and to set the value when handling "onChange".
     * */
    filters?: (TableFilterElement | null)[],
    /**
     * object defining the current state of the filters. Filters that do not have an entry are assumed to not be "active".
     */
    filterValues?: filterValuesType,
    /**
     * handler for the onChange event when a filter is added, removed, or edited. It is called with a single argument, the object containing the new values of the filters (see filterValues)
     */
    onChange?: (filterValues: filterValuesType) => void
}

/**
 * A right-aligned transparent bar for displaying and handling action buttons and table filters.
 *
 * Used by ConnectedTable to render its provided actions and filters above the table.
 */
const TableActionsAndFilters = (props: TableActionsAndFiltersProps): JSX.Element => {
    const {onChange, filters, filterValues, actions} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [filterListOpen, setFilterListOpen] = useState<Element | null>(null);
    const activeFilters = Object.keys(filterValues || {});
    const filterArray = filters && sortBy(
        filters.filter((filter): filter is TableFilterElement => !!filter && "props" in filter),
        (filter) => filter.props.label && translate(filter.props.label)
    );

    const handleChange = useDeepCompareCallback((eventOrValue, source) => {
        const value = (eventOrValue && eventOrValue.target) ? eventOrValue.target.value : eventOrValue;
        const newValues = Object.assign({}, filterValues, {[source]: isEmpty(value) ? undefined : value});

        onChange?.(newValues);
    }, [filterValues]);

    const addFilter = useDeepCompareCallback((source) => {
        handleChange("", source);
        setFilterListOpen(null);
    }, [filterValues]);

    const removeFilter = useDeepCompareCallback((source) => {
        const newValues = Object.assign({}, filterValues);
        delete newValues[source];
        onChange?.(newValues);
    }, [filterValues]);

    const clearFilters = useCallback(() => {
        onChange?.({});
    }, []);

    const renderFilter = (targetChild: TableFilterElement) => (
        <Grid item container alignItems="flex-end" className={classes.filterContainer} key={targetChild.props.source}>
            <Grid item>
                {React.cloneElement<any>(targetChild, {
                    id: "cuda-filter-input-" + targetChild.props.source.replace(/[^a-zA-Z0-9]/g, "-"),
                    onChange: (eventOrValue: ChangeEvent<HTMLDivElement>) => handleChange(eventOrValue, targetChild.props.source),
                    value: filterValues?.[targetChild.props.source] || "",
                    label: targetChild.props.label && translate(targetChild.props.label),
                    variant: "outlined",
                    className: classes.filterField,
                    InputProps: {classes: {root: classes.filterFieldInput}}
                })}
            </Grid>
            <Grid item>
                <IconButton
                    size="small"
                    className={classes.filterRemoveIcon}
                    onClick={() => removeFilter(targetChild.props.source)}
                >
                    <Close/>
                </IconButton>
            </Grid>
        </Grid>
    );

    const showFilters = Object.keys(activeFilters || {}).length > 0;
    const arrayActions = actions && (Array.isArray(actions) ? actions : [actions]);
    const leftActions = arrayActions && arrayActions.filter((action) => action && action?.props?.left);
    const rightActions = arrayActions && arrayActions.filter((action) => action && !action?.props?.left);

    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
                className={classes.buttonBar}
                direction="row-reverse"
                alignItems="center"
                wrap="wrap-reverse"
            >
                {rightActions && rightActions.map((action, index) => (
                    <Grid item key={index} className={action?.props?.fullWidth ? classes.actionFullWidth : undefined}>
                        {action}
                    </Grid>
                )) || null}
                {filterArray && filterArray.length > 0 && (
                    <React.Fragment>
                        <Grid item>
                            <ClickAwayListener onClickAway={() => setFilterListOpen(null)}>
                                <React.Fragment>
                                    <Button
                                        className={classes.filterButton}
                                        startIcon={<Filter/>}
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                        onClick={(event) => setFilterListOpen(event.currentTarget)}
                                        disabled={filterArray.length === activeFilters.length}
                                    >
                                        {translate("cuda.buttons.filters.add")}
                                    </Button>
                                    <Menu
                                        className={classes.list}
                                        anchorEl={filterListOpen}
                                        open={!!filterListOpen}
                                        onClose={() => setFilterListOpen(null)}
                                    >
                                        {filterArray
                                            .filter((filter) => !activeFilters.includes(filter.props.source))
                                            .map((filter) => (
                                                <MenuItem
                                                    key={filter.props.source}
                                                    onClick={() => addFilter(filter.props.source)}
                                                >
                                                    {filter.props.label && translate(filter.props.label)}
                                                </MenuItem>
                                            ))}
                                    </Menu>
                                </React.Fragment>
                            </ClickAwayListener>
                        </Grid>
                        {showFilters ? (
                            <Grid item>
                                <Button
                                    onClick={() => clearFilters()}
                                    startIcon={<Close/>}
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    className={classes.filterButton}
                                    classes={{root: classes.buttonLabel}}
                                >
                                    {translate("cuda.buttons.filters.clear")}
                                </Button>
                            </Grid>
                        ) : null}
                    </React.Fragment>
                )}
                <div className={classes.leftRightSeperator}/>
                {leftActions && leftActions.map((action, index) => (
                    <Grid item key={index} className={action?.props?.fullWidth ? classes.actionFullWidth : undefined}>
                        {action}
                    </Grid>
                )) || null}
            </Grid>
            {filterArray && (
                <Collapse
                    className={classes.filterBar}
                    in={showFilters}
                >
                    <Grid container alignItems="flex-end" justifyContent="flex-end" spacing={1}>
                        {filterArray
                            .filter((filter) => activeFilters.includes(filter.props.source))
                            .map(renderFilter)
                        }
                    </Grid>
                </Collapse>
            )}
        </React.Fragment>
    );
};

export default TableActionsAndFilters;
import {Card, ConnectedForm, TextInput, validateIpv4} from "@cuda-react/core";
import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";
import {parseJson} from "../../../../utils/jsonUtils";
import {get} from "lodash";

export const useStyles = makeStyles((theme) => ({
    message: {
        padding: theme.spacing(2, 2, 2, 4)
    }
}));

export const DcAgent = () => {
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <Card title="tesseract.settings.tabs.dcAgent.title">
            <ConnectedForm
                formatRequestData={(data) => ({
                    settings: {
                        "dc_agent": JSON.stringify(data)
                    }
                })}
                formatResourceData={(data) => ({
                    ipAddress: parseJson(data?.settings?.["dc_agent"])?.ipAddress || ""
                })}
                formatError={(errors) => get(errors, "settings.dc_agent")}
                resource={apiResources.settings}
                params={{filter: {type: "virtualWan"}}}
            >
                <Typography className={classes.message}>
                    {translate("tesseract.settings.tabs.dcAgent.description")}
                </Typography>
                <TextInput
                    source="ipAddress"
                    label="tesseract.settings.tabs.dcAgent.ipAddress"
                    validate={validateIpv4}
                />
            </ConnectedForm>
        </Card>
    );
};

export default DcAgent;
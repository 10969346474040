import {LinearProgress} from "@barracuda-internal/bds-core";
import {createStyles, makeStyles} from "@mui/styles";
import {LinearProgressProps} from "@mui/material/LinearProgress/LinearProgress";
import React, {useEffect, useState} from "react";

export interface BorderLinearProgressProps extends LinearProgressProps {
    /** Accepts a string for the css property border radius */
    borderRadius?: string,
    /** Accepts a string for the css property border radius */
    barColor?: string,
    /** ClassName to provide to the LinerProgress */
    className?: string
}

const useStyles = makeStyles((theme) => createStyles<string, BorderLinearProgressProps>({
    root: {
        height: 15,
        borderRadius: ({borderRadius}) => borderRadius || 7.5,
        width: "100%"
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: ({borderRadius}) => borderRadius || 5,
        backgroundColor: ({barColor}) => barColor || '#1a90ff',
        animation: "translateX 500ms"
    },
}));


/**
 * A pre-styled LinearProgress bar with a border. Uses an internal state to delay changes to the value in order for the
 * graph animation to work.
 */
export const BorderLinearProgress = (props: BorderLinearProgressProps) => {
    const [shownValue, setShownValue] = useState(0);
    const classes = useStyles(props);

    useEffect(() => {
        setShownValue(props.value || 0);
    }, [props.value]);

    return <LinearProgress value={shownValue} variant="determinate" className={props.className} classes={classes}/>;
};
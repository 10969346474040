import {useTranslation} from "react-i18next";
import {ToggleButton, ToggleButtonGroup} from "@barracuda-internal/bds-core";
import {CardSizes} from "../../../typesAndConstants";
import React, {useEffect} from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {StyledComponentProps} from "@cuda-react/theme";
import {GridSizeIcon} from "@cuda-react/icons";
import {GridSizes} from "@cuda-react/icons/lib/stratosIcons/GridSizeIcon/GridSizeIcon";

const styles = (theme: Theme) => createStyles({
    toggleButtonLarge: {
        textTransform: "none",
        height: 28,
        color: `${theme.palette.text.primary} !important`,
        fontSize: 16,
        width: "33%",
        "&:disabled": {
            color: "#DDDDDD !important",
            backgroundColor: "#AAAAAA"
        }
    },
    toggleGroup: {
        width: 320,
        paddingTop: theme.spacing(0.5)
    }
});
const useStyles = makeStyles(styles);

export interface SizeSelectProps extends StyledComponentProps<typeof styles> {
    value?: CardSizes,
    onChange?: (value: CardSizes) => void,
    forceSmall?: boolean
}

/** Toggle group button for selecting a Card Size */
export const SizeSelect = (props: SizeSelectProps) => {
    const {value, onChange, forceSmall} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);

    useEffect(() => {
        if (value === undefined) {
            onChange?.(CardSizes.small);
        }
    }, [value, onChange]);

    return (
        <ToggleButtonGroup
            aria-label="outlined primary button group"
            exclusive
            value={value}
            onChange={(event, value) => value && onChange?.(value)}
            className={classes.toggleGroup}
        >
            <ToggleButton
                classes={{sizeLarge: classes.toggleButtonLarge}}
                value={CardSizes.small}
                size="large"
            >
                <GridSizeIcon size={GridSizes.small}/> &nbsp;
                {translate("stratosReporting.previewDialog.small")}
            </ToggleButton>
            <ToggleButton
                value={CardSizes.medium}
                classes={{sizeLarge: classes.toggleButtonLarge}}
                disabled={forceSmall}
                size="large"
            >
                <GridSizeIcon size={GridSizes.medium}/> &nbsp;
                {translate("stratosReporting.previewDialog.medium")}
            </ToggleButton>
            <ToggleButton
                value={CardSizes.large}
                classes={{sizeLarge: classes.toggleButtonLarge}}
                disabled={forceSmall}
                size="large"
            >
                <GridSizeIcon size={GridSizes.large}/> &nbsp;
                {translate("stratosReporting.previewDialog.large")}
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
import React from "react";
import {TextInput, validateCIDR} from "@cuda-react/core";

const SdwanConnectorPoolNetworkStep = () => (
        <React.Fragment>
            <TextInput
                source="clientNetworkConfiguration.range"
                label="tesseract.network.sdwanConnector.dialog.poolNetwork.network"
                description="tesseract.network.sdwanConnector.dialog.description.poolNetwork.network"
                isRequired
                validate={validateCIDR}
            />
        </React.Fragment>
    );

SdwanConnectorPoolNetworkStep.propTypes = {};

export default SdwanConnectorPoolNetworkStep;
import React from "react";
import {ResourceStatisticsCard} from "../../baseCards";
import {DashboardCardProps} from "../../../typesAndConstants";
import generatePreviewData from "../../../utils/previewDataGenerators/zeroTrustUsers";
import {useCardFetch} from "../../../hooks";
import {CustomField, StatusIconField, TextField} from "@cuda-react/core";
import {Help} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {Success} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import apiResources from "../../../apiResources";
import {useTheme} from "@mui/material";

export interface UserStatusCardProps extends DashboardCardProps {
}

/**
 * Card that displays an overview of User online status
 *
 * This Card shows in an overview the total number of user, the enrolled and the pending ones .
 *
 * In the large size may also show a table with the top five users per time online.
 *
 */
export const UserStatusCard = (props: UserStatusCardProps) => {
    const {preview, size} = props;
    const {data = {}} = useCardFetch({url: !preview ? apiResources.zeroTrustUsers : undefined});
    const {total, enrolled, pending} = (preview ? generatePreviewData() : data) || {};
    const theme = useTheme();
    const overviewData = [
        {title: "stratosReporting.userStatusCard.enrolled", value: enrolled, color: theme.palette.success.main},
        {title: "stratosReporting.userStatusCard.pending", value: pending, color: theme.palette.info.main},
    ];

    return (
        <ResourceStatisticsCard
            overViewValue={total}
            overviewTitle="stratosReporting.userStatusCard.title"
            overViewData={overviewData}
            preview={preview}
            size={size}
            resource={apiResources.userStatus}
            tableTitle="stratosReporting.userStatusCard.tableTitle"
        >
            <TextField source="fullName" label="stratosReporting.userStatusCard.name"/>
            <TextField source="email" label="stratosReporting.userStatusCard.email"/>
            <TextField source="deviceCount" label="stratosReporting.userStatusCard.deviceCount"/>
            <CustomField
                source="deviceCount"
                label="stratosReporting.userStatusCard.enrollmentCompleted.label"
                render={(value, data) => data && (data?.deviceCount > 0 ? (
                    <StatusIconField icon={<Success style={{color: theme.palette.success.main}}/>} tooltip="stratosReporting.userStatusCard.enrollmentCompleted.yes"/>
                ) : (
                    <StatusIconField icon={<Help/>} tooltip="stratosReporting.userStatusCard.enrollmentCompleted.no"/>
                ))}
                columnProps={{
                    field: "deviceCount"
                }}
            />
        </ResourceStatisticsCard>
    );
};
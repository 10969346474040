import React from "react";
import {FormSection} from "@cuda-react/core";
import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import LanTableInput from "../../inputs/LanTableInput";
import RoutesTableInput from "../../inputs/RoutesTableInput";
import {getNetworkId} from "../../../../../hooks/createEditSiteHooks";
import {useWatch} from "react-hook-form";

interface PortsData {
    port: string;
    available: boolean;
}

interface LansTabProps {
    portsData: PortsData[];
}

const useStyles = makeStyles((theme: Theme) => ({
    tableWarning: {
        color: theme.palette.warning.main,
        marginLeft: theme.spacing(2),
    },
}));
const LansTab: React.FC<LansTabProps> = ({portsData}) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const lans = useWatch({name: "lans"}) || [];
    const activeLans = lans.map((lan: any) => ({
        ...lan,
        networkId: getNetworkId(lan),
    }));
    const tableRowDimmed = (row: any) =>
        portsData && !portsData.some((applianceInterface) => applianceInterface.port === row.port && applianceInterface.available);
    const lanHasPortDisabled = activeLans.some(tableRowDimmed);
    return (
        <React.Fragment>
            <FormSection title="tesseract.appliances.settings.lan.sectionTitle">
                <LanTableInput tableRowDimmed={tableRowDimmed} portsData={portsData}/>
                {lanHasPortDisabled && (
                    <Typography className={classes.tableWarning}>
                        {translate("tesseract.appliances.settings.lan.portWarning")}
                    </Typography>
                )}
            </FormSection>
            <FormSection title="tesseract.appliances.settings.route.sectionTitle">
                <RoutesTableInput/>
            </FormSection>
        </React.Fragment>
    );
};

export default LansTab;
import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";

interface ZtnaBarracudaIconProps {
    className?: string,
    height?: number,
    width?: number,
    fillColor?: string
}

const useStyles = makeStyles(() => ({
    root: {
            height: "80px",
            width: "100px"
    }
}));

export const ZtnaBarracudaIcon = (props: ZtnaBarracudaIconProps) => {
    const {className, fillColor} = props;
    const classes = useStyles(props);
    return (
        <SvgIcon
        className={classNames(className, classes.root, "cuda-react-icon")}
        id="cuda-icon-ztna-barracuda-logo"
        fill="none"
        >
            <path
                d="M9.42785 24.7029H5.00049C5.00772 17.6792 6.86505 13.2183 6.86505 13.2183C6.27202 20.7759 9.42785 24.7029 9.42785 24.7029ZM27.3089 8.0625C27.3089 8.0625 13.2517 13.6148 10.6461 24.7029H22.4798C22.4792 24.7029 13.9808 22.4767 27.3089 8.0625Z"
                style={{fill: fillColor ?? "#52667A", height: "50px", width: "50px"}}
            />

        </SvgIcon>
    );
};

export default ZtnaBarracudaIcon;
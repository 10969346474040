import {Theme} from "@mui/material";
import {flatten, get, isEqual, uniq} from "lodash";
import {ChartRef} from "./UseLocationsMap";
import {Series} from "highcharts";
import {CustomMapPointOrCluster} from "./zoomToFit";
import {SeriesMaplineOptions} from "highcharts/highcharts.src";
import {CustomMapPoint} from "./getLinkedPoints";

export type SpokeSeries = Series & {
    markerClusterInfo?: {
        clusters: CustomMapPointOrCluster[]
    },
    options: {
        data: CustomMapPoint[]
    }
}
/**
 * Draws/redraws linked line series to provided chart, connecting the linked hub to each spoke
 */
export const drawLinkedLines = (chart: ChartRef, theme: Theme, redraw: boolean = true) => {
    const spokeSeries = chart.current && chart.current.series.find((series) => series.userOptions.id === "linked-points-point-series" && series.visible) as SpokeSeries | undefined;
    const spokeClusters = spokeSeries?.markerClusterInfo?.clusters;
    const spokeData = spokeSeries?.options?.data;
    const hubData: any = get(chart.current?.series?.find?.((series) => series.userOptions.id === "linked-points-source-point-series" && series.visible), "options.data");

    if (!hubData || hubData.length === 0 || !spokeData || spokeData.length === 0) {
        return;
    }

    // if the spoke is in a cluster then use the cluster position
    // also ensures only one line is drawn to each cluster
    const lineTargets = uniq(
        spokeData.map(
            (spoke) => spokeClusters && spokeClusters.find(
                (cluster) => cluster.data && cluster.data.some(
                    (clusterData) => spoke.x === clusterData.x && spoke.y === clusterData.y
                )
            ) || spoke
        )
    );

    const data = lineTargets.map((spoke) => ({
        path: ["M", hubData[0].x, hubData[0].y, "L", spoke.x, spoke.y, "Z"]
    }));

    const existingLineSeries = chart.current && chart.current.series.find((series) => series.userOptions.id === "linked-points-line-series") as Series;
    const existingData = get(existingLineSeries, "options.data", []) as SeriesMaplineOptions["data"];

    // @ts-ignore We don't want to update the series if it is already visible with matching data
    if (existingLineSeries && existingLineSeries.visible && existingData?.length === data.length && existingData?.every(({path}, index) => isEqual(flatten(path), data[index].path))) {
        return;
    }

    if (existingLineSeries) {
        // @ts-ignore
        existingLineSeries.update({type: "mapline", data, visible: true}, redraw);
    } else {
        chart.current?.addSeries?.({
            type: "mapline",
            id: "linked-points-line-series",
            color: theme.palette.info.main,
            lineWidth: 2,
            enableMouseTracking: false,
            showInLegend: false,
            // @ts-ignore
            data
        }, redraw);
    }
};
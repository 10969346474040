import {SvgIcon} from "@barracuda-internal/bds-core";
import React from "react";
import {useTheme} from "@mui/material";
import classNames from "classnames";

export const EnrolledDevicesGraphic = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
    <SvgIcon
        className={classNames(className, "cuda-react-graphic")}
        id="cuda-graphic-enrolleddevices"
        viewBox="0 0 128 128"
    >
        <rect id="ArtBoard1" x="0" y="0" width="128" height="128" style={{fill: "none"}}/>
        <g>
            <g transform="matrix(2.10914,0,0,2.10914,-0.000421828,17.295)">
                <g>
                    <g transform="matrix(-1,0,0,1,48.5936,49.3382)">
                        <path
                            d="M0,-24.012C6.627,-24.012 12.006,-18.633 12.006,-12.006C12.006,-5.379 6.627,0 0,0C-6.627,0 -12.006,-5.379 -12.006,-12.006C-12.006,-18.633 -6.627,-24.012 0,-24.012"
                            style={{fill: theme.palette.neutral?.main, fillRule: "nonzero"}}/>
                    </g>
                    <g transform="matrix(-1,0,0,1,48.5936,49.3382)">
                        <path
                            d="M0,-24.012C6.627,-24.012 12.006,-18.633 12.006,-12.006C12.006,-5.379 6.627,0 0,0C-6.627,0 -12.006,-5.379 -12.006,-12.006C-12.006,-18.633 -6.627,-24.012 0,-24.012"
                            style={{fill: theme.palette.neutral?.main, fillRule: "nonzero"}}/>
                    </g>
                    <g transform="matrix(1,0,0,1,31.2532,2.8917)">
                        <path
                            d="M0,34.244L-9.639,34.244C-10.513,34.244 -11.242,33.659 -11.479,32.861L1.84,32.861C1.604,33.659 0.874,34.244 0,34.244M3.334,34.44C3.334,32.648 3.686,30.938 4.302,29.361L-21.615,29.361C-22.643,29.361 -23.479,28.525 -23.479,27.497L-23.479,2.473C-23.479,1.445 -22.643,0.608 -21.615,0.608L11.976,0.608C13.005,0.608 13.84,1.445 13.84,2.473L13.84,20.895C14.961,20.605 16.13,20.435 17.34,20.435L17.34,2.473C17.34,-0.485 14.934,-2.892 11.976,-2.892L-21.615,-2.892C-24.573,-2.892 -26.979,-0.485 -26.979,2.473L-26.979,27.497C-26.979,29.624 -25.725,31.449 -23.927,32.316L-31.253,32.316C-31.253,34.978 -29.095,37.136 -26.434,37.136L3.601,37.136C3.43,36.263 3.334,35.363 3.334,34.44"
                            style={{fill: theme.palette.icons?.graphics}}/>
                    </g>
                    <g transform="matrix(1,0,0,1,0,25.1058)">
                        <path
                            d="M47.205,15.825L49.583,15.825L49.583,18.22L47.205,18.22L47.205,15.825ZM47.188,14.977C47.188,14.611 47.23,14.245 47.313,13.879C47.402,13.513 47.532,13.18 47.704,12.881C47.842,12.598 47.992,12.377 48.153,12.216C48.319,12.049 48.508,11.88 48.718,11.708C48.935,11.537 49.19,11.295 49.483,10.985C49.711,10.735 49.86,10.492 49.933,10.253C50.01,10.009 50.049,9.743 50.049,9.455C50.049,9.305 50.032,9.161 49.999,9.022C49.971,8.884 49.916,8.762 49.833,8.656C49.7,8.473 49.522,8.343 49.301,8.266C49.084,8.182 48.851,8.141 48.602,8.141C48.413,8.141 48.228,8.163 48.045,8.207C47.867,8.252 47.715,8.324 47.587,8.424C47.432,8.54 47.316,8.687 47.238,8.864C47.166,9.042 47.133,9.239 47.138,9.455L44.743,9.455C44.81,8.862 44.979,8.318 45.25,7.825C45.522,7.331 45.874,6.943 46.307,6.66C46.623,6.45 46.972,6.289 47.355,6.178C47.737,6.067 48.12,6.012 48.502,6.012C49.134,6.012 49.73,6.112 50.29,6.311C50.85,6.505 51.313,6.827 51.679,7.276C51.923,7.559 52.111,7.889 52.244,8.266C52.378,8.637 52.444,9.011 52.444,9.388C52.444,9.993 52.308,10.541 52.037,11.035C51.765,11.528 51.43,11.988 51.03,12.415C50.858,12.598 50.703,12.759 50.565,12.898C50.432,13.031 50.31,13.158 50.199,13.28C50.093,13.397 49.999,13.519 49.916,13.646C49.761,13.901 49.666,14.109 49.633,14.27C49.6,14.431 49.583,14.666 49.583,14.977L47.188,14.977Z"
                            style={{fill: theme.palette.common.white, fillRule: "nonzero"}}/>
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
     );
};

export default EnrolledDevicesGraphic;
import {Dashboard} from "../../../hooks/useDashboards";
import {
    DialogBody,
    Form,
    TextInput,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import React from "react";

export interface RenameDashboardDialogProps {
    /** Current dashboard (leave undefined for create). */
    dashboard?: Dashboard,
    /** callback called on save. */
    addUpdateDashboard: (newDashboardSettings: Dashboard, originalDashboardName?: string) => Promise<any>,
    /** callback called on cancel/dialog close/after saving */
    onClose?: () => void,
    /** not used for this component, but for setting the menu button entry label **/
    label?: string,
}

/**
 * Dialog content for the Create/Edit Dashboard dialog.
 */
export const RenameDashboardDialog = ({
                                              dashboard,
                                              addUpdateDashboard,
                                              onClose,
                                          }: RenameDashboardDialogProps) => (
    <DialogBody
        title={"stratosReporting.dashboardPage.rename"}
        onClose={onClose}
        form
    >
        <Form
            initialValues={dashboard}
            onCancel= {onClose}
            save={
                ({...newDashboard}: any) => addUpdateDashboard(newDashboard, dashboard?.name)
                    .then((result) => {
                        onClose?.();
                        return result;
                    })
            }
        >
            <TextInput
                source="name"
                label="stratosReporting.dashboardPage.name"
                isRequired
                validate={[validateMaxLengthMemo(32), validateRegexMemo(/^[a-zA-Z0-9 ]+$/, "stratosReporting.dashboardPage.validateName")]}
            />
        </Form>
    </DialogBody>
);
import React, {useState} from "react";
import {ConnectedWizard, InputStep, ResultStep, SubmitStep, useGlobalParam} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import BasicPolicyInputs, {defaultPolicy} from "./BasicPolicyInputs";
import DeviceAttributesInputs, {defaultDeviceAttributes, validateDeviceAttributes} from "./DeviceAttributesInputs";
import {formatError, formatRequestData} from "./FormattingMethods";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../../apiResources";
import {get} from "lodash";

const useStyles = makeStyles({
    dialogContent: {
        width: "auto"
    },
    stepContent: {
        maxHeight: "calc(90vh - 195px)",
        overflowY: "auto",
        width: 730
    }
});

interface CreatePolicyProps {
    onClose?: any,
    onSuccess?: any
}

export const CreatePolicy: React.FC<CreatePolicyProps> = ({onClose, onSuccess}) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [securityPosture, setSecurityPosture] = useState();

    return (
        <ConnectedWizard
            title="tesseract.endpoint.remoteAccessPolicies.create"
            resource={apiResources.zeroTrustPolicies}
            formatRequestData={(data) => ({
                ...formatRequestData(data),
                virtualWanId
            })}
            initialValues={{
                ...defaultPolicy,
                ...defaultDeviceAttributes
            }}
            formatError={formatError}
            onClose={onClose}
            onSubmitSuccess={onSuccess}
            dialogBodyProps={{classes: {dialogContent: classes.dialogContent}}}
            onChange={(values) => {
                setSecurityPosture(get(values, "accessCriteria.securityPosture"));
            }}
            validate={validateDeviceAttributes(translate)}
            // @ts-ignore
            create
        >
            <InputStep
                label="tesseract.endpoint.remoteAccessPolicies.policy"
                submit={securityPosture === "disable"}
                // @ts-ignore
                classes={{root: classes.stepContent}}
            >
                <BasicPolicyInputs/>
            </InputStep>
            {(securityPosture !== "disable") ? (
                <SubmitStep label="tesseract.endpoint.remoteAccessPolicies.deviceAttributes"
                    // @ts-ignore
                            classes={{root: classes.stepContent}}>
                    <DeviceAttributesInputs/>
                </SubmitStep>
            ) : null}
            <ResultStep label="tesseract.endpoint.remoteAccessPolicies.success" onClose={onClose}
                // @ts-ignore
                        disableDirectNavigation>
                <Typography>{translate("tesseract.endpoint.remoteAccessPolicies.successMessage")}</Typography>
            </ResultStep>
        </ConnectedWizard>
    );
};


export default CreatePolicy;
import React from "react";
import {StepIcon as MuiStepIcon, StepIconProps as MuiStepIconProps, Theme} from "@mui/material";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    root: {
        // "&$active:not($error)": {
        //     color: theme.palette.primary.dark
        // }
    },
    active: {},
    error: {}
});
const useStyles = makeOverrideableStyles("StepIcon", styles);

export interface StepIconProps extends StyledComponentProps<typeof styles>, Omit<MuiStepIconProps, "classes"> {
    /**
     * the value to display in the icon, representing the current step. Values of 0 or less are ignored, and no value is rendered.
     */
    icon: number,
    /**
     * if true, the step is treated as a "substep" (so step number is reduced by 1).
     */
    subStep?: boolean
}

/**
 * Icon used by the Wizard component.
 *
 * Simply a modified version of material-ui's StepIcon. All other props not defined below are passed to the material-ui StepIcon.
 */
export const StepIcon: React.FC<StepIconProps> = (props) => {
    const {icon, subStep, classes: _classOverride, ...stepIconProps} = props;
    const classes = useStyles(props);
    const iconValue = subStep ? (icon - 1) : icon;

    return (
        <MuiStepIcon {...stepIconProps} classes={classes} icon={iconValue > 0 ? iconValue : ""}/>
    );
};

export default StepIcon;
import {
    BooleanInput,
    Card,
    ConnectedForm,
    TextInput,
    validateInt,
    validateMaxValueMemo,
    validateMinValueMemo
} from "@cuda-react/core";
import React from "react";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import apiResources from "../../../../apiResources";
import {get} from "lodash";
import {Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";

export const useStyles = makeStyles((theme: Theme) => ({
    message: {
        padding: theme.spacing(2, 2, 2, 4)
    }
}));

export const SIPProxy = () => {
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <Card title="tesseract.settings.tabs.SIPProxy.title">
            <ConnectedForm
                formatRequestData={(data) => ({
                    settings: {
                        sip: JSON.stringify({
                            enabled: data?.enabled ?? true,
                            udpTimeout: parseInt(data?.udpTimeout, 10) || 60
                        })
                    }
                })}
                formatResourceData={(data) => {
                    const sipData = JSON.parse(data?.settings?.sip || '{}');
                    return {
                        enabled: sipData.enabled ?? true,
                        udpTimeout: sipData.udpTimeout ?? 60
                    };
                }}
                formatError={(errors) => get(errors, "sip")}
                resource={apiResources.settings}
                params={{filter: {type: "virtualWan"}}}
            >
                <Typography className={classes.message}>
                    {translate("tesseract.settings.tabs.SIPProxy.cardDescription")}
                </Typography>
                <BooleanInput
                    source="enabled"
                    label="tesseract.settings.tabs.SIPProxy.sipProxySetting"
                    description="tesseract.settings.tabs.SIPProxy.description.sipProxySetting"
                    isRequired
                />
                <TextInput
                    source="udpTimeout"
                    label="tesseract.settings.tabs.SIPProxy.UdpTimeout"
                    description="tesseract.settings.tabs.SIPProxy.description.UdpTimeout"
                    validate={[validateInt, validateMaxValueMemo(300), validateMinValueMemo(30)]}
                    disable={(value, data) => (data && (data.enabled))}
                    isRequired
                />
            </ConnectedForm>
        </Card>
    );
};

export default SIPProxy;
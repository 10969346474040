import {previewDataGenerator} from "../../typesAndConstants";

const dataGenerator: previewDataGenerator = () => (
    {
        total: 100,
        enrolled: 85,
        pending: 15,
    }
);

export default dataGenerator;
import React, {useRef, useState} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTableRefresh,
    CrudTypes,
    CustomField,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    getArrayDataContent,
    StatusIconField,
    TablePage,
    TextField,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import {CircularProgress, Dialog} from "@barracuda-internal/bds-core";
import CreateRule from "./createEdit/CreateRule";
import EditRule from "./createEdit/EditRule";
import {BooleanIcon, GetStartedBackgroundGraphic, IPsecConnectionGraphic} from "@cuda-react/icons";
import IpsecLocalPeerFilter from "./IpsecLocalPeerFilter";
import Ipsecv2TableMenu from "./Ipsecv2TableMenu";

const IpsecV2 = (props: any) => {
    const registerAction = useMixpanel("IpsecV2");
    const ipSecTableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const ipsecSiteWans = getArrayDataContent(useCrudSubscription(CrudTypes.GET, apiResources.ipsecApplicableSitesWans)[0]?.data);
    const ipsecGatewayWans = getArrayDataContent(useCrudSubscription(CrudTypes.GET, apiResources.ipsecApplicableGatewaysWans)[0]?.data);
    const generatePeerData = (uuid: string, data: { local: { type: string, wan: string; }; }) => {
        const appliancesListType = (data?.local?.type === "gateway" ? ipsecGatewayWans : ipsecSiteWans);
        const appliance = Array.isArray(appliancesListType) ? appliancesListType.find((peer) => peer?.uuid === uuid) : appliancesListType;
        const wan = appliance?.wans.find((peerWan: {
            key: string;
            name: string;
        }) => peerWan?.key === data?.local?.wan);

        return appliance ? appliance?.name + (wan ? ` (${wan?.name})` : '') : <CircularProgress/>;
    };

    return (
        <>
            <TablePage
                titleActions={actionVisible &&
                    <CreateEditButtonDialog
                        create
                        component={CreateRule}
                        label="tesseract.integration.ipSecV2.createButton"
                        onClick={() => setCreateOpen(true)}
                        onSuccess={() => ipSecTableRefreshRef.current?.()}
                    />
                }
                authenticated
                resource={apiResources.ipsecV2}
                title="tesseract.integration.pageTitle"
                subtitle="tesseract.integration.ipSecV2.pageTitle"
                minCellWidth={90}
                tableName="IPsecVPN"
                resetOnGlobalParamChange
                refreshRef={ipSecTableRefreshRef}
                resizable
                reorderable
                editColumns
                params={(params: any) => ({
                    ...params,
                    filter: params?.filter ? {
                        ...params.filter,
                        "localConfigurationUuid": params?.filter?.["local.uuid"]?.uuid,
                        "localConfigurationType": params?.filter?.["local.uuid"]?.["peer-type"]
                    } : undefined
                })}
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<IPsecConnectionGraphic/>}
                        iconHeader="tesseract.integration.ipSecV2.noData.iconHeader"
                        textHeader="tesseract.integration.ipSecV2.noData.textHeader"
                        text="tesseract.integration.ipSecV2.noData.text"
                        actions={
                            <DisableableButton
                                buttonText="tesseract.integration.ipSecV2.noData.buttonText"
                                onClick={() => setCreateOpen(true)}
                            />
                        }
                    />
                }
                onPageStatusChange={(pageStatus: string) => setActionVisible(pageStatus === "table")}
                {...props}
            >
                {/* COMMENTED FOR NOW - BACKEND WILL BE IMPLEMENTED IN FUTURE */}
                {/*<StatusIconField*/}
                {/*    source="aggregateState"*/}
                {/*    alwaysVisible*/}
                {/*    width={80}*/}
                {/*/>*/}
                <TextField
                    source="name"
                    label="tesseract.integration.ipSecV2.table.name"
                    filter="text"
                    sortable
                />
                <TextField
                    source="description"
                    label="tesseract.integration.ipSecV2.table.description"
                    hideByDefault
                />
                <StatusIconField
                    source="enabled"
                    label="tesseract.integration.ipSecV2.table.enabled"
                    filter="select"
                    filterProps={{
                        choices: [
                            {
                                key: "true",
                                name: "tesseract.integration.ipSecV2.table.filters.enabled.enabled"
                            },
                            {
                                key: "false",
                                name: "tesseract.integration.ipSecV2.table.filters.enabled.disabled"
                            }]
                    }}
                    icon={<BooleanIcon/>}
                    width={90}
                />
                <CustomField
                    source="local.uuid"
                    label="tesseract.integration.ipSecV2.table.siteWanInterface"
                    render={(value, data) => (generatePeerData(value, data))}
                    filter="custom"
                    filterProps={{
                        component: IpsecLocalPeerFilter,
                        ipsecSiteWans,
                        ipsecGatewayWans
                    }}

                />
                <ChipArrayField
                    source="remotes"
                    label="tesseract.integration.ipSecV2.table.gateway"
                    render={(value) => value?.gateway}
                />
                <TextField
                    source="type"
                    label="tesseract.integration.ipSecV2.table.type"
                    defaultText="IPSec IKEv2"
                    sortable
                />
                <ChipArrayField
                    source="local.networks"
                    label="tesseract.integration.ipSecV2.table.localNetworks"
                    render={(value) => (value)}
                    hideByDefault
                />
                <ChipArrayField
                    source="local"
                    label="tesseract.integration.ipSecV2.table.localBgp"
                    render={(value) => value.bgpAddress ? value.bgpAddress + " - " + value.asn : []}
                    hideByDefault
                />
                <ChipArrayField
                    source="remotes[0].gateway"
                    label="tesseract.integration.ipSecV2.table.remoteNetworks"
                    render={(value, data) => data?.remotes?.map((remote: {
                        networks: string[]
                    }) => remote.networks ? remote.networks : [])}
                    hideByDefault
                />
                <ChipArrayField
                    source="remotes[0]"
                    label="tesseract.integration.ipSecV2.table.remoteBgp"
                    render={(value, data) => data?.remotes.map((remote: {
                        bgpAddress: string,
                        asn: string
                    }) => remote.bgpAddress ? remote.bgpAddress + " - " + remote.asn : [])}
                    hideByDefault
                />
                <ActionButtonsField
                    width={120}
                    source="id"
                    alwaysVisible
                >
                    <Ipsecv2TableMenu
                        onSuccess={() => {
                            registerAction("Restart Tunnel");
                            ipSecTableRefreshRef.current?.();
                        }}
                    />
                    <CreateEditButtonDialog
                        component={EditRule}
                        onSuccess={() => {
                            registerAction("Update");
                            ipSecTableRefreshRef.current?.();
                        }}
                    />
                    <DeleteDialog
                        resource={apiResources.ipsecV2}
                        title="tesseract.integration.ipSecV2.dialog.delete.title"
                        message="tesseract.integration.ipSecV2.dialog.delete.body"
                        onSuccess={() => {
                            registerAction("IPSec Tunnel Delete");
                            ipSecTableRefreshRef.current?.();
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                disableEnforceFocus
            >
                <CreateRule
                    onSuccess={() => {
                        registerAction("Create");
                        ipSecTableRefreshRef.current?.();
                    }}
                    onClose={() => setCreateOpen(false)}
                />
            </Dialog>
        </>
    );
};

export default IpsecV2;
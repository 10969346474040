import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";
import classNames from "classnames";

const LiveLogConnectionNotEstablishedIcon = ({className}: { className?: string }) => {
    const theme = useTheme();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-access-live-log-connection-not-established"
            viewBox="0 0 60 60"
        >
            <g>
                <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                      d="M41.5,25.7c.7-.1,1.4-.2,2.1-.2,1.5,0,3,.2,4.3.7l6-6-12.3-12.3-3.5,3.4,6.4,6.4H7.3v4.9h37.3l-3.1,3.1Z"/>
                <path style={{fill: theme.palette.icons?.graphics, strokeWidth: 0}}
                      d="M29.5,42.3c-.1-.8-.2-1.6-.2-2.5s0-1.7.2-2.5h-15.3l6.4-6.4-3.5-3.4-12.3,12.3,12.3,12.3,3.5-3.4-6.4-6.4h15.3Z"/>
            </g>
            <path style={{fill: theme.palette.risk?.high, strokeWidth: 0}}
                  d="M43.6,28.2c-6.4,0-11.6,5.2-11.6,11.6s5.2,11.6,11.6,11.6,11.6-5.2,11.6-11.6-5.2-11.6-11.6-11.6ZM49.1,43.5l-1.8,1.8-3.7-3.7-3.7,3.7-1.8-1.8,3.7-3.7-3.7-3.7,1.8-1.8,3.7,3.7,3.7-3.7,1.8,1.8-3.7,3.7,3.7,3.7Z"/>
        </SvgIcon>
    );
};

export default LiveLogConnectionNotEstablishedIcon;
import React, {useRef} from "react";
import apiResources from "../../../apiResources";
import {
    ActionButtonsField,
    ConnectedTableRefresh,
    DateField,
    DeleteDialog,
    EmptyPageContent,
    TablePage,
    TextField,
    useMixpanel
} from "@cuda-react/core";
import {createdRanges as enrolledRanges} from "../../logsAndReporting/auditLog/AuditLog";
import {EnrolledDevicesGraphic, NothingFoundBackgroundGraphic} from "@cuda-react/icons";

const DevicesTable = () => {
    const registerAction = useMixpanel("Devices");
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);

    return (
        <TablePage
            authenticated
            title="tesseract.endpoint.pageTitle"
            subtitle="tesseract.endpoint.devices"
            resource={apiResources.zeroTrustDevices}
            minCellWidth={144}
            tableName="EndpointDevices"
            pageMode="paginate"
            resetOnGlobalParamChange
            resizable
            reorderable
            editColumns
            refreshRef={tableRefreshRef}
            exportResource={{resource: apiResources.zeroTrustDevicesExport, filename: "devices"}}
            noDataPage={
                <EmptyPageContent
                    background={<NothingFoundBackgroundGraphic/>}
                    icon={<EnrolledDevicesGraphic/>}
                    iconHeader="tesseract.devices.noData.iconHeader"
                    textHeader="tesseract.devices.noData.textHeader"
                    text="tesseract.devices.noData.text"
                />
            }
        >
            <TextField
                source="userFullName"
                label="tesseract.devices.table.userName"
                filter="text"
                sortable
            />
            <TextField
                source="userEmail"
                label="tesseract.devices.table.email"
                sortable
            />
            <TextField
                source="brand"
                label="tesseract.devices.table.brand"
            />
            <TextField
                source="model"
                label="tesseract.devices.table.model"
            />
            <TextField
                source="hardwareModel"
                label="tesseract.devices.table.hardwareModel"
                hideByDefault
            />
            <TextField
                source="operatingSystem"
                label="tesseract.devices.table.os"
                filter="text"
            />
            <TextField
                source="operatingSystemFlavor"
                label="tesseract.devices.table.osFlavour"
                filter="text"
                hideByDefault
            />
            <TextField
                source="operatingSystemVersion"
                label="tesseract.devices.table.osVersion"
            />
            <DateField
                source="enrollmentDate"
                label="tesseract.devices.table.enrollmentDate"
                filter="select"
                filterProps={{
                    choices: enrolledRanges
                }}
                sortable
            />
            <ActionButtonsField
                source="id"
                width={48}
                alwaysVisible
            >
                <DeleteDialog
                    resource={apiResources.zeroTrustDevices}
                    title="tesseract.devices.delete.title"
                    message="tesseract.devices.delete.body"
                    onSuccess={() => {
                        registerAction("Delete Device");
                        tableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default DevicesTable;
import {Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";

export const styles = createStyles({
    typography: {
        height: "fit-content",
        padding: "0.5rem 1rem",
        textAlign: "center"
    }
});
const useStyles = makeOverrideableStyles("NoDataMessage", styles);


export interface NoDataMessageProps extends StyledComponentProps<typeof styles> {
    /**
     * the message to display.
     */
    message?: string | { message: string }
}

/**
 * Simple center aligned message for when there is no data (or messages).
 *
 * Commonly used with *CardContent components and ConnectedTable when no data is returned.
 */
const NoDataMessage = (props: NoDataMessageProps) => {
    const classes = useStyles(props);
    const {message = "cuda.cards.noData"} = props;
    const [translate] = useTranslation();

    return (
        <Typography className={classes.typography}>
            {translate(typeof message === "string" ? message : message?.message)}
        </Typography>
    );
};

export default NoDataMessage;
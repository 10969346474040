import React from "react";
import apiResources from "../../../apiResources";
import {ConnectedForm, DialogBody, SelectArrayInput, useGlobalParam} from "@cuda-react/core";
import {useTranslation} from "react-i18next";

interface AddPointOfEntryContentProps {
    onClose?: any,
    onSuccess?: any,
}

const AddPointOfEntryContent: React.FC<AddPointOfEntryContentProps> = ({onClose, onSuccess}) => {
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [translate] = useTranslation();

    return (
        <DialogBody
            title="tesseract.endpoint.pointsOfEntry.create.title"
            onClose={onClose}
            form
        >
            <ConnectedForm
                resource={apiResources.pointsOfEntryCreate}
                create
                onSubmitSuccess={() => {
                    onSuccess?.();
                    onClose?.();
                }}
                onCancel={onClose}
                formatRequestData={(data) => ({
                    ...data,
                    virtualWanId
                })}
                initialValues={{cgfs: [], sites: [], gateways: []}}
                validate={(data) => {
                    if (data.cgfs.length < 1 && data.sites.length < 1 && data.gateways.length < 1) {
                        return {
                            cgfs: translate("tesseract.endpoint.pointsOfEntry.create.validateSelectOne"),
                            gateways: translate("tesseract.endpoint.pointsOfEntry.create.validateSelectOne"),
                            sites: translate("tesseract.endpoint.pointsOfEntry.create.validateSelectOne"),
                        };
                    }
                    return {};
                }}
                allowDirtyNavigation
            >
                <SelectArrayInput
                    source="cgfs"
                    label="tesseract.endpoint.pointsOfEntry.create.firewalls"
                    description="tesseract.endpoint.pointsOfEntry.create.descriptions.firewalls"
                    resource={apiResources.firewallNames}
                    optionValue="uuid"
                />
                <SelectArrayInput
                    source="gateways"
                    label="tesseract.endpoint.pointsOfEntry.create.gateways"
                    description="tesseract.endpoint.pointsOfEntry.create.descriptions.gateways"
                    resource={apiResources.gatewayNames}
                    optionValue="uuid"
                />
                <SelectArrayInput
                    source="sites"
                    label="tesseract.endpoint.pointsOfEntry.create.sites"
                    description="tesseract.endpoint.pointsOfEntry.create.descriptions.sites"
                    resource={apiResources.siteNames}
                    options={{params: {filter: {modelSeries: "T"}}}}
                    optionValue="uuid"
                />
            </ConnectedForm>
        </DialogBody>
    );
};

export default AddPointOfEntryContent;
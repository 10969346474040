import React, {MouseEventHandler} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Skeleton, Step, StepLabel, Stepper, Typography} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import classNames from "classnames";
import {useHistory} from "react-router";
import {Forward} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {LocationDescriptor} from "history";

export const styles = (theme: Theme) => createStyles({
    rootLarge: {
        width: `calc(100% - ${theme.spacing(4)})`,
        minWidth: `calc(100% - ${theme.spacing(4)})`,
        maxWidth: `calc(100% - ${theme.spacing(4)})`,
        height: `calc(100% - ${theme.spacing(2)})`,
        minHeight: `calc(100% - ${theme.spacing(2)})`,
        padding: theme.spacing(1, 2),
        display: "flex",
        "& .MuiStepIcon-text": {
            fontSize: "0.8rem",
            fontWeight: "bold"
        },
        "& .MuiStepConnector-line": {
            height: "100%"
        },
        "& .MuiStepper-root": {
            width: "100%"
        }
    },
    root: {
        width: `calc(100% - ${theme.spacing(4)})`,
        minWidth: `calc(100% - ${theme.spacing(4)})`,
        maxWidth: `calc(100% - ${theme.spacing(4)})`,
        height: `calc(100% - ${theme.spacing(2)})`,
        padding: theme.spacing(1, 2),
        display: "flex",
        "& .MuiStepConnector-lineVertical": {
            minHeight: "10px",
            marginTop: "-2px",
            marginBottom: "-4px"
        },
        "& .MuiStepConnector-root": {
            height: "8px",
            marginLeft: "10px"
        },
        "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px"
        },
        "& .MuiStepIcon-text": {
            fontSize: "0.8rem",
            fontWeight: "bold"
        },
        "& .MuiStepper-root": {
            width: "100%"
        }
    },
    skeleton: {
        width: "75%",
        height: 20,
        transform: "none",
        display: "inline-block"
    },
    skeletonIcon: {
        height: 20,
        width: 20,
        transform: "none"
    },
    clickable: {
        cursor: "pointer",
        "& *": {
            cursor: "pointer !important"
        }
    },
    stepButton: {
        float: "right",
        marginRight: -12
    },
    linkText: {
        float: "right",
        fontSize: 12,
    },
    linkButton: {
        float: "right",
        width: "16px !important",
        height: "16px !important",
        marginRight: -22
    }
});

const useStyles = makeOverrideableStyles("VerticalStepperCardContent", styles);

export interface step {
    /**
     * Required: The label for the step, displayed as the step's title.
     */
    label: string,
    /**
     * Optional: The state of the step (e.g., "completed", "active", "error").
     */
    state?: string,
    /**
     * Optional: An icon to be displayed alongside the step's label.
     */
    icon?: React.ReactNode,
    /**
     * Optional: A boolean indicating whether the step is in the loading state.
     */
    loading?: boolean
    /**
     * Optional: address string, if a link at the end of a step label wants to be displayed
     */
    linkText?: LocationDescriptor
}

export const enum VerticalStepperCardSizes {
    small = "small",
    medium = "medium",
    large = "large",
}

export interface VerticalStepperProps extends StyledComponentProps<typeof styles> {
    /**
     * Required: An array of step objects, each representing a step in the stepper.
     */
    steps: step[],
    /**
     * Optional: The size of the card, the default is small/medium
     */
    size?: VerticalStepperCardSizes,
    /**
     * Optional: The index of the currently active step, defaults to 0
     */
    activeStep?: number,
    /**
     * Optional: A boolean indicating whether the stepper is in a loading state, when true the Skeleton component will be shown in place of the step labels.
     */
    loading?: boolean,
    /**
     * Optional: A function that when defined can be triggered clicking the card surface
     */
    onClickAction?: MouseEventHandler
}

export const VerticalStepperCardContent = (props: VerticalStepperProps) => {
    const {
        steps,
        size,
        activeStep,
        loading,
        onClickAction
    } = props;

    const [translate] = useTranslation();
    const classes = useStyles(props);
    const history = useHistory();

    return (
        <div
            className={classNames(
                size === "large" ? classes.rootLarge : classes.root,
                onClickAction && classes.clickable
            )}
            onClick={onClickAction}
        >
            <Stepper
                orientation="vertical"
                activeStep={activeStep || 0}
            >
                {
                    steps.map((step) => (
                        <Step key={step.label}>
                            <StepLabel
                                icon={loading ? <Skeleton className={classes.skeletonIcon}/> : step.icon}
                            >
                                {loading ? (
                                    <Skeleton className={classes.skeleton}/>
                                ) : (step.label && translate(step.label))}
                                {step.linkText ? (
                                    <div className={classes.stepButton}>
                                        <Forward id="arrow-forward-icon" className={classes.linkButton}
                                                 color="primary"/>
                                        <Typography className={classes.linkText}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        step.linkText && history.push(step.linkText);
                                                    }}
                                                    color="primary">
                                            <Trans i18nKey="tesseract.dashboard.cards.ztnaSetup.setup"/>
                                        </Typography>
                                    </div>
                                ) : null}
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
        </div>
    );
};
import React, {useCallback} from "react";

import {Button} from "@mui/material";
import {useCrudProps, useMixpanel} from "@cuda-react/core";
import {CreatedIdentityProvider} from "../IdentityProviderTypes";
import apiResources from "../../../../../apiResources";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@barracuda-internal/bds-core";
import {StatusIcon} from "@cuda-react/icons";
import useDemoApi from "../../../../../hooks/useDemoApi";

interface IDPAuthorizationStatusFieldProps {
    status: CreatedIdentityProvider['status'],
    id: CreatedIdentityProvider['id']
}
export const IDPAuthorizationStatusField = ({status, id}: IDPAuthorizationStatusFieldProps) => {
    const registerAction = useMixpanel('Identity Providers', false);

    const [translate] = useTranslation('translation');
    const authUrl = useDemoApi(apiResources.identityProviderAuthURL, apiResources.demoAuthorizationURL);
    const authorizationURL = useCrudProps(id ? authUrl : undefined, {id})[0].data?.authorizationURL;

    const handleAuthorize = useCallback(async () => {
        registerAction('Authorize Provider');
        window.open(authorizationURL);
    }, [id, authorizationURL]);

    if (status === 'needs_consent') {
        return (
            <Button
                variant="outlined"
                color="warning"
                startIcon={<StatusIcon status="warning" />}
                onClick={handleAuthorize}
            >
                {translate(`tesseract.identity.identityProviders.authorizationStatusField.${status}`)}
            </Button>
        );
    }

    return (
        <Box display="flex" alignItems="center">
            <StatusIcon status={status === 'active' ? "ok" : "warning"} />
            <Typography ml={1}>{translate(`tesseract.identity.identityProviders.authorizationStatusField.${status}`)}</Typography>
        </Box>
    );
};
import React, {createContext, useContext, useEffect} from "react";
import {ReportingData} from "../../typesAndConstants";
import generatePreviewData from "../../utils/previewDataGenerators/reportingData";

export interface ReportingDataContextProps {
    /** the data for reporting cards */
    data?: ReportingData,
    /** the loading state of the data for reporting cards */
    loading: boolean,
    /** callback for cards to register for data (should be called on card mount IF not in preview mode, with a unique id for that card)*/
    register?: (id: string) => void,
    /** callback for cards to unregister for data  (should be called on card unmount when not in preview mode, with a unique id for that card)*/
    unregister?: (id: string) => void,
    /** callback for cards to add/update the current filters */
    addFilter?: (value: any, source: string) => void
}

export const ReportingDataContext = createContext<ReportingDataContextProps>({loading: true});

export interface ReportingDataProviderProps extends ReportingDataContextProps {
    children?: React.ReactNode | React.ReactNode[],
}

export const ReportingDataProvider = ({children, data, loading, register, unregister, addFilter}: ReportingDataProviderProps) => (
    <ReportingDataContext.Provider value={{data, loading, register, unregister, addFilter}}>
        {children}
    </ReportingDataContext.Provider>
);


export const useReportingData = (id: string, preview?: boolean) => {
    const {data, loading, register, unregister, addFilter} = useContext(ReportingDataContext);

    useEffect(() => {
        !preview && register?.(id);
        return () => {
            !preview && unregister?.(id);
        };
    }, [preview, id]);

    return {
        data: preview ? generatePreviewData() : data,
        loading,
        addFilter: preview ? undefined : addFilter
    };
};
import {getArrayDataContent, useCrudProps} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";

export const useGatewaysSites = () => {
    const sites = getArrayDataContent(useCrudProps(apiResources.ipsecApplicableSitesWans, {filter: {modelSeries: "T"}})[0]?.data)
        .filter(({wans}) => wans?.length > 0);
    const gateways = getArrayDataContent(useCrudProps(apiResources.ipsecApplicableGatewaysWans, {filter: {type: "on-prem"}})[0]?.data)
        .map((gateway) => ({
            ...gateway,
            cloud: !(gateway?.wans?.length > 0)
        }));
    const [translate] = useTranslation();

    const validate = (data: any) => {
        // @ts-ignore
        const cloudSelected = gateways.find(({uuid}) => data?.local?.uuid === uuid)?.cloud;
        if (cloudSelected && data?.initiate) {
            return {
                initiate: translate("tesseract.integration.ipSecV2.cloudCantInitiate"),
                local: {
                    uuid: translate("tesseract.integration.ipSecV2.cloudCantInitiate")
                }
            };
        }
        return {};
    };

    return {gateways, sites, validate};
};
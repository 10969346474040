import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTableRefresh,
    CustomField,
    DeleteDialog,
    getArrayDataContent,
    TablePage,
    TextField,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import React, {useRef, useState} from "react";
import apiResources from "../../../../../apiResources";
import {Dialog} from "@barracuda-internal/bds-core";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {CreateEditCustomCategory} from "./createEdit/CreateEditCustomCategory";
import {useTranslation} from "react-i18next";
import {sortBy} from "lodash";

export const CustomCategories = () => {
    const registerAction = useMixpanel("Custom Categories");
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [translate] = useTranslation();
    const categoryChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]?.data), "key");
    const groupChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.urlFilterGroups)[0]?.data), "key");

    return (
        <>
            <TablePage
                titleActions={actionVisible &&
                    <CreateEditButtonDialog
                        component={CreateEditCustomCategory}
                        create
                        label="tesseract.customCategories.dialog.create.buttonText"
                        onClick={() => setCreateOpen(true)}
                        onSuccess={() => (tableRefreshRef.current?.())}
                    />
                }
                authenticated
                resource={apiResources.customCategories}
                title="tesseract.securityAndAccess.webFilter"
                subtitle="tesseract.network.customCategories"
                noDataMessage={"tesseract.customCategories.table.noData"}
                minCellWidth={160}
                tableName="Custom Categories"
                refreshRef={tableRefreshRef}
                params={(params: any) => ({
                    ...params,
                    filter: {
                        ...(params.filter || {}),
                        category: [...((params.filter && params.filter.categories && params.filter.categories.category || []))],
                        domain: [((params.filter && params.filter.categories && params.filter.categories.domain || []))]
                    }
                })}
                expandableFields={[
                    <ChipArrayField
                        source="domains"
                        key="domains"
                        label="tesseract.customCategories.table.domainsIncluded"
                        skipMissing
                    />,
                    <ChipArrayField
                        arraySource="categoryValues"
                        source="active"
                        key="categoryValues"
                        sourceValue="name"
                        skipMissing
                    />
                ]}
                formatData={(data: any) => getArrayDataContent(data).map((rowData) => {
                    const activeCategories = categoryChoices.filter((category) => rowData.categories.includes(category.key));
                    return {
                        ...rowData,
                        activeCategories,
                        categoryValues: groupChoices.map((group) => ({
                            ...group,
                            active: activeCategories.filter((category) => category.group === group.key)
                        })).filter((group) => group.active.length > 0),
                    };
                })}
                onPageStatusChange={(pageStatus: string) => setActionVisible(pageStatus === "table")}
            >
                <TextField
                    source="name"
                    label="tesseract.customCategories.table.name"
                    filter="text"
                    width={180}
                    sortable
                />
                <TextField
                    source="description"
                    label="tesseract.customCategories.table.description"
                    filter="text"
                    width={480}
                />
                <CustomField
                    source="categories"
                    label="tesseract.customCategories.table.details"
                    render={(rules, data) => {
                        const categoriesString = data.categories.length >= 1 ? translate(
                            `tesseract.customCategories.table.detailCategories`,
                            {...data, context: rules.length, additional: rules.length - 1}
                        ) : "";
                        const domainsString = data.domains.length >= 1 ? translate(
                            `tesseract.customCategories.table.detailDomains`,
                            {...data, context: data.domains.length, additionalDomains: data.domains.length - 1}
                        ) : "";

                        return `${categoriesString}${domainsString}`;
                    }}
                    filter="custom"
                    filterProps={{
                        component: DestinationFilter,
                        filterSources: ["domain", "category"],
                        categoryChoices
                    }}
                />
                <ActionButtonsField
                    width={128}
                    source="id"
                >
                    <CreateEditButtonDialog
                        component={CreateEditCustomCategory}
                        onSuccess={() => tableRefreshRef.current?.()}
                    />
                    <DeleteDialog
                        resource={apiResources.customCategories}
                        title="tesseract.customCategories.dialog.delete.title"
                        message="tesseract.customCategories.dialog.delete.body"
                        onSuccess={() => {
                            tableRefreshRef.current?.();
                            registerAction("Custom Category Delete");
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                onClose={() => setCreateOpen(false)}
                disableEnforceFocus
            >
                <CreateEditCustomCategory
                    title="tesseract.customCategories.dialog.dialogTitle"
                    create
                    onSuccess={() => tableRefreshRef.current?.()}
                    onClose={() => setCreateOpen(false)}
                    id={undefined}/>
            </Dialog>
        </>
    );
};

export default CustomCategories;
import React, {Children, ReactNode} from "react";
import {makeStyles} from "@mui/styles";
import {Divider, Grid} from "@barracuda-internal/bds-core";
import {CardSizeOptions} from "../../../typesAndConstants";

export interface DualContainerProps {
    children: ReactNode[];
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    divider: {
        height: CardSizeOptions.medium.height - 68,
        margin: (CardSizeOptions.medium.width - (2 * CardSizeOptions.small.width)) / 2
    },
    content: {
        flexGrow: 1,
        width: CardSizeOptions.small.width
    }
}));
/**
 * DualContainer splits the content into two columns
 *
 * @param props
 * @constructor
 */
export const DualContainer = (props: DualContainerProps) => {
    const {children} = props;
    const classes = useStyles(props);
    const childArray = Children.toArray(children);

    return (
        <Grid container wrap="nowrap" justifyContent="center">
            <Grid item className={classes.content}>
                {childArray[0]}
            </Grid>
            <Grid item>
                <Divider orientation="vertical" variant="middle" className={classes.divider}/>
            </Grid>
            <Grid item className={classes.content}>
                {childArray[1]}
            </Grid>
        </Grid>
    );
};

export default DualContainer;
import React from "react";
import {makeStyles} from "@mui/styles";
import {
    ConnectedForm,
    DialogBody,
    DualListInput,
    FormSection,
    getArrayDataContent,
    SelectInput,
    TextArrayInput,
    TextInput,
    useCrudProps,
} from "@cuda-react/core";
import apiResources from "../../../apiResources";

export const styles = {
    dialogContent: {
        width: 750,
    },
};
const useStyles = makeStyles(styles);

interface NotificationContentProps {
    title?: string;
    create?: boolean;
    id?: number | string;
    onClose?: () => void;
    onSuccess?: () => void;
}

const NotificationContent: React.FC<NotificationContentProps> = ({
                                                                     create,
                                                                     id,
                                                                     title,
                                                                     onSuccess,
                                                                     ...props
                                                                 }) => {
    const notificationStatus =
        useCrudProps(apiResources.auditStatuses, {}, {}, true)[0]?.data;
    const notificationEvents =
        useCrudProps(apiResources.auditEntriesEvents, {}, {}, true)[0]?.data;
    const classes = useStyles();
    return (
        <DialogBody
            {...props}
            form
            title={title}
            classes={{dialogContent: classes.dialogContent}}
        >
            <ConnectedForm
                create={create}
                flat
                resource={apiResources.notification}
                id={id}
                onSubmitSuccess={() => {
                    onSuccess && onSuccess();
                    props.onClose?.();
                }}
                onCancel={props.onClose}
                allowDirtyNavigation
            >
                <FormSection>
                    <TextInput
                        source="name"
                        label="tesseract.settings.tabs.notification.labels.name"
                        description="tesseract.settings.tabs.notification.descriptions.name"
                        isRequired
                    />
                    <SelectInput
                        label="tesseract.settings.tabs.notification.labels.status"
                        description="tesseract.settings.tabs.notification.descriptions.status"
                        source="status"
                        choices={getArrayDataContent(notificationStatus)}
                        optionValue="key"
                        isRequired
                    />
                    <DualListInput
                        label="tesseract.settings.tabs.notification.labels.events"
                        description="tesseract.settings.tabs.notification.descriptions.events"
                        source="events"
                        choices={getArrayDataContent(notificationEvents)}
                        translateChoice={true}
                        optionValue="key"
                        inputLabelProps={{
                            fullWidth: true,
                        }}
                        isRequired
                        optionBlockValue="type"
                        optionBlockData="events"
                    />
                    <TextArrayInput
                        label="tesseract.settings.tabs.notification.labels.administrators"
                        description="tesseract.settings.tabs.notification.descriptions.administrators"
                        source="administrators"
                        isRequired
                    />
                </FormSection>
            </ConnectedForm>
        </DialogBody>
    );
};

export default NotificationContent;
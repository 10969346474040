import {useMixpanel} from "@cuda-react/core";
import React from "react";
import WholePageLayout from "./subscription/WholePageLayout";

export const CustomUnauthorizedPage = () => {
    useMixpanel("Forbidden", true, {referrer: document.referrer});
    return (
        <WholePageLayout
            title="stratos.auth.unauthorized"
            message="stratos.auth.unauthorizedMore"
            noAuth
            logout
            demo
        />
    );
};

export default CustomUnauthorizedPage;
import React from "react";
import {formatErrorMessage} from "../../../utils";
import {Typography} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {FieldErrors, useFormState} from "react-hook-form";
import {get} from "lodash";

const styles = (theme: Theme) => createStyles({
    error: {
        color: theme.palette.error.main,
        padding: theme.spacing(1, 1, 2, 6),
    }
});

const useStyles = makeOverrideableStyles("ErrorDisplay", styles);

const unpackErrors = (errors: FieldErrors, source?: string | string[]) => {
    if (!source) {
        return errors;
    }
    if (Array.isArray(source)) {
        return source.map((subsource) => get(errors, subsource)).filter((error) => !!error);
    }
    return get(errors, source);
};

export interface ErrorDisplayProps extends StyledComponentProps<typeof styles> {
    /**
     * class name for the component.
     */
    className?: string,
    /**
     * dot-path notation for a source, or array of sources, for which to display the error messages.
     */
    source?: string | string[]
    /**
     * function to render the error.
     *
     * @function
     * @param {string|object|Array<string|object>} error the error/s to display.
     * @param {*} value the value of the input.
     */
    render?: (errors?: any) => JSX.Element
}

/**
 * Render error message/s of target source/s from with a Form/TabbedForm/Wizard.
 */
export const ErrorDisplay = (props: ErrorDisplayProps): JSX.Element | null => {
    const {source, render, className} = props;
    const classes = useStyles(props);
    const {errors: allErrors} = useFormState({name: source});
    const errors = unpackErrors(allErrors, source);

    if (!errors || (Array.isArray(errors) && errors.length === 0)) {
        return null;
    }

    return render ? render(errors) : (
        <Typography className={classNames(className, classes.error)} id="cuda-form-error">
            {formatErrorMessage(errors as any)}
        </Typography>
    );
};
export default ErrorDisplay;